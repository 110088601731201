(function() {
    'use strict';

    angular
        .module('atheer.smartFlow')
        .controller('SmartFlowBuilderInsightsController', SmartFlowBuilderInsightsController);

    /* @ngInject */
    function SmartFlowBuilderInsightsController($scope, id, SmartFlow, $state, SmartFlowBuilder) {
        var vm = this;
        vm.viewType = 'insights';
        vm.dataLoaded = false;

        if (id) {
            SmartFlow.get({
                id: id
            }, function(result) {
                vm.smartFlowModel = result;
                vm.dataLoaded = true;
            });
        }

        vm.goToSmartFlows = function() {
            $state.go("storm.admin-smartflows");
        };

        vm.isDraft = function() {
            if (vm.smartFlowModel.status == 'DRAFT') {
                return true;
            } else {
                return false;
            }
        }

        vm.isPaused = function() {
            if (vm.smartFlowModel.status == 'PAUSED') {
                return true;
            } else {
                return false;
            }
        }

        vm.isLive = function() {
            if (vm.smartFlowModel.status == 'LIVE') {
                return true;
            } else {
                return false;
            }
        }

        vm.isEnded = function() {
            if (vm.smartFlowModel.status == 'ENDED') {
                return true;
            } else {
                return false;
            }
        }

        vm.isNew = function() {
            if (vm.smartFlowModel == undefined || vm.smartFlowModel.id == null) {
                return true;
            } else {
                return false;
            }
        }
    }
})();
