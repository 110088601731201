(function() {
    'use strict';
    angular
        .module('atheer.services')
        .factory('WebHook', WebHook);

    /* @ngInject */
    function WebHook($resource) {
        var resourceUrl = 'api/system/webhooks/:id';

        return $resource(resourceUrl, {
            id: '@id'
        }, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function(data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': {
                method: 'PUT'
            },
            'getExecutions': {
                url: resourceUrl + '/executions',
                method: 'GET',
                isArray: true
            },
            'testWebHook': {
                url: 'api/system/webhooks/:id/test',
                method: 'POST'
            },
            'executeWebHook': {
                url: 'api/system/webhooks-executions/:id/run',
                method: 'POST'
            },
        });
    }
})();
