(function() {
    'use strict';

    angular
        .module('atheer.anchor')
        .controller('AnchorDetailController', AnchorDetailController)

    /* @ngInject */
    function AnchorDetailController($mdDialog, $scope, $filter, $sce, $q, ParseLinks, pagingParams, paginationConstants, $state, Team, AlertService, $mdToast, $mdSidenav, Principal, AirForm, Anchor, Content, ContentAction, CONTENT_META, User, id, $timeout) {
        var vm = this;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;

        vm.anchorModel = null;
        vm.view = "CONTENT";
        vm.content = [];
        vm.airForms = [];
        vm.users = [];
        vm.loadingContent = false;
        vm.loadingAirForms = false;
        vm.loadingUsers = false;
        vm.show_settings = false;
        vm.enable_scheduling = false;
        vm.show_audiences = false;
        vm.owner = null;
        vm.audiences = [];
        vm.illustrationEmptyScreenIdContent = "no_content_anchor";
        vm.illustrationEmptyScreenIdAnchor = "no_airform_anchor";
        vm.illustrationEmptyScreenIdUser = "no_user_anchor";

        vm.isHovered = false;

        vm.query = {
            filter: '',
            limit: '10',
            order: '-id',
            page: 1
        };

        vm.loadData = function() {

            if (id) {
                vm.loadingData = true;
                Anchor.get({
                    id: id
                }, function(result) {
                    vm.anchorModel = result;
                    vm.loadContent();
                    vm.loadAirForms();
                    vm.loadUsers();

                    var queue = [
                        Team.query({
                            query: 'id=in=(' + ((vm.anchorModel.audiences && vm.anchorModel.audiences.length != 0) ? vm.anchorModel.audiences.toString() : 'dummy') + ')'
                        }).$promise
                    ];

                    if (vm.anchorModel.owner_id) {
                        queue.push(Team.get({
                            id: vm.anchorModel.owner_id
                        }).$promise)
                    }

                    $q.all(queue).then(function(data) {
                        vm.audiences = data[0];
                        if (vm.anchorModel.owner_id) {
                            vm.owner = data[1];
                        }
                    });

                    if (vm.anchorModel.available_from) {
                        vm.anchorModel.available_from = new Date(vm.anchorModel.available_from)
                    }
                    if (vm.anchorModel.discontinue_from) {
                        vm.anchorModel.discontinue_from = new Date(vm.anchorModel.discontinue_from)
                    }

                    vm.loadingData = false;
                });
            } else {
                vm.anchorModel = {
                    name: null,
                    description: null,
                    status: 'PENDING',
                    enable: false,
                    external_id: null,
                    available_from: new Date(),
                    discontinue_from: new Date(),
                    owner_id: null,
                    audiences: []
                }
            };
        }

        vm.loadContent = function() {
            vm.loadingContent = true;
            if (angular.isDefined(vm.anchorModel.content) && vm.anchorModel.content.length > 0) {
                Content.getUnfilteredContents({
                    page: 0,
                    size: 50,
                    sort: 'title,asc',
                    query: 'id=in=(' + vm.anchorModel.content + ')'
                }, function(data, headers) {
                    vm.content = data;
                    vm.loadingContent = false;
                });
            } else {
                vm.content = [];
                vm.loadingContent = false;
            };
        };

        vm.loadAirForms = function() {
            vm.loadingAirForms = true;
            if (angular.isDefined(vm.anchorModel.air_forms) && vm.anchorModel.air_forms.length > 0) {
                AirForm.query({
                    page: 0,
                    size: 50,
                    sort: 'name,asc',
                    query: 'id=in=(' + vm.anchorModel.air_forms + ');status==ACTIVE'
                }, function(data, headers) {
                    vm.airForms = data;
                    vm.loadingAirForms = false;
                });
            } else {
                vm.airForms = [];
                vm.loadingAirForms = false;
            };
        };

        vm.loadUsers = function() {
            vm.loadingUsers = true;
            if (angular.isDefined(vm.anchorModel.users) && vm.anchorModel.users.length > 0) {
                User.query({
                    page: 0,
                    size: 50,
                    sort: 'first_name,asc',
                    query: 'id=in=(' + vm.anchorModel.users + ')'
                }, function(data, headers) {
                    vm.users = data;
                    vm.loadingUsers = false;
                });
            } else {
                vm.users = [];
                vm.loadingUsers = false;
            };
        };

        vm.onSaveSuccess = function(result) {
            vm.anchorModel = result;
            vm.isSaving = false;
            if (vm.anchorModel.available_from) {
                vm.anchorModel.available_from = new Date(vm.anchorModel.available_from)
            }
            if (vm.anchorModel.discontinue_from) {
                vm.anchorModel.discontinue_from = new Date(vm.anchorModel.discontinue_from)
            }
            AlertService.displayToast($mdToast);
        };

        vm.onSaveError = function() {
            vm.isSaving = false;
        };

        vm.save = function() {
            if (vm.owner) {
                vm.anchorModel.owner_id = vm.owner.id
            }

            if (vm.audiences && vm.audiences.length > 0) {
                var audienceIds = [];
                angular.forEach(vm.audiences, function(audience) {
                    audienceIds.push(audience.id);
                });
                vm.anchorModel.audiences = audienceIds;
            }

            if (vm.anchorModel.id) {
                Anchor.update(vm.anchorModel, vm.onSaveSuccess, vm.onSaveError);
            } else {
                Anchor.save(vm.anchorModel, vm.onSaveSuccess, vm.onSaveError);
            }
        };

        vm.goToAnchors = function() {
            $state.go('storm.admin-anchors');
        };

        vm.isNew = function() {
            if (vm.anchorModel == undefined || vm.anchorModel.id == null) {
                return true;
            } else {
                return false;
            }
        };

        vm.copyAnchor = function() {
            delete vm.anchorModel['id'];
        };

        vm.togglePanel = function(componentId) {
            $mdSidenav(componentId).toggle();
        };

        vm.openContentPicker = function() {
            var filterQuery = 'embedded==false;type!=SERIES;meta_type==DISCOVERABLE;enabled==true;status!=ARCHIVED';
            if (!angular.isUndefined(vm.anchorModel.content) && vm.anchorModel.content.length != 0) {
                filterQuery = filterQuery + ';id=out=(' + vm.anchorModel.content.toString()+')'
            }
            $mdDialog.show({
                    templateUrl: 'modules/content/content-picker.html',
                    controller: 'ContentPickerController',
                    controllerAs: 'vm',
                    clickOutsideToClose: true,
                    locals: {
                        filter: filterQuery,
                        allowMultiSelect: true,
                        isWeb: true,
                        isNotFilter: true
                    }
                })
                .then(function(selectedItems) {
                    var contentIds = [];
                    angular.forEach(selectedItems, function(content) {
                        contentIds.push(content.id);
                    });

                    if (angular.isUndefined(vm.anchorModel.content)) {
                        vm.anchorModel.content = contentIds;
                    } else {
                        angular.forEach(contentIds, function(contentId) {
                            if (vm.anchorModel.content.indexOf(contentId) == -1) {
                                vm.anchorModel.content.push(contentId);
                            }
                        });
                    };
                    vm.save();
                    vm.loadContent();
                });
        };

        vm.openAirFormPicker = function() {
            var filterQuery = 'status==ACTIVE';
            if (!angular.isUndefined(vm.anchorModel.air_forms) && vm.anchorModel.air_forms.length != 0) {
                filterQuery = filterQuery + ';id=out=(' + vm.anchorModel.air_forms.toString()+')'
            }
            $mdDialog.show({
                    controller: 'AirFormPickerController',
                    templateUrl: 'modules/airform/airform-picker.tmpl.html',
                    controllerAs: 'vm',
                    locals: {
                        filter: filterQuery,
                        allowMultiSelect: true
                    }
                })
                .then(function(selectedItems) {
                    var airformIds = [];
                    angular.forEach(selectedItems, function(airform) {
                        airformIds.push(airform.id);
                    });

                    if (angular.isUndefined(vm.anchorModel.air_forms)) {
                        vm.anchorModel.air_forms = airformIds;
                    } else {
                        angular.forEach(airformIds, function(airformId) {
                            if (vm.anchorModel.air_forms.indexOf(airformId) == -1) {
                                vm.anchorModel.air_forms.push(airformId);
                            }
                        });
                    };

                    vm.save();
                    vm.loadAirForms();
                });
        };

        vm.openUserPicker = function() {
            $mdDialog.show({
                    templateUrl: 'modules/user/user-picker.tmpl.html',
                    controller: 'UserPickerController',
                    controllerAs: 'vm',
                    locals: {
                        filter: vm.anchorModel.users,
                        allowMultiSelect: true,
                        userslist: [],
                        isSession: false,
                        isScheduledSession: false,
                        isAdmin: true,
                        isConversationSession: false,
                        isSmartScan: false
                    }
                })
                .then(function(selectedItems) {
                    var userIds = [];
                    angular.forEach(selectedItems, function(user) {
                        userIds.push(user.id);
                    });

                    if (angular.isUndefined(vm.anchorModel.users)) {
                        vm.anchorModel.users = userIds;
                    } else {
                        angular.forEach(userIds, function(userId) {
                            if (vm.anchorModel.users.indexOf(userId) == -1) {
                                vm.anchorModel.users.push(userId);
                            }
                        });
                    };
                    vm.save();
                    vm.loadUsers();
                });
        };

        vm.removeContent = function(content) {
            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.anchor.removeContent'))
                .ariaLabel($filter('translate')('atheer.anchor.removeContent'))
                .ok($filter('translate')('entity.action.remove'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function() {
                var index = vm.anchorModel.content.indexOf(content.id);
                vm.anchorModel.content.splice(index, 1);
                vm.save();
                vm.loadContent();
            }).finally(function() {});
        };

        vm.removeAirForm = function(airform) {
            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.anchor.removeAirForm'))
                .ariaLabel($filter('translate')('atheer.anchor.removeAirForm'))
                .ok($filter('translate')('entity.action.remove'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function() {
                var index = vm.anchorModel.air_forms.indexOf(airform.id);
                vm.anchorModel.air_forms.splice(index, 1);
                vm.save();
                vm.loadAirForms();
            }).finally(function() {});
        };

        vm.removeUser = function(user) {
            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.anchor.removeUser'))
                .ariaLabel($filter('translate')('atheer.anchor.removeUser'))
                .ok($filter('translate')('entity.action.remove'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function() {
                var index = vm.anchorModel.users.indexOf(user.id);
                vm.anchorModel.users.splice(index, 1);
                vm.save();
                vm.loadUsers();
            }).finally(function() {});
        };

        vm.getContentMeta = function(content) {
            var contentMetaData = $filter('filter')(CONTENT_META, {
                type: content.data.type.replace(/_/g, '-')
            })[0];
            return contentMetaData;
        };

        vm.launchContentPreview = function(content) {
            ContentAction.previewContent(content);
        };

        vm.hideInfo = function() {
            $timeout(function() {
                if(vm.isHovered) {
                    vm.isHovered = false;
                }
            }, 3000);
        };

        vm.loadData();
    }
})();
