(function() {

    'use strict';
    angular
        .module('atheer.content')
        .constant('CONTENT_RECOGNITION_META', {
            'document': {
                'sections': {
                    'qrcode': {
                        label: 'atheer.content.qrCode',
                        icon: 'zmdi zmdi-account',
                        controller: 'ContentDetailRecognitionQRCodeController',
                        template: 'modules/content/content-detail-recognition-qrcode.tmpl.html'
                    }
                }
            },
            'image': {
                'sections': {
                    'qrcode': {
                        label: 'atheer.content.qrCode',
                        icon: 'zmdi zmdi-account',
                        controller: 'ContentDetailRecognitionQRCodeController',
                        template: 'modules/content/content-detail-recognition-qrcode.tmpl.html'
                    }
                }
            },
            'smart_page': {
                'sections': {
                    'qrcode': {
                        label: 'atheer.content.qrCode',
                        icon: 'zmdi zmdi-account',
                        controller: 'ContentDetailRecognitionQRCodeController',
                        template: 'modules/content/content-detail-recognition-qrcode.tmpl.html'
                    }
                }
            },
            'podcast': {
                'sections': {
                    'qrcode': {
                        label: 'atheer.content.qrCode',
                        icon: 'zmdi zmdi-account',
                        controller: 'ContentDetailRecognitionQRCodeController',
                        template: 'modules/content/content-detail-recognition-qrcode.tmpl.html'
                    }
                }
            },
            'video': {
                'sections': {
                    'qrcode': {
                        label: 'atheer.content.qrCode',
                        icon: 'zmdi zmdi-account',
                        controller: 'ContentDetailRecognitionQRCodeController',
                        template: 'modules/content/content-detail-recognition-qrcode.tmpl.html'
                    }
                }
            },
            'link': {
                'sections': {
                    'qrcode': {
                        label: 'atheer.content.qrCode',
                        icon: 'zmdi zmdi-account',
                        controller: 'ContentDetailRecognitionQRCodeController',
                        template: 'modules/content/content-detail-recognition-qrcode.tmpl.html'
                    }
                }
            },
            'session_recording': {
                'sections': {
                    'qrcode': {
                        label: 'atheer.content.qrCode',
                        icon: 'zmdi zmdi-account',
                        controller: 'ContentDetailRecognitionQRCodeController',
                        template: 'modules/content/content-detail-recognition-qrcode.tmpl.html'
                    }
                }
            },
        });
})();
