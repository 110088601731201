(function() {
    'use strict';

    angular
        .module('atheer.airform')
        .controller('AirFormPickerController', AirFormPickerController);

    /* @ngInject */
    function AirFormPickerController($scope, $state, AirForm, allowMultiSelect, filter, ParseLinks, AlertService, $mdDialog, Principal, paginationConstants) {
        var vm = this;

        vm.loadingData = false;
        vm.loadAll = loadAll;
        vm.transition = transition;
        vm.removeFilter = removeFilter;
        vm.allowMultiSelect = allowMultiSelect;
        vm.airformFilter = filter;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.predicate = "id";
        vm.page = 1;

        vm.totalItems = null;
        vm.currentSelectedAirForms = [];
        vm.airformFilter = [];
        vm.illustartionEmptyScreenId = "no_airforms";

        vm.query = {
            filter: '',
            limit: paginationConstants.itemsPerPage,
            order: '-id',
            page: 1
        };

        vm.filter = {
            show: false,
            options: {
                debounce: 500
            }
        };

        vm.updateParentObject = function() {
            $mdDialog.hide(vm.currentSelectedAirForms);
        };

        vm.close = function() {
            $mdDialog.cancel();
        };

        function loadAll() {
            var query = null;
            query = getQueryString();
            vm.loadingData = true;
            AirForm.getActiveAirform({
                query: query,
                page: 0,
                size: vm.query.limit,
                sort: sort(),
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.airforms = data;
                vm.page = 1;
                vm.loadingData = false;
            }

            function onError(error) {
                vm.loadingData = false;
                AlertService.error(error.data.message);
            }
        };

        function transition() {
            var query = null;
            query = getQueryString();
            vm.promise = AirForm.getActiveAirform({
                query: query,
                page: vm.page - 1,
                size: vm.query.limit,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            }).$promise;

            vm.promise.then(function(data, headers) {
                vm.queryCount = vm.totalItems;
                vm.airforms = data;
            });
        };

        function removeFilter() {
            vm.filter.show = false;
            vm.query.filter = '';
            resetModal();
        };

        function getQueryString() {
            var airformQuery =  '(status==ACTIVE)';
            if (filter.length != 0) {
                airformQuery = filter;
            }
            if (vm.query.filter.length != 0) {
               airformQuery = airformQuery + ';(name=re="' + vm.query.filter + '")';
            }
            return airformQuery;
        };

        function resetModal() {
            loadAll();
        };
        vm.loadAll();
    }
})();
