(function() {
    'use strict';
    angular
        .module('atheer.team')
        .directive('fpTeamBuilder', fpTeamBuilder);

    function fpTeamBuilder() {
        var directive = {
            restrict: 'AE',
            templateUrl: 'modules/team/team-builder.tmpl.html',
            controller: TeamDBuilderDirectiveController
        };
        return directive;
    }

    /* @ngInject */
    function TeamDBuilderDirectiveController($rootScope, $scope, $q, ParseLinks, $mdSidenav, AlertService, $element, $filter, $mdDialog, MetaData, Setting, Team, FIELD_CONDITION_CONSTANT, DateUtils, $mdPanel, $timeout, triSettings, paginationConstants, ToastService) {

        $scope.primaryColor = triSettings.getSkinModel().primary_color_hex;
        $scope.secondaryColor = triSettings.getSkinModel().secondary_color_hex;
        $scope.isMasterWorkspace = $rootScope.isMasterWorkspace;
        $scope.matchSelected = false;

        $scope.timezones = [];
        $scope.languages = [];
        $scope.metaFieldGroups = [];
        $scope.teams = null;

        $scope.selectedTeam = null;
        $scope.teamDetail = null;
        $scope.hasNewTeam = false;

        $scope.selectedCondition = null;

        $scope.userHistoryPredicate = 'id';
        $scope.userHistoryReverse = 'asc';
        $scope.userHistoryPage = 1;
        $scope.userHistoryTotalItems = null;
        $scope.userHistory = [];
        $scope.isUserHistoryLoaded = false;
        $scope.userHistoryQueryCount = 0;
        $scope.userHistoryQueryLimit = paginationConstants.itemsPerPage;
        $scope.teamMemberCountHistory = null;
        $scope.predicate = "id";
        $scope.conditionListOptions = [];
        $scope.permissions = {
            manageTeam: 'manage-segment-user'
        };

        $scope.teamUserChartData = {
            chart: {
                type: 'line',
            },
            title: {
                text: $filter('translate')('atheer.teamInsights.members')
            },
            credits: {
                enabled: false
            },
            yAxis: {
                title: {
                    text: $filter('translate')('atheer.teamInsights.noOfMembers')
                }
            },
            xAxis: {
                title: {
                    text: $filter('translate')('atheer.teamInsights.date')
                },
                type: 'datetime',
                labels: {
                    format: '{value:%e - %b - %Y}'
                }

            },
            series: [{
                name: $filter('translate')('atheer.teamInsights.members'),
                data: []
            }],
            tooltip: {
                formatter: function() {
                    return '<b>' + Highcharts.dateFormat('%e - %b - %Y',
                        new Date(this.x)) + '</b><br/> Members : ' + this.y;
                }
            }
        };

        function getDateFromItem(item) {
            return item.x;
        }
        function getSortOrder() {
            if ($scope.predicate.startsWith('-')) {
                return $scope.predicate.substr(1) + ',' + 'desc';
            } else {
                return $scope.predicate + ',' + 'asc';
            }

        }

        $scope.reOrder = function (order){
            $scope.predicate = order;
            $scope.userHistoryTransition();
        }
        $scope.togglePanel = function(componentId) {
            $mdSidenav(componentId).toggle()
                .then(function() {
                    if (componentId == 'historyPanel') {
                        $scope.isUserDataLoaded = false;
                        $scope.loadMemberHistory();
                    } else if (componentId == 'insightsPanel') {
                        $scope.isInsightsLoaded = false;
                        $scope.loadTeamInsights();
                    }
                });
        };

        $scope.loadMemberHistory = function() {
            $scope.userHistory = [];
            $scope.isUserHistoryLoaded = false;
            Team.getMemberHistory({
                id: $scope.teamDetail.id,
                page: 0,
                size: 10,
                query: null
            }, onUserHistorySuccess, onUserHistoryError);

            function onUserHistorySuccess(data, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.userHistoryTotalItems = headers('X-Total-Count');
                $scope.userHistoryQueryCount = $scope.userHistoryTotalItems;
                $scope.userHistoryPage = 1;
                $scope.userHistory = data;
                $scope.isUserHistoryLoaded = true;
            }

            function onUserHistoryError(error) {
                AlertService.error(error.data.message);
                $scope.isUserHistoryDataLoaded = true;
            }
        };

        $scope.userHistoryTransition = function(pageNo) {
            if(!pageNo) {
                pageNo = $scope.userHistoryPage;
            } else {
                $scope.userHistoryPage = pageNo;
            }

            $scope.promise = Team.getMemberHistory({
                id: $scope.teamDetail.id,
                page: pageNo - 1,
                size: $scope.userHistoryQueryLimit,
                sort: getSortOrder()
            }).$promise;

            $scope.promise.then(function(data, headers) {
                $scope.userHistoryQueryCount = $scope.userHistoryTotalItems;
                $scope.userHistory = data;
            });
        }

        $scope.loadTeamInsights = function() {
            $scope.isInsightsLoaded = false;

            Team.getMemberCountHistory({
                id: $scope.teamDetail.id,
                page: 0,
                size: 90,
                sort: 'id,desc',
                query: null
            }, onInsightsSuccess, onInsightsError);

            function onInsightsSuccess(result) {
                $scope.teamUserChartData.series[0].data = [];
                $scope.teamMemberCountHistory = result;
                angular.forEach(result, function(countHistory) {
                    $scope.teamUserChartData.series[0].data.push({
                        x: new Date(countHistory.record_date),
                        y: countHistory.member_count
                    })
                });
                $scope.teamUserChartData.series[0].data = $filter('orderBy')($scope.teamUserChartData.series[0].data, getDateFromItem);
                $scope.isInsightsLoaded = true;
            }

            function onInsightsError(error) {
                AlertService.error(error.data.message);
                $scope.isInsightsLoaded = true;
            }
        };

        $scope.refreshTeam = function() {
            if ($scope.hasNewTeam) {
                $scope.teams.pop();
                $scope.hasNewTeam = false;
            }
            initTeam($scope.selectedTeam);
        };

        $scope.showDelete = function(teamInfo) {
            if (teamInfo.audience_no != 1000 && teamInfo.id != null) {
                return true;
            } else {
                return false;
            }
        }

        $scope.showActions = function(teamInfo) {
            if (teamInfo.audience_no != 1000 && teamInfo.id != null) {
                return true;
            } else {
                return false;
            }
        }

        $scope.showReset = function(teamInfo) {
            if (teamInfo != null && teamInfo.type == 'SMART') {
                if (teamInfo.audience_no == 1000) {
                    return false;
                } else if (teamInfo.id == null) {
                    return true;
                } else if (teamInfo.picture_id != $scope.selectedTeam.picture_id) {
                    return false;
                } else if (teamInfo.use_conversation != $scope.selectedTeam.use_conversation) {
                    return false;
                } else if ($rootScope._.isEqual(angular.toJson(teamInfo), angular.toJson($scope.selectedTeam))) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        }

        $scope.showSave = function(teamInfo) {
            if (teamInfo != null && angular.isDefined(teamInfo.criteria) && teamInfo.criteria != null) {
                angular.forEach(teamInfo.criteria.condition_groups, function(condition) {
                    angular.forEach(condition.conditions, function (selectedOption) {
                        if(selectedOption.displayValue) {
                            delete selectedOption.displayValue;
                        }
                    });
                });
            }
            if (teamInfo != null && teamInfo.type == 'SMART') {
                if (teamInfo.audience_no == 1000) {
                    return false;
                } else if (teamInfo.id == null && teamInfo.criteria.condition_groups.length > 0) {
                    return true;
                } else if (teamInfo.picture_id != $scope.selectedTeam.picture_id) {
                    return false;
                } else if (teamInfo.use_conversation != $scope.selectedTeam.use_conversation) {
                    return false;
                } else if ($rootScope._.isEqual(angular.toJson(teamInfo), angular.toJson($scope.selectedTeam))) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        }

        $scope.resetFilter = function() {
            if ($scope.hasNewTeam) {
                $scope.teams.pop();
                $scope.hasNewTeam = false;
                angular.forEach($scope.teams, function(teamInfo) {
                    if (teamInfo.audience_no == 1000) {
                        initTeam(teamInfo);
                    }
                });
            } else {
                initTeam($scope.selectedTeam);
            };
        };

        $scope.isDefaultTeam = function(teamInfo) {
            if (teamInfo.audience_no == 1000) {
                return true;
            } else {
                return false;
            }
        };

        $scope.getFieldLabel = function(condition) {
            var label = null;
            var fieldMeta;
            var fieldGroupMeta = $filter('filter')($scope.metaFieldGroups, {
                'name': condition.field_group_name
            })[0];
            //filter gets array of all fields which contain condition.field_name
            var fieldMetaArray = $filter('filter')(fieldGroupMeta.fields, {
                'name': condition.field_name
            });
            for(var cnt = 0; cnt < fieldMetaArray.length; cnt++) {
                if(fieldMetaArray[cnt].name === condition.field_name) {
                    fieldMeta = fieldMetaArray[cnt];
                    break;
                }
            }

            if (condition.data_type === "LIST" && condition.value) {
                var selectedListOption = $filter('filter')(fieldMeta.list_options, {
                    'option_id': condition.value
                })[0];
                $scope.conditionListOptions = fieldMeta.list_options;
                condition.displayValue = selectedListOption.label;
            }
            if (condition.data_type === "LANGUAGE" && condition.value) {
                var selectedListOption = $filter('filter')($scope.languages, {
                    'name': condition.value
                })[0];
                $scope.conditionListOptions = $scope.languages;
                condition.displayValue = selectedListOption.description;
            }
            if (condition.data_type === "TIMEZONE" && condition.value) {
                var selectedListOption = $filter('filter')($scope.timezones, {
                    'timezone_no': condition.value
                })[0];
                $scope.conditionListOptions = $scope.timezones;
                condition.displayValue = selectedListOption.display_name;
            }
            if (condition.data_type === "OBJECT") {
                var operator = $filter('filter')(FIELD_CONDITION_CONSTANT[condition.data_type][condition.field_name], {
                    value: condition.operator
                }, true)[0];
                condition.displayValue = condition.value.name;
            }
            else if (condition.data_type === "BOOLEAN") {
                var operator;
                if(condition.value === null) {
                    operator = $filter('filter')(FIELD_CONDITION_CONSTANT[condition.data_type], {
                        defaultValue: ""
                    }, true)[0];
                }else {
                    operator = $filter('filter')(FIELD_CONDITION_CONSTANT[condition.data_type], {
                        defaultValue: condition.value.toString()
                    }, true)[0];
                }
            }
            else {
                var operator = $filter('filter')(FIELD_CONDITION_CONSTANT[condition.data_type], {
                    value: condition.operator
                }, true)[0];
            }

            if (condition.field_group_name == 'profile') {
                label = fieldMeta.label + ' ' + $filter('translate')(operator.label);
            } else {
                label = fieldGroupMeta.label + ':' + fieldMeta.label + ' ' + $filter('translate')(operator.label);
            }

            if (condition.value != null && condition.data_type !== "BOOLEAN") {
                label = label + ' ' + getFieldDisplayValue(condition);
            }
            return label;
        };

        $scope.getConditionBackgroundColor = function(condition, conditionFocus) {
            if ($scope.isMissingValue(condition)) {
                return $scope.secondaryColor;
            }

            if (condition == $scope.selectedCondition) {
                return $scope.primaryColor;
            }
        };

        $scope.isMissingValue = function(condition) {
            if(condition.data_type === "OBJECT") {
                var operator = $filter('filter')(FIELD_CONDITION_CONSTANT[condition.data_type][condition.field_name], {
                    value: condition.operator
                }, true)[0];
            } else {
                var operator = $filter('filter')(FIELD_CONDITION_CONSTANT[condition.data_type], {
                    value: condition.operator
                }, true)[0];
            }

            if (operator.showInput && (condition.value == null || condition.value.length <= 0)) {
                return true;
            } else {
                return false;
            }
        };

        $scope.removeField = function(groupIndex, fieldIndex) {
            $scope.teamDetail.criteria.condition_groups[groupIndex].conditions.splice(fieldIndex, 1);
            if ($scope.teamDetail.criteria.condition_groups[groupIndex].conditions.length == 0) {
                $scope.teamDetail.criteria.condition_groups.splice(groupIndex, 1);
            }
            // firing an event downwards
            $scope.$emit('updateUserList', $scope.teamDetail);
        };

        $scope.editTeam = function(team) {
            $mdDialog.show({
                templateUrl: 'modules/team/team-detail-dialog.tmpl.html',
                clickOutsideToClose: true,
                controller: "TeamDetailDialogController",
                controllerAs: "vm",
                size: 'lg',
                locals: {
                    mode: 'UPDATE',
                    team: angular.copy(team)
                }
            }).then(function(updatedTeam) {
                team.name = updatedTeam.name;
                team.description = updatedTeam.description;
                team.status = updatedTeam.status;
                team.use_conversation = updatedTeam.use_conversation;
                team.functional_team = updatedTeam.functional_team;
                team.picture_id = updatedTeam.picture_id;
                $scope.saveTeam(team);
            });
        };

        $scope.saveDynamicTeam = function(team) {
            if (team.id) {
                $scope.saveTeam(team);
            } else {
                $mdDialog.show({
                    templateUrl: 'modules/team/team-detail-dialog.tmpl.html',
                    clickOutsideToClose: true,
                    controller: "TeamDetailDialogController",
                    controllerAs: "vm",
                    size: 'lg',
                    locals: {
                        mode: 'DYNAMIC',
                        team: team
                    }
                }).then(function(updatedTeam) {
                    team.name = updatedTeam.name;
                    team.description = updatedTeam.description;
                    team.status = updatedTeam.status;
                    team.use_conversation = updatedTeam.use_conversation;
                    team.functional_team = updatedTeam.functional_team;
                    team.picture_id = updatedTeam.picture_id;
                    $scope.saveTeam(team);
                });
            }
        };

        $scope.deleteTeam = function(team) {
            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.teamBuilder.delete'))
                .ariaLabel($filter('translate')('atheer.teamBuilder.delete'))
                .ok($filter('translate')('entity.action.delete'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function() {
                Team.delete({
                    id: team.id
                }, function() {
                    ToastService.displayToast(team.name + $filter('translate')('atheer.teamBuilder.deleted'), 3000);
                    loadAll();
                },function(error) {
                    var errorMessage;
                    if(error.data.description && error.data.description != '') {
                        errorMessage = error.data.description;
                    } else {
                        errorMessage = $filter('translate')('atheer.teamBuilder.errorDeletingTeam');
                    }
                    var errorWindow = $mdDialog.alert()
                        .title(errorMessage)
                        .ok('Close');
                    $mdDialog.show(errorWindow);
                });
            });
        };

        $scope.saveTeam = function(team) {
            if (team != null && angular.isDefined(team.criteria) && team.criteria != null) {
                angular.forEach(team.criteria.condition_groups, function(condition) {
                    angular.forEach(condition.conditions, function (selectedOption) {
                        if(selectedOption.displayValue) {
                            delete selectedOption.displayValue;
                        }
                    });
                });
            }
            if (team.id) {
                Team.update(team, onSaveSuccess, onSaveError);
            } else {
                Team.save(team, onSaveSuccess, onSaveError);
            }

            function onSaveSuccess(result) {
                resetTeams(result);
                ToastService.displayToast(result.name + $filter('translate')('atheer.teamBuilder.updated'), 3000);
            };

            function onSaveError() {

            };
        };

        $scope.showMatchType = function(ev) {
            if ($scope.teamDetail.audience_no != 1000) {
                $scope.matchSelected = true;

                var position = $mdPanel.newPanelPosition()
                    .relativeTo('#match-type')
                    .addPanelPosition($mdPanel.xPosition.OFFSET_END, $mdPanel.yPosition.ALIGN_TOPS);

                var config = {
                    attachTo: angular.element(document.body),
                    controller: "MatchSelectionPanelController",
                    controllerAs: 'vm',
                    templateUrl: 'core/components/filter/match-selection-panel.tmpl.html',
                    position: position,
                    openFrom: ev,
                    clickOutsideToClose: true,
                    escapeToClose: true,
                    focusOnOpen: false,
                    zIndex: 2,
                    onCloseSuccess: function(panelRef, reason) {
                        $scope.matchSelected = false;
                        if (panelRef.matchAll != $scope.teamDetail.criteria.match_all) {
                            $scope.teamDetail.criteria.match_all = panelRef.matchAll;
                            // firing an event downwards
                            $scope.$emit('updateUserList', $scope.teamDetail);
                        }
                    }
                };
                $mdPanel.open(config);
            }
        };

        $scope.showFields = function(ev) {
            if ($scope.teamDetail.audience_no == 1000) {
                var newDynamicTeam = {
                    audience_no: null,
                    name: 'Your New Team',
                    type: 'SMART',
                    status: 'DRAFT',
                    criteria: {
                        match_all: true,
                        condition_groups: []
                    }
                };
                $scope.selectedTeam = newDynamicTeam;
                $scope.teamDetail = newDynamicTeam;
                $scope.teams.push(newDynamicTeam);
                $scope.hasNewTeam = true;
            };

            var position = $mdPanel.newPanelPosition()
                .relativeTo('#add-filter')
                .addPanelPosition($mdPanel.xPosition.OFFSET_END, $mdPanel.yPosition.ALIGN_TOPS);


            var config = {
                attachTo: angular.element(document.body),
                controller: "FieldSelectionPanelController",
                controllerAs: 'vm',
                templateUrl: 'core/components/filter/field-selection-panel.tmpl.html',
                position: position,
                locals: {
                    metaFieldGroups: $scope.metaFieldGroups,
                    conditionGroups: $scope.teamDetail.criteria.condition_groups
                },
                openFrom: ev,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: false,
                zIndex: 2,
                onCloseSuccess: function(panelRef, reason) {
                    if (panelRef.condition != null) {
                        $timeout(function() {
                            $scope.showFieldSettting(panelRef.condition);
                        }, 200);
                    }
                }
            };
            $mdPanel.open(config);
        };

        $scope.showFieldSettting = function(condition) {
            $scope.selectedCondition = condition;
            var position = $mdPanel.newPanelPosition()
                .relativeTo('#' + condition.field_group_name + '-' + condition.field_name)
                .addPanelPosition($mdPanel.xPosition.OFFSET_END, $mdPanel.yPosition.ALIGN_TOPS);

            if(condition.data_type === 'LANGUAGE') {
                $scope.conditionListOptions = $scope.languages;
            }
            else if(condition.data_type === 'TIMEZONE') {
                $scope.conditionListOptions = $scope.timezones
            } else {
                $scope.conditionListOptions = [];
            }
            var config = {
                attachTo: angular.element(document.body),
                controller: "FieldSettingPanelController",
                controllerAs: 'vm',
                templateUrl: 'core/components/filter/field-setting-panel.tmpl.html',
                position: position,
                locals: {
                    metaFieldGroups: $scope.metaFieldGroups,
                    condition: condition,
                    conditionListOptions: $scope.conditionListOptions
                },
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: false,
                zIndex: 2,
                onCloseSuccess: function(panelRef, reason) {
                    $scope.selectedCondition = null;
                    // firing an event downwards
                    $scope.$emit('updateUserList', $scope.teamDetail);
                }
            };

            $mdPanel.open(config);
        };

        $scope.getUserProfilePicture = function(pictureId) {
            return "/media/pictures/" + pictureId;
        }

        $scope.teamFilter = function (item) { 
            return item.audience_no != 1001; 
        };

        function getFieldDisplayValue(condition) {
            if (!angular.isDefined(condition.value) || condition.value == null) {
                return null;
            } else {
                if (condition.data_type == 'DATE') {
                    if (condition.operator == 'EXACTLY') {
                        return condition.value + ' days ago';
                    } else if (condition.operator == 'GREATER_THAN') {
                        return condition.value + ' days ago';
                    } else if (condition.operator == 'GREATER_THAN_OR_EQUAL') {
                        return condition.value + ' days ago';
                    } else if (condition.operator == 'LESS_THAN') {
                        return condition.value + ' days ago';
                    } else if (condition.operator == 'LESS_THAN_OR_EQUAL') {
                        return condition.value + ' days ago';
                    } else if (condition.operator == 'WITHIN') {
                        return condition.value + ' days';
                    } else if (condition.operator == 'BETWEEN') {
                        var fromDate = (angular.isDefined(condition.value.from_date) && condition.value.from_date != null ? $filter('date')(condition.value.from_date, "MM-dd-yyyy") : null);
                        var toDate = (angular.isDefined(condition.value.to_date) && condition.value.to_date != null ? $filter('date')(condition.value.to_date, "MM-dd-yyyy") : null);
                        return (fromDate == null ? '' : fromDate) + ' and ' + (toDate == null ? '' : toDate);
                    } else if (condition.operator == 'IS') {
                        if (condition.value == 'TODAY') {
                            return 'today';
                        } else if (condition.value == 'YESTERDAY') {
                            return 'yesterday';
                        } else if (condition.value == 'START_OF_WEEK') {
                            return 'start of the week';
                        } else if (condition.value == 'END_OF_WEEK') {
                            return 'end of the week';
                        } else if (condition.value == 'START_OF_MONTH') {
                            return 'start of the month';
                        } else if (condition.value == 'END_OF_MONTH') {
                            return 'end of the month';
                        } else {
                            return condition.value;
                        }
                    } else if (condition.operator == 'AFTER') {
                        return $filter('date')(condition.value, "MM-dd-yyyy");
                    } else if (condition.operator == 'BEFORE') {
                        return $filter('date')(condition.value, "MM-dd-yyyy");
                    } else if (condition.operator == 'ON') {
                        return $filter('date')(condition.value, "MM-dd-yyyy");
                    } else {
                        return condition.value;
                    }
                }
                else if (condition.data_type == 'LIST' || condition.data_type == 'LANGUAGE' || condition.data_type == 'TIMEZONE' || condition.data_type == 'OBJECT') {
                    return condition.displayValue;
                }
                else {
                    return condition.value;
                }
            }
        };

        function refreshTeams() {
            $q.all([
                Team.query({
                    size: 250,
                    sort: sortTeam()
                }).$promise,
            ]).then(function(data) {
                $scope.teams = data[0];

                if ($scope.hasNewTeam) {
                    $scope.teams.push($scope.teamDetail);
                } else {
                    angular.forEach($scope.teams, function(teamInfo) {
                        if (teamInfo.audience_no == $scope.teamDetail.audience_no) {
                            initTeam(teamInfo);
                        }
                    });
                }
            });
        };

        function sortTeam() {
            return ['name' + ',' + 'asc'];
        }

        function resetTeams(team) {
            $q.all([
                Team.query({
                    size: 250,
                    sort: sortTeam()
                }).$promise,
            ]).then(function(data) {
                $scope.teams = data[0];
                $scope.hasNewTeam = false;

                angular.forEach($scope.teams, function(teamInfo) {
                    if (teamInfo.audience_no == team.audience_no) {
                        initTeam(teamInfo);
                    }
                });
            });
        };

        function initTeam(teamInfo) {
            $scope.selectedTeam = teamInfo;
            $scope.teamDetail = angular.copy(teamInfo);

            if (!angular.isDefined($scope.teamDetail.criteria) || $scope.teamDetail.criteria == null) {
                $scope.teamDetail.criteria = {
                    match_all: true
                }
            }
            // firing an event downwards
            $scope.$emit('updateUserList', $scope.teamDetail);
            localStorage.setItem("team-list-selected-team", teamInfo.audience_no.toString());
        };

        function loadAll() {
            $scope.loadingData = true;
            $q.all([
                MetaData.get({
                    id: 'user',
                    is_name: true,
                    add_default_properties: true
                }).$promise,
                Setting.getSystemTimezones({
                    size: 100
                }).$promise,
                Setting.getSystemLanguages().$promise,
                Team.query({
                    size: 250,
                    sort: sortTeam()
                }).$promise,
            ]).then(function(data) {
                $scope.metaFieldGroups = data[0].field_groups;
                $scope.timezones = data[1];
                $scope.languages = data[2];
                $scope.teams = data[3];

                var selectedTeam = "1000";
                if (localStorage.getItem("team-list-selected-team") !== null) {
                    //to get permission from browser cache for returning user
                    selectedTeam = localStorage.getItem("team-list-selected-team");
                }

                for(var teamNo in $scope.teams){
                    if ($scope.teams[teamNo].audience_no.toString() == selectedTeam) {
                        initTeam($scope.teams[teamNo]);
                        break;
                    }
                };
                $scope.loadingData = false;
            });
        }

        // listen for the event in the relevant $scope
        $rootScope.$on('refreshTeams', function(event) {
            refreshTeams();
        });


        loadAll();
    }
})();
