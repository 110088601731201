(function () {
    'use strict';

    angular
        .module('atheer.session')
        .service('SessionsService', SessionsService);

    /* @ngInject */
    function SessionsService($mdDialog, $state, deviceDetector) {
        var annotationImage;

        var service = {
            startInstantSession: startInstantSession,
            startSession: startSession,
            joinSession: joinSession,
            exportSessionsReport: exportSessionsReport
        };
        return service;

        function startInstantSession() {
            $mdDialog
                .show({
                    templateUrl: 'modules/session/session-start-dialog.tmpl.html',
                    clickOutsideToClose: true,
                    controller: 'SessionStartController',
                    controllerAs: 'vm',
                    locals: {
                        filter: null,
                        conversation: null,
                        isMobileBrowser: deviceDetector.isMobile()
                    }
                })
                .then(function (result) { });
        };

        function joinSession() {
            $mdDialog
                .show({
                    templateUrl: 'modules/session/session-join-dialog.tmpl.html',
                    clickOutsideToClose: true,
                    controller: 'SessionJoinController',
                    controllerAs: 'vm',
                    locals: {
                        sessionNo: null,
                        isMobileBrowser: deviceDetector.isMobile()
                    }
                })
                .then(function (result) { });
        };

        function startSession() {
            $state.go('storm.sessions.player', {
                session_no: entity.session_no
            });
        };

        function exportSessionsReport() {
            $mdDialog.show({
                templateUrl: 'modules/session/session-summary-report.tmpl.html',
                clickOutsideToClose: true,
                controller: "sessionSummaryReportController",
                controllerAs: "vm",
            }).then(function (result) {

            });

        }
    }
})();
