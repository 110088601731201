(function() {
    'use strict';

    angular
        .module('atheer.topic')
        .controller('TopicDetailImagesController', TopicDetailImagesController)

    /* @ngInject */
    function TopicDetailImagesController($scope, $q, $filter, id, Report, Topic, $timeout) {
        var vm = this;

        vm.loadingData = false;
        vm.topicModel = null;
        vm.showActions = false;

        vm.loadData = function() {
            if (id) {
                Topic.get({
                    id: id
                }, function(result) {
                    vm.topicModel = result;
                    vm.loadingData = false;

                    $timeout(function() {
                        vm.showActions = true;
                    }, 1000);
                });
            }
        };

        vm.loadData();
    }
})();
