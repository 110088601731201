(function() {
    'use strict';

    angular
        .module('atheer.anchor')
        .controller('AnchorController', AnchorController);

    /* @ngInject */
    function AnchorController($rootScope, $scope, $state, $mdDialog, Anchor, ParseLinks, AlertService, pagingParams, paginationConstants, $mdToast, $stateParams, $filter, $translate, Feature, ToastService) {
        var vm = this;

        vm.loadAll = loadAll;
        vm.loadingData = false;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.predicate = pagingParams.predicate;
        vm.transition = transition;
        vm.reOrder = reOrder;
        vm.illustartionEmptyScreenId = "anchor";

        vm.query = {
            filter: '',
            limit: paginationConstants.itemsPerPage,
            order: '-id',
            page: 1,
            filters: {
                folder: vm.defaultFolder,
                metaType: 'all',
                enabled: false,
                externalId: false
            }
        };

        vm.filter = {
            options: {
                debounce: 500
            }
        };

        vm.removeFilter = removeFilter;
        vm.filter.show = false;
        vm.deleteObject = deleteObject;
        vm.archiveObject = archiveObject;
        vm.editObject = editObject;
        vm.createObject = createObject;

        vm.permissions = {
            create: 'create-anchor',
            update: 'update-anchor',
            delete: 'delete-anchor',
            manage: 'manage-anchor'
        };

        vm.getMediaUrl = function(mediaUrl) {
            return $filter('mediaUrl')(mediaUrl);
        }

        vm.copyAnchor = function(contentId, contentType) {
            $state.go("storm.admin-content-detail", {
                'contentType': contentType.replace(/-/g, '_'),
                'id': contentId,
                'copy': true
            });
        }

        vm.onEnableChange = function(anchor) {
            Anchor.update(anchor, function() {
                ToastService.displayToast(anchor.name + ' ' + $filter('translate')('atheer.content.enableUpdateSuccess'), 3000);
            }, function() {
                ToastService.displayToast($filter('translate')('atheer.content.enableUpdateError'), 3000)
            });
        }

        function loadAll() {
            vm.loadingData = true;
            var savedQuery = getSavedFilters();
            if ( savedQuery ) {
                vm.query = savedQuery;
            }
            vm.page = vm.query.page;

            Anchor.query({
                page: vm.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort(),
                query: getQueryString(),
                is_external_id: vm.query.filters.externalId
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (pagingParams.ascending ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.anchors = data;
                //vm.page = pagingParams.page;
                vm.loadingData = false;
            }

            function onError(error) {
                AlertService.error(error.data.message);
                vm.loadingData = false;
            }
        }

        function reOrder(order) {
            vm.predicate = order;
            vm.transition();
        }

        function transition() {
            vm.promise = Anchor.query({
                page: vm.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: getSortOrder(),
                query: getQueryString(),
                is_external_id: vm.query.filters.externalId
            }).$promise;

            vm.promise.then(function(data, headers) {
                vm.queryCount = vm.totalItems;
                vm.anchors = data;
            });
            vm.query.page = vm.page;
            saveFilters(vm.query);
        }

        function getSortOrder() {
            if (vm.predicate.startsWith('-')) {
                return vm.predicate.substr(1) + ',' + 'desc';
            } else {
                return vm.predicate + ',' + 'asc';
            }

        }

        function getQueryString() {
            var anchorQuery = vm.query.filters.metaType == 'all' ? '' : ';' + vm.query.filters.metaType;

            if (vm.query.filters.enabled) {
                if (anchorQuery === "") {
                    anchorQuery = 'enable==true';
                } else {
                    anchorQuery = anchorQuery + ';enable==true';
                }
            }

            if (vm.query.filters.enabled) {
                if (vm.query.filter.length > 0) {
                    anchorQuery = anchorQuery + ";" + 'name=re=' + vm.query.filter;
                }
            } else {
                if (vm.query.filter.length > 0) {
                    anchorQuery = 'name=re=' + vm.query.filter;
                }
            }

            return anchorQuery;
        }

        function removeFilter() {
            vm.filter.show = false;
            vm.query.filter = '';
            resetModal();
        }

        function createObject() {
            $state.go("storm.admin-anchor-detail");
        }

        function editObject(anchorId) {
            $state.go("storm.admin-anchor-detail", {
                'id': anchorId
            });
        }

        function archiveObject(entity) {
            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.anchor.archiveAnchor'))
                .ariaLabel($filter('translate')('atheer.anchor.archiveAnchor'))
                .ok($filter('translate')('entity.action.archive'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function() {
                entity.status = 'ARCHIVED';

                Anchor.update(entity, function() {
                    resetModal();
                });
            });
        };

        function deleteObject(entity) {
            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.anchor.deleteAnchor'))
                .ariaLabel($filter('translate')('atheer.anchor.deleteAnchor'))
                .ok($filter('translate')('entity.action.delete'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function() {
                Anchor.delete({
                    id: entity.id
                }, function() {
                    resetModal();
                });
            });
        };

        function resetModal() {
            loadAll();
            AlertService.displayToast($mdToast);
        };

        vm.toggleFilter = function() {
            vm.filter.show = !vm.filter.show;
            if (vm.filter.show) {
                vm.showFolders = false;
            }
        }

        vm.seeInsights = function(anchorId) {
            $state.go("storm.admin-anchor-detail-insights.section", {
                'id': anchorId,
                'section': 'overview'
            });
        };

        var refreshAnchorListener = $rootScope.$on('refreshAnchors', function(event, filter) {
            vm.query.filter = filter;
            vm.query.page = 1;
            saveFilters(vm.query);
            vm.loadAll();
        });

        var updateAnchorListener = $rootScope.$on('updateSmartscanList', function(event, filters) {
            if (filters.externalId || filters.enabled) {
                vm.illustartionEmptyScreenId = "no_anchor";
            } else {
                vm.illustartionEmptyScreenId = "anchor";
            }
            vm.query.filters = filters;
            vm.query.page = 1;
            saveFilters(vm.query);
            vm.loadAll();
        });

        function saveFilters(filterData) {
            sessionStorage.setItem('objectFilters', JSON.stringify(filterData));
        };
    
        function getSavedFilters() {
            return JSON.parse(sessionStorage.getItem('objectFilters'));
        };

        // Unregister
        $scope.$on('$destroy', function() {
            refreshAnchorListener();
            updateAnchorListener();
        });

        vm.loadAll();
    }
})();
