(function () {
	"use strict";

	angular
		.module("atheer.smartFlow")
		.controller(
			"JobFlowInsightsOverviewController",
			JobFlowInsightsOverviewController
		);

	/* @ngInject */
	function JobFlowInsightsOverviewController(
		$scope,
		$filter,
		JobFlow,
		id,
		SmartFlow,
		$state,
		SmartFlowBuilder,
		Report,
		$mdDialog,
		name,
		WORFLOW_CELL_CONSTANT,
		paginationConstants
	) {
		var vm = this;
		vm.smartFlowModel = { id: id, name: name };
		vm.viewType = "insights";
		vm.loadingData = true;
		vm.runList = [];
		vm.query = {
			limit: paginationConstants.itemsPerPage,
			page: 1
		}
		vm.init = init;

		var query = "workflowId==" + id;
		var category = "";

		vm.goToSmartFlows = function () {
			$state.go("storm.admin-smartflows");
		};

		vm.openJobDetails = function (job) {
			$mdDialog
				.show({
					animation: true,
					templateUrl:
						"modules/smartflow/jobflow-insights-job-detail.tmpl.html",
					controller: "JobFlowInsightsJobDetailsController",
					controllerAs: "vm",
					backdrop: "static",
					size: "lg",
					data: job,
					resolve: {
						entity: function () {
							return {
								id: null,
								name: null,
								properties: [],
							};
						},
					},
				})
		}

		vm.openErrorDetails = function (data) {
			$mdDialog
				.show({
					animation: true,
					templateUrl:
						"modules/smartflow/jobflow-insights-error-detail.tmpl.html",
					controller: "JobFlowInsightsErrorDetailsController",
					controllerAs: "vm",
					backdrop: "static",
					size: "lg",
					data: data
				})
		}

		vm.openInputDetails = function (inputData, key) {
            $mdDialog
                .show({
                    animation: true,
                    templateUrl:
                        "modules/smartflow/jobflow-insights-input-detail.tmpl.html",
                    controller: "JobFlowInsightsInputDetailsController",
                    controllerAs: "vm",
                    backdrop: "static",
                    size: "lg",
                    data: inputData,
                    inputId: key
                });
		}

		function init() {
            category = SmartFlowBuilder.getSmartFlowCategory();
			JobFlow.query(
				{
					page: vm.query.page - 1,
					size: vm.query.limit,
					query: query,
					sort: 'startDate,desc'
				},
				function (result, headers) {
					vm.loadingData = false;
					vm.runList = result;
					vm.queryCount = headers("x-total-count");
					angular.forEach(vm.runList, function (run) {
						var job = []
						angular.forEach(run.outputs, function (output) {
							if (output.outputReference) {
								job.push(output.outputReference);
							}
						});

						var inputList = [];
						angular.forEach(run.inputs, function (inputData, key) {
							if (inputData.properties) {
								inputList.push({ key: key, value: inputData.properties });
							}
						});

						run["inputList"] = inputList;
						run["jobNumberList"] = job;
					})
				}
			);
		}

		init();
	}
})();
