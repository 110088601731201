(function () {
	'use strict';

	angular.module('atheer.job').controller('JobController', JobController);

	/* @ngInject */
	function JobController(
		$rootScope,
		$state,
		$scope,
		$sce,
		$cookies,
		BuildLanguages,
		$location,
		$window
	) {
		var vm = this;
		vm.ngxUrlTrusted = null;
		vm.isIFrameDataLoaded = false;

		function setNgxPageUrl() {
			var ngxUrl = null;
			var path = $location.path();
			var hostUrl =
				location.hostname === 'localhost'
					? 'https://' + location.hostname + ':4200'
					: location.origin;

			if ($state.current.name == 'storm.jobs') {
				ngxUrl = hostUrl + '/ngx/jobs';
			} else if ($state.current.name == 'storm.job-detail') {
				var id = path.split('/').pop();
				if ($location.search().action) {
					ngxUrl = hostUrl + '/ngx/jobs/' + id + '?action=' + $location.search().action;
				}else {
					ngxUrl = hostUrl + '/ngx/jobs/' + id;
				}
			} else if ($state.current.name == 'storm.job-execute') {
				var id = path.split('/').pop();
				ngxUrl = hostUrl + '/ngx/job/execute/' + id;
			} else if ($state.current.name == 'storm.new-job') {
				ngxUrl = hostUrl + '/ngx/jobs/new';
			} else if ($state.current.name == 'storm.edit-job') {
				var id = path.split('/').pop();
				ngxUrl = hostUrl + '/ngx/jobs/edit/' + id;
			} else {
				ngxUrl = ngxUrl = hostUrl + '/ngx/jobs';
			}

			vm.ngxUrlTrusted = $sce.trustAsResourceUrl(ngxUrl);
			$rootScope.selectedLeftNavMenu = 'jobs';
		}

		function handleNgxEvent(event) {
			var hostUrl =
				location.hostname === 'localhost'
					? 'https://' + location.hostname + ':4200'
					: location.origin;
			if (event.origin !== hostUrl) return;
			if (event.data.ngxLocationChanged) {
				var ngState = event.data.ngxLocationChanged.ngState;
				var id = event.data.ngxLocationChanged.id;
				vm.isIFrameDataLoaded = true;
				scopeApply();

				if (id == null) {
					$state.transitionTo(ngState, null, {
						location: true,
						inherit: true,
						relative: $state.$current,
						notify: false
					});
				} else {
					$state.transitionTo(
						ngState,
						{
							id: id
						},
						{
							location: true,
							inherit: true,
							relative: $state.$current,
							notify: false
						}
					);
				}
			}
		}

		var scopeApply = function (fn) {
			var phase = $scope.$root.$$phase;
			if (phase !== "$apply" && phase !== "$digest") {
				return $scope.$apply(fn);
			}
		};

		$window.addEventListener('message', handleNgxEvent, false);

		// Unregister
		$scope.$on('$destroy', function () {
			window.removeEventListener('message', handleNgxEvent, false);
		});

		setNgxPageUrl();
	}
})();
