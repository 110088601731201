'use strict';

angular.module('atheer')
    .provider('recorderService', ['recorderScriptUrl',
        function(scriptPath) {

            var handler = null,
                service = {
                    isHtml5: false,
                    isReady: false
                },
                permissionHandlers = {
                    onDenied: null,
                    onClosed: null,
                    onAllow: null
                },
                utils;

            var html5AudioProps = {
                audioContext: null,
                inputPoint: null,
                audioInput: null,
                audioRecorder: null,
                analyserNode: null
            };

            var html5HandlerConfig = {
                gotStream: function(stream) {
                    window.streamReference = stream;

                    var audioContext = html5AudioProps.audioContext;
                    // Create an AudioNode from the stream.
                    html5AudioProps.audioInput = audioContext.createMediaStreamSource(stream);
                    html5AudioProps.audioInput.connect((html5AudioProps.inputPoint = audioContext.createGain()));

                    //analyser
                    html5AudioProps.analyserNode = audioContext.createAnalyser();
                    html5AudioProps.analyserNode.fftSize = 2048;
                    html5AudioProps.inputPoint.connect(html5AudioProps.analyserNode);
                    html5AudioProps.audioRecorder = new Recorder(html5AudioProps.audioInput);

                    //create Gain
                    var zeroGain = audioContext.createGain();
                    zeroGain.gain.value = 0.0;
                    html5AudioProps.inputPoint.connect(zeroGain);
                    zeroGain.connect(audioContext.destination);

                    //service booted
                    service.isReady = true;
                    handler = html5AudioProps.audioRecorder;

                    if (angular.isFunction(permissionHandlers.onAllowed)) {
                        if (window.location.protocol == 'https:') {
                            //to store permission for https websites
                            localStorage.setItem("permission", "given");
                        }
                        permissionHandlers.onAllowed();
                    }

                },
                failStream: function(data) {
                    if (angular.isDefined(permissionHandlers.onDenied)) {
                        permissionHandlers.onDenied();
                    }
                },
                getPermission: function() {
                    navigator.getUserMedia({
                        "audio": true
                    }, html5HandlerConfig.gotStream, html5HandlerConfig.failStream);
                },
                init: function() {
                    service.isHtml5 = true;
                    var AudioContext = window.AudioContext || window.webkitAudioContext;
                    if (AudioContext && !html5AudioProps.audioContext) {
                        html5AudioProps.audioContext = new AudioContext();
                    }

                    if (localStorage.getItem("permission") !== null) {
                        //to get permission from browser cache for returning user
                        html5HandlerConfig.getPermission();
                    }
                }
            };

            navigator.getUserMedia = navigator.getUserMedia ||
                navigator.webkitGetUserMedia ||
                navigator.mozGetUserMedia;

            var init = function() {
                if (navigator.getUserMedia) {
                    html5HandlerConfig.init();
                }
            };

            var controllers = {};

            service.controller = function(id) {
                return controllers[id];
            };

            service.setController = function(id, controller) {
                controllers[id] = controller;
            };

            service.isAvailable = function() {
                return service.isHtml5;
            };

            service.getHandler = function() {
                return handler;
            };

            service.removeStream = function() {
                if (!window.streamReference) return;

                window.streamReference.getAudioTracks().forEach(function(track) {
                    track.stop();
                });

                window.streamReference.getVideoTracks().forEach(function(track) {
                    track.stop();
                });

                window.streamReference = null;
            };

            service.refresh = function() {
                return init();
            };

            service.showPermission = function(listeners) {
                if (!service.isAvailable()) {
                    console.warn("Neither HTML5 nor SWF is supported.");
                    return;
                }

                if (listeners) {
                    angular.extend(permissionHandlers, listeners);
                }

                if (service.isHtml5) {
                    html5HandlerConfig.getPermission();
                }
            };

            service.$html5AudioProps = html5AudioProps;

            var provider = {
                $get: ['recorderUtils',
                    function(recorderUtils) {
                        utils = recorderUtils;
                        init();
                        return service;
                    }
                ]
            };
            return provider;
        }
    ]);
