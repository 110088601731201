(function() {
    'use strict';

    angular
        .module('atheer.session')
        .controller('conversationMessageNotifier', conversationMessageNotifier);

    /* @ngInject */
    function conversationMessageNotifier($state, $filter, notification, $rootScope) {
        var vm = this;

        vm.loadingData = true;
        vm.notification = notification;
        vm.avatarData = {};
        vm.conversationMessage = "";

        vm.viewMessage = function() {
            $rootScope.selectedLeftNavMenu = 'conversations';
            $state.go('storm.conversations-detail', {
                'id': vm.notification.data.conversation_id
            });
        };

         // Function to truncate messages
        function truncateMessage(message, limit) {
            return message.length > limit ? message.substring(0, limit) + '...' : message;
        }

        vm.loadData = function() {
            vm.avatarData = {
                firstName: vm.notification.data.user_info.first_name,
                lastName: vm.notification.data.user_info.last_name,
                id: vm.notification.data.user_info.id,
                pictureId: vm.notification.data.user_info.picture_id
            };
            if ( vm.notification.data.content ) {
                switch(vm.notification.data.content.data.type.toLowerCase()){
                    case 'image':
                        vm.conversationMessage = $filter('translate')('notification.conversation.imageShared');
                        break;
                    case 'video':
                        vm.conversationMessage = $filter('translate')('notification.conversation.videoShared');
                        break;
                    case 'audio':
                        vm.conversationMessage = $filter('translate')('notification.conversation.audioShared');
                        break;
                    case 'text':
                        vm.conversationMessage = truncateMessage(vm.conversationMessage, 24);
                        break;
                    default:
                        vm.conversationMessage = $filter('translate')('notification.conversation.contentShared');
                }
            } else if ( vm.notification.data.type === 'AUDIO') {
                vm.conversationMessage = $filter('translate')('notification.conversation.audioShared');
            }
            else {
                vm.conversationMessage = truncateMessage(vm.notification.data.body, 24);
            }

            $rootScope.$broadcast('messageCount', 1);

            vm.loadingData = false;
        };

        vm.loadData();
    }
})();
