(function() {
    'use strict';

    var fpAlert = {
        template: '<div>' + '</div>',
        controller: fpAlertController
    };

    angular
        .module('atheer')
        .component('fpAlert', fpAlert);

    /* @ngInject */
    function fpAlertController($scope, AlertService, $mdToast, toastConstants) {
        var vm = this;
        vm.alerts = AlertService.get();

        for(var i=0, len= vm.alerts.length; i < len; i++){
          $mdToast.show({
              template: '<md-toast><span flex>' + vm.alerts[i].msg + '</span></md-toast>',
              position: toastConstants.position,
              hideDelay: 3000
          });
        }
        $scope.$on('$destroy', function () {
            vm.alerts = [];
        });
    }
})();
