(function() {
    'use strict';

    angular
        .module('atheer.user')
        .controller('ProfileLibraryBookmarksController', ProfileLibraryBookmarksController);

    /* @ngInject */
    function ProfileLibraryBookmarksController($rootScope, $scope, $state, $mdDialog, Principal, $translate, pagingParams, paginationConstants, $mdToast, $filter, Experience, CONTENT_META, $mdMedia, ContentAction, $window, id) {
        var vm = this;

        vm.error = null;
        vm.success = null;
        vm.user = null;
        vm.viewType = 'card';
        vm.loadingData = true;
        vm.userId = id == 'me' ? Principal.getUserId() : id;
        vm.contents = [];

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;

        vm.query = {
            filter: '',
            limit: paginationConstants.itemsPerPage,
            order: '-id',
            page: 1
        };

        vm.getMediaUrl = function(mediaUrl){
            return $filter('mediaUrl')(mediaUrl);
        };

        vm.getCardProperty = function(type) {
            var contentMetaData = $filter('filter')(CONTENT_META, {
                type: type.replace(/_/g, '-')
            })[0];
            return contentMetaData.card_properties;
        };

        vm.getDefaultBackground = function(type) {
            var card_properties = vm.getCardProperty(type);
            return card_properties != undefined ? card_properties.defaultBackground : '';
        };

        vm.getDefaultIcon = function(type) {
            var card_properties = vm.getCardProperty(type);
            return card_properties != undefined ? card_properties.defaultIcon : '';
        };

        vm.openPlayer = function(feed, isSkill) {
            ContentAction.launchContent(feed);
        };

        vm.openRecommendationModal = function(feed){
            ContentAction.openRecommendDialog(feed);
        };

        vm.openFeedbackDialog = function(reactionType, feed) {
            if (reactionType == "FLAG") {
                ContentAction.openFlagDialog(content);
            }
            else if (reactionType == "PROBLEM"){
                ContentAction.openProblemDialog(content);
            }
        };

        vm.setOtherRelativeData = function(content){
            vm.currentContentPinnedData = undefined;
            vm.currentContentLikeData = undefined;
            vm.currentContent = content;

            ContentAction.getPinnedData(content).then(function(value){
                vm.currentContentPinnedData = value;
            });

            ContentAction.getLikeData(content).then(function(value){
                vm.currentContentLikeData = value;
            });
        };

        vm.isPinned = function(content) {
            return content == vm.currentContent && vm.currentContentPinnedData ? true : false;
        };

        vm.isLiked = function(content) {
            return content == vm.currentContent && vm.currentContentLikeData ? true : false;
        };

        vm.likeOrUnlikeContent = function(content){
            if(content == vm.currentContent)
            {
                ContentAction.likeOrUnlikeContent(content, vm.currentContentLikeData).then(function(value){
                    vm.currentContentLikeData = value;
                })
            }
        };

        vm.pinOrUnpinContent = function(content){
            if(content == vm.currentContent)
            {
                ContentAction.pinOrUnpinContent(content, vm.currentContentPinnedData).then(function(value){
                    vm.currentContentPinnedData = value;
                })
            }
        };

        vm.changeFilter = function(filter) {
            vm.statusFilter = filter;
            vm.loadData();
        };

        vm.loadData = function() {
            vm.loadingData = true;
            //fetch pins data
            Experience.getBookmarks({
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort(),
                query: getQueryString()
            }, function(response, headers) {
                vm.bookmarks = response;
                vm.page = pagingParams.page;
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                prepareContents();
                vm.loadingData = false;
            });
        };

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        };

        function getQueryString() {
            var queryString = 'user_id==' + vm.userId;
            return queryString;
        };

        function prepareContents() {
            vm.contents = [];
            angular.forEach(vm.bookmarks, function(bookmark) {
                vm.contents.push(bookmark.object);
            })
        }

        vm.getCardMeta = function(card) {
            var contentMetaData = $filter('filter')(CONTENT_META, {
                type: card.data.type.replace(/_/g, '-')
            })[0];
            return contentMetaData;
        };

        vm.launchContent = function(contentFeed) {
            ContentAction.launchContent(contentFeed);
        };

        vm.transition = function() {
            vm.promise = Experience.getBookmarks({
                page: vm.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort(),
                query: getQueryString()
            }).$promise;

            vm.promise.then(function(response, headers) {
                vm.bookmarks = response;
                vm.queryCount = vm.totalItems;
                prepareContents();
            });
        };

        vm.setDirection = function(direction) {
            vm.direction = direction;
        };

        $scope.$on('$destroy', function() {
            $rootScope.$$listeners['content-player-closed'] = [];
        });

        $rootScope.$on('content-player-closed', function() {
            vm.loadData();
        });

        vm.back = function() {
            $window.history.back();
        };

        vm.loadData();
    }
})();
