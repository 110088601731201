(function () {
    'use strict';

    angular
        .module('atheer.topic')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider, CONTENT_INSIGHT_META, TOPIC_RECOGNITION_META, TOPIC_INSIGHT_META) {
        $stateProvider
            .state('storm.topic-list', {
                url: '/topics/list/view',
                views: {
                    '@storm': {
                        templateUrl: 'modules/ngx-frame.html',
                        controller: 'TopicViewController',
                        controllerAs: 'vm',
                    }
                },
                data: {
                    pageTitle: 'atheer.topic.home.title',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showSideNavMenu: false,
                        sideNavMenuPosition: 'left',
                        showToolbar: false,
                        sideNavMenuSize: 15
                    }
                }
            })
            .state('storm.topic-view', {
                url: '/topics/view/:id',
                views: {
                    '@storm': {
                        templateUrl: 'modules/ngx-frame.html',
                        controller: 'TopicViewController',
                        controllerAs: 'vm',
                    }
                },
                data: {
                    pageTitle: 'atheer.topic.home.title',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showSideNavMenu: false,
                        sideNavMenuPosition: 'left',
                        showToolbar: false,
                        sideNavMenuSize: 15
                    }
                }
            })
            .state('storm.admin-topics', {
                url: '/topics?page&sort&search',
                views: {
                    '@storm': {
                        templateUrl: 'modules/topic/topics.html',
                        controller: 'TopicController',
                        controllerAs: 'vm',
                    },
                    'toolbar@storm': {
                        templateUrl: 'modules/topic/topic-toolbar.tmpl.html',
                        controller: 'TopicToolbarController',
                        controllerAs: 'vm'
                    },
                    'sideMenu@storm': {
                        templateUrl: 'modules/topic/topic-menu.tmpl.html',
                        controller: 'TopicMenuController',
                        controllerAs: 'vm',
                    }
                },
                data: {
                    pageTitle: 'atheer.topic.home.title',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showSideNavMenu: true,
                        sideNavMenuPosition: 'left',
                        showToolbar: false,
                        sideNavMenuSize: 15
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }]
                }
            })
            .state('storm.admin-topic-fields', {
                url: '/topics/meta-data',
                templateUrl: 'modules/topic/topic-metadata.tmpl.html',
                // set the controller to load for this page
                controller: 'TopicMetaDataController',
                controllerAs: 'vm',
                // layout-column class added to make footer move to
                // bottom of the page on short pages
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false
                    }
                },
                resolve: {
                    id: function ($stateParams) {
                        return 'topic';
                    }
                }
            })
            .state('storm.admin-topic-detail', {
                url: '/topics/:id',
                templateUrl: 'modules/topic/topic-detail.tmpl.html',
                controller: 'TopicDetailController',
                controllerAs: 'vm',
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null,
                    topicType: null,
                    parentTopic: [],
                    isDeeplink: false
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    id: function ($stateParams) {
                        return $stateParams.id;
                    }
                },
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false
                    }
                }
            })
            .state('storm.admin-topic-detail-recognition', {
                url: '/topics/:id/recognition',
                templateUrl: 'modules/topic/topic-detail-recoginition.tmpl.html',
                controller: 'TopicDetailRecognitionController as vm',
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    id: function ($stateParams) {
                        return $stateParams.id;
                    }
                }
            })
            .state('storm.admin-topic-detail-recognition.section', {
                url: '/:section',
                templateUrl: function ($stateParams) {
                    return TOPIC_RECOGNITION_META['topic'].sections[$stateParams.section || 'overview'].template;
                },
                controllerProvider: function ($stateParams) {
                    return TOPIC_RECOGNITION_META['topic'].sections[$stateParams.section || 'overview'].controller;
                },
                controllerAs: 'vm',
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    id: function ($stateParams) {
                        return $stateParams.id;
                    },
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }]
                }
            })
            .state('storm.admin-topic-detail-insights', {
                url: '/topics/:id/insights',
                templateUrl: 'modules/topic/topic-detail-insights.tmpl.html',
                controller: 'TopicDetailInsightsController as vm',
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    id: function ($stateParams) {
                        return $stateParams.id;
                    }
                }
            })
            .state('storm.admin-topic-detail-insights.section', {
                url: '/:section',
                templateUrl: function ($stateParams) {
                    return TOPIC_INSIGHT_META['topic'].sections[$stateParams.section || 'overview'].template;
                },
                controllerProvider: function ($stateParams) {
                    return TOPIC_INSIGHT_META['topic'].sections[$stateParams.section || 'overview'].controller;
                },
                controllerAs: 'vm',
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    id: function ($stateParams) {
                        return $stateParams.id;
                    },
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }]
                }
            })
            ;
    }
})();
