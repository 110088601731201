(function() {
    'use strict';

    angular
        .module('atheer.system')
        .controller('AuthenticationLensSetupController', AuthenticationLensSetupController);

    /* @ngInject */
    function AuthenticationLensSetupController($scope, $state, $q, Setting, $filter, $mdDialog, ToastService, PermissionService, Feature) {
        var vm = this;

        vm.loadingData = false;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.loginSettings = [];
        vm.lensEnableAtheerLogin = true;
        vm.lensEnableForgotPassword = false;
        vm.lensGoogleSSO = false;
        vm.lensTwitterSSO = false;
        vm.lensLinkedInSSO = false;
        vm.lensFacebookSSO = false;
        vm.lensSamlSSO = false;
        vm.lensAzureAdSSO = false;
        vm.oidcSSO = false;
        vm.lensAutoSignoutEnabled = false;
        vm.lensAutoSignoutWaitMins = 30;
        vm.lensAutoSignoutWarning = false;
        vm.hasPermission = PermissionService.hasAccess('manage-sso-system');
        vm.isFeatureActive = Feature.isActive('feature_system_sso');

        vm.loadData = function() {
            vm.loadingData = true;

            $q.all([
                    Setting.getAuthenticationSetupSettings().$promise
                ])
                .then(function(results) {
                    setLoginFields(results[0]);
                    vm.loadingData = false;
                });
        };

        function setLoginFields(settings) {
            angular.forEach(settings, function(setting) {
                if (setting.category == 'authentication-setup') {
                    vm.loginSettings = setting;
                    angular.forEach(setting.fields, function(settingField) {
                        if (settingField.name == 'lens_google_sso') {
                            vm.lensGoogleSSO = (settingField.value == 'true' ? true : false);
                        } else if (settingField.name == 'lens_facebook_sso') {
                            vm.lensFacebookSSO = (settingField.value == 'true' ? true : false);
                        } else if (settingField.name == 'lens_linkedin_sso') {
                            vm.lensLinkedInSSO = (settingField.value == 'true' ? true : false);
                        } else if (settingField.name == 'lens_twitter_sso') {
                            vm.lensTwitterSSO = (settingField.value == 'true' ? true : false);
                        } else if (settingField.name == 'lens_saml_sso') {
                            vm.lensSamlSSO = (settingField.value == 'true' ? true : false);
                        } else if (settingField.name == 'lens_azureAD_sso') {
                            vm.lensAzureAdSSO = (settingField.value == 'true' ? true : false);
                        } else if (settingField.name == 'lens_oidc_sso') {
                            vm.oidcSSO = (settingField.value == 'true' ? true : false);
                        } else if (settingField.name == 'lens_forgot_password') {
                            vm.lensEnableForgotPassword = (settingField.value == 'true' ? true : false);
                        } else if (settingField.name == 'lens_atheer_login') {
                            vm.lensEnableAtheerLogin = (settingField.value == 'true' ? true : false);
                        } else if (settingField.name == 'lens_auto_signout_enabled') {
                            vm.lensAutoSignoutEnabled = (settingField.value == 'true' ? true : false);
                        } else if (settingField.name == 'lens_auto_signout_wait_time_mins') {
                            vm.lensAutoSignoutWaitMins = parseInt(settingField.value);
                        } else if (settingField.name == 'lens_auto_signout_show_warning') {
                            vm.lensAutoSignoutWarning = (settingField.value == 'true' ? true : false);
                        }
                    });
                }
            });
        }

        function setLoginSettings() {
            if(vm.loginSettings.fields == undefined) {
                vm.loginSettings.fields = [];
            };

            setSettingField("lens_atheer_login", (vm.lensEnableAtheerLogin ? 'true' : 'false'));
            setSettingField("lens_forgot_password", (vm.lensEnableForgotPassword ? 'true' : 'false'));
            setSettingField("lens_google_sso", (vm.lensGoogleSSO ? 'true' : 'false'));
            setSettingField("lens_facebook_sso", (vm.lensFacebookSSO ? 'true' : 'false'));
            setSettingField("lens_linkedin_sso", (vm.lensLinkedInSSO ? 'true' : 'false'));
            setSettingField("lens_twitter_sso", (vm.lensTwitterSSO ? 'true' : 'false'));
            setSettingField("lens_saml_sso", (vm.lensSamlSSO ? 'true' : 'false'));
            setSettingField("lens_azureAD_sso", (vm.lensAzureAdSSO ? 'true' : 'false'));
            setSettingField("lens_oidc_sso", (vm.oidcSSO ? 'true' : 'false'));
            setSettingField("lens_auto_signout_enabled", (vm.lensAutoSignoutEnabled ? 'true' : 'false'));
            setSettingField("lens_auto_signout_wait_time_mins", vm.lensAutoSignoutWaitMins + '');
            setSettingField("lens_auto_signout_show_warning", (vm.lensAutoSignoutWarning ? 'true' : 'false'));
        };

        function setSettingField(fieldName, fieldValue) {
            var foundField = false;

            angular.forEach(vm.loginSettings.fields, function(settingField) {
                if (settingField.name == fieldName) {
                    settingField.value = fieldValue;
                    foundField = true;
                }
            });

            if (!foundField) {
                var settingField = {
                    name: fieldName,
                    value: fieldValue,
                }
                vm.loginSettings.fields.push(settingField);
            }
        };

        vm.save = function() {
            setLoginSettings();

            Setting.update(vm.loginSettings, function() {
                ToastService.displayToast($filter('triTranslate')("atheer.setting.success"));
            });
        };

        vm.loadData();
    }
})();
