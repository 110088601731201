(function () {
    "use strict";

    angular.module("atheer.datamodel").config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider, CONTENT_INSIGHT_META) {
        $stateProvider.state("storm.admin-datamodel", {
            url: "/datamodels?page&sort&search",
            templateUrl: "modules/smartflow/datamodel/datamodel.html",
            controller: "DataModelController",
            controllerAs: "vm",
            data: {
                pageTitle: "atheer.datamodel.home.title",
                layout: {
                    contentClass: "layout-column",
                    footer: false,
                    showToolbar: false,
                },
            },
            params: {
                page: {
                    value: "1",
                    squash: true,
                },
                sort: {
                    value: "id,asc",
                    squash: true,
                },
                search: null,
            },
            resolve: {
                pagingParams: [
                    "$stateParams",
                    "PaginationUtil",
                    function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate(
                                $stateParams.sort
                            ),
                            ascending: PaginationUtil.parseAscending(
                                $stateParams.sort
                            ),
                            search: $stateParams.search,
                        };
                    },
                ]
            },
        });
    }
})();
