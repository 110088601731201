(function () {
    'use strict';

    angular
        .module('atheer.user')
        .controller('UserSignupController', UserSignupController);

    /* @ngInject */
    function UserSignupController($scope, $state, $window, $sce, $location, Feature) {
        var vm = this;
        vm.ngxUrlTrusted = null;
        vm.isIFrameDataLoaded = false;

        function setNgxPageUrl() {
            var ngxUrl = null;
            var hostUrl =
                location.hostname === "localhost"
                    ? "https://" + location.hostname + ":4200"
                    : location.origin;
            var path = $location.path();

            if ($state.current.name == "account.signup") {
                if(!Feature.isActive('feature_user_signup')) {
                    $state.go('account.signin');
                }
                ngxUrl = hostUrl + "/ngx/signup";
            } else if ($state.current.name == "account.signup-confirmed") {
                var id = path.split("/").pop();
                ngxUrl = hostUrl + "/ngx/signup/" + id;
            }
            vm.ngxUrlTrusted = $sce.trustAsResourceUrl(ngxUrl);
        }

        function handleNgxEvent(event) {
            var hostUrl =
                location.hostname === "localhost"
                    ? "https://" + location.hostname + ":4200"
                    : location.origin;
            if (event.origin !== hostUrl) return;
            if (event.data.ngxLocationChanged) {
                var ngState = event.data.ngxLocationChanged.ngState;
                var id = event.data.ngxLocationChanged.id;
                var queryParams = event.data.ngxLocationChanged.queryParams;
                id = id ? id : queryParams;
                vm.isIFrameDataLoaded = true;
                scopeApply();

                if (id == null && queryParams == null) {
                    $state.transitionTo(ngState, null, {
                        location: true,
                        inherit: true,
                        relative: $state.$current,
                        notify: false
                    });
                } else {
                    $state.transitionTo(
                        ngState,
                        {
                            id: id
                        },
                        {
                            location: true,
                            inherit: true,
                            relative: $state.$current,
                            notify: false
                        }
                    );
                }
            }
        }

        var scopeApply = function (fn) {
            var phase = $scope.$root.$$phase;
            if (phase !== "$apply" && phase !== "$digest") {
                return $scope.$apply(fn);
            }
        };

        $window.addEventListener("message", handleNgxEvent, false);

        // Unregister
        $scope.$on("$destroy", function () {
            window.removeEventListener("message", handleNgxEvent, false);
        });

        setNgxPageUrl();

    }
})();
