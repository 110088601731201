(function() {
    'use strict';

    angular
        .module('atheer.content')
        .controller('ContentFeedbackController', ContentFeedbackController);

    /* @ngInject */
    function ContentFeedbackController($scope, $state, $mdDialog, $filter, content, type, Experience,Principal, ToastService) {
        var vm = this;
        vm.type = type;

        vm.contentFeedbackModel = {
            object: content,
            comment: '',
            user_id: Principal.getUserId()
        }

        vm.close = function() {
            $mdDialog.hide();
        }

        vm.submit = function() {
            if (type == "FLAG") {
                Experience.raiseFlag(vm.contentFeedbackModel, function(result) {
                    vm.flagData = result;

                    ToastService.displayToast($filter('triTranslate')('atheer.content.receivedYourFeedback'), 3000);
                    vm.close();
                });
            } else if (type == "PROBLEM") {
                Experience.reportProblem(vm.contentFeedbackModel, function(result) {
                    vm.flagData = result;

                    ToastService.displayToast($filter('triTranslate')('atheer.content.receivedYourFeedback'), 3000);
                    vm.close();
                });
            }
        }
    }
})();
