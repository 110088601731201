(function() {

    'use strict';
    angular
        .module('atheer.topic')
        .constant('TOPIC_RECOGNITION_META', {
            topic: {
                sections: {
                    qr_code: {
                        label: 'atheer.topic.qrCode',
                        icon: 'zmdi zmdi-account',
                        controller: 'TopicDetailQRCodeController',
                        template: 'modules/topic/topic-detail-qrcode.tmpl.html'
                    }
                }
            }
        });
})();
