(function() {
    'use strict';

    angular
        .module('atheer')
        .directive('fpNotificationCount', fpNotificationCount);

    function fpNotificationCount() {
        var directive = {
            controller: notificationCountController,
            restrict: 'EA',
            templateUrl: "core/components/notification-count/notification-count-directive.tmpl.html"
        };
        return directive;
    }

    /* @ngInject */
    function notificationCountController($scope, $q, $timeout, $mdToast, Foryou) {
        $scope.notificationCount = 0;

        function init() {
            $q.all([
                Foryou.get().$promise
            ]).then(function(data) {
                //getting assignment data
                $scope.notificationCount = data[0].todos_count;

                if(data[0].questions && data[0].questions.length > 0) {
                    $scope.notificationCount = $scope.notificationCount + data[0].questions.length;
                };
            }).catch(function(data) {}).finally(function() {});
        }
        init();
    }
})();
