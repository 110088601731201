(function() {
    'use strict';

    angular
        .module('atheer.conversation')
        .controller('ConversationMessageReactionPanelController', ConversationMessageReactionPanelController);

    /* @ngInject */
    function ConversationMessageReactionPanelController(mdPanelRef, $scope, $state, $location, $mdDialog, $q, $filter, $mdToast, AlertService, ConversationModelService, Principal, Message, $mdPanel) {
        var vm = this;

        function handleEmoji(event) {
            if (event.data.emojiSelected) {
                mdPanelRef.emoji = event.data.emoji;
                mdPanelRef.close();
            }
        };

        window.addEventListener('message', handleEmoji, false);

        mdPanelRef.listenerFn = handleEmoji;

        // Unregister
        $scope.$on('$destroy', function() {
            window.removeEventListener('message', handleEmoji, false);
        });
    }
})();
