(function() {
    'use strict';

    angular
        .module('atheer.core')
        .provider('triSkins', skinsProvider)
        .run(addSkinToScope);

    /* @ngInject */
    function skinsProvider($mdThemingProvider, triThemingProvider) {
        var skins = {};
        var currentSkin = null;
        var useSkinCookie = false;

        return {
            skin: function(id, name) {
                if (angular.isDefined(skins[id])) {
                    return skins[id];
                }
                var skin = new Skin(id, name, $mdThemingProvider, triThemingProvider);
                skins[id] = skin;
                return skins[id];
            },
            setSkin: function(id) {
                if (angular.isUndefined(skins[id])) {
                    return;
                }

                // set skin to selected skin
                currentSkin = skins[id];

                // make material load the themes needed for the skin
                currentSkin.loadThemes();

                return currentSkin;
            },
            useSkinCookie: function(skinCookie) {
                useSkinCookie = skinCookie;
            },
            $get: function() {
                return {
                    registerSkin: function(id, name) {
                        if (angular.isDefined(skins[id])) {
                            return skins[id];
                        }
                        var skin = new Skin(id, name, $mdThemingProvider, triThemingProvider);
                        skins[id] = skin;
                        return skins[id];
                    },
                    setSkin: function(id) {
                        if (angular.isUndefined(skins[id])) {
                            return;
                        }
                        // set skin to selected skin
                        currentSkin = skins[id];
                        // make material load the themes needed for the skin
                        currentSkin.loadThemes();
                        return currentSkin;
                    },
                    getCurrent: function() {
                        return currentSkin;
                    },
                    getSkins: function() {
                        return skins;
                    }
                };
            }
        };
    }

    /* @ngInject */
    function Skin(id, name, $mdThemingProvider, triThemingProvider) {
        var THEMABLE_ELEMENTS = ['sidebar', 'toolbar', 'content'];
        var self = this;
        self.id = id;
        self.name = name;
        self.elements = {};

        THEMABLE_ELEMENTS.forEach(function(element) {
            self[element + 'Theme'] = function setElementTheme(themeName) {
                self.elements[element] = themeName;
                return self;
            };
        });

        self.loadThemes = function() {
            // go through each element
            for (var element in self.elements) {
                // register theme with mdThemingProvider (will load css in the header)
                var theme = triThemingProvider.theme(self.elements[element]);

                $mdThemingProvider.theme(theme.name)
                    .primaryPalette(theme.colors.primary.name, theme.colors.primary.hues)
                    .accentPalette(theme.colors.accent.name, {
                        'default': '500',
                    })
                    .warnPalette(theme.colors.warn.name, theme.colors.warn.hues);

                if (angular.isDefined(theme.colors.background)) {
                    $mdThemingProvider
                        .theme(theme.name)
                        .backgroundPalette(theme.colors.background.name, theme.colors.background.hues);
                }
            }

            $mdThemingProvider.setDefaultTheme(self.elements.content);
        };
    }

    /* @ngInject */
    function addSkinToScope($rootScope, triSkins) {
        $rootScope.triSkin = triSkins.getCurrent();
    }
})();
