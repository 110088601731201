(function() {
    'use strict';

    angular
        .module('atheer.system')
        .config(stateConfig);


    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('storm.admin-setting.authentication', {
                abstract: true,
                url: '/authentication',
                views: {
                    '@storm': {
                        templateUrl: 'modules/system/authentication/authentication.tmpl.html',
                        controller: 'AuthenticationController',
                        controllerAs: 'vm',
                    }
                },
                data: {
                    pageTitle: 'atheer.setting.authentication',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: false
                    }
                }
            })
            .state('storm.admin-setting.authentication.studio', {
                url: '/studio-setup',
                controller: 'AuthenticationStudioSetupController',
                controllerAs: 'vm',
                templateUrl: 'modules/system/authentication/authentication-studio-setup.tmpl.html',
                data: {
                    pageTitle: 'atheer.setting.authentication',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: false
                    }
                }
            })
            .state('storm.admin-setting.authentication.lens', {
                url: '/lens-setup',
                controller: 'AuthenticationLensSetupController',
                controllerAs: 'vm',
                templateUrl: 'modules/system/authentication/authentication-lens-setup.tmpl.html',
                data: {
                    pageTitle: 'atheer.setting.authentication',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: false
                    }
                }
            })
            .state('storm.admin-setting.authentication.saml', {
                url: '/saml',
                controller: 'AuthenticationSamlController',
                controllerAs: 'vm',
                templateUrl: 'modules/system/authentication/authentication-saml.tmpl.html',
                data: {
                    pageTitle: 'atheer.setting.authentication',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: false
                    }
                }
            })
            .state('storm.admin-setting.authentication.google', {
                url: '/google',
                controller: 'AuthenticationGoogleController',
                controllerAs: 'vm',
                templateUrl: 'modules/system/authentication/authentication-google.tmpl.html',
                data: {
                    pageTitle: 'atheer.setting.authentication',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: false
                    }
                }
            })
            .state('storm.admin-setting.authentication.azureAD', {
                url: '/azure-ad',
                controller: 'AuthenticationAzureADController',
                controllerAs: 'vm',
                templateUrl: 'modules/system/authentication/authentication-azure-ad.tmpl.html',
                data: {
                    pageTitle: 'atheer.setting.authentication',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: false
                    }
                }
            })
            .state('storm.admin-setting.authentication.oidc', {
                url: '/oidc',
                controller: 'AuthenticationOIDCController',
                controllerAs: 'vm',
                templateUrl: 'modules/system/authentication/authentication-oidc.tmpl.html',
                data: {
                    pageTitle: 'atheer.setting.authentication',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: false
                    }
                }
            });
    }
})();
