(function() {
    'use strict';

    angular
        .module('atheer.insights')
        .controller('InsightsSessionToolbarController', InsightsSessionToolbarController);

    /* @ngInject */
    function InsightsSessionToolbarController($scope, $state, Setting, AlertService, $mdDialog, $mdToast, $stateParams, Principal, Feature, PermissionService) {
        var vm = this;

        var lessThan3Day = new Date();
        lessThan3Day.setDate(lessThan3Day.getDate() - 2);
        vm.illustartionEmptyScreenId = "insight_graph";

        vm.customTemplates = [{
            name: 'Last 3 Days',
            dateStart: lessThan3Day,
            dateEnd: new Date(),
        }];

        vm.pickerModel = {
            selectedTemplate: 'Last 3 Days'
        };

        vm.loadingData = true;

        vm.loadAll = loadAll;
        function loadAll() {
            vm.loadingData = false;
        };

        vm.toggleSideMenu = function() {
            $scope.$emit('toggleSideMenu', 'toggle');
        };

        vm.emailSessionSummaryReport = function() {
            $mdDialog.show({
                templateUrl: 'modules/session/session-summary-report.tmpl.html',
                clickOutsideToClose: true,
                controller: "sessionSummaryReportController",
                controllerAs: "vm",
            }).then(function(result) {

            });
        }
        vm.loadAll();
    }
})();
