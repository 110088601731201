(function() {
    'use strict';

    angular
        .module('atheer.core')
        .config(translateConfig);

    /* @ngInject */
    function translateConfig(triSettingsProvider, triRouteProvider) {
        var now = new Date();
        // set app name & logo (used in loader, sidemenu, footer, login pages, etc)
        triSettingsProvider.setName('Atheer');
        triSettingsProvider.setCopyright('&copy;' + now.getFullYear() + ' Atheer Inc.');
        triSettingsProvider.setLogoBig('assets/images/ath-color.png');
        triSettingsProvider.setLogoSmall('assets/images/ath-sm-white.png');
        // set current version of app (shown in footer)
        triSettingsProvider.setVersion('1.0.0');
        // set the document title that appears on the browser tab
        triRouteProvider.setTitle('Atheer');
        triRouteProvider.setSeparator('|');
    }
})();
