(function() {
    'use strict';

    angular
        .module('atheer.user')
        .controller('UserToolbarController', UserToolbarController);

    /* @ngInject */
    function UserToolbarController($rootScope, $scope, $state, $mdDialog, PermissionService, User, Team, ParseLinks, Experience, AlertService, pagingParams, paginationConstants, $mdToast, $stateParams, $filter, $translate, Feature, ToastService) {
        var vm = this;

        vm.refreshAll = refreshAll;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.selectedUsers = [];
        vm.selectedTeam = null;
        vm.showNewUserMenu = false;

        vm.permissions = {
            create: 'create-user',
            update: 'update-user',
            delete: 'delete-user',
            manage: 'manage-user',
            import: 'import-user',
            invite: 'manage-invitation-user',
            team: 'manage-segment-user',
            user_field: 'manage-user-field-user'
        };

        vm.query = {
            filter: ''
        };

        vm.filter = {
            options: {
                debounce: 500
            }
        };

        if ( getSavedFilters() ) {
            vm.query = getSavedFilters();
        }

        vm.removeFilter = removeFilter;
        vm.filter.show = vm.query.filter.length > 0 ? true : false;
        vm.createObject = createObject;

        vm.toggleFilter = function() {
            vm.filter.show = !vm.filter.show;
        };

        vm.selectMenu = function(item) {
            $state.go('storm.admin-content-detail', {
                'contentType': item.type.replace(/-/g, '_')
            });
        };

        vm.clearSelection = function() {
            vm.selectedUsers = [];
            $scope.$emit('refreshSelectedCardsListing', vm.selectedUsers);
        };

        vm.createObject = function() {
            $mdDialog.show({
                animation: true,
                templateUrl: 'modules/user/user-detail-dialog.html',
                controller: 'UserDetailDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: function() {
                        return {
                            id: null,
                            login: null,
                            password: null,
                            first_name: null,
                            last_name: null,
                            email: null,
                            invite_user: false,
                            activated: true,
                            language_id: null,
                            timezone_id: null,
                            created_by: null,
                            created_date: null,
                            last_modified_by: null,
                            last_modified_on: null
                        };
                    }
                }
            }).then(function() {
                $scope.$emit('refreshUsers', vm.query.filter);
            });
        };

        vm.openCreateDialog = function() {
            $mdDialog.show({
                templateUrl: 'modules/team/team-detail-dialog.tmpl.html',
                clickOutsideToClose: true,
                controller: "TeamDetailDialogController",
                controllerAs: "vm",
                locals: {
                    mode: 'STATIC',
                    team: null
                },
                size: 'lg'
            }).then(function(updatedTeam) {
                vm.saveTeam(updatedTeam);
            });
        };

        vm.onTeamSaveSuccess = function (result) {
            var userTeams = [];
            angular.forEach(vm.selectedUsers, function (user) {
                userTeams.push({
                    user_id: user.id,
                    object: result,
                    source: 'ADMIN',
                    assigned_on: new Date()
                });
            });

            ToastService.displayToast(result.name + $filter('translate')('atheer.userManagement.create'), 3000);

            Experience.bulkTeams(userTeams, function (result) {
                $scope.$emit('refreshTeams');
            });
        };

        vm.onTeamUpdateSuccess = function(result) {
            var userTeams = [];
            angular.forEach(vm.selectedUsers, function(user) {
                userTeams.push({
                    user_id: user.id,
                    object: result,
                    source: 'ADMIN',
                    assigned_on: new Date()
                });
            });

            ToastService.displayToast(result.name + $filter('translate')('atheer.userManagement.update'), 3000);

            Experience.bulkTeams(userTeams, function(result) {
                $scope.$emit('refreshTeams');
            });
        };

        vm.onTeamSaveError = function() {

        };

        vm.saveTeam = function(updatedTeam) {
            if (updatedTeam.id) {
                Team.update(updatedTeam, vm.onTeamUpdateSuccess, vm.onTeamSaveError);
            } else {
                updatedTeam.audience_no = null;
                updatedTeam.type = 'STATIC';
                Team.save(updatedTeam, vm.onTeamSaveSuccess, vm.onTeamSaveError);
            }
        };

        vm.removeUsersFromList = function() {
            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.userManagement.removeUsers') + vm.selectedUsers.length + $filter('translate')('atheer.userManagement.usersFrom') + vm.selectedTeam.name + $filter('translate')('atheer.userManagement.teams'))
                .ariaLabel($filter('translate')('atheer.userManagement.removeUsers') + vm.selectedUsers.length + $filter('translate')('atheer.userManagement.usersFrom') + vm.selectedTeam.name + $filter('translate')('atheer.userManagement.teams'))
                .ok($filter('translate')('entity.action.remove'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function() {
                var userTeams = [];
                angular.forEach(vm.selectedUsers, function(user) {
                    userTeams.push({
                        user_id: user.id,
                        object: vm.selectedTeam,
                        source: 'ADMIN',
                        assigned_on: new Date()
                    });
                });

                Experience.bulkTeamsRemove(userTeams, function(result) {
                    $scope.$emit('refreshUsers', vm.query.filter);
                });
            });
        };

        vm.removeBulkUsers = function() {
            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.userManagement.removeUsers') + vm.selectedUsers.length + " " + $filter('translate')('atheer.userManagement.home.title') + " ?")
                .ariaLabel($filter('translate')('atheer.userManagement.removeUsers') + vm.selectedUsers.length + " " + $filter('translate')('atheer.userManagement.home.title') + " ?")
                .ok($filter('translate')('entity.action.delete'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function() {
                var usersToRemove = [];
                angular.forEach(vm.selectedUsers, function(user) {
                    usersToRemove.push(user.login);
                });

                User.bulkRemove(usersToRemove, function(result) {
                    ToastService.displayToast(vm.selectedUsers.length + $filter('translate')('atheer.userManagement.usersDeleted'), 3000);
                    $scope.$emit('refreshUsers', vm.query.filter);
                });
            });
        };

        vm.init = function() {
            vm.showNewUserMenu = PermissionService.hasPermission(vm.permissions.create) && (PermissionService.hasPermission(vm.permissions.import) || (Feature.isActive('feature_user_invitation') && PermissionService.hasPermission(vm.permissions.invite)))
        };

        //calls when hit menu on sidebar to open sub menus in side bar
        var updateUserListListener = $rootScope.$on('updateUserList', function(event, team) {
            vm.selectedTeam = team;
        });

        var refreshSelectedCardsToolbar = $rootScope.$on('refreshSelectedCardsToolbar', function(event, selectedUsers) {
            vm.selectedUsers = selectedUsers;
        });

        // Unregister
        $scope.$on('$destroy', function() {
            refreshSelectedCardsToolbar();
        });

        function refreshAll() {
            saveFilters(vm.query);
            $scope.$emit('refreshUsers', vm.query.filter);
        };

        function removeFilter() {
            vm.filter.show = false;
            vm.query.filter = '';
            saveFilters(vm.query);
            $scope.$emit('refreshUsers', vm.query.filter);
        };

        function createObject() {
            $state.go("storm.admin-content-detail");
        };

        function saveFilters(filterData) {
            sessionStorage.setItem('objectHeader', JSON.stringify(filterData));
        };
    
        function getSavedFilters() {
            return JSON.parse(sessionStorage.getItem('objectHeader'));
        };

        vm.init();
    }
})();
