(function() {
    'use strict';

    angular
        .module('atheer.session')
        .controller('SessionController', SessionController);

    /* @ngInject */
    function SessionController($scope, $state, Setting, AlertService, $mdDialog, $mdToast, $stateParams, Principal, Feature, PermissionService) {
        var vm = this;

        vm.loadingData = true;

        vm.loadAll = loadAll;
        vm.pageTitleKey = $state.$current.data.pageTitle;

        function loadAll() {
            vm.loadingData = false;
        };

        vm.loadAll();
    }
})();
