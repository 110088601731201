(function() {
    'use strict';

    angular
        .module('atheer.sdk')
        .controller('SdkWebHookDetailLogsController', SdkWebHookDetailLogsController)

    /* @ngInject */
    function SdkWebHookDetailLogsController($mdDialog, $scope, ToastService, $filter, ParseLinks, pagingParams, paginationConstants, $sce, $q, $state, AlertService, $mdToast, $mdSidenav, Principal, WebHook, Feature, User, id, $timeout) {
        var vm = this;

        vm.webHookId = id;
        vm.webHookModel = null;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.reOrder = reOrder;
        vm.transition = transition;
        vm.illustartionEmptyScreenId = "web_hooks_logs";

        vm.query = {
            filter: '',
            limit: paginationConstants.itemsPerPage,
            order: '-id',
            page: 1
        };

        vm.loadAll = function() {
            vm.loadingData = true;
            WebHook.getExecutions({
                id: id,
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            WebHook.get({
                id: id
            }, function(result) {
                vm.webHookModel = result;
            });

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.webHookExecutions = data;
                vm.page = pagingParams.page;
                vm.loadingData = false;
            }

            function onError(error) {
                AlertService.error(error.data.message);
                vm.loadingData = false;
            }
        };

        vm.goToWebHooks = function() {
            $state.go('storm.admin-airsdk-web-hooks');
        };

        vm.showInfo = function(webHookExecution) {
            $mdDialog.show({
                templateUrl: 'modules/sdk/sdk-webhook-detail-log-info.tmpl.html',
                clickOutsideToClose: true,
                controller: "SdkWebHookDetailLogInfoController",
                controllerAs: "vm",
                locals: {
                    webHookExecution: webHookExecution
                }
            }).then(function(result) {

            });
        };

        vm.executeRun = function(webHookExecution) {
            WebHook.executeWebHook({
                id: webHookExecution.id
            }, function(result) {
                ToastService.displayToast($filter('translate')('atheer.webHook.runSubmitted'), 3000)
            });
        }

        function reOrder(order) {
            vm.predicate = order;
            vm.transition();
        }

        function getSortOrder() {
            if (vm.predicate.startsWith('-')) {
                return vm.predicate.substr(1) + ',' + 'desc';
            } else {
                return vm.predicate + ',' + 'asc';
            }

        }

        function transition() {
            vm.promise = WebHook.getExecutions({
                id: id,
                page: vm.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: getSortOrder(),
                search: vm.currentSearch
            }).$promise;

            vm.promise.then(function(data, headers) {
                vm.queryCount = vm.totalItems;
                vm.webHookExecutions = data;
            });
        }

        function resetModal() {
            loadAll();
            AlertService.displayToast($mdToast);
        };

        vm.loadAll();
    }
})();
