(function () {
    'use strict';

    angular
        .module('atheer.system')
        .controller('JobPDFSettingsController', JobPDFSettingsController)

    /* @ngInject */
    function JobPDFSettingsController($scope, $state, $q, $http, Setting, $filter, $window, Principal, ToastService, $location) {
        var vm = this;

        vm.loadingData = false;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.featureSettings = null;
        vm.features = {};
        vm.isAdmin = Principal.isWorkspaceAdmin();
        vm.readOnly = false;

        vm.photo_width = 210;
        vm.photo_height = 280;
        vm.photo_setting = "No";
        vm.photo_setting_options = ["Yes", "No"];
        vm.response_col_width_allocation = "20, 20, 20, 20, 20"
        vm.excludedFields = [];
        vm.fixedKeys = [
            {
                name: "timeline",
                label: "Job timeline"
            },
            {
                name: "sectionHeader",
                label: "Section header"
            },
            {
                name: "stepDuration",
                label: "Step duration"
            },
            {
                name: "stepDescription",
                label: "Step description"
            },
            {
                name: "tableDataMessage",
                label: "Table Step data table caption message"
            },
            {
                name: "response_type",
                label: "Response type column in step response table"
            },
            {
                name: "response_deviation",
                label: "Response deviation column in step response table"
            },
            {
                name: "response_user",
                label: "Response User column in step response table"
            }
        ];

        function setFeaturesModel(activityTemplates, jobpdfConfig) {
            vm.featureSettings = jobpdfConfig[0];

            //Initialize based on current pdf configuration
            angular.forEach(jobpdfConfig[0].fields, function (field) {
                if ( field.name === 'photoSource' ) {
                    vm.photo_setting = field.value === 'original' ? "Yes" : "No";
                } else if ( field.name === 'photoWidth' ) {
                    vm.photo_width = parseInt(field.value);
                } else if ( field.name === 'photoHeight' ) {
                    vm.photo_height = parseInt(field.value);
                } else if ( field.name ===  'responseDataTableColPer' ) {
                    vm.response_col_width_allocation = field.value;
                } else {
                    vm.excludedFields.push(field.name);
                }
            });

            var fixedAttrLoaded = false;
            angular.forEach(activityTemplates, function (template) {
                if ( !fixedAttrLoaded ) {
                    var feature = {
                        name: 'Job fixed fields',
                        label: 'Job fixed fields',
                        editable: true,
                        fields: []
                    }
                    angular.forEach(template.fixedAttributes, function(attribute) {
                        if ( attribute.show ) {
                            var field = {
                                name: attribute.name,
                                label: attribute.label,
                                show: !vm.excludedFields.includes(attribute.name)
                            }
                            feature.fields.push(field);
                        }
                    });
                    vm.features[feature.label] = feature;
                    fixedAttrLoaded = true;
                }
                if ( template.enabledCustomAttributes.length > 0 ) {
                    var feature = {
                        name: template.id,
                        label: template.name + " - Custom fields",
                        editable: true,
                        fields: []
                    }
                    angular.forEach(template.enabledCustomAttributes, function(attribute) {
                        var field = {
                            name: attribute.name,
                            label: attribute.label,
                            show: !vm.excludedFields.includes(attribute.name)
                        }
                        feature.fields.push(field);

                    });
                    vm.features[feature.label] = feature;
                }
            });
            // load fixed entries
            var feature = {
                name: 'Job section fields',
                label: 'Job section fields',
                editable: true,
                fields: []
            }
            angular.forEach(vm.fixedKeys, function(attribute) {
                var field = {
                    name: attribute.name,
                    label: attribute.label,
                    show: !vm.excludedFields.includes(attribute.name)
                }
                feature.fields.push(field);

            });
            vm.features[feature.label] = feature;
        }

        function setFeatureSettings() {
            vm.featureSettings.fields = [];

            for (var key in vm.features) {
                var feature = vm.features[key];
                var settingField;
                angular.forEach(feature.fields, function (field) {
                    if ( ! field.show) {
                        settingField = {
                            name: field.name
                        }
                    } else {
                            return;
                    }
                    vm.featureSettings.fields.push(settingField);
                });
            }

            if ( vm.photo_setting === 'Yes') {
                settingField = {
                    name: 'photoSource',
                    value: 'original'
                }  
                vm.featureSettings.fields.push(settingField);           
        
                settingField = {
                    name: 'photoWidth' ,
                    value: vm.photo_width.toString()
                }      
                vm.featureSettings.fields.push(settingField); 

                settingField = {
                    name: 'photoHeight',
                    value: vm.photo_height.toString()
                }    
                vm.featureSettings.fields.push(settingField); 
            }

            if ( vm.response_col_width_allocation !== '' && vm.response_col_width_allocation !== "20, 20, 20, 20, 20" ) {
                settingField = {
                    name: 'responseDataTableColPer',
                    value: vm.response_col_width_allocation
                }
                vm.featureSettings.fields.push(settingField); 
            } 

        }

        vm.save = function () {
            setFeatureSettings();

            Setting.update(vm.featureSettings, function() {
                ToastService.displayToast($filter('triTranslate')("atheer.setting.success"), 3000);
            }); 
        }

        vm.loadData = function () {
            vm.loadingData = true;
            var host = $location.host();
            if (angular.isDefined(host) && host.startsWith("rigpartner.")) {
                    vm.readOnly = true;
            }

            $q.all([
                Setting.getActivityTemplates().$promise,
                Setting.getJobPDFSettings().$promise
            ])
                .then(function (results) {
                    setFeaturesModel(results[0], results[1]);
                    vm.loadingData = false;
                });
        };
        vm.loadData();
    }
})();
