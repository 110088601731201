(function () {
    'use strict';

    angular
        .module('atheer.user')
        .controller('SessionPlayerRecordingMessageController', SessionPlayerRecordingMessageController);

    /* @ngInject */
    function SessionPlayerRecordingMessageController($mdDialog, mainData) {
        var vm = this;
        vm.mainData = mainData;

        vm.close = function () {
            $mdDialog.hide(true);
        };

        vm.leaveAirSession = function () {
            $mdDialog.hide(false);
        };
    }
})();
