(function() {
    'use strict';

    angular
        .module('atheer.content')
        .config(moduleConfig);

    /* @ngInject */
    function moduleConfig($stateProvider, triMenuProvider) {
        $stateProvider
            .state('storm.launcher', {
                url: '/launcher/:id',
                templateUrl: 'modules/content/launcher.tmpl.html',
                controller: 'LauncherController',
                controllerAs: 'vm',
                data: {
                    layout: {
                        sideMenuSize: 'hidden',
                        footer: false,
                        showToolbar: false,
                        contentClass: 'layout-column'
                    }
                },
                resolve: {
                    id: function($stateParams) {
                        return $stateParams.id;
                    },
                }
            });
    }
})();
