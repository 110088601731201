(function() {
    'use strict';

    angular
        .module('atheer.workspace')
        .controller('WorkspaceDetailDialogController', WorkspaceDetailDialogController)

    /* @ngInject */
    function WorkspaceDetailDialogController($mdDialog, $scope, $filter, $sce, $state, $q, AlertService, $mdToast, $mdSidenav, Workspace, poller, $timeout, Setting) {
        var vm = this;

        vm.loadingData = false;
        vm.isSaving = false;
        vm.successSave = false;
        vm.errorSaving = false;
        vm.specialCharFlag = false;
        vm.duplicateDomainError = '';

        vm.workspace = {
            id: null,
            workspace_no: null,
            name: null,
            description: null,
            nosql_schema: null,
            sql_schema: null,
            sub_domain: null,
            email_domain: null,
            status: 'ACTIVE',
            subscription_type: 'TRIAL',
            start_date: new Date(),
            end_date: null,
            admin_fname: null,
            admin_lname: null,
            admin_email: null,
            website_url: null,
            enabled: true
        };

        vm.validateSpecialChars = function(e) {
            var regex = new RegExp("[/\\\\.\"\'$]");
            var key = e.keyCode || e.which;
            key = String.fromCharCode(key);
            if(regex.test(key)) {
                vm.specialCharFlag = true;
                e.returnValue = false;
                if(e.preventDefault) {
                    e.preventDefault();
                }
            } else {
                vm.specialCharFlag = false;
            }
        };
        vm.onCreateSaveSuccess = function(result) {
            vm.jobStream = result;

            // Get poller.
            var jobStreamStatusPoller = poller.get(Workspace, {
                action: 'getWorkspaceStatus',
                delay: 3000,
                argumentsArray: [{
                    id: vm.jobStream.id
                }],
                smart: true
            });

            jobStreamStatusPoller.promise.then(null, null, function(result) {
                vm.jobStream = result;
                if (result.status == 'COMPLETED') {
                    poller.stopAll();
                    vm.successSave = true;
                    vm.isSaving = false;

                    Workspace.get({
                        id: vm.jobStream.workspace_id
                    }, function(result) {
                        vm.workspace = result;
                    });
                }
            });
        };

        vm.onSaveError = function(err) {
            $timeout(function() {
                vm.isSaving = false;
                vm.errorSaving = true;
                if(err && err.data){
                    vm.duplicateDomainError = err.data.description;
                }
            }, 1000);
        };

        vm.save = function() {
            vm.isSaving = true;
            vm.workspace.nosql_schema = vm.workspace.sub_domain;
            vm.workspace.sql_schema = vm.workspace.sub_domain;
            vm.duplicateDomainError = '';
            Workspace.saveAsync(vm.workspace, vm.onCreateSaveSuccess, vm.onSaveError);
        };

        vm.close = function() {
            $mdDialog.hide();
        };

        vm.checkDetail = function() {
            $mdDialog.hide(vm.workspace);
        };
    }
})();
