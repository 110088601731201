(function () {
    'use strict';

    angular
        .module('atheer.system')
        .controller('LabelDictionaryController', LabelDictionaryController)

    /* @ngInject */
    function LabelDictionaryController($scope, $state, $q, LocalResourceService, Setting, $filter, $window, Principal, ToastService) {
        var vm = this;

        vm.loadingData = false;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.languages = [];
        vm.dictionarySettings = null;
        vm.dictionary = {};
        vm.selectedDictionary = {};
        vm.selectedLanguage = "en_US";

        function setDictionaryModel(dictionarySettings) {
            angular.forEach(dictionarySettings, function (dictionary) {
                if (dictionary.category == 'label-dictionary') {
                    vm.dictionarySettings = dictionary;

                    angular.forEach(dictionary.fields.filter(function (field) {
                        return !field.name.endsWith('_plural')
                    }), function (dictionaryField) {
                        var dictionary = vm.dictionary[dictionaryField.group_name];

                        if (dictionary) {
                            dictionary.settings.push(dictionaryField);
                        } else {
                            dictionary = {
                                name: dictionaryField.group_name,
                                label: dictionaryField.group_label,
                                editable: dictionaryField.group_editable,
                                settings: []
                            }
                            dictionary.settings.push(dictionaryField);
                            vm.dictionary[dictionaryField.group_name] = dictionary;
                        }

                        var dictionaryPluralField = vm.dictionarySettings.fields.filter(function (field) {
                            return (field.name == dictionaryField.name + '_plural' && field.group_name == dictionaryField.group_name);
                        })[0];
                        if (dictionaryPluralField) {
                            dictionaryField['plural_value'] = dictionaryPluralField.value;
                            dictionaryField['default_plural_value'] = dictionaryPluralField.default_value;
                        }
                    });
                }
            });
            vm.selectedDictionary = vm.dictionary[vm.selectedLanguage];
            vm.selectedDictionary.settings = $filter('orderBy')(vm.selectedDictionary.settings, 'group_description != undefined');
        };

        function setDictionarySettings() {
            angular.forEach(vm.selectedDictionary.settings, function (setting) {
                angular.forEach(vm.dictionarySettings.fields, function (dictionaryField) {
                    if (dictionaryField.name == setting.name && dictionaryField.group_name == setting.group_name) {
                        dictionaryField.value = setting.value
                    }

                    if (dictionaryField.name == setting.name + '_plural' && dictionaryField.group_name == setting.group_name) {
                        dictionaryField.value = setting.plural_value
                    }
                });
            });
        };

        vm.updateDictionary = function () {
            vm.selectedDictionary = vm.dictionary[vm.selectedLanguage];
            vm.selectedDictionary.settings = $filter('orderBy')(vm.selectedDictionary.settings, 'group_description != undefined');
        };

        vm.save = function () {
            setDictionarySettings();
            Setting.update(vm.dictionarySettings, function (result) {
                vm.dictionarySettings = result;
                ToastService.displayToastWithAction($filter('triTranslate')("atheer.setting.dictionary.success"),
                    $filter('triTranslate')("entity.action.refresh"), 'md-accent', '', function (response) {
                        if (response == 'ok') {
                            $window.location.reload();
                        }
                    });
            });
        };

        vm.loadData = function () {
            vm.loadingData = true;

            $q.all([
                Setting.getLabelDictionarySettings().$promise
            ])
                .then(function (results) {
                    setDictionaryModel(results[0]);

                    vm.languages = [
                        { name: 'english_us', displayName: 'English (US)', value: 'en_US', serverKey: 'en_US' },
                        { name: 'english_uk', displayName: 'English (UK)', value: 'en_GB', serverKey: 'en_GB' },
                        { name: 'french', displayName: 'French', value: 'fr_FR', serverKey: 'fr_FR' },
                        { name: 'german', displayName: 'German', value: 'de_DE', serverKey: 'de_DE' },
                        { name: 'greek', displayName: 'Greek', value: 'el_GR', serverKey: 'el_GR' },
                        { name: 'italian', displayName: 'Italian', value: 'it_IT', serverKey: 'it_IT' },
                        { name: 'japanese', displayName: 'Japanese', value: 'ja_JP', serverKey: 'ja_JP' },
                        { name: 'portuguese', displayName: 'Portuguese', value: 'pt_BR', serverKey: 'pt_BR' },
                        { name: 'spanish', displayName: 'Spanish', value: 'es_CL', serverKey: 'es_CL' },
                        { name: 'chinese', displayName: 'Chinese (Traditional)', value: 'zh_TW', serverKey: 'zh_TW' },
                        { name: 'chinese_cn', displayName: 'Chinese (Simplified)', value: 'zh_CN', serverKey: 'zh_CN' },
                        { name: 'dutch', displayName: 'Dutch', value: 'nl_NL', serverKey: 'nl_NL' },
                        { name: 'danish', displayName: 'Danish (Denmark)', value: 'da_DK', serverKey: 'da_DK' },
                        { name: 'norwegian', displayName: 'Norwegian', value: 'no_NO', serverKey: 'no_NO' },
                        { name: 'polish', displayName: 'Polish (Poland)', value: 'pl_PL', serverKey: 'pl_PL' },
                        { name: 'russian', displayName: 'Russian', value: 'ru_RU', serverKey: 'ru_RU' },
                        { name: 'swedish', displayName: 'Swedish (Sweden)', value: 'sv_SE', serverKey: 'sv_SE' },
                        { name: 'turkish', displayName: 'Turkish (Turkey)', value: 'tr_TR', serverKey: 'tr_TR' }
                    ];

                    vm.languages.sort(function (a, b) {
                        if (a['displayName'] > b['displayName']) {
                            return 1;
                        } else if (a['displayName'] < b['displayName']) {
                            return -1;
                        }
                        return 0;
                    });

                    vm.loadingData = false;
                });
        };
        vm.loadData();
    }
})();
