(function() {
    'use strict';

    angular
        .module('atheer.session')
        .controller('SessionFeedbackController', SessionFeedbackController);

    /* @ngInject */
    function SessionFeedbackController($scope, $state, $q, $filter, Report, sessionModel, userId, ParseLinks, AlertService, $mdDialog, $stateParams, Experience, isMobileBrowser, ToastService) {
        var vm = this;

        vm.sessionModel = sessionModel;
        vm.userId = userId;
        vm.rating = null;
        vm.ratingFeedback = null;
        vm.isMobileBrowser = isMobileBrowser;

        vm.close = function() {
            $mdDialog.cancel();
        };

        vm.submit = function() {
            Experience.createSessionActivity({
                object: vm.sessionModel,
                user_id: vm.userId,
                activity_type: "FEEDBACK",
                properties: {
                    rating: vm.rating,
                    feedback: vm.ratingFeedback
                }
            }, function(result) {
                ToastService.displayToast($filter('translate')('atheer.session.feedback.submitted'), 3000);
                $mdDialog.hide();
            });

        };
    };
})();
