(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('atheer')
        .constant('VERSION', "1.0.0")
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('HTML5_ENABLED', true)
;
})();
