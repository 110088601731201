(function() {
    'use strict';

    angular
        .module('atheer.system')
        .filter('mediaUrl', mediaUrl);

    function mediaUrl() {
        return mediaUrl;

        function mediaUrl (input) {
            if (input == null || input == undefined || input == '') {
                return;
            } else {
                if(input.startsWith("http")) {
                    return input;
                } else {
                    return '/media/images/' + input;
                }
            }
        }
    }
})();
