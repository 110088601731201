(function() {
    'use strict';

    angular
        .module('atheer.sdk')
        .controller('SdkWebHookDetailController', SdkWebHookDetailController)

    /* @ngInject */
    function SdkWebHookDetailController($mdDialog, $scope, $filter, $sce, $q, $state, AlertService, $mdToast, $mdSidenav, Principal, WebHook, Feature, User, id, $timeout) {
        var vm = this;

        vm.webHookModel = null;
        vm.webHooksEnabled = Feature.isActive('feature_airsdk_webhooks');
        vm.eventsSupported = [{
          name: 'session-completed',
          label: 'sessionCompleted'
        }, {
          name: 'job-created',
          label: 'jobCreated'
        }, {
          name: 'job-completed',
          label: 'jobCompleted'
        }, {
            name: 'job-aborted',
            label: 'jobAborted'
          }, {
          name: 'user-created',
          label: 'userCreated'
        }, {
          name: 'user-terminated',
          label: 'userTerminated'
        }];

        vm.loadData = function() {
            if (id) {
                vm.loadingData = true;
                WebHook.get({
                    id: id
                }, function(result) {
                    vm.webHookModel = result;
                    vm.loadingData = false;
                });
            } else {
                vm.webHookModel = {
                    name: 'Untitled',
                    description: null,
                    auth_type: 'BEARER_TOKEN',
                    auth_header_key: 'Authorization',
                    payload_format: 'JSON',
                    events: [],
                    headers: [],
                    enable: false,
                    secret: 'Bearer <Your Secret>'
                }
            };
        };

        vm.onSaveSuccess = function(result) {
            vm.webHookModel = result;
            vm.isSaving = false;
            AlertService.displayToast($mdToast);
        };

        vm.onSaveError = function() {
            vm.isSaving = false;
        };

        vm.save = function() {
            if (vm.webHookModel.id) {
                WebHook.update(vm.webHookModel, vm.onSaveSuccess, vm.onSaveError);
            } else {
                WebHook.save(vm.webHookModel, vm.onSaveSuccess, vm.onSaveError);
            }
        };

        vm.goToWebHooks = function() {
            $state.go('storm.admin-airsdk-web-hooks');
        };

        vm.isNew = function() {
            if (vm.webHookModel == undefined || vm.webHookModel.id == null) {
                return true;
            } else {
                return false;
            }
        };

        vm.copyWebHook = function() {
            delete vm.webHookModel['id'];
        };

        vm.toggleEvent = function(item, list) {
            var idx = list.indexOf(item);
            if (idx > -1) {
                list.splice(idx, 1);
            } else {
                list.push(item);
            }
        };

        vm.existsEvent = function(item, list) {
            return list.indexOf(item) > -1;
        };

        vm.addHeader = function() {
            var header = {
                name: 'x-sample-header',
                value: 'header-value'
            };

            vm.webHookModel.headers.push(header);
        };

        vm.copyHeader = function(header) {
            var headerNew = {
                name: header.name,
                value: header.value
            };
            vm.webHookModel.headers.push(headerNew);
        };

        vm.removeHeader = function(index) {
            vm.webHookModel.headers.splice(index, 1);
        };

        vm.testWebHook = function() {
            $mdDialog.show({
                templateUrl: 'modules/sdk/sdk-webhook-test.tmpl.html',
                clickOutsideToClose: true,
                controller: "SdkWebHookTestController",
                controllerAs: "vm",
                locals: {
                    webHook: vm.webHookModel
                }
            }).then(function(result) {

            });
        };

        vm.loadData();
    }
})();
