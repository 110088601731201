(function () {
    'use strict';

    angular
        .module('atheer.services')
        .factory('Feature', Feature);

    /* @ngInject */
    function Feature(Principal) {
        var featureSettings = {};
        var accessPermissions = [];

        var service = {
            setFeatures: setFeatures,
            isActive: isActive,
            isAllActive: isAllActive,
            setDeviceFeaturePermissions: setDeviceFeaturePermissions,
            getCurrentDeviceAccessPermissions: getCurrentDeviceAccessPermissions
        };
        return service;

        function setFeatures(features) {
            angular.forEach(features, function (feature) {
                if (feature.category == 'features') {
                    angular.forEach(feature.fields, function (settingField) {
                        featureSettings['feature' + '_' + settingField.group_name + '_' + settingField.name] = settingField.value;
                    });
                }
            });
        }

        function isActive(featureName) {
            if (Principal.isWorkspaceAdmin()) {
                return true;
            } else {
                if (angular.isDefined(featureSettings[featureName]) && featureSettings[featureName] == 'true') {
                    return true;
                } else {
                    return false;
                }
            }
        }

        function isAllActive(features) {
            var active = true;
            for (var featureIndex in features) {
                if (!isActive(features[featureIndex])) {
                    active = false;
                };
            }
            return active;
        }

        function setDeviceFeaturePermissions(permissons) {
            accessPermissions = permissons;
        }

        function getCurrentDeviceAccessPermissions(device, user) {
            var currentPermissions = accessPermissions.filter(function (permssionSet) {
                return permssionSet.client === 'Web' && permssionSet.device === device && permssionSet.user === user

            });
            return currentPermissions[0].permissions;
        }
    }
})();
