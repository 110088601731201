(function() {
    'use strict';

    angular
        .module('atheer.workspace')
        .controller('WorkspaceDetailAdvInsightsController', WorkspaceDetailAdvInsightsController)

    /* @ngInject */
    function WorkspaceDetailAdvInsightsController($mdDialog, $scope, $filter, $sce, ParseLinks, pagingParams, paginationConstants, $state, $q, AlertService, $mdToast, $mdSidenav, Workspace, id, $timeout, Setting) {
        var vm = this;

        vm.loadingData = false;
        vm.isSaving = false;
        vm.isCreating = false;
        vm.errorSaving = false;
        vm.currentStep = 'provisioned';
        vm.uploadStatus = 'idle';
        vm.importStatus = 'idle';
        vm.insightsSettings = null;

        vm.featureSettings = null;
        vm.features = {};

        vm.loadingData = true;

        vm.onFeatureSaveSuccess = function(result) {
            vm.featureSettings = result;
            AlertService.displayToast($mdToast);
            checkProvisioningStatus();
        };

        vm.onSaveError = function() {
            vm.isSaving = false;
            vm.isCreating = false;
            vm.errorSaving = true;
        };

        vm.save = function() {
            vm.isSaving = true;
            vm.currentStep == 'provisioning';
            Workspace.activateInsights({
                id: id
            }, vm.onFeatureSaveSuccess, vm.onSaveError);
        };

        function loadAll() {
            vm.loadingData = true;

            $q.all([
                Workspace.get({
                    id: id
                }).$promise,
                Workspace.getInsightSettings({
                    id: id
                }).$promise
            ]).then(function(results) {
                vm.workspace = results[0];
                vm.insightsSettings = results[1];
                if(vm.insightsSettings.state == 'UNKNOWN' || !vm.insightsSettings.state) {
                    vm.currentStep = 'default'
                } else if(vm.insightsSettings.state == 'INPROGRESS' || vm.insightsSettings.state == 'INITIATED') {
                    vm.currentStep = 'provisioning'
                } else if(vm.insightsSettings.state == 'ERROR') {
                    vm.currentStep = 'error'
                };

                vm.loadingData = false;
            }).catch(function(data) {}).finally(function() {});
        }

        function checkProvisioningStatus() {
            // Get poller.
            var statusPoller = poller.get(Workspace, {
                action: 'getInsightSettings',
                delay: 3000,
                argumentsArray: [{
                    id: id
                }],
                smart: true
            });

            statusPoller.promise.then(null, null, function(result) {
                var insightSettings = result;
                if (result.provisioned) {
                    poller.stopAll();
                    vm.currentStep = 'provisioned';
                }
            });
        };

        loadAll();
    }
})();
