(function() {
    'use strict';

    angular
        .module('atheer.insights')
        .controller('InsightsDashboardDetailDialogController', InsightsDashboardDetailDialogController);

    /* @ngInject */
    function InsightsDashboardDetailDialogController($scope, $stateParams,Feature, $mdDialog, entity, Dashboard) {
        var vm = this;
        vm.dashboard = entity;
        vm.isSavingError = false;
        vm.errorMessage = '';

        var onSaveSuccess = function(result) {
            $scope.$emit('updateDashboardList', result);
            $mdDialog.hide(result);
            vm.isSaving = false;
        };

        var onSaveError = function(err) {
            vm.isSaving = false;
            vm.isSavingError = true;
            if(err) {
                var obj = err.headers();
                vm.errorMessage = obj['x-atheer-error-message'];
            }
        };

        vm.save = function() {
            vm.isSaving = true;
            Dashboard.save(vm.dashboard, onSaveSuccess, onSaveError);
        };

        vm.clear = function() {
            $mdDialog.hide();
        };

        vm.dynamicTitle = null !== vm.dashboard.id ? 'atheer.insights.dashboard.editLabel' : 'atheer.insights.dashboard.createLabel';
    }
})();
