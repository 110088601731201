(function() {
    'use strict';

    angular
        .module('atheer.anchor')
        .controller('AnchorMenuController', AnchorMenuController);

    /* @ngInject */
    function AnchorMenuController($scope, $state, $mdDialog, $mdPanel, Anchor, ParseLinks, AlertService, pagingParams, paginationConstants, $mdToast, $stateParams, $filter, CONTENT_META, $translate, Feature) {
        var vm = this;

        vm.loadingData = true;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.predicate = pagingParams.predicate;

        vm.filters = {
            metaType: 'all',
            enabled: false,
            externalId: false,
            status: null,
        };

        if ( getSavedFilters() ) {
            vm.filters = getSavedFilters();
        }

        vm.updateFilters = function() {
            saveFilters(vm.filters);
            $scope.$emit('updateSmartscanList', vm.filters);
        }

        vm.loadAll = function() {
            vm.loadingData = false;
        }

        function saveFilters(filterData) {
            sessionStorage.setItem('objectMenu', JSON.stringify(filterData));
        };
    
        function getSavedFilters() {
            return JSON.parse(sessionStorage.getItem('objectMenu'));
        };
        
        vm.loadAll();
    }
})();
