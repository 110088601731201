(function() {
    'use strict';

    angular
        .module('atheer.smartFlow')
        .controller('SmartFlowBuilderInsightsOverviewController', SmartFlowBuilderInsightsOverviewController);

    /* @ngInject */
    function SmartFlowBuilderInsightsOverviewController($scope, $filter, $q, id, SmartFlow, $state, SmartFlowBuilder, Report, WORFLOW_CELL_CONSTANT) {
        var vm = this;
        vm.viewType = 'insights';
        vm.loadingData = false;

        vm.smartFlowModel = $scope.$parent.vm.smartFlowModel;

        vm.overviewSummary = null;
        vm.activitySummary = null;

        function getDateFromItem(item) {
            return item.x;
        }

        vm.userEnteredChartData = {
            chart: {
                type: 'line',
            },
            title: {
                text: $filter('translate')('atheer.smartFlow.insights.completions')
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            yAxis: {
                title: {
                    text: $filter('translate')('atheer.smartFlow.insights.noOfUsers')
                }
            },
            xAxis: {
                title: {
                    text: $filter('translate')('atheer.smartFlow.insights.dates')
                },
                type: 'datetime',
                labels: {
                    format: '{value:%e - %b - %Y}'
                }

            },
            series: [{
                name: $filter('translate')('atheer.smartFlow.insights.usersEntered'),
                data: []
            }],
            tooltip: {
                formatter: function() {
                    return '<b>' + Highcharts.dateFormat('%e - %b - %Y',
                        new Date(this.x)) + '</b><br/> Users : ' + this.y;
                }
            }
        };

        vm.goToSmartFlows = function() {
            $state.go("storm.admin-smartflows");
        };

        vm.getCount = function(activityName) {
            for (var i = 0; i < vm.overviewSummary.metrics.length; i++) {
                if (vm.overviewSummary.metrics[i].name === activityName) {
                    return vm.overviewSummary.metrics[i].count;
                }
            }
            return 0;
        }

        vm.loadData = function() {
            vm.loadingData = true;

            $q.all([
                Report.getSmartFlowOverviewSummary({
                    id: id
                }).$promise,
                Report.getSmartFlowActivitySummary({
                    id: id
                }).$promise
            ]).then(function(data) {
                vm.overviewSummary = data[0];
                vm.activitySummary = data[1];
                vm.loadingData = false;

                angular.forEach(vm.overviewSummary.history, function(userCountObj) {
                    vm.userEnteredChartData.series[0].data.push({x :new Date(userCountObj.name), y :userCountObj.count})
                });
                vm.userEnteredChartData.series[0].data = $filter('orderBy')(vm.userEnteredChartData.series[0].data, getDateFromItem);

                if (vm.smartFlowModel.canvas_data != null && vm.smartFlowModel.canvas_data.length > 0) {
                    var graph = new joint.dia.Graph;
                    graph.fromJSON(JSON.parse(vm.smartFlowModel.canvas_data));

                    _.each(vm.activitySummary, function(activitySummary) {
                        var cell = graph.getCell(activitySummary.id);
                        if (cell) {
                            var title = cell.attr('text/text');
                            activitySummary.title = title;
                            if(WORFLOW_CELL_CONSTANT[activitySummary.name]) {
                                  activitySummary.type_label = WORFLOW_CELL_CONSTANT[activitySummary.name].label;
                            }

                        }
                    });
                }

            }).catch(function(data) {}).finally(function() {});
        }
        vm.loadData();
    }
})();
