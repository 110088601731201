(function() {
    'use strict';

    angular
        .module('atheer.team')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('storm.admin-teams', {
                url: '/teams?page&sort&search',
                views: {
                    '@storm': {
                        templateUrl: 'modules/team/teams.html',
                        controller: 'TeamController',
                        controllerAs: 'vm',
                    },
                    'toolbar@storm': {
                        templateUrl: 'modules/team/team-toolbar.tmpl.html',
                        controller: 'TeamToolbarController',
                        controllerAs: 'vm'
                    },
                    'sideMenu@storm': {
                        templateUrl: 'modules/team/team-menu.tmpl.html',
                        controller: 'TeamMenuController',
                        controllerAs: 'vm',
                    }
                },
                data: {
                    pageTitle: 'atheer.team.home.title',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showSideNavMenu: true,
                        sideNavMenuPosition: 'left',
                        showToolbar: false,
                        sideNavMenuSize: 15
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }]
                }
            })
            .state('storm.admin-team-detail', {
                url: '/teams/:id',
                templateUrl: 'modules/team/team-detail.tmpl.html',
                controller: 'TeamDetailController',
                controllerAs: 'vm',
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null,
                    teamType: null,
                    parentTeam: [],
                    isDeeplink: false
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    id: function($stateParams) {
                        return $stateParams.id;
                    }
                },
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false
                    }
                }
            });
    }
})();
