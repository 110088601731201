(function() {

    'use strict';
    angular
        .module('atheer')
        .constant('FIELD_CONDITION_CONSTANT', {
            'STRING': [{
                defaultValue: null,
                showInput: true,
                label: 'atheer.fieldCondition.is',
                value: 'EQUAL'
            }, {
                defaultValue: null,
                showInput: true,
                label: 'atheer.fieldCondition.isNot',
                value: 'NOT_EQUAL'
            }, {
                defaultValue: null,
                showInput: true,
                label: 'atheer.fieldCondition.startsWith',
                value: 'STARTS_WITH'
            }, {
                defaultValue: null,
                showInput: true,
                label: 'atheer.fieldCondition.endsWith',
                value: 'ENDS_WITH'
            }, {
                defaultValue: null,
                showInput: true,
                label: 'atheer.fieldCondition.contains',
                value: 'CONTAINS'
            }, {
                defaultValue: null,
                showInput: true,
                label: 'atheer.fieldCondition.doesnotContains',
                value: 'NOT_CONTAINS'
            }, {
                defaultValue: null,
                showInput: false,
                label: 'atheer.fieldCondition.isNotSet',
                value: 'NOT_EXISTS'
            }, {
                defaultValue: null,
                showInput: false,
                label: 'atheer.fieldCondition.isSet',
                value: 'EXISTS'
            }, {
                defaultValue: null,
                showInput: false,
                label: 'atheer.fieldCondition.in',
                value: 'IN'
            }, {
                defaultValue: null,
                showInput: false,
                label: 'atheer.fieldCondition.notIn',
                value: 'NOT_IN'
            }],
            'NUMBER': [{
                    defaultValue: null,
                    showInput: true,
                    label: 'atheer.fieldCondition.is',
                    value: 'EQUAL'
                },
                {
                    defaultValue: null,
                    showInput: true,
                    label: 'atheer.fieldCondition.isNot',
                    value: 'NOT_EQUAL'
                },
                {
                    defaultValue: null,
                    showInput: true,
                    label: 'atheer.fieldCondition.greaterThan',
                    value: 'GREATER_THAN'
                },
                {
                    defaultValue: null,
                    showInput: true,
                    label: 'atheer.fieldCondition.greaterThanOrEquals',
                    value: 'GREATER_THAN_OR_EQUAL'
                },
                {
                    defaultValue: null,
                    showInput: true,
                    label: 'atheer.fieldCondition.lessThan',
                    value: 'LESS_THAN'
                },
                {
                    defaultValue: null,
                    showInput: true,
                    label: 'atheer.fieldCondition.lessThanOrEquals',
                    value: 'LESS_THAN_OR_EQUAL'
                }, {
                    defaultValue: null,
                    showInput: false,
                    label: 'atheer.fieldCondition.isNotSet',
                    value: 'NOT_EXISTS'
                }, {
                    defaultValue: null,
                    showInput: false,
                    label: 'atheer.fieldCondition.isSet',
                    value: 'EXISTS'
                }
            ],
            'DECIMAL': [{
                    defaultValue: null,
                    showInput: true,
                    label: 'atheer.fieldCondition.is',
                    value: 'EQUAL'
                },
                {
                    defaultValue: null,
                    showInput: true,
                    label: 'atheer.fieldCondition.isNot',
                    value: 'NOT_EQUAL'
                },
                {
                    defaultValue: null,
                    showInput: true,
                    label: 'atheer.fieldCondition.greaterThan',
                    value: 'GREATER_THAN'
                },
                {
                    defaultValue: null,
                    showInput: true,
                    label: 'atheer.fieldCondition.greaterThanOrEquals',
                    value: 'GREATER_THAN_OR_EQUAL'
                },
                {
                    defaultValue: null,
                    showInput: true,
                    label: 'atheer.fieldCondition.lessThan',
                    value: 'LESS_THAN'
                },
                {
                    defaultValue: null,
                    showInput: true,
                    label: 'atheer.fieldCondition.lessThanOrEquals',
                    value: 'LESS_THAN_OR_EQUAL'
                }, {
                    defaultValue: null,
                    showInput: false,
                    label: 'atheer.fieldCondition.isNotSet',
                    value: 'NOT_EXISTS'
                }, {
                    defaultValue: null,
                    showInput: false,
                    label: 'atheer.fieldCondition.isSet',
                    value: 'EXISTS'
                }
            ],
            'BOOLEAN': [{
                defaultValue: "true",
                showInput: false,
                label: 'atheer.fieldCondition.isTrue',
                value: 'EQUAL'
            }, {
                defaultValue: "false",
                showInput: false,
                label: 'atheer.fieldCondition.isFalse',
                value: 'NOT_EQUAL'
            }, {
                defaultValue: "",
                showInput: false,
                label: 'atheer.fieldCondition.isNotSet',
                value: 'EXISTS'
                }],
            'DATE': [{
                    defaultValue: null,
                    showInput: true,
                    label: 'atheer.fieldCondition.moreThan',
                    value: 'GREATER_THAN'
                },
                {
                    defaultValue: null,
                    showInput: true,
                    label: 'atheer.fieldCondition.moreThanOrEquals',
                    value: 'GREATER_THAN_OR_EQUAL'
                },
                {
                    defaultValue: null,
                    showInput: true,
                    label: 'atheer.fieldCondition.lessThan',
                    value: 'LESS_THAN'
                }, {
                    defaultValue: null,
                    showInput: true,
                    label: 'atheer.fieldCondition.lessThanOrEquals',
                    value: 'LESS_THAN_OR_EQUAL'
                }, {
                    defaultValue: null,
                    showInput: true,
                    label: 'atheer.fieldCondition.within',
                    value: 'WITHIN'
                },
                {
                    defaultValue: null,
                    showInput: true,
                    label: 'atheer.fieldCondition.after',
                    value: 'AFTER'
                }, {
                    defaultValue: null,
                    showInput: true,
                    label: 'atheer.fieldCondition.on',
                    value: 'ON'
                }, {
                    defaultValue: null,
                    showInput: true,
                    label: 'atheer.fieldCondition.before',
                    value: 'BEFORE'
                }, {
                    defaultValue: null,
                    showInput: true,
                    label: 'atheer.fieldCondition.between',
                    value: 'BETWEEN'
                }, {
                    defaultValue: null,
                    showInput: false,
                    label: 'atheer.fieldCondition.isNotSet',
                    value: 'NOT_EXISTS'
                }, {
                    defaultValue: null,
                    showInput: false,
                    label: 'atheer.fieldCondition.isSet',
                    value: 'EXISTS'
                }
            ],
            'OBJECT': {
                'content': [{
                    defaultValue: null,
                    showInput: true,
                    label: 'atheer.fieldCondition.objectIs',
                    value: 'EQUAL'
                }],
                'badge': [{
                    defaultValue: null,
                    showInput: true,
                    label: 'atheer.fieldCondition.objectIs',
                    value: 'EQUAL'
                }],
                'security-role': [{
                    defaultValue: null,
                    showInput: true,
                    label: 'atheer.fieldCondition.objectIs',
                    value: 'EQUAL'
                }],
                'audience': [{
                    defaultValue: null,
                    showInput: true,
                    label: 'atheer.fieldCondition.objectIs',
                    value: 'EQUAL'
                }],
                'topic': [{
                    defaultValue: null,
                    showInput: true,
                    label: 'atheer.fieldCondition.objectIs',
                    value: 'EQUAL'
                }]
            },
            'LANGUAGE': [{
                    defaultValue: null,
                    showInput: true,
                    label: 'atheer.fieldCondition.objectIs',
                    value: 'EQUAL'
                },
                {
                    defaultValue: null,
                    showInput: true,
                    label: 'atheer.fieldCondition.objectIsNot',
                    value: 'NOT_EQUAL'
                }
            ],
            'TIMEZONE': [{
                    defaultValue: null,
                    showInput: true,
                    label: 'atheer.fieldCondition.objectIs',
                    value: 'EQUAL'
                },
                {
                    defaultValue: null,
                    showInput: true,
                    label: 'atheer.fieldCondition.objectIsNot',
                    value: 'NOT_EQUAL'
                }
            ],
            'LIST': [{
                    defaultValue: null,
                    showInput: true,
                    label: 'atheer.fieldCondition.objectIs',
                    value: 'EQUAL'
                },
                {
                    defaultValue: null,
                    showInput: true,
                    label: 'atheer.fieldCondition.objectIsNot',
                    value: 'NOT_EQUAL'
                }
            ]
        });
})();
