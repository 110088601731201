(function() {
    'use strict';

    angular
        .module('atheer.insights')
        .controller('InsightsTopicController', InsightsTopicController);

    /* @ngInject */
    function InsightsTopicController($scope, $q, $translate, $filter, Report, CONTENT_META, triSettings, Workspace, workspaceId) {
        var vm = this;

        vm.primaryColor = triSettings.getSkinModel().primary_color_hex;
        vm.secondaryColor = triSettings.getSkinModel().secondary_color_hex;
        vm.loadingData = false;
        vm.dashboardModel = null;
        vm.illustartionEmptyScreenId = "insight_graph";

        vm.topTopicByScanCount = {
            chart: {
                type: 'bar',
            },
            title: {
                text: $translate.instant('atheer.insights.topics.topTopicByScanCount')
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            yAxis: {
                title: {
                    text: $translate.instant('atheer.insights.topics.NoOfScans')
                },
                allowDecimals: false
            },
            xAxis: {
                title: {
                    text: $translate.instant('atheer.insights.topics.title')
                },
                categories: [],
                labels: {
                    enabled: true
                }
            },
            plotOptions: {
                series: {
                    stacking: 'normal'
                }
            },
            series: [{
                name: $translate.instant('atheer.insights.topics.title'),
                color: vm.primaryColor,
                data: []
            }],
            tooltip: {
                formatter: function() {
                    return '<b>' + this.series.name + '</b> : ' + this.y + ' ' + this.x;
                }
            }
        };

        vm.topUsersByScanCount = {
            chart: {
                type: 'bar',
            },
            title: {
                text: $translate.instant('atheer.insights.topics.topUsersByScan')
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            yAxis: {
                title: {
                    text: $translate.instant('atheer.insights.topics.NoOfScans')
                },
                allowDecimals: false
            },
            xAxis: {
                title: {
                    text: $translate.instant('atheer.insights.users.title')
                },
                categories: [],
                labels: {
                    enabled: true
                }
            },
            plotOptions: {
                series: {
                    stacking: 'normal'
                }
            },
            series: [{
                name: $translate.instant('atheer.insights.topics.scans'),
                color: vm.primaryColor,
                data: []
            }],
            tooltip: {
                formatter: function() {
                    return '<b>' + this.series.name + '</b> : ' + this.y + ' ' + this.x;
                }
            }
        };

        vm.topicScansByDay = {
            chart: {
                type: 'column',
            },
            title: {
                text: $translate.instant('atheer.insights.topics.scansPerDay')
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            yAxis: {
                title: {
                    text: $translate.instant('atheer.insights.topics.NoOfScans')
                },
                allowDecimals: false
            },
            xAxis: {
                categories: [],
                labels: {
                    enabled: true
                }
            },
            plotOptions: {
                series: {
                    stacking: 'normal'
                }
            },
            series: [{
                name: $translate.instant('atheer.insights.dates'),
                color: vm.primaryColor,
                data: []
            }],
            tooltip: {
                formatter: function() {
                    return '<b>' + this.series.name + '</b> : ' + this.y + ' ' + this.x;
                }
            }
        };

        function performDataProcess() {
            angular.forEach(vm.dashboardModel.top_topic_by_scan_count, function(topTopicByScanCount) {
                vm.topTopicByScanCount.xAxis.categories.push(topTopicByScanCount.name);
                vm.topTopicByScanCount.series[0].data.push(topTopicByScanCount.count);
            });

            angular.forEach(vm.dashboardModel.top_users_by_scan_count, function(topUsersByScanCount) {
                vm.topUsersByScanCount.xAxis.categories.push(topUsersByScanCount.name);
                vm.topUsersByScanCount.series[0].data.push(topUsersByScanCount.count);
            });

            angular.forEach(vm.dashboardModel.topic_scan_by_day, function(topicScansByDay) {
                vm.topicScansByDay.xAxis.categories.push(topicScansByDay.name);
                vm.topicScansByDay.series[0].data.push(topicScansByDay.count);
            });

            vm.loadingData = false;
        }

        vm.loadData = function() {
            vm.loadingData = true;

            var resource = angular.isDefined(workspaceId) ? Workspace.getTopicDashboard({
                id: workspaceId
            }).$promise : Report.getTopicDashboard().$promise;

            $q.all([
                    resource
                ])
                .then(function(results) {
                    vm.dashboardModel = results[0];
                    performDataProcess();
                });
        }

        vm.loadData();
    }
})();
