(function () {
    "use strict";

    angular
        .module("atheer.smartFlow")
        .controller(
            "JobFlowMoleculesTeamResolverController",
            JobFlowMoleculesTeamResolverController
        );

    /* @ngInject */
    function JobFlowMoleculesTeamResolverController(
        $mdDialog,
        JobFlow,
        setting,
        SmartFlowBuilder
    ) {
        var vm = this;
        var object = {
            search_key_name: "searchKeys",
            search_key_value: "",
            data_model_name: "dataModel",
            data_model_value: "",
        };

        vm.resolverTypeList = [
            { name: "Static", value: "STATIC" },
            { name: "Query", value: "QUERY" },
            { name: "Lookup", value: "LOOKUP" },
            { name: "Datamodel", value: "DATAMODEL" },
        ];

        vm.resolverList = [];
        vm.lookUpData = [];
        vm.loadingData = true;
        vm.listItems = [];
        vm.staticObject = [];
        vm.dynamicObject = [];
        vm.isStaticObjectLoaded = false;
        vm.isDynamicObjectLoaded = false;
        vm.dataModelProperties = [];
        vm.objects = [];
        vm.selectedDataModelId = "";

        vm.close = function () {
            $mdDialog.cancel();
        };

        vm.submit = function () {
            var rawData = angular.copy(vm.setting.properties);
            vm.setting.properties = {};

            switch (rawData.resolverType) {
                case "STATIC":
                    vm.setting.properties = {
                        objectType: rawData.objectType,
                        staticResult: rawData.staticResult,
                        resolverType: rawData.resolverType,
                    };
                    break;
                case "DATAMODEL":
                    vm.setting.properties = {
                        objectType: rawData.objectType,
                        resolverType: rawData.resolverType,
                        dataModelCriteria: {
                            dataModelColumnName:
                                rawData.dataModelCriteria.dataModelColumnName,
                            dataModelId: rawData.dataModelCriteria.dataModelId,
                        },
                    };
                    break;
                case "QUERY":
                    var searchKeyList = Object.keys(
                        rawData.searchCriteria.searchKeys
                    );
                    var searchKeyObject = {};

                    angular.forEach(searchKeyList, function (searchKey) {
                        searchKeyObject[
                            rawData.searchCriteria.searchKeys[searchKey]
                        ] = searchKey;
                    });

                    vm.setting.properties = {
                        objectType: rawData.objectType,
                        resolverType: rawData.resolverType,
                        searchCriteria: {
                            searchKeys: searchKeyObject,
                            returnSingleObject: true,
                        },
                    };
                    break;
                case "LOOKUP":
                    var lookupCriteria = [];
                    angular.forEach(vm.lookUpData, function (row) {
                        var obj = {
                            conditionGroup: {
                                logicalOperator: "AND",
                                conditions: [],
                            },
                            onSuccessResult: row["onSuccessResult"],
                        };

                        angular.forEach(row, function (value, key) {
                            if (key === "onSuccessResult") {
                                return;
                            }

                            obj.conditionGroup.conditions.push({
                                operand: key,
                                operator: "EQ",
                                value: value,
                            });
                        });

                        lookupCriteria.push(obj);
                    });
                    vm.setting.properties = {
                        objectType: rawData.objectType,
                        resolverType: rawData.resolverType,
                        returnSingleObject: true,
                        lookupCriteria: lookupCriteria,
                    };
                    break;
            }

            $mdDialog.hide(vm.setting);
        };

        vm.onDataModelChange = function (option) {
            if (!vm.setting.properties.dataModelCriteria) {
                vm.setting.properties.dataModelCriteria = {
                    dataModelColumnName: option.name,
                    dataModelId: vm.selectedDataModelId,
                };
            } else {
                vm.setting.properties.dataModelCriteria.dataModelColumnName =
                    option.name;
            }
        };
        vm.onResolveTypeChnage = function () {
            if (vm.lookUpData && vm.lookUpData.length > 0) {
                var keys = Object.keys(vm.lookUpData[0]);
                for(var i = 0; i < keys.length; i++) {
                    if(vm.resolverList && vm.resolverList.length) {
                        if(vm.resolverList.indexOf(keys[i]) === -1 && keys[i] !== 'onSuccessResult') {
                            angular.forEach(vm.lookUpData, function (obj) {
                                delete obj[keys[i]];
                            });
                        }
                    } else {
                        vm.lookUpData = [];
                    }
                }
            } else {
                var obj = {};
                angular.forEach(vm.resolverList, function (value) {
                    obj[value] = undefined;
                });

                vm.lookUpData.push(obj);
            }
        };

        vm.onObjectTypeChange = function (isData) {
            vm.loadingData = true;
            vm.staticObject = [];
            vm.dynamicObject = [];

            JobFlow.getObjectList(
                {
                    objectType: vm.setting.properties["objectType"],
                    sort: "name,asc",
                },
                function (result) {
                    vm.isStaticObjectLoaded = true;
                    vm.loadingData = false;
                    vm.staticObject = result;
                }
            );

            if (vm.setting.properties.resolverType === "QUERY") {
                JobFlow.getMetaData(
                    { objectType: vm.setting.properties["objectType"] },
                    function (result) {
                        vm.isDynamicObjectLoaded = true;
                        vm.loadingData = false;
                        vm.setMetaData(result);
                    }
                );

                vm.setting.properties["searchCriteria"]["searchKeys"] = isData
                    ? vm.setting.properties["searchCriteria"]["searchKeys"]
                    : {};
            }

            if (vm.setting.properties.resolverType === "LOOKUP") {
                var dataModelId = SmartFlowBuilder.getDataModelId();

                if (!dataModelId) {
                    vm.loadingData = false;
                    return;
                }

                setDataModelProperties(dataModelId);
            }
        };

        vm.setMetaData = function (result) {
            angular.forEach(result, function (metaData) {
                var value = metaData.split(".").join("##");
                var data = {
                    label: metaData,
                    value: value,
                };
                vm.dynamicObject.push(data);
            });
        };

        vm.onResolverTypeChange = function () {
            if (vm.setting.properties.resolverType === "QUERY") {
                vm.setting.properties["searchCriteria"] = {};
                vm.setting.properties.searchCriteria["searchKeys"] = {};
                vm.objects = [];

                initQueryData();
            }

            if (vm.setting.properties.resolverType === "LOOKUP") {
                vm.onObjectTypeChange();
            }
            if (vm.setting.properties.resolverType === "DATAMODEL") {
                initDatamodel();
            }
        };

        vm.showProperties = function (object, index) {
            $mdDialog
                .show({
                    controller: "JobFlowMoleculesPropertiesController",
                    controllerAs: "vm",
                    templateUrl:
                        "modules/smartflow/jobflow-molecules-properties.tmpl.html",
                    escapeToClose: true,
                    clickOutsideToClose: true,
                    locals: {
                        dataModelProperties: vm.dataModelProperties,
                        type: vm.setting.properties["objectType"],
                    },
                    multiple: true,
                })
                .then(function (result) {
                    vm.setting.properties.searchCriteria.searchKeys[
                        result.name
                    ] = "";
                    vm.objects[index].data_model_value = result.name;

                    var datamodelKeys = [];
                    angular.forEach(vm.objects, function (row) {
                        datamodelKeys.push(row.data_model_value);
                    });
                    for (var key in vm.setting.properties.searchCriteria.searchKeys) {
                        if (datamodelKeys.length && datamodelKeys.indexOf(key) == -1) {
                            delete vm.setting.properties.searchCriteria.searchKeys[key];
                        }
                    }
                });
        };

        vm.addRow = function () {
            var obj = {};
            angular.forEach(vm.resolverList, function (value) {
                obj[value] = undefined;
            });

            vm.lookUpData.push(obj);
        };

        vm.deleteRow = function (index) {
            vm.lookUpData.splice(index, 1);
        };

        function initLookUp() {
            var properties = angular.copy(vm.setting.properties);
            vm.resolverList = [];
            vm.lookUpData = [];
            var obj = {};

            angular.forEach(properties.lookupCriteria, function (row) {
                obj = {};
                angular.forEach(row.conditionGroup.conditions, function (
                    value
                ) {
                    obj[value.operand] = value.value;
                    if (!vm.resolverList[value.operand]) {
                        vm.resolverList.push(value.operand);
                    }
                });

                obj["onSuccessResult"] = row.onSuccessResult;
                vm.lookUpData.push(obj);
            });
        }

        function init() {
            vm.setting = angular.copy(setting);
            vm.setting.properties.objectType = "Team";

            if (!vm.setting.properties.resolverType) {
                vm.setting.properties.resolverType = "STATIC";
            }

            if (vm.setting.properties.resolverType === "LOOKUP") {
                initLookUp();
            }
            if (vm.setting.properties.resolverType === "DATAMODEL") {
                initDatamodel();
            }
            if (
                !vm.setting.properties.resolverType ||
                vm.setting.properties.resolverType !== "QUERY"
            ) {
                if (vm.setting.properties.objectType) {
                    vm.onObjectTypeChange();
                }
                return;
            }

            initQueryData();
        }

        vm.addField = function (key) {
            var obj = angular.copy(object);
            obj.data_model_value = key ? key : "";
            vm.objects.push(obj);
        };

        vm.removeField = function (id, obj) {
            vm.objects.splice(id, 1);
            delete vm.setting.properties.searchCriteria.searchKeys[
                obj.data_model_value
            ];
        };

        function initDatamodel() {
            vm.loadingData = true;
            vm.selectedDataModelId = SmartFlowBuilder.getDataModelId();
            if (!vm.selectedDataModelId) {
                vm.loadingData = false;
                return;
            }
            setDataModelProperties(vm.selectedDataModelId);
        }

        function initQueryData() {
            var dataModelId = SmartFlowBuilder.getDataModelId();
            setDataModelProperties(dataModelId);

            var keys = Object.keys(vm.setting.properties.searchCriteria);
            var searchKeyList = Object.keys(
                vm.setting.properties.searchCriteria.searchKeys
            );
            var searchKeyObject = {};

            angular.forEach(searchKeyList, function (searchKey) {
                searchKeyObject[
                    vm.setting.properties.searchCriteria.searchKeys[searchKey]
                ] = searchKey;
            });

            vm.setting.properties.searchCriteria.searchKeys = searchKeyObject;

            if (keys && keys.length > 0) {
                var searchKeys = Object.keys(
                    vm.setting.properties.searchCriteria.searchKeys
                );
                if (
                    searchKeys &&
                    searchKeys.length > 0 &&
                    vm.setting.properties["resolverType"] === "QUERY"
                ) {
                    angular.forEach(searchKeys, function (key) {
                        vm.addField(key);
                    });
                } else {
                    vm.addField();
                }
                vm.onObjectTypeChange(true);
            } else {
                vm.resolverType = true;
                vm.addField();
            }
        }

        function setDataModelProperties(dataModelId) {
            JobFlow.getDataModelById({ id: dataModelId }, function (result) {
                vm.dataModelProperties = result.properties;
                vm.loadingData = false;
            });
        }

        init();
    }
})();
