(function () {
    "use strict";

    angular
        .module("atheer.insights")
        .controller("InsightsAirformController", InsightsAirformController);

    /* @ngInject */
    function InsightsAirformController(
        $scope,
        $q,
        $filter,
        Report,
        CONTENT_META,
        Workspace,
        workspaceId,
        $translate
    ) {
        var vm = this;
        vm.loadingData = false;

        vm.dashboardModel = [
            {
                columns: [
                    {
                        name: "total_active_airforms",
                        count: 0,
                        title: $translate.instant('atheer.insights.airForms.totalActiveAirForms'),
                    },
                    {
                        name: "total_scheduled_airforms",
                        count: 0,
                        title: $translate.instant('atheer.insights.airForms.totalScheduledAirForms'),
                    },
                    {
                        name: "total_draft_airforms",
                        count: 0,
                        title: $translate.instant('atheer.insights.airForms.totalDraftAirforms'),
                    },
                    {
                        name: "total_archived_airforms",
                        count: 0,
                        title: $translate.instant('atheer.insights.airForms.totalArchivedAirForms'),
                    },
                ],
            },
            {
                columns: [
                    {
                        name: "total_jobs_completed_in_last_30_days",
                        count: 0,
                        title: $translate.instant('atheer.insights.airForms.totalJobs30Days'),
                    },
                    {
                        name: "total_jobs_completed_in_last_7_days",
                        count: 0,
                        title: $translate.instant('atheer.insights.airForms.totalJobs7Days'),
                    },
                    {
                        name: "total_jobs_in_progress",
                        count: 0,
                        title: $translate.instant('atheer.insights.airForms.totalJobsInProgress'),
                    },
                    {
                        name: "total_jobs_aborted_in_last_30_days",
                        count: 0,
                        title: $translate.instant('atheer.insights.airForms.totalJobsAborted'),
                    },
                ],
            },
        ];

        function performDataProcess() {
            vm.loadingData = false;
        }

        vm.getCount = function (columnName) {
            for (var k = 0; k < vm.dashboardModel.length; k++) {
                for (var l = 0; l < vm.dashboardModel[k].columns.length; l++) {
                    if (vm.dashboardModel[k].columns[l].name === columnName) {
                        return vm.dashboardModel[k].columns[l].count;
                    }
                }
            }
        };

        vm.setModel = function (result) {
            for (var i = 0; i < vm.dashboardModel.length; i++) {
                for (var j = 0; j < vm.dashboardModel[i].columns.length; j++) {
                    for (var key in result) {
                        if (key == vm.dashboardModel[i].columns[j].name) {
                            vm.dashboardModel[i].columns[j].count = result[key];
                            break;
                        }
                    }
                }
            }
        };
        vm.loadData = function () {
            vm.loadingData = true;
            var resource = angular.isDefined(workspaceId)
                ? Workspace.getAirformDashboard({
                      id: workspaceId,
                  }).$promise
                : Report.getAirformDashboard().$promise;

            $q.all([resource]).then(function (results) {
                vm.setModel(results[0]);
                performDataProcess();
            });
        };
        vm.loadData();
    }
})();
