(function () {
    "use strict";

    angular
        .module("atheer.smartFlow")
        .controller(
            "JobFlowMoleculesAirFormResolverController",
            JobFlowMoleculesAirFormResolverController
        );

    /* @ngInject */
    function JobFlowMoleculesAirFormResolverController(
        $mdDialog,
        JobFlow,
        setting,
        DataModel,
        SmartFlowBuilder,
        $q,
        $filter
    ) {
        var vm = this;
        vm.object = {
            dataSetId: "",
            dataSetColumnId: "",
            dataModelPropertyName: "",
            outputColumnId: "",
        };
        vm.loadingData = true;
        vm.listItems = [];
        vm.staticObject = [];
        vm.datasetObject = [];
        vm.datasetColumnsObject = [];
        vm.dataModels = [];
        vm.isStaticObjectLoaded = false;
        vm.isDynamicObjectLoaded = false;
        vm.dataset = "";

        vm.resolverTypeList = [
            { name: "Static", value: "STATIC" },
            { name: "Dataset Lookup", value: "DATASET" },
        ];
        vm.close = function () {
            $mdDialog.cancel();
        };

        vm.submit = function () {
            var rawData = angular.copy(vm.setting.properties);
            vm.setting.properties = {};
            if (rawData.resolverType === "STATIC") {
                vm.setting.properties = {
                    objectType: rawData.objectType,
                    staticResult: rawData.staticResult,
                    resolverType: rawData.resolverType,
                };
            } else if (rawData.resolverType === "DATASET") {
                vm.setting.properties = {
                    objectType: rawData.objectType,
                    dataSetQueryCriteria: rawData.dataSetQueryCriteria,
                    resolverType: rawData.resolverType,
                };
            }
            $mdDialog.hide(vm.setting);
        };

        vm.onResolverTypeChange = function () {
            if (vm.setting.properties.resolverType === "DATASET") {
                getDatasetQueryData();
            }

            if (vm.setting.properties.resolverType === "STATIC") {
                getStaticResults();
            }
        };

        vm.onSelectDataset = function () {
            vm.loadingData = true;
            JobFlow.getDatasetColumns(
                { id: vm.setting.properties.dataSetQueryCriteria.dataSetId },
                function (data) {
                    vm.loadingData = false;
                    vm.datasetColumnsObject = data;
                }
            );
        };

        function getDatasetQueryData() {
            vm.setting.properties.resolverType = "DATASET";
            vm.setting.properties.objectType = "AiRForm";
            vm.loadingData = true;
            var dataModelId = SmartFlowBuilder.getDataModelId();
            if (!dataModelId) {
                vm.loadingData = false;
                return;
            } else {
                if (!vm.setting.properties.dataSetQueryCriteria) {
                    vm.setting.properties["dataSetQueryCriteria"] = vm.object;
                    $q.all([
                        JobFlow.getDatasets().$promise,
                        JobFlow.getDataModelById({ id: dataModelId }).$promise,
                    ]).then(function (data) {
                        vm.loadingData = false;
                        vm.isDynamicObjectLoaded = true;
                        vm.datasetObject = data[0];
                        vm.dataModels = data[1].properties;
                    });
                } else if (
                    vm.setting.properties.dataSetQueryCriteria.dataSetId !== ""
                ) {
                    vm.object = vm.setting.properties.dataSetQueryCriteria;
                    $q.all([
                        JobFlow.getDatasets().$promise,
                        JobFlow.getDatasetColumns({
                            id:
                                vm.setting.properties.dataSetQueryCriteria
                                    .dataSetId,
                        }).$promise,
                        JobFlow.getDataModelById({ id: dataModelId }).$promise,
                    ]).then(function (data) {
                        vm.loadingData = false;
                        vm.isDynamicObjectLoaded = true;
                        vm.datasetObject = data[0];
                        vm.datasetColumnsObject = data[1];
                        vm.dataModels = data[2].properties;
                    });
                }
            }
        }
        function getStaticResults() {
            vm.setting.properties.resolverType = "STATIC";
            vm.setting.properties.objectType = "AiRForm";

            JobFlow.getObjectList(
                { objectType: vm.setting.properties["objectType"] },
                function (result) {
                    vm.loadingData = false;
                    vm.isStaticObjectLoaded = true;
                    vm.staticObject = result;
                }
            );
        }
        function init() {
            vm.setting = angular.copy(setting);
            if (!vm.setting.properties.resolverType) {
                vm.setting.properties.resolverType = "STATIC";
                getStaticResults();
            } else if (vm.setting.properties.resolverType === "DATASET") {
                getDatasetQueryData();
            } else {
                getStaticResults();
            }
        }

        init();
    }
})();
