(function () {
    'use strict';

    angular
        .module('atheer.smartFlow')
        .controller('JobFlowMoleculesSettingController', JobFlowMoleculesSettingController)

    /* @ngInject */
    function JobFlowMoleculesSettingController($mdDialog, $mdToast, JobFlow, cellType, setting) {
        var vm = this;

        vm.settingForm = {};
        vm.settingFields = [];
        vm.title = '';
        vm.loadingData = true;
        vm.data;
        vm.isObjectList = false;

        vm.close = function () {
            $mdDialog.cancel();
        };

        vm.submit = function () {
            if (vm.isObjectList) {
                checkForNullObjects();
            }
            $mdDialog.hide(vm.setting);
        };

        function checkForNullObjects() {
            angular.forEach(vm.setting.properties, function (property) {
                var keys = Object.keys(property);
                angular.forEach(keys, function (key) {
                    if (key == '') {
                        delete property[key];
                    }
                });
            });
        }

        function init() {
            vm.setting = angular.copy(setting);

            JobFlow.getMoleculesProperties(function (data) {
                vm.loadingData = true;
                vm.data = data;
                getCurrentCellMolecule(data);
            });
        }

        function getCurrentCellMolecule(data) {
            angular.forEach(data, function (inputElement) {
                if (inputElement.type == cellType) {
                    vm.title = inputElement.title;
                    getProperties(inputElement.properties);
                }
            });
        }

        function getProperties(properties) {
            angular.forEach(properties, function (property) {

                var field = {
                    description: property.description,
                    label: property.label,
                    name: property.name,
                    required: property.required,
                    type: property.type
                }

                if (field.type == 'LIST') {
                    field.list = property.list;
                }

                if (field.type == 'OBJECT' || field.type == 'OBJECT_LIST') {
                    vm.isObjectList = field.type == 'OBJECT_LIST' ? true : false;
                    vm.setting.properties[property.name] = vm.setting.properties[property.name] ? vm.setting.properties[property.name] : {};
                }

                vm.settingFields.push(field);
                vm.loadingData = false;
            });
        }

        init();
    }
})();
