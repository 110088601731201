(function() {
    'use strict';

    angular
        .module('atheer.cloud')
        .controller('JhiConfigurationController', JhiConfigurationController);

    JhiConfigurationController.$inject = ['$filter','JhiConfigurationService'];

    function JhiConfigurationController (filter,JhiConfigurationService) {
        var vm = this;

        vm.allConfiguration = null;
        vm.configuration = null;
        vm.query = {
                filter: '',
                limit: '10',
                order: '-id',
                page: 1
            };

        vm.filter = {
                options: {
                    debounce: 500
                }
            };

        vm.removeFilter = removeFilter;
        vm.filter.show = false;
        function removeFilter() {
            vm.filter.show = false;
            vm.query.filter = '';

            if(vm.filter.form.$dirty) {
                vm.filter.form.$setPristine();
            }
        }
        JhiConfigurationService.get().then(function(configuration) {
            vm.configuration = configuration;
        });
        JhiConfigurationService.getEnv().then(function (configuration) {
            vm.allConfiguration = configuration;
        });
    }
})();
