(function() {
    'use strict';
    angular
        .module('atheer.services')
        .factory('Workspace', Workspace);

    /* @ngInject */
    function Workspace($resource, DateUtils) {
        var resourceUrl = 'api/system/workspaces/:id';

        return $resource(resourceUrl, {
            id: '@id'
        }, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function(data) {
                    return transformResponse(data);
                }
            },
            'update': {
                method: 'PUT',
                transformResponse: function(data) {
                    return transformResponse(data);
                }
            },
            'save': {
                method: 'POST',
                transformResponse: function(data) {
                    return transformResponse(data);
                }
            },
            'saveAsync': {
                url: 'api/system/workspaces/async',
                method: 'POST',
            },
            'getWorkspaceStatus': {
                url: 'api/system/job-streams/:id',
                method: 'GET'
            },
            'getFeatures': {
                url: resourceUrl + '/features',
                method: 'GET',
                isArray: true
            },
            'updateFeatures': {
                url: resourceUrl + '/features',
                method: 'PUT'
            },
            'getDashboard': {
                url: resourceUrl + '/dashboard',
                method: 'GET'
            },
            'getConversationDashboard': {
                url: resourceUrl + '/conversation/dashboard',
                method: 'GET'
            },
            'getSessionDashboard': {
                url: resourceUrl + '/session/dashboard',
                method: 'GET'
            },
            'getContentDashboard': {
                url: resourceUrl + '/content/dashboard',
                method: 'GET'
            },
            'getAirformDashboard': {
                url: resourceUrl + '/airform/dashboard',
                method: 'GET'
            },
            'getSmartScanDashboard': {
                url: resourceUrl + '/smartscan/dashboard',
                method: 'GET'
            },
            'getSmartflowDashboard': {
                url: resourceUrl + '/smartflow/dashboard',
                method: 'GET'
            },
            'getUserDashboard': {
                url: resourceUrl + '/user/dashboard',
                method: 'GET'
            },
            'getInsightSettings': {
                url: resourceUrl + '/insight-settings',
                method: 'GET'
            },
            'setInsightSettings': {
                url: resourceUrl + '/insight-settings',
                method: 'PUT'
            },
            'activateInsights': {
                url: resourceUrl + '/activate-insights',
                method: 'PUT'
            },
        });

        function transformResponse(data) {
            data = angular.fromJson(data);
            data.start_date = DateUtils.convertLocalDateFromServer(data.start_date);
            data.end_date = DateUtils.convertLocalDateFromServer(data.end_date);
            return data;
        }
    }
})();
