(function () {
    'use strict';

    angular
        .module('atheer', [
            'tmh.dynamicLocale',
            'pascalprecht.translate',
            'LocalStorageModule',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ui.router',
            'permission',
            'ngAnimate',
            'ngSanitize',
            'ngMessages',
            'ngMaterial',
            'angularMoment',
            'md.data.table',
            'ng-jwplayer',
            'froala',
            'signature',
            angularDragula(angular),
            'mdPickers',
            'angAccordion',
            'dibari.angular-ellipsis',
            'ngTagCloud',
            'formlyMaterial',
            'emguo.poller',
            'ng.deviceDetector',
            'highcharts-ng',
            'ngclipboard',
            'pubnub.angular.service',
            'ngMaterialDateRangePicker',
            'ng-showdown',
            //'atheer.profiler',
            'atheer.services',
            'atheer.core',
            'atheer.conversation',
            'atheer.session',
            'atheer.user',
            'atheer.public',
            'atheer.team',
            'atheer.insights',
            'atheer.system',
            'atheer.cloud',
            'atheer.content',
            'atheer.topic',
            'atheer.smartFlow',
            'atheer.datamodel',
            'atheer.workspace',
            'atheer.anchor',
            'atheer.airform',
            'atheer.job',
            'atheer.ticket',
            'atheer.sdk',
            'atheer.doc',
            'atheer.home'
            //atheer-needle-add-module - Atheer will add modules here
        ])
        .run(run);

    function run($rootScope, stateHandler, translationHandler, $location, $cookieStore, $http, Principal, LoginService, $state, AuthServerProvider, Feature, Auth, idleHandler) {
        var host = $location.host();

        $rootScope.isAppWorkspace = false;

        stateHandler.initialize();
        translationHandler.initialize();
        idleHandler.initialize();

        if (angular.isDefined(host) && host.startsWith("app.")) {
            $rootScope.isAppWorkspace = true;
        } else {
            $rootScope.isAppWorkspace = false;
            if (host != 'localhost') {
                Auth.validateWorkspaceName(host.split('.')[0])
                    .then(function (companySetting) {
                        $rootScope.companySetting = companySetting.data;
                    })
                    .catch(function () {
                        $state.go('account.noaccess');
                    });
            }
        }

        $rootScope.$on('$locationChangeStart', function (event, newUrl, oldUrl) {
            if (Principal.isAuthenticated() || AuthServerProvider.hasValidToken()) {
                if ($location.path() == '/') {
                    if ($location.path().startsWith('/deeplink')) {
                        $location.path($location.path())
                    } else {
                        if ($rootScope.isMasterWorkspace) {
                            $location.path('/system/dashboard');
                        } else if (angular.isDefined($rootScope.baseHome)) {
                            $location.path($rootScope.baseHome);
                        } else {
                            $location.path('/home');
                        }
                    }
                }
                if (($location.path() == '/airforms' || $location.path() == '/adv-insights') && $rootScope.isAppletEditActive) {
                    console.log('handling angular redirect for: ' + $location.path())
                    event.preventDefault();
                    $location.replace();
                    var iFrame = document.getElementById('ngxFrame').contentWindow;
                    var targetOrigin = location.hostname === "localhost"
                        ? "https://" + location.hostname + ":4200"
                        : location.origin;
                    iFrame.postMessage({ 'dirtyCheckAppletData': $rootScope.isAppletEditActive }, targetOrigin);
                }

            } else if ($location.path() == '/signout') {
                $location.path('/signout');
            } else if ($location.path() == '/signup') {
                $location.path('/signup');
            } else if ($location.path().startsWith('/invite')) {
                $location.path($location.path());
            } else if ($location.path().startsWith('/signup/')) {
                $location.path($location.path());
            } else if ($location.path() == '/forgot') {
                $location.path('/forgot');
            } else if ($location.path() == '/reset') {
                $location.path('/reset');
            } else if ($rootScope.isAppWorkspace && $location.path().startsWith('/letsgo')) {
                $location.path($location.path());
            } else if ($location.path() == '/join') {
                $location.path('/join');
            } else if ($location.path().startsWith('/join')) {
                $location.path($location.path());
            } else if ($location.path().startsWith('/guest')) {
                $location.path($location.path());
            } else if ($location.path().startsWith('/signin/noaccess')) {
                $location.path($location.path())
            } else if ($location.path().startsWith('/atheer-signin')) {
                $location.path($location.path())
            } else if ($location.path().startsWith('/deeplink')) {
                $location.path($location.path())
            } else if ($location.path().startsWith('/public')) {
                $location.path($location.path())
            } else {
                if ($location.path() !== '/') {
                    $location.path('/signin');
                }
            }
        });
    }
})();
