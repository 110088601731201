(function() {
    'use strict';

    angular
        .module('atheer.core')
        .directive('scrollToBottom', scrollToBottom);

    /* @ngInject */
    function scrollToBottom($timeout, $window) {
        var directive = {
            link: link,
            restrict: 'A',
            scope: {
                'scrollToBottom': '='
            }
        };
        return directive;

        function link($scope, $element, attrs) {
            $scope.$watch('scrollToBottom', function(newVal) {
                if (newVal) {
                    $timeout(function() {
                        $element[0].scrollTop = $element[0].scrollHeight;
                    }, 0);
                }
            });
        }
    }

})();
