(function () {
    'use strict';

    angular
        .module('atheer.user')
        .controller('QrCodeDialogController', QrCodeDialogController);

    /* @ngInject */
    function QrCodeDialogController($rootScope, $q, $scope, $state, $timeout, $mdDialog, filter, Principal, $translate, $mdToast, $filter, User, $mdMedia, $window, $http) {
        var vm = this;

        var expireTime = 5 * 60 * 1000;

        vm.startTime = new Date().getTime() + (expireTime);
        vm.codeExpired = false;
        vm.isQRLoading = true;
        vm.userId = filter == null ? 'me' : filter;
        vm.qrCodeImage;

        function getQRCode() {
            vm.isQRLoading = true;
            var url = '/api/users/' + vm.userId + '/qrcode'
            $http.get(url, { responseType: 'blob' }).then(function (response) {
                vm.qrCodeImage = URL.createObjectURL(response.data);
                vm.isQRLoading = false;
                startTimer();
            })
        }

        vm.close = function () {
            $mdDialog.cancel();
        };

        vm.refreshQRCode = function () {
            vm.startTime = new Date().getTime() + (expireTime);
            vm.codeExpired = false;
            getQRCode();
        };

        function startTimer() {
            $timeout(function () {
                vm.codeExpired = true;
            }, expireTime);
        };

        getQRCode();
    }
})();
