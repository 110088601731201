(function() {
    'use strict';

    angular
        .module('atheer.core')
        .directive('fpTypeTracking', fpTypeTracking);

    /* @ngInject */
    function fpTypeTracking(ConversationTypingService, Feature) {
        var directive = {
            link: link,
            controller: fpTypeTrackingController,
            restrict: 'A'
        };
        return directive;

        function link($scope, $element, attrs) {
            $scope.$watch(attrs['ngModel'], function(input) {
                // When to start Typing ?
                // Content is not empty and was not typing before
                if (!_.isEmpty(input) && !ConversationTypingService.isCurrentUserTyping) {
                    ConversationTypingService.startTyping();
                    $scope.stopTypingScheduler();
                } else if (!_.isEmpty(input) && ConversationTypingService.isCurrentUserTyping) {
                    $scope.stopTypingScheduler();
                } else if (_.isEmpty(input) && ConversationTypingService.isCurrentUserTyping) {
                    $scope.stopTypingScheduler();
                } else {
                    // When user send the message, we need to immediately send stop typing signal
                    ConversationTypingService.stopTyping();
                }
            });
        };

        /* @ngInject */
        function fpTypeTrackingController($scope) {
            // Time before the stop typing event is fired after stopping to type.
            $scope.stopTypingTime = 5000

            // Scheduler that trigger stopTyping if the function has not been invoced after stopTypingTime
            $scope.stopTypingScheduler = _.debounce(ConversationTypingService.stopTyping, $scope.stopTypingTime)
        }
    }
})();
