(function () {
    'use strict';
    angular.
        module("atheer").
        directive("fpGroupAvatar", fpGroupAvatar);

    function fpGroupAvatar() {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                boxSize: '=',
                iconSize: '=',
                color: '='
            },
            templateUrl: 'core/components/avatar/group-avatar.tmpl.html'
        };
    }
}());
