(function() {
    'use strict';
    angular
        .module('atheer.services')
        .factory('Notification', Notification);

    /* @ngInject */
    function Notification($resource) {
        var resourceUrl = 'api/system/notifications/:id';

        return $resource(resourceUrl, {
            id: '@id'
        }, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function(data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': {
                method: 'PUT'
            },
            'test': {
                url: resourceUrl + '/test',
                method: 'POST'
            }
        });
    }
})();
