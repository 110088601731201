(function () {
    'use strict';
    angular
        .module('atheer.team')
        .directive('fpTeamDetailBuilder', fpTeamDetailBuilder);

    function fpTeamDetailBuilder() {
        var directive = {
            scope: {
                teamModel: '='
            },
            bindToController: true,
            controllerAs: 'vm',
            restrict: 'E',
            templateUrl: 'modules/team/team-detail-builder.tmpl.html',
            controller: TeamDetailBuilderDirectiveController
        };
        return directive;
    }

    /* @ngInject */
    function TeamDetailBuilderDirectiveController($rootScope, $scope, $q, ParseLinks, $mdSidenav, AlertService, $element, $filter, $mdDialog, MetaData, Setting, Team, FIELD_CONDITION_CONSTANT, DateUtils, $mdPanel, $timeout, triSettings, paginationConstants, ToastService) {
        var vm = this;

        vm.originalTeam = angular.copy(vm.teamModel);
        vm.primaryColor = triSettings.getSkinModel().primary_color_hex;
        vm.secondaryColor = triSettings.getSkinModel().secondary_color_hex;
        vm.isMasterWorkspace = $rootScope.isMasterWorkspace;
        vm.matchSelected = false;
        vm.timezones = [];
        vm.languages = [];
        vm.metaFieldGroups = [];
        vm.selectedCondition = null;
        vm.showReset = false;

        vm.predicate = "id";
        vm.conditionListOptions = [];

        vm.getFieldLabel = function (condition) {
            var label = null;
            var fieldMeta;
            var fieldGroupMeta = $filter('filter')(vm.metaFieldGroups, {
                'name': condition.field_group_name
            })[0];
            //filter gets array of all fields which contain condition.field_name
            var fieldMetaArray = $filter('filter')(fieldGroupMeta.fields, {
                'name': condition.field_name
            });
            for (var cnt = 0; cnt < fieldMetaArray.length; cnt++) {
                if (fieldMetaArray[cnt].name === condition.field_name) {
                    fieldMeta = fieldMetaArray[cnt];
                    break;
                }
            }

            if (condition.data_type === "LIST" && condition.value) {
                var selectedListOption = $filter('filter')(fieldMeta.list_options, {
                    'option_id': condition.value
                })[0];
                vm.conditionListOptions = fieldMeta.list_options;
                condition.displayValue = selectedListOption.label;
            }
            if (condition.data_type === "LANGUAGE" && condition.value) {
                var selectedListOption = $filter('filter')(vm.languages, {
                    'name': condition.value
                })[0];
                vm.conditionListOptions = vm.languages;
                condition.displayValue = selectedListOption.description;
            }
            if (condition.data_type === "TIMEZONE" && condition.value) {
                var selectedListOption = $filter('filter')(vm.timezones, {
                    'timezone_no': condition.value
                })[0];
                vm.conditionListOptions = vm.timezones;
                condition.displayValue = selectedListOption.display_name;
            }
            if (condition.data_type === "OBJECT") {
                var operator = $filter('filter')(FIELD_CONDITION_CONSTANT[condition.data_type][condition.field_name], {
                    value: condition.operator
                }, true)[0];
                condition.displayValue = condition.value ? condition.value.name : '';
            }
            else if (condition.data_type === "BOOLEAN") {
                var operator;
                if (condition.value === null) {
                    operator = $filter('filter')(FIELD_CONDITION_CONSTANT[condition.data_type], {
                        defaultValue: ""
                    }, true)[0];
                } else {
                    operator = $filter('filter')(FIELD_CONDITION_CONSTANT[condition.data_type], {
                        defaultValue: condition.value.toString()
                    }, true)[0];
                }
            }
            else {
                var operator = $filter('filter')(FIELD_CONDITION_CONSTANT[condition.data_type], {
                    value: condition.operator
                }, true)[0];
            }

            if (condition.field_group_name == 'profile') {
                label = fieldMeta.label + ' ' + $filter('translate')(operator.label);
            } else {
                label = fieldGroupMeta.label + ':' + fieldMeta.label + ' ' + $filter('translate')(operator.label);
            }

            if (condition.value != null && condition.data_type !== "BOOLEAN") {
                label = label + ' ' + getFieldDisplayValue(condition);
            }
            return label;
        };

        vm.getConditionBackgroundColor = function (condition, conditionFocus) {
            if (vm.isMissingValue(condition)) {
                return vm.secondaryColor;
            }

            if (condition == vm.selectedCondition) {
                return vm.primaryColor;
            }
        };

        vm.isMissingValue = function (condition) {
            if (condition.data_type === "OBJECT") {
                var operator = $filter('filter')(FIELD_CONDITION_CONSTANT[condition.data_type][condition.field_name], {
                    value: condition.operator
                }, true)[0];
            } else {
                var operator = $filter('filter')(FIELD_CONDITION_CONSTANT[condition.data_type], {
                    value: condition.operator
                }, true)[0];
            }

            if (operator.showInput && (condition.value == null || condition.value.length <= 0)) {
                return true;
            } else {
                return false;
            }
        };

        vm.removeField = function (groupIndex, fieldIndex) {
            vm.teamModel.criteria.condition_groups[groupIndex].conditions.splice(fieldIndex, 1);
            if (vm.teamModel.criteria.condition_groups[groupIndex].conditions.length == 0) {
                vm.teamModel.criteria.condition_groups.splice(groupIndex, 1);
            }
            // firing an event downwards
            $scope.$emit('updateUserList');
            updateResetFilter();
        };

        vm.showMatchType = function (ev) {
            vm.matchSelected = true;

            var position = $mdPanel.newPanelPosition()
                .relativeTo('#match-type')
                .addPanelPosition($mdPanel.xPosition.OFFSET_END, $mdPanel.yPosition.ALIGN_TOPS);

            var config = {
                attachTo: angular.element(document.body),
                controller: "MatchSelectionPanelController",
                controllerAs: 'vm',
                templateUrl: 'core/components/filter/match-selection-panel.tmpl.html',
                position: position,
                openFrom: ev,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: false,
                zIndex: 2,
                onCloseSuccess: function (panelRef, reason) {
                    vm.matchSelected = false;
                    if (panelRef.matchAll != vm.teamModel.criteria.match_all) {
                        vm.teamModel.criteria.match_all = panelRef.matchAll;
                        $scope.$emit('updateUserList');
                        updateResetFilter()
                    }
                }
            };
            $mdPanel.open(config);
        };

        vm.showFields = function (ev) {
            var position = $mdPanel.newPanelPosition()
                .relativeTo('#add-filter')
                .addPanelPosition($mdPanel.xPosition.OFFSET_END, $mdPanel.yPosition.ALIGN_TOPS);


            var config = {
                attachTo: angular.element(document.body),
                controller: "FieldSelectionPanelController",
                controllerAs: 'vm',
                templateUrl: 'core/components/filter/field-selection-panel.tmpl.html',
                position: position,
                locals: {
                    metaFieldGroups: vm.metaFieldGroups,
                    conditionGroups: vm.teamModel.criteria.condition_groups
                },
                openFrom: ev,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: false,
                zIndex: 2,
                onCloseSuccess: function (panelRef, reason) {
                    if (panelRef.condition != null) {
                        $timeout(function () {
                            vm.showFieldSettting(panelRef.condition);
                        }, 200);
                    }
                    updateResetFilter();
                }
            };
            $mdPanel.open(config);
        };

        vm.showFieldSettting = function (condition) {
            vm.selectedCondition = condition;
            var position = $mdPanel.newPanelPosition()
                .relativeTo('#' + condition.field_group_name + '-' + condition.field_name)
                .addPanelPosition($mdPanel.xPosition.OFFSET_END, $mdPanel.yPosition.ALIGN_TOPS);

            if (condition.data_type === 'LANGUAGE') {
                vm.conditionListOptions = vm.languages;
            }
            else if (condition.data_type === 'TIMEZONE') {
                vm.conditionListOptions = vm.timezones
            } else {
                vm.conditionListOptions = [];
            }
            var config = {
                attachTo: angular.element(document.body),
                controller: "FieldSettingPanelController",
                controllerAs: 'vm',
                templateUrl: 'core/components/filter/field-setting-panel.tmpl.html',
                position: position,
                locals: {
                    metaFieldGroups: vm.metaFieldGroups,
                    condition: condition,
                    conditionListOptions: vm.conditionListOptions
                },
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: false,
                zIndex: 2,
                onCloseSuccess: function (panelRef, reason) {
                    vm.selectedCondition = null;
                    $scope.$emit('updateUserList');
                    updateResetFilter()
                }
            };

            $mdPanel.open(config);
        };

        vm.resetFilter = function () {
            vm.teamModel.criteria = vm.originalTeam.criteria;
            $scope.$emit('updateUserList');
            updateResetFilter();
        };

        function getFieldDisplayValue(condition) {
            if (!angular.isDefined(condition.value) || condition.value == null) {
                return null;
            } else {
                if (condition.data_type == 'DATE') {
                    if (condition.operator == 'EXACTLY') {
                        return condition.value + ' days ago';
                    } else if (condition.operator == 'GREATER_THAN') {
                        return condition.value + ' days ago';
                    } else if (condition.operator == 'GREATER_THAN_OR_EQUAL') {
                        return condition.value + ' days ago';
                    } else if (condition.operator == 'LESS_THAN') {
                        return condition.value + ' days ago';
                    } else if (condition.operator == 'LESS_THAN_OR_EQUAL') {
                        return condition.value + ' days ago';
                    } else if (condition.operator == 'WITHIN') {
                        return condition.value + ' days';
                    } else if (condition.operator == 'BETWEEN') {
                        var fromDate = (angular.isDefined(condition.value.from_date) && condition.value.from_date != null ? $filter('date')(condition.value.from_date, "MM-dd-yyyy") : null);
                        var toDate = (angular.isDefined(condition.value.to_date) && condition.value.to_date != null ? $filter('date')(condition.value.to_date, "MM-dd-yyyy") : null);
                        return (fromDate == null ? '' : fromDate) + ' and ' + (toDate == null ? '' : toDate);
                    } else if (condition.operator == 'IS') {
                        if (condition.value == 'TODAY') {
                            return 'today';
                        } else if (condition.value == 'YESTERDAY') {
                            return 'yesterday';
                        } else if (condition.value == 'START_OF_WEEK') {
                            return 'start of the week';
                        } else if (condition.value == 'END_OF_WEEK') {
                            return 'end of the week';
                        } else if (condition.value == 'START_OF_MONTH') {
                            return 'start of the month';
                        } else if (condition.value == 'END_OF_MONTH') {
                            return 'end of the month';
                        } else {
                            return condition.value;
                        }
                    } else if (condition.operator == 'AFTER') {
                        return $filter('date')(condition.value, "MM-dd-yyyy");
                    } else if (condition.operator == 'BEFORE') {
                        return $filter('date')(condition.value, "MM-dd-yyyy");
                    } else if (condition.operator == 'ON') {
                        return $filter('date')(condition.value, "MM-dd-yyyy");
                    } else {
                        return condition.value;
                    }
                }
                else if (condition.data_type == 'LIST' || condition.data_type == 'LANGUAGE' || condition.data_type == 'TIMEZONE' || condition.data_type == 'OBJECT') {
                    return condition.displayValue;
                }
                else {
                    return condition.value;
                }
            }
        };

        function updateResetFilter() {
            if ($rootScope._.isEqual(angular.toJson(vm.originalTeam.criteria), angular.toJson(vm.teamModel.criteria))) {
                vm.showReset = false;
            } else {
                vm.showReset = true;
            }
        };

        function initTeam() {
            if (!angular.isDefined(vm.teamModel.criteria) || vm.teamModel.criteria == null) {
                vm.teamModel.criteria = {
                    match_all: true
                }
            }
        };

        function loadAll() {
            vm.loadingData = true;
            initTeam();
            $q.all([
                MetaData.get({
                    id: 'user',
                    is_name: true,
                    add_default_properties: true
                }).$promise,
                Setting.getSystemTimezones({
                    size: 100
                }).$promise,
                Setting.getSystemLanguages().$promise
            ]).then(function (data) {
                vm.metaFieldGroups = data[0].field_groups;
                vm.timezones = data[1];
                vm.languages = data[2];
                vm.loadingData = false;
            });
        };

        //calls when filters are updated
        var updateTeamListener = $rootScope.$on('updateTeam', function (event) {
            vm.originalTeam = vm.teamModel;
            updateResetFilter();
        });

        // Unregister
        $scope.$on('$destroy', function () {
            updateTeamListener();
        });

        loadAll();
    }
})();
