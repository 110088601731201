(function () {
    'use strict';

    angular
        .module('atheer.session')
        .directive('sessionZoomPosition', sessionZoomPosition);

    function sessionZoomPosition() {
        var directive = {
            scope: {
                zoom: '=',
                onPositionChange: '=',
                onPositionRelease: '='
            },
            bindToController: true,
            controller: sessionZoomPositionController,
            controllerAs: 'vm',
            template: '<div style="position: absolute; height: 160px; width: 240px; right: 30px; bottom: 6px; z-index: 40; border: 1px solid gray">\n' +
                '            <div class="small-box" style="position: absolute; cursor: move;\n' +
                '             width: 50%; height: 50%;border:1px solid #000; background-color: #9999993d;"></div>\n' +
                '            <div ng-click="vm.onClick($event)" style="position: absolute; height: 160px; width: 240px; z-index: -1;">\n' +
                '            </div>\n' +
                '        </div>',
            restrict: 'E',
        };
        return directive;
    };

    /* @ngInject */
    function sessionZoomPositionController($scope, $element) {
        var vm = this;
        vm.boxWidth = 120;
        vm.boxHeight = 80;
        vm.largeBoxWidth = $element[0].children[0].clientWidth;
        vm.largeBoxHeight = $element[0].children[0].clientHeight;

        var smallBox = $element[0].querySelector('.small-box');
        dragElement(smallBox);

        $scope.$watch('vm.zoom', function (zoom) {
            if (zoom) {
                var zoomPercent = 100 / zoom;
                smallBox.style.width = zoomPercent + '%';
                smallBox.style.height = zoomPercent + '%';
                var width = zoomPercent * vm.largeBoxWidth;
                var height = zoomPercent * vm.largeBoxHeight;
                vm.boxWidth = width / 100;
                vm.boxHeight = height / 100;
                limitBorders((vm.largeBoxWidth - vm.boxWidth)/2, (vm.largeBoxHeight - vm.boxHeight)/2);
                vm.onPositionRelease();
            }
        });

        function limitBorders(x, y) {
            if (x >= vm.largeBoxWidth / (2 * vm.zoom)) {
                x = Math.min(x, vm.largeBoxWidth - vm.boxWidth);
            } else {
                x = Math.max(x, 0);
            }

            if (y >= vm.largeBoxHeight / (2 * vm.zoom)) {
                y = Math.min(y, vm.largeBoxHeight - vm.boxHeight);
            } else {
                y = Math.max(y, 0);
            }

            if (x + vm.boxWidth > vm.largeBoxWidth) {
                x = vm.largeBoxWidth - vm.boxWidth;
            }

            if (y + vm.boxHeight > vm.largeBoxHeight) {
                y = vm.largeBoxHeight - vm.boxHeight;
            }

            smallBox.style.left = x + 'px';
            smallBox.style.top = y + 'px';
            vm.onPositionChange((x + (vm.boxWidth / 2)) / vm.largeBoxWidth, (y + (vm.boxHeight / 2)) / vm.largeBoxHeight);
        };

        vm.onClick = function (event) {
            // 2 is to get half-width or half-height of the small box
            limitBorders(event.offsetX - (vm.boxWidth / 2), event.offsetY - (vm.boxHeight / 2));
        };

        function dragElement(element) {
            var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
            element.onmousedown = dragMouseDown;

            function dragMouseDown(e) {
                e = e || window.event;
                e.preventDefault();
                pos3 = e.clientX;
                pos4 = e.clientY;
                document.onmouseup = closeDragElement;
                document.onmousemove = elementDrag;
            };

            function elementDrag(e) {
                e = e || window.event;
                e.preventDefault();
                pos1 = pos3 - e.clientX;
                pos2 = pos4 - e.clientY;
                pos3 = e.clientX;
                pos4 = e.clientY;
                limitBorders(element.offsetLeft - pos1, element.offsetTop - pos2);
            };

            function closeDragElement() {
                vm.onPositionRelease();
                document.onmouseup = null;
                document.onmousemove = null;
            };
        };
    };
})();