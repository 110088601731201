(function() {
    'use strict';

    angular
        .module('atheer.system')
        .controller('AppController', AppController);

    /* @ngInject */
    function AppController($scope, $state, App, ParseLinks, AlertService, pagingParams, paginationConstants, $mdDialog, $mdToast, $stateParams, INTEGRATION_APP_META, Principal) {
        var vm = this;

        vm.loadAll = loadAll;

        vm.editObject = editObject;

        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.pageSize = 100;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.getAppMeta = getAppMeta;
        vm.isWorkspaceAdmin = Principal.isWorkspaceAdmin();

        function getAppMeta(appName){
            return INTEGRATION_APP_META[appName];
        }

        vm.query = {
            filter: '',
            limit: vm.pageSize,
            order: '-id',
            page: 1
        };

        vm.filter = {
            show: false,
            options: {
                debounce: 500
            }
        };

        function loadAll() {
            App.query({
                page: pagingParams.page - 1,
                size: vm.pageSize,
                sort: sort(),
                query: getQueryString()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.apps = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function getQueryString() {
            if(vm.isWorkspaceAdmin) {
                return null;
            } else {
                return  'enabled==true';
            }
        }

        function editObject(appName) {
            $state.go('storm.admin-app-detail', {
                'name': appName
            });
        }
        vm.loadAll();
    }
})();
