(function() {
    'use strict';
    angular
        .module('atheer')
        .directive('fpFooter', fpFooter)

    function fpFooter() {
        var directive = {
            restrict: 'E',
            scope: {
                'key': '@'
            },
            controller: footerDirectiveController,
            controllerAs: 'vm',
            templateUrl: 'core/components/footer/footer.tmpl.html'
        };
        return directive;
    }

    /* @ngInject */
    function footerDirectiveController($rootScope, $scope, $timeout, $window, Feature, deviceDetector, $location) {
        var vm = this;
        vm.showTermsAndPrivacy = true;
        vm.poweredByLink = "http://www.atheerair.com/";

        vm.termsLink = "http://www.atheerair.com/";
        vm.privacyLink = "https://www.atheerair.com/privacy-policy/";

        if($rootScope.companySetting) {
            vm.termsLink = $rootScope.companySetting.terms_url;
            vm.privacyLink = $rootScope.companySetting.privacy_policy_url;
        };

        var host = $location.host();
        if (angular.isDefined(host) && host.startsWith("rigpartner.")) {
            vm.termsLink = "https://rigquip.com/terms-conditions/";
            vm.privacyLink = "https://rigquip.com/privacy-policy/";
        };

        vm.buildInfoSettings = $rootScope.buildInfo;
        vm.isMobileBrowser = deviceDetector.isMobile();
        vm.showPoweredBy = Feature.isActive('feature_system_show_powered_by');

        if($location.path().startsWith('/join')) {
            vm.showTermsAndPrivacy =  false;
        }
    }
})();
