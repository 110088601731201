(function () {
    'use strict';

    angular
        .module('atheer.conversation')
        .controller('ConversationDetailController', ConversationDetailController);

    /* @ngInject */
    function ConversationDetailController($rootScope, $scope, $state, $q, $filter, Principal, Conversation, AlertService, $mdDialog, $mdToast, id, ConversationModelService, ConversationTypingService, triSettings, ToastService, deviceDetector) {
        var vm = this;

        vm.permissions = {
            create1on1: 'create-1-on-1-conversation',
            createGroup: 'create-group-conversation',
            update: 'update-conversation',
        };

        var refreshConversationListener = null;
        var conversationMessageInternalListener = null;

        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.userId = Principal.getUserId();
        vm.primaryColor = triSettings.getSkinModel().primary_color_hex;
        vm.secondaryColor = triSettings.getSkinModel().secondary_color_hex;

        vm.conversation = null;
        vm.conversationId = id;

        vm.invitedUserIds = [];
        vm.showConversationInfo = false;

        vm.getConverstionImageUrl = function (conversation) {
            if (conversation.type == 'PRIVATE_USER') {
                return conversation.user_info.picture_id ? '/media/pictures/' + conversation.user_info.picture_id : 'assets/images/avatars/user-avatar.png';
            } else if (conversation.type == 'PRIVATE_GROUP') {
                return conversation.picture_id ? '/media/pictures/' + conversation.picture_id : 'assets/images/avatars/group-avatar.png';
            } else if (conversation.type == 'PUBLIC_GROUP') {
                return conversation.picture_id ? '/media/pictures/' + conversation.picture_id : 'assets/images/avatars/group-avatar.png';
            } else if (conversation.type == 'PRIVATE_AUDIENCE') {
                return conversation.picture_id ? '/media/pictures/' + conversation.picture_id : 'assets/images/avatars/group-avatar.png';
            };
        };

        vm.getUserInfoMessage = function (message) {
            var user = Principal.getUserInfo();
            return user.first_name + ' ' + user.last_name;
        };

        vm.getConverstionTitle = function (conversation) {
            if (conversation.type == 'PRIVATE_USER') {
                return conversation.user_info.first_name + ' ' + conversation.user_info.last_name;
            } else if (conversation.type == 'PRIVATE_GROUP') {
                return conversation.name;
            } else if (conversation.type == 'PUBLIC_GROUP') {
                return conversation.name;
            } else if (conversation.type == 'PRIVATE_AUDIENCE') {
                return conversation.name;
            };
        };

        vm.getConverstionLastMessage = function (conversation) {
            if (conversation && conversation.last_message) {
                return conversation.last_message.body;
            }
        };

        vm.inviteUsersToConversation = function () {
            vm.invitedUserIds = [];
            if (vm.invitedUserIds.length == 0 && vm.conversation && vm.conversation.users) {
                for (var i = 0; i < vm.conversation.users.length; i++) {
                    if (vm.conversation.users[i].left == false) {
                        vm.invitedUserIds.push(vm.conversation.users[i].user_id);
                    }
                }
            }

            $mdDialog.show({
                templateUrl: 'modules/user/user-picker.tmpl.html',
                controller: 'UserPickerController',
                controllerAs: 'vm',
                locals: {
                    filter: vm.invitedUserIds,
                    allowMultiSelect: true,
                    userslist: [],
                    isSession: false,
                    isAdmin: false,
                    isScheduledSession: false,
                    isConversationSession: false,
                    isSmartScan: false
                }
            })
                .then(function (selectedItems) {
                    var invitingUsers = [];
                    for (var i = 0; i < selectedItems.length; i++) {
                        vm.invitedUserIds.push(selectedItems[i].id);
                        invitingUsers.push(selectedItems[i].id);
                    }
                    inviteGroupConversationUsers(invitingUsers)
                });
        };

        vm.deleteConversation = function () {
            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.conversation.deleteThisConversation'))
                .ariaLabel($filter('translate')('atheer.conversation.deleteThisConversation'))
                .ok($filter('translate')('entity.action.delete'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function () {
                Conversation.delete({
                    id: vm.conversation.id
                }, function () {
                    var message = {
                        id: vm.conversation.id
                    };

                    ConversationModelService.publishConvMessage(vm.conversationId, 'CONVERSATION_DELETED', message);

                    //unsubscribe to channel
                    ConversationModelService.unSubscribe(vm.conversation);

                    $scope.$emit('conversationMessageInternal', {
                        type: 'CONVERSATION_DELETED',
                        id: vm.conversation.id
                    });

                    AlertService.displayToast($mdToast);
                });
            });
        };

        vm.archiveConversation = function () {
            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.conversation.archiveThisConversation'))
                .ariaLabel($filter('translate')('atheer.conversation.archiveThisConversation'))
                .ok($filter('translate')('entity.action.archive'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function () {
                Conversation.archive({
                    id: vm.conversation.id
                }, function () {
                    var message = {
                        id: vm.conversation.id
                    };

                    ConversationModelService.publishConvMessage(vm.conversationId, 'CONVERSATION_ARCHIVED', message);

                    $scope.$emit('conversationMessageInternal', {
                        type: 'CONVERSATION_ARCHIVED',
                        id: vm.conversation.id
                    });
                });
            });
        };

        vm.isPinned = function () {
            if (vm.conversation && vm.conversation.users.length > 0) {
                for (var i = 0; i < vm.conversation.users.length; i++) {
                    if (vm.conversation.users[i].user_id == vm.userId && vm.conversation.users[i].pinned) {
                        return true;
                    }
                };
            }

            return false;
        }

        vm.pinConversation = function () {
            Conversation.pin({
                id: vm.conversation.id
            }, function () {
                ToastService.displayToast($filter('translate')('atheer.conversation.conversationPinned'), 3000);

                var message = {
                    id: vm.conversation.id
                };

                ConversationModelService.publishConvMessage(vm.conversationId, 'CONVERSATION_PINNED', message);

                $scope.$emit('conversationMessageInternal', {
                    type: 'CONVERSATION_PINNED',
                    id: vm.conversation.id
                });
            });
        }

        vm.startSession = function () {
            $mdDialog.show({
                templateUrl: 'modules/session/session-start-dialog.tmpl.html',
                clickOutsideToClose: true,
                controller: "SessionStartController",
                controllerAs: "vm",
                locals: {
                    filter: vm.conversation.type == 'PRIVATE_USER' ? "private_conversation" : "group_conversation",
                    conversation: vm.conversation,
                    isMobileBrowser: deviceDetector.isMobile()
                }
            }).then(function (result) {

            });
        };

        vm.unPinConversation = function () {
            Conversation.unpin({
                id: vm.conversation.id
            }, function () {
                ToastService.displayToast($filter('translate')('atheer.conversation.conversationUnpinned'), 3000);

                var message = {
                    id: vm.conversation.id
                };

                ConversationModelService.publishConvMessage(vm.conversationId, 'CONVERSATION_UNPINNED', message);

                $scope.$emit('conversationMessageInternal', {
                    type: 'CONVERSATION_UNPINNED',
                    id: vm.conversation.id
                });
            });
        }

        vm.leaveConversation = function () {
            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.conversation.leaveThisConversation'))
                .ariaLabel($filter('translate')('atheer.conversation.leaveThisConversation'))
                .ok($filter('translate')('entity.action.leave'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function () {
                var userIDs = [vm.userId];
                Conversation.leave({
                    id: vm.conversation.id,
                    users: userIDs
                }, function () {
                    var message = {
                        id: vm.conversation.id
                    };

                    ConversationModelService.publishConvMessage(vm.conversationId, 'CONVERSATION_USER_LEFT', message);

                    $scope.$emit('conversationMessageInternal', {
                        type: 'CONVERSATION_USER_LEFT',
                        id: vm.conversation.id
                    });
                });
            });
        };

        vm.editGroupConversation = function () {
            $mdDialog.show({
                templateUrl: 'modules/conversation/conversation-group-dialog.tmpl.html',
                clickOutsideToClose: true,
                controller: "ConversationGroupController",
                controllerAs: "vm",
                locals: {
                    filter: vm.conversation
                }
            }).then(function (conversation) {
                vm.conversation = conversation;
                updateGroupConversation(conversation);
            }).finally(function () { });
        };

        // watches
        //calls when hit menu on sidebar to open sub menus in side bar
        refreshConversationListener = $rootScope.$on('refreshConversation', function (event, conversation) {
            vm.conversation = conversation;
            vm.conversationId = vm.conversation != null ? vm.conversation.id : null;
            if (vm.conversation != null) {
                initTypingSevice();
            }
        });

        conversationMessageInternalListener = $rootScope.$on('conversationMessageInternal', function (event, messageInfo) {
            if (messageInfo.type == 'CLOSE_CONVERSATION_INFO') {
                vm.showConversationInfo = false;
            } else if (messageInfo.type == 'CONVERSATION_ADD_USERS') {
                vm.inviteUsersToConversation();
            } else if (messageInfo.type == 'EDIT_CONVERSATION') {
                vm.editGroupConversation();
            };
        });

        // Unregister
        $scope.$on('$destroy', function () {
            refreshConversationListener = null;
            conversationMessageInternalListener = null;
            ConversationModelService.destroy();
        });

        function updateGroupConversation(conversation) {
            Conversation.update(conversation, onSaveSuccess, onSaveError);

            function onSaveSuccess(result) {
                ConversationModelService.publishNewConversation(vm.userId, result, 'REFRESH_CONVERSATION');

                ToastService.displayToast($filter('translate')('atheer.conversation.conversationUpdated'), 3000);

                $scope.$emit('conversationMessageInternal', {
                    type: 'CONVERSATION_UPDATED',
                    conversation: vm.conversation
                });
            };

            function onSaveError() {

            };
        };

        function inviteGroupConversationUsers(userIds) {
            Conversation.invite({
                id: vm.conversation.id,
                users: userIds
            }, onSaveSuccess, onSaveError);

            function onSaveSuccess(result) {
                ConversationModelService.publishNewConversation(vm.userId, result, 'REFRESH_CONVERSATION');

                ToastService.displayToast(userIds.length + $filter('translate')('atheer.conversation.participantsInvited'), 3000);

                vm.conversation = result;
                $scope.$emit('conversationMessageInternal', {
                    type: 'CONVERSATION_UPDATED',
                    conversation: result
                });
            };

            function onSaveError() {
                AlertService.displayToast($mdToast);
            };
        };

        function initTypingSevice() {
            ConversationTypingService.init(vm.conversation);
        };

        function loadData() {
            if (ConversationModelService.isInitialized()) {
                vm.conversation = ConversationModelService.getConversations()[0];
                if (vm.conversation != null) {
                    initTypingSevice();
                }
            } else {
                if (id) {
                    vm.loadingData = true;
                    $q.all([
                        Conversation.get({
                            id: id
                        }).$promise,
                    ])
                        .then(function (results) {
                            vm.conversation = results[0];
                            initTypingSevice();
                            vm.loadingData = false;
                        });
                } else {
                    vm.conversation = null;
                    vm.loadingData = false;
                };
            }
        };
        loadData();
    }
})();
