(function() {
    'use strict';

    angular
        .module('atheer.user')
        .config(moduleConfig);

    function moduleConfig($stateProvider, triMenuProvider) {
        $stateProvider
            .state('storm.admin-users', {
                url: '/users',
                views: {
                    '@storm': {
                        templateUrl: 'modules/user/users.tmpl.html',
                        controller: 'UserController',
                        controllerAs: 'vm',
                    },
                    'toolbar@storm': {
                        templateUrl: 'modules/user/user-toolbar.tmpl.html',
                        controller: 'UserToolbarController',
                        controllerAs: 'vm'
                    },
                    'sideMenu@storm': {
                        templateUrl: 'modules/user/user-menu.tmpl.html',
                        controller: 'UserMenuController',
                        controllerAs: 'vm',
                    }
                },
                data: {
                    pageTitle: 'atheer.userManagement.home.title',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showSideNavMenu: true,
                        showToolbar: false,
                        sideNavMenuSize: 20,
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }]
                }
            })
            .state('storm.ops-users', {
                url: '/ops-users',
                views: {
                    '@storm': {
                        templateUrl: 'modules/user/users.tmpl.html',
                        controller: 'UserController',
                        controllerAs: 'vm',
                    },
                    'toolbar@storm': {
                        templateUrl: 'modules/user/user-toolbar.tmpl.html',
                        controller: 'UserToolbarController',
                        controllerAs: 'vm'
                    },
                    'sideMenu@storm': {
                        templateUrl: 'modules/user/user-menu.tmpl.html',
                        controller: 'UserMenuController',
                        controllerAs: 'vm',
                    }
                },
                data: {
                    pageTitle: 'atheer.userManagement.home.title',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showSideNavMenu: true,
                        showToolbar: true,
                        sideNavMenuSize: 20,
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }]
                }
            })
            .state('storm.admin-users-import', {
                url: '/users/import',
                templateUrl: 'modules/user/import/user-import.tmpl.html',
                controller: 'UserImportController',
                controllerAs: 'vm',
                data: {
                    pageTitle: 'atheer.userManagement.home.title',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }]
                }
            })
            .state('storm.admin-user-fields', {
                url: '/users/meta-data',
                templateUrl: 'modules/user/metadata/user-metadata.tmpl.html',
                // set the controller to load for this page
                controller: 'UserMetaDataController',
                controllerAs: 'vm',
                // layout-column class added to make footer move to
                // bottom of the page on short pages
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false
                    }
                },
                resolve: {
                    id: function($stateParams) {
                        return 'user';
                    }
                }
            });
    }
})();
