(function() {
    'use strict';
    angular
        .module('atheer.services')
        .factory('UserInvitation', UserInvitation);


    /* @ngInject */
    function UserInvitation($resource) {
        var resourceUrl = 'api/user-invitations/:id';

        return $resource(resourceUrl, {
            id: '@id'
        }, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function(data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getByActivationKey': {
                url: 'api/user-invitations/key/:id',
                method: 'GET',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT'
            },
            'resend': {
                url: 'api/user-invitations/:id/resend',
                method: 'PUT'
            },
            'bulkInvitation': {
                url: 'api/user-invitations/bulk',
                method: 'POST',
                isArray: true
            },
        });
    }
})();
