(function () {
    'use strict';

    angular
        .module('atheer.smartFlow')
        .controller('JobFlowMoleculesDataMapperController', JobFlowMoleculesDataMapperController)

    /* @ngInject */
    function JobFlowMoleculesDataMapperController($mdDialog, JobFlow, setting, Upload, SmartFlowBuilder, $q, $filter, ToastService) {
        var vm = this;

        var object = {
            header_value: '',
            header_name: 'header',
            data_model_value: '',
            data_model_name: 'data_model'
        };

        vm.loadingData = true;
        vm.data;
        vm.object = [];
        vm.smartflowCategory = SmartFlowBuilder.getSmartFlowCategory();

        vm.headerModel;
        vm.dataModels = [];
        vm.dataModel;
        vm.file = {};
        vm.headers = [];
        vm.isUploaded = false;

        vm.close = function () {
            $mdDialog.cancel();
        };

        vm.submit = function () {
            syncSettings();
            var keys = vm.setting.properties.mapping ? Object.keys(vm.setting.properties.mapping) : '';
            if (keys && keys.length > 0) {
                angular.forEach(keys, function (key) {
                    angular.forEach(vm.dataModel.properties, function (model) {
                        if (vm.setting.properties.mapping[key].name == model.name) {
                            vm.setting.properties.mapping[key].dataType = model.dataType;
                        }
                    });
                });
            }
            $mdDialog.hide(vm.setting);
        };

        function syncSettings() {
            var rawSettings = vm.setting.properties.mapping;
            vm.setting.properties.mapping = {};
            for (var objCnt = 0; objCnt < vm.object.length; objCnt++) {
                if (rawSettings[vm.object[objCnt].header_value]) {
                    vm.setting.properties.mapping[vm.object[objCnt].header_value] = rawSettings[vm.object[objCnt].header_value];
                }
            }
        }

        vm.uploadDataMapper = function () {
            if(vm.file == null) {
                return;
            }
            var fileExt = vm.file && vm.file.name.split('.')[1];
            if(fileExt == 'csv') {
                Upload.upload({
                    url: 'api/smartflow/datamapper/headers',
                    data: {
                        'file': vm.file,
                        'type': 'csv'
                    }
                }).then(function (data) {
                    vm.setting.headers = data.data;
                    vm.setting.properties.mapping = {};
                    vm.isUploaded = true;
                    ToastService.displayToast($filter('translate')('atheer.smartFlow.builder.fileSuccess'), 3000);
                }, function () {
                    ToastService.displayToast($filter('translate')('atheer.smartFlow.builder.fileNotUploaded'), 3000);
                });
            } else {
                vm.file = {};
                ToastService.displayToast($filter('translate')('atheer.smartFlow.builder.fileDoesntSupport'), 3000);
            }
        };

        vm.addField = function (key) {
            var obj = angular.copy(object);
            obj.header_value = key ? key : '';
            vm.object.push(obj);
        };

        vm.removeField = function (id, obj) {
            vm.object.splice(id, 1);
            delete vm.setting.properties.mapping[obj.header_value];
        };

        vm.onDataModelChange = function () {
            angular.forEach(vm.dataModels, function (model) {
                if (vm.setting.dataModel == model.id) {
                    vm.dataModel = model;
                }
            })
        }

        function init() {
            vm.setting = angular.copy(setting);
            $q.all([
                JobFlow.getDataModel().$promise,
                JobFlow.getDataMapper().$promise
            ]).then(function (data) {
                vm.dataModels = data[0];
                vm.loadingData = false;
                vm.isUploaded = true;

                if (vm.smartflowCategory === 'JOB_EVENT') {
                    vm.setting.headers = data[1];
                }

                if (vm.setting && vm.setting.dataModel) {
                    vm.onDataModelChange();
                }
            });

            vm.isUploaded = vm.setting && vm.setting.headers ? true : false;
            if (vm.setting.properties && vm.setting.properties.mapping) {
                var keys = Object.keys(vm.setting.properties.mapping);
                if (keys && keys.length > 0) {
                    angular.forEach(keys, function (key) {
                        vm.addField(key);
                    });
                } else {
                    vm.addField();
                }
            } else {
                vm.addField();
            }
        }

        init();
    }
})();
