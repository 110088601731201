(function() {
    'use strict';

    angular
        .module('atheer.insights')
        .controller('InsightsDashboardViewController', InsightsDashboardViewController);

    /* @ngInject */
    function InsightsDashboardViewController($scope, $q, $filter, $translate, Report, Workspace, triSettings,
        $window, $stateParams, BuildLanguages, $location, $sce, Setting, AlertService, Insight, triLoaderService) {
        var vm = this;
        vm.ngxUrlTrusted = null;
        vm.advInsights = null;
            //console.log('Loading insights viewer with state: ' + JSON.stringify($stateParams))
        var dashboardId = $stateParams.dashboardId;
        var dashboardMode = $stateParams.dashboardmode;
        var apiKey = null;

        function setNgxPageUrl() {
            $q.all([
                Workspace.getInsightSettings().$promise
            ]).then(function(results) {
                vm.advInsights = results[0];
                //console.log('Insight settings: ' + JSON.stringify(vm.advInsights));
                var ngxUrl = null;
                var regexSubdomainPattern = /\.atheer\.my$/ig;
                var hostUrl = vm.advInsights.hostUri;
                var workspaceId = vm.advInsights.workspaceId;
                apiKey = dashboardMode == 'view' ? vm.advInsights.viewApiKey : vm.advInsights.manageApiKey;
                var hideControls = dashboardMode == 'view' ? '[deleteButton,saveAsButton]' : '[deleteButton,saveAsButton,shareButton]';

                ngxUrl = hostUrl + '/dashboards/embedded/#/workspace/' + workspaceId 
                    + '/dashboard/' + dashboardId 
                    + '?showNavigation=false'
                    + '&showEmbedButton=false'
                    + '&apiTokenAuthentication=true'
                    + '&hideControl=' + hideControls
                //console.log('setNgxPageUrl 2: ' + ngxUrl);
                
                vm.ngxUrlTrusted = $sce.trustAsResourceUrl(ngxUrl);
                triLoaderService.setLoaderActive(false);
            }).catch(function(data) {}).finally(function() {});

        }

        function handleNgxEvent(event) {
            //console.log('Loading dashboard view with: ' + JSON.stringify(event));
            if (event.data.ngxLocationChanged) {
                var ngState = event.data.ngxLocationChanged.ngState;
                var id = event.data.ngxLocationChanged.id;

                if (id == null) {
                    $state.transitionTo(ngState, null, {
                        location: true,
                        inherit: true,
                        relative: $state.$current,
                        notify: false
                    });
                } else {
                    $state.transitionTo(
                        ngState, {
                            id: id
                        }, {
                            location: true,
                            inherit: true,
                            relative: $state.$current,
                            notify: false
                        }
                    );
                }
            } else if(event.data.navigate) {
                $state.transitionTo(
                    ngState, {
                        id: event.data.navigate.toState
                    },{
                        id: event.data.navigate.id, 
                        workspaceId: event.data.navigate.workspaceId, 
                        dashboardId: event.data.navigate.dashboardId,
                        dashboardmode: 'view'
                    }
                );
            }
        }

        function handleApiTokenRequest(event) {
            //console.log("Post message received", JSON.stringify(event));
            if (event.data.gdc && event.data.gdc.event.name === "listeningForApiToken") {
                const postMessageStructure = {
                    gdc: {
                        product: "dashboard",
                        event: {
                            name: "setApiToken",
                            data: {
                                token: apiKey
                            }
                        }
                    }
                };
                //console.log("Sending token to embedded window: " + JSON.stringify(postMessageStructure));
                const origin = "*";
                const iframe = document.getElementById("insight-view-frame").contentWindow;
                iframe.postMessage(postMessageStructure, origin);
            }

            if(event.data.gdc && event.data.gdc.product === 'analyticalDesigner' && event.data.gdc.event.name === 'visualizationSaved') {
                Insight.syncAllInsights();
            }

            if(event.data.navigate) {
                handleNgxEvent(event);
            }
        }

        window.addEventListener("message", handleApiTokenRequest,false);

        setNgxPageUrl();

        

        $scope.$on('$destroy', function() {
            window.removeEventListener('message', handleApiTokenRequest, false);
        });
    }
})();
