'use strict';

/**
 * @ngdoc function
 * @name AdminController
 * @kind function
 *
 * @description
 *
 * Handles the admin view
 */
(function () {
    'use strict';

    angular
        .module('atheer.core')
        .controller('StormStateController', StormStateController);

    /* @ngInject */
    function StormStateController($scope, $state, $rootScope, $timeout, $templateRequest, $compile, $element, $window, triLayout, triLoaderService, triSettings) {
        var loadingQueue = [];
        var vm = this;

        vm.layout = triLayout.layout;
        vm.activateHover = activateHover;
        vm.removeHover = removeHover;
        vm.showLoader = triLoaderService.isActive();

        $scope.sideBarOpen = triSettings.getSkinModel().side_menu_state == 'OPEN';

        $scope.notification = {
            count: 0
        };

        // we need to use the scope here because otherwise the expression in md-is-locked-open doesnt work
        $scope.layout = triLayout.layout; //eslint-disable-line

        $scope.widgetColor = 'rgb(0, 188, 212)';
        $scope.closeWidgetColor = triSettings.getSkinModel().primary_color_hex;
        $scope.widgetSize = '45px';
        $scope.showSideNavMenu = true;

        if (triSettings.getSkinModel().side_menu == 'DARK') {
            $scope.widgetColor = triSettings.getSkinModel().primary_color_hex;
        } else {
            $scope.widgetColor = 'white';
        };

        if (triSettings.getSkinModel().widget_size == 'SMALL') {
            $scope.widgetSize = '45px';
        } else if (triSettings.getSkinModel().widget_size == 'MEDIUM') {
            $scope.widgetSize = '55px';
        } else if (triSettings.getSkinModel().widget_size == 'LARGE') {
            $scope.widgetSize = '65px';
        } else if (triSettings.getSkinModel().widget_size == 'HIDDEN') {
            $scope.widgetSize = 'HIDDEN';
        }

        $scope.widgetLogo = triSettings.getLogoSmall();

        $scope.toggleEmbedUI = function () {
            $scope.showEmbedUI = !$scope.showEmbedUI;

            if ($scope.showEmbedUI) {
                window.parent.postMessage({
                    toggleWidget: {
                        height: '100%',
                        width: '520px'
                    }
                }, "*");
            } else {
                if ($scope.widgetSize == 'HIDDEN') {
                    window.parent.postMessage({
                        toggleWidget: {
                            height: '0px',
                            width: '0px'
                        }
                    }, "*");
                } else {
                    window.parent.postMessage({
                        toggleWidget: {
                            height: '80px',
                            width: '80px'
                        }
                    }, "*");
                }

            }
        }

        ////////////////
        function activateHover() {
            if (triLayout.layout.sideMenuSize === 'icon') {
                $element.find('.storm-sidenav-left').addClass('hover');
                $rootScope.$broadcast('leftSideNavOpen');
                $timeout(function () {
                    $window.dispatchEvent(new Event('resize'));
                }, 300);
            }
        }

        function removeHover() {
            if (triLayout.layout.sideMenuSize === 'icon') {
                $element.find('.storm-sidenav-left').removeClass('hover');
                $rootScope.$broadcast('leftSideNavClose');
                $timeout(function () {
                    $window.dispatchEvent(new Event('resize'));
                }, 300);
            }
        }

        function injectFooterUpdateContent(viewName) {
            var contentView = $element.find('.storm-content');
            if (viewName === '@storm' && angular.isDefined(triLayout.layout.footerTemplateUrl)) {
                // add footer to the content view
                $templateRequest(triLayout.layout.footerTemplateUrl)
                    .then(function (template) {
                        // compile template with current scope and add to the content
                        var linkFn = $compile(template);
                        var content = linkFn($scope);
                        $timeout(function () {
                            contentView.append(content);
                        });
                    });
            }
        }

        function loaderEvent(event, isActive) {
            vm.showLoader = isActive;
        }

        function stateChangeStart() {
            if (!$rootScope.isJobExecutionActive && !$rootScope.isJobBoardActive) {
                // state has changed so start the loader
                triLoaderService.setLoaderActive(true);
            }
        }

        function viewContentLoading($event, viewName) {
            // a view is loading so add it to the queue
            // so we know when to turn off the loader
            loadingQueue.push(viewName);
        }

        function viewContentLoaded($event, viewName) {
            if (angular.isDefined(triLayout.layout.footer) && triLayout.layout.footer === true) {
                // inject footer into content
                injectFooterUpdateContent(viewName);
            }

            // view content has loaded so remove it from queue
            var index = loadingQueue.indexOf(viewName);
            if (-1 !== index) {
                loadingQueue.splice(index, 1);
            }
            // is the loadingQueue empty?
            if (loadingQueue.length === 0) {
                // nothing left to load so turn off the loader
                triLoaderService.setLoaderActive(false);
            }
        }

        function leftSideNavOpen() {
            vm.sideBarOpen = true;
        }

        function leftSideNavClose() {
            vm.sideBarOpen = false;
        }

        // watches
        //calls when hit menu on sidebar to open sub menus in side bar
        $rootScope.$on('toggleSideMenu', function (event, type) {
            if (type == 'toggle') {
                $scope.showSideNavMenu = !$scope.showSideNavMenu;
            } else {
                $scope.showSideNavMenu = true;
            }

        });

        // watches
        //calls when hit menu on sidebar to open sub menus in side bar
        $rootScope.$on('showSideMenu', function (event, type) {
            if (type == 'false') {
                $scope.showSideNavMenu = false;
            } else {
                $scope.showSideNavMenu = true;
            }
        });

        // register listeners for loader
        $scope.$on('loader', loaderEvent);

        // watch for ui router state change
        $scope.$on('$stateChangeStart', stateChangeStart);

        // watch for view content loading
        $scope.$on('$viewContentLoading', viewContentLoading);

        // watch for view content loaded
        $scope.$on('$viewContentLoaded', viewContentLoaded);

        // register listeners for loader
        $scope.$on('leftSideNavOpen', leftSideNavOpen);

        // register listeners for loader
        $scope.$on('leftSideNavClose', leftSideNavClose);
    }
})();
