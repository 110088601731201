(function() {
    'use strict';

    angular
        .module('atheer.user')
        .controller('ProfileSessionsUpcomingController', ProfileSessionsUpcomingController);

    /* @ngInject */
    function ProfileSessionsUpcomingController($scope, $state, $q, $filter, Report, Principal, CONTENT_META, Pubnub, Session, ParseLinks, pagingParams, id, paginationConstants, AlertService, $mdDialog, $mdToast, $stateParams, Feature, Setting) {
        var vm = this;

        vm.loadingData = false;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.reOrder = reOrder;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.predicate = pagingParams.predicate;
        vm.transition = transition;
        vm.uId = Principal.getUser().id;
        vm.pageSize = paginationConstants.itemsPerPage;

        vm.isHost = function(session) {
            if (session.owner == vm.uId) {
                return true;
            }
            for (var i = 0; i < session.session_users.length; i++) {
                if (session.session_users[i].user_id == vm.uId) {
                    if (session.session_users[i].role == "HOST") {
                        return true;
                    }
                }
            };
            return false;
        }

        vm.refresh = function() {
            loadData();
        };

        function reOrder(order) {
            vm.predicate = order;
            vm.transition();
        };

        function transition() {
            vm.promise = Session.query({
                page: vm.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort(),
                user_id: id,
                scheduled: true
            }).$promise;

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            vm.promise.then(function(data, headers) {
                vm.queryCount = vm.totalItems;
                vm.sessions = data;
            });
        };

        function getSortOrder() {
            if (vm.predicate.startsWith('-')) {
                return vm.predicate.substr(1) + ',' + 'desc';
            } else {
                return vm.predicate + ',' + 'asc';
            }

        };

        function deleteObject(entity) {
            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.session.upcomingSessions.deleteSession'))
                .ariaLabel($filter('translate')('atheer.session.upcomingSessions.deleteSession'))
                .ok($filter('translate')('entity.action.delete'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function() {
                Session.delete({
                    id: entity.id
                }, function() {
                    resetModal();
                });
            });
        };

        function resetModal() {
            loadData();
            AlertService.displayToast($mdToast);
        };

        function getQueryString() {
            var query = 'type==' + 'SCHEDULED';
            return query;
        };

        function loadData() {
            vm.loadingData = true;
            Session.query({
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort(),
                user_id: id,
                scheduled: true
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.sessions = data;
                vm.page = pagingParams.page;
                vm.loadingData = false;
            }

            function onError(error) {
                AlertService.error(error.data.message);
                vm.loadingData = false;
            }
        };
        loadData();
    }
})();
