(function() {
    'use strict';

    angular
        .module('atheer.services')
        .factory('_', UnderScore);

    /* @ngInject */
    function UnderScore() {
        _.mixin(s.exports());
        return window._;
    }
})();
