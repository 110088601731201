(function() {
    'use strict';

    angular
        .module('atheer.user')
        .config(moduleConfig);

    /* @ngInject */
    function moduleConfig($stateProvider, triMenuProvider) {
        $stateProvider
            .state('storm.noaccess', {
                url: '/noaccess',
                templateUrl: 'modules/user/noaccess/noaccess.tmpl.html',
                controller: 'NoAccessContoller',
                controllerAs: 'vm',
                data: {
                    layout: {
                        footer: false,
                        showToolbar: false,
                        contentClass: 'layout-column'
                    }
                }
            });
    }
})();
