(function() {
    'use strict';

    angular
        .module('atheer.user')
        .controller('ProfileTeamsController', ProfileTeamsController);

    /* @ngInject */
    function ProfileTeamsController($scope, $state, triTheming, triSettings, $mdDialog, Principal, $translate, pagingParams, paginationConstants, $mdToast, $filter, Experience, CONTENT_META, $mdMedia, id) {
        var vm = this;
        vm.doNotMatch = null;
        vm.error = null;
        vm.success = null;
        vm.user = null;
        vm.loadingData = true;
        vm.userId = id == 'me' ? Principal.getUserId() : id;
        vm.teams = [];
        vm.primaryBackgroundColor = triTheming.rgba(
            triTheming.getPaletteColor(triSettings.getSkinModel().primary_color, '500').value
        );
        vm.secondaryBackgroundColor = triTheming.rgba(
            triTheming.getPaletteColor(triSettings.getSkinModel().secondary_color, '500').value
        );

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;

        vm.query = {
            filter: '',
            limit: paginationConstants.itemsPerPage,
            order: '-id',
            page: 1
        };

        vm.openAssignModal = function() {
            $mdDialog.show({
                templateUrl: 'modules/team/team-assign-dialog.tmpl.html',
                clickOutsideToClose: true,
                controller: "TeamAssignController",
                controllerAs: "vm",
                size: 'lg',
                locals: {
                    team: undefined,
                    userId: vm.userId,
                    teamsFilterList: 1001
                }
            }).then(function() {
                vm.loadData();
            });
        }

        vm.removeTeam = function(team) {
            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.teams.removeTeam'))
                .ariaLabel($filter('translate')('atheer.teams.removeTeam'))
                .ok($filter('translate')('entity.action.delete'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function() {
                Experience.deleteTeam({
                    id: team.id
                }, function() {
                    vm.loadData();
                });
            });
        }

        vm.loadData = function() {
            vm.loadingData = true;
            //fetch pins data
            Experience.getTeams({
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort(),
                query: getQueryString()
            }, function(response, headers) {
                vm.page = pagingParams.page;
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.teams = response;
                vm.loadingData = false;
            });
        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function getQueryString() {
            var queryString = "user_id==" + vm.userId;
            if (vm.query.filter.length <= 0) {
                return queryString;
            }
        }

        vm.transition = function() {
            vm.promise = Experience.getTeams({
                page: vm.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort(),
                query: getQueryString()
            }).$promise;

            vm.promise.then(function(response, headers) {
                vm.teams = response;
                vm.queryCount = vm.totalItems;
            });
        }
        vm.loadData();
    }
})();
