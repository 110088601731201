(function () {
	"use strict";

	angular
		.module("atheer.smartFlow")
		.controller("JobFlowInsightsInputDetailsController", JobFlowInsightsInputDetailsController);

	/* @ngInject */
	function JobFlowInsightsInputDetailsController(
		$mdDialog,
		inputId,
		data
	) {
		var vm = this;
		vm.loadingData = false;

		vm.clear = function () {
			$mdDialog.hide();
		};

		function init() {
			vm.headerList = [];
			vm.inputList = [];

			vm.headerList.push("atheer.smartFlow.insights.inputId");
			vm.inputList.push(inputId);

			angular.forEach(data, function (input, key) {
				vm.headerList.push(key);
				vm.inputList.push(input);
			})
		}

		init();
	}
})();
