(function () {
    'use strict';
    angular
        .module('atheer.content')
        .factory('ContentAction', ContentAction);

    /* @ngInject */
    function ContentAction($window, $rootScope, $location, deviceDetector, Experience, Principal, $mdDialog, $q, Content, Scorm, Base64, ToastService, $filter, $http) {
        return {
            launchContent: function (feed, assignmentId) {
                var contentType = feed.data.type;
                var contentItem = feed;

                this.playContent(contentType, contentItem, this.markContentViewed, assignmentId);
            },
            markContentViewed: function (contentItem) {
                var device = {
                    os: deviceDetector.os,
                    device: 'browser',
                    browser: deviceDetector.browser,
                    os_version: deviceDetector.os_version,
                    browser_version: deviceDetector.browser_version
                };

                Experience.markViewed({
                    object: contentItem,
                    user_id: Principal.getUserId(),
                    time_spent: 0,
                    device_properties: device
                }, function (result) {
                });
            },
            getContentUrl: function (contentItem) {
                var contenturl = '/media';
                var sourceMediaId = contentItem.data.source_media.id;
                var sourceMediaFileName = contentItem.data.source_media.properties.file_name;
                switch (contentItem.data.type) {
                    case 'session_recording':
                        contenturl = contenturl + '/session-recordings/' + sourceMediaId + '/' + sourceMediaFileName;
                        break;

                    case 'video':
                        contenturl = contenturl + '/videos/' + sourceMediaId + '/' + sourceMediaFileName;
                        break;

                    case 'document':
                        contenturl = contenturl + '/documents/' + sourceMediaFileName;
                        break;

                    case 'image':
                        contenturl = contenturl + '/images/' + sourceMediaFileName;
                        break;

                    case 'podcast':
                        contenturl = contenturl + '/audios/' + sourceMediaFileName;
                        break;

                    case 'zip':
                        contenturl = contenturl + '/zips/' + sourceMediaFileName;
                        break;
                }

                return contenturl;

            },
            playContent: function (contentType, contentItem, callback, assignmentId) {
                if (contentItem.data.type === 'link') {
                    $window.open(contentItem.data.source_url, "_blank");
                    this.markContentViewed(contentItem);
                    return;
                }

                if (contentItem.data.type === 'smart_page') {
                    var newWindow = $window.open();
                    newWindow.document.write(contentItem.data.content);
                    this.markContentViewed(contentItem);
                    return;
                }

                if (contentItem.data.type === 'video' && contentItem.data.source_url) {
                    $window.open(contentItem.data.source_url);
                    this.markContentViewed(contentItem);
                    return;
                }

                if (contentItem.data.type === 'video' && contentItem.data.embed_code) {
                    var newWindow = $window.open();
                    newWindow.document.write(contentItem.data.embed_code);
                    this.markContentViewed(contentItem);
                    return;
                }

                if (contentItem.data.type === 'podcast' && contentItem.data.embed_code) {
                    var newWindow = $window.open();
                    newWindow.document.write(contentItem.data.embed_code);
                    this.markContentViewed(contentItem);
                    return;
                }

                if (contentItem.data.type === 'document' && contentItem.data.embed_code) {
                    var newWindow = $window.open();
                    newWindow.document.write(contentItem.data.embed_code);
                    this.markContentViewed(contentItem);
                    return;
                }

                contentItem.loading = true;
                var contentUrl = this.getContentUrl(contentItem);
                $http
                    .get(contentUrl, {
                        responseType: "blob",
                    })
                    .success(function (data) {
                        if (callback) {
                            callback(contentItem);
                        }
                        var contentBlobUrl = $window.URL.createObjectURL(data);
                        $window.open(contentBlobUrl);
                        contentItem.loading = false;

                    });
            },
            previewContent: function (contentItem) {
                var contentType = contentItem.data.type;

                if (contentType == 'series') {
                    $mdDialog.show({
                        templateUrl: 'modules/content/detail/content-detail-dialog.tmpl.html',
                        clickOutsideToClose: true,
                        fullscreen: true,
                        controller: 'ContentDetailController',
                        controllerAs: "vm",
                        locals: {
                            id: contentItem.id,
                            preview: true
                        }
                    });
                } else if (contentType === 'course') {
                    if (contentItem.data.launch_mode == 'MODAL_WINDOW') {
                        Scorm.getCoursePreviewUrl({
                            id: contentItem.id,
                            appURL: 'closer'
                        }, function (result) {
                            $mdDialog.show({
                                templateUrl: 'modules/content/course-player.tmpl.html',
                                clickOutsideToClose: true,
                                fullscreen: true,
                                controller: 'CoursePlayerController',
                                locals: {
                                    dataToPass: contentItem,
                                    mode: 'preview',
                                    assignmentId: null,
                                    courseUrl: result.url
                                }
                            });
                        });
                    } else {
                        Scorm.getCoursePreviewUrl({
                            id: contentItem.id,
                            appURL: encodeURIComponent($location.absUrl())
                        }, function (result) {
                            $window.location.href = result.url;
                        });
                    }
                } else {
                    Content.get({
                        id: contentItem.id
                    }, function (result) {
                        $mdDialog.show({
                            templateUrl: 'modules/content/content-player.tmpl.html',
                            clickOutsideToClose: true,
                            fullscreen: true,
                            controller: 'ContentPlayerController',
                            locals: {
                                dataToPass: result,
                                mode: 'preview'
                            }
                        });
                    });
                }
            },
            openShareDialog: function (contents, callback) {
                $mdDialog.show({
                    templateUrl: 'modules/content/content-share-dialog.tmpl.html',
                    clickOutsideToClose: true,
                    controller: "ContentShareController",
                    controllerAs: "vm",
                    locals: {
                        contents: contents
                    }
                }).then(function (result) {
                    if (callback) {
                        callback();
                    }
                }).finally(function () {
                });
            },
            openFeedbackDialog: function (reactionType, content) {
                if (reactionType == "FLAG" || reactionType == "PROBLEM") {
                    $mdDialog.show({
                        templateUrl: 'modules/content/content-feedback-dialog.tmpl.html',
                        clickOutsideToClose: true,
                        controller: "ContentFeedbackController",
                        controllerAs: "vm",
                        locals: {
                            content: content,
                            type: reactionType
                        }
                    }).finally(function () {
                    });
                }
            },
            openFlagDialog: function (content) {
                $mdDialog.show({
                    templateUrl: 'modules/content/content-feedback-dialog.tmpl.html',
                    clickOutsideToClose: true,
                    controller: "ContentFeedbackController",
                    controllerAs: "vm",
                    locals: {
                        content: content,
                        type: "FLAG"
                    }
                }).finally(function () {
                });
            },
            openProblemDialog: function (content) {
                $mdDialog.show({
                    templateUrl: 'modules/content/content-feedback-dialog.tmpl.html',
                    clickOutsideToClose: true,
                    controller: "ContentFeedbackController",
                    controllerAs: "vm",
                    locals: {
                        content: content,
                        type: "PROBLEM"
                    }
                }).finally(function () {
                });
            },
            bookmarkOrRemoveBookmarkContent: function (content, bookmarkData) {
                var deferred = $q.defer();

                var bookmarkContent = function () {
                    Experience.bookmark({
                        object: content,
                        user_id: Principal.getUserId()
                    }, function (result) {
                        deferred.resolve(result);
                    }, function (result) {
                        deferred.reject(pinData);
                    });
                }

                var removeBookmarkContent = function () {
                    Experience.removeBookmark({
                        id: pinData.id
                    }, function (result) {
                        deferred.resolve(result);
                    }, function (result) {
                        deferred.reject(likeData);
                    });
                }

                if (angular.isDefined(bookmarkData)) {
                    removeBookmarkContent();
                } else {
                    bookmarkContent();
                }
                return deferred.promise;
            },
            likeOrUnlikeContent: function (content, likeData) {
                var deferred = $q.defer();

                var likeContent = function () {
                    Experience.like({
                        object: content,
                        user_id: Principal.getUserId(),
                        reaction_type: 'LIKE'
                    }, function (result) {
                        deferred.resolve(result);
                    }, function (result) {
                        deferred.reject(likeData);
                    });
                }

                var unlikeContent = function () {
                    Experience.unlike({
                        id: likeData.id
                    }, function (result) {
                        deferred.resolve(result);
                    }, function (result) {
                        deferred.reject(likeData);
                    });
                }

                if (angular.isDefined(likeData)) {
                    unlikeContent();
                } else {
                    likeContent();
                }
                return deferred.promise;
            },
            getLikeData: function (content) {
                var deferred = $q.defer();
                Experience.getLikes({
                    query: "object_id==" + content.id + ";user_id==" + Principal.getUserId()
                }, function (result) {
                    var likeData = undefined;
                    if (result.length > 0) {
                        likeData = result[0];
                    }
                    deferred.resolve(likeData);
                }, function (result) {
                    deferred.reject(undefined);
                });
                return deferred.promise;
            },
            launchUserCardSummary: function (userId, content) {
                $mdDialog.show({
                    templateUrl: 'modules/content/smart-content-insight-user-card-summary.tmpl.html',
                    clickOutsideToClose: true,
                    controller: "SmartContentInsightUserCardSummaryController",
                    controllerAs: "vm",
                    locals: {
                        content: content,
                        userId: userId,
                        title: content.title + ' Summary'
                    }
                }).finally(function () {
                });
            },
            openMyUploadDialog: function (ev, callback) {
                $mdDialog.show({
                    templateUrl: 'modules/user/upload/upload-dialog.tmpl.html',
                    targetEvent: ev,
                    controller: 'MyUploadDialogController',
                    controllerAs: 'vm',
                    clickOutsideToClose: true
                }).then(function (result) {
                    if (callback) {
                        callback();
                    }
                }).finally(function () {
                });
            }
        };
    }
})();
