(function() {
    'use strict';

    angular
        .module('atheer.workspace')
        .controller('WorkspaceController', WorkspaceController);

    /* @ngInject */
    function WorkspaceController($scope, $state, Workspace, ParseLinks, AlertService, pagingParams, paginationConstants, $mdDialog, $mdToast, $stateParams) {
        var vm = this;

        vm.loadingData = false;
        vm.loadAll = loadAll;
        vm.transition = transition;
        vm.removeFilter = removeFilter;

        vm.createObject = createObject;
        vm.editObject = editObject;
        vm.deleteObject = deleteObject;

        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;

        vm.query = {
            filter: '',
            limit: paginationConstants.itemsPerPage,
            order: '-id',
            page: 1
        };

        vm.filter = {
            show: false,
            options: {
                debounce: 500
            }
        };

        vm.loadAll();

        function loadAll() {
            vm.loadingData = true;
            Workspace.query({
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort(),
                query: getQueryString()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.loadingData = false;
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.workspaces = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                vm.loadingData = false;
                AlertService.error(error.data.message);
            }
        }

        function getQueryString() {
            if (vm.query.filter.length <= 0) {
                return null;
            } else {
                return 'name=re="' + vm.query.filter + '"'
            }
        }

        function transition() {
            vm.promise = Workspace.query({
                page: vm.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                query: getQueryString()
            }).$promise;

            vm.promise.then(function(data, headers) {
                vm.queryCount = vm.totalItems;
                vm.workspaces = data;
            });
        }

        function removeFilter() {
            vm.filter.show = false;
            vm.query.filter = '';
            vm.loadAll();
        }

        function createObject() {
            $mdDialog.show({
                animation: true,
                templateUrl: 'modules/workspace/workspace-detail-dialog.tmpl.html',
                controller: 'WorkspaceDetailDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg'
            }).then(function(result) {
                if (result != undefined && result != null) {
                    $state.go('storm.admin-workspace-detail.info', {
                        'id': result.id
                    });
                } else {
                    resetModal();
                }
            });
        }

        function editObject(objectId) {
            $state.go('storm.admin-workspace-detail.info', {
                'id': objectId
            });
        }

        function deleteObject(entity) {
            var confirm = $mdDialog.confirm()
                .title('Are you sure you want to delete this Workspace?')
                .ariaLabel('Are you sure you want to delete this Workspace?')
                .ok('Delete')
                .cancel('Cancel');

            $mdDialog.show(confirm).then(function() {
                Workspace.delete({
                    id: entity.id
                }, function() {
                    resetModal();
                });
            });
        };

        function resetModal() {
            loadAll();
            AlertService.displayToast($mdToast);
        };
    }
})();
