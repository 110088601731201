(function () {
	"use strict";

	angular
		.module("atheer.smartFlow")
		.controller(
			"JobFlowMoleculesJobScopeController",
			JobFlowMoleculesJobScopeController
		);

	/* @ngInject */
	function JobFlowMoleculesJobScopeController(
		$mdDialog,
		JobFlow,
		setting,
		SmartFlowBuilder,
		$state,
		$q
	) {
		var vm = this;
		vm.airFormList = [];
		vm.selectedAirFormList = [];
		vm.loadingData = true;
		vm.jobScopeType = "Completed";
		var smartFlowData = SmartFlowBuilder.getSmartFlowData();
		vm.smartFlowId = $state.params.id || smartFlowData.id;

		vm.close = function () {
			$mdDialog.cancel();
		};

		vm.submit = function () {
			vm.setting = {
				properties: {
					jobStatus: vm.jobScopeType,
					airForms: vm.selectedAirFormList,
					workflow: vm.smartFlowId
				}
			};

			JobFlow.updateJobScope({
				jobStatus: vm.jobScopeType,
				airForms: vm.selectedAirFormList,
				workflow: vm.smartFlowId
			}, function (result) {
				vm.loadingData = false;
				$mdDialog.hide(vm.setting);
			});
		};

		function init() {
			JobFlow.getObjectList({ objectType: "AiRForm" }, function (result) {
				vm.loadingData = false;
				vm.airFormList = result;
			});

			JobFlow.getJobScope({ query: "workflow==" + vm.smartFlowId }, function (result) {
				vm.loadingData = false;

				if (result.length > 0) {
					vm.selectedAirFormList = result[0].airForms;
					vm.jobScopeType = result[0].jobStatus
				}
			});
		}

		init();
	}
})();
