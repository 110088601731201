(function() {
    'use strict';

    angular
        .module('atheer')
        .config(permissionConfig);
        
    function permissionConfig($urlRouterProvider) {
        $urlRouterProvider.deferIntercept();
    }
})();
