(function() {
    'use strict';

    angular
        .module('atheer.user')
        .controller('ResetFinishController', ResetFinishController);

    /* @ngInject */
    function ResetFinishController($stateParams, $timeout, Auth, LoginService, triSettings) {
        var vm = this;

        vm.triSettings = triSettings;
        vm.useDarkBackGroundLogos = true;
        vm.keyMissing = angular.isUndefined($stateParams.key);
        vm.confirmPassword = null;
        vm.doNotMatch = null;
        vm.error = null;
        vm.finishReset = finishReset;
        vm.login = LoginService.open;
        vm.resetAccount = {};
        vm.success = null;

        vm.loadData = function() {
            vm.useDarkBackGroundLogos = triSettings.getSkinModel().use_dark_logos;
        }

        function finishReset() {
            Auth.resetPasswordFinish({
                key: $stateParams.key,
                newPassword: vm.password
            }).then(function() {
                vm.success = 'OK';
            }).catch(function() {
                vm.success = null;
                vm.error = 'ERROR';
            });
        }

        vm.loadData();
    }
})();
