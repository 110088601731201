(function () {
    'use strict';

    angular
        .module('atheer.user')
        .controller('SessionPlayerMergeRealityController', SessionPlayerMergeRealityController);

    /* @ngInject */
    function SessionPlayerMergeRealityController($mdDialog, mainData, message) {
        var vm = this;
        vm.mainData = mainData;
        vm.requestedUserName = getUserNameByJitsiId(message.data.overlayId)
        vm.close = function () {
            $mdDialog.hide(message);
        };

        function getUserNameByJitsiId(id) {
            var userInfo = vm.mainData.sessionUsers.filter(function (value, index, arr) {
                return value.jitsiUserId == id;
            })[0];
            return userInfo && userInfo.firstName ? userInfo.firstName + ' ' + userInfo.lastName : userInfo.lastName;
        };
    }
})();