(function() {
    'use strict';

    angular
        .module('atheer.system')
        .controller('AuthenticationSamlSPDialogController', AuthenticationSamlSPDialogController);

    /* @ngInject */
    function AuthenticationSamlSPDialogController($scope, $q, Setting, $filter, $mdDialog, ToastService) {
        var vm = this;

        vm.loadingData = false;
        vm.configSettings = [];

        vm.spMetadata = null;

        vm.loadData = function() {
            vm.loadingData = true;

            $q.all([
                    Setting.getAuthenticationSamlMetadata().$promise
                ])
                .then(function(results) {
                    vm.spMetadata = results[0].service_provier;
                    vm.loadingData = false;
                });
        };

        vm.close = function() {
            $mdDialog.cancel();
        };

        vm.onCopySuccess = function() {
            ToastService.displayToast($filter('translate')('atheer.authentication.setup.copied'), 2000);
        };

        vm.loadData();
    }
})();
