(function() {
    'use strict';

    angular
        .module('atheer.system')
        .controller('LocaleDeleteController',LocaleDeleteController);

    LocaleDeleteController.$inject = ['$mdDialog', 'entity', 'Locale'];

    function LocaleDeleteController($mdDialog, entity, Locale) {
        var vm = this;
        vm.locale = entity;
        vm.clear = function() {
            $mdDialog.cancel();
        };
        vm.confirmDelete = function (id) {
            Locale.delete({id: id},
                function () {
                    $mdDialog.hide();
                });
        };
    }
})();
