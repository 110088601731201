(function() {
    'use strict';

    angular
        .module('atheer.user')
        .controller('UserDetailController', UserDetailController);

    /* @ngInject */
    function UserDetailController($rootScope, $q, $scope, $state, PermissionService, Auth, Principal, $translate, $mdToast, $mdMenu, $filter, id, User, Experience, MetaData, Report, Feature, $window) {
        var vm = this;

        if(!angular.isDefined(id)) {
            id = 'me';
        };

        vm.user = null;
        vm.experienceSummary = null;
        vm.userId = id;
        vm.profileViewMode = id == 'me' ? 'me' : 'admin';
        vm.hasOverDue = false;
        vm.hasPendingAssignments = false;
        //vm.overDueChartData = angular.copy(pieChartConfig);
        //vm.assignmentChartData = angular.copy(pieChartConfig);
        vm.allCompleted = true;
        vm.loadingData = false;
        vm.isAdminMode = vm.profileViewMode == 'admin';
        vm.isMeMode = vm.profileViewMode == 'me';
        vm.isBoardActive = false;
        vm.isMyUploadActive = false;
        vm.activeLink = 'overview';
        vm.showBoards =  false;

        if(vm.profileViewMode == 'me' && !$rootScope.showForYou) {
            vm.activeLink = 'about';
        }

        vm.loadData = function() {

        };

        vm.loadData();
    }
})();
