(function() {
    'use strict';

    angular
        .module('atheer.content')
        .controller('AnnouncementAssignController', AnnouncementAssignController);

    /* @ngInject */
    function AnnouncementAssignController($scope, $state, $mdDialog, $q, $filter, $mdToast, User, content, Experience, Team, Principal) {
        var vm = this;

        vm.asyncUsers = [];
        vm.asyncTeams = [];
        vm.content = content;

        vm.assignProgress = 'idle';
        vm.assignSuccess = 0;

        vm.contentAssignModel = {
            required: false,
            is_due_date_relative: 'true',
            relative_period: 'DAYS',
            relative_value: 5,
            due_on: moment().add(5, 'd').toDate(),
            type: 'TODO',
            object: content
        }

        vm.close = function() {
            if (vm.assignProgress !== 'idle') {
                $mdDialog.hide();
            } else {
                $mdDialog.cancel();
            }
        }

        vm.submit = function() {
            vm.assignProgress = 'started';

            Experience.bulkAssign({
                required: vm.contentAssignModel.required,
                is_date_date_relative: vm.contentAssignModel.is_due_date_relative,
                relative_period: vm.contentAssignModel.relative_period,
                relative_value: vm.contentAssignModel.relative_value,
                users: vm.asyncUsers,
                audiences: vm.asyncTeams,
                assigned_by: Principal.getUserId(),
                due_on: vm.contentAssignModel.due_on,
                content_ids: vm.content
            }, function(result) {
                vm.assignProgress = 'complete';
                vm.assignSuccess = result.success;
            });
        }

        vm.disableSubmit = function() {
            if (vm.asyncUsers.length <= 0 && vm.asyncTeams.length <= 0) {
                return true;
            } else {
                if (vm.contentAssignModel.required) {
                    if (vm.contentAssignModel.is_due_date_relative == 0) {
                        if (vm.contentAssignModel.due_on == null) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (vm.contentAssignModel.is_due_date_relative == 1) {
                        if (vm.contentAssignModel.relative_value == null || vm.contentAssignModel.relative_value == '') {
                            return true;
                        } else {
                            return false;
                        }
                    }
                } else {
                    return false;
                }
            }
        }
    }
})();
