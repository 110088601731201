(function() {
    'use strict';

    angular
        .module('atheer.core')
        .provider('triMenu', menuProvider);


    /* @ngInject */
    function menuProvider() {
        // Provider
        var menuArray = [];
        var menuInfo = {};

        this.addMenu = addMenu;
        this.removeMenu = removeMenu;
        this.removeAllMenu = removeAllMenu;
        this.isTabMenu = isTabMenu;
        this.getMenu = getMenu;
        this.getIndex = getIndex;

        function addMenu(item) {
            menuArray.push(item);
            createMenuInfo(item, item.type, item, 0);
        }

        function createMenuInfo(item, type, mainMenu, index) {
            menuInfo[item.state] = {
                type: type,
                menu: mainMenu,
                index: index
            };

            if (angular.isDefined(item.children)) {
                var i = 0;
                angular.forEach(item.children, function(children) {
                    createMenuInfo(children, type, mainMenu, i++);
                });
            }
        }

        function removeAllMenu() {
            for (var i = menuArray.length - 1; i >= 0; i--) {
                menuArray.splice(i, 1);
            }
        }

        function getIndex(state) {
            if (menuInfo[state]) {
                return menuInfo[state].index;
            } else {
                return;
            }
        }

        function getMenu(state) {
            if (menuInfo[state]) {
                return menuInfo[state].menu;
            } else {
                return;
            }
        }

        function isTabMenu(state) {
            if (menuInfo[state]) {
                return menuInfo[state].type == 'tab';
            } else {
                return;
            }
        }

        function removeMenu(state, params) {
            findAndDestroyMenu(menuArray, state, params);
        }

        function findAndDestroyMenu(menu, state, params, isChildren) {
            if (menu instanceof Array) {
                for (var i = menu.length - 1; i >= 0; i--) {
                    if (menu[i].state === state && angular.equals(menu[i].params, params)) {
                        menu.splice(i, 1);
                        if (!isNaN(isChildren) && !menuArray[isChildren].children.length) {
                            menuArray.splice(isChildren, 1);
                        }
                        break;
                    } else if (angular.isDefined(menu[i].children)) {
                        findAndDestroyMenu(menu[i].children, state, params, i);
                    }
                }
            }
        }

        // Service
        this.$get = function() {
            return {
                menu: menuArray,
                addMenu: addMenu,
                removeMenu: removeMenu,
                removeAllMenu: removeAllMenu,
                isTabMenu: isTabMenu,
                getMenu: getMenu,
                getIndex: getIndex
            };
        };
    }
})();
