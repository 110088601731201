(function() {
    'use strict';
    angular
        .module('atheer.services')
        .factory('Launcher', Launcher);

    /* @ngInject */
    function Launcher($resource) {
        var resourceUrl = 'api/launch/:id';

        return $resource(resourceUrl, {
            id: '@id'
        }, {
            'getAssignment': {
                method: 'GET'
            }
        });
    }
})();
