(function() {
    'use strict';

    angular
        .module('atheer.insights')
        .controller('InsightsController', InsightsController);

    /* @ngInject */
    function InsightsController($scope, $state, Setting, AlertService, $mdDialog, $mdToast, $stateParams, Principal, Feature, PermissionService) {
        var vm = this;

        vm.loadingData = true;
        vm.illustartionEmptyScreenId = "insight_graph";

        vm.loadAll = loadAll;
        vm.pageTitleKey = $state.$current.data.pageTitle;

        function loadAll() {
            vm.loadingData = false;
        };

        vm.loadAll();
    }
})();
