(function() {
    'use strict';

    angular
        .module('atheer.sdk')
        .controller('SdkWebHookTestController', SdkWebHookTestController);

    /* @ngInject */
    function SdkWebHookTestController($scope, $state, $mdDialog, $q, WebHook, $timeout, $mdToast, webHook, triSettings) {
        var vm = this;

        vm.webHook = webHook;
        vm.testPending = true;
        vm.webHookExecution = null;
        vm.primaryColor = triSettings.getSkinModel().primary_color_hex;

        vm.testWebHook = function() {
            WebHook.testWebHook({
                id: vm.webHook.id
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.webHookExecution = data;
                vm.testPending = false;
            };

            function onError(error) {
                vm.testPending = false;
            };
        };

        vm.close = function() {
            $mdDialog.cancel();
        };

        vm.testWebHook();
    };
})();
