(function () {
    'use strict';

    angular
        .module('atheer.smartFlow')
        .controller('SmartFlowController', SmartFlowController);

    /* @ngInject */
    function SmartFlowController($rootScope, $scope, $state, SmartFlow, ParseLinks, AlertService, pagingParams, paginationConstants, $mdDialog, $mdToast, $stateParams, $filter) {
        var vm = this;

        vm.loadAll = loadAll;
        vm.loadingData = false;
        vm.transition = transition;
        vm.removeFilter = removeFilter;

        vm.createObject = createObject;
        vm.editObject = editObject;
        vm.deleteObject = deleteObject;
        vm.executeNow = executeNow;
        vm.copyObject = copyObject;

        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.descending;

        vm.updateSmartFlowListListener = null;

        vm.illustartionEmptyScreenId = "smart_flow";

        vm.permissions = {
            create: 'create-workflow',
            update: 'update-workflow',
            delete: 'delete-workflow',
            manage: 'manage-workflow'
        };

        vm.query = {
            filter: '',
            limit: paginationConstants.itemsPerPage,
            order: '-id',
            page: 1,
            filters: {
                status: null,
                type: null
            },
        };

        vm.filter = {
            show: false,
            options: {
                debounce: 500
            }
        };

        function loadAll() {
            vm.loadingData = true;
            SmartFlow.query({
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort(),
                query: getQueryString()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.smartFlows = data;
                vm.page = pagingParams.page;
                vm.loadingData = false;
            }

            function onError(error) {
                AlertService.error(error.data.message);
                vm.loadingData = false;
            }
        }

        function transition() {
            vm.loadingData = true;
            vm.promise = SmartFlow.query({
                page: vm.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                query: getQueryString()
            }).$promise;

            vm.promise.then(function (data, headers) {
                vm.queryCount = vm.totalItems;
                vm.smartFlows = data;
                vm.loadingData = false;
            });
        }

        function removeFilter() {
            vm.filter.show = false;
            vm.query.filter = '';

            if (vm.filter.form.$dirty) {
                vm.filter.form.$setPristine();
            }
        }

        function createObject() {
            $state.go("storm.admin-smartflow-detail", {
                'id': '',
                'isCopy': false,
                'isJobFlow': true
            });
        }

        function editObject(objectId, isJobFlow) {
            $state.go('storm.admin-smartflow-detail', {
                'id': objectId,
                'isCopy': false,
                'isJobFlow': isJobFlow
            });
        }

        function copyObject(objectId, isJobFlow) {
            $state.go('storm.admin-smartflow-detail', {
                'id': objectId,
                'isCopy': true,
                'isJobFlow': isJobFlow
            });
        }

        function deleteObject(entity) {
            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.smartFlow.deleteSmartFlow'))
                .ariaLabel($filter('translate')('atheer.smartFlow.deleteSmartFlow'))
                .ok($filter('translate')('entity.action.delete'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function () {
                SmartFlow.delete({
                    id: entity.id
                }, function () {
                    resetModal();
                });
            });
        };

        function executeNow(entity) {
            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.smartFlow.executeSmartFlow'))
                .ariaLabel($filter('translate')('atheer.smartFlow.executeSmartFlow'))
                .ok($filter('translate')('atheer.smartFlow.executeNow'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function () {
                entity.type = 'EXECUTE';
                SmartFlow.update(entity, function () {
                    $state.go('storm.admin-smartflow-detail', {
                        'id': entity.id,
                        'isJobFlow': entity.newSmartFlow
                    });
                });
            });
        };

        function getQueryString() {
            var query = null;
            if (vm.query.filter !== '') {
                query = 'name=re=' + vm.query.filter;
            }
            if (vm.query.filters.status && vm.query.filters.status != 'ALL') {
                if(query) {
                    query = query + ';status==' + vm.query.filters.status;
                } else {
                    query = 'status==' + vm.query.filters.status;
                }

            } else {
                if(query) {
                    query = query + ';status=in=(DRAFT, LIVE, ENDED, PAUSED)';
                } else {
                    query = 'status=in=(DRAFT, LIVE, ENDED, PAUSED)';
                }

            }

            if (vm.query.filters.type && vm.query.filters.type != 'ALL') {
                query = query + ';type==' + vm.query.filters.type;
            }
            return query;
        }

        function resetModal() {
            loadAll();
            AlertService.displayToast($mdToast);
        };

        var refreshSmartFlowListener = $rootScope.$on('refreshSmartFlows', function(event, filter) {
            vm.query.filter = filter;
            vm.loadAll();
        });

        var updateSmartFlowListListener = $rootScope.$on('updateSmartFlowList', function (event, filters) {
            vm.illustartionEmptyScreenId = "no_smart_flow";
            vm.query.filters = filters;
            vm.loadAll();
        });

        // Unregister
        $scope.$on('$destroy', function() {
            refreshSmartFlowListener();
            updateSmartFlowListListener();
        });

        vm.loadAll();
    }
})();
