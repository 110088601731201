(function() {
    'use strict';

    angular
        .module('atheer.user')
        .controller('ProfileLibraryController', ProfileLibraryController);

    /* @ngInject */
    function ProfileLibraryController($scope, $state, $q, MetaData, Principal, User, JhiLanguageService, $translate, $mdToast, $filter, id, System) {
        var vm = this;

        vm.isAdminMode = id != 'me';
        vm.userId = id == 'me' ? Principal.getUserId() : id;
        vm.profileEvents = [];
        vm.profileMeta = null;
        vm.activeLink = 'user-about';

        vm.loadData = function() {
            vm.loadingData = true;

            $q.all([
                User.getById({
                    id: vm.userId
                }).$promise,
                MetaData.get({
                    id: 'user',
                    is_name: true
                }).$promise
            ]).then(function(data) {
                vm.user = data[0];
                angular.forEach(data[1].field_groups, function(field_group) {
                    if(field_group.name == 'profile' && !field_group.is_many) {
                        vm.profileMeta = field_group;
                    }
                    if (field_group.is_many) {
                        vm.profileEvents.push(field_group);
                    }
                });

                vm.loadingData = false;

            }).catch(function(data) {}).finally(function() {});
        }
        vm.loadData();
    }
})();
