(function() {
    'use strict';

    angular
        .module('atheer.smartFlow')
        .controller('SmartFlowTemplatePageController', SmartFlowTemplatePageController)

    /* @ngInject */
    function SmartFlowTemplatePageController($scope, $state, $mdDialog, $mdSidenav, SmartFlow, SmartFlowBuilder, $element, WORFLOW_CELL_CONSTANT) {
        var vm = this;

        vm.smartFlowTemplates = SmartFlow.query();
        vm.smartFlowTemplateId = null;
        vm.smartFlowTemplate = null;
        vm.useSetting = true

        vm.goToSmartFlowDetail = function() {
            $state.go("storm.admin-smartflow-detail");
        }

        vm.useThisSmartFlow = function() {
            var newSmartFlow = angular.copy(vm.smartFlowTemplate);

            delete newSmartFlow['id'];
            newSmartFlow.status = 'DRAFT';

            if (!vm.useSetting) {
                var cellsArray = [];
                var canvas_data = JSON.parse(vm.smartFlowTemplate.canvas_data);

                angular.forEach(canvas_data.cells, function(cell) {
                    if (cell.setting) {
                        var setting = WORFLOW_CELL_CONSTANT[cell.setting.typeId].setting;
                        setting.type = cell.setting.type;
                        setting.typeId = cell.setting.typeId;
                        cell.setting = setting;
                    }
                    cellsArray.push(cell);
                });

                canvas_data.cells = cellsArray;
                newSmartFlow.canvas_data = angular.toJson(canvas_data);
                //newSmartFlow.canvas_data = JSON.stringify(canvas_data);
            }

            SmartFlow.save(newSmartFlow, function(result) {
                $state.go("storm.admin-smartflow-detail", {
                    'id': result.id
                });
                vm.useSetting = true;
                $mdDialog.hide();
            });
        }




        vm.previewSmartFlowTemplate = function(smartFlowTemplate) {
            vm.smartFlowTemplateId = smartFlowTemplate.id;
            vm.smartFlowTemplate = smartFlowTemplate;

            // firing an event downwards
            $scope.$broadcast('templateChange', smartFlowTemplate.canvas_data);
        }

        vm.openTemplateOptionDialog = function() {
            $mdDialog.show({
                animation: true,
                templateUrl: 'modules/smartflow/smartflow-template-options-dialog.tmpl.html',
                scope: $scope,
                backdrop: 'static',
                clickOutsideToClose: true,
                size: 'lg'
            });
        }
    }
})();
