(function() {
    'use strict';

    angular
        .module('atheer.insights')
        .controller('InsightsDataController', InsightsDataController);

    /* @ngInject */
    function InsightsDataController($scope, $q, $translate, $filter, Report, CONTENT_META, triSettings, Workspace, workspaceId) {
        var vm = this;
        vm.primaryColor = triSettings.getSkinModel().primary_color_hex;
        vm.secondaryColor = triSettings.getSkinModel().secondary_color_hex;

        vm.loadingData = false;
        vm.dashboardModel = null;
        vm.illustartionEmptyScreenId = "insight_graph";


        vm.loadData = function() {
            vm.loadingData = false;
        }

        vm.loadData();
    }
})();
