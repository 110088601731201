(function() {
    'use strict';
    angular
        .module('atheer.services')
        .factory('Provider', Provider);

    /* @ngInject */
    function Provider($resource, DateUtils) {
        var resourceUrl = 'api/contents/providers/:id';

        return $resource(resourceUrl, {
            id: '@id'
        }, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function(result) {
                    return transformResponse(result);
                }
            },
            'save': {
                method: 'POST',
                transformResponse: function(result) {
                    return transformResponse(result);
                }
            },
            'update': {
                method: 'PUT',
                transformResponse: function(result) {
                    return transformResponse(result);
                }
            }
        });

        function transformResponse(result) {
            result = angular.fromJson(result);
            return result;
        }
    }
})();
