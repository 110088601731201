(function() {
    'use strict';

    angular
        .module('atheer')
        .controller('FieldSelectionPanelController', FieldSelectionPanelController);

    /* @ngInject */
    function FieldSelectionPanelController(mdPanelRef, $scope, $state, $mdDialog, $q, $filter, $mdToast, Principal, metaFieldGroups, conditionGroups, $mdPanel, FIELD_CONDITION_CONSTANT) {
        var vm = this;
        var fieldsToHide = ['managed_audiences','first_seen','last_seen','last_contacted','last_heard_from','user_type','browser_name','browser_version','browser_language','browser_os','initial_referrer','initial_referrer_domain','city','continent_code','country_code','country_name','latitude','longitude','postal_code','region_name','timezone','topic','content','manager'];
        var groupsToHide = ['segment', 'badge']
        vm.metaFieldGroups = $filter('filter')(metaFieldGroups, function(group) {
            return (groupsToHide.indexOf(group.name) === -1 && group.enabled && group.search_enabled);
        });

        //hiding fields in list FOS-4066
        for(var fieldIndex = 0; fieldIndex < vm.metaFieldGroups.length; fieldIndex++) {
            var fieldGroup = $filter('filter')(vm.metaFieldGroups[fieldIndex].fields, function(field) {
                return (fieldsToHide.indexOf(field.name) === -1 && field.enabled && field.search_enabled);
            });
            vm.metaFieldGroups[fieldIndex].fields = fieldGroup;
            if(!fieldGroup.length) {
                vm.metaFieldGroups.splice(fieldIndex,1);
                fieldIndex--;
            }
        }

        vm.selectField = function(group, field) {
            var condition = {
                "field_group_name": group.name,
                "field_name": field.name,
                "data_type": field.data_type,
                "operator": getDefaultOperator(field),
                "value": null
            };
            if (field.data_type === "LIST" || field.data_type == 'LANGUAGE' || field.data_type == 'TIMEZONE') {
                condition["displayValue"] = null;
            }

            var conditionGroup = {
                conditions: [condition]
            }
            conditionGroups.push(conditionGroup);
            mdPanelRef.condition = condition;
            if (field.list_options) {
                mdPanelRef.conditionListOptions = field.list_options;
            }
            mdPanelRef.close();

        };

        function getDefaultOperator(field) {
            var fieldConditionsMeta = FIELD_CONDITION_CONSTANT[field.data_type];
            if (field.data_type === "OBJECT") {
                return fieldConditionsMeta[field.ref_object][0].value
            }
            return fieldConditionsMeta[0].value;
        }
    }
})();
