(function() {
    'use strict';

    angular
        .module('atheer.workspace')
        .controller('WorkspaceSignupController', WorkspaceSignupController);

    /* @ngInject */
    function WorkspaceSignupController($translate, $timeout, WorkspaceSignup, $scope, $state, $mdToast, $http, $filter, triSettings) {
        var vm = this;

        vm.error = null;
        vm.success = false;
        vm.triSettings = triSettings;

        vm.workspace = {
            email: null
        }

        vm.workspaceSignupData = {
            email: null,
            first_name: null,
            last_name: null,
            password: null,
            confirmation_code: null,
            company_name: null,
            company_type: null,
            company_size: null,
            company_url: null,
            user_role: null,
            team_url: null
        }

        vm.signup = function() {
            WorkspaceSignup.save(vm.workspace, vm.onSaveSuccess, vm.onSaveError);
        };

        vm.onSaveSuccess = function(result) {
            vm.workspace = result;
            vm.success = true;

            vm.workspaceSignupData.email = result.email;
            vm.workspaceSignupData.confirmation_code = result.confirmation_code;

            $state.go('workspace.signup-confirm', {"workspaceSignupData": vm.workspaceSignupData});
        };

        vm.onSaveError = function() {
            vm.errorCreating = true;
        };
    }
})();
