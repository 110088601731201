(function() {
    'use strict';

    angular
        .module('atheer.system')
        .controller('SessionSettingController', SessionSettingController)

    /* @ngInject */
    function SessionSettingController($mdDialog, $q, $scope, $filter, $sce, ParseLinks, $state, AlertService, ContentAction, $mdToast, $mdSidenav, Principal, Setting, Feature, ToastService) {
        var vm = this;

        vm.sessionSetting = [];
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.loadingData = false;
        vm.timezones = [];
        vm.templates = [];
        vm.hrs = [];
        vm.minutes = [0, 15, 30, 45];
        vm.repeats = [];
        vm.possible_recurring_session = ["Daily", "Weekly", "Monthly", "No Fixed Time"];
        vm.timeslots = [];
        vm.days = [{name: "Sun", isSelected: false},
            {name: "Mon", isSelected: false},
            {name: "Tue", isSelected: false},
            {name: "Wed", isSelected: false},
            {name: "Thu", isSelected: false},
            {name: "Fri", isSelected: false},
            {name: "Sat", isSelected: false}];
        vm.weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        vm.frequencyDays = ["First", "Second", "Third", "Fourth", "Last"];
        vm.AMPM = ["AM", "PM"];
        vm.sessionSetting = [];
        vm.numOfdays = [];
        vm.enableWaitingRoom = false;
        vm.recurringSessionFeature = Feature.isActive('feature_airsession_recurring_session');

        vm.sessionModel = {
            id: null,
            host_video: false,
            participant_video: false,
            join_before_host: false,
            mute_upon_entry: false,
            sign_in_required: false,
            enable_waiting_room: false,
            record_session: false,
            recurring_Session: false,
            enable_external_survey: false,
            timezone_id: "1007",
            external_survey_url: "",
            wearable_external_survey_url: ""
        };

        vm.onSaveSuccess = function(result) {
            vm.sessionModel = result;
            vm.isSaving = false;
            AlertService.displayToast($mdToast);
        };

        vm.onSaveError = function() {
            vm.isSaving = false;
        };

        function setSessionSettings() {
            setSessionSettingField("host_video", vm.sessionModel.host_video);
            setSessionSettingField("participant_video", vm.sessionModel.participant_video);
            setSessionSettingField("join_before_host", vm.sessionModel.join_before_host);
            setSessionSettingField("mute_upon_entry", vm.sessionModel.mute_upon_entry);
            setSessionSettingField("sign_in_required", vm.sessionModel.sign_in_required);
            setSessionSettingField("enable_waiting_room", vm.sessionModel.enable_waiting_room);
            setSessionSettingField("record_session", vm.sessionModel.record_session);
            setSessionSettingField("recurring_Session", vm.sessionModel.recurring_Session);
            setSessionSettingField("timezone_id", vm.sessionModel.timezone_id);
            setSessionSettingField("enable_external_survey", vm.sessionModel.enable_external_survey);
            setSessionSettingField("external_survey_url", vm.sessionModel.external_survey_url);
            setSessionSettingField("wearable_external_survey_url", vm.sessionModel.wearable_external_survey_url);
        }

        function setSessionSettingField(fieldName, fieldValue) {
            var foundField = false;
            angular.forEach(vm.sessionSetting.fields, function(settingField) {
                if (settingField.name == fieldName) {
                    settingField.value = fieldValue;
                    foundField = true;
                }
            });

            if (!foundField) {
                var settingField = {
                    name: fieldName,
                    value: fieldValue,
                }
                vm.sessionSetting.fields.push(settingField);
            }
        }

        vm.save = function() {
            setSessionSettings();

            Setting.update(vm.sessionSetting, function() {
                ToastService.displayToast($filter('triTranslate')("atheer.setting.success"), 3000);
            });
        }



        vm.loadData = function() {
            vm.loadingData = true;

            $q.all([
                    Setting.getSystemTimezones({
                            size: 100
                        }).$promise,
                    Setting.getSessionSettings().$promise
                ])
                .then(function(results) {
                    vm.timezones = results[0];
                    setSettingSessionModel(results[1]);
                    vm.loadingData = false;
                });
        };

        function setSettingSessionModel(settings) {
            angular.forEach(settings, function(setting) {
                if (setting.category == 'session_setting') {
                    vm.sessionSetting = setting;

                    angular.forEach(setting.fields, function(settingField) {
                        if (settingField.name == 'host_video') {
                            if (settingField.value == "false")
                                vm.sessionModel.host_video = false;
                            else
                                vm.sessionModel.host_video = true;
                        }
                        if (settingField.name == 'participant_video') {
                            if (settingField.value == "false")
                                vm.sessionModel.participant_video = false;
                            else
                                vm.sessionModel.participant_video = true;
                        }
                        if (settingField.name == 'join_before_host') {
                            if (settingField.value == "false")
                                vm.sessionModel.join_before_host = false;
                            else
                                vm.sessionModel.join_before_host = true;
                        }
                        if (settingField.name == 'mute_upon_entry') {
                            if (settingField.value == "false")
                                vm.sessionModel.mute_upon_entry = false;
                            else
                                vm.sessionModel.mute_upon_entry = true;
                        }
                        if (settingField.name == 'sign_in_required') {
                            if (settingField.value == "false")
                                vm.sessionModel.sign_in_required = false;
                            else
                                vm.sessionModel.sign_in_required = true;
                        }
                        if (settingField.name == 'enable_waiting_room') {
                            if (settingField.value == "false")
                                vm.sessionModel.enable_waiting_room = false;
                            else
                                vm.sessionModel.enable_waiting_room = true;
                        }
                        if (settingField.name == 'record_session') {
                            if (settingField.value == "false")
                                vm.sessionModel.record_session = false;
                            else
                                vm.sessionModel.record_session = true;
                        }
                        if (settingField.name == 'recurring_Session') {
                            if (settingField.value == "false")
                                vm.sessionModel.recurring_Session = false;
                            else
                                vm.sessionModel.recurring_Session = true;
                        }
                        if (settingField.name == 'timezone_id') {
                            vm.sessionModel.timezone_id = settingField.value;
                        }
                        if (settingField.name == 'external_survey_url') {
                            if (settingField.value)
                                vm.sessionModel.external_survey_url = settingField.value;
                            else
                                vm.sessionModel.external_survey_url = "";
                        }
                        if (settingField.name == 'wearable_external_survey_url') {
                            if (settingField.value)
                                vm.sessionModel.wearable_external_survey_url = settingField.value;
                            else
                                vm.sessionModel.wearable_external_survey_url = "";
                        }
                        if (settingField.name == 'enable_external_survey') {
                            if (settingField.value == "false")
                                vm.sessionModel.enable_external_survey = false;
                            else
                                vm.sessionModel.enable_external_survey = true;
                        }
                    });
                }
            });
        }

        vm.loadData();
    }
})();
