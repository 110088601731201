(function() {
    'use strict';

    angular
        .module('atheer')
        .controller('FilterDetailDialogController', FilterDetailDialogController);

    /* @ngInject */
    function FilterDetailDialogController($scope, $state, $mdDialog, $q, $filter, $mdToast, $timeout, Filter, mode, object, filter) {
        var vm = this;

        vm.mode = mode;
        vm.object = object;

        vm.filterModel = {
            name: null,
            description: null
        };

        if (filter != null) {
            vm.filterModel = {
                name: filter.name,
                description: filter.desription
            };
        }

        vm.close = function() {
            $mdDialog.cancel();
        }

        vm.submit = function() {
            $mdDialog.hide(vm.filterModel);
        }
    }
})();
