(function () {
    'use strict';

    angular
        .module('atheer.core')
        .config(themesConfig);

    /* @ngInject */
    function themesConfig($mdThemingProvider, triThemingProvider, triSkinsProvider) {
        $mdThemingProvider.generateThemesOnDemand(true);
        /**
         *  PALETTES
         */
        $mdThemingProvider.definePalette('white', {
            '50': '#ffffff',
            '100': '#ffffff',
            '200': '#ffffff',
            '300': '#ffffff',
            '400': '#ffffff',
            '500': '#ffffff',
            '600': '#ffffff',
            '700': '#ffffff',
            '800': '#ffffff',
            '900': '#ffffff',
            'A100': '#ffffff',
            'A200': '#ffffff',
            'A400': '#ffffff',
            'A700': '#ffffff',
            'contrastDefaultColor': 'dark'
        });

        $mdThemingProvider.definePalette('black', {
            '50': '#e1e1e1',
            '100': '#b6b6b6',
            '200': '#8c8c8c',
            '300': '#646464',
            '400': '#3a3a3a',
            '500': '#e1e1e1',
            '600': '#e1e1e1',
            '700': '#232323',
            '800': '#1a1a1a',
            '900': '#121212',
            'A100': '#3a3a3a',
            'A200': '#ffffff',
            'A400': '#ffffff',
            'A700': '#ffffff',
            'contrastDefaultColor': 'light'
        });

        $mdThemingProvider.definePalette('crimson', {
            '50': '#fae1e4',
            '100': '#f2b4bc',
            '200': '#e9828f',
            '300': '#e05062',
            '400': '#d92b40',
            '500': '#d2051e',
            '600': '#cd041a',
            '700': '#c70416',
            '800': '#c10312',
            '900': '#b6010a',
            'A100': '#ffdfdf',
            'A200': '#ffacad',
            'A400': '#ff797b',
            'A700': '#ff5f62',
            'contrastDefaultColor': 'light',
            'contrastDarkColors': '50 100 200 A100 A200 A400 A700',
            'contrastLightColors': '300 400 500 600 700 800 900'
        });

        $mdThemingProvider.definePalette('scarlet', {
            '50': '#f8e5e6',
            '100': '#efbfc0',
            '200': '#e49597',
            '300': '#d96a6d',
            '400': '#d04a4d',
            '500': '#c82a2e',
            '600': '#c22529',
            '700': '#bb1f23',
            '800': '#b4191d',
            '900': '#a70f12',
            'A100': '#ffd6d7',
            'A200': '#ffa3a4',
            'A400': '#ff7072',
            'A700': '#ff5759',
            'contrastDefaultColor': 'light',
            'contrastDarkColors': '50 100 200 300 A100 A200 A400 A700',
            'contrastLightColors': '400 500 600 700 800 900'
        });

        $mdThemingProvider.definePalette('bright-red', {
            '50': '#ffe0e0',
            '100': '#ffb3b3',
            '200': '#ff8080',
            '300': '#ff4d4d',
            '400': '#ff2626',
            '500': '#ff0000',
            '600': '#ff0000',
            '700': '#ff0000',
            '800': '#ff0000',
            '900': '#ff0000',
            'A100': '#ffffff',
            'A200': '#fff2f2',
            'A400': '#ffbfbf',
            'A700': '#ffa6a6',
            'contrastDefaultColor': 'light',
            'contrastDarkColors': '50 100 200 300 A100 A200 A400 A700',
            'contrastLightColors': '400 500 600 700 800 900'
        });

        $mdThemingProvider.definePalette('bright-pink', {
            '50': '#fee1f7',
            '100': '#fdb5ec',
            '200': '#fc84df',
            '300': '#fb53d2',
            '400': '#fa2ec8',
            '500': '#f909be',
            '600': '#f808b8',
            '700': '#f706af',
            '800': '#f605a7',
            '900': '#f50299',
            'A100': '#ffffff',
            'A200': '#ffe8f5',
            'A400': '#ffb5e0',
            'A700': '#ff9cd5',
            'contrastDefaultColor': 'light',
            'contrastDarkColors': '50 100 200 300 A100 A200 A400 A700',
            'contrastLightColors': '400 500 600 700 800 900'
        });

        $mdThemingProvider.definePalette('violet', {
            '50': '#f7e0ff',
            '100': '#ecb3ff',
            '200': '#e080ff',
            '300': '#d34dff',
            '400': '#c926ff',
            '500': '#c000ff',
            '600': '#ba00ff',
            '700': '#b200ff',
            '800': '#aa00ff',
            '900': '#9c00ff',
            'A100': '#ffffff',
            'A200': '#f9f2ff',
            'A400': '#e3bfff',
            'A700': '#d8a6ff',
            'contrastDefaultColor': 'light',
            'contrastDarkColors': '50 100 200 300 A100 A200 A400 A700',
            'contrastLightColors': '400 500 600 700 800 900'
        });

        $mdThemingProvider.definePalette('grape', {
            '50': '#ece2ef',
            '100': '#cfb7d7',
            '200': '#b087bc',
            '300': '#9057a1',
            '400': '#78338c',
            '500': '#600f78',
            '600': '#580d70',
            '700': '#4e0b65',
            '800': '#44085b',
            '900': '#330448',
            'A100': '#d07cff',
            'A200': '#be49ff',
            'A400': '#ac16ff',
            'A700': '#a200fc',
            'contrastDefaultColor': 'light',
            'contrastDarkColors': '50 100 200 A100 A200',
            'contrastLightColors': '300 400 500 600 700 800 900 A400 A700'
        });

        $mdThemingProvider.definePalette('navy', {
            '50': '#e2e6f2',
            '100': '#b6bfdf',
            '200': '#8695ca',
            '300': '#556bb5',
            '400': '#304ba5',
            '500': '#0c2b95',
            '600': '#0a268d',
            '700': '#082082',
            '800': '#061a78',
            '900': '#031067',
            'A100': '#969fff',
            'A200': '#6370ff',
            'A400': '#3041ff',
            'A700': '#172aff',
            'contrastDefaultColor': 'light',
            'contrastDarkColors': '50 100 200 A100',
            'contrastLightColors': '300 400 500 600 700 800 900 A200 A400 A700'
        });

        $mdThemingProvider.definePalette('cobalt', {
            '50': '#e0e0ff',
            '100': '#b3b3ff',
            '200': '#8080ff',
            '300': '#4d4dff',
            '400': '#2626ff',
            '500': '#0000ff',
            '600': '#0000ff',
            '700': '#0000ff',
            '800': '#0000ff',
            '900': '#0000ff',
            'A100': '#ffffff',
            'A200': '#f2f2ff',
            'A400': '#bfbfff',
            'A700': '#a6a6ff',
            'contrastDefaultColor': 'light',
            'contrastDarkColors': '50 100 200 A100 A200 A400 A700',
            'contrastLightColors': '300 400 500 600 700 800 900'
        });

        $mdThemingProvider.definePalette('cerulean', {
            '50': '#e8ecff',
            '100': '#c5cfff',
            '200': '#9fafff',
            '300': '#798eff',
            '400': '#5c76ff',
            '500': '#3f5eff',
            '600': '#3956ff',
            '700': '#314cff',
            '800': '#2942ff',
            '900': '#1b31ff',
            'A100': '#ffffff',
            'A200': '#ffffff',
            'A400': '#ccd0ff',
            'A700': '#b3b9ff',
            'contrastDefaultColor': 'light',
            'contrastDarkColors': '50 100 200 300 A100 A200 A400 A700',
            'contrastLightColors': '400 500 600 700 800 900'
        });

        $mdThemingProvider.definePalette('sky-blue', {
            '50': '#f0fdfd',
            '100': '#d8fafa',
            '200': '#bff7f7',
            '300': '#a5f3f3',
            '400': '#91f1f1',
            '500': '#7eeeee',
            '600': '#76ecec',
            '700': '#6be9e9',
            '800': '#61e7e7',
            '900': '#4ee2e2',
            'A100': '#ffffff',
            'A200': '#ffffff',
            'A400': '#d8ffff',
            'A700': '#beffff',
            'contrastDefaultColor': 'light',
            'contrastDarkColors': '50 100 200 300 400 500 600 700 800 900 A100 A200 A400 A700',
            'contrastLightColors': ''
        });

        $mdThemingProvider.definePalette('process-blue', {
            '50': '#e0f0f9',
            '100': '#b3daef',
            '200': '#80c2e5',
            '300': '#4daada',
            '400': '#2697d2',
            '500': '#0085ca',
            '600': '#007dc5',
            '700': '#0072bd',
            '800': '#0068b7',
            '900': '#0055ab',
            'A100': '#d4e7ff',
            'A200': '#a1caff',
            'A400': '#6eadff',
            'A700': '#559eff',
            'contrastDefaultColor': 'light',
            'contrastDarkColors': '50 100 200 300 400 A100 A200 A400',
            'contrastStrongLightColors': '500 600 700 800 900 A700'
        });

        $mdThemingProvider.definePalette('dark-blue', {
            '50': '#e1e2ed',
            '100': '#b3b6d1',
            '200': '#8185b3',
            '300': '#4e5495',
            '400': '#282f7e',
            '500': '#020a67',
            '600': '#02095f',
            '700': '#010754',
            '800': '#01054a',
            '900': '#010339',
            'A100': '#6f6fff',
            'A200': '#3c3cff',
            'A400': '#0909ff',
            'A700': '#0000ef',
            'contrastDefaultColor': 'light',
            'contrastDarkColors': '50 100 200 300 400 A100 A200 A400',
            'contrastStrongLightColors': '500 600 700 800 900 A700'
        });

        $mdThemingProvider.definePalette('cool-grey', {
            '50': '#eeefef',
            '100': '#d6d7d7',
            '200': '#babcbd',
            '300': '#9ea1a3',
            '400': '#8a8c8f',
            '500': '#75787b',
            '600': '#6d7073',
            '700': '#626568',
            '800': '#585b5e',
            '900': '#45484b',
            'A100': '#b2d5f9',
            'A200': '#83bcf5',
            'A400': '#46a2ff',
            'A700': '#2c96ff',
            'contrastDefaultColor': 'light',
            'contrastDarkColors': '50 100 200 300 400 A100 A200 A400',
            'contrastStrongLightColors': '500 600 700 800 900 A700'
        });

        $mdThemingProvider.definePalette('sea-green', {
            '50': '#e5f8f4',
            '100': '#beeee4',
            '200': '#93e3d3',
            '300': '#68d8c1',
            '400': '#47cfb3',
            '500': '#27c7a6',
            '600': '#23c19e',
            '700': '#1dba95',
            '800': '#17b38b',
            '900': '#0ea67b',
            'A100': '#d4fff2',
            'A200': '#a1ffe2',
            'A400': '#6effd2',
            'A700': '#55ffca',
            'contrastDefaultColor': 'light',
            'contrastDarkColors': '50 100 200 300 400 500 600 700 A100 A200 A400 A700',
            'contrastLightColors': '800 900'
        });

        $mdThemingProvider.definePalette('deep-green', {
            '50': '#e6eaeb',
            '100': '#c1cbcc',
            '200': '#98a8aa',
            '300': '#6e8588',
            '400': '#4f6a6f',
            '500': '#305055',
            '600': '#2b494e',
            '700': '#244044',
            '800': '#1e373b',
            '900': '#13272a',
            'A100': '#6ce8ff',
            'A200': '#39e0ff',
            'A400': '#06d8ff',
            'A700': '#00c6eb',
            'contrastDefaultColor': 'light',
            'contrastDarkColors': '50 100 200 A100 A200 A400 A700',
            'contrastLightColors': '300 400 500 600 700 800 900'
        });

        $mdThemingProvider.definePalette('bright-green', {
            '50': '#e1fae6',
            '100': '#b4f2c2',
            '200': '#82e999',
            '300': '#4fe070',
            '400': '#2ad951',
            '500': '#04d232',
            '600': '#03cd2d',
            '700': '#03c726',
            '800': '#02c11f',
            '900': '#01b613',
            'A100': '#dfffe0',
            'A200': '#acffb0',
            'A400': '#79ff80',
            'A700': '#5fff68',
            'contrastDefaultColor': 'light',
            'contrastDarkColors': '50 100 200 300 400 500 A100 A200 A400 A700',
            'contrastLightColors': '600 700 800 900'
        });

        $mdThemingProvider.definePalette('basil-green', {
            '50': '#e1f2ea',
            '100': '#b5dfc9',
            '200': '#84caa6',
            '300': '#52b582',
            '400': '#2da567',
            '500': '#08954c',
            '600': '#078d45',
            '700': '#06823c',
            '800': '#047833',
            '900': '#026724',
            'A100': '#96ffb1',
            'A200': '#63ff8b',
            'A400': '#30ff65',
            'A700': '#17ff52',
            'contrastDefaultColor': 'light',
            'contrastDarkColors': '50 100 200 300 A100 A200 A400 A700',
            'contrastLightColors': '400 500 600 700 800 900'
        });

        $mdThemingProvider.definePalette('bright-yellow', {
            '50': '#fff9e6',
            '100': '#fff1c0',
            '200': '#ffe896',
            '300': '#ffdf6c',
            '400': '#ffd84d',
            '500': '#ffd12d',
            '600': '#ffcc28',
            '700': '#ffc622',
            '800': '#ffc01c',
            '900': '#ffb511',
            'A100': '#ffffff',
            'A200': '#fffdf8',
            'A400': '#ffebc5',
            'A700': '#ffe3ac',
            'contrastDefaultColor': 'light',
            'contrastDarkColors': '50 100 200 300 400 500 600 700 800 900 A100 A200 A400 A700',
            'contrastLightColors': ''
        });

        var primaryColor = localStorage.getItem('primary-color');
        var secondaryColor = localStorage.getItem('secondary-color');

        if (primaryColor != null) {
            primaryColor = '#' + primaryColor;
            var tinyColorObject = tinycolor(primaryColor);
            var contrastDefaultColor = tinyColorObject.isLight() ? 'dark' : 'light';

            $mdThemingProvider.definePalette('custom-primary-color', {
                '50': tinyColorObject.lighten(52),
                '100': tinyColorObject.lighten(37),
                '200': tinyColorObject.lighten(26),
                '300': tinyColorObject.lighten(12),
                '400': tinyColorObject.lighten(6),
                '500': primaryColor,
                '600': tinyColorObject.darken(6),
                '700': tinyColorObject.darken(12),
                '800': tinyColorObject.darken(18),
                '900': tinyColorObject.darken(24),
                'A100': tinyColorObject.lighten(50).saturate(30),
                'A200': tinyColorObject.lighten(30).saturate(30),
                'A400': tinyColorObject.lighten(10).saturate(15),
                'A700': tinyColorObject.lighten(5).saturate(5),
                'contrastDefaultColor': contrastDefaultColor
            });
        }

        if (secondaryColor != null) {
            secondaryColor = '#' + secondaryColor;
            var tinyColorObject = tinycolor(secondaryColor);
            var contrastDefaultColor = tinyColorObject.isLight() ? 'dark' : 'light';
            $mdThemingProvider.definePalette('custom-secondary-color', {
                '50': tinyColorObject.lighten(52),
                '100': tinyColorObject.lighten(37),
                '200': tinyColorObject.lighten(26),
                '300': tinyColorObject.lighten(12),
                '400': tinyColorObject.lighten(6),
                '500': secondaryColor,
                '600': tinyColorObject.darken(6),
                '700': tinyColorObject.darken(12),
                '800': tinyColorObject.darken(18),
                '900': tinyColorObject.darken(24),
                'A100': tinyColorObject.lighten(50).saturate(30),
                'A200': tinyColorObject.lighten(30).saturate(30),
                'A400': tinyColorObject.lighten(10).saturate(15),
                'A700': tinyColorObject.lighten(5).saturate(5),
                'contrastDefaultColor': contrastDefaultColor
            });
        }

        var triCyanMap = $mdThemingProvider.extendPalette('cyan', {
            'contrastDefaultColor': 'light',
            'contrastLightColors': '500 700 800 900',
            'contrastStrongLightColors': '500 700 800 900'
        });

        // Register the new color palette map with the name triCyan
        $mdThemingProvider.definePalette('triCyan', triCyanMap);
    }
})();
