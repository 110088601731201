(function() {
    'use strict';

    angular
        .module('atheer.cloud')
        .config(moduleConfig);

    function moduleConfig($stateProvider) {
        $stateProvider
        .state('storm.cloud-admin.logs', {
            url: '/logs',
            templateUrl: 'modules/cloud/logs/logs.tmpl.html',
            controller: 'LogsController',
            controllerAs: 'vm',
            data: {
                pageTitle: 'atheer.logs.title',
                layout: {
                    contentClass: 'layout-column',
                    footer: false,
                    showToolbar: false,
                    showSideNavMenu: true,
                }
            }
        });
    }
})();
