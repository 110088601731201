(function() {
    'use strict';

    angular
        .module('atheer.user')
        .controller('UserController', UserController);

    /* @ngInject */
    function UserController($rootScope, $scope, $state, Principal, $timeout, User, ParseLinks, AlertService, pagingParams, paginationConstants, $mdDialog, $mdToast, $stateParams, JhiLanguageService, Team, Experience, PermissionService, Feature, $filter, ToastService) {
        var vm = this;

        vm.emptyCriteria = {
            match_all: true,
            condition_groups: []
        };

        vm.loadingData = false;
        vm.loadAll = loadAll;
        vm.transition = transition;
        vm.reOrder = reOrder;
        vm.removeFilter = removeFilter;
        vm.updateUserListListener = null;
        vm.selectedUsers = [];
        vm.selectedTeam = null;
        vm.showNewUserMenu = false;
        vm.showUserSideMenu = $state.$current.data.showSideNavMenu;
        vm.teamEnabled = Feature.isActive('feature_audience_enable');

        vm.createObject = createObject;
        vm.editObject = editObject;
        vm.deleteObject = deleteObject;
        vm.getQueryString = getQueryString;

        vm.clear = clear;
        vm.setActive = setActive;

        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;

        vm.currentAccount = null;
        vm.languages = null;
        vm.links = null;
        vm.page = 1;
        vm.illustartionEmptyScreenId = "users";

        vm.permissions = {
            create: 'create-user',
            update: 'update-user',
            delete: 'delete-user',
            manage: 'manage-user',
            import: 'import-user',
            invite: 'manage-invitation-user',
            team: 'manage-segment-user',
            user_field: 'manage-user-field-user'
        };

        vm.totalItems = null;

        vm.query = {
            field: 'first_name=re=',
            filter: '',
            limit: paginationConstants.itemsPerPage,
            order: '-id',
            page: 1
        };

        vm.filter = {
            show: false,
            options: {
                debounce: 500
            }
        };

        vm.toggleFilter = function() {
            vm.filter.show = !vm.filter.show;
        };

        vm.clearSelection = function() {
            vm.selectedUsers = [];
        };

        vm.init = function() {
            JhiLanguageService.getAll().then(function(languages) {
                vm.languages = languages;
            });
            vm.showNewUserMenu = PermissionService.hasPermission(vm.permissions.create) && (PermissionService.hasPermission(vm.permissions.import) || (Feature.isActive('feature_user_invitation') && PermissionService.hasPermission(vm.permissions.invite)))
        };

        function loadAll() {
            vm.loadingData = true;
            vm.selectedUsers = [];

            var savedQuery = getSavedFilters();
            if ( savedQuery ) {
                vm.query = savedQuery;
            }
            vm.page = vm.query.page;

            if (vm.selectedTeam == null || vm.selectedTeam.audience_no == 1000) {
                var criteria = vm.selectedTeam == null ? vm.emptyCriteria : vm.selectedTeam.criteria;

                User.query({
                    page: vm.page - 1,
                    size: paginationConstants.itemsPerPage,
                    sort: sort(),
                    query: getQueryString(),
                    check_admin: true
                }, function(data, headers) {
                    vm.users = data;
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    //vm.page = pagingParams.page;
                    vm.loadingData = false;
                }, onError);
            } else if (vm.selectedTeam.type == 'SMART') {
                var criteria = vm.selectedTeam == null ? vm.emptyCriteria : vm.selectedTeam.criteria;

                Experience.getUsers({
                    page: vm.page - 1,
                    size: paginationConstants.itemsPerPage,
                    sort: sort()
                }, criteria, function(data, headers) {
                    vm.users = data;
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    //vm.page = pagingParams.page;
                    vm.loadingData = false;
                }, onError);
            } else if (vm.selectedTeam.type == 'STATIC') {
                Experience.getTeams({
                    page: vm.page - 1,
                    size: paginationConstants.itemsPerPage,
                    query: "object_id==" + vm.selectedTeam.id,
                    user_info: true
                }, function(data, headers) {
                    vm.users = [];
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;

                    angular.forEach(data, function(userTeam) {
                        vm.users.push(userTeam.user_info);
                    });

                    //vm.page = pagingParams.page;
                    vm.loadingData = false;
                }, onError);
            }

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onError(error) {
                AlertService.error(error.data.message);
                vm.loadingData = false;
            }
        };

        function reOrder(order) {
            vm.predicate = order;
            vm.transition();
        }

        function transition() {
            if (vm.selectedTeam == null || vm.selectedTeam.audience_no == 1000) {
                var criteria = vm.selectedTeam == null ? vm.emptyCriteria : vm.selectedTeam.criteria;
                vm.promise = User.query({
                    page: vm.page - 1,
                    size: paginationConstants.itemsPerPage,
                    sort: getSortOrder(),
                    query: getQueryString(),
                    check_admin: true
                }).$promise;

                vm.promise.then(function(data, headers) {
                    vm.users = data;
                    vm.queryCount = vm.totalItems;
                });
            } else if (vm.selectedTeam.type == 'SMART') {
                var criteria = vm.selectedTeam == null ? vm.emptyCriteria : vm.selectedTeam.criteria;
                vm.promise = Experience.getUsers({
                    page: vm.page - 1,
                    size: paginationConstants.itemsPerPage,
                    sort: getSortOrder(),
                }, criteria).$promise;

                vm.promise.then(function(data, headers) {
                    vm.users = data;
                    vm.queryCount = vm.totalItems;
                });
            } else if (vm.selectedTeam.type == 'STATIC') {
                vm.promise = Experience.getTeams({
                    page: vm.page - 1,
                    size: paginationConstants.itemsPerPage,
                    query: "object_id==" + vm.selectedTeam.id,
                    user_info: true
                }).$promise;

                vm.promise.then(function(data, headers) {
                    vm.users = [];
                    vm.queryCount = vm.totalItems;
                    angular.forEach(data, function(userTeam) {
                        vm.users.push(userTeam.user_info);
                    });
                });
            }
            vm.query.page = vm.page;
            saveFilters(vm.query);
        };

        function removeFilter() {
            vm.filter.show = false;
            vm.query.filter = '';
            resetModal();
        };

        function getSortOrder() {
            if (vm.predicate.startsWith('-')) {
                return vm.predicate.substr(1) + ',' + 'desc';
            } else {
                return vm.predicate + ',' + 'asc';
            }

        }

        function getQueryString() {
            if (vm.query.filter.length <= 0) {
                return null;
            } else {
                var queryArr = vm.query.filter.split(" ");
                var searchQuery = "(";

                searchQuery += 'first_name=re="' + vm.query.filter + '"';
                searchQuery += ',last_name=re="' + vm.query.filter + '"';
                searchQuery += ',email=re="' + vm.query.filter + '"';

                searchQuery += ")";

                return searchQuery
            }
        };

        function createObject() {
            $mdDialog.show({
                animation: true,
                templateUrl: 'modules/user/user-detail-dialog.html',
                controller: 'UserDetailDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: function() {
                        return {
                            id: null,
                            login: null,
                            password: null,
                            first_name: null,
                            last_name: null,
                            email: null,
                            invite_user: false,
                            activated: true,
                            language_id: null,
                            timezone_id: null,
                            created_by: null,
                            created_date: null,
                            last_modified_by: null,
                            last_modified_on: null
                        };
                    }
                }
            }).then(function() {
                resetModal();
            });
        };

        function editObject(objectId) {
            $state.go('storm.admin-profile.overview', {
                'id': objectId
            });
        };

        function deleteObject(entity) {
            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.userManagement.deleteUser'))
                .ariaLabel($filter('translate')('atheer.userManagement.deleteUser'))
                .ok($filter('translate')('entity.action.delete'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function() {
                User.delete({
                    login: entity.login
                }, function() {
                    resetModal();
                });
            });
        };

        function resetModal() {
            loadAll();
            AlertService.displayToast($mdToast);
        };

        function clear() {
            vm.user = {
                id: null,
                login: null,
                first_name: null,
                last_name: null,
                email: null,
                activated: true,
                language_id: 'en',
                timezone_id: null,
                created_by: null,
                created_date: null,
                last_modified_by: null,
                last_modified_on: null
            };
            vm.editForm.$setPristine();
            vm.editForm.$setUntouched();
        };

        function setActive(user, isActivated) {
            user.activated = isActivated;
            User.update(user, function() {
                vm.loadAll();
                vm.clear();
            });
        };

        $scope.$watchCollection('vm.selectedUsers', function(newVal) {
            $scope.$emit('refreshSelectedCardsToolbar', vm.selectedUsers);
        });

        var refreshUsersListener = $rootScope.$on('refreshUsers', function(event, filter) {
            vm.query.filter = filter;
            vm.query.page = 1;
            saveFilters(vm.query);
            vm.loadAll();
        });

        //calls when hit menu on sidebar to open sub menus in side bar
        var updateUserListListener = $rootScope.$on('updateUserList', function(event, team) {
            if ( vm.selectedTeam !== null ) { vm.query.page = 1; saveFilters(vm.query);}
            vm.selectedTeam = team;
            vm.loadAll();
        });

        var refreshSelectedCardsListing = $rootScope.$on('refreshSelectedCardsListing', function(event, selectedUsers) {
            vm.selectedUsers = selectedUsers;
        });

        function saveFilters(filterData) {
            sessionStorage.setItem('objectFilters', JSON.stringify(filterData));
        };
    
        function getSavedFilters() {
            return JSON.parse(sessionStorage.getItem('objectFilters'));
        };

        // Unregister
        $scope.$on('$destroy', function() {
            refreshUsersListener();
            updateUserListListener();
            refreshSelectedCardsListing();
        });

        vm.init();

        if(!vm.teamEnabled) {
            vm.loadAll();
            $scope.$emit('showSideMenu', 'false');
        }
    }
})();
