(function () {
    "use strict";

    angular
        .module("atheer.content")
        .controller("ContentViewController", ContentViewController);

    /* @ngInject */
    function ContentViewController($scope, $window, $state, $sce, $location, $rootScope) {
        var vm = this;
        vm.ngxUrlTrusted = null;
        vm.isIFrameDataLoaded = false;

        function setNgxPageUrl() {
            var ngxUrl = null;
            var hostUrl =
                location.hostname === "localhost"
                    ? "https://" + location.hostname + ":4200"
                    : location.origin;
            var path = $location.path();
            $rootScope.selectedLeftNavMenu = 'home';

            if ($state.current.name === "storm.content-documents") {
                $rootScope.selectedLeftNavMenu = 'library';
                const queryParams = JSON.parse(sessionStorage.getItem('DocumentListingQueryParams'));
                var query = []
                if(queryParams && queryParams.hasOwnProperty('page')) {
                    query.push('page='+queryParams.page)
                } 
                if (queryParams && queryParams.hasOwnProperty('size')) {
                    query.push('size='+queryParams.size)
                }
                if (queryParams && queryParams.hasOwnProperty('search')) {
                    query.push('search='+queryParams.search)
                } 
                sessionStorage.removeItem('DocumentListingQueryParams')
                ngxUrl = hostUrl + "/ngx/contents/content-documents?" + query.join('&');
            }
            if ($state.current.name === "storm.content-view") {
                var id = path.split("/").pop();
                ngxUrl = hostUrl + "/ngx/contents/" + id;
            }
            vm.ngxUrlTrusted = $sce.trustAsResourceUrl(ngxUrl);
        }

        function handleNgxEvent(event) {
            var hostUrl =
                location.hostname === "localhost"
                    ? "https://" + location.hostname + ":4200"
                    : location.origin;
            if (event.origin !== hostUrl) return;
            if (event.data.ngxLocationChanged) {
                var ngState = event.data.ngxLocationChanged.ngState;
                var id = event.data.ngxLocationChanged.id;
                vm.isIFrameDataLoaded = true;
                scopeApply();

                if(ngState === "storm.content-view")
                    return;

                if (id == null) {
                    $state.transitionTo(ngState, null, {
                        location: true,
                        inherit: true,
                        relative: $state.$current,
                        notify: false,
                    });
                } else {
                    $state.transitionTo(
                        ngState,
                        {
                            id: id,
                        },
                        {
                            location: true,
                            inherit: true,
                            relative: $state.$current,
                            notify: false,
                        }
                    );
                }
            }
            else if(event.data.navigate) {
                var ngState = event.data.navigate.toState;
                var stateParams = event.data.navigate.params;
                sessionStorage.setItem('DocumentListingQueryParams', JSON.stringify(event.data.navigate.params))
                if (stateParams) {
                    if (stateParams.id) {
                        $state.go(ngState, { id: stateParams.id, contentType: 'document' });
                    } else {
                        $state.go(ngState, { contentType: 'document' });
                    }
                }
            }
        }

        var scopeApply = function (fn) {
            var phase = $scope.$root.$$phase;
            if (phase !== "$apply" && phase !== "$digest") {
                return $scope.$apply(fn);
            }
        };

        $window.addEventListener("message", handleNgxEvent, false);

        // Unregister
        $scope.$on("$destroy", function () {
            window.removeEventListener("message", handleNgxEvent, false);
        });
        setNgxPageUrl();
    }
})();
