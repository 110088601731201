(function() {
    'use strict';

    angular
        .module('atheer.system')
        .controller('UserFieldPickerController', UserFieldPickerController);

    /* @ngInject */
    function UserFieldPickerController($scope, $state, $mdDialog, Setting, ParseLinks, AlertService, $mdToast, $filter, MetaData, selectedUserFields) {
        var vm = this;

        vm.loadAll = loadAll;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.predicate = "id";
        vm.selected = [];
        vm.allowMultiSelect = true; 
        vm.userFields = [];
        vm.selectedUserFields = selectedUserFields;

        vm.currentSelectedItems = [];
        vm.FIELDS_NOT_SUPPORTED_IN_SIGNUP = "user_no,preferred_name,initial_referrer_domain,timezone,login,email,status,picture_id,banner_id, event_summary,external_id,start_date,terminated_on, activated,deleted,activated_on, first_seen,last_seen,last_seen_ip,last_contacted,last_heard_from,activation_key,reset_key, reset_date,web_device,geoip_data,user_type,managed_audiences,browser_name,browser_version,browser_language, browser_os,manager,initial_referrer,initial_referrer_domaim,city,continent_code,country_code,country_name,latitude,longitude,postal_code,region_name";
 

        vm.loadAll();

        function loadAll() {
            MetaData.get({
                id: 'user',
                is_name: true,
                add_default_properties: true
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                if (data.field_groups && data.field_groups.length > 0) {
                    angular.forEach(data.field_groups[0].fields, function(userField) {
                        if ( ! vm.FIELDS_NOT_SUPPORTED_IN_SIGNUP.includes(userField.name) && userField.enabled === true ) {
                            var flist = $filter('filter')(vm.selectedUserFields, { name: userField.name }, true);
                            if ( flist === undefined || flist == '' ) {
                                vm.userFields.push(userField);
                            }
                        }
                    });
                }
                vm.page = 1;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function reOrder(order) {
        }


        function getSortOrder() {
        }

        function getQueryString() {

        }

        function removeFilter() {

        }

        function resetModal() {
            loadAll();
            AlertService.displayToast($mdToast);
        };

        vm.updateParentObject = function() {
            $mdDialog.hide(vm.currentSelectedItems);
        }

        vm.close = function() {
            $mdDialog.cancel();
        }
    }
})();
