(function() {
    'use strict';
    angular
        .module('atheer.services')
        .factory('Filter', Filter);

    /* @ngInject */
    function Filter($resource) {
        var resourceUrl = 'api/filters/:id';

        return $resource(resourceUrl, {
            id: '@id'
        }, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function(data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': {
                method: 'PUT'
            }
        });
    }
})();
