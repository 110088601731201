(function() {
    'use strict';

    angular
        .module('atheer.topic')
        .controller('TopicController', TopicController);

    /* @ngInject */
    function TopicController($rootScope, $scope, $window, $state, Topic, ParseLinks, AlertService, pagingParams, paginationConstants, $mdDialog, $mdToast, $stateParams, Feature, $filter, ToastService) {
        var vm = this;

        vm.loadAll = loadAll;
        vm.loadingData = false;
        vm.transition = transition;
        vm.removeFilter = removeFilter;
        vm.getQueryString = getQueryString;

        vm.createObject = createObject;
        vm.editObject = editObject;
        vm.deleteObject = deleteObject;

        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.reOrder = reOrder;
        vm.illustartionEmptyScreenId = "topic";

        vm.permissions = {
            create: 'manage-topic-content',
            update: 'manage-topic-content',
            delete: 'manage-topic-content',
            manage: 'manage-topic-content'
        };

        vm.query = {
            filter: '',
            limit: paginationConstants.itemsPerPage,
            order: '-id',
            page: 1,
            filters: {
                enabled: false,
                externalId: false,
                allowScan: false
            }
        };

        vm.filter = {
            show: false,
            options: {
                debounce: 500
            }
        };

        function loadAll() {
            vm.loadingData = true;
            var savedQuery = getSavedFilters();
            if ( savedQuery ) {
                vm.query = savedQuery;
            }
            vm.page = vm.query.page;

            Topic.query({
                page: vm.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort(),
                query: getQueryString()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.loadingData = false;
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.topics = data;
                //vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
                vm.loadingData = false;
            }
        }

        function reOrder(order) {
            vm.predicate = order;
            vm.reverse = !vm.reverse;
            vm.transition();
        }

        function transition() {
            vm.promise = Topic.query({
                page: vm.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                query: getQueryString()
            }).$promise;

            vm.promise.then(function(data, headers) {
                vm.queryCount = vm.totalItems;
                vm.topics = data;
            });

            vm.query.page = vm.page;
            saveFilters(vm.query);
        };

        function getQueryString() {
            var topicQuery = "";

            if (vm.query.filters.enabled) {
                if (topicQuery === "") {
                    topicQuery = 'enabled==true';
                } else {
                    topicQuery = topicQuery + ';enabled==true';
                }
            }

            if (vm.query.filters.externalId) {
                if (topicQuery === "") {
                    topicQuery = 'externalId=ex=true';
                } else {
                    topicQuery = topicQuery + ';externalId=ex=true';
                }
            }

            if (vm.query.filters.allowScan) {
                if (topicQuery === "") {
                    topicQuery = 'allowScan==true';
                } else {
                    topicQuery = topicQuery + ';allowScan==true';
                }
            }

            if (vm.query.filter.length > 0) {
                topicQuery = 'name=re=' + vm.query.filter;
            }

            return topicQuery;
        }

        function removeFilter() {
            vm.filter.show = false;
            vm.query.filter = '';

            if (vm.filter.form.$dirty) {
                vm.filter.form.$setPristine();
            }
        };

        vm.getDefaultBackground = function() {
            return '#bdbec0';
        };

        vm.back = function() {
            $window.history.back();
        };

        vm.onPublishChange = function(topic) {
            Topic.update(topic, function() {
                ToastService.displayToast(topic.name + ' ' + $filter('translate')('atheer.content.enableUpdateSuccess'), 3000);
            }, function() {
                ToastService.displayToast($filter('translate')('atheer.content.enableUpdateError'), 3000)
            });
        }

        function createObject(parentTopic) {
            $state.go('storm.admin-topic-detail', {
                'topicType': 'STATIC',
                'parentTopic': null
            });
        };

        function editObject(objectId) {
            $state.go("storm.admin-topic-detail", {
                'id': objectId
            });
        }

        function deleteObject(entity) {
            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.topic.deleteTopic'))
                .ariaLabel($filter('translate')('atheer.topic.deleteTopic'))
                .ok($filter('translate')('entity.action.delete'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function() {
                Topic.delete({
                    id: entity.id
                }, function() {
                    resetModal();
                });
            });
        };

        function resetModal() {
            loadAll();
            AlertService.displayToast($mdToast);
        };

        function saveFilters(filterData) {
            sessionStorage.setItem('objectFilters', JSON.stringify(filterData));
        };
    
        function getSavedFilters() {
            return JSON.parse(sessionStorage.getItem('objectFilters'));
        };

        var refreshTopicListener = $rootScope.$on('refreshTopics', function(event, filter) {
            vm.query.filter = filter;
            vm.query.page = 1;
            saveFilters(vm.query);
            vm.loadAll();
        });

        var updateTopicListener = $rootScope.$on('updateTopicList', function(event, filters) {
            vm.query.filters = filters;
            vm.query.page = 1;
            saveFilters(vm.query);
            vm.loadAll();
        });

        // Unregister
        $scope.$on('$destroy', function() {
            refreshTopicListener();
            updateTopicListener();
        });

        vm.loadAll();
    }
})();
