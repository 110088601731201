(function() {
    'use strict';

    angular
        .module('atheer.system')
        .controller('BadgeController', BadgeController);

    /* @ngInject */
    function BadgeController($scope, $state, $window, Badge, ParseLinks, AlertService, pagingParams, paginationConstants, $mdDialog, $mdToast, $stateParams, $filter) {
        var vm = this;

        vm.loadAll = loadAll;
        vm.transition = transition;
        vm.reOrder = reOrder;
        vm.removeFilter = removeFilter;
        vm.getQueryString = getQueryString;

        vm.createObject = createObject;
        vm.editObject = editObject;
        vm.deleteObject = deleteObject;

        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
         vm.illustartionEmptyScreenId = "badge";

        vm.showCardView = true;
        vm.cardView = true;

        vm.permissions = {
            create : 'manage-badge-system',
            update : 'manage-badge-system',
            delete : 'manage-badge-system',
            manage : 'manage-badge-system'
        };

        vm.query = {
            filter: '',
            limit: paginationConstants.itemsPerPage,
            order: '-id',
            page: 1
        };

        vm.filter = {
            show: false,
            options: {
                debounce: 500
            }
        };

        vm.openAssignModal = function(badgeObj) {
            $mdDialog.show({
                templateUrl: 'modules/system/badge/badge-assign-dialog.tmpl.html',
                clickOutsideToClose: true,
                controller: "BadgeAssignController",
                controllerAs: "vm",
                size: 'lg',
                locals: {
                    badge: badgeObj,
                    userId: undefined
                }
            });
        }

        function loadAll() {
            Badge.query({
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort(),
                query: getQueryString()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.badges = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function getQueryString() {
            if (vm.query.filter.length <= 0) {
                return null;
            } else {
                return 'name=re=' + vm.query.filter;
            }
        }

        function reOrder(order) {
            vm.predicate = order;
            vm.reverse = !vm.reverse;
            vm.transition();
        }

        function transition() {
            vm.promise = Badge.query({
                page: vm.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                query: getQueryString()
            }).$promise;

            vm.promise.then(function(data, headers) {
                vm.queryCount = vm.totalItems;
                vm.badges = data;
            });
        }

        function removeFilter() {
            vm.filter.show = false;
            vm.query.filter = '';
            vm.loadAll();
        }

        function createObject() {
            $state.go('storm.admin-badge-detail');
        }

        function editObject(objectId) {
            $state.go('storm.admin-badge-detail', {
                'id': objectId
            });
        }

        function deleteObject(entity) {
            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.badge.deleteObject'))
                .ariaLabel($filter('translate')('atheer.badge.deleteObject'))
                .ok($filter('translate')('entity.action.delete'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function() {
                Badge.delete({
                    id: entity.id
                }, function() {
                    resetModal();
                });
            });
        };

        function resetModal() {
            loadAll();
            AlertService.displayToast($mdToast);
        };

        vm.getDefaultBackground = function() {
            return '#bdbec0';
        };

        vm.back = function() {
            $window.history.back();
        };

        vm.loadAll();
    }
})();
