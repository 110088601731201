(function() {
    'use strict';

    angular
        .module('atheer.user')
        .controller('SupportToolbarController', SupportToolbarController);

    /* @ngInject */
    function SupportToolbarController($scope, $state, $q, Explore, ParseLinks, triLoaderService, $filter, Experience, Principal, $mdToast, Feature, $window) {
        var vm = this;

        vm.loadingData = false;

        function loadData() {
            vm.loadingData = false;
        }

        vm.back = function() {
            $window.history.back();
        };
        loadData();
    }
})();
