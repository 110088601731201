(function () {
    "use strict";

    angular
        .module("atheer.datamodel")
        .controller("DataModelCreateController", DataModelCreateController);

    /* @ngInject */
    function DataModelCreateController(
        $scope,
        $stateParams,
        $mdDialog,
        entity,
        DataModel,
        User,
        data,
        JhiLanguageService,
        $translate,
        ToastService
    ) {
        var vm = this;
        vm.isEditMode = data ? true : false;
        vm.displayName = vm.isEditMode ? data.displayName : "";
        vm.loadingData = false;
        vm.propertyList = vm.isEditMode
            ? data.properties
            : [{ name: "", dataType: "NUMBER" }];
        vm.title = vm.isEditMode ? $translate.instant('atheer.datamodel.home.createLabel'): $translate.instant('atheer.datamodel.home.editLabel');

        vm.dataTypeList = [
            {
                name: "STRING",
            },
            {
                name: "NUMBER",
            },
            {
                name: "DECIMAL",
            },
            {
                name: "DATE",
            },
            {
                name: "DATETIME",
            },
            {
                name: "TEAM",
            },
            {
                name: "BOOLEAN",
            }
        ];

        vm.togglePanel = function (componentId) {
            $mdSidenav(componentId).toggle();
        };

        vm.getDefaultBackground = function () {
            return "#bdbec0";
        };

        var onSaveSuccess = function (result) {
            var message = vm.isEditMode
                ? $translate.instant('atheer.datamodel.created'): $translate.instant('atheer.datamodel.updated');

            ToastService.displayToast(message, 3000);
            $mdDialog.hide(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;

            if (vm.isEditMode) {
                DataModel.update(
                    {
                        displayName: vm.displayName,
                        properties: vm.propertyList,
                        id: data.id,
                    },
                    onSaveSuccess,
                    onSaveError
                );

                return;
            }

            DataModel.save(
                {
                    displayName: vm.displayName,
                    properties: vm.propertyList,
                },
                onSaveSuccess,
                onSaveError
            );
        };

        vm.remove = function (index) {
            vm.propertyList.splice(index, 1);
        };

        vm.add = function () {
            vm.propertyList.push({ name: "", dataType: "NUMBER" });
        };

        vm.clear = function () {
            $mdDialog.hide();
        };
    }
})();
