(function () {
    'use strict';

    angular
        .module('atheer.home')
        .controller('HomeController', HomeController);

    /* @ngInject */
    function HomeController(triSettings, Home, Principal, $filter, Session, $state, triTheming, User, $mdDialog, $rootScope, PermissionService, Feature, deviceDetector, Experience, SessionsService, Auth, AuthServerProvider, $sce, $window, $scope, $location, ToastService) {
        var vm = this;

        vm.isLoading = false;
        vm.isIFrameDataLoaded = true;
        vm.userInfo = {};
        vm.homeData = {};
        vm.welcomeMsg = "";
        vm.formattedDate = "";
        vm.upcomingSessions = [];
        vm.jobs = [];
        vm.isSessionsLoading = false;
        vm.isJobsLoading = false;
        vm.primaryColor = triSettings.getSkinModel().primary_color_hex;
        vm.secondaryColor = triSettings.getSkinModel().secondary_color_hex;
        vm.backgroundColor = triTheming.rgba(triTheming.getPaletteColor(triSettings.getSkinModel().primary_color, '50').value);
        vm.isMobileBrowser = deviceDetector.isMobile();
        vm.isConfigurableHomeEnabled = Feature.isActive('feature_system_enable_configurable_home_page');
        vm.isPublicUser = false;
        vm.triSettings = triSettings;
        vm.deviceUserPermissions = {};
        vm.pageName = 'Home';


        vm.sessionQuery = {
            page: 0,
            size: 3
        };

        vm.jobquery = {
            page: 0,
            size: 3
        };

        function loadData() {
            vm.isLoading = true;
            vm.isPublicUser = Auth.isPublicUser();
            if (vm.isPublicUser) {
                if (!sessionStorage.getItem('Authorization')) {
                    AuthServerProvider.getPublicUserToken().then(function () {
                        Principal.identity(true).then(function () {
                            getHomePageData();
                        })

                    })
                } else {
                    Principal.identity(true).then(function () {
                        getHomePageData();
                    })
                }
            } else {
                getHomePageData();
            }

        };

        function getHomePageData() {
            Home.query({
                query: "web==true"
            }, function (res) {
                handleHomePageData(res);
            }, function (err) {
            })
        }

        function handleHomePageData(res) {
            if ($rootScope.showAlertMessageFor) {
                var content = $rootScope.showAlertMessageFor == 'session' ? 'home.noAccessForSessions' :
                    ($rootScope.showAlertMessageFor == 'jobs' ? 'home.noAccessForJobs' : 'home.noAccessForConversation');
                $rootScope.showAlertMessageFor = '';
                $mdDialog.show({
                    templateUrl: 'modules/home/no-access-dialog.tmpl.html',
                    clickOutsideToClose: true,
                    controller: 'NoAccessController',
                    controllerAs: 'vm',
                    locals: {
                        content: content,
                        title: 'home.permissionError'
                    }
                });
            }
            vm.homeData = res;
            setPermissionsAndFeatures();
            var date = new Date();
            vm.userInfo = Principal.getUserInfo();
            vm.welcomeMsg = $filter('translate')('home.hello') + vm.userInfo.first_name;
            vm.formattedDate = getFormattedDate(date);
            document.documentElement.style.setProperty('--primary-color', triTheming.getPaletteColor(triSettings.getSkinModel().primary_color, '500').value);
            document.documentElement.style.setProperty('--secondary-color', triTheming.getPaletteColor(triSettings.getSkinModel().secondary_color, '500').value);

            vm.homeData.home_screen_tiles = getSortedHomeScreenTiles(res);
            vm.homeData.carouselData = getCarouselData(res);

            if (vm.isPublicUser) {

            } else {
                vm.upcomingSessions = res.home_screen_tiles[0].tile_data_elements[0].sessions;
                vm.jobs = res.home_screen_tiles[1].tile_data_elements[0].jobs;

                vm.sessionQuery.page = res.home_screen_tiles[0].tile_data_elements[0].page_number;
                vm.sessionQuery.size = res.home_screen_tiles[0].tile_data_elements[0].page_size;

                vm.jobquery.page = res.home_screen_tiles[1].tile_data_elements[0].page_number;
                vm.jobquery.size = res.home_screen_tiles[1].tile_data_elements[0].page_size;
            }



            getDay();
            vm.isLoading = false;
        }

        function getCarouselData(data) {
            var carouselData = [];
            if (data.announcements_count > 0 && Feature.isActive('feature_content_announcement')) {
                angular.forEach(data.announcements, function (announcement) {
                    announcement.dismiss = function () {
                        Experience.markViewed({
                            object: {
                                id: announcement.id
                            },
                            user_id: vm.userInfo.id
                        }, function (res) {
                            var updatedCarouselData = vm.homeData.carouselData.filter(function (value) {
                                return value.id != res.object.id;
                            });
                            vm.homeData.carouselData = updatedCarouselData;
                        }, function (err) {
                        });
                    };
                    announcement.getImageSrc = function (data) {
                        return data.show_media ? '/media/images/' + data.media_id : 'assets/images/card/announcement.svg';
                    };
                    announcement.getTitleHeight = function (data) {
                        const title = document.getElementById('fp-announcement-title-' + data.id);
                        return title && title.clientHeight;
                    };
                    announcement.isAnnouncement = true;
                    carouselData.push(announcement);
                });
            }
            if (Feature.isActive('feature_content_did_you_know') && data.did_you_know) {
                data.did_you_know.data.content = '<p>' + data.did_you_know.data.content + '</p>';
                data.did_you_know.isAnnouncement = false;
                data.did_you_know.getImageSrc = function (data) {
                    return data.show_media ? '/media/images/' + data.media_id : 'assets/images/card/announcement.svg';
                };
                data.did_you_know.getTitleHeight = function (data) {
                    const title = document.getElementById('fp-announcement-title-' + data.id);
                    return title && title.clientHeight;
                };
                carouselData.push(data.did_you_know);
            }
            return carouselData;
        };

        function getSortedHomeScreenTiles(data) {
            var sortedHomeScreenTiles = [];
            angular.forEach(data.home_screen_tiles, function (tile) {
                if (tile.name == 'air_sessions') {
                    sortedHomeScreenTiles[0] = tile;
                } else if (tile.name == 'conversations') {
                    sortedHomeScreenTiles[2] = tile;
                } else if (tile.name == 'jobs') {
                    sortedHomeScreenTiles[1] = tile;
                } else if (tile.name == 'access') {
                    sortedHomeScreenTiles[0] = tile;
                }
            });

            return sortedHomeScreenTiles;
        }

        function getDay() {
            angular.forEach(vm.upcomingSessions, function (session) {
                session.formattedDay = getFormattedDay(session.start_time)
            });
        }

        function getFormattedDate(date) {
            var dt = date.getDate();
            var months = [$filter('translate')('home.months.january'), $filter('translate')('home.months.february'),
            $filter('translate')('home.months.march'), $filter('translate')('home.months.april'),
            $filter('translate')('home.months.may'), $filter('translate')('home.months.june'),
            $filter('translate')('home.months.july'), $filter('translate')('home.months.august'),
            $filter('translate')('home.months.september'), $filter('translate')('home.months.october'),
            $filter('translate')('home.months.november'), $filter('translate')('home.months.december')];
            if (dt == 1 || dt == 21 || dt == 31) {
                return dt + 'st ' + months[date.getMonth()];
            } else if (dt == 2 || dt == 22) {
                return dt + 'nd ' + months[date.getMonth()];
            } else if (dt == 3 || dt == 23) {
                return dt + 'rd ' + months[date.getMonth()];
            } else {
                return dt + 'th ' + months[date.getMonth()];
            }
        };

        function getFormattedDay(date) {
            var date1 = new Date();
            var date2 = new Date($filter('date')(date))
            var days = [$filter('translate')('home.days.sunday'), $filter('translate')('home.days.monday'), $filter('translate')('home.days.tuesday'), $filter('translate')('home.days.wednesday'), $filter('translate')('home.days.thursday'), $filter('translate')('home.days.friday'), $filter('translate')('home.days.saturday')];
            if (date2.getDate() == date1.getDate()) {
                return $filter('translate')('home.day');
            } else {
                return days[date2.getDay()];
            }
        }

        function getUpcomingSessions() {
            vm.isSessionsLoading = true;
            Session.query({
                page: vm.sessionQuery.page,
                size: vm.sessionQuery.size,
                scheduled: true
            }, function (res) {
                vm.upcomingSessions = res;
                getDay();
                vm.isSessionsLoading = false;
            });
        };

        function getJobs() {
            vm.isJobsLoading = true;
            User.getJobs({
                id: vm.userInfo.id,
                page: vm.jobquery.page,
                size: vm.jobquery.size
            }, function (res) {
                vm.jobs = res;
                vm.isJobsLoading = false;
            })
        };

        function generateDeviceAccessPermissions() {
            var device = deviceDetector.isDesktop() ? 'Desktop' : 'Handheld';
            var user = Auth.isPublicUser() ? 'Guest' : 'SignedIn';
            var permissions = Feature.getCurrentDeviceAccessPermissions(device, user);
            angular.forEach(permissions, function (permission) {
                vm.deviceUserPermissions[permission.object + '_' + permission.action] = {
                    effect: permission.effect,
                    recommendation: permission.recommendation
                };
            })
        }

        function setPermissionsAndFeatures() {
            generateDeviceAccessPermissions();
            angular.forEach(vm.homeData.home_screen_tiles, function (homeTile) {
                if (homeTile.name == 'air_sessions') {
                    homeTile.permission = 'access-session';
                    homeTile.features = ['feature_airsession_enable', 'feature_studio-settings_home_airsessions'];
                    angular.forEach(homeTile.quick_actions, function (action) {
                        if (action.name == 'start_session') {
                            action.permission = 'create-session';
                            action.devicePermission = vm.deviceUserPermissions['AiRSession_Create'];
                        }
                        if (action.name == 'schedule_session') {
                            action.permission = 'create-session';
                            action.feature = 'feature_airsession_schedule_session';
                            action.devicePermission = vm.deviceUserPermissions['AiRSession_Schedule'];
                        }

                        if (action.name == 'join_session') {
                            action.devicePermission = vm.deviceUserPermissions['AiRSession_Attend'];
                        }
                    });
                } else if (homeTile.name == 'conversations') {
                    homeTile.permission = 'access-conversation';
                    homeTile.features = ['feature_conversation_enable', 'feature_studio-settings_home_conversations'];
                    angular.forEach(homeTile.quick_actions, function (action) {
                        action.devicePermission = vm.deviceUserPermissions['Conversation_Create'];
                        if (action.name == 'conv_one_one') {
                            action.permission = 'create-1-1-conversation';
                        } else if (action.name == 'conv_group' || action.name == 'conv_join') {
                            action.permission = 'create-group-conversation';
                            action.feature = 'feature_conversation_group_conversation';
                        }
                    });
                } else if (homeTile.name == 'jobs') {
                    homeTile.permission = 'access-job';
                    homeTile.features = ['feature_airform_enable', 'feature_studio-settings_home_airforms'];
                    angular.forEach(homeTile.quick_actions, function (action) {
                        if (action.name === 'job_start') {
                            action.devicePermission = vm.deviceUserPermissions['Job_Create'];
                        } else {
                            action.devicePermission = vm.deviceUserPermissions['JobBoard_View'];
                        }
                    });
                };
            });
        };

        function displayToast(textContent, delay) {
            ToastService.displayToast(textContent, delay);
        };

        function redirect(state, name) {
            $state.go(state);
            $rootScope.selectedLeftNavMenu = name;
        };

        function handleNgxAction(event) {
            var hostUrl =
                location.hostname === "localhost"
                    ? "https://" + location.hostname + ":4200"
                    : location.origin;
            if (event.origin !== hostUrl) return;
            if (event.data.doAction) {
                var action = event.data.doAction.action;
                var props = event.data.doAction.properties
                vm.onIconClick(action, props)
            } else if (event.data.ngxLocationChanged) {
                var ngState = event.data.ngxLocationChanged.ngState;
                var id = event.data.ngxLocationChanged.id;
                var queryParams = event.data.ngxLocationChanged.queryParams;
                id = id ? id : queryParams;
                vm.isIFrameDataLoaded = true;
                scopeApply();

                if (id == null && queryParams == null) {
                    $state.transitionTo(ngState, null, {
                        location: true,
                        inherit: true,
                        relative: $state.$current,
                        notify: false
                    });
                } else {
                    $state.transitionTo(
                        ngState,
                        {
                            id: id
                        },
                        {
                            location: true,
                            inherit: true,
                            relative: $state.$current,
                            notify: false
                        }
                    );
                }
            }
        }

        var scopeApply = function (fn) {
            var phase = $scope.$root.$$phase;
            if (phase !== "$apply" && phase !== "$digest") {
                return $scope.$apply(fn);
            }
        };

        vm.joinSession = function (session) {
            var permission = vm.deviceUserPermissions['AiRSession_Attend'];
            if (permission && permission.effect === 'Deny') {
                displayToast($filter('translate')('atheer.devicePermission.' + permission.recommendation), 3000);
                return;
            }
            $state.go('storm.sessions.player', {
                'session_no': session.session_no
            });
        };

        vm.getCardBackgroundColor = function (index) {
            return index !== 1 ? vm.primaryColor : vm.secondaryColor;
        };

        vm.getLogo = function (url) {
            if (url == 'job_action1' || url == 'job_action2') {
                return '/assets/images/card/job_action.svg';
            } else if (url == 'join_session') {
                return '/assets/images/card/join_session.svg';
            } else if (url == 'schedule_session') {
                return '/assets/images/card/schedule_session.svg';
            } else if (url == 'conv_one_one') {
                return '/assets/images/card/conv_one_one.svg';
            } else if (url == 'conv_join') {
                return '/assets/images/card/conv_join.svg';
            } else if (url == 'conv_group') {
                return '/assets/images/card/conv_group.svg';
            } else if (url == 'job_start') {
                return '/assets/images/card/job_start.svg';
            } else if (url == 'start_session') {
                return '/assets/images/card/start_session.svg';
            } else if (url == 'join_guest') {
                return '/assets/images/card/join_guest.svg';
            } else if (url === 'topic_search') {
                return '/assets/images/card/topic_search.svg';
            }

            return '/assets/images/card/' + url + '.svg'
        };

        vm.onIconClick = function (name, properties, permission, description) {
            if (permission && permission.effect === 'Deny') {
                displayToast($filter('translate')('atheer.devicePermission.' + permission.recommendation), 3000);
                return;
            }
            if (name == 'start_session') {
                sessionStorage.setItem('context', 'home');
                SessionsService.startInstantSession();
            } else if (name == 'join_session') {
                sessionStorage.setItem('context', 'home');
                SessionsService.joinSession();
            } else if (name == 'schedule_session') {
                redirect('storm.sessions.upcoming-detail', 'airsessions');
            } else if (name == 'conv_one_one') {
                $rootScope.isPersonalConv = true;
                redirect('storm.conversations', 'conversations');
            } else if (name == 'conv_group') {
                $rootScope.isGroupConv = true;
                redirect('storm.conversations', 'conversations');
            } else if (name == 'conv_join') {
                $rootScope.isJoinConv = true;
                redirect('storm.conversations', 'conversations');
            } else if (name == 'job_start') {
                sessionStorage.setItem('appletDataId', properties.appletId);
                redirect('storm.new-job', 'jobs');
            } else if (name == 'job_action1' || name == 'job_action2') {
                sessionStorage.setItem('jobBoardId', properties.job_board_id);
                redirect('storm.jobs', 'jobs');
            } else if (name == 'join_guest') {
                sessionStorage.setItem('context', 'home');
                sessionStorage.removeItem('Authorization');
                Principal.logout(null);
                redirect('account.guest-join', 'airsessions');
            } else if (name == 'topic_search') {
                var tile = {
                    name: name,
                    properties: properties,
                    title: description
                }
                sessionStorage.setItem('selectedTile', JSON.stringify(tile));
                redirect('public.topics', 'home')
            }
        };

        vm.getDisable = function (action) {
            if (action && action.feature && !vm.isFeatureActive(action.feature)) {
                return true;
            } else if (action && action.permission && !PermissionService.hasAccess(action.permission)) {
                return true;
            }

            return false;
        };

        vm.isFeatureActive = function (feature) {
            return Feature.isActive(feature);
        };

        vm.isFeaturesActive = function (features) {
            return Feature.isAllActive(features);
        };

        vm.gotoPage = function (name) {
            if (name == 'air_sessions') {
                var permission = vm.deviceUserPermissions['AiRSession_View'];
                if (permission && permission.effect === 'Deny') {
                    displayToast($filter('translate')('atheer.devicePermission.' + permission.recommendation), 3000);
                    return;
                }
                redirect('storm.sessions.upcoming', 'airsessions')
            } else if (name == 'jobs') {
                var permission = vm.deviceUserPermissions['JobBoard_View'];
                if (permission && permission.effect === 'Deny') {
                    displayToast($filter('translate')('atheer.devicePermission.' + permission.recommendation), 3000);
                    return;
                }
                redirect('storm.jobs', 'jobs');
            }
        };

        vm.executeJob = function (applet) {
            var permission = vm.deviceUserPermissions['Job_Create'];
            if (permission && permission.effect === 'Deny') {
                displayToast($filter('translate')('atheer.devicePermission.' + permission.recommendation), 3000);
                return;
            }
            sessionStorage.setItem('appletDataId', applet.id);
            redirect('storm.new-job', 'jobs');
        };

        vm.refresh = function () {
            getUpcomingSessions();
            getJobs();
        };

        if (!vm.isConfigurableHomeEnabled) {
            loadData();
        } else {
            var hostUrl =
                location.hostname === "localhost"
                    ? "https://" + location.hostname + ":4200"
                    : location.origin;
            var ngxUrl = hostUrl;
            var path = $location.path();
            vm.isIFrameDataLoaded = false;

            if ($state.current.name == "storm.home" || $state.current.name === 'public.home') {
                ngxUrl = hostUrl + "/ngx/home";
            } else if ($state.current.name == "storm.unified-search" || $state.current.name === 'public.unified-search') {
                var id = path.split("/").pop();
                ngxUrl = hostUrl + "/ngx/home/search?" + id;
            }

            vm.ngxUrlTrusted = $sce.trustAsResourceUrl(ngxUrl);
            $rootScope.selectedLeftNavMenu = 'home';


            $window.addEventListener("message", handleNgxAction, false);

            $scope.$on("$destroy", function () {
                window.removeEventListener("message", handleNgxAction, false);
            });
        }

    }
})();
