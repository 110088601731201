(function() {
    'use strict';

    angular
        .module('atheer.system')
        .controller('LanguageDeleteController',LanguageDeleteController);

    LanguageDeleteController.$inject = ['$mdDialog', 'entity', 'Language'];

    function LanguageDeleteController($mdDialog, entity, Language) {
        var vm = this;
        vm.language = entity;
        vm.clear = function() {
            $mdDialog.cancel();
        };
        vm.confirmDelete = function (id) {
            Language.delete({id: id},
                function () {
                    $mdDialog.hide();
                });
        };
    }
})();
