(function() {
    'use strict';

    angular
        .module('atheer.insights')
        .controller('InsightsAnalyzeController', InsightsAnalyzeController);

    /* @ngInject */
    function InsightsAnalyzeController($scope, $q, $translate, $filter, $sce, Report, CONTENT_META, triSettings, Workspace, workspaceId, Insight) {
        var vm = this;
        vm.primaryColor = triSettings.getSkinModel().primary_color_hex;
        vm.secondaryColor = triSettings.getSkinModel().secondary_color_hex;

        vm.ngxUrlTrusted = null;
        vm.insightsSettings = null;
        var apiKey = null;

        function setNgxPageUrl() {
            $q.all([
                Workspace.getInsightSettings({
                    id: workspaceId
                }).$promise
            ]).then(function(results) {
                vm.insightsSettings = results[0];
                //console.log('Insight settings: ' + JSON.stringify(vm.insightsSettings));
                var ngxUrl = null;
                var regexSubdomainPattern = /\.atheer\.my$/ig;
                var hostUrl = vm.insightsSettings.hostUri;
                apiKey = vm.insightsSettings.manageApiKey;

                ngxUrl = hostUrl + '/analyze/embedded/#/' + vm.insightsSettings.workspaceId + '/reportId/edit?apiTokenAuthentication=true';
                vm.ngxUrlTrusted = $sce.trustAsResourceUrl(ngxUrl);
            }).catch(function(data) {}).finally(function() {});
        }

        function handleApiTokenRequest(event) {
            //console.log("Post message received", JSON.stringify(event));
            if (event.data.gdc && event.data.gdc.event.name === "listeningForApiToken") {
                const postMessageStructure = {
                    gdc: {
                        product: "analyticalDesigner",
                        event: {
                            name: "setApiToken",
                            data: {
                                token: apiKey
                            }
                        }
                    }
                };
                //console.log("Sending token to embedded window");
                const origin = "*";
                const iframe = document.getElementById("insight-analyze-frame").contentWindow;
                iframe.postMessage(postMessageStructure, origin);
            }

            if(event.data.gdc && event.data.gdc.product === 'analyticalDesigner' && event.data.gdc.event.name === 'visualizationSaved') {
                Insight.syncAllInsights();
            }
        }

        setNgxPageUrl();

        window.addEventListener("message", handleApiTokenRequest,false);

        $scope.$on('$destroy', function() {
            window.removeEventListener('message', handleApiTokenRequest, false);
        });
    }
})();
