(function() {
    'use strict';
    angular
        .module('atheer.services')
        .factory('Dashboard', Dashboard);

    /* @ngInject */
    function Dashboard($resource) {
        var resourceUrl = 'api/dashboards/:id';

        return $resource(resourceUrl, {
            id: '@id'
        }, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET'
            },
            'save': {
                method: 'POST'
            },
            'update': {
                method: 'PUT'
            },
            'insights': {
                url: 'api/insights',
                method: 'GET',
                isArray: true
            },
            'getDashboardInsights': {
                url: resourceUrl + '/insights',
                method: 'GET',
                isArray: true
            },
            'syncAllInsights': {
                url: 'api/sync-insights',
                method: 'GET'
            }
        });
    }
})();
