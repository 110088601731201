(function() {
    'use strict';

    angular
        .module('atheer.system')
        .controller('UserSignupSettingController', UserSignupSettingController);

    /* @ngInject */
    function UserSignupSettingController($mdDialog, $scope, $state, $q, Setting, $filter, ToastService, SecurityRole, MetaData) {
        var vm = this;

        vm.loadingData = false;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.userSignupSetting = [];
        vm.userSignupModel = {
            signup_policy: 'invitation_only',
            allowed_email_addresses: "",
            restricted_email_addresses: "",
            security_role: "",
            profile_attributes: "first_name, last_name"
        };
        vm.selectedUserFields = [];
        vm.selectedSecurityRole = null;
        vm.defaultSelectedUserFields = [
            {
                "name": "first_name",
                "label": "First Name",
                "description": "First Name",
                "order": 1,
                "data_type": "STRING",
                "type": "REGULAR",
                "enabled": true,
                "search_enabled": true,
                "required": true,
                "locked": true
            },
            {
                "name": "last_name",
                "label": "Last Name",
                "description": "Last Name",
                "order": 2,
                "data_type": "STRING",
                "type": "REGULAR",
                "enabled": true,
                "search_enabled": true,
                "required": false,
                "locked": true
            },
            {
                "name": "phone_number",
                "label": "Phone number",
                "description": "Phone number",
                "order": 3,
                "data_type": "STRING",
                "type": "REGULAR",
                "enabled": true,
                "search_enabled": true,
                "required": false,
                "locked": true
            } ];
        vm.loadData = function() {
            vm.loadingData = true;

            $q.all([
                    Setting.getUserSignupSettings().$promise,
                    Setting.getUserSignupMetadata().$promise
                ])
                .then(function(results) {
                    setUserSignupModel(results[0]);
                
                    setProfileModel(results[1]);

                    SecurityRole.get({
                        id: vm.userSignupModel.security_role
                    }, function(result) {
                            vm.selectedSecurityRole = result;
                    });
                    vm.loadingData = false;
                });
        };

        function setUserSignupModel(settings) {
            angular.forEach(settings, function(setting) {
                if (setting.category == 'user_signup') {
                    vm.userSignupSetting = setting;

                    angular.forEach(setting.fields, function(settingField) {
                        if (settingField.name == 'policy') {
                            vm.userSignupModel.signup_policy = settingField.value;
                        }
                        if (settingField.name == 'allow_email_domains') {
                            vm.userSignupModel.allowed_email_addresses = settingField.value;
                        }
                        if (settingField.name == 'exclude_email_domains') {
                            vm.userSignupModel.restricted_email_addresses = settingField.value;
                        }
                        if (settingField.name == 'profile_attributes') {
                            vm.userSignupModel.profile_attributes = settingField.value;
                        }
                        if (settingField.name == 'security_role') {
                            vm.userSignupModel.security_role = settingField.value;
                        }
                    });
                }
            });
        }

        function setProfileModel(selectedUserFields) {
            if (selectedUserFields.field_groups && selectedUserFields.field_groups.length > 0) {
                vm.selectedUserFields = selectedUserFields.field_groups[0].fields; 
                vm.selectedUserFields = vm.selectedUserFields.filter(function (i) { 
                    return i.name !== 'email'; 
                }); 
            }
            else { 
                vm.selectedUserFields = vm.defaultSelectedUserFields;
            }
        }

        function setUserSignupSettings() {
    
            vm.userSignupModel.profile_attributes =  Array.prototype.map.call(vm.selectedUserFields, function(item) { return item.name; }).join(","); 

            setUserSignupSettingField("policy", vm.userSignupModel.signup_policy);
            setUserSignupSettingField("allow_email_domains", vm.userSignupModel.allowed_email_addresses);
            setUserSignupSettingField("exclude_email_domains", vm.userSignupModel.restricted_email_addresses);
            setUserSignupSettingField("profile_attributes", vm.userSignupModel.profile_attributes);
            setUserSignupSettingField("security_role", vm.selectedSecurityRole ? vm.selectedSecurityRole.id : '');
        }

        function setUserSignupSettingField(fieldName, fieldValue) {
            var foundField = false;
            angular.forEach(vm.userSignupSetting.fields, function(settingField) {
                if (settingField.name == fieldName) {
                    if ( fieldName === "security_role" && fieldValue === "" ) { fieldValue = settingField.default_value; }
                    settingField.value = fieldValue;
                    foundField = true;
                }
            });

            if (!foundField) {
                var settingField = {
                    name: fieldName,
                    value: fieldValue,
                }
                vm.userSignupSetting.fields.push(settingField);
            }
        }

        vm.deleteFieldGroup = function(userField, index) {
            vm.selectedUserFields.splice(index,1);
        }

        vm.openAssignModal = function() {

            $mdDialog.show({
                templateUrl: 'modules/system/user-signup/user-field-picker.tmpl.html',
                clickOutsideToClose: true,
                controller: "UserFieldPickerController",
                controllerAs: "vm",
                size: 'lg',
                locals: {
                    selectedUserFields: vm.selectedUserFields
                }
            }).then(function(selectedItems) {
              vm.addSelectedFields(selectedItems);
            });
        }

        vm.addSelectedFields = function(selectedItems) {
            angular.forEach(selectedItems, function(userField) {
                    vm.selectedUserFields.push(userField);
            });
        }

        vm.save = function() {

            if (vm.userSignupModel.signup_policy == 'specificEmailAddress' && 
                (vm.userSignupModel.allowed_email_addresses == null || vm.userSignupModel.allowed_email_addresses.length <= 0) && 
                (vm.userSignupModel.restricted_email_addresses == null || vm.userSignupModel.restricted_email_addresses.length <= 0)
                ) {
                ToastService.displayToast($filter('triTranslate')("atheer.userSignup.emailDomain"), 3000);
                return;
            } 

            if (vm.userSignupModel.signup_policy == 'specificEmailAddress' && 
                ( vm.userSignupModel.allowed_email_addresses != null && vm.userSignupModel.allowed_email_addresses.length > 0) && 
                ( vm.userSignupModel.restricted_email_addresses != null && vm.userSignupModel.restricted_email_addresses.length > 0)
            ) {
            ToastService.displayToast($filter('triTranslate')("atheer.userSignup.specifyEitherDomain"), 3000);
            return;
        } 

            setUserSignupSettings();

            Setting.update(vm.userSignupSetting, function() {
                ToastService.displayToast($filter('triTranslate')("atheer.setting.success"), 3000);
            });

            vm.loadData();
        }
        
        vm.loadData();
    }
})();
