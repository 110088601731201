(function() {
    'use strict';

    angular
        .module('atheer.session')
        .controller('SessionCopyInvitationDialogController', SessionCopyInvitationDialogController);

    /* @ngInject */
    function SessionCopyInvitationDialogController($mdDialog, $q, ToastService, $filter, $location, Principal, Setting, session, SessionPlayerService) {
        var vm = this;
        vm.session = session;
        vm.loadData = false;
        vm.userFullName = Principal.getUserFullName();
        vm.timezoneName = null;
        vm.sessionJoinUrl = null;
        vm.invitationText = null;

        vm.close = function() {
            $mdDialog.cancel();
        };

        vm.onCopySuccess = function() {
            ToastService.displayToast($filter('translate')('atheer.session.info.copied'), 2000);
        };

        function loadData() {
            vm.loadingData = true;

            if ($location.host().endsWith('atheer.my')) {
                vm.sessionJoinUrl = 'https://' + $location.host() + ':' + $location.port() + '/sessions/player/' + vm.session.session_no;
            } else {
                vm.sessionJoinUrl = 'https://' + $location.host() + '/sessions/player/' + vm.session.session_no;
            };

            $q.all([
                    Setting.getSystemTimezones({
                        size: 100
                    }).$promise,
                ])
                .then(function(results) {
                    vm.timezones = results[0];

                    for (var i = 0, len = vm.timezones.length; i < len; i++) {
                        if (vm.timezones[i].timezone_no == vm.session.timezone) {
                            vm.timezoneName = vm.timezones[i].display_name;
                            break;
                        }
                    }

                    vm.invitationText = $filter('translate')('atheer.session.topic') + ": " + vm.session.topic + "\n";
                    vm.invitationText = vm.invitationText + $filter('translate')('atheer.session.copyStartTime') + ": " + moment(vm.session.start_time).format('MMM D, YYYY hh:mm A') + " - " + vm.timezoneName + "\n\n";
                    vm.invitationText = vm.invitationText + $filter('translate')('atheer.session.upcomingSessions.joinAirSession') + "\n";
                    vm.invitationText = vm.invitationText + vm.sessionJoinUrl + "\n\n";
                    vm.invitationText = vm.invitationText + $filter('translate')('atheer.session.sessionNo') + ": " + vm.session.session_no + "\n";

                    if(vm.session.use_passcode) {
                        vm.invitationText = vm.invitationText + $filter('translate')('atheer.session.detail.passcode') + ": " + vm.session.passcode + "\n";
                    }

                    vm.loadingData = false;
                });
        };

        loadData();
    }
})();
