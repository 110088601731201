(function() {
    'use strict';

    angular
        .module('atheer.team')
        .controller('TeamAssignController', TeamAssignController);

    /* @ngInject */
    function TeamAssignController($scope, $state, $mdDialog, $q, $filter, $timeout, team, userId, teamsFilterList, Experience, User, Team, ToastService) {
        var vm = this;

        vm.asyncUsers = [];
        vm.asyncTeams = [];
        vm.fromProfilePage = userId != undefined;
        vm.team = team;
        vm.searchUser = searchUser;
        vm.teamsFilterList = teamsFilterList;

        vm.searchTeam = searchTeam;

        vm.transformChip = function(chip) {
            if (angular.isObject(chip)) {
                return chip;
            }
        }

        function getTeamQueryString(criteria) {
            var query;
            if ( vm.teamsFilterList ) {
                query = "audienceNo!=" + vm.teamsFilterList;
            }
            if (criteria.length > 0) {
                query += ';name=re=' + criteria;
            }
            return query;
        }

        function searchTeam(criteria) {
            var d = $q.defer();
            var result = Team.query({
                query: getTeamQueryString(criteria)
            }, function(result) {
                d.resolve(result);
            });
            return d.promise;
        }

        function getQueryString(criteria) {
            if (criteria.length <= 0) {
                return null;
            } else {
                return 'text_search=ts=' + criteria;
            }
        }

        function searchUser(criteria) {
            var d = $q.defer();
            var result = User.query({
                query: getQueryString(criteria)
            }, function(result) {
                d.resolve(result);
            });
            return d.promise;
        }

        vm.close = function() {
            $mdDialog.cancel();
        }

        vm.submit = function() {
            if (!vm.fromProfilePage) {
                angular.forEach(vm.asyncUsers, function(user) {
                    Experience.assignTeam({
                        user_id: user.id,
                        object: vm.team,
                        source: 'ADMIN',
                        assigned_on: new Date()
                    }, function(result) {
                        ToastService.displayToast($filter('triTranslate')("assignTeam.membersAdded"), 3000);
                        $mdDialog.hide();
                    });
                });
            } else {
                angular.forEach(vm.asyncTeams, function(team) {
                    Experience.assignTeam({
                        user_id: userId,
                        object: team,
                        assigned_on: new Date()
                    }, function(result) {
                        ToastService.displayToast($filter('triTranslate')("assignTeam.teamAdded"), 3000);
                        $mdDialog.hide();
                    });
                });
            }
        }
    }
})();
