(function() {
    'use strict';

    angular
        .module('atheer.system')
        .controller('MediaController', MediaController);

    /* @ngInject */
    function MediaController($scope, $state, $translate, isPickerView, isMultiple, Media, ParseLinks, AlertService, pagingParams, paginationConstants, $mdDialog, $mdToast, $stateParams) {
        var vm = this;

        vm.loadAll = loadAll;
        vm.transition = transition;
        vm.reOrder = reOrder;
        vm.removeFilter = removeFilter;

        vm.createObject = createObject;
        vm.editObject = editObject;
        vm.deleteObject = deleteObject;
        vm.getQueryString = getQueryString;

        vm.getUrl = getUrl;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;

        vm.isPickerView = isPickerView;
        vm.isMultiple = isPickerView && isMultiple;
        vm.currentMedia = [];

        vm.permissions = {
            create : 'create-media',
            update : 'update-media',
            delete : 'delete-media',
            manage : 'manage-media'
        };

        vm.query = {
            filter: '',
            limit: paginationConstants.itemsPerPage,
            order: '-id',
            page: 1
        };

        vm.filter = {
            show: false,
            options: {
                debounce: 500
            }
        };

        vm.updateParentObject = function() {
            $mdDialog.hide(vm.isMultiple ? vm.currentMedia : vm.currentMedia[0]);
        }


        function loadAll() {
            Media.query({
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort(),
                query: getQueryString()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.media = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function reOrder(order) {
            vm.predicate = order;
            vm.reverse = !vm.reverse;
            vm.transition();
        }

        function transition() {
            var sortColumn = (vm.predicate.startsWith("-") ? vm.predicate.slice(1) : vm.predicate);
            vm.promise = Media.query({
                page: vm.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sortColumn + ',' + (vm.reverse ? 'asc' : 'desc'),
                query: getQueryString()
            }).$promise;

            vm.promise.then(function(data, headers) {
                vm.queryCount = vm.totalItems;
                vm.media = data;
            });
        }

        function getQueryString() {
            if (vm.query.filter.length <= 0) {
                return null;
            } else {
                return 'name=re=' + vm.query.filter;
            }
        }

        function removeFilter() {
            vm.filter.show = false;
            vm.query.filter = '';
            vm.loadAll();
        }

        function createObject() {
            $mdDialog.show({
                animation: true,
                templateUrl: 'modules/system/media/media-dialog.html',
                controller: 'MediaDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: function() {
                        return undefined;
                    }
                }
            }).then(function() {
                resetModal();
            });
        }

        function editObject(objectId) {
            $mdDialog.show({
                animation: true,
                templateUrl: 'modules/system/media/media-dialog.html',
                controller: 'MediaDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: ['Media', function(Media) {
                        return Media.get({
                            id: objectId
                        });
                    }]
                }
            }).then(function() {
                resetModal();
            });
        }

        function deleteObject(entity) {
            var confirm = $mdDialog.confirm()
                .title('Are you sure you want to delete this Media?')
                .ariaLabel('Are you sure you want to delete this Media?')
                .ok('Delete')
                .cancel('Cancel');

            $mdDialog.show(confirm).then(function() {
                Media.delete({
                    id: entity.id
                }, function() {
                    resetModal();
                });
            });
        };

        function resetModal() {
            loadAll();
            AlertService.displayToast($mdToast);
        };

        function getUrl(media) {
            if (media.type == 'IMAGE') {
                var fileExtension = media.source_file_name.substr(media.source_file_name.lastIndexOf('.') + 1);
                return media.id + '.' + fileExtension;
            } else {
                return null;
            }
        }

        vm.loadAll();
    }
})();
