(function () {
    'use strict';

    angular
        .module('atheer')
        .config(customFormlyConfig);

    /* @ngInject */
    function customFormlyConfig(formlyConfigProvider) {
        formlyConfigProvider.setType({
            name: 'objectpicker',
            templateUrl: 'core/components/formly/formly-custom-object-picker.tmpl.html'
        });

        formlyConfigProvider.setType({
            name: 'boolean-switch',
            templateUrl: 'core/components/formly/formly-custom-switch-boolean.tmpl.html'
        });

        formlyConfigProvider.setType({
            name: 'custom-datepicker',
            templateUrl: 'core/components/formly/formly-custom-datepicker.tmpl.html'
        });

        formlyConfigProvider.setType({
            name: 'custom-select',
            templateUrl: 'core/components/formly/formly-custom-select.tmpl.html'
        });

        formlyConfigProvider.setType({
            name: 'custom-chips',
            templateUrl: 'core/components/formly/formly-custom-chips.tmpl.html'
        });
    }
})();
