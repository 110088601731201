(function() {
    'use strict';
    angular
        .module('atheer.services')
        .factory('Team', Team);

    /* @ngInject */
    function Team($resource) {
        var resourceUrl = 'api/audiences/:id';

        return $resource(resourceUrl, {
            id: '@id'
        }, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET'
            },
            'save': {
                method: 'POST'
            },
            'update': {
                method: 'PUT'
            },
            'getMemberHistory': {
                url: resourceUrl + '/history',
                method: 'GET',
                isArray: true
            },
            'getMemberCountHistory': {
                url: resourceUrl + '/count/history',
                method: 'GET',
                isArray: true
            },
            'syncUsers': {
                url: 'api/audiences/:id/sync',
                method: 'POST',
            },
            'getSyncUsersStatus': {
                url: 'api/system/job-streams/:id',
                method: 'GET'
            },
        });
    }
})();
