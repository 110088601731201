(function () {
    "use strict";

    angular
        .module("atheer.ticket")
        .controller("TicketController", TicketController);

    /* @ngInject */
    function TicketController($scope, $window, $state, $sce, $rootScope) {
        var vm = this;
        vm.ngxUrlTrusted = null;
        vm.isIFrameDataLoaded = false;

        function setNgxPageUrl() {
            var ngxUrl = null;
            var hostUrl =
                location.hostname === "localhost"
                    ? "https://" + location.hostname + ":4200"
                    : location.origin;

            if ($state.current.name == "storm.tickets") {
                ngxUrl = hostUrl + "/ngx/tickets";
            }

            vm.ngxUrlTrusted = $sce.trustAsResourceUrl(ngxUrl);
            $rootScope.selectedLeftNavMenu = 'tickets';
        }

        function handleNgxEvent(event) {
            var hostUrl =
                location.hostname === "localhost"
                    ? "https://" + location.hostname + ":4200"
                    : location.origin;
            if (event.origin !== hostUrl) return;
            if (event.data.ngxLocationChanged) {
                var ngState = event.data.ngxLocationChanged.ngState;
                vm.isIFrameDataLoaded = true;
                scopeApply();

                $state.transitionTo(ngState, null, {
                    location: true,
                    inherit: true,
                    relative: $state.$current,
                    notify: false,
                });
            }
        }

        var scopeApply = function (fn) {
            var phase = $scope.$root.$$phase;
            if (phase !== "$apply" && phase !== "$digest") {
                return $scope.$apply(fn);
            }
        };

        $window.addEventListener("message", handleNgxEvent, false);

        // Unregister
        $scope.$on("$destroy", function () {
            window.removeEventListener("message", handleNgxEvent, false);
        });

        setNgxPageUrl();
    }
})();
