(function() {
    'use strict';

    angular
        .module('atheer')
        .config(pollerConfig);

      /* @ngInject */
    function pollerConfig(pollerConfig) {
        pollerConfig.stopOn = '$stateChangeStart';
        pollerConfig.resetOn = '$stateChangeStart';
    }
})();
