(function() {
    'use strict';

    angular
        .module('atheer.cloud')
        .controller('JobStreamController', JobStreamController);

    /* @ngInject */
    function JobStreamController($scope, $state, JobStream, ParseLinks, AlertService, pagingParams, paginationConstants, $mdDialog, $mdToast, $stateParams) {
        var vm = this;
        vm.loadAll = loadAll;
        vm.transition = transition;
        vm.removeFilter = removeFilter;

        vm.createObject = createObject;
        vm.editObject = editObject;
        vm.deleteObject = deleteObject;

        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;

        vm.query = {
            filter: '',
            limit: '10',
            order: '-id',
            page: 1
        };

        vm.filter = {
            show: false,
            options: {
                debounce: 500
            }
        };

        function loadAll() {
            JobStream.query({
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.jobStreams = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function transition() {
            vm.promise = JobStream.query({
                page: vm.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            }).$promise;

            vm.promise.then(function(data, headers) {
                vm.queryCount = vm.totalItems;
                vm.jobStreams = data;
            });
        }

        function removeFilter() {
            vm.filter.show = false;
            vm.query.filter = '';

            if (vm.filter.form.$dirty) {
                vm.filter.form.$setPristine();
            }
        }

        function createObject() {
            $mdDialog.show({
                animation: true,
                templateUrl: 'modules/cloud/job-stream/job-stream-dialog.html',
                controller: 'JobStreamDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: function() {
                        return {
                            scheduleNo: null,
                            name: null,
                            type: null,
                            status: null,
                            quartzStatus: false,
                            triggerInfo: null,
                            ownerId: null,
                            workspaceId: null,
                            id: null
                        };
                    }
                }
            }).then(function() {
                resetModal();
            });
        }

        function editObject(objectId) {
            $mdDialog.show({
                animation: true,
                templateUrl: 'modules/cloud/job-stream/job-stream-dialog.html',
                controller: 'JobStreamDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: ['JobStream', function(JobStream) {
                        return JobStream.get({
                            id: objectId
                        });
                    }]
                }
            }).then(function() {
                resetModal();
            });
        }

        function deleteObject(entity) {
            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.jobStream.deleteObject'))
                .ariaLabel($filter('translate')('atheer.jobStream.deleteObject'))
                .ok($filter('translate')('entity.action.delete'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function() {
                JobStream.delete({
                    id: entity.id
                }, function() {
                    resetModal();
                });
            });
        };

        function resetModal() {
            loadAll();
            AlertService.displayToast($mdToast);
        };

        vm.loadAll();
    }
})();
