(function () {
    'use strict';

    angular
        .module('atheer.smartFlow')
        .directive('smartFlowBuilderReadOnly', smartFlowBuilderReadOnlyDirective);

    /* @ngInject */
    function smartFlowBuilderReadOnlyDirective() {
        var directive = {
            restrict: 'E',
            require: '^smartFlowBuilderReadOnly',
            replace: true,
            templateUrl: 'modules/smartflow/smartflow-builder-read-only.tmpl.html',
            scope: {
                canvasData: '=',
                type: '=',
                isJobFlow: '='
            },
            controller: smartFlowBuilderReadOnlyController,
            controllerAs: 'vm',
            bindToController: true,
            link: function (scope, element, attrs) {
                scope.init(element, attrs);
            }
        };
        return directive;
    }

    /* @ngInject */
    /* @ngInject */
    function smartFlowBuilderReadOnlyController($scope, $mdToast, AlertService, $state, $mdDialog, SmartFlowBuilder) {
        var vm = this;

        $scope.init = function (element, attrs) {
            SmartFlowBuilder.initPreview(element, vm.canvasData, vm.isJobFlow, vm.type);
        };
    }
})();
