(function() {
    'use strict';

    angular
        .module('atheer.system')
        .controller('SystemMenuController', SystemMenuController);

    /* @ngInject */
    function SystemMenuController($scope, $state, Setting, AlertService, $mdDialog, $mdToast, $stateParams, Principal, Feature, PermissionService) {
        var vm = this;

        vm.loadingData = true;

        vm.loadAll = loadAll;
        vm.pageTitleKey = $state.$current.data.pageTitle;

        vm.settings = [];
        vm.activeLink = $state.current.name;

        vm.settingsConfig = [{
            state: 'storm.system-admin.dashboard',
            icon: 'zmdi zmdi-chart',
            label: 'atheer.system.dashboard',
            adminOnly: false,
        }, {
            state: 'storm.system-admin.locales',
            icon: 'fa fa-globe',
            label: 'atheer.system.locales',
            adminOnly: false,
        }, {
            state: 'storm.system-admin.languages',
            icon: 'zmdi zmdi-globe-alt',
            label: 'atheer.system.languages',
            adminOnly: false,
        }, {
            state: 'storm.system-admin.timezones',
            icon: 'fa fa-clock-o',
            label: 'atheer.system.timezones',
            adminOnly: false,
        }];


        function loadAll() {
            vm.loadingData = false;

            angular.forEach(vm.settingsConfig, function(setting) {
                var featureActive = true;
                var hasPermission = true;

                if (angular.isDefined(setting.feature)) {
                    featureActive = Feature.isActive(setting.feature);
                };

                if (angular.isDefined(setting.permission)) {
                    hasPermission = PermissionService.hasAccess(setting.permission);
                };

                if (setting.adminOnly && Principal.isWorkspaceAdmin()) {
                    vm.settings.push(setting);
                } else if (!setting.adminOnly && featureActive && hasPermission) {
                    vm.settings.push(setting);
                };
            });
        };

        vm.loadAll();
    }
})();
