(function() {
    'use strict';
    angular
        .module('atheer')
        .constant('ATHEER_FEATURES', {
            'system': {
                'notification_edit': 'feature_system_notification_edit',
                'email_set_up': 'feature_system_email_set_up',
                'adv_image_edit': 'feature_system_adv_image_edit',
                'sso': 'feature_system_sso'
            },
            'studio': {
                'help_center': 'feature_studio-settings_help_center',
            },
            'user': {
                'invitation': 'feature_user_invitation',
                'signup': 'feature_user_signup',
                'extended_fields': 'feature_user_extended_fields'
            },
            'audience': {
                'dynamic': 'feature_audience_dynamic'
            },
            'security_roles': {
                'custom_roles': 'feature_security_role_custom_roles'
            },
            'airsession': {
                'enable': 'feature_airsession_enable',
                'schedule_session': 'feature_airsession_schedule_session',
                'recurring_session': 'feature_airsession_recurring_session',
                'session_template': 'feature_airsession_session_template',
                'personal_session': 'feature_airsession_personal_session',
                'live_annotation': 'feature_airsession_live_annotation',
                'recording': 'feature_airsession_recording'
            },
            'conversation': {
                'enable': 'feature_conversation_enable',
                'group_conversation': 'feature_conversation_group_conversation',
                'recurring_session': 'feature_conversation_typing_indicator'
            },
            'content': {
                'enable': 'feature_content_enable',
                'did_you_know': 'feature_content_did_you_know',
                'announcement': 'feature_content_announcement',
                'video': 'feature_content_video',
                'podcast': 'feature_content_podcast',
                'document': 'feature_content_document',
                'image': 'feature_content_image',
                'page': 'feature_content_page',
                'link': 'feature_content_link',
                'airsession_recording': 'feature_content_airsession_recording',
                'share': 'feature_content_share',
                'bookmark': 'feature_content_bookmark',
                'flag': 'feature_content_extended_flag',
                'rating': 'feature_content_rating',
                'like': 'feature_content_like',
                'problem': 'feature_content_problem',
                'allow_upload': 'feature_content_allow_upload',
                'allow_owner': 'feature_content_allow_owner',
                'extended_fields': 'feature_content_extended_fields'
            },
            'topic': {
                'enable': 'feature_topic_enable',
                'extended_fields': 'feature_topic_extended_fields'
            },
            'badge': {
                'enable': 'feature_badge_enable'
            },
            'airform': {
                'enable': 'feature_airform_enable'
            },
            'smartscan': {
                'enable': 'feature_smartscan_enable'
            },
            'anchor': {
                'enable': 'feature_anchor_enable'
            },
            'smartflow': {
                'enable': 'feature_smartflow_enable'
            },
            'insights': {
                'dashboard': 'feature_insights_dashboard',
                'user': 'feature_insightsuser',
                'conversation': 'feature_insights_conversation',
                'airsession': 'feature_insights_airsession',
                'content': 'feature_insights_content',
                'airform': 'feature_insights_airform',
                'smartscan': 'feature_insights_smartscan',
                'anchor': 'feature_insights_anchor',
                'smartflow': 'feature_insights_mobile_smartflow'
            },
            'adv-insights': {
                'enabled': 'feature_adv-insights_enabled',
                'designer': 'feature_adv-insights_designer'
            },
            'airbridge': {
                'enable': 'feature_airbridge_enable'
            },
            'airsense': {
                'enable': 'feature_airsense_enable'
            },
            'airsdk': {
                'enable': 'feature_airsdk_enable'
            },
        });
})();
