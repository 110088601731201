(function() {
    'use strict';

    angular
        .module('atheer.system')
        .controller('SecurityRolePickerController', SecurityRolePickerController);

    /* @ngInject */
    function SecurityRolePickerController($scope, $state, $mdDialog, SecurityRole, ParseLinks, AlertService, $mdToast, $filter, filter, allowMultiSelect, paginationConstants) {
        var vm = this;

        vm.loadAll = loadAll;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.predicate = "id";
        vm.transition = transition;
        vm.reOrder = reOrder;
        vm.selected = [];
        vm.allowMultiSelect = allowMultiSelect;

        vm.currentSelectedItems = [];

        vm.query = {
            filter: '',
            limit: paginationConstants.itemsPerPage,
            order: '-id',
            page: 1
        };

        vm.filter = {
            options: {
                debounce: 500
            }
        };

        vm.removeFilter = removeFilter;
        vm.filter.show = false;
        vm.getQueryString = getQueryString;

        vm.loadAll();

        function loadAll() {
            SecurityRole.query({
                page: 0,
                size: vm.query.limit,
                sort: sort(),
                query: getQueryString()
            }, onSuccess, onError);

            function sort() {
                var result = ['id' + ',' + 'asc'];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.securityRoles = data;
                vm.page = 1;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function reOrder(order) {
            vm.predicate = order;
            vm.transition();
        }

        function transition() {
            vm.promise = SecurityRole.query({
                page: vm.page - 1,
                size: vm.query.limit,
                sort: getSortOrder(),
                query: getQueryString()
            }).$promise;

            vm.promise.then(function(data, headers) {
                vm.queryCount = vm.totalItems;
                vm.securityRoles = data;
            });
        }

        function getSortOrder() {
            if (vm.predicate.startsWith('-')) {
                return vm.predicate.substr(1) + ',' + 'desc';
            } else {
                return vm.predicate + ',' + 'asc';
            }

        }

        function getQueryString() {
            if (vm.query.filter.length <= 0) {
                return null;
            } else {
                return 'name=re="' + vm.query.filter +'"';
            }
        }

        function removeFilter() {
            vm.filter.show = false;
            vm.query.filter = '';
            resetModal();
        }

        function resetModal() {
            loadAll();
            AlertService.displayToast($mdToast);
        };

        vm.updateParentObject = function() {
            $mdDialog.hide(vm.currentSelectedItems);
        }

        vm.close = function() {
            $mdDialog.cancel();
        }
    }
})();
