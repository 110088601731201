(function() {
    'use strict';

    angular
        .module('atheer.core')
        .controller('LoaderController', LoaderController);

    /* @ngInject */
    function LoaderController(triSettings) {
        var vm = this;

        vm.triSettings = triSettings;
    }
})();
