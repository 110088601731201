(function() {
    'use strict';
    angular
        .module('atheer.services')
        .factory('Audience', Audience);

    /* @ngInject */    
    function Audience($resource) {
        var resourceUrl = 'api/audiences/:id';

        return $resource(resourceUrl, {
            id: '@id'
        }, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function(data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getMemberHistory': {
                url: resourceUrl + '/history',
                method: 'GET',
                isArray: true
            },
            'getMemberCountHistory': {
                url: resourceUrl + '/count/history',
                method: 'GET',
                isArray: true
            },
            'update': {
                method: 'PUT'
            }
        });
    }
})();
