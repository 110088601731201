(function () {
    'use strict';

    angular
        .module('atheer.core')
        .controller('LeftSidenavController', LeftSidenavController);

    /* @ngInject */
    function LeftSidenavController($rootScope, $state, $scope, $location, triSettings, triLayout, $mdUtil, $mdSidenav, Principal, Auth, Feature, $window) {
        var vm = this;

        vm.layout = triLayout.layout;
        vm.sidebarInfo = {
            appName: triSettings.name,
            appLogoBig: triSettings.getLogoBig(),
            appLogoSmall: triSettings.getLogoSmall(),
            menuState: triSettings.getSkinModel().side_menu_state,
            logoRight: triSettings.getSkinModel().logo_right
        };

        vm.logOut = logOut;
        vm.goHome = goHome;
        vm.showHelp = showHelp;
        vm.isWorkspaceAdmin = false;
        vm.menuOffset = 50;
        vm.isMasterWorkspace = $rootScope.isMasterWorkspace;
        vm.showHelpCenter = false; // Feature.isActive('feature_studio-settings_help_center');
        vm.primaryColor = triSettings.getSkinModel().primary_color_hex;
        vm.settingPermission = ['manage-setting-system'];
        ////////////
        if (!$location.path().startsWith('/deeplink/sessions')) {
            Principal.identity().then(function (user) {
                setUserInfo(user);
            });
        }

        function showHelp() {
            $window.open('https://frontlineos-atheer.zendesk.com/hc/en-us', '_blank');
        };

        function goHome() {
            var navPath = $rootScope.isMasterWorkspace ? $rootScope.baseHome : '/home';
            $rootScope.selectedLeftNavMenu = $rootScope.isMasterWorkspace ? $rootScope.baseLeftNavMenu : 'home';
            $location.path(navPath);
        };

        function setUserInfo(user) {
            vm.currentUser = {
                id: user.id,
                first_name: user.first_name,
                last_name: user.last_name,
                displayName: user.first_name + ' ' + user.last_name,
                initials: user.first_name.charAt(0) + user.last_name.charAt(0),
                userName: user.first_name,
                picture_id: user.picture_id
            };
            vm.user = user;
            if (Principal.isWorkspaceAdmin()) {
                vm.isWorkspaceAdmin = true;
            }
        };

        function logOut() {
            Auth.logout();
            $state.go('account.signout');
        };

        vm.toggleToolBarMenu = function (menu, submenu) {
            $rootScope.isQR = submenu ? true : false;
        };
    }
})();
