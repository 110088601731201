(function() {
    'use strict';

    angular
        .module('atheer.content')
        .directive('fpContentFeedList', fpContentFeedList);

    function fpContentFeedList() {
        var directive = {
            restrict: 'E',
            scope: {
                'contents': '=',
                'enableRemove': '@?',
                'removeCallback': '&?',
                'loadMoreEnabled': '@?'
            },
            transclude: true,
            controller: contentListViewDirectiveController,
            templateUrl: 'modules/content/content-feed-list.tmpl.html'
        };
        return directive;
    }

    /* @ngInject */
    function contentListViewDirectiveController($rootScope, $scope, $state, CONTENT_META, $q, Principal, Experience, $filter, ContentAction, Feature, $mdDialog, Launcher) {

        $scope.loadMoreEnabled = $scope.loadMoreEnabled == 'true';

        $scope.limitToIndex = $scope.loadMoreEnabled && $scope.contents.length > 5 ? 5 : $scope.contents.length;

        $scope.loadMore = function(type) {
            var diff = $scope.contents.length - $scope.limitToIndex;
            $scope.limitToIndex += diff > 5 ? 5 : diff;
        };

        function init() {

        };

        $scope.getCardMeta = function(card) {
            var contentMetaData = $filter('filter')(CONTENT_META, {
                type: card.data.type.replace(/_/g, '-')
            })[0];
            return contentMetaData;
        };

        $scope.launchContent = function(content) {
            if ($scope.fromSeries && !$scope.seriesEnrolled) {
                ContentAction.previewContent(content);
            } else {
                ContentAction.launchContent(content);
            };
        };

        init();
    }
})();
