(function() {
    'use strict';

    angular
        .module('atheer.system')
        .controller('AuthenticationController', AuthenticationController);

    /* @ngInject */
    function AuthenticationController($scope, $state, $q, Setting, $mdToast, $filter, $mdDialog, PermissionService, Feature) {
        var vm = this;

        vm.hasPermission = PermissionService.hasAccess('manage-sso-system');
        vm.isFeatureActive = Feature.isActive('feature_system_sso');
        vm.pageTitleKey = $state.$current.data.pageTitle;
    }
})();
