(function() {
    'use strict';
    angular
        .module('atheer.services')
        .factory('Scorm', Scorm);

    /* @ngInject */
    function Scorm($resource) {
        var resourceUrl = 'api/scorm/:id';

        return $resource(resourceUrl, {
            id: '@id'
        }, {
            'getCoursePreviewUrl': {
                url: 'api/scorm/preview/:id',
                method: 'GET'
            },
            'getCourseLaunchUrl': {
                url: 'api/scorm/launch/:id',
                method: 'GET'
            },
            'updateProgress': {
                url: 'api/scorm/update/:id',
                method: 'PUT'
            },
        });
    }
})();
