(function() {
    'use strict';

    angular
        .module('atheer.topic')
        .controller('TopicDetailRecognitionController', TopicDetailRecognitionController)

    /* @ngInject */
    function TopicDetailRecognitionController($mdDialog, $scope, $filter, $sce, $q, ParseLinks, pagingParams, paginationConstants, $state, Team, AlertService, $mdToast, $mdSidenav, TOPIC_RECOGNITION_META, Principal, Topic, Feature, User, id) {

      var vm = this;

      vm.loadingData = true;

      vm.recognitionSections = angular.copy(TOPIC_RECOGNITION_META['topic'].sections);

      vm.topicModel = null;

      vm.loadData = function() {
          if (id) {
              Topic.get({
                  id: id
              }, function(result) {
                  vm.topicModel = result;
                  vm.loadingData = false;
              });
          }
      };
      vm.loadData();
    }
})();
