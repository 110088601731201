(function() {
    'use strict';

    angular
        .module('atheer.services')
        .factory('JhiHealthService', JhiHealthService);
        
    /* @ngInject */
    function JhiHealthService ($rootScope, $http) {
        var service = {
            checkHealth: checkHealth
        };

        return service;

        function checkHealth () {
            return $http.get('health').then(function (response) {
                return response.data;
            });
        }
    }
})();
