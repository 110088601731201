(function() {
    'use strict';

    angular
        .module('atheer.anchor')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider, ANCHOR_INSIGHT_META, ANCHOR_RECOGNITION_META) {
        $stateProvider
            .state('storm.admin-anchors', {
                url: '/anchors?page&sort&search',
                views: {
                    '@storm': {
                        templateUrl: 'modules/anchor/anchor.tmpl.html',
                        controller: 'AnchorController',
                        controllerAs: 'vm',
                    },
                    'toolbar@storm': {
                        templateUrl: 'modules/anchor/anchor-toolbar.tmpl.html',
                        controller: 'AnchorToolbarController',
                        controllerAs: 'vm'
                    },
                    'sideMenu@storm': {
                        templateUrl: 'modules/anchor/anchor-menu.tmpl.html',
                        controller: 'AnchorMenuController',
                        controllerAs: 'vm',
                    }
                },
                data: {
                    pageTitle: 'atheer.anchor.home.title',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showSideNavMenu: true,
                        sideNavMenuPosition: 'left',
                        showToolbar: false,
                        sideNavMenuSize: 15
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null,
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }]
                }
            })
            .state('storm.admin-anchor-detail', {
                url: '/anchors/:id',
                templateUrl: 'modules/anchor/anchor-detail.tmpl.html',
                controller: 'AnchorDetailController as vm',
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    id: function($stateParams) {
                        return $stateParams.id;
                    }
                },
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false
                    }
                }
            })
            .state('storm.admin-anchor-detail-recognition', {
                url: '/anchors/:id/recognition',
                templateUrl: 'modules/anchor/anchor-detail-recoginition.tmpl.html',
                controller: 'AnchorDetailRecognitionController as vm',
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    id: function($stateParams) {
                        return $stateParams.id;
                    }
                }
            })
            .state('storm.admin-anchor-detail-recognition.section', {
                url: '/:section',
                templateUrl: function($stateParams) {
                    return ANCHOR_RECOGNITION_META['anchor'].sections[$stateParams.section || 'overview'].template;
                },
                controllerProvider: function($stateParams) {
                    return ANCHOR_RECOGNITION_META['anchor'].sections[$stateParams.section || 'overview'].controller;
                },
                controllerAs: 'vm',
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    id: function($stateParams) {
                        return $stateParams.id;
                    },
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }]
                }
            })
            .state('storm.admin-anchor-detail-insights', {
                url: '/anchors/:id/insights',
                templateUrl: 'modules/anchor/anchor-detail-insights.tmpl.html',
                controller: 'AnchorDetailInsightsController as vm',
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    id: function($stateParams) {
                        return $stateParams.id;
                    }
                }
            })
            .state('storm.admin-anchor-detail-insights.section', {
                url: '/:section',
                templateUrl: function($stateParams) {
                    return ANCHOR_INSIGHT_META['anchor'].sections[$stateParams.section || 'overview'].template;
                },
                controllerProvider: function($stateParams) {
                    return ANCHOR_INSIGHT_META['anchor'].sections[$stateParams.section || 'overview'].controller;
                },
                controllerAs: 'vm',
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    id: function($stateParams) {
                        return $stateParams.id;
                    },
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }]
                }
            });
    }
})();
