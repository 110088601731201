(function () {
    'use strict';

    angular
        .module('atheer.sdk')
        .controller('SdkDocumentationController', SdkDocumentationController);

    /* @ngInject */
    function SdkDocumentationController($rootScope, $location, Principal, $scope, $sce, $cookies, $q, Setting, $mdToast, $filter, $mdDialog, Feature) {
        var vm = this;
        var host = $location.host();
        var domain = Principal.getWorkspaceDomain();
        var apiUrl = "https://openapi.atheer.dev?workspace=" + domain;

        vm.viewType = "doc";
        vm.webHooksEnabled = Feature.isActive('feature_airsdk_webhooks');

        if (angular.isDefined(host) && host.endsWith(".dev")) {
            apiUrl = "https://atheer-dev.redoc.ly?workspace=" + domain;
        } else if (angular.isDefined(host) && host.endsWith(".qa")) {
            apiUrl = "https://openapi.atheer.qa?workspace=" + domain;
        } else if (angular.isDefined(host) && host.endsWith(".si")) {
            apiUrl = "https://openapi.atheer.si?workspace=" + domain;
        } else if (angular.isDefined(host) && host.endsWith(".io")) {
            apiUrl = "https://openapi.atheer.io?workspace=" + domain;
        } else {
            apiUrl = "https://atheer-dev.redoc.ly?workspace=" + domain;
        };
        vm.hostUrl = $sce.trustAsResourceUrl(apiUrl);
    }
})();
