(function () {
    'use strict';

    angular
        .module('atheer.doc')
        .controller('ProductDocumentationController', ProductDocumentationController);

    /* @ngInject */
    function ProductDocumentationController($rootScope, $scope, $sce, $cookies, $q, Setting, $mdToast, $filter, $mdDialog, Feature) {
        var vm = this;

        vm.hostUrl = $sce.trustAsResourceUrl("https://atheer.gitbook.io/product-docs/LOPpaLR4RiH0bDQ9ukeX/");
    }
})();
