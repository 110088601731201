(function() {
    'use strict';

    angular
        .module('atheer.user')
        .controller('SignoutController', SignoutController);

    /* @ngInject */
    function SignoutController($rootScope, $stateParams, Auth, LoginService, triSettings) {

        var vm = this;
        vm.buildInfoSettings = $rootScope.buildInfo;
        vm.isDesktop = window.isDesktop;
        vm.isWhiteLabelled = window.isWhiteLabelled;

        vm.changeWorkspace = function () {
            if(window && window.nodeAPI) {
                window.nodeAPI.ipc.send('back-to-workspace');
            }
        };
    }
})();
