(function() {
    'use strict';

    angular
        .module('atheer.user')
        .controller('SupportController', SupportController);

    /* @ngInject */
    function SupportController($scope, $state, $q, Explore, ParseLinks, triLoaderService, $filter, Experience, Principal, $mdToast, Feature) {
        var vm = this;

        vm.loadingData = false;

        function loadData() {
            vm.loadingData = false;
        }
        loadData();
    }
})();
