(function() {
    'use strict';

    angular
        .module('atheer.session')
        .controller('SessionAnnotationModeDialogController', SessionAnnotationModeDialogController);

    /* @ngInject */
    function SessionAnnotationModeDialogController($mdDialog,mainData,SessionPlayerService) {
        var vm = this;
        vm.mainData = mainData;
        vm.close = function() {
            $mdDialog.cancel();
        };
        vm.classicAnnotationMode = function() {
            vm.mainData.annotate('CLASSIC');
            vm.close();
        };
        vm.annotateArMode = function(){
            vm.mainData.annotate('AR');
            vm.close();
        }
        vm.shareLocalImage = function (id) {
            $mdDialog.show({
                templateUrl: 'modules/conversation/conversation-local-content-picker.tmpl.html',
                controller: 'ConversationLocalContentPicker',
                controllerAs: 'vm',
                locals: { annotationMode: true },
            })
            .then(function(content) {
                mainData.sendAnnotationLockMessageForLocalImage();
                vm.isOpenAnnotationOptions = false;
                getImageSize(content.data.source_media.properties.file_name);
            });
        }

        vm.shareContent = function() {
            $mdDialog.show({
                    templateUrl: 'modules/content/content-picker.html',
                    controller: 'ContentPickerController',
                    controllerAs: 'vm',
                    clickOutsideToClose: true,
                    locals: {
                        filter: 'embedded==false;type=out=(SERIES,EMAIL_LAYOUT);meta_type==DISCOVERABLE;status!=ARCHIVED;enabled==true;type==IMAGE',
                        allowMultiSelect: false,
                        isWeb: false,
                        isNotFilter: false
                    }
                })
                .then(function(content) {
                    mainData.sendAnnotationLockMessageForLocalImage();
                    vm.isOpenAnnotationOptions = false;
                    getImageSize(content[0].data.source_media.properties.file_name);
                });
        }

        function getImageSize(url) {
            var image = new Image();
            image.src = "/media/images/"+url;
            image.onload = function() {
                var annotationData =  {
                    height: this.height,
                    url: url,
                    width: this.width
                }
                vm.mainData.startLocalImageShareAnnotation(annotationData)
              };
          }
    }
})();
