(function() {
    'use strict';

    angular
        .module('atheer')
        .directive('fpAvatar', fpAvatar);

    /* @ngInject */
    function fpAvatar(PubNubService) {
        var directive = {
            restrict: 'AE',
            replace: true,
            scope: {
                user: '=',
                presence: '=presence',
                width: '@width',
                bgcolor: '@bgColor',
                textColor: '@textColor',
                wrapper: '=wrapper',
                pictureResolution: '@pictureResolution',
                pixelated: '=pixelated',
                roundShape: '=roundShape',
                class: '@class',
                imgClass: '@imgClass',
                style: '@style',
                cornerRadius: '@cornerRadius',
                pictureFormat: '@pictureFormat',
                colorsPalette: '=colorsPalette',
                autoColor: '=autoColor',
                fontWeight: '@fontWeight',
                fontScale: '@fontScale',
                textShadow: '=textShadow',
                bind: '=bind',
                cameCase: '@camelCase',
            },
            link: function(scope, element, attrs) {

                var _user = null,
                    _long = 45,
                    _picture_resolution = 256,
                    _wrapper = true,
                    _str = "", //scope.initials || "",
                    _picture = null,
                    _presence = false,
                    _bgcolor = "#000",
                    _textcolor = "#fff",
                    _pixelated = false,
                    _img_styling = "vertical-align:top;",
                    _roundShape = false,
                    _wrapper_styling = "border-radius:0; display:block;",
                    _extra_classes = "",
                    _extra_img_classes = "",
                    _extra_styles = "",
                    _corner_radius = "0",
                    _picture_format = "png",
                    _colors_palette = ["#bdc3c7", "#6f7b87", "#2c3e50", "#2f3193", "#662d91", "#922790", "#ec2176", "#ed1c24", "#f36622", "#f8941e", "#fab70f", "#fdde00", "#d1d219", "#8ec73f", "#00a650", "#00aa9c", "#00adef", "#0081cd", "#005bab"],
                    _autoColor = true,
                    _font_weight = 100,
                    _font_scale = 100,
                    _text_shadow = false,
                    _bind = false,
                    _camel_case = false,
                    _img_width = "100%";

                function checkValues() {

                    if (scope.bind != undefined) {
                        _bind = scope.bind;
                    }

                    if (scope.textColor != undefined) {
                        _textcolor = scope.textColor;
                    }

                    if (scope.pictureResolution != undefined) {
                        _picture_resolution = scope.pictureResolution;
                    }

                    if (scope.width != undefined) {
                        _long = scope.width;
                    }

                    if (scope.wrapper != undefined) {
                        _wrapper = scope.wrapper;
                        if (_wrapper === false) {
                            _img_width = _long;
                        }
                    }

                    if (scope.pixelated != undefined) {
                        _pixelated = scope.pixelated;
                        if (_pixelated === true) {
                            _img_styling += "image-rendering:pixelated; image-rendering:-moz-crisp-edges;";
                        }
                    }

                    if (scope.roundShape != undefined) {
                        _roundShape = scope.roundShape;
                        if (_roundShape) _wrapper_styling += "border-radius: " + _long + "px;";
                    } else {
                        if (scope.cornerRadius != undefined) {
                            _corner_radius = scope.cornerRadius;
                            _wrapper_styling += "border-radius: " + _corner_radius + "px;";
                        }
                    }

                    if (scope.class != undefined) {
                        _extra_classes = scope.class;
                    }

                    if (scope.imgClass != undefined) {
                        _extra_img_classes = scope.imgClass;
                    }

                    if (scope.style != undefined) {
                        _extra_styles = scope.style;
                    }

                    if (scope.pictureFormat === 'jpeg') {
                        _picture_format = "jpeg";
                    }

                    if (scope.colorsPalette != undefined) {
                        _colors_palette = scope.colorsPalette;
                    }

                    if (scope.bgcolor != undefined) {
                        _bgcolor = scope.bgcolor;
                    } else {
                        if (scope.autoColor != undefined) {
                            _autoColor = scope.autoColor;
                        }
                    }

                    if (scope.fontWeight != undefined) {
                        _font_weight = scope.fontWeight;
                    }

                    if (scope.fontScale != undefined) {
                        _font_scale = scope.fontScale;
                    }

                    if (scope.textShadow != undefined) {
                        _text_shadow = scope.textShadow;
                    }

                    if (scope.presence === true) {
                        _presence = true;
                    }

                    if (scope.user != undefined) {
                        _user = scope.user;
                    }


                    if (scope.cameCase != undefined) {
                        _camel_case = scope.cameCase;
                    }
                }

                function generateAvatar(name, w, h, bgcolor, textcolor, bgImage) {

                    var WIDTH = 256,
                        HEIGHT = 256,
                        canvas, ctx, _font_size;

                    if (w != undefined && w > 0) {
                        if (h != undefined && h > 0) {
                            WIDTH = w;
                            HEIGHT = h;
                        }
                    }

                    canvas = document.createElement('canvas');
                    canvas.id = "ngAvatar-" + Date.now();
                    canvas.width = WIDTH;
                    canvas.height = HEIGHT;

                    ctx = canvas.getContext('2d');
                    ctx.fillStyle = bgcolor;
                    ctx.fillRect(0, 0, WIDTH, HEIGHT);

                    _font_size = WIDTH / (2.5 / (_font_scale / 100));
                    ctx.font = _font_weight + " " + _font_size + "px sans-serif";

                    if (_text_shadow === true) {
                        ctx.shadowColor = "black";
                        ctx.shadowOffsetX = 0;
                        ctx.shadowOffsetY = 0;
                        ctx.shadowBlur = 5;
                    }

                    ctx.textAlign = "center";
                    ctx.fillStyle = textcolor;
                    ctx.fillText(_str, WIDTH / 2, HEIGHT - (HEIGHT / 2) + (_font_size / 3));

                    return canvas.toDataURL("image/" + _picture_format);
                }

                function getPicture(user) {
                    if (_camel_case) {
                        if (user.pictureId != null && _user.pictureId.length > 0) {
                            return '/media/pictures/' + _user.pictureId
                        } else {
                            return null;
                        }
                    } else {
                        if (user.picture_id != null && _user.picture_id.length > 0) {
                            return '/media/pictures/' + _user.picture_id
                        } else {
                            return null;
                        }
                    }
                }

                function getInitials(user) {
                    var str = null;

                    var firstName = '',
                        lastName = '';

                    if (_camel_case) {
                        firstName = _user.firstName ? _user.firstName.charAt(0) : _user.firstName;
                        lastName = _user.lastName ? _user.lastName.charAt(0) : _user.lastName;
                        str = firstName + ' ' + lastName
                    } else {
                        firstName = _user.first_name ? _user.first_name.charAt(0) : _user.first_name;
                        lastName = _user.last_name ? _user.last_name.charAt(0) : _user.last_name;
                        str = firstName + ' ' + lastName
                    };

                    var output = "",
                        i = 0,
                        str = str.split(" "),
                        len = str.length;

                    for (i; i < len; i++)
                        if (str[i] != "") output += str[i][0]; //.toUpperCase();
                    return output.toUpperCase();
                }

                var currentElement = element;

                function render() {
                    if (_user == null) {
                        return;
                    };

                    var imgData = null;
                    var pubNubState = null;

                    if (_presence) {
                        pubNubState = PubNubService.getUserState(_user.id);
                    }

                    var picture = getPicture(_user);
                    if (picture != null) {
                        imgData = picture;
                    } else {
                        _str = getInitials(_user);

                        if (_autoColor === true) {
                            var i, lon = _str.length,
                                charIndex = 0,
                                colorIndex;
                            charIndex = _str.charCodeAt(lon - 1) - 'A'.charCodeAt(0);
                            colorIndex = charIndex % _colors_palette.length;
                            _bgcolor = _colors_palette[colorIndex];
                        }
                        imgData = generateAvatar(_str, _picture_resolution, _picture_resolution, _bgcolor, _textcolor, null);
                    }

                    var html = '';
                    if (_wrapper) html += '<div class="storm-avatar-wrapper" style="' + _wrapper_styling + ' width:' + _long + 'px; height:' + _long + 'px; ' + _extra_styles + '">';
                    html += '<div style="overflow:hidden; border-radius:' + _long + 'px;' + ' width:' + _long + 'px; height:' + _long + 'px; ' + '">';
                    html += '<img src="' + imgData + '" class="avatar-picture ' + _extra_img_classes + '" style="' + _img_styling + '" width=100% height=100% object-fit="cover" />';
                    html += '</div>'
                    if (_presence && pubNubState != undefined && pubNubState != null) {
                        html += '<div class="presence online"></div>'
                    }
                    if (_wrapper) html += '</div>';

                    var replacementElement = angular.element(html);
                    currentElement.replaceWith(replacementElement);
                    currentElement = replacementElement;
                }

                checkValues();
                render();

                if (_bind === true) {
                    scope.$watch('user', function(value) {
                        checkValues();
                        render();
                    });
                } else {
                    render();
                }

                scope.$on('refreshUserStatus', function(event, userId) {
                    if (userId == _user.id) {
                        checkValues();
                        render();
                    }
                });
            }
        };
        return directive;
    }
})();
