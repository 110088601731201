(function() {
    'use strict';

    angular
        .module('atheer.conversation')
        .controller('ConversationGroupController', ConversationGroupController);

    /* @ngInject */
    function ConversationGroupController($scope, $state, $mdDialog, $q, filter, $mdToast, User, Experience, Team, Principal, triSettings, $filter) {
        var vm = this;

        vm.filter = filter;
        vm.asyncUsers = [];
        vm.privateConversation = true;
        vm.primaryColor = triSettings.getSkinModel().primary_color_hex;

        vm.froalaOptions = {
            placeholderText: $filter('translate')('global.enterTextHerePlaceholder')
        }

        vm.converationModel = {
            conversation_no: null,
            name: null,
            purpose: null,
            topic: null,
            status: 'OPEN',
            type: 'PRVIATE_GROUP',
            users: [],
            picture_id: null
        };

        vm.converationModel = filter == null ? vm.converationModel : angular.copy(filter);
        if (vm.converationModel.type == 'PRIVATE_GROUP') {
            vm.privateConversation = true;
        } else {
            vm.privateConversation = false;
        };


        vm.getGroupProfileImageUrl = function() {
            return vm.loadingData ? 'assets/images/avatars/group-avatar.png' : vm.converationModel.picture_id ? '/media/pictures/' + vm.converationModel.picture_id : 'assets/images/avatars/group-avatar.png';
        }

        vm.updateGroupProfileImage = function(mediaId, responseObj) {
            vm.converationModel.picture_id = mediaId;
        }

        vm.removeGroupProfileImage = function() {
            vm.converationModel.picture_id = undefined;
        }

        vm.close = function() {
            $mdDialog.cancel();
        }

        vm.submit = function() {
            if (vm.privateConversation) {
                vm.converationModel.type = 'PRIVATE_GROUP';
            } else {
                vm.converationModel.type = 'PUBLIC_GROUP';
            };

            vm.converationModel.users.push({
                user_id: Principal.getUserId(),
                role: 'OWNER'
            });

            angular.forEach(vm.asyncUsers, function(user) {
                vm.converationModel.users.push({
                    user_id: user.id,
                    role: 'MEMBER'
                });
            });

            $mdDialog.hide(vm.converationModel);
        };
    };
})();
