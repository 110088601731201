(function() {
    'use strict';

    angular
        .module('atheer')
        .directive('rotatingPlaneSpinner', rotatingPlaneSpinner)
        .directive('doubleBounceSpinner', doubleBounceSpinner)
        .directive('waveSpinner', waveSpinner)
        .directive('wanderingCubesSpinner', wanderingCubesSpinner)
        .directive('pulseSpinner', pulseSpinner)
        .directive('chasingDotsSpinner', chasingDotsSpinner)
        .directive('circleSpinner', circleSpinner)
        .directive('threeBounceSpinner', threeBounceSpinner)
        .directive('cubeGridSpinner', cubeGridSpinner)
        .directive('wordPressSpinner', wordPressSpinner)
        .directive('fadingCircleSpinner', fadingCircleSpinner)
        .directive('mAppLoading', mAppLoading);

    function rotatingPlaneSpinner() {
        var directive = {
            restrict: 'E',
            templateUrl: 'core/components/spinkit/rotatingPlaneSpinner.html'
        };

        return directive;
    }

    function doubleBounceSpinner() {
        var directive = {
            restrict: 'E',
            templateUrl: 'core/components/spinkit/doubleBounceSpinner.html'
        };

        return directive;
    }

    function waveSpinner() {
        var directive = {
            restrict: 'E',
            templateUrl: 'core/components/spinkit/waveSpinner.html'
        };

        return directive;
    }

    function wanderingCubesSpinner() {
        var directive = {
            restrict: 'E',
            templateUrl: 'core/components/spinkit/wanderingCubesSpinner.html'
        };

        return directive;
    }

    function pulseSpinner() {
        var directive = {
            restrict: 'E',
            templateUrl: 'core/components/spinkit/pulseSpinner.html'
        };

        return directive;
    }

    function chasingDotsSpinner() {
        var directive = {
            restrict: 'E',
            templateUrl: 'core/components/spinkit/chasingDotsSpinner.html'
        };

        return directive;
    }

    function circleSpinner() {
        var directive = {
            restrict: 'E',
            templateUrl: 'core/components/spinkit/circleSpinner.html'
        };

        return directive;
    }

    function threeBounceSpinner() {
        var directive = {
            restrict: 'E',
            templateUrl: 'core/components/spinkit/threeBounceSpinner.html'
        };

        return directive;
    }

    function cubeGridSpinner() {
        var directive = {
            restrict: 'E',
            templateUrl: 'core/components/spinkit/cubeGridSpinner.html'
        };

        return directive;
    }

    function wordPressSpinner() {
        var directive = {
            restrict: 'E',
            templateUrl: 'core/components/spinkit/wordPressSpinner.html'
        };

        return directive;
    }

    function fadingCircleSpinner() {
        var directive = {
            restrict: 'E',
            templateUrl: 'core/components/spinkit/fadingCircleSpinner.html'
        };

        return directive;
    }

    function mAppLoading($animate) {
        var directive = {
            restrict: 'C',
            link: link
        };

        // I bind the JavaScript events to the scope.
        function link(scope, element, attributes) {
            // Due to the way AngularJS prevents animation during the bootstrap
            // of the application, we can't animate the top-level container; but,
            // since we added "ngAnimateChildren", we can animated the inner
            // container during this phase.
            // --
            // NOTE: Am using .eq(1) so that we don't animate the Style block.
            $animate.leave(element.children().eq(1)).then(
                function cleanupAfterAnimation() {
                    // Remove the root directive element.
                    element.remove();
                    // Clear the closed-over variable references.
                    scope = element = attributes = null;
                }
            );
        }
        return directive;
    }
})();
