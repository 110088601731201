(function() {
    'use strict';

    angular
        .module('atheer.services')
        .factory('DeeplinkService', DeeplinkService);


    /* @ngInject */
    function DeeplinkService () {
        var content = {};
        var service = {
            getContentData: getContentData,
            setContentData: setContentData
        };

        return service;

        function getContentData () {
            return content;
        }

        function setContentData (data) {
            content = data
        }
    }
})();
