(function () {
    'use strict';

    angular
        .module('atheer.user')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('account', {
                abstract: true,
                views: {
                    'root': {
                        templateUrl: 'modules/user/user-account.tmpl.html'
                    }
                }
            })
            .state('account.signin', {
                url: '/signin',
                templateUrl: 'modules/user/signin/signin.tmpl.html',
                controller: 'SigninController',
                controllerAs: 'vm'
            })
            .state('account.atheer-signin', {
                url: '/atheer-signin',
                templateUrl: 'modules/user/signin/atheer-signin.tmpl.html',
                controller: 'SigninController',
                controllerAs: 'vm'
            })
            .state('account.signout', {
                url: '/signout',
                templateUrl: 'modules/user/signout/signout.tmpl.html',
                controller: 'SignoutController',
                controllerAs: 'vm'
            })
            .state('account.signup', {
                url: '/signup',
                templateUrl: 'modules/ngx-frame.html',
                controller: 'UserSignupController',
                controllerAs: 'vm'
            })
            .state('account.invite', {
                url: '/invite/:id',
                templateUrl: 'modules/user/activate/user-info.tmpl.html',
                controller: 'UserActivateInfoController',
                controllerAs: 'vm',
                resolve: {
                    id: function ($stateParams) {
                        return $stateParams.id;
                    }
                }
            })
            .state('account.conversations', {
                url: '/conversations',
                templateUrl: 'modules/conversation/conversations.tmpl.html',
                controller: 'ConversationsController',
                controllerAs: 'vm',
                data: {
                    pageTitle: 'atheer.conversation.home.title',
                    layout: {
                        showSideNavMenu: false,
                        contentClass: 'layout-column',
                        showToolbar: false,
                        footer: false
                    }
                }
            })
            .state('account.signup-confirmed', {
                url: '/signup/:id',
                templateUrl: 'modules/ngx-frame.html',
                controller: 'UserSignupController',
                controllerAs: 'vm',
                resolve: {
                    id: function ($stateParams) {
                        return $stateParams.id;
                    }
                }
            })
            .state('account.activate', {
                url: '/activate?key',
                templateUrl: 'modules/user/actiate/activate.tmpl.html',
                controller: 'ActivationController',
                controllerAs: 'vm',
                data: {
                    pageTitle: 'atheer.activate.title'
                }
            })
            .state('account.forgot', {
                url: '/forgot',
                templateUrl: 'modules/user/password/forgot.tmpl.html',
                controller: 'ForgotController',
                controllerAs: 'vm',
            })
            .state('account.forced-password', {
                url: '/forced-password',
                templateUrl: 'modules/user/password/forced-password.tmpl.html',
                // set the controller to load for this page
                controller: 'ForcedPasswordController',
                controllerAs: 'vm',
                // layout-column class added to make footer move to
                // bottom of the page on short pages
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false
                    }
                },
                resolve: {
                    id: function ($stateParams) {
                        return 'user';
                    }
                }
            })
            .state('account.reset', {
                url: '/reset?key',
                templateUrl: 'modules/user/password/reset.tmpl.html',
                controller: 'ResetFinishController',
                controllerAs: 'vm'
            })
            .state('account.guest-join', {
                url: '/join',
                templateUrl: 'modules/user/guest/guest-join.tmpl.html',
                controller: 'GuestJoinController',
                controllerAs: 'vm'
            })
            .state('account.join', {
                url: '/join/:session_no/:workspace_code/:launch_session',
                templateUrl: 'modules/user/guest/guest-join.tmpl.html',
                controller: 'GuestJoinController',
                controllerAs: 'vm',
                resolve: {
                    session_no: function ($stateParams) {
                        return $stateParams.session_no;
                    },
                    workspace_code: function ($stateParams) {
                        return $stateParams.workspace_code;
                    },
                    launch_session: function ($stateParams) {
                        return $stateParams.launch_session;
                    }
                }
            })
            .state('account.guest', {
                url: '/guest/join/:session_no/:workspace_code/:launch_session',
                templateUrl: 'modules/user/guest/guest-join.tmpl.html',
                controller: 'GuestJoinController',
                controllerAs: 'vm',
                resolve: {
                    session_no: function ($stateParams) {
                        return $stateParams.session_no;
                    },
                    workspace_code: function ($stateParams) {
                        return $stateParams.workspace_code;
                    },
                    launch_session: function ($stateParams) {
                        return $stateParams.launch_session;
                    }
                }
            })
            .state('account.noaccess', {
                url: '/signin/noaccess',
                templateUrl: 'modules/user/noaccess/noaccess.tmpl.html',
                controller: 'NoAccessContoller',
                controllerAs: 'vm',
                params: {
                    isWorkspaceInactive: true
                }
            });
    }
})();
