(function() {
    'use strict';

    angular
        .module('atheer.system')
        .controller('LanguageController', LanguageController);

    /* @ngInject */
    function LanguageController ($scope, $state, Language, ParseLinks, AlertService, pagingParams, paginationConstants, $mdDialog, $mdToast, $stateParams) {
        var vm = this;
        vm.loadAll = loadAll;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.loadAll();

        vm.query = {
                filter: '',
                limit: paginationConstants.itemsPerPage,
                order: '-id',
                page: 1
            };

        vm.filter = {
                options: {
                    debounce: 500
                }
            };

        vm.removeFilter = removeFilter;
        vm.filter.show = false;

        vm.createObject=createObject;
        vm.editObject=editObject;
        vm.deleteObject=deleteObject;

        function loadAll () {
            Language.query({
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.languages = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function transition () {
            vm.promise = Language.query({
        				page: vm.page-1,
        				size: paginationConstants.itemsPerPage,
        				sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
        				search: vm.currentSearch
      			  }).$promise;

            vm.promise.then(function (data,headers) {
              vm.queryCount = vm.totalItems;
              vm.languages = data;
            });
        }

        function removeFilter() {
            vm.filter.show = false;
            vm.query.filter = '';

            if(vm.filter.form.$dirty) {
                vm.filter.form.$setPristine();
            }
        }

        function createObject() {
      			$mdDialog.show({
      				animation : true,
      				templateUrl : 'modules/system/language/language-dialog.html',
      				controller : 'LanguageDialogController',
      				controllerAs : 'vm',
      				backdrop : 'static',
      				size : 'lg',
              resolve: {
                  entity: function () {
                      return {
                          languageNo: null,
                          name: null,
                          description: null,
                          code: null,
                          enabled: false,
                          systemProvided: false,
                          id: null
                      };
                  }
              }
      			}).then(function() {
      			     resetModal();
      			});
    		}

        function editObject(objectId) {
      			$mdDialog.show({
      				animation : true,
              templateUrl : 'modules/system/language/language-dialog.html',
      				controller : 'LanguageDialogController',
      				controllerAs : 'vm',
      				backdrop : 'static',
      				size : 'lg',
              resolve: {
                  entity: ['Language', function(Language) {
                      return Language.get({
                        id : objectId
                      });
                  }]
              }
      			}).then(function() {
      			     resetModal();
      			});
    		}

    		function deleteObject(objectId) {
      			$mdDialog.show({
      				animation : true,
      				templateUrl : 'modules/system/language/language-delete-dialog.html',
      				controller : 'LanguageDeleteController',
      				controllerAs : 'vm',
      				backdrop : 'static',
              resolve: {
                  entity: ['Language', function(Language) {
                      return Language.get({
                        id : objectId
                      });
                  }]
              }
      			}).then(function() {
      			     resetModal();
      			});
      		};

    		function resetModal () {
            loadAll();
            AlertService.displayToast($mdToast);
    		};

    }
})();
