(function () {
    'use strict';

    angular
        .module('atheer.smartFlow')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('storm.admin-smartflows', {
                url: '/smartflows?page&sort&search',
                views: {
                    '@storm': {
                        templateUrl: 'modules/smartflow/smartflows.html',
                        controller: 'SmartFlowController',
                        controllerAs: 'vm',
                    },
                    'toolbar@storm': {
                        templateUrl: 'modules/smartflow/smartflow-toolbar.tmpl.html',
                        controller: 'SmartFlowToolbarController',
                        controllerAs: 'vm'
                    },
                    'sideMenu@storm': {
                        templateUrl: 'modules/smartflow/smartflow-menu.tmpl.html',
                        controller: 'SmartFlowMenuController',
                        controllerAs: 'vm',
                    }
                },
                data: {
                    pageTitle: 'atheer.smartFlow.home.title',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showSideNavMenu: true,
                        sideNavMenuPosition: 'left',
                        showToolbar: false
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }]
                }
            })
            .state('storm.admin-smartflow-template', {
                url: '/smartflow/templates',
                templateUrl: 'modules/smartflow/smartflow-template.tmpl.html',
                // set the controller to load for this page
                controller: 'SmartFlowTemplatePageController',
                controllerAs: 'vm',
                // layout-column class added to make footer move to
                // bottom of the page on short pages
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false
                    }
                }
            })
            .state('storm.admin-smartflow-detail', {
                url: '/smartflows/:id',
                templateUrl: 'modules/smartflow/smartflow-detail.tmpl.html',
                controller: 'SmartFlowDetailController',
                controllerAs: 'vm',
                resolve: {
                    id: function ($stateParams) {
                        return $stateParams.id;
                    }
                },
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false
                    }
                },
                params: {
                    viewType: 'build',
                    isCopy: function ($stateParams) {
                        return $stateParams.isCopy
                    },
                    isJobFlow: function ($stateParams) {
                        return $stateParams.isJobFlow;
                    }

                }
            })
            .state('storm.admin-jobflow-insights', {
                url: '/smartflows/:id/insights',
                templateUrl: 'modules/smartflow/jobflow-insights-overview.tmpl.html',
                controller: 'JobFlowInsightsOverviewController',
                controllerAs: 'vm',
                resolve: {
                    id: function ($stateParams) {
                        return $stateParams.id;
                    },
                    name: function ($stateParams) {
                        return $stateParams.name;
                    }
                },
                params: {
                    name: function ($stateParams) {
                        return $stateParams.name;
                    }
                },
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false
                    }
                }
            })
            .state('storm.admin-smartflow-insights', {
                url: '/smartflows/:id/insights',
                templateUrl: 'modules/smartflow/smartflow-builder-insights.tmpl.html',
                controller: 'SmartFlowBuilderInsightsController',
                controllerAs: 'vm',
                resolve: {
                    id: function ($stateParams) {
                        return $stateParams.id;
                    }
                },
                abstract: true,
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false
                    }
                }
            })
            .state('storm.admin-smartflow-insights.section', {
                url: '/:section',
                templateUrl: function ($stateParams) {
                    return 'modules/smartflow/smartflow-builder-insights-' + $stateParams.section + '.tmpl.html';
                },
                controllerProvider: function ($stateParams, $filter) {
                    return 'SmartFlowBuilderInsights' + $filter('capitalize')($stateParams.section) + 'Controller';
                },
                controllerAs: 'vm',
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null,
                },
                resolve: {
                    id: function ($stateParams) {
                        return $stateParams.id;
                    },
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }]
                },
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false
                    }
                }
            });
    }
})();
