(function () {
	"use strict";

	angular
		.module("atheer.smartFlow")
		.controller(
			"JobFlowMoleculesJobSeedController",
			JobFlowMoleculesJobSeedController
		);

	/* @ngInject */
	function JobFlowMoleculesJobSeedController(
		$mdDialog,
		JobFlow,
		setting,
		SmartFlowBuilder,
        $q
	) {
		var vm = this;

		var object = {
			data_mapper_value: "",
			data_mapper_key: "data_mapper_key",
			airForm_value: "",
			airForm_key: "airForm_key",
		};

		var jobObject = {
			data_mapper_value: "",
			data_mapper_key: "data_mapper_key",
			jobAttribute_value: "",
			jobAttribute_key: "jobAttribute_key",
		};

		vm.isAiRFormMapper = false;
		vm.isJobMapper = false;
		vm.loadingData = false;
		vm.dataModelProperties = [];
		vm.objectList = [];
		vm.jobObjectList = [];
		vm.smartflowCategory = SmartFlowBuilder.getSmartFlowCategory();
		vm.close = function () {
			$mdDialog.cancel();
		};
		vm.hasJobUpdate = SmartFlowBuilder.getJobUpdateStatus();

		vm.submit = function () {
			vm.setting.properties.mapping = {};

			if (vm.isAiRFormMapper) {
				angular.forEach(vm.objectList, function (object) {
					var valueList = object.airForm_value.split(":");
					if(valueList[0] === "AUTOMATION_LABEL") {
                        vm.setting.properties.mapping[object.data_mapper_value] = {
                            dataType: valueList[2],
                            name: valueList[1],
                            objectType: "AUTOMATION_LABEL",
                            jobKey: "",
                        };
                    } else {
                        vm.setting.properties.mapping[object.data_mapper_value] = {
                            dataType: valueList[2],
                            name: valueList[0] + ":" + valueList[1],
                            jobKey: "",
                        };
                    }
				});
			}

			if (vm.isJobMapper) {
				angular.forEach(vm.jobObjectList, function (object) {
					if (
						vm.setting.properties.mapping[object.data_mapper_value]
					) {
						vm.setting.properties.mapping[object.data_mapper_value][
							"jobKey"
						] = object.jobAttribute_value;
					} else {
						vm.setting.properties.mapping[
							object.data_mapper_value
						] = {
							name: "",
							dataType: "STRING",
							jobKey: object.jobAttribute_value,
						};
					}
				});
			}

			$mdDialog.hide(vm.setting);
		};

		vm.addField = function (key) {
			var obj = angular.copy(object);
			vm.objectList.push(obj);
		};

		vm.removeField = function (id, obj) {
			vm.objectList.splice(id, 1);
			delete vm.setting.properties[obj.airForm_value];
		};

		vm.addJobField = function (key) {
			var obj = angular.copy(jobObject);
			vm.jobObjectList.push(obj);
		};

		vm.removeJobField = function (id, obj) {
			vm.jobObjectList.splice(id, 1);
			delete vm.setting.properties[obj.jobAttribute_value];
		};

		vm.populateAirFormResponseList = function () {
			vm.responseList = [];
			angular.forEach(vm.airFormData.lanes, function (lane) {
				angular.forEach(lane.sections, function (section) {
					angular.forEach(section.steps, function (step) {
						var key = "";
						if (step.moleculeType == "BRANCH") {
							angular.forEach(step.paths, function (path) {
								angular.forEach(path.steps, function (step) {
									key = "";
									angular.forEach(step.metadata, function (
										data
									) {
										if (data.level === "HEADER") {
											angular.forEach(
												data.values,
												function (value) {
													if (
														value.name === "title"
													) {
														key = value.value;
													}
												}
											);
										}
										if (data.level === "RESPONSE") {
											angular.forEach(
												data.values,
												function (value) {
													var resLabel = "";
													if (
														value.moleculeType ===
														"NUMERIC" ||
														value.moleculeType ===
														"TEXT" ||
														value.moleculeType ===
														"SCIENTIFIC" ||
														value.moleculeType ===
														"PERCENTAGE" ||
														value.moleculeType ===
														"YES_NO" ||
														value.moleculeType ===
														"DATE" ||
                                                        value.moleculeType ===
                                                        "DATE_TIME" ||
                                                        value.moleculeType ===
                                                        "MULTIPLE_CHOICE" ||
                                                        value.moleculeType ===
                                                        "CHECKBOX" ||
                                                        value.moleculeType ===
                                                        "QRCODE" ||
                                                        value.moleculeType ===
                                                        "BARCODE"
													) {
														resLabel =
															key +
															" : " +
															value.label;
														vm.responseList.push({
															value: resLabel,
															key:
																step.id +
																":" +
																value.id +
																":STRING",
														});
                                                        // for automation labels
                                                        angular.forEach(value.metadata, function (metadataProp) {
                                                            if(metadataProp.level === "PROPERTY") {
                                                                angular.forEach(metadataProp.values, function (metaValue) {
                                                                    if(metaValue.label === "Automation Label" && metaValue.value) {
                                                                        vm.responseList.push({
                                                                            value: metaValue.value,
                                                                            key: "AUTOMATION_LABEL:" + metaValue.value + ":STRING"
                                                                        });
                                                                    }
                                                                });
                                                            }
                                                        });
													}
												}
											);
										}
									});
								});
							});
						} else {
							angular.forEach(step.metadata, function (data) {
								if (data.level === "HEADER") {
									angular.forEach(data.values, function (
										value
									) {
										if (value.name === "title") {
											key = value.value;
										}
									});
								}
								if (data.level === "RESPONSE") {
									angular.forEach(data.values, function (
										value
									) {
										var resLabel = "";
										if (
                                            value.moleculeType ===
                                            "NUMERIC" ||
                                            value.moleculeType ===
                                            "TEXT" ||
                                            value.moleculeType ===
                                            "SCIENTIFIC" ||
                                            value.moleculeType ===
                                            "PERCENTAGE" ||
                                            value.moleculeType ===
                                            "YES_NO" ||
                                            value.moleculeType ===
                                            "DATE" ||
                                            value.moleculeType ===
                                            "DATE_TIME" ||
                                            value.moleculeType ===
                                            "MULTIPLE_CHOICE" ||
                                            value.moleculeType ===
                                            "CHECKBOX" ||
                                            value.moleculeType ===
                                            "QRCODE" ||
                                            value.moleculeType ===
                                            "BARCODE"
										) {
											resLabel =
												key + " : " + value.label;
											vm.responseList.push({
												value: resLabel,
												key:
													step.id +
													":" +
													value.id +
													":STRING",
											});
											// for automation labels
                                            angular.forEach(value.metadata, function (metadataProp) {
                                                if(metadataProp.level === "PROPERTY") {
                                                    angular.forEach(metadataProp.values, function (metaValue) {
                                                        if(metaValue.label === "Automation Label" && metaValue.value) {
                                                            vm.responseList.push({
                                                                value: metaValue.value,
                                                                key: "AUTOMATION_LABEL:" + metaValue.value + ":STRING"
                                                            });
                                                        }
                                                    });
                                                }
                                            });
										}
									});
								}
							});
						}
					});
				});
			});
		};

        vm.populateAutomationLabelsList = function () {
            vm.responseList = [];
            angular.forEach(vm.dataAttributes, function (attr) {
                if(attr.category === "AUTOMATION_LABEL") {
                    vm.responseList.push({
                        value: attr.label,
                        key: "AUTOMATION_LABEL:" + attr.label + ":STRING"
                    });
                }
            });
        };

		vm.onJobMapperChange = function (addField) {
			if (vm.isJobMapper && !vm.jobAttributeData) {
				vm.loadingAirForm = true;
                $q.all([
                    JobFlow.getDataMapper().$promise,
                    JobFlow.getMetaData({ objectType: "Job" }).$promise
                ]).then(function(data) {
                    vm.loadingAirForm = false;
                    vm.loadingData = false;
                    var jobAttributesLabels = data[0];
                    vm.jobAttributeData = data[1];
                    vm.jobAttributeList = [];
                    angular.forEach(vm.jobAttributeData, function (value) {
                        angular.forEach(jobAttributesLabels, function (attr){
                            if(attr.name === value) {
                                vm.jobAttributeList.push({ key: value, value: attr.label });
                            }
                        });
                    });

                    if (!addField) {
                        vm.addJobField();
                    }
                });
			}
		};

		vm.onAiRFormMapperChange = function (addField) {
			if (vm.isAiRFormMapper && !vm.airFormData) {
				vm.loadingAirForm = true;
				var airFormId = SmartFlowBuilder.getAirFormId();
				if(airFormId) {
                    JobFlow.getAirForm({ id: airFormId }, function (result) {
                        vm.airFormData = result.toJSON();
                        vm.populateAirFormResponseList();
                        vm.loadingAirForm = false;
                    });
                } else {
                    JobFlow.getDataMapper(function (result) {
                        vm.dataAttributes = result;
                        vm.populateAutomationLabelsList();
                        vm.loadingAirForm = false;
                    })
                }

				if (!addField) {
					vm.addField();
				}
			}
		};

		function init() {
			vm.setting = angular.copy(setting);
			if (!vm.setting.properties.mapping) {
				vm.setting.properties = {
					mapping: {},
				};
			} else {
				angular.forEach(vm.setting.properties.mapping, function (
					value,
					key
				) {
					if (!!value.name) {
                        vm.isAiRFormMapper = true;
                        var obj = angular.copy(object);
                        obj.data_mapper_value = key;
					    if(value.objectType === "AUTOMATION_LABEL") {
                            obj.airForm_value = "AUTOMATION_LABEL:" + value.name + ":" + value.dataType;
                        } else {
                            obj.airForm_value = value.name + ":" + value.dataType;
                        }
                        vm.objectList.push(obj);
					}

					if (!!value.jobKey) {
						vm.isJobMapper = true;
						var obj = angular.copy(jobObject);
						obj.data_mapper_value = key;
						obj.jobAttribute_value = value.jobKey;
						vm.jobObjectList.push(obj);
					}
				});

				if (vm.isAiRFormMapper) {
					vm.onAiRFormMapperChange(true);
				}

				if (vm.isJobMapper) {
					vm.onJobMapperChange(true);
				}
			}
			var dataModelId = SmartFlowBuilder.getDataModelId();

			if (dataModelId) {
				vm.loadingDataModel = true;
				JobFlow.getDataModelById({ id: dataModelId }, function (
					result
				) {
					vm.dataModelProperties = result.properties;
					vm.loadingDataModel = false;
				});
			}
		}

		init();
	}
})();
