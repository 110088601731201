(function () {
    'use strict';

    angular
        .module('atheer.user')
        .controller('SigninController', SigninController);

    /* @ngInject */

    function SigninController($rootScope, $location, $window, $state, $q, Setting, $timeout, Auth, triSettings, Feature, triMenu, Principal, $cookies, deviceDetector, $filter, translationStorageProvider, User, LocalResourceService, ToastService) {
        var vm = this;

        vm.isAppWorkspace = $rootScope.isAppWorkspace;

        vm.useDarkBackGroundLogos = true;
        vm.authenticationError = false;
        vm.credentials = {};
        vm.login = login;
        vm.password = null;
        vm.rememberMe = true;
        vm.username = null;
        vm.workspace = null;
        vm.triSettings = triSettings;
        vm.userSignupSetting = {
            invitation_only: "true"
        };
        vm.buildInfoSettings = $rootScope.buildInfo;

        vm.enableAtheerLogin = true;
        vm.enableForgotPassword = false;
        vm.googleSSO = false;
        vm.twitterSSO = false;
        vm.linkedInSSO = false;
        vm.facebookSSO = false;
        vm.samlSSO = false;
        vm.azureAdSSO = false;
        vm.studioOidcSSO = false;
        vm.featureForcedPassword = Feature.isActive('feature_user_initial_login_forced_password_required');
        vm.isGuestFeature = Feature.isActive('feature_airsession_invite_guest_user');
        vm.isSignUpFeatureActive = Feature.isActive('feature_user_signup');

        vm.showSSOLogin = false;

        vm.isDesktop = window.isDesktop;
        vm.isWhitedLablled = window.isWhiteLabelled;
        vm.isWorkspaceError = false;
        vm.isMobileBrowser = deviceDetector.isMobile();
        vm.backgroundColor = {};
        vm.isLoading = true;

        vm.samlLogin = {
            icon: 'fa fa-twitter',
            color: '#5bc0de',
            url: '/sso/saml'
        };

        vm.twitterLogin = {
            icon: 'fa fa-twitter',
            color: '#5bc0de',
            url: '/sso/twitter'
        };

        vm.googleLogin = {
            icon: 'fa fa-google',
            color: '#FFFFFF',
            url: '/sso/google'
        };

        vm.facebookLogin = {
            icon: 'fa fa-facebook',
            color: '#337ab7',
            url: '/sso/facebook'
        };

        vm.linkedInLogin = {
            icon: 'fa fa-linkedin',
            color: '#337ab7',
            url: '/sso/linkedin'
        };

        vm.oidcLogin = {
            icon: 'fa fa-microsoft',
            color: '#FFFFFF',
            url: '/sso/oidc'
        };

        vm.oidcSignOn = {
            url: '/sso/genericOidc'
        };

        vm.isSelectLanguage = false;
        vm.supportedLanguages = [
            { name: 'english_us', displayName: 'English (US)', value: 'en_US', serverKey: 'en_US' },
            { name: 'english_uk', displayName: 'English (UK)', value: 'en_GB', serverKey: 'en_GB' },
            { name: 'french', displayName: 'French', value: 'fr_FR', serverKey: 'fr_FR' },
            { name: 'german', displayName: 'German', value: 'de_DE', serverKey: 'de_DE' },
            { name: 'greek', displayName: 'Greek', value: 'el_GR', serverKey: 'el_GR' },
            { name: 'italian', displayName: 'Italian', value: 'it_IT', serverKey: 'it_IT' },
            { name: 'japanese', displayName: 'Japanese', value: 'ja_JP', serverKey: 'ja_JP' },
            { name: 'portuguese', displayName: 'Portuguese', value: 'pt_BR', serverKey: 'pt_BR' },
            { name: 'spanish', displayName: 'Spanish', value: 'es_CL', serverKey: 'es_CL' },
            { name: 'chinese', displayName: 'Chinese (Traditional)', value: 'zh_TW', serverKey: 'zh_TW' },
            { name: 'chinese_cn', displayName: 'Chinese (Simplified)', value: 'zh_CN', serverKey: 'zh_CN' },
            { name: 'dutch', displayName: 'Dutch', value: 'nl_NL', serverKey: 'nl_NL' },
            { name: 'danish', displayName: 'Danish (Denmark)', value: 'da_DK', serverKey: 'da_DK' },
            { name: 'norwegian', displayName: 'Norwegian', value: 'no_NO', serverKey: 'no_NO' },
            { name: 'polish', displayName: 'Polish (Poland)', value: 'pl_PL', serverKey: 'pl_PL' },
            { name: 'russian', displayName: 'Russian', value: 'ru_RU', serverKey: 'ru_RU' },
            { name: 'swedish', displayName: 'Swedish (Sweden)', value: 'sv_SE', serverKey: 'sv_SE' },
            { name: 'turkish', displayName: 'Turkish (Turkey)', value: 'tr_TR', serverKey: 'tr_TR' }
        ];

        vm.supportedLanguages.sort(function (a, b) {
            if (a['displayName'] > b['displayName']) {
                return 1;
            } else if (a['displayName'] < b['displayName']) {
                return -1;
            }
            return 0;
        });

        LocalResourceService.get({ fileName: 'languages.json' }, function (res) {
            vm.languageCodes = res.languageCodes;
        });

        vm.selectLanguage = function () {
            vm.isSelectLanguage = !vm.isSelectLanguage;
        };

        vm.onLanguageChange = function (value) {
            localStorage.setItem('isUserSelectedLanguage', true)
            translationStorageProvider.put('NG_TRANSLATE_LANG_KEY', value);
            vm.isSelectLanguage = false;
            $window.location.reload();
        };

        vm.redirect = function (url) {
            var hostUrl = $window.location.protocol + '//' + $window.location.host + url;
            $window.location.href = hostUrl;
        };

        vm.chooseWorkspace = function () {
            var subDomain = $window.location.host.split('.')[0]
            var hostUrl = $location.absUrl();
            var path = $location.path();

            var appUrl = hostUrl.replace(subDomain, "app").replace(path, "");
            $window.location.href = appUrl;
        };

        vm.selectWorkspace = function () {
            Auth.validateWorkspaceName(vm.workspace).then(function (res) {
                vm.isWorkspaceError = false;

                angular.forEach(vm.languageCodes, function (lang) {
                    if (lang.name == res.data.language_id) {
                        translationStorageProvider.put('NG_TRANSLATE_LANG_KEY', lang.value);
                    }
                });

                var subDomain = $window.location.host.split('.')[0]
                var hostUrl = $location.absUrl();
                var path = $location.path();

                var appUrl = hostUrl.replace("app", vm.workspace).replace(path, "");
                $window.location.href = appUrl;
            }).catch(function (err) {
                if (err && err.data && err.data.WorkspaceActiveState === "workspace is inactive") {
                    $state.go('account.noaccess')
                } else {
                    vm.isWorkspaceError = true;
                }
            });
        };

        vm.onWorkspaceChange = function () {
            if (vm.isWorkspaceError) {
                vm.isWorkspaceError = false;
            }
        }

        vm.onGoBack = function () {
            if (window && window.nodeAPI) {
                window.nodeAPI.ipc.send('back-to-workspace');
            }
        }

        $timeout(function () {
            angular.element('[ng-model="vm.username"]').focus();
        });

        function login(event) {

            var isWorkspaceAdmin = vm.username == 'admin';

            var credentials = {
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe,
                widgetToken: false
            };

            Auth.login(credentials).then(function () {
                vm.authenticationError = false;

                User.getById({
                    id: Principal.getUserId()
                }, function (data) {
                    if (data && data.language_id) {
                        angular.forEach(vm.languageCodes, function (lang) {
                            if (lang.name == data.language_id) {
                                if (lang.name != 'system_default') {
                                    $rootScope.userSelectedLanguage = lang.value;
                                } else {
                                    var lang = ($window.navigator.language || $window.navigator.userLanguage).replace('-', '_');
                                    if (lang == 'el') {
                                        lang = 'el_GR';
                                    } else if (lang == 'ja') {
                                        lang = 'ja_JP';
                                    } else if (lang.startsWith('zh')) {
                                        lang = 'zh_TW';
                                    } else if (lang.startsWith('nl')) {
                                        lang = 'nl_NL';
                                    }
                                    $rootScope.userSelectedLanguage = lang;
                                }
                            }
                        });
                    }

                    // If we're redirected to login, our
                    // previousState is already set in the authExpiredInterceptor. When login succesful go to stored state
                    if ($rootScope.redirected && $rootScope.previousStateName) {
                        $state.go($rootScope.previousStateName, $rootScope.previousStateParams);
                        $rootScope.redirected = false;
                    } else {
                        $rootScope.$broadcast('authenticationSuccess');
                    }
                    if ($rootScope.isMasterWorkspace) {
                        $state.go('storm.system-admin.dashboard');
                    } else {
                        if (vm.featureForcedPassword) {
                            var userInfo = Principal.getUserInfo();
                            if (userInfo.forced_password_enabled) {
                                $state.go('account.forced-password');
                            } else {
                                $location.path("/");
                            }
                        } else {
                            $location.path("/");
                        }
                    }
                });

            }).catch(function (res, err) {
                vm.authenticationError = true;
                console.log('Studio-Login-error: ' + res.data.ExceptionType);
                var loginError = res.data.ExceptionType;
                var loginErrorMsg = (loginError == 'UserNotActivatedException' || loginError == 'AccountExpiredException') ? 'atheer.signIn.accountDisabled' : 'atheer.signIn.usernameOrPasswordYouEnteredIsInvalid';
                ToastService.displayToastWithAction($filter('translate')(loginErrorMsg),
                    $filter('translate')('entity.action.ok'), 'md-warn', 3000);
            });
        };

        function loadAll() {
            sessionStorage.clear();
            vm.useDarkBackGroundLogos = triSettings.getSkinModel().use_dark_logos;
            vm.backgroundColor = {
                "background-color": triSettings.getSkinModel().primary_color_hex
            };
            if ($cookies.get('isGuest')) {
                $cookies.remove('isGuest');
            }
            //for setting language
            var host = $location.host();
            //for localhost
            // var workspace = host == 'localhost' ? 'demo' : host.split('.')[0];
            var workspace = host.split('.')[0];
            if (!$cookies.getObject('isRefreshed') && !localStorage.getItem('isUserSelectedLanguage')) {
                Auth.validateWorkspaceName(workspace).then(function (res) {
                    angular.forEach(vm.languageCodes, function (lang) {
                        if (lang.name == res.data.language_id) {
                            if (res.data.language_id != 'system_default' && lang.value != $cookies.getObject('NG_TRANSLATE_LANG_KEY')) {
                                translationStorageProvider.put('NG_TRANSLATE_LANG_KEY', lang.value);
                                $cookies.putObject('isRefreshed', true);
                                $window.location.reload();
                            }
                            if (res.data.language_id == 'system_default') {
                                var lang = ($window.navigator.language || $window.navigator.userLanguage).replace('-', '_');
                                if (lang == 'el') {
                                    lang = 'el_GR';
                                } else if (lang == 'ja') {
                                    lang = 'ja_JP';
                                } else if (lang.startsWith('zh')) {
                                    lang = 'zh_TW';
                                } else if (lang.startsWith('nl')) {
                                    lang = 'nl_NL';
                                }
                                if (lang != $cookies.getObject('NG_TRANSLATE_LANG_KEY')) {
                                    translationStorageProvider.put('NG_TRANSLATE_LANG_KEY', lang);
                                    $cookies.putObject('isRefreshed', true);
                                    $window.location.reload();
                                }
                            }
                        }
                    });
                });
            } else {
                $cookies.remove('isRefreshed');
            }
            $q.all([
                Setting.getUserSignupSettings().$promise,
                Setting.getAuthenticationSetupSettings().$promise
            ])
                .then(function (results) {
                    setUserSignupSettings(results[0]);
                    setLoginSettings(results[1]);
                    vm.isLoading = false;
                });
        };

        function setUserSignupSettings(signupSettings, ssoSettings) {
            angular.forEach(signupSettings, function (setting) {
                if (setting.category == 'user_signup') {
                    vm.userSignupSetting = setting;

                    angular.forEach(setting.fields, function (settingField) {
                        if (settingField.name == 'policy') {
                            vm.userSignupSetting.invitation_only = settingField.value === 'invitation_only';
                            vm.isSignupEnabled = vm.isSignUpFeatureActive && !vm.userSignupSetting.invitation_only;
                        }
                    });
                }
            });
        };

        function setLoginSettings(settings) {
            angular.forEach(settings, function (setting) {
                if (setting.category == 'authentication-setup') {
                    vm.loginSettings = setting;
                    angular.forEach(setting.fields, function (settingField) {
                        if (settingField.name == 'google_sso') {
                            vm.googleSSO = (settingField.value == 'true' ? true : false);
                            if (vm.googleSSO) {
                                vm.showSSOLogin = true;
                            }
                        } else if (settingField.name == 'facebook_sso') {
                            vm.facebookSSO = (settingField.value == 'true' ? true : false);
                            if (vm.facebookSSO) {
                                vm.showSSOLogin = true;
                            }
                        } else if (settingField.name == 'linkedin_sso') {
                            vm.linkedInSSO = (settingField.value == 'true' ? true : false);
                            if (vm.linkedInSSO) {
                                vm.showSSOLogin = true;
                            }
                        } else if (settingField.name == 'twitter_sso') {
                            vm.twitterSSO = (settingField.value == 'true' ? true : false);
                            if (vm.twitterSSO) {
                                vm.showSSOLogin = true;
                            }
                        } else if (settingField.name == 'saml_sso') {
                            vm.samlSSO = (settingField.value == 'true' ? true : false);
                        } else if (settingField.name == 'azureAD_sso') {
                            vm.azureAdSSO = (settingField.value == 'true' ? true : false);
                            if (vm.azureAdSSO) {
                                vm.showSSOLogin = true;
                            }
                        } else if (settingField.name == 'forgot_password') {
                            vm.enableForgotPassword = (settingField.value == 'true' ? true : false);
                        } else if (settingField.name == 'atheer_login') {
                            vm.enableAtheerLogin = (settingField.value == 'true' ? true : false);
                        } else if (settingField.name == 'studio_oidc_sso') {
                            vm.studioOidcSSO = (settingField.value == 'true' ? true : false);
                        }
                    });
                }
            });
            if ($rootScope.isMasterWorkspace) {
                vm.enableForgotPassword = true;
            }
        };

        loadAll();
    }
})();
