(function() {
    'use strict';

    angular
        .module('atheer.system')
        .controller('AppDetailController', AppDetailController)

    /* @ngInject */
    function AppDetailController($mdDialog, $scope, $filter, $sce, ParseLinks, AlertService, $mdToast, $mdSidenav, App, appName) {

        this.appModel = null;

        this.loadData = function() {
            App.getByName({
                id: appName
            }, function(result) {
                $scope.vm.appModel = result;
            });
        }

        function onSaveSuccess(result) {
            $scope.vm.appModel = result;
            $scope.vm.isSaving = false;
            AlertService.displayToast($mdToast);
        };

        function onSaveError() {
            $scope.vm.isSaving = false;
        };

        this.save = function() {
            this.appModel.app_type = 'SYSTEM';
            App.update(this.appModel, onSaveSuccess, onSaveError);
        };

        this.enable = function() {
            this.appModel.enabled = true;
            this.appModel.app_type = 'SYSTEM';
            App.update(this.appModel, onSaveSuccess, onSaveError);
        };

        this.disable = function() {
            this.appModel.enabled = false;
            this.appModel.app_type = 'SYSTEM';
            App.update(this.appModel, onSaveSuccess, onSaveError);
        };

        this.togglePanel = function(componentId) {
            $mdSidenav(componentId).toggle();
        };

        this.loadData();
    }
})();
