(function() {
    'use strict';

    angular
        .module('atheer.session')
        .controller('SessionsAllToolbarController', SessionsAllToolbarController);

    /* @ngInject */
    function SessionsAllToolbarController($rootScope, $scope, $state, $mdDialog, ParseLinks, AlertService, pagingParams, paginationConstants, $mdToast, $stateParams, $filter, $translate, Feature, ToastService, SessionsService) {
        var vm = this;

        vm.pageTitleKey = $state.$current.data.pageTitle;

        vm.query = {
            filter: ''
        };

        vm.filter = {
            options: {
                debounce: 500
            }
        };

        vm.filter.show = false;

        vm.permissions = {
            create: 'create-session',
            update: 'update-session',
            delete: 'delete-session',
            updatePersonalSession: 'update-personal-session'
        };

        vm.toggleFilter = function() {
            vm.filter.show = !vm.filter.show;
        };

        vm.refreshAll = function() {
            $scope.$emit('refreshSessions', vm.query.filter);
        };

        vm.removeFilter = function() {
            vm.filter.show = false;
            vm.query.filter = '';
            $scope.$emit('refreshSessions', vm.query.filter);
        };

        vm.exportSessionsReport = function() {
            SessionsService.exportSessionsReport();
        };

        vm.goBack = function() {
            $state.go('storm.sessions.upcoming');
        };
    }
})();
