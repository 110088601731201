(function() {
    'use strict';

    angular
        .module('atheer.system')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
        .state('storm.system-admin.timezones', {
            url: '/timezones?page&sort&search',
            templateUrl: 'modules/system/timezone/timezones.html',
            controller: 'TimezoneController',
            controllerAs: 'vm',
            data: {
                pageTitle: 'atheer.timezone.home.title',
                layout: {
                    contentClass: 'layout-column',
                    footer: false,
                    showToolbar: false,
                    showSideNavMenu: false,
                }
            },

            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }]
            }
        });
    }
})();
