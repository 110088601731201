(function() {
    'use strict';

    angular
        .module('atheer.session')
        .controller('SessionSaveAnnotationController', SessionSaveAnnotationController);

    /* @ngInject */
    function SessionSaveAnnotationController($mdDialog, $rootScope, sessionNo, isAnnotationSaved, SessionAnnotationService, $filter, ToastService) {
        var vm = this;

        vm.onLoad = onLoad;

        vm.annotationTitle = null;
        vm.isError = false;
        vm.isValidating = false;

        function onLoad() {
            vm.annotationTitle = $filter('translate')('atheer.session.saveAnnotation.annotationTitle') + moment().format('YYYYMMDDhhmmss')  + '.jpg';
        };

        vm.save = function() {
            vm.isValidating = true;
            $rootScope.$broadcast('generate-annotation-image', { event: event });
            SessionAnnotationService.saveAnnotation(vm.annotationTitle, sessionNo).then(function (response) {
                vm.isValidating = false;
                $rootScope.$broadcast('annotation-image-saved',{
                    isAnnotationSaved: isAnnotationSaved,
                    annotationImageData: response
                });
                ToastService.displayToast($filter('translate')('atheer.session.saveAnnotation.saved'), 3000);
                $mdDialog.hide();
            }).catch(function(err) {
                vm.isError = true;
                vm.isValidating = false;
            });
        }

        vm.close = function() {
            $mdDialog.cancel();
        };

        vm.onLoad();
    }
})();
