(function() {
    'use strict';

    angular
        .module('atheer.system')
        .controller('FieldGroupDialogController', FieldGroupDialogController);

    /* @ngInject */
    function FieldGroupDialogController($scope, $stateParams, $mdDialog, fieldGroup, isAdd, _) {
        var vm = this;
        vm.fieldGroup = fieldGroup;
        vm.isAdd = isAdd;

        vm.add = function() {
            $mdDialog.hide(vm.fieldGroup);
        };

        vm.clear = function() {
            $mdDialog.cancel();
        };

        vm.setFieldGroupName = function() {
            if ( vm.isAdd ) {
                vm.fieldGroup.name = _.underscored(vm.fieldGroup.label);
            }
        }
    }
})();
