(function() {
    'use strict';

    angular
        .module('atheer')
        .run(UnderScore);

    /* @ngInject */
    function UnderScore($rootScope) {
        $rootScope._ = window._;;
    }
})();
