(function () {
    'use strict';

    angular
        .module('atheer.core')
        .controller('ToolbarController', DefaultToolbarController);

    /* @ngInject */
    function DefaultToolbarController($scope, $injector, $rootScope, $mdMedia, $window, $state, $stateParams, $element, $filter, $mdUtil, $mdSidenav, $timeout, $document, triBreadcrumbsService, triSettings, triLayout, Auth, Principal, Feature, triMenu, ToastService, $location) {
        var vm = this;
        //vm.breadcrumbs = triBreadcrumbsService.breadcrumbs;
        //vm.emailNew = false;
        vm.languages = triSettings.languages;
        vm.openSideNav = openSideNav;
        vm.hideMenuButton = hideMenuButton;
        vm.switchLanguage = switchLanguage;
        vm.toggleNotificationsSideNav = toggleNotificationsSideNav;
        vm.helpOpened = false;
        vm.isFullScreen = false;
        vm.fullScreenIcon = 'zmdi zmdi-fullscreen';
        vm.toggleFullScreen = toggleFullScreen;
        vm.toggleToolBarMenu = toggleToolBarMenu;
        vm.logout = logout;
        vm.search = search;
        vm.isActiveMenu = isActiveMenu;
        vm.hasMenuPermission = hasMenuPermission;
        vm.isFeatureActive = isFeatureActive;
        vm.getSelectecNavBarMenu = getSelectecNavBarMenu;
        vm.selectedMenu = triMenu.getMenu($state.current.name);
        vm.toolBarTitle;
        vm.isTabMenu = triMenu.isTabMenu($state.current.name);
        vm.selectedNavBarMenu = getSelectecNavBarMenu();
        vm.showToolbarFromParam = angular.isDefined($stateParams.showToolbar) ? $stateParams.showToolbar : true;
        vm.selectedTabIndex = triMenu.getIndex($state.current.name);
        vm.breadcrumbs = triBreadcrumbsService.breadcrumbs;
        vm.pictureUrl = null;
        vm.showHelp = showHelp;

        vm.sidebarInfo = {
            appName: triSettings.name,
            appLogoBig: triSettings.getLogoBig(),
            appLogoSmall: triSettings.getLogoSmall(),
            logoRight: triSettings.getSkinModel().logo_right
        };

        if (!$location.path().startsWith('/deeplink/sessions')) {
            Principal.identity().then(function (user) {
                setUserInfo(user);
            });
        }

        vm.searchText = $state.params.searchText;

        vm.permissions = {
            createContent: 'create-content'
        };

        //vm.sizes = [
        //    "All Content",
        //    "Topics",
        //    "My Uploads",
        //    "My Boards"
        //];

        //calls when hit menu on sidebar to open sub menus in side bar
        $rootScope.$on('toggleLeftHandMenu', function (event, item, open) {
            vm.selectedMenu = item;
            vm.toolBarTitle = item.name;
            if (item.type == 'tab') {
                vm.isTabMenu = true;
            } else {
                vm.isTabMenu = false;
            }
            vm.selectedNavBarMenu = getSelectecNavBarMenu();
        });

        //calls when hit menu on sidebar to open sub menus in side bar
        $rootScope.$on('toggleToolBarMenu', function (event, item) {
            vm.selectedMenu = null;
            vm.toolBarTitle = item;
            vm.isTabMenu = false;
            vm.selectedNavBarMenu = getSelectecNavBarMenu();
        });

        //calls when hit menu on sidebar to open sub menus in side bar
        $rootScope.$on('updateUserInfo', function (event, user) {
            setUserInfo(user);
        });

        function isActiveMenu(tabName) {
            return $state.includes(tabName);
        }

        function getSelectecNavBarMenu() {
            return null;
        }

        function setUserInfo(user) {
            vm.currentUser = {
                displayName: user.first_name,
                userName: user.first_name,
                avatar: 'assets/images/avatars/user-avatar.png',
                id: user.id
            };
            vm.user = user;
            vm.pictureUrl = vm.user.picture_id ? '/media/pictures/' + vm.user.picture_id : 'assets/images/avatars/user-avatar.png';
        }

        function openSideNav(navID) {
            $mdSidenav(navID).toggle();
        }

        function logout() {
            Auth.logout();
            $state.go('account.signout');
        }

        function toggleToolBarMenu(toolbarMenu) {
            $rootScope.$emit('toggleToolBarMenu', toolbarMenu);
        }

        function switchLanguage(languageCode) {
            if ($injector.has('$translate')) {
                var $translate = $injector.get('$translate');
                $translate.use(languageCode)
                    .then(function () {
                        ToastService.displayToast($filter('triTranslate')('Language Changed'), 500)
                        $rootScope.$emit('changeTitle');
                    });
            }
        }

        function hideMenuButton() {
            switch (triLayout.layout.sideMenuSize) {
                case 'hidden':
                    // always show button if menu is hidden
                    return false;
                case 'off':
                    // never show button if menu is turned off
                    return true;
                default:
                    // show the menu button when screen is mobile and menu is hidden
                    return $mdMedia('gt-sm');
            }
        }

        function toggleNotificationsSideNav(tab) {
            $rootScope.$broadcast('triSwitchNotificationTab', tab);
            vm.openSideNav('notifications');
        }

        function toggleFullScreen() {
            vm.isFullScreen = !vm.isFullScreen;
            vm.fullScreenIcon = vm.isFullScreen ? 'zmdi zmdi-fullscreen-exit' : 'zmdi zmdi-fullscreen';
            // more info here: https://developer.mozilla.org/en-US/docs/Web/API/Fullscreen_API
            var doc = $document[0];
            if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
                if (doc.documentElement.requestFullscreen) {
                    doc.documentElement.requestFullscreen();
                } else if (doc.documentElement.msRequestFullscreen) {
                    doc.documentElement.msRequestFullscreen();
                } else if (doc.documentElement.mozRequestFullScreen) {
                    doc.documentElement.mozRequestFullScreen();
                } else if (doc.documentElement.webkitRequestFullscreen) {
                    doc.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
                }
            } else {
                if (doc.exitFullscreen) {
                    doc.exitFullscreen();
                } else if (doc.msExitFullscreen) {
                    doc.msExitFullscreen();
                } else if (doc.mozCancelFullScreen) {
                    doc.mozCancelFullScreen();
                } else if (doc.webkitExitFullscreen) {
                    doc.webkitExitFullscreen();
                }
            }
        }

        //$scope.$on('newMailNotification', function(){
        //    vm.emailNew = true;
        //});
        function search() {
            vm.selectedNavBarMenu = null;
            $state.go('storm.search', {
                query: vm.searchText
            });
        }

        function hasMenuPermission(menuItem) {
            var hasPermission = true;
            if (angular.isDefined(menuItem.permission)) {
                if (menuItem.permission.constructor === Array) {
                    angular.forEach(menuItem.permission, function (permission) {
                        if (hasPermission) {
                            if (angular.isDefined(vm.user) && vm.user.permissionSet.indexOf(permission) > -1) {
                                hasPermission = true;
                            } else {
                                hasPermission = false;
                            }
                        }
                    });
                } else {
                    if (angular.isDefined(vm.user) && vm.user.permissionSet.indexOf(menuItem.permission) > -1) {
                        hasPermission = true;
                    } else {
                        hasPermission = false;
                    }
                }
            }
            return hasPermission;
        }

        function isFeatureActive(menuItem) {
            var isActive = true;
            if (angular.isDefined(menuItem.feature)) {
                if (menuItem.feature.constructor === Array) {
                    angular.forEach(menuItem.feature, function (feature) {
                        if (isActive) {
                            if (Feature.isActive(feature)) {
                                isActive = true;
                            } else {
                                isActive = false;
                            }
                        }
                    });
                } else {
                    if (Feature.isActive(menuItem.feature)) {
                        isActive = true;
                    } else {
                        isActive = false;
                    }
                }
            }
            return isActive;
        }

        function showHelp() {
            var currentState = $state.current.name;
            if (currentState.startsWith("storm.admin")) {
                return true;
            } else {
                return false;
            }
        }
    }
})();
