(function () {
    'use strict';

    angular
        .module('atheer.copilot')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('storm.copilot', {
                url: '/copilot',
                templateUrl: 'modules/copilot/copilot.tmpl.html',
                controller: 'CopilotController',
                controllerAs: 'vm',
                data: {
                    pageTitle: 'atheer.copilot.home.title',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showSideNavMenu: false,
                        sideNavMenuPosition: 'left',
                        showToolbar: false,
                        sideNavMenuSize: 15
                    }
                }
            });
    }
})();
