(function () {
    'use strict';

    angular
        .module('atheer.smartFlow')
        .directive('smartFlowBuilder', smartFlowBuilderDirective);

    /* @ngInject */
    function smartFlowBuilderDirective() {
        var directive = {
            restrict: 'E',
            require: '^smartFlowBuilder',
            replace: true,
            templateUrl: 'modules/smartflow/smartflow-builder.tmpl.html',
            scope: {
                canvasData: '=',
                triggerData: '=',
                type: '=',
                isJobFlow: '='
            },
            controller: SmartFlowBuilderController,
            controllerAs: 'vm',
            bindToController: true,
            link: function (scope, element, attrs) {
                scope.init(element, attrs);
            }
        };
        return directive;
    }

    /* @ngInject */
    function SmartFlowBuilderController($scope, SmartFlow, $mdToast, AlertService, $state, $mdDialog, SmartFlowBuilder) {
        var vm = this;

        vm.undo = function () {
            SmartFlowBuilder.undo();
        };

        vm.redo = function () {
            SmartFlowBuilder.redo();
        };

        vm.centerAndFit = function () {
            SmartFlowBuilder.centerAndFit();
        };

        $scope.init = function (element, attrs) {
            SmartFlowBuilder.init(element, vm.canvasData, vm.triggerData, vm.type, vm.isJobFlow, vm.type);
        };
    }
})();
