(function() {
    'use strict';

    angular
        .module('atheer.insights')
        .controller('InsightsDashboardsController', InsightsDashboardsController);

    /* @ngInject */
    function InsightsDashboardsController($rootScope, $scope, triTheming, triSettings, $window, $state, 
        Dashboard, ParseLinks, AlertService, pagingParams, paginationConstants, $mdDialog, $mdToast, 
        $stateParams, Feature, $filter, ToastService, workspaceId, Workspace, Principal) {
        var vm = this;

        vm.workspaceId = workspaceId;
        vm.loadingData = false;
        vm.transition = transition;
        vm.removeFilter = removeFilter;
        vm.getQueryString = getQueryString;
        vm.insightSettings = Workspace.getInsightSettings();

        vm.createObject = createObject;
        vm.editObject = editObject;
        vm.deleteObject = deleteObject;
        vm.launchDashboard = launchDashboard;
        vm.canLaunchDashboard = canLaunchDashboard;
        vm.isLegacyDashboard = isLegacyDashboard;
        vm.primaryBackgroundColor = triTheming.rgba(
            triTheming.getPaletteColor(triSettings.getSkinModel().primary_color, '500').value
        );
        vm.secondaryBackgroundColor = triTheming.rgba(
            triTheming.getPaletteColor(triSettings.getSkinModel().secondary_color, '500').value
        );

        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.reOrder = reOrder;
        vm.illustartionEmptyScreenId = "dashboard";

        vm.permissions = {
            create: 'create-dashboard',
            update: 'update-dashboard',
            delete: 'delete-dashboard',
            manage: 'manage-dashboard'
        };

        vm.query = {
            limit: paginationConstants.itemsPerPage,
            order: '-id',
            page: 1,
            filters: {
                searchText: '',
                system: true,
                createdByMe: true,
                enabled: true
            }
        };

        vm.filter = {
            show: false,
            options: {
                debounce: 500
            }
        };

        function loadAll() {
            vm.loadingData = true;
            Dashboard.query({
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: getSortOrder(),
                query: getQueryString()
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.loadingData = false;
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.dashboards = data;
                vm.page = pagingParams.page;

                processData();
            }

            function onError(error) {
                AlertService.error(error.data.message);
                vm.loadingData = false;
            }
        }

        function processData() {
            angular.forEach(vm.dashboards, function(dashboard) {
                if (dashboard.status == 'DRAFT') {
                    dashboard.active = false;
                } else {
                    dashboard.active = true;
                }
            });
        }

        function reOrder(order) {
            vm.predicate = order;
            vm.reverse = !vm.reverse;
            vm.transition();
        }

        function getSortOrder() {
            if (vm.predicate.startsWith('-')) {
                return vm.predicate.substr(1) + ',' + 'desc';
            } else {
                return vm.predicate + ',' + 'asc';
            }

        }

        function transition() {
            vm.promise = Dashboard.query({
                page: vm.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: getSortOrder(),
                query: getQueryString()
            }).$promise;

            vm.promise.then(function(data, headers) {
                vm.queryCount = vm.totalItems;
                vm.dashboards = data;
                processData();
            });
        };

        function getQueryString() {
            var query = "deleted==false";
            if(vm.query.filters.searchText.length > 0) {
                query = query + ";name=re=" + vm.query.filters.searchText;
            }

            if(vm.query.filters.system || vm.query.filters.createdByMe || vm.query.filters.enabled) {
                var typeQuery = "(";

                if(vm.query.filters.system) {
                    typeQuery = typeQuery + "type==System";
                }

                if(typeQuery.length > 1 && vm.query.filters.createdByMe) {
                    typeQuery = typeQuery + ",";
                }

                if(vm.query.filters.createdByMe) {
                    typeQuery = typeQuery + "created_by==" + Principal.getUserLogin();
                }

                if(typeQuery.length > 1 && vm.query.filters.enabled) {
                    typeQuery = typeQuery + ",";
                }

                if(vm.query.filters.enabled) {
                    typeQuery = typeQuery + "enabled==" + vm.query.filters.enabled;
                }

                typeQuery = typeQuery + ")";

                if(query.length > 0) {
                    query = query + ";" + typeQuery;
                } else {
                    return typeQuery;
                }
            }

            return query;
        }

        function removeFilter() {
            vm.filter.show = false;
            vm.query.filter = '';

            if (vm.filter.form.$dirty) {
                vm.filter.form.$setPristine();
            }
        };

        vm.getDefaultBackground = function() {
            return '#bdbec0';
        };

        vm.back = function() {
            $window.history.back();
        };

        function createObject() {
            $mdDialog.show({
                animation: true,
                templateUrl: 'modules/insights/insights-dashboard-detail-dialog.html',
                controller: 'InsightsDashboardDetailDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: function() {
                        return {
                            id: null,
                            name: null
                        };
                    }
                }
            }).then(function() {
                resetModal();
            });
        };

        function editObject(dashboardId) {
            $state.go("storm.insights.dashboard-detail", {
                'id': dashboardId
            });
        }

        function isLegacyDashboard(dashboard) {
            return (dashboard.type == 'System' && dashboard.dashboard_no < 10000);
        }

        function canLaunchDashboard(dashboard) {
            return (isLegacyDashboard(dashboard) || (dashboard.insights && dashboard.insights.length > 0));
        }

        function launchDashboard(dashboard, mode) {
            if(isLegacyDashboard(dashboard)) {
                if(dashboard.dashboard_no == 1000) {
                    $state.go("storm.insights.workspace-summary",{
                        id: dashboard.id
                    });    
                } else if(dashboard.dashboard_no == 1001) {
                    $state.go("storm.insights.conversations",{
                        id: dashboard.id
                    });    
                } else if(dashboard.dashboard_no == 1002) {
                    $state.go("storm.insights.contents",{
                        id: dashboard.id
                    });    
                } else if(dashboard.dashboard_no == 1003) {
                    $state.go("storm.insights.topics",{
                        id: dashboard.id
                    });    
                } else if(dashboard.dashboard_no == 1004) {
                    $state.go("storm.insights.sessions",{
                        id: dashboard.id
                    });    
                } else if(dashboard.dashboard_no == 1005) {
                    $state.go("storm.insights.airforms",{
                        id: dashboard.id
                    });    
                } else if(dashboard.dashboard_no == 1006) {
                    $state.go("storm.insights.users",{
                        id: dashboard.id
                    });    
                }
            } else {
                //console.log('Edit dashboard: ' + dashboard.dashboard_id + ', workspace: ' + vm.insightSettings.workspaceId + ', mode: ' + mode);
                $state.go("storm.insights.dashboard-view", {
                    'dashboardId': dashboard.dashboard_id,
                    'workspaceId': vm.insightSettings.workspaceId,
                    'id': dashboard.id,
                    'dashboardmode': mode
                });
            }
        }

        function deleteObject(entity) {
            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.insights.dashboard.delete.question'))
                .ariaLabel($filter('translate')('atheer.insights.dashboard.delete.question'))
                .ok($filter('translate')('entity.action.delete'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function() {
                Dashboard.delete({
                    id: entity.id
                }, function() {
                    resetModal();
                },function(error) {
                    var errorMessage;
                    if(error.data.description && error.data.description != '') {
                        errorMessage = error.data.description;
                    } else {
                        errorMessage = $filter('translate')('atheer.insights.dashboard.errorDeletingDashboard');
                    }
                    var errorWindow = $mdDialog.alert()
                        .title(errorMessage)
                        .ok('Close');
                    $mdDialog.show(errorWindow);
                });
            });
        };

        function resetModal() {
            loadAll();
            AlertService.displayToast($mdToast);
        };

        var refreshDashboardListener = $rootScope.$on('refreshDashboards', function(event, filter) {
            if(filter.hasOwnProperty("searchText")) {
                vm.query.filters.searchText = filter.searchText;
            }

            if(filter.hasOwnProperty("system")) {
                vm.query.filters.system = filter.system;
            }

            if(filter.hasOwnProperty("createdByMe")) {
                vm.query.filters.createdByMe = filter.createdByMe;
            }

            if(filter.hasOwnProperty("enabled")) {
                vm.query.filters.enabled = filter.enabled;
            }
            
            loadAll();
        });

        // Unregister
        $scope.$on('$destroy', function() {
            refreshDashboardListener();
        });

        loadAll();
    }
})();
