(function() {
    'use strict';

    angular
        .module('atheer.cloud')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('storm.cloud-admin.job-streams', {
            url: '/jobs?page&sort&search',
            templateUrl: 'modules/cloud/job-stream/job-streams.html',
            controller: 'JobStreamController',
            controllerAs: 'vm',
            data: {
                pageTitle: 'atheer.jobStream.home.title',
                layout: {
                    contentClass: 'layout-column',
                    footer: false,
                    showToolbar: false,
                    showSideNavMenu: true,
                }
            },

            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }]
            }
        });
    }
})();
