(function() {

    angular
        .module('atheer.services')
        .service('CloudUpload', CloudUpload);

    /* @ngInject */
    function CloudUpload ($window) {
    	var fileStackConfig = JSON.parse(window.localStorage.getItem('_filestack'));
    	print(0, fileStackConfig);
    	fileStackConfig = sanitize(fileStackConfig)
    	print(1,fileStackConfig);
    	var filestackPicker = null;
    	try{
			
			if(fileStackConfig.domain == null){
				filestackPicker = filestack.init(fileStackConfig.apiKey);
				print(2, filestackPicker);
				return filestackPicker;
			}
			/*
				Right now copied the security options from filestack api doc.
				May need to get it from filestack team
			*/
			var security = {};

			var cname = {"cname": fileStackConfig.domain };
			// pre 1.0
			filestackPicker = filestack.init(fileStackConfig.apiKey, cname);
			print(3, filestackPicker);
			return filestackPicker;

    	}catch(e){
    		console.error('Error creating filestack with configuration =',fileStackConfig);
    		filestackPicker =  filestack.init(fileStackConfig.apiKey);
    		print(4, filestackPicker);
			return filestackPicker;
    	}

    	function sanitize(fileStackConfig){
    		var defaultFSConfig = {
				"domain":'filestack.atheer.dev',
				"apiKey":'AH9iFHtFCT4WGuoRCgbNBz'
			};
			if(fileStackConfig == null){
				return defaultFSConfig;
			}
			if(_.isUndefined(fileStackConfig.domain) || fileStackConfig.domain == ","){
				return defaultFSConfig;
			}
    		return fileStackConfig;
    	}
    	
    	function print(count, filestackPicker){
    		console.log(count,filestackPicker);
    	}
    	
    }


})();
