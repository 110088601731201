/******************************************************************************************/
/** For any change in default setting update smartflow-builder-cell-settings.constant.js **
/******************************************************************************************/
(function (joint) {

    'use strict';

    joint.shapes.atheer = joint.shapes.atheer || {};

    joint.shapes.atheer = joint.shapes.atheer || {};

    joint.shapes.smartflow = joint.shapes.smartflow || {};

    joint.shapes.atheer.Base = joint.shapes.basic.Generic.extend({
        markup: '<g class="rotatable"><g class="scalable"><image/></g><text/></g>',
        defaults: joint.util.deepSupplement({
            type: 'atheer.Base',
            size: {
                width: 50,
                height: 50
            },
            attrs: {
                image: {
                    width: 45,
                    height: 45
                },
                text: {
                    text: '',
                    'text-anchor': 'middle',
                    'ref-x': .5,
                    'ref-dy': 15,
                    'font-family': 'RobotoDraft, Roboto, Helvetica Neue, sans-serif',
                    'font-weight': 'Normal',
                    'font-size': 14,
                    display: '',
                    stroke: '#000',
                    'stroke-width': 0,
                    'fill': '#222138'
                }
            }
        }, joint.shapes.basic.Generic.prototype.defaults),
        updateSetting: function (setting) {
            //do nothing
        },
        validate: function () {
            //do nothing
        },
        updateTitle: function (title) {
            var wrapText = joint.util.breakText(title, {
                width: 100
            });
            var lines = wrapText.split(/\r\n|\r|\n/).length;
            this.attr('text', { text: wrapText, 'data-tooltip': title });
        }
    });

    joint.shapes.atheer.Trigger = joint.shapes.atheer.Base.extend({
        defaults: joint.util.deepSupplement({}, joint.shapes.atheer.Base.defaults)
    });

    joint.shapes.atheer.Activity = joint.shapes.atheer.Base.extend({
        defaults: joint.util.deepSupplement({}, joint.shapes.atheer.Base.defaults)
    });

    joint.shapes.atheer.Condition = joint.shapes.atheer.Base.extend({
        defaults: joint.util.deepSupplement({}, joint.shapes.atheer.Base.defaults)
    });

    joint.shapes.atheer.Flow = joint.shapes.atheer.Base.extend({
        defaults: joint.util.deepSupplement({}, joint.shapes.atheer.Base.defaults)
    });

    joint.shapes.atheer.TeamTrigger = joint.shapes.atheer.Trigger.extend({
        defaults: joint.util.deepSupplement({
            type: 'atheer.TeamTrigger',
            typeId: 'audienceTrigger',
            pageId: 'audience-trigger',
            attrs: {
                image: {
                    'xlink:href': 'assets/images/smartflow/audience.svg'
                },
                text: {
                    text: 'Team'
                }
            },
            active: true,
            setting: {
                isConfigured: false,
                myTeam: false,
                data: [],
                title: undefined,
                objectType: 'Team'
            },
            slices: [{
                color: '#80C3D9'
            }]
        }, joint.shapes.atheer.Base.prototype.defaults),
        updateSetting: function (setting) {
            this.prop('setting', setting);
            var title = this.prop('setting/title');
            if (title) {
                this.updateTitle(title);
                setting.isConfigured = true;
            } else {
                this.updateTitle('Team');
            }
        },
        validate: function () {
            var setting = this.prop('setting');
            if (setting.data.length == 0) {
                return false;
            } else {
                return true;
            }
        },
    });

    joint.shapes.atheer.TeamAssign = joint.shapes.atheer.Activity.extend({
        defaults: joint.util.deepSupplement({
            type: 'atheer.TeamAssign',
            typeId: 'audienceAssign',
            pageId: 'audience-assign',
            attrs: {
                image: {
                    'xlink:href': 'assets/images/smartflow/assign-audience.svg'
                },
                text: {
                    text: 'Assign Team'
                }
            },
            active: true,
            setting: {
                isConfigured: false,
                data: [],
                title: undefined,
                objectType: 'Team'
            },
            slices: [{
                color: '#80C3D9',
                title: 'On Assign',
                typeId: 'branch',
                value: 'onAssign'
            }]
        }, joint.shapes.atheer.Base.prototype.defaults),
        updateSetting: function (setting) {
            this.prop('setting', setting);
            var title = this.prop('setting/title');
            if (title) {
                this.updateTitle(title);
                setting.isConfigured = true;
            } else {
                this.updateTitle('Assign Team');
            }
        },
        validate: function () {
            var setting = this.prop('setting');
            if (setting.data.length == 0) {
                return false;
            } else {
                return true;
            }
        },
    });

    joint.shapes.atheer.TeamRemove = joint.shapes.atheer.Activity.extend({
        defaults: joint.util.deepSupplement({
            type: 'atheer.TeamRemove',
            typeId: 'audienceRemove',
            pageId: 'audience-remove',
            attrs: {
                image: {
                    'xlink:href': 'assets/images/smartflow/remove-audience.svg'
                },
                text: {
                    text: 'Remove Team'
                }
            },
            active: true,
            setting: {
                isConfigured: false,
                data: [],
                title: undefined,
                objectType: 'Team'
            },
            slices: [{
                color: '#80C3D9',
                title: 'On Remove',
                typeId: 'branch',
                value: 'onRemove'
            }]
        }, joint.shapes.atheer.Base.prototype.defaults),
        updateSetting: function (setting) {
            this.prop('setting', setting);
            var title = this.prop('setting/title');
            if (title) {
                this.updateTitle(title);
                setting.isConfigured = true;
            } else {
                this.updateTitle('Remove Team');
            }
        },
        validate: function () {
            var setting = this.prop('setting');
            if (setting.data.length == 0) {
                return false;
            } else {
                return true;
            }
        },
    });

    joint.shapes.atheer.TeamCheck = joint.shapes.atheer.Condition.extend({
        defaults: joint.util.deepSupplement({
            type: 'atheer.TeamCheck',
            typeId: 'audienceCheck',
            pageId: 'audience-check',
            attrs: {
                image: {
                    'xlink:href': 'assets/images/smartflow/audience-check.svg'
                },
                text: {
                    text: 'Team'
                }
            },
            active: true,
            setting: {
                isConfigured: false,
                data: [],
                title: undefined,
                objectType: 'Team'
            },
            slices: [{
                color: '#80C3D9',
                title: 'YES',
                typeId: 'condition',
                value: 'yes'
            }, {
                color: '#96D186',
                title: 'NO',
                typeId: 'condition',
                value: 'no'
            }]
        }, joint.shapes.atheer.Base.prototype.defaults),
        updateSetting: function (setting) {
            this.prop('setting', setting);
            var title = this.prop('setting/title');
            if (title) {
                this.updateTitle(title);
                setting.isConfigured = true;
            } else {
                this.updateTitle('Team');
            }
        },
        validate: function () {
            var setting = this.prop('setting');
            if (!setting.data) {
                return false;
            } else {
                return true;
            }
        },
    });

    joint.shapes.atheer.UserFieldTrigger = joint.shapes.atheer.Trigger.extend({
        defaults: joint.util.deepSupplement({
            type: 'atheer.UserFieldTrigger',
            typeId: 'userFieldTrigger',
            pageId: 'user-field-trigger',
            attrs: {
                image: {
                    'xlink:href': 'assets/images/smartflow/user-field-trigger.svg'
                },
                text: {
                    text: 'User Profile Field'
                }
            },
            active: true,
            setting: {
                isConfigured: false
            },
            slices: [{
                color: '#80C3D9'
            }]
        }, joint.shapes.atheer.Base.prototype.defaults),
        updateSetting: function (setting) {
            setting.isConfigured = true;

            //fix the json
            var criteria = angular.toJson(setting.criteria);
            setting.criteria = criteria;

            this.prop('setting', setting);
            var title = this.prop('setting/title');
            if (title) {
                this.updateTitle(title);
            } else {
                this.updateTitle('User Profile Field');
            }
        },
        validate: function () {
            var setting = this.prop('setting');
            if (!setting.criteria) {
                return false;
            } else {
                return true;
            }
        },
    });

    joint.shapes.atheer.UserFieldCheck = joint.shapes.atheer.Condition.extend({
        defaults: joint.util.deepSupplement({
            type: 'atheer.UserFieldCheck',
            typeId: 'userFieldCheck',
            pageId: 'user-field-check',
            attrs: {
                image: {
                    'xlink:href': 'assets/images/smartflow/user-field-check.svg'
                },
                text: {
                    text: 'User Profile'
                }
            },
            active: true,
            setting: {
                isConfigured: false
            },
            slices: [{
                color: '#80C3D9',
                title: 'YES',
                typeId: 'condition',
                value: 'yes'
            }, {
                color: '#96D186',
                title: 'NO',
                typeId: 'condition',
                value: 'no'
            }]
        }, joint.shapes.atheer.Base.prototype.defaults),
        updateSetting: function (setting) {
            setting.isConfigured = true;

            //fix the json
            var criteria = angular.toJson(setting.criteria);
            setting.criteria = criteria;

            this.prop('setting', setting);
            var title = this.prop('setting/title');
            if (title) {
                this.updateTitle(title);
            } else {
                this.updateTitle('User Profile');
            }
        },
        validate: function () {
            var setting = this.prop('setting');
            if (!setting.criteria) {
                return false;
            } else {
                return true;
            }
        },
    });

    joint.shapes.atheer.AnnouncementAssign = joint.shapes.atheer.Activity.extend({
        defaults: joint.util.deepSupplement({
            type: 'atheer.AnnouncementAssign',
            typeId: 'announcementAssign',
            pageId: 'announcement-assign',
            attrs: {
                image: {
                    'xlink:href': 'assets/images/smartflow/announcement.svg'
                },
                text: {
                    text: 'Announcement'
                }
            },
            active: true,
            setting: {
                isConfigured: false,
                absoluteDate: moment().add(5, 'd').toDate(),
                relativeDate: {
                    period: 'days',
                    value: 5
                },
                isDueDateRelative: 1,
                data: [],
                title: undefined,
                objectType: 'Content'
            },
            slices: [{
                color: '#80C3D9',
                title: 'On Assign',
                typeId: 'branch',
                value: 'onAssign'
            }, {
                color: '#96D186',
                title: 'On Dismiss',
                typeId: 'branch',
                value: 'onCompletion'
            }]
        }, joint.shapes.atheer.Base.prototype.defaults),
        updateSetting: function (setting) {
            this.prop('setting', setting);
            var title = this.prop('setting/title');
            if (title) {
                this.updateTitle(title);
                setting.isConfigured = true;
            } else {
                this.updateTitle('Announcement');
            }
        },
        validate: function () {
            var setting = this.prop('setting');
            if (setting.data.length == 0) {
                return false;
            }
            if (setting.isDueDateRelative == 0) {
                if (!setting.absoluteDate) {
                    return false;
                }
            } else {
                if (setting.relativeDate.value == 0) {
                    return false;
                }
            }
            return true;
        },
    });

    joint.shapes.atheer.ContentAssign = joint.shapes.atheer.Activity.extend({
        defaults: joint.util.deepSupplement({
            type: 'atheer.ContentAssign',
            typeId: 'contentAssign',
            pageId: 'content-assign',
            attrs: {
                image: {
                    'xlink:href': 'assets/images/smartflow/assign.svg'
                },
                text: {
                    text: 'Assign'
                }
            },
            active: true,
            setting: {
                isConfigured: false,
                required: false,
                absoluteDate: moment().add(5, 'd').toDate(),
                relativeDate: {
                    period: 'days',
                    value: 5
                },
                isDueDateRelative: 1,
                data: [],
                title: undefined,
                note: null,
                objectType: 'Content'
            },
            slices: [{
                color: '#80C3D9',
                title: 'On Assign',
                typeId: 'branch',
                value: 'onAssign'
            }, {
                color: '#F4A787',
                title: 'On Completion',
                typeId: 'branch',
                value: 'onCompletion'
            }]
        }, joint.shapes.atheer.Base.prototype.defaults),
        updateSetting: function (setting) {
            this.prop('setting', setting);
            var title = this.prop('setting/title');
            if (title) {
                this.updateTitle(title);
                setting.isConfigured = true;
            } else {
                this.updateTitle('Assign Content');
            }
        },
        validate: function () {
            var setting = this.prop('setting');
            if (setting.data.length == 0) {
                return false;
            }
            if (setting.isDueDateRelative == 0) {
                if (!setting.absoluteDate) {
                    return false;
                }
            } else {
                if (setting.relativeDate.value == 0) {
                    return false;
                }
            }
            return true;
        },
    });

    joint.shapes.atheer.ContentRemove = joint.shapes.atheer.Activity.extend({
        defaults: joint.util.deepSupplement({
            type: 'atheer.ContentRemove',
            typeId: 'contentRemove',
            pageId: 'content-remove',
            attrs: {
                image: {
                    'xlink:href': 'assets/images/smartflow/remove-content.svg'
                },
                text: {
                    text: 'Remove Assignment'
                }
            },
            active: true,
            setting: {
                isConfigured: false,
                data: [],
                title: undefined,
                objectType: 'Content'
            },
            slices: [{
                color: '#80C3D9',
                title: 'On Remove',
                typeId: 'branch',
                value: 'onRemove'
            }]
        }, joint.shapes.atheer.Base.prototype.defaults),
        updateSetting: function (setting) {
            this.prop('setting', setting);
            var title = this.prop('setting/title');
            if (title) {
                this.updateTitle(title);
                setting.isConfigured = true;
            } else {
                this.updateTitle('Remove Assignment');
            }
        },
        validate: function () {
            var setting = this.prop('setting');
            if (setting.data.length == 0) {
                return false;
            } else {
                return true;
            }
        },
    });

    joint.shapes.atheer.ContentRecommend = joint.shapes.atheer.Activity.extend({
        defaults: joint.util.deepSupplement({
            type: 'atheer.ContentRecommend',
            typeId: 'contentRecommend',
            pageId: 'content-recommend',
            attrs: {
                image: {
                    'xlink:href': 'assets/images/smartflow/recommend.svg'
                },
                text: {
                    text: 'Recommend'
                }
            },
            active: true,
            setting: {
                isConfigured: false,
                data: [],
                title: undefined,
                note: null,
                objectType: 'Content'
            },
            slices: [{
                color: '#80C3D9',
                title: 'On Recommmend',
                typeId: 'branch',
                value: 'onRecommend'
            }, {
                color: '#96D186',
                title: 'On View',
                typeId: 'branch',
                value: 'onView'
            }]
        }, joint.shapes.atheer.Base.prototype.defaults),
        updateSetting: function (setting) {
            this.prop('setting', setting);
            var title = this.prop('setting/title');
            if (title) {
                this.updateTitle(title);
                setting.isConfigured = true;
            } else {
                this.updateTitle('Recommmend Content');
            }
        },
        validate: function () {
            var setting = this.prop('setting');
            if (setting.data.length == 0) {
                return false;
            } else {
                return true;
            }
        },
    });

    joint.shapes.atheer.ContentCheck = joint.shapes.atheer.Condition.extend({
        defaults: joint.util.deepSupplement({
            type: 'atheer.ContentCheck',
            typeId: 'contentCheck',
            pageId: 'content-check',
            attrs: {
                image: {
                    'xlink:href': 'assets/images/smartflow/assignment-check.svg'
                },
                text: {
                    text: 'Assignment'
                }
            },
            active: true,
            setting: {
                isConfigured: false,
                data: [],
                title: undefined,
                objectType: 'Content',
                status: 'COMPLETED'
            },
            slices: [{
                color: '#80C3D9',
                title: 'YES',
                typeId: 'condition',
                value: 'yes'
            }, {
                color: '#96D186',
                title: 'NO',
                typeId: 'condition',
                value: 'no'
            }]
        }, joint.shapes.atheer.Base.prototype.defaults),
        updateSetting: function (setting) {
            this.prop('setting', setting);
            var title = this.prop('setting/title');
            if (title) {
                var title = title;
                if (setting.status == 'ASSIGNED') {
                    title = title + ' Is ' + 'Assigned';
                } else if (setting.status == 'IN_PROGRESS') {
                    title = title + ' Is ' + 'In Progress';
                } else if (setting.status == 'COMPLETED') {
                    title = title + ' Is ' + 'Completed';
                }
                this.updateTitle(title);
                setting.isConfigured = true;
            } else {
                this.updateTitle('Assignment');
            }
        },
        validate: function () {
            var setting = this.prop('setting');
            if (!setting.data) {
                return false;
            } else {
                return true;
            }
        },
    });

    joint.shapes.atheer.QuestionCheck = joint.shapes.atheer.Condition.extend({
        defaults: joint.util.deepSupplement({
            type: 'atheer.QuestionCheck',
            typeId: 'questionCheck',
            pageId: 'question-check',
            attrs: {
                image: {
                    'xlink:href': 'assets/images/smartflow/question-check.svg'
                },
                text: {
                    text: 'Answer'
                }
            },
            active: true,
            setting: {
                isConfigured: false,
                data: [],
                title: undefined,
                status: 'COMPLETED',
                pass: true,
                objectType: 'Content'
            },
            slices: [{
                color: '#80C3D9',
                title: 'TRUE',
                typeId: 'condition',
                value: 'yes'
            }, {
                color: '#96D186',
                title: 'FALSE',
                typeId: 'condition',
                value: 'no'
            }]
        }, joint.shapes.atheer.Base.prototype.defaults),
        updateSetting: function (setting) {
            this.prop('setting', setting);
            var title = this.prop('setting/title');
            if (title) {
                if (setting.status == 'ASSIGNED') {
                    title = title + ' Is ' + 'Assigned';
                } else if (setting.status == 'IN_PROGRESS') {
                    title = title + ' Is ' + 'In Progress';
                } else if (setting.status == 'COMPLETED') {
                    if (setting.pass) {
                        title = title + ' Is ' + 'Correct';
                    } else {
                        title = title + ' Is ' + 'Incorrect';
                    }
                }
                this.updateTitle(title);
                setting.isConfigured = true;
            } else {
                this.updateTitle('Answer');
            }
        },
        validate: function () {
            var setting = this.prop('setting');
            if (setting.data.length == 0) {
                return false;
            } else {
                return true;
            }
        },
    });

    joint.shapes.atheer.BadgeAssign = joint.shapes.atheer.Activity.extend({
        defaults: joint.util.deepSupplement({
            type: 'atheer.BadgeAssign',
            typeId: 'badgeAssign',
            pageId: 'badge-assign',
            attrs: {
                image: {
                    width: 45,
                    height: 45,
                    'xlink:href': 'assets/images/smartflow/assign-badge.svg'
                },
                text: {
                    text: 'Assign Badge'
                }
            },
            active: true,
            setting: {
                isConfigured: false,
                data: [],
                title: undefined,
                objectType: 'Badge'
            },
            slices: [{
                color: '#80C3D9',
                title: 'On Assign',
                typeId: 'branch',
                value: 'onAssign'
            }]
        }, joint.shapes.atheer.Base.prototype.defaults),
        updateSetting: function (setting) {
            this.prop('setting', setting);
            var title = this.prop('setting/title');
            if (title) {
                this.updateTitle(title);
                setting.isConfigured = true;
            } else {
                this.updateTitle('Assign Badge');
            }
        },
        validate: function () {
            var setting = this.prop('setting');
            if (setting.data.length == 0) {
                return false;
            } else {
                return true;
            }
        },
    });

    joint.shapes.atheer.BadgeRemove = joint.shapes.atheer.Activity.extend({
        defaults: joint.util.deepSupplement({
            type: 'atheer.BadgeRemove',
            typeId: 'badgeRemove',
            pageId: 'badge-remove',
            attrs: {
                image: {
                    'xlink:href': 'assets/images/smartflow/remove-badge.svg'
                },
                text: {
                    text: 'Remove Badge'
                }
            },
            active: true,
            setting: {
                isConfigured: false,
                data: [],
                title: undefined,
                objectType: 'Badge'
            },
            slices: [{
                color: '#80C3D9',
                title: 'On Remove',
                typeId: 'branch',
                value: 'onRemove'
            }]
        }, joint.shapes.atheer.Base.prototype.defaults),
        updateSetting: function (setting) {
            this.prop('setting', setting);
            var title = this.prop('setting/title');
            if (title) {
                this.updateTitle(title);
                setting.isConfigured = true;
            } else {
                this.updateTitle('Remove Badge');
            }
        },
        validate: function () {
            var setting = this.prop('setting');
            if (setting.data.length == 0) {
                return false;
            } else {
                return true;
            }
        },
    });

    joint.shapes.atheer.SecurityRoleAssign = joint.shapes.atheer.Activity.extend({
        defaults: joint.util.deepSupplement({
            type: 'atheer.SecurityRoleAssign',
            typeId: 'securityRoleAssign',
            pageId: 'securityrole-assign',
            attrs: {
                image: {
                    'xlink:href': 'assets/images/smartflow/assign-securityrole.svg'
                },
                text: {
                    text: 'Assign Sec. Role'
                }
            },
            active: true,
            setting: {
                isConfigured: false,
                data: [],
                title: undefined,
                objectType: 'SecurityRole'
            },
            slices: [{
                color: '#80C3D9',
                title: 'On Assign',
                typeId: 'branch',
                value: 'onAssign'
            }]
        }, joint.shapes.atheer.Base.prototype.defaults),
        updateSetting: function (setting) {
            this.prop('setting', setting);
            var title = this.prop('setting/title');
            if (title) {
                this.updateTitle(title);
                setting.isConfigured = true;
            } else {
                this.updateTitle('Assign Security Role');
            }
        },
        validate: function () {
            var setting = this.prop('setting');
            if (setting.data.length == 0) {
                return false;
            }
            else {
                return true;
            }
        },
    });

    joint.shapes.atheer.SecurityRoleRemove = joint.shapes.atheer.Activity.extend({
        defaults: joint.util.deepSupplement({
            type: 'atheer.SecurityRoleRemove',
            typeId: 'securityRoleRemove',
            pageId: 'securityrole-remove',
            attrs: {
                image: {
                    'xlink:href': 'assets/images/smartflow/remove-securityrole.svg'
                },
                text: {
                    text: 'Remove Sec. Role'
                }
            },
            active: true,
            setting: {
                isConfigured: false,
                data: [],
                title: undefined,
                objectType: 'SecurityRole'
            },
            slices: [{
                color: '#80C3D9',
                title: 'On Remove',
                typeId: 'branch',
                value: 'onRemove'
            }]
        }, joint.shapes.atheer.Base.prototype.defaults),
        updateSetting: function (setting) {
            this.prop('setting', setting);
            var title = this.prop('setting/title');
            if (title) {
                this.updateTitle(title);
                setting.isConfigured = true;
            } else {
                this.updateTitle('Remove Security Role');
            }
        },
        validate: function () {
            var setting = this.prop('setting');
            if (!setting.data.length == 0) {
                return false;
            } else {
                return true;
            }
        },
    });

    joint.shapes.atheer.EmailSend = joint.shapes.atheer.Activity.extend({
        defaults: joint.util.deepSupplement({
            type: 'atheer.EmailSend',
            typeId: 'emailSend',
            pageId: 'email-send',
            attrs: {
                image: {
                    'xlink:href': 'assets/images/smartflow/email.svg'
                },
                text: {
                    text: 'Newsletter'
                }
            },
            active: true,
            setting: {
                isConfigured: false,
                data: [],
                title: undefined,
                objectType: 'Content'
            },
            slices: [{
                color: '#80C3D9',
                title: 'On Send',
                typeId: 'branch',
                value: 'onSend'
            }]
        }, joint.shapes.atheer.Base.prototype.defaults),
        updateSetting: function (setting) {
            this.prop('setting', setting);
            var title = this.prop('setting/title');
            if (title) {
                this.updateTitle(title);
                setting.isConfigured = true;
            } else {
                this.updateTitle('E-Mail/Newsletter');
            }
        },
        validate: function () {
            var setting = this.prop('setting');
            if (!setting.data) {
                return false;
            } else {
                return true;
            }
        },
    });

    joint.shapes.atheer.SlackMessageSend = joint.shapes.atheer.Activity.extend({
        defaults: joint.util.deepSupplement({
            type: 'atheer.SlackMessageSend',
            typeId: 'slackMessageSend',
            pageId: 'slack-message-send',
            attrs: {
                image: {
                    'xlink:href': 'assets/images/smartflow/slack-message.svg'
                },
                text: {
                    text: 'Slack Message'
                }
            },
            active: false,
            setting: {
                isConfigured: false,
                title: null,
                message: null
            },
            slices: [{
                color: '#80C3D9',
                title: 'On Send',
                typeId: 'branch',
                value: 'onSend'
            }]
        }, joint.shapes.atheer.Base.prototype.defaults),
        updateSetting: function (setting) {
            this.prop('setting', setting);
            var title = this.prop('setting/title');
            if (title) {
                this.updateTitle(title);
                setting.isConfigured = true;
            } else {
                this.updateTitle('Slack Message');
            }
        },
        validate: function () {
            var setting = this.prop('setting');
            if (!setting.message) {
                return false;
            } else {
                return true;
            }
        },
    });

    joint.shapes.atheer.PushNotificationSend = joint.shapes.atheer.Activity.extend({
        defaults: joint.util.deepSupplement({
            type: 'atheer.PushNotificationSend',
            typeId: 'pushNotificationSend',
            pageId: 'push-notification-send',
            attrs: {
                image: {
                    'xlink:href': 'assets/images/smartflow/push-notification.svg'
                },
                text: {
                    text: 'Push Notification'
                }
            },
            active: true,
            setting: {
                isConfigured: false,
                title: null,
                message: null
            },
            slices: [{
                color: '#80C3D9',
                title: 'On Send',
                typeId: 'branch',
                value: 'onSend'
            }]
        }, joint.shapes.atheer.Base.prototype.defaults),
        updateSetting: function (setting) {
            this.prop('setting', setting);
            var title = this.prop('setting/title');
            if (title) {
                this.updateTitle(title);
                setting.isConfigured = true;
            } else {
                this.updateTitle('Push Notification');
            }
        },
        validate: function () {
            var setting = this.prop('setting');
            if (!setting.message) {
                return false;
            } else {
                return true;
            }
        },
    });

    joint.shapes.atheer.Wait = joint.shapes.atheer.Flow.extend({
        defaults: joint.util.deepSupplement({
            type: 'atheer.Wait',
            typeId: 'wait',
            pageId: 'wait',
            attrs: {
                image: {
                    'xlink:href': 'assets/images/smartflow/wait.svg'
                },
                text: {
                    text: 'Wait'
                }
            },
            active: true,
            setting: {
                isConfigured: false,
                period: 'hrs',
                value: 0
            },
            slices: [{
                color: '#80C3D9',
                title: 'On Wait',
                typeId: 'branch',
                value: 'onWait'
            }]
        }, joint.shapes.atheer.Base.prototype.defaults),
        updateSetting: function (setting) {
            this.prop('setting', setting);
            var value = this.prop('setting/value');
            var period = this.prop('setting/period');
            if (value != 0) {
                this.updateTitle(value + ' ' + period);
                setting.isConfigured = true;
            } else {
                this.updateTitle('Wait');
            }
        },
        validate: function () {
            var setting = this.prop('setting');
            if (setting.value == 0) {
                return false;
            } else {
                return true;
            }
        },
    });

    joint.shapes.atheer.End = joint.shapes.atheer.Flow.extend({
        defaults: joint.util.deepSupplement({
            type: 'atheer.End',
            typeId: 'end',
            pageId: 'end',
            attrs: {
                image: {
                    'xlink:href': 'assets/images/smartflow/end.svg'
                },
                text: {
                    text: 'End'
                }
            },
            setting: {
                isConfigured: false
            },
            active: true
        }, joint.shapes.atheer.Base.prototype.defaults),
        updateSetting: function (setting) {
            //do nothing
        },
        validate: function () {
            return true;
        },
    });
})(joint);
