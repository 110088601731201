(function() {
    'use strict';

    angular
        .module('atheer.session')
        .config(moduleConfig);

    /* @ngInject */
    function moduleConfig($stateProvider, triMenuProvider) {

        $stateProvider
            .state('storm.sessions', {
                url: '/sessions',
                abstract: true,
                templateUrl: 'modules/session/session.tmpl.html',
                controller: 'SessionController',
                controllerAs: 'vm',
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: true
                    }
                }
            })
            .state('storm.sessions.player', {
                url: '/player/:session_no',
                templateUrl: 'modules/session/session-player.tmpl.html',
                controller: 'SessionPlayerController',
                controllerAs: 'vm',
                data: {
                    pageTitle: 'atheer.session.home.live',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showSideNavMenu: false,
                        showToolbar: false,
                        sideMenuSize: 'hidden'
                    }
                },
                resolve: {
                    session_no: function($stateParams) {
                        return $stateParams.session_no;
                    }
                }
            })
            .state('storm.sessions.upcoming', {
                url: '/upcoming',
                views: {
                    '@storm': {
                        templateUrl: 'modules/session/sessions-upcoming.tmpl.html',
                        controller: 'SessionsUpcomingController',
                        controllerAs: 'vm',
                    },
                    'toolbar@storm': {
                        templateUrl: 'modules/session/sessions-upcoming-toolbar.tmpl.html',
                        controller: 'SessionsUpcomingToolbarController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    pageTitle: 'atheer.session.home.upcoming',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showSideNavMenu: false,
                        showToolbar: false,
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'active_session,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }]
                }
            })
            .state('storm.sessions.upcoming-detail', {
                url: '/upcoming/:id',
                views: {
                    '@storm': {
                        templateUrl: 'modules/session/session-detail.tmpl.html',
                        controller: 'SessionDetailController',
                        controllerAs: 'vm',
                    },
                    'toolbar@storm': {
                        templateUrl: 'modules/session/session-detail-toolbar.tmpl.html',
                        controller: 'SessionsDetailToolbarController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    pageTitle: 'atheer.session.home.template',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showSideNavMenu: false,
                        showToolbar: false,
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    id: function($stateParams) {
                        return $stateParams.id;
                    },
                    sessionType: function($stateParams) {
                        return 'SCHEDULED';
                    },
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }]
                }
            })
            .state('storm.sessions.past', {
                url: '/past',
                views: {
                    '@storm': {
                        templateUrl: 'modules/session/sessions-past.tmpl.html',
                        controller: 'SessionsPastController',
                        controllerAs: 'vm',
                    },
                    'toolbar@storm': {
                        templateUrl: 'modules/session/sessions-past-toolbar.tmpl.html',
                        controller: 'SessionsPastToolbarController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    pageTitle: 'atheer.session.home.past',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showSideNavMenu: false,
                        showToolbar: false,
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }]
                }
            })
            .state('storm.sessions.past-recording', {
                url: '/past/:session_no',
                views: {
                    '@storm': {
                        templateUrl: 'modules/session/sessions-past.tmpl.html',
                        controller: 'SessionsPastController',
                        controllerAs: 'vm',
                    },
                    'toolbar@storm': {
                        templateUrl: 'modules/session/sessions-past-toolbar.tmpl.html',
                        controller: 'SessionsPastToolbarController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    pageTitle: 'atheer.session.home.past',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showSideNavMenu: false,
                        showToolbar: false,
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }]
                }
            })
            .state('storm.sessions.personal', {
                url: '/personal',
                views: {
                    '@storm': {
                        templateUrl: 'modules/session/session-detail.tmpl.html',
                        controller: 'SessionDetailController',
                        controllerAs: 'vm',
                    },
                    'toolbar@storm': {
                        templateUrl: 'modules/session/session-detail-toolbar.tmpl.html',
                        controller: 'SessionsDetailToolbarController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    pageTitle: 'atheer.session.home.personal',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showSideNavMenu: false,
                        showToolbar: false,
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    id: function($stateParams) {
                        return $stateParams.id;
                    },
                    sessionType: function($stateParams) {
                        return 'PERSONAL';
                    },
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }]
                }
            })
            .state('storm.sessions.all', {
                url: '/all',
                views: {
                    '@storm': {
                        templateUrl: 'modules/session/sessions-all.tmpl.html',
                        controller: 'SessionsAllController',
                        controllerAs: 'vm',
                    },
                    'toolbar@storm': {
                        templateUrl: 'modules/session/sessions-all-toolbar.tmpl.html',
                        controller: 'SessionsAllToolbarController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    pageTitle: 'atheer.session.home.all',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showSideNavMenu: false,
                        showToolbar: false,
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }]
                }
            })
            .state('storm.sessions.templates', {
                url: '/templates',
                views: {
                    '@storm': {
                        templateUrl: 'modules/session/session-templates.tmpl.html',
                        controller: 'SessionTemplatesContoller',
                        controllerAs: 'vm',
                    },
                    'toolbar@storm': {
                        templateUrl: 'modules/session/session-templates-toolbar.tmpl.html',
                        controller: 'SessionTemplatesToolbarController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    pageTitle: 'atheer.session.home.template',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showSideNavMenu: false,
                        showToolbar: false,
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }]
                }
            })
            .state('storm.sessions.template-detail', {
                url: '/templates/:id',
                views: {
                    '@storm': {
                        templateUrl: 'modules/session/session-detail.tmpl.html',
                        controller: 'SessionDetailController',
                        controllerAs: 'vm',
                    },
                    'toolbar@storm': {
                        templateUrl: 'modules/session/session-detail-toolbar.tmpl.html',
                        controller: 'SessionsDetailToolbarController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    pageTitle: 'atheer.session.home.template',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showSideNavMenu: true,
                        showToolbar: false,
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    id: function($stateParams) {
                        return $stateParams.id;
                    },
                    sessionType: function($stateParams) {
                        return 'TEMPLATE';
                    },
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }]
                }
            });
    }
})();
