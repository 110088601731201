(function() {
    'use strict';
    angular
        .module('atheer.core')
        .filter('fpToDate', fpToDate);

    function fpToDate() {
        return function(input) {
            if (input && !isNaN(Date.parse(input))) {
                return new Date(input);
            } else {
                return new Date();
            }

        }
    }
})();
