(function() {
    'use strict';
    angular
        .module('atheer.services')
        .factory('SmartFlow', SmartFlow);

    /* @ngInject */
    function SmartFlow($resource, DateUtils) {
        var resourceUrl = 'api/workflows/:id';

        return $resource(resourceUrl, {
            id: '@id'
        }, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function(result) {
                    return transformResponse(result);
                }
            },
            'update': {
                method: 'PUT',
                transformResponse: function(result) {
                    return transformResponse(result);
                }
            },
            'save': {
                method: 'POST',
                transformResponse: function(result) {
                    return transformResponse(result);
                }
            }
        });

        function transformResponse(data) {
            data = angular.fromJson(data);

            if (data.start_date) {
                data.start_date = DateUtils.convertDateTimeFromServer(data.start_date);
            }

            if (data.end_date) {
                data.end_date = DateUtils.convertDateTimeFromServer(data.end_date);
            }
            return data;
        }
    }
})();
