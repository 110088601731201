(function() {
    'use strict';

    angular
        .module('atheer.smartFlow')
        .directive('smartFlowBuilderPreview', smartFlowBuilderPreviewDirective);

    /* @ngInject */
    function smartFlowBuilderPreviewDirective() {
        var directive = {
            restrict: 'E',
            require: '^smartFlowBuilderPreview',
            replace: true,
            templateUrl: 'modules/smartflow/smartflow-builder-preview.tmpl.html',
            scope: {
                canvasData: '='
            },
            controller: smartFlowBuilderPreviewController,
            controllerAs: 'vm',
            bindToController: true,
            link: function(scope, element, attrs) {
                scope.init(element, attrs);
            }
        };
        return directive;
    }

    /* @ngInject */
    /* @ngInject */
    function smartFlowBuilderPreviewController($scope, $mdToast, AlertService, $state, $mdDialog, SmartFlowBuilder) {
        var vm = this;

        $scope.init = function(element, attrs) {
            SmartFlowBuilder.initPreview(element);
            SmartFlowBuilder.loadCanvasData(vm.canvasData);
        };

        // listen for the event in the relevant $scope
        $scope.$on('templateChange', function(event, data) {
            vm.canvasData = data;
            SmartFlowBuilder.loadCanvasData(vm.canvasData);
        });
    }
})();
