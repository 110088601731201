(function() {
    'use strict';

    angular
        .module('atheer.core')
        .provider('triSettings', settingsProvider);

    /* @ngInject */
    function settingsProvider() {
        // Provider
        var settings = {
            languages: [],
            name: '',
            logoBig: '',
            logoSmall: '',
            copyright: '',
            version: '',
            skinModel: null
        };

        return {
            addLanguage: function(newLanguage) {
                settings.languages.push(newLanguage);
            },
            setSkinModel: function(skin) {
                settings.skinModel = skin;
            },
            setLogoBig: function(logo) {
                settings.logo = logo;
            },
            setLogoSmall: function(logo) {
                settings.logoSmall = logo;
            },
            setName: function(name) {
                settings.name = name;
            },
            setCopyright: function(copy) {
                settings.copyright = copy;
            },
            setVersion: function(version) {
                settings.version = version;
            },
            $get: function() {
                return {
                    setSkinModel: function(skin) {
                        settings.skinModel = skin;
                    },
                    getSkinModel: function() {
                        return settings.skinModel;
                    },
                    setLogoBig: function(logo) {
                        settings.logo = logo;
                    },
                    getLogoBig: function() {
                        return settings.logo;
                    },
                    setLogoSmall: function(logo) {
                        settings.logoSmall = logo;
                    },
                    getLogoSmall: function() {
                        return settings.logoSmall;
                    },
                    setName: function(name) {
                        settings.name = name;
                    },
                    getName: function() {
                        return settings.name;
                    },
                    setCopyright: function(copy) {
                        settings.copyright = copy;
                    },
                    getCopyright: function() {
                        return settings.copyright;
                    },
                    setVersion: function(version) {
                        settings.version = version;
                    },
                    getVersion: function() {
                        return settings.version;
                    },
                    languages: settings.languages,
                    name: settings.name,
                    copyright: settings.copyright,
                    logoBig: settings.logo,
                    logoSmall: settings.logoSmall,
                    version: settings.version
                };
            }
        }
    }
})();
