(function () {
	'use strict';
	angular
		.module('atheer.services')
		.factory('JobFlow', JobFlow);

	/* @ngInject */
	function JobFlow($resource) {
		var resourceUrl = 'api/smartflow/';
		var airFormUrl = 'api/applets/authoring';
		var insightsUrl = 'api/smartflow/insights';
		var jobDetailUrl = 'api/jobs';
		var executionUrl = 'api/smartflow/insights/execution';
		var jobScope = 'api/jobWorkflowRegistry'

		return $resource(resourceUrl, {
			id: '@id'
		}, {
			'query': {
				url: insightsUrl,
				method: 'GET',
				isArray: true
			},
			'getMoleculesProperties': {
				url: resourceUrl + 'molecules',
				method: 'GET',
				isArray: true
			},
			'getDataModel': {
				url: resourceUrl + 'datamodels',
				method: 'GET',
				isArray: true
			},
			'getDataMapper': {
				url: resourceUrl + 'datamapper/attributes',
				method: 'GET',
				isArray: true
			},
			'getDatasets': {
				url: 'api/datasets',
				method: 'GET',
				isArray: true
			},
			'getDatasetColumns': {
				url: '/api/datasets/:id/columns',
				method: 'GET',
				isArray: true
			},
			'getObjectList': {
				url: resourceUrl + 'data/:objectType',
				method: 'GET',
				isArray: true
			},
			'getMetaData': {
				url: resourceUrl + 'metadata/:objectType',
				method: 'GET',
				isArray: true
			},
			'getDataModelById': {
				url: resourceUrl + 'datamodels/:id',
				method: 'GET'
			},
			'getAirForm': {
				url: airFormUrl + '/:id',
				method: 'GET'
			},
			'getJobDetail': {
				url: jobDetailUrl,
				method: 'GET',
				isArray: true
			},
			'getExecutionDetail': {
				url: executionUrl + '/:id',
				method: 'GET'
			},

			'updateJobScope': {
				url: jobScope,
				method: 'POST'
			},

			'getJobScope': {
				url: jobScope,
				method: 'GET',
				isArray: true
			}
		});
	}
})();
