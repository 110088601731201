(function() {
    'use strict';

    angular
        .module('atheer.user')
        .controller('ProfileSecurityRolesController', ProfileSecurityRolesController);

    /* @ngInject */
    function ProfileSecurityRolesController($scope, $state, $mdDialog, Principal, $translate, pagingParams, paginationConstants, $mdToast, $filter, Experience, CONTENT_META, $mdMedia, id, PermissionService) {
        var vm = this;
        vm.doNotMatch = null;
        vm.error = null;
        vm.success = null;
        vm.user = null;
        vm.loadingData = true;
        vm.userId = id == 'me' ? Principal.getUserId() : id;
        vm.securityRoles = [];
        vm.isSuperUser = PermissionService.hasAccess('is-super-user-user');
        vm.disableAddUpdate = !vm.isSuperUser && Principal.getUserId() == id;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;

        vm.query = {
            filter: '',
            limit: paginationConstants.itemsPerPage,
            order: '-id',
            page: 1
        };

        vm.openAssignModal = function() {
            $mdDialog.show({
                templateUrl: 'modules/system/security-role/security-role-assign-dialog.tmpl.html',
                clickOutsideToClose: true,
                controller: "SecurityRoleAssignController",
                controllerAs: "vm",
                size: 'lg',
                locals: {
                    securityRole: undefined,
                    userId: vm.userId,
                    users: []
                }
            }).then(function() {
                vm.loadData();
            });
        }

        vm.removeUserSecurityRole = function(securityRole, index) {
            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.securityRole.removeUserSecurityRole'))
                .ariaLabel($filter('translate')('atheer.securityRole.removeUserSecurityRole'))
                .ok($filter('translate')('entity.action.delete'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function() {
                Experience.deleteSecurityRole({
                    id: securityRole.id
                }, function() {
                    vm.loadData();
                });
            });
        }

        vm.loadData = function() {
            vm.loadingData = true;
            //fetch pins data
            Experience.getSecurityRoles({
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort(),
                query: getQueryString()
            }, function(response, headers) {
                vm.page = pagingParams.page;
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.securityRoles = response;
                vm.loadingData = false;
            });
        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function getQueryString() {
            var queryString = "user_id==" + vm.userId;
            if (vm.query.filter.length <= 0) {
                return queryString;
            }
        }

        vm.transition = function() {
            vm.promise = Experience.getSecurityRoles({
                page: vm.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort(),
                query: getQueryString()
            }).$promise;

            vm.promise.then(function(response, headers) {
                vm.securityRoles = response;
                vm.queryCount = vm.totalItems;
            });
        }
        vm.loadData();
    }
})();
