(function () {
    'use strict';

    angular
        .module('atheer.services')
        .factory('AuthServerProvider', AuthServerProvider);

    /* @ngInject */
    function AuthServerProvider($http, $cookies, $q, $window, $location) {
        var service = {
            getToken: getToken,
            hasValidToken: hasValidToken,
            login: login,
            loginWithToken: loginWithToken,
            logout: logout,
            getPublicUserToken: getPublicUserToken
        };

        return service;

        function getToken() {
            var loginToken = $cookies.get('LoginToken');

            if (loginToken != null) {
                $cookies.remove('LoginToken');
                localStorage.setItem('Authorization', loginToken);

            }
            else if ($location.search().activation_token != undefined) {
                var loginToken = $location.search().activation_token;
                if (loginToken != null) {
                    $location.search('activation_token', null)
                    localStorage.setItem('Authorization', loginToken);
                }
            }

            var jwtToken = localStorage.getItem('Authorization');
            return jwtToken;
        }

        function getPublicUserToken() {
            return $http.get('api/public-access/login').success(publicAuthenticationSuccess);

            function publicAuthenticationSuccess(data, status, headers) {
                sessionStorage.setItem('Authorization', data.id_token)
            }
        }



        function hasValidToken() {
            var token = this.getToken();
            if (token && !isTokenExpired(token)) {
                return true;
            } else {
                return false;
            }
        }

        function login(credentials) {
            var data = {
                username: credentials.username,
                password: credentials.password,
                rememberMe: credentials.rememberMe
            };

            return $http.post('api/authenticate', data).success(authenticateSuccess);

            function authenticateSuccess(data, status, headers) {
                var bearerToken = headers('Authorization');
                if (bearerToken == null) {
                    bearerToken = headers('x-amzn-remapped-authorization');
                }

                if (angular.isDefined(bearerToken) && bearerToken.slice(0, 11) === 'Bearer ath-') {
                    var jwt = bearerToken.slice(11, bearerToken.length);
                    localStorage.setItem('Authorization', jwt)
                    return jwt;
                };
            }
        }

        function loginWithToken(jwt, rememberMe) {
            var deferred = $q.defer();

            if (angular.isDefined(jwt)) {
                localStorage.setItem('Authorization', jwt)
                deferred.resolve(jwt);
            } else {
                deferred.reject();
            }
            return deferred.promise;
        }

        function logout() {
            localStorage.removeItem('Authorization');
            sessionStorage.clear();
            var cookies = $cookies.getAll();
            angular.forEach(cookies, function (v, k) {
                $cookies.remove(k);
            });
        }

        function urlBase64Decode(str) {
            var output = str.replace(/-/g, '+').replace(/_/g, '/');
            switch (output.length % 4) {
                case 0:
                    {
                        break;
                    }
                case 2:
                    {
                        output += '==';
                        break;
                    }
                case 3:
                    {
                        output += '=';
                        break;
                    }
                default:
                    {
                        throw 'Illegal base64url string!';
                    }
            }
            return $window.decodeURIComponent(escape($window.atob(output))); //polyfill https://github.com/davidchambers/Base64.js
        };


        function decodeToken(token) {
            var parts = token.split('.');

            if (parts.length !== 3) {
                throw new Error('JWT must have 3 parts');
            }

            var decoded = urlBase64Decode(parts[1]);
            if (!decoded) {
                throw new Error('Cannot decode the token');
            }
            return angular.fromJson(decoded);
        };

        function getTokenExpirationDate(token) {
            var decoded = decodeToken(token);

            if (typeof decoded.exp === "undefined") {
                return null;
            }

            var d = new Date(0); // The 0 here is the key, which sets the date to the epoch
            d.setUTCSeconds(decoded.exp);
            return d;
        };

        function isTokenExpired(token, offsetSeconds) {
            var d = getTokenExpirationDate(token);
            offsetSeconds = offsetSeconds || 0;
            if (d === null) {
                return false;
            }
            // Token expired?
            return !(d.valueOf() > (new Date().valueOf() + (offsetSeconds * 1000)));
        };
    }
})();
