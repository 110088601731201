(function() {
    'use strict';

    angular
        .module('atheer.session')
        .controller('SessionPastEditController', SessionPastEditController);

    /* @ngInject */
    function SessionPastEditController($scope, $state, $q, $filter, Report, Feature, Pubnub, Session, $mdDialog, $mdToast, $stateParams, filter, Principal, User) {
        var vm = this;

        vm.loadingData = false;
        vm.session = null;
        vm.sessionId = filter;
        vm.close = function() {
            $mdDialog.cancel();
        };

        vm.updateSession = function() {
            Session.update(vm.session, function(result) {
                onSaveSuccess();
            });
        };

        function onSaveSuccess() {
            vm.isEnd = true;
            $mdDialog.hide();
        };


        function loadData() {
            vm.loadingData = true;
            $q.all([
                            Session.get({
                                id: vm.sessionId
                            }).$promise,
                        ])
                        .then(function(results) {
                            vm.session = results[0];
                            vm.loadingData = false;
                        });
        }

        loadData();
    }
})();
