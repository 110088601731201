(function() {
    'use strict';

    angular
        .module('atheer.cloud')
        .config(moduleConfig);

    function moduleConfig($stateProvider) {
        $stateProvider
        .state('storm.cloud-admin.configuration', {
            url: '/configuration',
            templateUrl: 'modules/cloud/configuration/configuration.html',
            controller: 'JhiConfigurationController',
            controllerAs: 'vm',
            data: {
                pageTitle: 'atheer.configuration.title',
                footer: false,
                showToolbar: false,
                showSideNavMenu: true,
            }
        });
    }
})();
