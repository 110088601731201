(function() {
    'use strict';

    angular
        .module('atheer.insights')
        .controller('InsightsSessionController', InsightsSessionController);

    /* @ngInject */
    function InsightsSessionController($scope, $q, $translate, $filter, Report, CONTENT_META, triSettings, Workspace, workspaceId) {
        var vm = this;
        vm.primaryColor = triSettings.getSkinModel().primary_color_hex;
        vm.secondaryColor = triSettings.getSkinModel().secondary_color_hex;

        vm.loadingData = false;
        vm.dashboardModel = null;
        vm.illustartionEmptyScreenId = "insight_graph";

        vm.topUsersBySessionCount = {
            chart: {
                type: 'bar',
            },
            title: {
                text: $translate.instant('atheer.insights.airSessions.topUsersAirSessionCount')
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            yAxis: {
                title: {
                    text: $translate.instant('atheer.insights.airSessions.noOfAirSessions')
                },
                allowDecimals: false
            },
            xAxis: {
                title: {
                    text: $translate.instant('atheer.insights.users.title')
                },
                categories: [],
                labels: {
                    enabled: true
                }
            },
            plotOptions: {
                series: {
                    stacking: 'normal'
                }
            },
            series: [{
                name: $translate.instant('atheer.insights.airSessions.title'),
                color: vm.primaryColor,
                data: []
            }],
            tooltip: {
                formatter: function() {
                    return '<b>' + this.series.name + '</b> : ' + this.y + ' ' + this.x;
                }
            }
        };

        vm.topUsersBySessionDuration = {
            chart: {
                type: 'bar',
            },
            title: {
                text: $translate.instant('atheer.insights.airSessions.topUsersByDuration')
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            yAxis: {
                title: {
                    text: $translate.instant('atheer.insights.minutes')
                },
                allowDecimals: false
            },
            xAxis: {
                title: {
                    text: $translate.instant('atheer.insights.users.title')
                },
                categories: [],
                labels: {
                    enabled: true
                }
            },
            plotOptions: {
                series: {
                    stacking: 'normal'
                }
            },
            series: [{
                name: $translate.instant('atheer.insights.minutes'),
                color: vm.primaryColor,
                data: []
            }],
            tooltip: {
                formatter: function() {
                    return '<b>' + this.series.name + '</b> : ' + this.y + ' ' + this.x;
                }
            }
        };

        vm.usageBySessionCount = {
            chart: {
                type: 'column',
            },
            title: {
                text: $translate.instant('atheer.insights.airSessions.sessionUsageByCount')
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            yAxis: {
                title: {
                    text: $translate.instant('atheer.insights.airSessions.noOfAirSessions')
                },
                allowDecimals: false
            },
            xAxis: {
                categories: [],
                labels: {
                    enabled: true
                }
            },
            plotOptions: {
                series: {
                    stacking: 'normal'
                }
            },
            series: [{
                name: $translate.instant('atheer.insights.minutes'),
                color: vm.primaryColor,
                data: []
            }],
            tooltip: {
                formatter: function() {
                    return '<b>' + this.series.name + '</b> : ' + this.y + ' ' + this.x;
                }
            }
        };

        vm.usageBySessionDuration = {
            chart: {
                type: 'column',
            },
            title: {
                text: $translate.instant('atheer.insights.airSessions.sessionUsageByMinutes')
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            yAxis: {
                title: {
                    text: $translate.instant('atheer.insights.minutes')
                },
                allowDecimals: false
            },
            xAxis: {
                categories: [],
                labels: {
                    enabled: true
                }
            },
            plotOptions: {
                series: {
                    stacking: 'normal'
                }
            },
            series: [{
                name: $translate.instant('atheer.insights.dates'),
                color: vm.primaryColor,
                data: []
            }],
            tooltip: {
                formatter: function() { 
                    return '<b>' + this.series.name + '</b> : ' + this.y + ' ' + this.x;
                }
            }
        };

        function performDataProcess() {
            angular.forEach(vm.dashboardModel.top_users_by_session_count, function(topUsersBySessionCount) {
                vm.topUsersBySessionCount.xAxis.categories.push(topUsersBySessionCount.name);
                vm.topUsersBySessionCount.series[0].data.push(topUsersBySessionCount.count);
            });

            angular.forEach(vm.dashboardModel.top_users_by_session_duration, function(topUsersBySessionDuration) {
                vm.topUsersBySessionDuration.xAxis.categories.push(topUsersBySessionDuration.name);
                vm.topUsersBySessionDuration.series[0].data.push(topUsersBySessionDuration.count);
            });

            angular.forEach(vm.dashboardModel.session_usage_by_count, function(usageBySessionCount) {
                vm.usageBySessionCount.xAxis.categories.push(usageBySessionCount.name);
                vm.usageBySessionCount.series[0].data.push(usageBySessionCount.count);
            });

            angular.forEach(vm.dashboardModel.session_usage_by_duration, function(usageBySessionDuration) {
                vm.usageBySessionDuration.xAxis.categories.push(usageBySessionDuration.name);
                vm.usageBySessionDuration.series[0].data.push(usageBySessionDuration.count);
            });

            vm.loadingData = false;
        }

        vm.loadData = function() {
            vm.loadingData = true;

            var resource = angular.isDefined(workspaceId) ? Workspace.getSessionDashboard({
                id: workspaceId
            }).$promise : Report.getSessionDashboard().$promise;

            $q.all([
                    resource
                ])
                .then(function(results) {
                    vm.dashboardModel = results[0];
                    performDataProcess();
                });
        }

        vm.getCount = function(metricsName) {
            if (metricsName == 'avg_rating') {
                return vm.dashboardModel.avg_rating;
            } else {
                return 5;
            }

        };

        vm.loadData();
    }
})();
