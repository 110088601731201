(function() {
    'use strict';
    angular
        .module('atheer')
        .directive('fpFilterBuilder', fpFilterBuilder);

    function fpFilterBuilder() {
        return {
            restrict: 'AE',
            templateUrl: 'core/components/filter/filter-builder.tmpl.html',
            controller: FilterBuilderDirectiveController,
            scope: {
                'object': '@',
                'label': '@',
                'icon': '@'
            },
        };
    }

    function FilterBuilderDirectiveController($rootScope, $scope, $q, ParseLinks, $mdSidenav, AlertService, $mdToast, $element, $filter, $mdDialog, MetaData, Setting, Filter, FIELD_CONDITION_CONSTANT, DateUtils, $mdPanel, $timeout, triSettings) {

        $scope.primaryColor = triSettings.getSkinModel().primary_color_hex;
        $scope.secondaryColor = triSettings.getSkinModel().secondary_color_hex;

        $scope.matchSelected = false;

        $scope.timezones = [];
        $scope.languages = [];
        $scope.metaFieldGroups = [];
        $scope.filters = null;

        $scope.selectedFilter = null;
        $scope.filterDetail = null;
        $scope.hasNewFilter = false;

        $scope.selectedCondition = null;

        function getDateFromItem(item) {
            return item.x;
        }

        $scope.refreshFilter = function() {
            if ($scope.hasNewFilter) {
                $scope.filters.pop();
                $scope.hasNewFilter = false;
            }
            initFilter($scope.selectedFilter);
        };

        $scope.showDelete = function(filterInfo) {
            if (filterInfo.filter_no != 1000 && filterInfo.id != null) {
                return true;
            } else {
                return false;
            }
        }

        $scope.showActions = function(filterInfo) {
            if (filterInfo.filter_no != 1000 && filterInfo.id != null) {
                return true;
            } else {
                return false;
            }
        }

        $scope.showReset = function(filterInfo) {
            if (filterInfo != null) {
                if (filterInfo.filter_no == 1000) {
                    return false;
                } else if (filterInfo.id == null) {
                    return true;
                } else if ($rootScope._.isEqual(angular.toJson(filterInfo), angular.toJson($scope.selectedFilter))) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        }

        $scope.showSave = function(filterInfo) {
            if (filterInfo != null) {
                if (filterInfo.filter_no == 1000) {
                    return false;
                } else if (filterInfo.id == null && filterInfo.criteria.condition_groups.length > 0) {
                    return true;
                } else if ($rootScope._.isEqual(angular.toJson(filterInfo), angular.toJson($scope.selectedFilter))) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        }

        $scope.resetFilter = function() {
            if ($scope.hasNewFilter) {
                $scope.filters.pop();
                $scope.hasNewFilter = false;
                angular.forEach($scope.filters, function(filterInfo) {
                    if (filterInfo.filter_no == 1000) {
                        initFilter(filterInfo);
                    }
                });
            } else {
                initFilter($scope.selectedFilter);
            };
        };

        $scope.isDefaultFilter = function(filterInfo) {
            if (filterInfo.filter_no == 1000) {
                return true;
            } else {
                return false;
            }
        };

        $scope.getFieldLabel = function(condition) {
            var label = null;
            var fieldGroupMeta = $filter('filter')($scope.metaFieldGroups, {
                'name': condition.field_group_name
            })[0];
            var fieldMeta = $filter('filter')(fieldGroupMeta.fields, {
                'name': condition.field_name
            })[0];

            var operator = $filter('filter')(FIELD_CONDITION_CONSTANT[condition.data_type], {
                value: condition.operator
            }, true)[0];

            if (condition.field_group_name == 'profile') {
                label = fieldMeta.label + ' ' + $filter('translate')(operator.label);
            } else {
                label = fieldGroupMeta.label + ':' + fieldMeta.label + ' ' + $filter('translate')(operator.label);
            }

            if (condition.value != null) {
                label = label + ' ' + getFieldDisplayValue(condition);
            };
            return label;
        };

        $scope.getConditionBackgroundColor = function(condition, conditionFocus) {
            if ($scope.isMissingValue(condition)) {
                return $scope.secondaryColor;
            }

            if (condition == $scope.selectedCondition) {
                return $scope.primaryColor;
            }
        };

        $scope.isMissingValue = function(condition) {
            var operator = $filter('filter')(FIELD_CONDITION_CONSTANT[condition.data_type], {
                value: condition.operator
            }, true)[0];

            if (operator.showInput && (condition.value == null || condition.value.length <= 0)) {
                return true;
            } else {
                return false;
            }
        };

        $scope.removeField = function(groupIndex, fieldIndex) {
            $scope.filterDetail.criteria.condition_groups[groupIndex].conditions.splice(fieldIndex, 1);
            if ($scope.filterDetail.criteria.condition_groups[groupIndex].conditions.length == 0) {
                $scope.filterDetail.criteria.condition_groups.splice(groupIndex, 1);
            }
            // firing an event downwards
            $scope.$emit('updateResults', $scope.filterDetail);
        };

        $scope.editFilter = function(filter) {
            $mdDialog.show({
                templateUrl: 'core/components/filter/filter-detail-dialog.tmpl.html',
                clickOutsideToClose: true,
                controller: "FilterDetailDialogController",
                controllerAs: "vm",
                size: 'lg',
                locals: {
                    mode: 'UPDATE',
                    object: $scope.object,
                    filter: angular.copy(filter)
                }
            }).then(function(updatedFilter) {
                filter.name = updatedFilter.name;
                filter.description = updatedFilter.description;
                filter.status = updatedFilter.status;
                $scope.saveFilter(filter);
            });
        };

        $scope.saveDynamicFilter = function(filter) {
            if (filter.id) {
                $scope.saveFilter(filter);
            } else {
                $mdDialog.show({
                    templateUrl: 'core/components/filter/filter-detail-dialog.tmpl.html',
                    clickOutsideToClose: true,
                    controller: "FilterDetailDialogController",
                    controllerAs: "vm",
                    size: 'lg',
                    locals: {
                        mode: 'CREATE',
                        object: $scope.object,
                        filter: filter
                    }
                }).then(function(updatedFilter) {
                    filter.name = updatedFilter.name;
                    filter.description = updatedFilter.description;
                    $scope.saveFilter(filter);
                });
            }
        };

        $scope.deleteFilter = function(filter) {
            var confirm = $mdDialog.confirm()
                .title('Are you sure you want to delete this Filter?')
                .ariaLabel('Are you sure you want to delete this Filter?')
                .ok('Delete')
                .cancel('Cancel');

            $mdDialog.show(confirm).then(function() {
                Filter.delete({
                    id: filter.id
                }, function() {
                    AlertService.displayToast($mdToast);
                    loadAll();
                });
            });
        };

        $scope.saveFilter = function(filter) {
            if (filter.id) {
                Filter.update(filter, onSaveSuccess, onSaveError);
            } else {
                Filter.save(filter, onSaveSuccess, onSaveError);
            }

            function onSaveSuccess(result) {
                resetFilters(result);
                AlertService.displayToast($mdToast);
            };

            function onSaveError() {

            };
        };



        $scope.showMatchType = function(ev) {
            if ($scope.filterDetail.filter_no != 1000) {
                $scope.matchSelected = true;

                var position = $mdPanel.newPanelPosition()
                    .relativeTo('#match-type')
                    .addPanelPosition($mdPanel.xPosition.OFFSET_END, $mdPanel.yPosition.ALIGN_TOPS);

                var config = {
                    attachTo: angular.element(document.body),
                    controller: "MatchSelectionPanelController",
                    controllerAs: 'vm',
                    templateUrl: 'core/components/filter/match-selection-panel.tmpl.html',
                    position: position,
                    openFrom: ev,
                    clickOutsideToClose: true,
                    escapeToClose: true,
                    focusOnOpen: false,
                    zIndex: 2,
                    onCloseSuccess: function(panelRef, reason) {
                        $scope.matchSelected = false;
                        if (panelRef.matchAll != $scope.filterDetail.criteria.match_all) {
                            $scope.filterDetail.criteria.match_all = panelRef.matchAll;
                            // firing an event downwards
                            $scope.$emit('updateResults', $scope.filterDetail);
                        }
                    }
                };
                $mdPanel.open(config);
            }
        };

        $scope.showFields = function(ev) {
            if ($scope.filterDetail.filter_no == 1000) {
                var newDynamicFilter = {
                    filter_no: null,
                    name: 'Your New Filter',
                    object_type: $scope.object,
                    criteria: {
                        match_all: true,
                        condition_groups: []
                    }
                };
                $scope.selectedFilter = newDynamicFilter;
                $scope.filterDetail = newDynamicFilter;
                $scope.filters.push(newDynamicFilter);
                $scope.hasNewFilter = true;
            };

            var position = $mdPanel.newPanelPosition()
                .relativeTo('#add-filter')
                .addPanelPosition($mdPanel.xPosition.OFFSET_END, $mdPanel.yPosition.ALIGN_TOPS);


            var config = {
                attachTo: angular.element(document.body),
                controller: "FieldSelectionPanelController",
                controllerAs: 'vm',
                templateUrl: 'core/components/filter/field-selection-panel.tmpl.html',
                position: position,
                locals: {
                    metaFieldGroups: $scope.metaFieldGroups,
                    conditionGroups: $scope.filterDetail.criteria.condition_groups
                },
                openFrom: ev,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: false,
                zIndex: 2,
                onCloseSuccess: function(panelRef, reason) {
                    if (panelRef.condition != null) {
                        $timeout(function() {
                            $scope.showFieldSettting(panelRef.condition);
                        }, 200);
                    }
                }
            };
            $mdPanel.open(config);
        };

        $scope.showFieldSettting = function(condition, ev) {
            $scope.selectedCondition = condition;

            var position = $mdPanel.newPanelPosition()
                .relativeTo('#' + condition.field_group_name + '-' + condition.field_name)
                .addPanelPosition($mdPanel.xPosition.OFFSET_END, $mdPanel.yPosition.ALIGN_TOPS);

            var config = {
                attachTo: angular.element(document.body),
                controller: "FieldSettingPanelController",
                controllerAs: 'vm',
                templateUrl: 'core/components/filter/field-setting-panel.tmpl.html',
                position: position,
                locals: {
                    metaFieldGroups: $scope.metaFieldGroups,
                    condition: condition,
                    conditionListOptions: []
                },
                openFrom: ev,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: false,
                zIndex: 2,
                onCloseSuccess: function(panelRef, reason) {
                    $scope.selectedCondition = null;
                    // firing an event downwards
                    $scope.$emit('updateResults', $scope.filterDetail);
                }
            };

            $mdPanel.open(config);
        };

        function getFieldDisplayValue(condition) {
            if (!angular.isDefined(condition.value) || condition.value == null) {
                return null;
            } else {
                if (condition.data_type == 'DATE') {
                    if (condition.operator == 'EXACTLY') {
                        return condition.value + ' days ago';
                    } else if (condition.operator == 'GREATER_THAN') {
                        return condition.value + ' days ago';
                    } else if (condition.operator == 'GREATER_THAN_OR_EQUAL') {
                        return condition.value + ' days ago';
                    } else if (condition.operator == 'LESS_THAN') {
                        return condition.value + ' days ago';
                    } else if (condition.operator == 'LESS_THAN_OR_EQUAL') {
                        return condition.value + ' days ago';
                    } else if (condition.operator == 'WITHIN') {
                        return condition.value + ' days';
                    } else if (condition.operator == 'BETWEEN') {
                        var fromDate = (angular.isDefined(condition.value.from_date) && condition.value.from_date != null ? $filter('date')(condition.value.from_date, "MM-dd-yyyy") : null);
                        var toDate = (angular.isDefined(condition.value.to_date) && condition.value.to_date != null ? $filter('date')(condition.value.to_date, "MM-dd-yyyy") : null);
                        return (fromDate == null ? '' : fromDate) + ' and ' + (toDate == null ? '' : toDate);
                    } else if (condition.operator == 'IS') {
                        if (condition.value == 'TODAY') {
                            return 'today';
                        } else if (condition.value == 'YESTERDAY') {
                            return 'yesterday';
                        } else if (condition.value == 'START_OF_WEEK') {
                            return 'start of the week';
                        } else if (condition.value == 'END_OF_WEEK') {
                            return 'end of the week';
                        } else if (condition.value == 'START_OF_MONTH') {
                            return 'start of the month';
                        } else if (condition.value == 'END_OF_MONTH') {
                            return 'end of the month';
                        } else {
                            return condition.value;
                        }
                    } else if (condition.operator == 'AFTER') {
                        return $filter('date')(condition.value, "MM-dd-yyyy");
                    } else if (condition.operator == 'BEFORE') {
                        return $filter('date')(condition.value, "MM-dd-yyyy");
                    } else if (condition.operator == 'ON') {
                        return $filter('date')(condition.value, "MM-dd-yyyy");
                    } else {
                        return condition.value;
                    }
                } else {
                    return condition.value;
                }
            }
        };

        function refreshFilters() {
            $q.all([
                Filter.query({
                    size: 100,
                }).$promise,
            ]).then(function(data) {
                $scope.filters = data[0];

                if ($scope.hasNewFilter) {
                    $scope.filters.push($scope.filterDetail);
                } else {
                    angular.forEach($scope.filters, function(filterInfo) {
                        if (filterInfo.filter_no == $scope.filterDetail.filter_no) {
                            initFilter(filterInfo);
                        }
                    });
                }
            });
        };

        function resetFilters(filter) {
            $q.all([
                Filter.query({
                    size: 100,
                }).$promise,
            ]).then(function(data) {
                $scope.filters = data[0];
                $scope.hasNewFilter = false;

                angular.forEach($scope.filters, function(filterInfo) {
                    if (filterInfo.filter_no == filter.filter_no) {
                        initFilter(filterInfo);
                    }
                });
            });
        };

        function initFilter(filterInfo) {
            $scope.selectedFilter = filterInfo;
            $scope.filterDetail = angular.copy(filterInfo);

            if (!angular.isDefined($scope.filterDetail.criteria) || $scope.filterDetail.criteria == null) {
                $scope.filterDetail.criteria = {
                    match_all: true
                }
            }
            // firing an event downwards
            $scope.$emit('updateResults', $scope.filterDetail);
        };

        function loadAll() {
            $scope.loadingData = true;
            $q.all([
                MetaData.get({
                    id: $scope.object,
                    is_name: true,
                    add_default_properties: true
                }).$promise,
                Setting.getSystemTimezones({
                    size: 100
                }).$promise,
                Setting.getSystemLanguages().$promise,
                Filter.query({
                    query: 'objectType==' + $scope.object,
                    size: 100
                }).$promise,
            ]).then(function(data) {
                $scope.metaFieldGroups = data[0].field_groups;
                $scope.timezones = data[1];
                $scope.languages = data[2];
                $scope.filters = data[3];

                angular.forEach($scope.filters, function(filterInfo) {
                    if (filterInfo.filter_no == 1000) {
                        initFilter(filterInfo);
                    }
                });
                $scope.loadingData = false;
            });
        }

        // listen for the event in the relevant $scope
        $rootScope.$on('refreshFilters', function(event) {
            refreshFilters();
        });
        loadAll();
    }
})();
