(function() {
    'use strict';

    angular
        .module('atheer.user')
        .controller('UserDetailMenuController', UserDetailMenuController);

    /* @ngInject */
    function UserDetailMenuController($scope, $q, $state, id, User, MetaData, AlertService, $mdDialog, $mdToast, $stateParams, Principal, Feature, PermissionService) {
        var vm = this;

        vm.loadingData = true;

        vm.userId = id == 'me' ? Principal.getUserId() : id;
        vm.profileViewMode = id == 'me' ? 'me' : 'admin';
        vm.isAdminMode = vm.profileViewMode == 'admin';

        vm.pageTitleKey = $state.$current.data.pageTitle;

        vm.settings = [];
        vm.activeLink = $state.current.name;
        vm.profileEvents = [];
        vm.profileMeta = null;

        vm.settingsConfig = [{
            label: 'atheer.userDetail.menu.overview',
            state: 'storm.profile.overview',
            isAdminOnly: false
        }, {
            label: 'atheer.userDetail.menu.about',
            state: 'storm.profile.about',
            isAdminOnly: false
        }, {
            label: 'atheer.userDetail.menu.password',
            state: 'storm.profile.password',
            isAdminOnly: false
        }, {
            label: 'atheer.userDetail.menu.library',
            state: 'storm.profile.library.bookmarks',
            isAdminOnly: false
        }, {
            label: 'atheer.userDetail.menu.airSessions',
            state: 'storm.profile.sessions.upcoming',
            isAdminOnly: true
        }, {
            label: 'atheer.userDetail.menu.teams',
            state: 'storm.profile.teams',
            isAdminOnly: true
        }, {
            label: 'atheer.userDetail.menu.securityRoles',
            state: 'storm.profile.security-roles',
            isAdminOnly: true
        }, ]

        function loadData() {
            vm.loadingData = true;

            $q.all([
                User.getById({
                    id: vm.userId
                }).$promise,
                MetaData.get({
                    id: 'user',
                    is_name: true
                }).$promise
            ]).then(function(data) {
                vm.user = data[0];
                angular.forEach(data[1].field_groups, function(field_group) {
                    if (field_group.name == 'profile' && !field_group.is_many) {
                        vm.profileMeta = field_group;
                    }
                    if (field_group.is_many) {
                        vm.profileEvents.push(field_group);
                    }
                });
                vm.loadingData = false;

            }).catch(function(data) {}).finally(function() {});

            if (vm.isAdminMode) {
                angular.forEach(vm.settingsConfig, function(setting) {
                    vm.settings.push(setting);
                });
            } else {
                angular.forEach(vm.settingsConfig, function(setting) {
                    if (!setting.isAdminOnly) {
                        vm.settings.push(setting);
                    }
                });
            }
        }

        vm.goToUsers = function() {
            if($stateParams.isDeeplink) {
                $window.history.go(-2);
            } else {
                // $window.history.back();
                $state.go('storm.admin-users');
            }
        };

        loadData();
    }
})();
