(function() {

    'use strict';
    angular
        .module('atheer.smartFlow')
        .constant('WORFLOW_CELL_CONSTANT', {
            'audienceTrigger': {
                setting: {
                    isConfigured: false,
                    myTeam: false,
                    audience: undefined
                },
                label: 'Team'

            },
            'audienceAssign': {
                setting: {
                    isConfigured: false,
                    audience: undefined
                },
                label: 'Assign Team'

            },
            'audienceRemove': {
                setting: {
                    title: '',
                    isConfigured: false,
                    audience: undefined
                },
                label: 'Remove Team'

            },
            'audienceCheck': {
                setting: {
                    isConfigured: false,
                    audience: undefined
                },
                label: 'Team'

            },
            'userFieldTrigger': {
                setting: {
                    isConfigured: false
                },
                label: 'User Profile Field'

            },
            'userFieldCheck': {
                setting: {
                    isConfigured: false,
                    content: undefined
                },
                label: 'User Profile'

            },
            'announcementAssign': {
                setting: {
                    isConfigured: false,
                    absoluteDate: null,
                    relativeDate: {
                        period: 'hrs',
                        value: ''
                    },
                    isDueDateRelative: 0,
                    content: undefined
                },
                label: 'Announcement'

            },
            'contentAssign': {
                setting: {
                    isConfigured: false,
                    required: false,
                    absoluteDate: null,
                    relativeDate: {
                        period: 'hrs',
                        value: ''
                    },
                    isDueDateRelative: 0,
                    content: undefined
                },
                label: 'Assign Content'

            },
            'contentRemove': {
                setting: {
                    isConfigured: false,
                    content: undefined
                },
                label: 'Remove Assignment'

            },
            'contentRecommend': {
                setting: {
                    title: '',
                    isConfigured: false,
                    content: undefined,
                    note: null
                },
                label: 'Recommend'

            },
            'contentCheck': {
                setting: {
                    isConfigured: false,
                    content: undefined
                },
                label: 'Assignment'

            },
            'questionAssign': {
                setting: {
                    isConfigured: false,
                    required: false,
                    isDueDate: 0,
                    content: undefined,
                    absoluteDate: null
                },
                label: 'Ask Question'

            },
            'questionCheck': {
                setting: {
                    status: 'COMPLETED',
                    pass: true,
                    isConfigured: false,
                    content: undefined
                },
                label: 'Answer'

            },
            'badgeAssign': {
                setting: {
                    isConfigured: false,
                    badge: undefined
                },
                label: 'Assign Badge'

            },
            'badgeRemove': {
                setting: {
                    isConfigured: false,
                    badge: undefined
                },
                label: 'Remove Badge'

            },
            'taskAssign': {
                setting: {
                    isConfigured: false,
                    required: false,
                    absoluteDate: null,
                    relativeDate: {
                        period: 'hrs',
                        value: ''
                    },
                    isDueDateRelative: 0,
                    content: undefined
                },
                label: 'Assign Task'

            },
            'taskRemove': {
                setting: {
                    isConfigured: false,
                    content: undefined
                },
                label: 'Remove Task'

            },
            'taskListAssign': {
                setting: {
                    isConfigured: false,
                    required: false,
                    absoluteDate: null,
                    relativeDate: {
                        period: 'hrs',
                        value: ''
                    },
                    isDueDateRelative: 0,
                    content: undefined
                },
                label: 'Assign Task List'

            },
            'taskListRemove': {
                setting: {
                    isConfigured: false,
                    task: undefined
                },
                label: 'Remove Task List'

            },
            'securityRoleAssign': {
                setting: {
                    isConfigured: false,
                    securityrole: undefined
                },
                label: 'Assign Sec. Role'

            },
            'securityRoleRemove': {
                setting: {
                    isConfigured: false,
                    securityrole: undefined
                },
                label: 'Remove Sec. Role'

            },
            'emailSend': {
                setting: {
                    isConfigured: false,
                    content: undefined
                },
                label: 'Newsletter'

            },
            'slackMessageSend': {
                setting: {
                    isConfigured: false,
                    message: ''
                },
                label: 'Slack Message'

            },
            'pushNotificationSend': {
                setting: {
                    isConfigured: false,
                    content: undefined
                },
                label: 'Push Notification'

            },
            'smsSend': {
                setting: {
                    isConfigured: false,
                    message: ''
                },
                label: 'SMS'

            },
            'externalSystem': {
                setting: {
                    title: '',
                    isConfigured: false
                },
                label: 'External System'

            },
            'wait': {
                setting: {
                    isConfigured: false,
                    period: 'hrs',
                    value: 0
                },
                label: 'Wait'
            },
            'end': {
                setting: {
                    isConfigured: false
                },
                label: 'End'
            }
        });
})();
