(function() {
    'use strict';

    angular
        .module('atheer.insights')
        .controller('InsightsMenuController', InsightsMenuController);

    /* @ngInject */
    function InsightsMenuController($scope, $state, $mdDialog, $mdPanel, Team, ParseLinks, AlertService, pagingParams, paginationConstants, $mdToast, $stateParams, $filter, $translate, Feature) {
        var vm = this;

        vm.loadingData = true;
        vm.loadAll = loadAll;
        vm.pageTitleKey = $state.$current.data.pageTitle;

        vm.activeLink = $state.current.name;

        vm.goToWorkspaces = function() {
            $state.go("storm.admin-workspaces");
        };

        function loadAll() {
            vm.loadingData = false;
        };
        vm.loadAll();

        $scope.$on('$destroy', function() {
            $scope.$emit('toggleSideMenu', 'reset');
        });
    }
})();
