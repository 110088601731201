(function() {
    'use strict';

    angular
        .module('atheer.session')
        .controller('ConversationMessageMenuController', ConversationMessageMenuController);

    /* @ngInject */
    function ConversationMessageMenuController(mdPanelRef, $rootScope, $scope, PubNubService, $state, $location, $mdDialog, $q, $filter, $mdToast, Principal, $mdPanel, messageModel, conversationModel, Message, ConversationModelService, AlertService) {
        var vm = this;

        vm.message = messageModel;
        vm.conversation = conversationModel;
        vm.userId = Principal.getUserId();
        vm.isPinned = false;

        vm.deleteMessage = function() {
            mdPanelRef.close();
            ConversationModelService.deleteMessage(vm.message);
        };

        vm.pinMessage = function() {
            mdPanelRef.close();
            ConversationModelService.pinMessage(vm.message);
        };

        vm.unPinMessage = function() {
            mdPanelRef.close();
            ConversationModelService.unPinMessage(vm.message);
        };

        vm.addReaction = function(ev) {
            mdPanelRef.close();
            ConversationModelService.addMessageReaction(ev, vm.message);;
        };

        function loadData() {
            vm.isPinned = false;

            if (vm.message && vm.message.is_pinned) {
                vm.isPinned = true;
            }
        };
        loadData();
    }
})();
