(function() {
    'use strict';

    angular
        .module('atheer')
        .directive('fpConvMessageAudioPlayer', fpConvMessageAudioPlayer);

    function fpConvMessageAudioPlayer() {
        var directive = {
            restrict: 'E',
            controller: fpConvMessageAudioPlayerController,
            scope: {
                audioId: '=',
                mine: '=',
                isPinned: '='
            },
            templateUrl: 'modules/conversation/conversation-audio-message.tmpl.html',
            link: function($scope, $element, $attrs) {
                $element.css('display', 'block');
            }
        };
        return directive;
    }

    /* @ngInject */
    function fpConvMessageAudioPlayerController($scope, $timeout, $attrs, $injector, $filter, $q, $mdDialog, triSettings) {
        $scope.wavesurfer = null;
        $scope.playing = false;

        var secondaryColor = triSettings.getSkinModel().secondary_color_hex;

        var waveColor = '#D9DCFF';
        if (!$scope.mine) {
            waveColor = '#1F1C2C';
        }

        var containerId = $scope.isPinned ? '#aud-' + $scope.audioId + '-pinned' : '#aud-' + $scope.audioId;

        var options = {
            container: containerId,
            waveColor: waveColor,
            progressColor: secondaryColor,
            cursorColor: secondaryColor,
            barWidth: 3,
            barHeight: 2,
            barRadius: 3,
            cursorWidth: 1,
            height: 30,
            barGap: 3,
        };

        $scope.play = function() {
            $scope.wavesurfer.play();
            $scope.playing = true;
        };

        $scope.pause = function() {
            $scope.wavesurfer.pause();
            $scope.playing = false;
        };

        $timeout(function() {
            $scope.wavesurfer = WaveSurfer.create(options);

            if ($attrs.url) {
                $scope.wavesurfer.load($attrs.url, $attrs.data || null);
            }
            $scope.$emit('wavesurferInit', $scope.wavesurfer);

            $scope.wavesurfer.on('finish', function() {
                $scope.playing = false;
                $scope.wavesurfer.seekTo(0);
                $scope.$apply();
            });

        }, 100);
    }
})();
