(function() {
    'use strict';

    angular
        .module('atheer.smartFlow')
        .controller('SmartFlowMenuController', SmartFlowMenuController);

    /* @ngInject */
    function SmartFlowMenuController($scope, $state, $mdDialog, Content, Team, ParseLinks, AlertService, ContentAction, pagingParams, paginationConstants, $mdToast, $stateParams, $filter, CONTENT_META, $translate, Feature) {
        var vm = this;

        vm.loadingData = true;
        vm.pageTitleKey = $state.$current.data.pageTitle;

        vm.filters = {
            type: 'ALL',
            status: 'ALL'
        };

        vm.updateFilters = function() {
            vm.filters.audience = vm.selectedTeam;
            $scope.$emit('updateSmartFlowList', vm.filters);
        }

        vm.loadAll = function() {
          vm.loadingData = false;
        }
        vm.loadAll();
    }
})();
