(function() {
    'use strict';

    angular
        .module('atheer.topic')
        .controller('TopicInsightOverviewController', TopicInsightOverviewController)

    /* @ngInject */
    function TopicInsightOverviewController($scope, $q, $filter, id, Report) {
        var vm = this;

        vm.loadingData = false;

        vm.topicScansByDay = {
            chart: {
                type: 'column',
            },
            title: {
                text: $filter('translate')('atheer.topic.scanPerDay')
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            yAxis: {
                title: {
                    text: $filter('translate')('atheer.topic.noOfScans')
                },
                allowDecimals: false
            },
            xAxis: {
                categories: [],
                labels: {
                    enabled: true
                }
            },
            plotOptions: {
                series: {
                    stacking: 'normal'
                }
            },
            series: [{
                name: $filter('translate')('atheer.topic.dates'),
                color: vm.primaryColor,
                data: []
            }],
            tooltip: {
                formatter: function() {
                    return '<b>' + this.series.name + '</b> : ' + this.y + ' ' + this.x;
                }
            }
        };

        function performDataProcess() {
            angular.forEach(vm.dashboardModel.topic_scan_by_day, function(topicScansByDay) {
                vm.topicScansByDay.xAxis.categories.push(topicScansByDay.name);
                vm.topicScansByDay.series[0].data.push(topicScansByDay.count);
            });

            vm.loadingData = false;
        }

        vm.loadData = function() {
           vm.loadingData = true;

            var resource = Report.getTopicSummary({
                id : id
            }).$promise;

            $q.all([
                    resource
                ])
                .then(function(results) {
                    vm.dashboardModel = results[0];
                    performDataProcess();
                });
        }

        vm.loadData();
    }
})();
