(function() {
    'use strict';

    angular
        .module('atheer.content')
        .controller('ContentBulkEditController', ContentBulkEditController);

    /* @ngInject */
    function ContentBulkEditController($scope, $state, $mdDialog, $q, $filter, $mdToast, User, contents, Experience, Team, Principal) {
        var vm = this;

        vm.topics = [];
        vm.folders = [];
        vm.audiences = [];
        vm.owner = null;
        vm.contents = contents;

        vm.updateProgress = 'idle';

        Principal.identity().then(function(user) {
            vm.owner = user;
            vm.loadingData = false;
        })

        vm.contentBulkEditModel = {
            tags: [],
            owner_id: null,
            available_from: new Date(),
            discontinue_from: new Date(),
            topics: [],
            audiences: [],
            folders: [],
        };

        vm.close = function() {
            if (vm.updateProgress !== 'idle') {
                $mdDialog.hide();
            } else {
                $mdDialog.cancel();
            }
        }

        function getUniqueArray(array) {
            var returnArray = [];
            return returnArray;
        }

        vm.submit = function() {
            vm.updateProgress = 'started';

            if (vm.owner) {
                vm.contentBulkEditModel.owner_id = vm.owner.id
            } else {
                vm.contentBulkEditModel.owner_id = null;
            }
            if (vm.topics && vm.topics.length > 0) {
                var topicIds = [];
                angular.forEach(vm.topics, function(topic) {
                    topicIds.push(topic.id);
                });
                vm.contentBulkEditModel.topics = topicIds;
            } else {
                vm.contentBulkEditModel.topics = null;
            }

            if (vm.audiences && vm.audiences.length > 0) {
                var audienceIds = [];
                angular.forEach(vm.audiences, function(audience) {
                    audienceIds.push(audience.id);
                });
                vm.contentBulkEditModel.audiences = audienceIds;
            }
            if (vm.folders && vm.folders.length > 0) {
                var folderIds = [];
                angular.forEach(vm.folders, function(folder) {
                    folderIds.push(folder.id);
                });
                vm.contentBulkEditModel.folders = folderIds;
            }

            angular.forEach(vm.contents, function(content) {
                if (vm.contentBulkEditModel.topics) {
                    if (content.data.topics) {
                        content.data.topics = content.data.topics.concat(vm.contentBulkEditModel.topics);
                    } else {
                        content.data.topics = vm.contentBulkEditModel.topics
                    }
                }

                if (vm.contentBulkEditModel.audiences) {
                    if (content.data.audiences) {

                        content.data.audiences = content.data.audiences.concat(vm.contentBulkEditModel.audiences);
                    } else {
                        content.data.audiences = vm.contentBulkEditModel.audiences
                    }
                }

                if (vm.contentBulkEditModel.folders) {
                    if (content.folders) {

                        content.folders = content.folders.concat(vm.contentBulkEditModel.folders);
                    } else {
                        content.folders = vm.contentBulkEditModel.folders
                    }
                }
                content.owner_id = vm.contentBulkEditModel.owner_id;
                content.tags = vm.contentBulkEditModel.tags;
                content.available_from = vm.contentBulkEditModel.available_from;
                content.discontinue_from = vm.contentBulkEditModel.discontinue_from;
            });

            //Call the Save Here
            vm.updateProgress = 'complete';
        }
    }
})();
