(function() {
    'use strict';

    angular
        .module('atheer.system')
        .controller('LensAppsController', LensAppsController);

    /* @ngInject */
    function LensAppsController($scope, $state, LensApp, $mdPanel, Workspace, ParseLinks, AlertService, pagingParams, paginationConstants, $mdDialog, $mdToast, $stateParams, triSettings, $location) {
        var vm = this;

        vm.loadingData = false;
        vm.loadAll = loadAll;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.primaryColor = triSettings.getSkinModel().primary_color_hex;
        vm.showRigPartnerStore = false;

        vm.lensApps = [];
        vm.lensAppsData = [];
        
        var host = $location.host();
        if (angular.isDefined(host) && host.startsWith("rigpartner.")) {
            vm.showRigPartnerStore = true;
        }

        function getDevicesList() {
            vm.lensApps.length = vm.lensAppsData.length;
            angular.forEach(vm.lensAppsData, function(app) {
                if (app.type == "APPLE") {
                    vm.lensApps[0] = app;
                } else if (app.type == "ANDROID") {
                    vm.lensApps[1] = app;
                } else if (app.type == "GLASS") {
                    vm.lensApps[2] = app;
                } else if (app.type == "DESKTOP") {
                    vm.lensApps[3] = app;
                }
            });

            vm.loadingData = false;
        }


        vm.showSupoortedDevice = function(lensType) {
            $mdDialog.show({
                templateUrl: 'modules/system/lens-app/lens-app-support-devices.html',
                clickOutsideToClose: true,
                controller: "LensAppSupportDevicesController",
                locals: {
                    filter: lensType
                },
                controllerAs: "vm"
            }).then(function(result) {

            });
        }


        function loadAll() {
            vm.loadingData = true;

            LensApp.query({
                query: 'isLatest==true'
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.lensAppsData = data;
                getDevicesList();
            }

            function onError(error) {
                AlertService.error(error.data.message);
                vm.loadingData = false;
            }
        }

        vm.loadAll();
    }
})();
