(function() {
    'use strict';

    angular
        .module('atheer')
        .controller('RecorderController', RecorderController);

    RecorderController.$inject = ['$element', 'recorderService', 'recorderUtils', '$scope', '$timeout', '$filter', '$interval', 'recorderPlaybackStatus', 'Upload'];

    function RecorderController(element, service, recorderUtils, $scope, $timeout, $filter, $interval, PLAYBACK, Upload) {
        //used in NON-Angular Async process
        var scopeApply = function(fn) {
            var phase = $scope.$root.$$phase;
            if (phase !== '$apply' && phase !== '$digest') {
                return $scope.$apply(fn);
            }
        };

        var createReadOnlyVersion = function(object) {
            var obj = {};
            for (var property in object) {
                if (object.hasOwnProperty(property)) {
                    Object.defineProperty(obj, property, {
                        get: (function(a) {
                            var p = a;
                            return function() {
                                return object[p];
                            }
                        })(property),
                        enumerable: true,
                        configurable: true
                    });
                }
            }
            return obj;
        };


        var blobToDataURL = function(blob, callback) {
            var a = new FileReader();
            a.onload = function(e) {
                callback(e.target.result);
            };
            a.readAsDataURL(blob);
        };

        var control = this,
            timing = null,
            audioObjId = 'recorded-audio-' + control.id,
            status = {
                isRecording: false,
                playback: PLAYBACK.STOPPED,
                isDenied: null,
                isSwfLoaded: null,
                isConverting: false,
                get isPlaying() {
                    return status.playback === PLAYBACK.PLAYING;
                },
                get isStopped() {
                    return status.playback === PLAYBACK.STOPPED;
                },
                get isPaused() {
                    return status.playback === PLAYBACK.PAUSED;
                }
            }

        control.timeLimit = control.timeLimit || 0;
        control.status = createReadOnlyVersion(status);
        control.isAvailable = service.isAvailable();
        control.elapsedTime = 0;
        control.elapsedTimeString = "00:00";
        control.playBackTime = 0;
        control.playBackTimeString = "00:00";
        //Sets ID for the element if ID doesn't exists
        if (!control.id) {
            control.id = recorderUtils.generateUuid();
            element.attr("id", control.id);
        }

        //register controller with service
        service.setController(control.id, this);

        var playbackOnEnded = function() {
            status.playback = PLAYBACK.STOPPED;
            control.onPlaybackComplete();
            control.playBackTime = 0;
            control.playBackTimeString = "00:00";
            $interval.cancel(timing);
            scopeApply();
        };

        var playbackOnPause = function() {
            status.playback = PLAYBACK.PAUSED;
            control.onPlaybackPause();
        };

        var playbackOnStart = function() {
            status.playback = PLAYBACK.PLAYING;
            control.onPlaybackStart();
            timing = $interval(function() {
                if (!status.isPaused) {
                    ++control.playBackTime;
                    var duration = new Date(1970, 0, 1).setSeconds(control.playBackTime);
                    var mask = 'mm:ss';
                    control.playBackTimeString = $filter('date')(duration, mask);
                }
            }, 1000);
        };

        var playbackOnResume = function() {
            status.playback = PLAYBACK.PLAYING;
            control.onPlaybackResume();
        };

        var embedPlayer = function(blob) {
            if (document.getElementById(audioObjId) == null) {
                element.append('<audio type="audio/mp3" id="' + audioObjId + '"></audio>');

                var audioPlayer = document.getElementById(audioObjId);
                if (control.showPlayer) {
                    audioPlayer.setAttribute('controls', '');
                }

                audioPlayer.addEventListener("ended", playbackOnEnded);
                audioPlayer.addEventListener("pause", function(e) {
                    if (this.duration !== this.currentTime) {
                        playbackOnPause();
                        scopeApply();
                    }
                });


                audioPlayer.addEventListener("playing", function(e) {
                    if (status.isPaused) {
                        playbackOnResume();
                    } else {
                        playbackOnStart();
                    }
                    scopeApply();
                });
            }

            if (blob) {
                blobToDataURL(blob, function(url) {
                    document.getElementById(audioObjId).src = url;
                });
            } else {
                document.getElementById(audioObjId).removeAttribute('src');
            }
        };

        control.getAudioPlayer = function() {
            return document.getElementById(audioObjId);
        };

        control.startRecord = function() {
            if (!service.isAvailable()) {
                return;
            }

            if (status.isPlaying) {
                control.playbackPause();
                //indicate that this is not paused.
                status.playback = PLAYBACK.STOPPED;
            }

            //clear audio previously recorded
            control.audioModel = null;

            var id = control.id;
            var recordHandler = service.getHandler();

            //Record initiation based on browser type
            var start = function() {
                if (service.isHtml5) {
                    //HTML5 recording
                    if (!recordHandler) {
                        return;
                    }

                    recordHandler.clear();
                    recordHandler.record();
                } else {
                    //Flash recording
                    if (!service.isReady) {
                        //Stop recording if the flash object is not ready
                        return;
                    }
                    recordHandler.record(id, 'audio.wav');
                }

                status.isRecording = true;
                control.onRecordStart();
                control.elapsedTime = 0;

                timing = $interval(function() {
                    ++control.elapsedTime;
                    var duration = new Date(1970, 0, 1).setSeconds(control.elapsedTime);
                    var mask = 'mm:ss';
                    control.elapsedTimeString = $filter('date')(duration, mask);
                    if (control.timeLimit && control.timeLimit > 0 && control.elapsedTime >= control.timeLimit) {
                        control.stopRecord();
                    }
                }, 1000);
            };

            if (recordHandler) {
                start();
            } else if (!status.isDenied) {
                //probably permission was never asked
                service.showPermission({
                    onDenied: function() {
                        status.isDenied = true;
                        scopeApply();
                    },
                    onAllowed: function() {
                        status.isDenied = false;
                        recordHandler = service.getHandler();
                        start();
                        scopeApply();
                    }
                });
            }
        };

        control.stopRecord = function() {
            var id = control.id;
            if (!service.isAvailable() || !status.isRecording) {
                return false;
            }

            var recordHandler = service.getHandler();
            var completed = function(blob) {
                $interval.cancel(timing);
                status.isRecording = false;
                var finalize = function(inputBlob) {
                    control.audioModel = inputBlob;
                    embedPlayer(inputBlob);
                };
                finalize(blob)
                embedPlayer(null);
                control.onRecordComplete();
            };

            //To stop recording
            if (service.isHtml5) {
                recordHandler.stop();
                recordHandler.getBuffer(function() {
                    recordHandler.exportWAV(function(blob) {
                        completed(blob);
                        scopeApply();
                    });
                });
            } else {
                recordHandler.stopRecording(id);
                completed(recordHandler.getBlob(id));
            }

            service.removeStream();
        };

        control.playbackRecording = function() {
            if (status.isPlaying || !service.isAvailable() || status.isRecording || !control.audioModel) {
                return false;
            }
            control.getAudioPlayer().play();
        };

        control.playbackPause = function() {
            if (!status.isPlaying || !service.isAvailable() || status.isRecording || !control.audioModel) {
                return false;
            }
            control.getAudioPlayer().pause();
        };

        control.save = function() {
            control.onSaveComplete();
            service.refresh();
        };

        control.discard = function() {
            control.audioModel = null;
            control.elapsedTime = 0;
            control.elapsedTimeString = "00:00";
            service.refresh();
        };

        control.isHtml5 = function() {
            return service.isHtml5;
        };

        if (control.autoStart) {
            $timeout(function() {
                control.startRecord();
            }, 1000);
        }

        element.on('$destroy', function() {
            $interval.cancel(timing);
        });
    }
})();
