(function() {
    'use strict';

    angular
        .module('atheer.system')
        .controller('MicrosoftAppDetailController', MicrosoftAppDetailController)

    /* @ngInject */
    function MicrosoftAppDetailController($mdDialog, $scope, $filter, $sce, ParseLinks, pagingParams, paginationConstants, $state, AlertService, $mdToast, $mdSidenav, App, appName, Experience, $location, $http,IntegrationApp) {
        var vm = this;

        vm.data2 = true;
        vm.appModel = null;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;

        vm.query = {
            filter: '',
            limit: '10',
            order: '-id',
            page: 1
        };

        vm.loadData = function() {
            if ($location.search().code != undefined) {
                IntegrationApp.msftoffice({
                    code:$location.search().code,
                    app:appName
                },function(result){debugger
                    $scope.vm.appModel = result;
                    $location.url('/admin/app/msft-office');
                });
            }
            App.getByName({
                id: appName
            }, function(result) {
                $scope.vm.appModel = result;
            });
        }

        vm.onSaveSuccess = function(result) {
            vm.appModel = result;
            vm.isSaving = false;
            AlertService.displayToast($mdToast);
        };

        vm.onSaveError = function() {
            vm.isSaving = false;
        };

        vm.save = function() {
            vm.appModel.app_type = 'SYSTEM';
            App.update(vm.appModel, vm.onSaveSuccess, vm.onSaveError);
        };

        vm.enable = function() {
            vm.appModel.enabled = true;
            vm.appModel.app_type = 'SYSTEM';
            App.update(vm.appModel, vm.onSaveSuccess, vm.onSaveError);
        };

        vm.disable = function() {
            vm.appModel.enabled = false;
            vm.appModel.app_type = 'SYSTEM';
            App.update(vm.appModel, vm.onSaveSuccess, vm.onSaveError);
        };

        vm.togglePanel = function(componentId) {
            $mdSidenav(componentId).toggle();
        };
        vm.loadData();
    }
})();
