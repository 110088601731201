(function () {
    'use strict';

    angular
        .module('atheer')
        .controller('DeeplinkController', DeeplinkController);

    /* @ngInject */
    function DeeplinkController($stateParams, $state, Content, DeeplinkService, PermissionService, Feature, $mdDialog, Auth) {
        var vm = this;

        function onLoadData() {
            var isPublicUser = Auth.isPublicUser();
            if ($stateParams.configType == 'topic') {
                if (Feature.isActive('feature_topic_enable')) {
                    if (isPublicUser) {
                        $state.go('public.topics-detail', {
                            id: $stateParams.id
                        })
                    } else {
                        $state.go('storm.topic-view', {
                            id: $stateParams.id
                        })
                    }
                } else {
                    openNoAccessDialog('deeplink.noAccessForTopics');
                }
            } else if ($stateParams.configType == 'user') {
                if (PermissionService.hasAccess('manage-user')) {
                    $state.go('storm.profile.overview', {
                        id: $stateParams.id
                    });
                } else {
                    openNoAccessDialog('deeplink.noAccessForUsers');
                }
            } else if ($stateParams.configType == 'content') {
                if (Feature.isActive('feature_content_enable')) {
                    if (isPublicUser) {
                        $state.go('public.content-detail', {
                            id: $stateParams.id
                        })
                    } else {
                        $state.go('storm.content-view', {
                            id: $stateParams.id
                        })
                    }
                } else {
                    openNoAccessDialog('deeplink.noAccessForContent');
                }
            }
        };

        function openNoAccessDialog(content) {
            $mdDialog.show({
                templateUrl: 'modules/home/no-access-dialog.tmpl.html',
                clickOutsideToClose: true,
                controller: 'NoAccessController',
                controllerAs: 'vm',
                locals: {
                    content: content,
                    title: 'home.permissionError',
                    isDeeplink: true
                }
            });
        }

        onLoadData();
    }
})();
