(function () {
    'use strict';

    angular
        .module('atheer.user')
        .controller('SessionPlayerSharedZoomController', SessionPlayerSharedZoomController);

    /* @ngInject */
    function SessionPlayerSharedZoomController($mdDialog, $filter, isSharedZoomDisabled, triSettings) {
        var vm = this;

        vm.zoomtitle = null;
        vm.zoomcontent = null;
        vm.iconSrc = null;
        vm.iconColor = triSettings.getSkinModel().primary_color_hex;

        function onLoad() {
            if (isSharedZoomDisabled) {
                vm.iconSrc = "/assets/images/card/shared-zoom-disabled.svg";
                vm.zoomtitle = $filter('translate')('atheer.session.sessionPlayer.sharedZoom.disabled.title');
                vm.zoomcontent = $filter('translate')('atheer.session.sessionPlayer.sharedZoom.disabled.description');
            } else {
                vm.iconSrc = "/assets/images/card/shared-zoom-enabled.svg";
                vm.zoomtitle = $filter('translate')('atheer.session.sessionPlayer.sharedZoom.enabled.title');
                vm.zoomcontent = $filter('translate')('atheer.session.sessionPlayer.sharedZoom.enabled.description');
            }
        };

        vm.close = function () {
            $mdDialog.cancel();
        };

        vm.continue = function () {
            $mdDialog.hide(!isSharedZoomDisabled);
        }

        onLoad();
    }
})();
