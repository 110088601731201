(function() {
    'use strict';
    angular
        .module('atheer.services')
        .factory('AirformReport', AirformReport);

    /* @ngInject */
    function AirformReport($resource) {
        var resourceUrl = 'api/applets';

        return $resource(resourceUrl, {
            id: '@id'
        },{
            'getAirform': {
                url: resourceUrl + '/countByStatus',
                method: 'GET',
                isArray:true,
                params:{
                    daysFilter:'@daysFilter'
                }
            },
            'getAirformExecution': {
                url: resourceUrl + '/execution/countByStatus',
                method: 'GET',
                isArray:true,
                params:{
                    daysFilter:'@daysFilter'
                }
            }
        });
    }
})();
