(function() {
    'use strict';

    angular
        .module('atheer.conversation')
        .controller('ConversationGroupPickerController', ConversationGroupPickerController);

    /* @ngInject */
    function ConversationGroupPickerController($scope, $state, User, allowMultiSelect, filter, Conversation, ParseLinks, AlertService, $mdDialog, Principal, paginationConstants) {
        var vm = this;

        vm.loadAll = loadAll;
        vm.transition = transition;
        vm.removeFilter = removeFilter;
        vm.allowMultiSelect = allowMultiSelect;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.predicate = "id";
        vm.page = 1;

        vm.totalItems = null;
        vm.currentSelectedGroups = [];
        vm.illustartionEmptyScreenId = "group_conv";


        vm.query = {
            filter: '',
            limit: paginationConstants.itemsPerPage,
            order: '-id',
            page: 1
        };

        vm.filter = {
            show: false,
            options: {
                debounce: 500
            }
        };

        vm.getGroupImageUrl = function(group) {
            return group.picture_id ? '/media/pictures/' + group.picture_id : 'assets/images/avatars/group-avatar.png';
        }

        vm.getUsers = function(group) {
            var userInfo = [];
            angular.forEach(group.users_info, function(user_info) {
                userInfo.push(" " + user_info.first_name + " " + user_info.last_name)
            });

            return userInfo.join();
        }

        function loadAll() {
            Conversation.query({
                query: "status==OPEN;type==PUBLIC_GROUP",
                page: 0,
                size: vm.query.limit,
                sort: sort(),
                public_group: true
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.groups = data;
                vm.page = 1;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function transition() {
            vm.promise = Conversation.query({
                query: "status==OPEN;type==PUBLIC_GROUP",
                page: vm.page - 1,
                size: vm.query.limit,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                public_group: true,
                search: vm.currentSearch
            }).$promise;

            vm.promise.then(function(data, headers) {
                vm.queryCount = vm.totalItems;
                vm.groups = data;
            });
        }

        function removeFilter() {
            vm.filter.show = false;
            vm.query.filter = '';

            if (vm.filter.form.$dirty) {
                vm.filter.form.$setPristine();
            }
        }

        vm.updateParentObject = function() {
            $mdDialog.hide(vm.currentSelectedGroups);
        }

        function resetModal() {
            loadAll();
        };

        vm.close = function() {
            $mdDialog.cancel();
        }

        vm.loadAll();
    }
})();
