(function() {
    'use strict';

    angular
        .module('atheer.content')
        .controller('ContentDetailRecognitionController', ContentDetailRecognitionController);

    /* @ngInject */
    function ContentDetailRecognitionController($scope, $filter, $state, CONTENT_META, CONTENT_INSIGHT_META, Content, content_type, id, CONTENT_RECOGNITION_META) {

        var vm = this;

        vm.loadingData = true;

        vm.contentMetaData = angular.copy($filter('filter')(CONTENT_META, {
            type: content_type.replace(/_/g, '-')
        })[0]);

        vm.insightSections = angular.copy(CONTENT_RECOGNITION_META[content_type].sections);

        vm.contentModel = null;

        vm.hasInsights = function() {
            return CONTENT_INSIGHT_META[content_type] != undefined;
        }

        vm.hasRecognition = function() {
            return CONTENT_RECOGNITION_META[content_type] != undefined;
        }

        vm.loadData = function() {
            if (id) {
                Content.get({
                    id: id
                }, function(result) {
                    vm.contentModel = result;
                    vm.loadingData = false;
                });
            }
        }
        vm.loadData();
    }
})();
