(function () {
    'use strict';

    angular
        .module('atheer.session')
        .controller('SessionJoinController', SessionJoinController);

    /* @ngInject */
    function SessionJoinController($state, $filter, Session, $mdDialog, sessionNo, isMobileBrowser, ToastService) {
        var vm = this;

        vm.loadingData = false;
        vm.sessionNo = sessionNo ? sessionNo : null;
        vm.sessionModel = null;
        vm.isMobileBrowser = isMobileBrowser;

        vm.close = function () {
            sessionStorage.removeItem('context');
            $mdDialog.cancel();
        }

        vm.joinSession = function () {
            vm.loadingData = true;

            Session.get({
                id: vm.sessionNo,
                for_launch: true,
                is_session_no: true,
                add_user: true,
                is_web: true
            }, onSuccess, onError);

            function onSuccess(result) {
                if (!result.id && result.result && result.result === "NOT_FOUND") {
                    ToastService.displayToast($filter('triTranslate')('atheer.session.joinSession.expired'), 3000);
                    vm.loadingData = false;

                } else {
                    vm.sessionModel = result;
                    vm.loadingData = false;
                    $mdDialog.cancel();
                    $state.go('storm.sessions.player', {
                        'session_no': vm.sessionModel.session_no
                    });
                }
            }

            function onError(error) {
                ToastService.displayToast($filter('triTranslate')('atheer.session.joinSession.expired'), 3000);
                vm.loadingData = false;
            }
        }
    }
})();
