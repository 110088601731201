(function() {
    'use strict';

    angular
        .module('atheer.content')
        .filter('contentResponseLabel', contentResponseLabel);

    function contentResponseLabel($filter) {
        return contentResponseLabel;

        function contentResponseLabel(input) {
            if (input.contentModel.data.type == 'yes_no_question') {

                if (input.choice) {
                    return 'Yes';
                } else {
                    return 'No';
                }
            } else if (input.contentModel.data.type == 'true_false_question') {
                if (input.choice) {
                    return 'True';
                } else {
                    return 'False';
                }
            } else if (input.contentModel.data.type == 'legal_question') {
                if (input.choice) {
                    return 'Accepted';
                } else {
                    return 'Not Accepted';
                }
            } else {
                var returnStr = '';
                if (input.choices) {
                    angular.forEach(input.choices, function(choice, index) {
                        if (index != 0) {
                            returnStr += ',';
                        }
                        returnStr += ' ' + ($filter('filter')(input.contentModel.data.choices, {
                            choice_id: choice
                        })[0]).label;
                    })
                } else {
                    returnStr = input.choice;
                }
                return returnStr;
            }
        }
    }
})();
