(function () {
    'use strict';

    angular
        .module('atheer.session')
        .service('SessionPlayerService', SessionPlayerService);

    /* @ngInject */
    function SessionPlayerService($mdDialog, $filter, $mdToast, Feature) {

        var jitsiInterfaceConfig = {
            SHOW_JITSI_WATERMARK: false,
            SHOW_WATERMARK_FOR_GUESTS: false,
            TOOLBAR_BUTTONS: [],
            DISPLAY_WELCOME_PAGE_CONTENT: false,
            VIDEO_QUALITY_LABEL_DISABLED: true,
            CONNECTION_INDICATOR_DISABLED: false,
            CONNECTION_INDICATOR_AUTO_HIDE_ENABLED: true,
            SETTINGS_SECTIONS: ['devices'],
            LANG_DETECTION: false
        };

        var jitsiApi;
        var sessionSettings;

        var service = {
            init: init,
            destroy: destroy,
            getInterfaceConfig: getInterfaceConfig,
            setDisplayName: setDisplayName,
            setSubject: setSubject,
            toggleShareScreen: toggleShareScreen,
            toggleAudio: toggleAudio,
            toggleVideo: toggleVideo,
            showSettings: showSettings,
            showVideoQuality: showVideoQuality,
            showKeyboardShortcuts: showKeyboardShortcuts,
            applyZoom: applyZoom,
            hangup: hangup,
            annotate: annotate,
            annotateAR: annotateAR,
            lockArAnnotation: lockArAnnotation,
            startRecording: startRecording,
            stopRecording: stopRecording,
            toggleTileView: toggleTileView,
            toggleFilmStrip: toggleFilmStrip,
            addEventListener: addEventListener,
            addEventListeners: addEventListeners,
            getSessionSettings: getSessionSettings,
            setSessionSettings: setSessionSettings,
            getConferenceTimestatmp: getConferenceTimestatmp,
            hideThumbnails: hideThumbnails,
            setDisplayPicture: setDisplayPicture,
            captureScreenShot: captureScreenShot,
            toggleCamera: toggleCamera,
            setTileView: setTileView,
            setNotifications: setNotifications,
            pinParticipant: pinParticipant,
            setSharedZoom: setSharedZoom,
            requestRemoteControl: requestRemoteControl,
            stopRemoteControl: stopRemoteControl,
            grantHostPermission: grantHostPermission,
            setFollowme: setFollowme,
            setAnnotationMode: setAnnotationMode,
            enableMergeReality: enableMergeReality,
            disableMergeReality: disableMergeReality

        };
        return service;

        function getInterfaceConfig(sessionModel) {
            return jitsiInterfaceConfig;
        };

        function init(api, isRemoteContolEnabled) {
            jitsiApi = api;
            if (window.isDesktop && isRemoteContolEnabled) {
                window.nodeAPI.enableRemoteControlFeature(api);
            }
        };

        function destroy() {
            jitsiApi.dispose();
            jitsiApi = null;
        };

        function setDisplayName(displayName) {
            jitsiApi.executeCommand('displayName', displayName);
        };

        function setSubject(subject) {
            jitsiApi.executeCommand('subject', subject);
        };

        function setTileView(enableTileView) {
            jitsiApi.executeCommand('setTileView', enableTileView);
        };

        function setNotifications(enableNotifications) {
            jitsiApi.executeCommand('setNotifications', enableNotifications);
        };

        function setDisplayPicture(displayPictureURL) {
            jitsiApi.executeCommand('avatarUrl', displayPictureURL);
        };

        function toggleShareScreen() {
            jitsiApi.executeCommand('toggleShareScreen');
        };

        function hangup() {
            jitsiApi.executeCommand('hangup');
        };

        function toggleAudio() {
            jitsiApi.executeCommand('toggleAudio');
        };

        function toggleVideo() {
            jitsiApi.executeCommand('toggleVideo');
        };

        function showSettings() {
            jitsiApi.executeCommand('showSettings');
        };

        function showVideoQuality() {
            jitsiApi.executeCommand('showVideoQuality');
        };

        function showKeyboardShortcuts() {
            jitsiApi.executeCommand('showKeyboardShortcuts');
        };

        function applyZoom(xValue, yValue, zValue, id) {
            jitsiApi.executeCommand('applyZoom', {
                xValue: xValue,
                yValue: yValue,
                zValue: zValue,
                id: id
            })
        };

        function enableMergeReality(enable, expertId, techId) {
            jitsiApi.executeCommand('mergeReality', {
                enable: enable,
                baseId: techId,
                overlayId: expertId
            });
        }

        function disableMergeReality() {
            jitsiApi.executeCommand('mergeReality', {
                enable: false
            });
        }

        function setSharedZoom(isEnabled) {
            jitsiApi.executeCommand('setPrivateZoom', isEnabled);
        };

        function startRecording() {
            jitsiApi.executeCommand('startRecording', {
                mode: 'file',
                dropboxToken: null,
                shouldShare: false,
                rtmpStreamKey: null,
                rtmpBroadcastID: null,
                youtubeStreamKey: null,
                youtubeBroadcastID: null
            });
        };

        function stopRecording() {
            jitsiApi.executeCommand('stopRecording', 'file');
        };

        function toggleTileView() {
            jitsiApi.executeCommand('toggleTileView');
        };

        function toggleFilmStrip() {
            jitsiApi.executeCommand('toggleFilmStrip');
        };

        function annotate(isClassicAnnotation) {
            captureScreenShot(isClassicAnnotation)
        };

        function annotateAR() {
            jitsiApi.executeCommand('getVideoDimensions');
        };

        function lockArAnnotation(targetUserId, isArLocked) {
            jitsiApi.executeCommand('lockArAnnotation', {
                participantId: targetUserId,
                isLocked: isArLocked
            });
        };

        function hideThumbnails(isHide) {
            jitsiApi.executeCommand('hideThumbnails', isHide);
        };

        function captureScreenShot(isClassicAnnotation) {
            jitsiApi.executeCommand('captureScreenShot', isClassicAnnotation);
        };

        function requestRemoteControl(userId) {
            jitsiApi.executeCommand('requestRemoteControl', userId);
        }

        function stopRemoteControl() {
            jitsiApi.executeCommand('stopRemoteControl');
        }

        function toggleCamera() {
            jitsiApi.executeCommand('toggleCamera');
        };

        function pinParticipant(participantId) {
            jitsiApi.executeCommand('pinParticipant', participantId);
        };

        function grantHostPermission(participantId) {
            jitsiApi.executeCommand('grantHostPermission', participantId);
        }

        function setFollowme(status) {
            jitsiApi.executeCommand('setFollowMe', status);
        }

        function setAnnotationMode(status) {
            jitsiApi.executeCommand('setAnnotationMode', status);
        }

        function addEventListener(event, listener) {
            jitsiApi.addEventListener(event, listener);
        };

        function addEventListeners(eventListeners) {
            jitsiApi.addEventListeners(eventListeners);
        };

        function getConferenceTimestatmp() {
            return jitsiApi.getConferenceTimestamp();
        };

        function getSessionSettings() {
            return sessionSettings;
        };

        function setSessionSettings(localSessionSettings) {
            sessionSettings = localSessionSettings;
        };

        /**
         * detect IE
         * returns version of IE or false, if browser is not Internet Explorer
         */
        function detectIE() {
            var ua = window.navigator.userAgent;

            var msie = ua.indexOf('MSIE ');
            if (msie > 0) {
                // IE 10 or older => return version number
                return true;
            }

            var trident = ua.indexOf('Trident/');
            if (trident > 0) {
                return true;
            }

            var edge = ua.indexOf('Edge/');
            if (edge > 0) {
                return true;
            }

            var edg = ua.indexOf('Edg/');
            if (edg > 0) {
                return true;
            }

            // other browser
            return false;
        }
    }
})();
