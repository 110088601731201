(function () {
    'use strict';

    angular.module('atheer.ticket').config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('storm.tickets', {
                url: '/tickets',
                templateUrl: 'modules/ngx-frame.html',
                controller: 'TicketController',
                controllerAs: 'vm',
                data: {
                    pageTitle: 'atheer.ticket.home.title',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showSideNavMenu: false,
                        sideNavMenuPosition: 'left',
                        showToolbar: false,
                        sideNavMenuSize: 15
                    }
                }
            })
    }
})();