(function() {
    'use strict';
    angular
        .module('atheer.services')
        .factory('Category', Category);

    /* @ngInject */
    function Category($resource) {
        var resourceUrl = 'api/categories/:categoryId';

        return $resource(resourceUrl, {
            id: '@categoryId'
        }, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET'
            },
            'save': {
                method: 'POST'
            },
            'update': {
                method: 'PUT'
            },
            'delete': {
                method: 'DELETE'
            }
        });
    }
})();
