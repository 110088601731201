(function () {
    'use strict';

    angular
        .module('atheer.home')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('storm.home', {
                url: '/home',
                templateUrl: 'modules/home/home.tmpl.html',
                controller: 'HomeController',
                controllerAs: 'vm',
                data: {
                    pageTitle: 'global.menu.home',
                    layout: {
                        showSideNavMenu: false,
                        contentClass: 'layout-column',
                        showToolbar: false,
                        footer: false
                    }
                }
            })
            .state('storm.unified-search', {
                url: '/home/search/:id',
                templateUrl: 'modules/home/home.tmpl.html',
                controller: 'HomeController',
                controllerAs: 'vm',
                data: {
                    pageTitle: 'atheer.content.home.title'
                }
            })
    }
})();
