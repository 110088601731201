(function() {
    'use strict';

    angular
        .module('atheer')
        .config(recorderConfig);

    /* @ngInject */
    function recorderConfig(recorderServiceProvider) {
    }
})();
