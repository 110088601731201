(function() {
    'use strict';

    angular
        .module('atheer')
        .run(signedRequestRun);

    function signedRequestRun($rootScope, $state, $location, $log, Base64, AuthServerProvider) {
        var signedRequest = $location.search()['signed_request'];

        if ($location.$$search.signed_request) {
            delete $location.$$search.signed_request;
            $location.$$compose();
        }

        if (angular.isDefined(signedRequest) && signedRequest != null) {
            //AuthServerProvider.storeAuthenticationToken(signedRequest, true);
        }
    }
})();
