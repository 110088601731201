(function () {
    'use strict';

    angular.module('atheer.public').config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('public', {
                abstract: true,
                views: {
                    'root': {
                        templateUrl: 'modules/public/public.tmpl.html'
                    }
                }
            })
            .state('public.home', {
                url: '/public/home',
                templateUrl: 'modules/home/home.tmpl.html',
                controller: 'HomeController',
                controllerAs: 'vm',
                data: {
                    pageTitle: 'global.menu.home'
                }
            })
            .state('public.topics', {
                url: '/public/topics',
                templateUrl: 'modules/ngx-frame.html',
                controller: 'PublicController',
                controllerAs: 'vm',
                data: {
                    pageTitle: 'global.menu.home'
                }
            })
            .state('public.topics-detail', {
                url: '/public/topics/:id',
                templateUrl: 'modules/ngx-frame.html',
                controller: 'PublicController',
                controllerAs: 'vm',
                data: {
                    pageTitle: 'atheer.topic.home.title'
                },
                resolve: {
                    id: function ($stateParams) {
                        return $stateParams.id;
                    }
                }
            })
            .state('public.content-detail', {
                url: '/public/contents/:id',
                templateUrl: 'modules/ngx-frame.html',
                controller: 'PublicController',
                controllerAs: 'vm',
                data: {
                    pageTitle: 'atheer.content.home.title'
                },
                resolve: {
                    id: function ($stateParams) {
                        return $stateParams.id;
                    }
                }
            })
            .state('public.unified-search', {
                url: '/public/home/search/:id',
                templateUrl: 'modules/home/home.tmpl.html',
                controller: 'HomeController',
                controllerAs: 'vm',
                data: {
                    pageTitle: 'atheer.content.home.title'
                }
            })
            .state('public.job-execute', {
                url: '/public/jobs/execute/:id',
                templateUrl: 'modules/ngx-frame.html',
                controller: 'HomeController',
                controllerAs: 'vm',
                data: {
                    pageTitle: 'atheer.job.home.title',
                },
                resolve: {
                    id: function ($stateParams) {
                        return $stateParams.id;
                    }
                }
            })
            .state('public.job-detail', {
                url: '/public/jobs/:id',
                templateUrl: 'modules/ngx-frame.html',
                controller: 'HomeController',
                controllerAs: 'vm',
                data: {
                    pageTitle: 'atheer.job.home.title',
                },
                resolve: {
                    id: function ($stateParams) {
                        return $stateParams.id;
                    }
                }
            })
    }
})();