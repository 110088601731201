(function() {
    'use strict';

    angular
        .module('atheer.services')
        .factory('Principal', Principal)

    /* @ngInject */
    function Principal($rootScope, $q, User, PubNubService, SessionConfigService) {
        var _identity,
            _authenticated = false;

        var service = {
            authenticate: authenticate,
            logout: logout,
            getUserId: getUserId,
            getUserLogin: getUserLogin,
            getUser: getUser,
            getUserInfo: getUserInfo,
            getUserShortName: getUserShortName,
            getUserFullName: getUserFullName,
            getPrimaryTeam: getPrimaryTeam,
            getWorkspaceDomain: getWorkspaceDomain,
            isWorkspaceAdmin: isWorkspaceAdmin,
            isAdmin: isAdmin,
            identity: identity,
            isAuthenticated: isAuthenticated,
            isIdentityResolved: isIdentityResolved,
            getWorkspaceCode: getWorkspaceCode
        };

        return service;

        function authenticate(identity) {
            _identity = identity;
            _authenticated = identity !== null;
        };

        function logout() {
            _identity = undefined;
            _authenticated = false;
        };

        function getUserId() {
            if (!_authenticated || !_identity) {
                return;
            }
            return _identity.id;
        };

        function getUserLogin() {
            if (!_authenticated || !_identity) {
                return;
            }
            return _identity.login;
        };

        function getWorkspaceDomain() {
            if (!_authenticated || !_identity) {
                return;
            }
            return _identity.workspace_info.domain;
        };

        function getUserInfo() {
            if (!_authenticated || !_identity) {
                return;
            }
            var user = _identity;
            return user;
        };

        function getUserShortName() {
            var shortName = "";

            if (!_authenticated || !_identity) {
                return;
            }

            if (_identity.first_name) {
                shortName = shortName + _identity.first_name.charAt(0).toUpperCase();
            }
            if (_identity.last_name) {
                shortName = shortName + _identity.last_name.charAt(0).toUpperCase();
            } else {
                shortName = shortName + ".";
            }
            return shortName;
        };

        function getUserFullName() {
            if (!_authenticated || !_identity) {
                return;
            }
            return _identity.first_name + ' ' + _identity.last_name;
        };

        function getUser() {
            if (!_authenticated || !_identity) {
                return;
            }
            var user = angular.copy(_identity);
            user.permissions = null;
            user.permissionSet = null;
            return user;
        };

        function getPrimaryTeam() {
            if (!_authenticated || !_identity) {
                return;
            }

            if (_identity.audiences && _identity.audiences.length > 0) {
                if (_identity.audiences.length == 1) {
                    return _identity.audiences[0]
                } else {
                    return _identity.audiences[1];
                }
            }
        };

        function isWorkspaceAdmin() {
            if (!_authenticated || !_identity) {
                return false;
            } else {
                var login = _identity.login;
                if (login == 'admin' || login.endsWith("@masterair.com")) {
                    return true;
                } else {
                    return false;
                }
            }
            return _identity.id;
        };

        function isAdmin() {
            if (!_authenticated || !_identity) {
                return false;
            } else {
                return _identity.permissionSet.length > 0;
            }
        };

        function getWorkspaceCode() {
            if (!_authenticated || !_identity) {
                return;
            } else {
                return _identity.workspace_info.code;
            }
        }

        function identity(force) {
            var deferred = $q.defer();

            if (force === true) {
                _identity = undefined;
            }

            // check and see if we have retrieved the identity data from the server.
            // if we have, reuse it by immediately resolving
            if (angular.isDefined(_identity)) {
                deferred.resolve(_identity);
                return deferred.promise;
            }

            // retrieve the identity data from the server, update the identity object, and then resolve.
            User.getFullUser({
                    id: 'me'
                }).$promise
                .then(getAccountThen)
                .catch(getAccountCatch);

            return deferred.promise;

            function getAccountThen(account) {
                _identity = account;
                _authenticated = true;

                _identity.permissionSet = [];

                angular.forEach(account.permissions, function(permission) {
                    var objectName = permission.object_name;
                    var permissionName = permission.permission_name;

                    _identity.permissionSet.push(permissionName + "-" + objectName);
                });

                var login = _identity.login;
                if (angular.equals(login, "admin")) {
                    $rootScope.isWorkspaceAdmin = true;
                } else {
                    $rootScope.isWorkspaceAdmin = false;
                }

                PubNubService.init(_identity.id, _identity.messaging_config, _identity.channels, _identity.permissionSet);
                SessionConfigService.init(_identity.session_config);

                $rootScope.$broadcast('identityResolved', _identity);
                deferred.resolve(_identity);
            }

            function getAccountCatch() {
                _identity = null;
                _authenticated = false;
                deferred.resolve(_identity);
            }
        };

        function isAuthenticated() {
            return _authenticated;
        };

        function isIdentityResolved() {
            return angular.isDefined(_identity);
        };
    }
})();
