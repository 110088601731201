(function() {
    'use strict';

    angular
        .module('atheer.sdk')
        .controller('SdkWebHookDetailLogInfoController', SdkWebHookDetailLogInfoController);

    /* @ngInject */
    function SdkWebHookDetailLogInfoController($scope, $state, $mdDialog, $q, $mdToast, webHookExecution, triSettings) {
        var vm = this;

        vm.webHookExecution = webHookExecution;
        vm.primaryColor = triSettings.getSkinModel().primary_color_hex;
        vm.close = function() {
            $mdDialog.cancel();
        };
    };
})();
