(function() {
    'use strict';

    angular
        .module('atheer.system')
        .controller('EmailSettingController', EmailSettingController);

    /* @ngInject */
    function EmailSettingController($scope, $state, $q, Setting, $filter, ToastService) {
        var vm = this;

        vm.loadingData = false;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.emailSetting = [];
        vm.emailModel = {
            from_address: null,
            name: null,
            email_layout: null,
            logo_url: 'https://sitecdn.atheer.ai/wp-content/uploads/2017/04/atheer-logo.png'
        };
        vm.emailLayouts = [];
        vm.view = 'EDIT';
        vm.previewContent = null;

        vm.loadData = function() {
            vm.loadingData = true;

            $q.all([
                    Setting.getEmailSettings().$promise
                ])
                .then(function(results) {
                    setEmailModel(results[0]);
                    vm.emailLayouts = results[1];
                    vm.loadingData = false;
                });
        };

        vm.changeView = function(viewType) {
            vm.view = viewType;

            if(viewType == 'PREVIEW') {
                vm.previewContent = vm.emailLayouts[0].body;
                vm.previewContent = vm.previewContent.replace("{{content}}", vm.emailContent.data.content);
            }
        }

        function setEmailModel(settings) {
            angular.forEach(settings, function(setting) {
                if (setting.category == 'email_info') {
                    vm.emailSetting = setting;

                    angular.forEach(setting.fields, function(settingField) {
                        if (settingField.name == 'from_address') {
                            vm.emailModel.from_address = settingField.value;
                        }
                        if (settingField.name == 'name') {
                            vm.emailModel.name = settingField.value;
                        }
                        if (settingField.name == 'email_layout') {
                            vm.emailModel.email_layout = settingField.value;
                        }
                        if (settingField.name == 'logo_url') {
                            vm.emailModel.logo_url = settingField.value;
                        }
                    });
                }
            });
        }

        function setEmailSettings() {
            setEmailSettingField("from_address", vm.emailModel.from_address);
            setEmailSettingField("name", vm.emailModel.name);
            setEmailSettingField("email_layout", vm.emailModel.email_layout);
            setEmailSettingField("logo_url", vm.emailModel.logo_url);
        }

        function setEmailSettingField(fieldName, fieldValue) {
            var foundField = false;
            angular.forEach(vm.emailSetting.fields, function(settingField) {
                if (settingField.name == fieldName) {
                    settingField.value = fieldValue;
                    foundField = true;
                }
            });

            if (!foundField) {
                var settingField = {
                    name: fieldName,
                    value: fieldValue,
                }
                vm.emailSetting.fields.push(settingField);
            }
        }

        vm.save = function() {
            setEmailSettings();
            $q.all([
                    Setting.update(vm.emailSetting).$promise
                ])
                .then(function(results) {
                    ToastService.displayToast($filter('triTranslate')("atheer.setting.success"), 3000);
                });
        }
        vm.loadData();
    }
})();
