(function() {
    'use strict';

    angular
        .module('atheer.conversation')
        .controller('ConversationsController', ConversationsController);

    /* @ngInject */
    function ConversationsController($scope, $http, $state, Setting, $q, ToastService, $filter, AlertService, Message, $mdDialog, $mdToast, $stateParams, Principal, Feature, PermissionService, User, Conversation, ConversationModelService) {
        var vm = this;

        vm.illustartionEmptyScreenId = "conversation";
        vm.loadingData = false;
        vm.userId = Principal.getUserId();

        vm.newConverationModel = {
            conversation_no: null,
            name: null,
            purpose: null,
            topic: null,
            status: 'OPEN',
            type: 'PRIVATE_USER',
            users: null
        };

        function loadData() {
            vm.loadingData = true;

            User.getConversations({
                query: getOpenConversationQuery(),
                page: 0,
                size: 100
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                if (data.length > 0) {
                    $state.go('storm.conversations-detail', {
                        'id': data[0].id
                    });
                } else {
                    vm.loadingData = false;
                }
            };

            function onError(error) {
                AlertService.error(error.data.message);
                vm.loadingData = false;
            };
        };

        function getOpenConversationQuery() {
            return "status==OPEN"
        }

        function createPersonalConversation(users) {
            var conversation = angular.copy(vm.newConverationModel);
            var conversationUsers = [];

            conversationUsers.push({
                user_id: vm.userId,
                role: 'OWNER'
            });

            angular.forEach(users, function(user) {
                conversationUsers.push({
                    user_id: user.id,
                    role: 'OWNER'
                })
            });

            conversation.users = conversationUsers;
            createConversation(conversation);
        };

        function createGroupConversation(conversation) {
            createConversation(conversation);
        };

        function createConversation(conversation) {
            Conversation.save(conversation, onSaveSuccess, onSaveError);

            function onSaveSuccess(result) {
                //publish conversation to users
                ConversationModelService.publishNewConversation(vm.userId, result, 'REFRESH_CONVERSATION');

                ToastService.displayToast($filter('translate')('atheer.conversation.conversationCreated'), 3000);

                $state.go('storm.conversations-detail', {
                    'id': result.id
                });
            };

            function onSaveError() {

            };
        };

        function joinCoversastion(conversation) {
            Conversation.join({
                id: conversation.id,
            }, onJoinSuccess, onJoinError);

            function onJoinSuccess(result) {
                //publish conversation to users
                ConversationModelService.publishNewConversation(vm.userId, result, 'REFRESH_CONVERSATION');

                ToastService.displayToast($filter('translate')('atheer.conversation.conversationJoined'), 3000);

                $state.go('storm.conversations-detail', {
                    'id': result.id
                });
            };

            function onJoinError() {

            };
        };

        vm.startPeronsalConversation = function() {
            $mdDialog.show({
                    templateUrl: 'modules/user/user-picker.tmpl.html',
                    controller: 'UserPickerController',
                    controllerAs: 'vm',
                    locals: {
                        filter: vm.invitedUserIds,
                        allowMultiSelect: false,
                        userslist: [],
                        isSession: false,
                        isAdmin: false,
                        isScheduledSession: false,
                        isConversationSession: false,
                        isSmartScan: false
                    }
                })
                .then(function(selectedItems) {
                    createPersonalConversation(selectedItems);
                });
        };

        vm.startGroupConversation = function() {
            $mdDialog.show({
                templateUrl: 'modules/conversation/conversation-group-dialog.tmpl.html',
                clickOutsideToClose: true,
                controller: "ConversationGroupController",
                controllerAs: "vm",
                locals: {
                    filter: null
                }
            }).then(function(conversation) {
                createGroupConversation(conversation);
            }).finally(function() {});
        }

        vm.joinGroupConversation = function() {
            $mdDialog.show({
                    templateUrl: 'modules/conversation/conversation-group-picker.tmpl.html',
                    controller: 'ConversationGroupPickerController',
                    controllerAs: 'vm',
                    locals: {
                        filter: null,
                        allowMultiSelect: false
                    }
                })
                .then(function(conversations) {
                    joinCoversastion(conversations[0]);
                });
        }
        loadData();
    }
})();
