(function() {
    'use strict';

    angular
        .module('atheer.system')
        .controller('AuthenticationOIDCController', AuthenticationOIDCController);

    /* @ngInject */
    function AuthenticationOIDCController($scope, $state, $q, Setting, $filter, $mdDialog, ToastService) {
        var vm = this;

        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.loadingData = false;
        vm.configSettings = [];

        vm.applicationId = null;
        vm.tenantId = null;
        vm.discoverUri = null;
        vm.authUri = null;
        vm.tokenUri = null;
        vm.signInLabel = null;
        vm.logoutUri = null;

        vm.loadData = function() {
            vm.loadingData = true;

            $q.all([
                    Setting.getAuthenticationConfigSettings().$promise
                ])
                .then(function(results) {
                    setConfigFields(results[0]);

                    vm.loadingData = false;
                });
        };

        function setConfigFields(settings) {
            angular.forEach(settings, function(setting) {
                if (setting.category == 'authentication-config') {
                    vm.configSettings = setting;
                    angular.forEach(setting.fields, function(settingField) {
                        if (settingField.name == 'oidc_application_id') {
                            vm.applicationId = settingField.value;
                        } else if (settingField.name == 'oidc_tenant_id') {
                            vm.tenantId = settingField.value;
                        } else if (settingField.name == 'oidc_discover_uri') {
                            vm.discoverUri = settingField.value;
                        } else if (settingField.name == 'oidc_auth_uri') {
                            vm.authUri = settingField.value;
                        } else if (settingField.name == 'oidc_token_uri') {
                            vm.tokenUri = settingField.value;
                        } else if (settingField.name == 'oidc_sign_in_label') {
                            vm.signInLabel = settingField.value;
                        } else if (settingField.name == 'oidc_logout_uri') {
                            vm.logoutUri = settingField.value;
                        }
                    });
                }
            });
        }

        function setConfigSettings() {
            if(vm.configSettings.fields == undefined) {
                vm.configSettings.fields = [];
            };

            setSettingField("oidc_application_id", vm.applicationId);
            setSettingField("oidc_tenant_id", vm.tenantId);
            setSettingField("oidc_discover_uri", vm.discoverUri);
            setSettingField("oidc_auth_uri", vm.authUri);
            setSettingField("oidc_token_uri", vm.tokenUri);
            setSettingField("oidc_sign_in_label", vm.signInLabel);
            setSettingField("oidc_logout_uri", vm.logoutUri);
        };

        function setSettingField(fieldName, fieldValue) {
            var foundField = false;

            angular.forEach(vm.configSettings.fields, function(settingField) {
                if (settingField.name == fieldName) {
                    settingField.value = fieldValue;
                    foundField = true;
                }
            });

            if (!foundField) {
                var settingField = {
                    name: fieldName,
                    value: fieldValue,
                }
                vm.configSettings.fields.push(settingField);
            }
        };

        vm.save = function() {
            setConfigSettings();

            Setting.update(vm.configSettings, function() {
                ToastService.displayToast($filter('triTranslate')("atheer.setting.success"));
            });
        };

        vm.loadData();
    }
})();
