(function () {
    'use strict';

    angular
        .module('atheer.session')
        .directive('sessionInsights', sessionInsights);

    function sessionInsights() {
        var directive = {
            scope: {
                sessionModel: '='
            },
            bindToController: true,
            controller: sessionInsightsController,
            controllerAs: 'vm',
            templateUrl: 'modules/session/session-insights.tmpl.html',
            restrict: 'E'
        };
        return directive;
    };

    /* @ngInject */
    function sessionInsightsController($scope, $mdDialog, $mdSidenav, triTheming, triSettings, $mdToast, $document, $location, $mdPanel, $state, $timeout, $q, $filter, ParseLinks, Principal, Session, Experience, AlertService, paginationConstants, $http) {
        var vm = this;

        vm.loadingData = false;
        vm.transition = transition;
        vm.pageSize = paginationConstants.itemsPerPage;
        vm.sessionActivities = null;
        vm.session_activities = [];
        vm.participantsList = [];
        vm.guestList = [];
        vm.loadingActivities = false;

        vm.feedbackPage = 0;
        vm.ratingAndCommentsActivities = [];
        vm.feedbackActivities = [];
        vm.feedbackPageSize = paginationConstants.itemsPerPage;
        vm.loadingFeedbackActivities = false;
        vm.isLoadingFeedback = false;
        vm.backgroundColor = triTheming.rgba(
            triTheming.getPaletteColor(triSettings.getSkinModel().primary_color, '500').value
        );

        vm.togglePanel = function () {
            $mdSidenav('insightsPanel').toggle()
                .then(function () {

                });
        };

        vm.downloadReport = function () {
            var url = '/api/sessions/pdf/' + vm.sessionModel.id;

            $http.get(url, { responseType: 'blob' }).then(function (response) {
                var objectUrl = URL.createObjectURL(response.data);
                var link = document.createElement('a');
                link.download = vm.sessionModel.session_no,
                    link.href = objectUrl;
                link.click();
            })

        }

        function transition() {
            vm.loadingActivities = true;
            Experience.getSessionActivities({
                page: vm.page - 1,
                size: vm.pageSize,
                sort: sort(),
                query: getQueryString(),
                user_info: true
            }, onTSuccess, onTError);

            function onTSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.queryCount = headers('X-Total-Count');
                vm.sessionActivities = data;
                getSessionActivities();
                getLineHeight();
                vm.loadingActivities = false;
            }

            function onTError(error) {
                AlertService.error(error.data.message);
            }
        };

        function sort() {
            return 'id,asc';
        };

        function getQueryString() {
            var queryString = "object_id==" + vm.sessionModel.id;
            return queryString;
        };

        function loadData() {
            if (!vm.sessionModel) {
                return;
            }

            vm.loadingData = true;
            vm.isLoadingFeedback = true;

            Experience.getSessionActivities({
                page: 0,
                size: vm.pageSize,
                sort: sort(),
                query: getQueryString(),
                user_info: true
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.queryCount = headers('X-Total-Count');
                vm.sessionActivities = data;
                vm.page = 1;
                vm.loadingData = false;
                getSessionActivities();
                getLineHeight();
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }

            getParticipantsList();
            getRatingsAndComments();
        };

        function getRatingsAndComments() {
            Experience.getSessionRatingAndComments({
                page: 0,
                size: vm.feedbackPageSize,
                sort: sort(),
                query: getRatingAndCommentsQueryString(),
                user_info: true
            }, onSuccess, onError);

            function onSuccess(result, headers) {
                vm.feedbackLinks = ParseLinks.parse(headers('link'));
                vm.feedbackQueryCount = headers('X-Total-Count');
                vm.ratingAndCommentsActivities = result;
                vm.feedbackPage = 1;
                vm.isLoadingFeedback = false;

                getFeedbackDetails();
            };

            function onError(error) {
                AlertService.error(error.data.message);
            };
        };

        function getRatingAndCommentsQueryString() {
            return "action_type==FEEDBACK;object_id==" + vm.sessionModel.id;
        };

        function getFeedbackDetails() {
            vm.feedbackActivities = [];
            angular.forEach(vm.ratingAndCommentsActivities, function (ratingAndCommentActivity) {
                if (ratingAndCommentActivity.activity_type == 'FEEDBACK') {
                    vm.feedbackActivities.push(ratingAndCommentActivity);
                }
            });
        };

        function getLineHeight() {
            setTimeout(function () {
                angular.forEach(vm.session_activities, function (session_activity, index) {
                    var line = document.getElementById('activity-line-' + index);
                    line.style.height = (session_activity.activities.length == 1 ? 59 : 76 * session_activity.activities.length - 17) + 'px';
                })
            }, 500);
        };

        function getSessionActivities() {
            vm.session_activities = [];
            angular.forEach(vm.sessionActivities, function (sessionActivity) {
                var created_on_1 = moment(sessionActivity.created_on).format('LT');
                var exists = false;
                var full_name = sessionActivity.user_info.first_name + " " + sessionActivity.user_info.last_name;
                angular.forEach(vm.session_activities, function (session_activity) {
                    var created_on_2 = session_activity.created_on;
                    if (angular.equals(created_on_1, created_on_2)) {
                        exists = true;
                        var obj = {
                            activity: sessionActivity.activity_type,
                            activator: full_name,
                            user_info: sessionActivity.user_info
                        };
                        session_activity.activities.push(obj);
                    }
                });
                if (!exists && sessionActivity.id != "") {
                    var obj = {
                        created_on: created_on_1,
                        activities: [
                            {
                                activity: sessionActivity.activity_type,
                                activator: full_name,
                                user_info: sessionActivity.user_info
                            }
                        ]
                    };
                    vm.session_activities.push(obj);
                }
            });
        };

        vm.getProfilePicture = function (user_info) {
            return user_info.picture_id ? '/media/pictures/' + user_info.picture_id : 'assets/images/avatars/user-avatar.png';
        };

        vm.feedbackTransition = function () {
            vm.loadingFeedbackActivities = true;
            Experience.getSessionRatingAndComments({
                page: vm.feedbackPage - 1,
                size: vm.feedbackPageSize,
                sort: sort(),
                query: getRatingAndCommentsQueryString(),
                user_info: true
            }, onTSuccess, onTError);

            function onTSuccess(result, headers) {
                vm.feedbackLinks = ParseLinks.parse(headers('link'));
                vm.feedbackQueryCount = headers('X-Total-Count');
                vm.ratingAndCommentsActivities = result;
                getFeedbackDetails();
                vm.loadingFeedbackActivities = false;
            };

            function onTError(error) {
                AlertService.error(error.data.message);
            };
        };

        function getParticipantsList() {
            vm.participantsList = [];
            vm.guestList = [];
            if (vm.sessionModel.owner_info) {
                var obj = {
                    user_id: vm.sessionModel.owner_info.id,
                    first_name: vm.sessionModel.owner_info.first_name,
                    last_name: vm.sessionModel.owner_info.last_name,
                    picture_id: vm.sessionModel.owner_info.picture_id,
                    joined: false,
                };

                vm.participantsList.push(obj);
            }

            if (vm.sessionModel.session_users_info) {
                angular.forEach(vm.sessionModel.session_users, function (sessionUser) {
                    angular.forEach(vm.sessionModel.session_users_info, function (sessionUserInfo) {
                        var obj = {
                            user_id: sessionUserInfo.id,
                            first_name: sessionUserInfo.first_name,
                            last_name: sessionUserInfo.last_name,
                            picture_id: sessionUserInfo.picture_id,
                            joined: false,
                        }
                        if (!sessionUser.is_guest) {
                            if (sessionUser.user_id == sessionUserInfo.id) {
                                vm.participantsList.push(obj);
                            }
                        } else {
                            if (sessionUser.user_id == sessionUserInfo.id) {
                                vm.guestList.push(obj);
                            }
                        }
                    });
                });
            }

            angular.forEach(vm.sessionModel.session_joined_users_info, function (sessionJoinedUserInfo) {
                angular.forEach(vm.participantsList, function (participant) {
                    var joined = false;
                    if (participant.user_id == sessionJoinedUserInfo.id) {
                        participant.joined = true;
                    }
                });
                angular.forEach(vm.guestList, function (participant) {
                    var joined = false;
                    if (participant.user_id == sessionJoinedUserInfo.id) {
                        participant.joined = true;
                    }
                });
            });
        };

        function getParticipantDetails(sessionUser) {

        }

        $scope.$watch('vm.sessionModel', function (newValue) {
            if (angular.isDefined(vm.sessionModel) && vm.sessionModel != null) {
                loadData();
            }
        });
    };
})();
