(function() {
    'use strict';

    angular
        .module('atheer.user')
        .controller('ForcedPasswordController', ForcedPasswordController);

    /* @ngInject */
    function ForcedPasswordController($scope, $state, $location, $q, Auth, Principal, User, Feature, triSettings, JhiLanguageService, $translate, $filter, ToastService) {
        var vm = this;

        vm.useDarkBackGroundLogos = true;
        vm.changePassword = changePassword;
        vm.cancelChange = cancelChange;
        vm.doNotMatch = null;
        vm.error = null;
        vm.success = null;
        vm.user = null;
        vm.currentPassword = null;
        vm.minLength = 8;
        vm.maxLength = 12;
        vm.forcedPasswordEnabled = false;
        vm.password = null;
        vm.confirmPassword = null;

        vm.userId = Principal.getUserId();

        vm.loadData = function() {
            vm.loadingData = true;
            vm.useDarkBackGroundLogos = triSettings.getSkinModel().use_dark_logos;

            $q.all([
                User.getById({
                    id: vm.userId
                }).$promise
            ]).then(function(data) {
                vm.user = data[0];
                vm.forcedPasswordEnabled = vm.user.forced_password_enabled;
                vm.loadingData = false;

            }).catch(function(data) {}).finally(function() {});

            JhiLanguageService.getAll().then(function(languages) {
                vm.languages = languages;
            });
        }

        function cancelChange() {
            $location.path('/signin');
        }

        function checkPasswordReq() {
            if (vm.password == null || vm.confirmPassword == null) {
                return false;
            }
            if (vm.password !== vm.confirmPassword) {
                ToastService.displayToast($filter('triTranslate')('atheer.forcedPassword.passwordsDontMatch'), 2000);
                return false;
            }

            if (vm.password.length < vm.minLength || vm.password.length > vm.maxLength) {
                return false;
            }

            var pattern=  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,12}$/;
            if (!vm.password.match(pattern)) {
                ToastService.displayToast($filter('triTranslate')('atheer.forcedPassword.passwordDoesntMeetRequirement'), 2000);
                return false;
            }

            return true;
        }

        function changePassword() {
            if (!checkPasswordReq()) {
                vm.error = null;
                vm.success = null;
                vm.doNotMatch = 'ERROR';
            } else {
                changeMyPassword();
            }
        }

        function changeMyPassword() {
            vm.doNotMatch = null;

            var passwordInfo = {
                invitation_key: null,
                notify_user: false,
                new_password: vm.password,
                forced_password_enabled: false
            };

            var promise = User.forceMePassword({
            }, passwordInfo).$promise;

            promise.then(function() {
                vm.error = null;
                vm.success = 'OK';

                ToastService.displayToast($filter('triTranslate')('atheer.forcedPassword.passwordChangedSuccessfully'), 2000);
                vm.currentPassword = null;
                $location.path("/");
            }).catch(function() {
                vm.success = null;
                vm.error = 'ERROR';

                ToastService.displayToast($filter('triTranslate')('atheer.forcedPassword.incorrectPassword'), 2000);
            });
        }
        vm.loadData();
    }
})();
