(function() {
    'use strict';

    angular
        .module('atheer.system')
        .controller('AuthenticationSamlController', AuthenticationSamlController);

    /* @ngInject */
    function AuthenticationSamlController($scope, $state, $q, Setting, $filter, $mdDialog, ToastService) {
        var vm = this;

        vm.loadingData = false;
        vm.configSettings = [];
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.samlMetaData = null;

        vm.loadData = function() {
            vm.loadingData = true;

            $q.all([
                    Setting.getAuthenticationConfigSettings().$promise
                ])
                .then(function(results) {
                    setConfigFields(results[0]);
                    vm.loadingData = false;
                });
        };

        function setConfigFields(settings) {
            angular.forEach(settings, function(setting) {
                if (setting.category == 'authentication-config') {
                    vm.configSettings = setting;
                    angular.forEach(setting.fields, function(settingField) {
                        if (settingField.name == 'saml_metadata') {
                            vm.samlMetaData = settingField.value;
                        }
                    });
                }
            });
        }

        function setConfigSettings() {
            if(vm.configSettings.fields == undefined) {
                vm.configSettings.fields = [];
            };

            setSettingField("saml_metadata", vm.samlMetaData);
        };

        function setSettingField(fieldName, fieldValue) {
            var foundField = false;

            angular.forEach(vm.configSettings.fields, function(settingField) {
                if (settingField.name == fieldName) {
                    settingField.value = fieldValue;
                    foundField = true;
                }
            });

            if (!foundField) {
                var settingField = {
                    name: fieldName,
                    value: fieldValue,
                }
                vm.configSettings.fields.push(settingField);
            }
        };

        vm.save = function() {
            setConfigSettings();

            Setting.update(vm.configSettings, function() {
                ToastService.displayToast($filter('triTranslate')("atheer.setting.success"));
            });
        };

        vm.showSPMetaData = function() {
            $mdDialog.show({
                animation: true,
                templateUrl: 'modules/system/authentication/authentication-saml-sp-dialog.tmpl.html',
                controller: 'AuthenticationSamlSPDialogController',
                controllerAs: 'vm',
                backdrop: 'static'
            });
        }

        vm.loadData();
    }
})();
