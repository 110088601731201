(function () {
    'use strict';

    angular
        .module('atheer.smartFlow')
        .directive('jobFlowObjectBuilder', jobFlowObjectBuilderDirective);

    /* @ngInject */
    function jobFlowObjectBuilderDirective() {
        var directive = {
            restrict: 'E',
            require: '^jobFlowObjectBuilder',
            replace: true,
            templateUrl: 'modules/smartflow/jobflow-object-builder.tmpl.html',
            scope: {
                setting: '=',
                type: '=',
                name: '='
            },
            controller: jobFlowObjectBuilderController,
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;
    }

    /* @ngInject */
    function jobFlowObjectBuilderController($scope) {
        var vm = this;

        vm.object;

        var object = {
            key_name: "key",
            key_label: "Key",
            key_description: "Key",
            key_type: "text",
            key_value: "",
            value_name: "value",
            value_label: "Value",
            value_description: "Value",
            value_type: "text",
            value_value: ""
        };

        function init() {
            vm.object = [];
            var keys = Object.keys(vm.setting);
            if (keys && keys.length > 0) {
                angular.forEach(keys, function (key) {
                    vm.addField(key);
                });
            } else {
                vm.addField();
            }
        }

        vm.addField = function (key) {
            var obj = angular.copy(object);
            obj.key_value = key ? key : '';
            if (vm.type === 'OBJECT_LIST') {
                vm.setting[obj.key_value] = vm.setting[obj.key_value] ? vm.setting[obj.key_value] : [];
            }
            vm.object.push(obj);
        };

        vm.removeField = function (id, object) {
            delete vm.setting[object.key_value];
            vm.object.splice(id, 1);
        }

        vm.onkeydown = function (event, obj) {
            if (vm.type === 'OBJECT_LIST') {
                if (event.keyCode === 9) {
                    return;
                }
                if (event.keyCode === 8 && obj.key_value !== "") {
                    vm.setting[obj.key_value.substring(0, obj.key_value.length - 1)] = vm.setting[obj.key_value];
                } else {
                    vm.setting[obj.key_value + event.key] = vm.setting[obj.key_value];
                }
                delete vm.setting[obj.key_value];
            }
        }

        init();

    }
})();
