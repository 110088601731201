(function() {
    'use strict';

    angular
        .module('atheer')
        .config(translationConfig);

    /* @ngInject */
    function translationConfig($translateProvider, tmhDynamicLocaleProvider) {
        $translateProvider.determinePreferredLanguage();
        $translateProvider.useStorage('translationStorageProvider');
        $translateProvider.useSanitizeValueStrategy('escaped');
        $translateProvider.addInterpolation('$translateMessageFormatInterpolation');
        $translateProvider.useLoader('dictionaryHandler', {
            prefix: 'i18n/studio/studio-',
            suffix: '.json?timestamp=' + new Date().getTime()
        });

        tmhDynamicLocaleProvider.localeLocationPattern('i18n/angular-locale_{{locale.replace("_", "-") | lowercase}}.js');
        tmhDynamicLocaleProvider.useCookieStorage();
        tmhDynamicLocaleProvider.storageKey('NG_TRANSLATE_LANG_KEY');
    }
})();
