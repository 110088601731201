(function () {
    'use strict';

    angular
        .module('atheer.content')
        .controller('DocumentDetailController', DocumentDetailController)
        .controller('DocumentViewController', DocumentViewController)
        .controller('DocumentFeedbackController', DocumentFeedbackController)

    /* @ngInject */
    function DocumentFeedbackController($scope, $mdBottomSheet) {
    }

    /* @ngInject */
    function DocumentViewController($scope, $controller, $mdBottomSheet) {

        var basectrl = $controller('BaseContentViewController as vm', {
            $scope: $scope
        });
        angular.extend(this, basectrl);

        $scope.showFeedback = function (firstTime) {

            $mdBottomSheet.show({
                templateUrl: 'modules/content/document-feedback.tmpl.html',
                clickOutsideToClose: false,
                escapeToClose: false,
                controller: 'DocumentFeedbackController',
                scope: $scope,
                preserveScope: true,
                parent: '#fp-content-view-' + $scope.data.id + '-' + $scope.context,
                disableParentScroll: true,
                locals: {
                    firstTime: firstTime
                }
            });
        }
    }

    /* @ngInject */
    function DocumentDetailController($scope, $timeout, $controller, $interval, id, Content, poller, $resource, triLoaderService) {
        var basectrl = $controller('BaseContentDetailController as vm', {
            $scope: $scope,
            id: id,
            content_type: "document"
        });
        angular.extend(this, basectrl);

        var vm = this;

        vm.uploadStatus = 'idle';
        vm.processStatus = 'IDLE';
        vm.extractStatus = 'IDLE';
        vm.isDocumentForAI = false;

        vm.isExternalDocument = false;
        vm.fileMaxSize = 500 * 1024 * 1024;
        triLoaderService.setLoaderActive(false);


        vm.createDocument = function (mediaId, responseObj, isRequiredForProcessing, documentTags) {
            vm.processStatus = 'UPLOADED';

            vm.contentModel.title = responseObj.name;
            vm.contentModel.description = responseObj.description;
            vm.contentModel.data.source_media = responseObj;
            vm.contentModel.data.doc_status = 'UPLOADED';
            vm.contentModel.external_id = responseObj.external_id;
            vm.contentModel.process_document = isRequiredForProcessing;
            vm.isDocumentForAI = isRequiredForProcessing;
            vm.contentModel.tags = documentTags;
            vm.save();

            vm.checkDocumentStatus();
        }

        $scope.$watch('vm.contentModel', function (newVal) {
            if (!vm.isNew()) {
                vm.checkDocumentStatus();
            }
        });

        vm.checkDocumentStatus = function () {
            if ((vm.contentModel.data.doc_status == 'UPLOADED' || vm.contentModel.data.doc_status == 'PROCESSING') && vm.contentModel.id) {
                vm.processStatus = vm.contentModel.data.doc_status;

                // Get poller.
                var documentStatusPoller = poller.get(Content, {
                    action: 'get',
                    delay: 3000,
                    argumentsArray: [{
                        id: vm.contentModel.id
                    }],
                    smart: true
                });

                documentStatusPoller.promise.then(null, null, function (result) {
                    if (result.data.doc_status == 'READY') {
                        poller.stopAll();
                        vm.processStatus = 'COMPLETE';
                        
                        $timeout(function () {
                            vm.processStatus = 'READY';
                            vm.contentModel = result;
                            if (vm.isDocumentForAI) {
                                var payload = {
                                    tags: [],
                                    content_ids: [vm.contentModel.id]
                                };
                                Content.processDocument(payload);
                            }
                        }, 2000);
                    }
                    if (result.data.doc_status == 'FAILED') {
                        poller.stopAll();
                        vm.processStatus = 'FAILED';
                        vm.contentModel = result;
                    }
                });
            } else {
                vm.processStatus = vm.contentModel.data.doc_status;
            }
        }

        vm.useEmbedCode = function () {
            vm.isExternalDocument = true;
            vm.extractStatus = 'EXTRACTING';

            $timeout(function () {
                vm.extractEmbedData();
            }, 1000);
        }

        vm.extractEmbedData = function () {
            vm.contentMetaData.show_tags = true;

            vm.contentModel.title = "Untitled";
            vm.contentModel.description = null;
            vm.contentModel.data.external_document = true;
            vm.contentModel.tags = null;
            vm.contentModel.data.use_custom_thumbnail = true;
            vm.contentModel.data.doc_status = "READY";
            vm.contentModel.data.thumbnail_url = null;
            //strip width and height to make it responsive
            vm.contentModel.data.embed_code = vm.contentModel.data.embed_code.replace(/width=\"[0-9]+\"/g, '').replace(/height=\"[0-9]+\"/g, '');

            vm.extractStatus = 'COMPLETE';

            $timeout(function () {
                vm.save();
                vm.extractStatus = 'READY';
            }, 1000);
        }

        vm.disableEmbedSubmit = function () {
            if (vm.contentModel.data.embed_code == null || vm.contentModel.data.embed_code.length <= 0) {
                return true;
            } else {
                return false;
            }
        }
    }
})();
