(function() {
    'use strict';

    angular
        .module('atheer')
        .factory('ToastService', ToastService);

    function ToastService ($mdToast, toastConstants) {
        
        return {
            displayToastWithRenderHtml: displayToastWithRenderHtml,
            displayToast: displayToast,
            displayToastWithAction: displayToastWithAction
        };

        function displayToast(message, delay) {
            var timer = delay ? delay : 3000;
            $mdToast.show(
                $mdToast.simple().theme('primary-theme')
                .textContent(message)
                .position(toastConstants.position)
                .hideDelay(timer)
            );
        }

        function displayToastWithRenderHtml(message,delay) {
            var timer = delay ? delay : 3000;
            $mdToast.show({
                template:
                    "<md-toast><span flex>" + message.replace(/\n/g, '<br>') + "</span></md-toast>",
                position: toastConstants.position,
                hideDelay: timer,
            });
        }

        function displayToastWithAction(message, action, highlightClass, delay, callback) {
            var timer = delay ? delay : 3000;
            if(callback) {
                $mdToast.show(
                    $mdToast.simple().theme('primary-theme')
                    .content(message)
                    .action(action)
                    .highlightAction(true)
                    .highlightClass(highlightClass)
                    .position(toastConstants.position)
                    .hideDelay(timer)
                ).then(callback)
            } else {
                $mdToast.show(
                    $mdToast.simple().theme('primary-theme')
                    .content(message)
                    .action(action)
                    .highlightAction(true)
                    .highlightClass(highlightClass)
                    .position(toastConstants.position)
                    .hideDelay(timer)
                );
            }
        }
    }
})();
