(function() {
    'use strict';

    angular
        .module('atheer.system')
        .controller('SecurityRoleDetailController', SecurityRoleDetailController)

    /* @ngInject */
    function SecurityRoleDetailController($mdDialog, $scope, $filter, $sce, ParseLinks, pagingParams, paginationConstants, $state, AlertService, $mdToast, $mdSidenav, SecurityRole, id, Experience, MetaData) {
        var vm = this;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;

        vm.securityRoleModel = null;
        vm.view = "PERMISSIONS";
        vm.allMetaObjects = [];
        vm.userSecurityRoles = []
        vm.illustartionEmptyScreenId = "security_role_user";

        vm.jobRole = "Standard Role";
        vm.query = {
            filter: '',
            limit: paginationConstants.itemsPerPage,
            order: '-id',
            page: 1
        };
        vm.isJobRole = function (label) {
            if(vm.jobRole === 'Job Role' && label === "Job") {
               return false;
            } else if(vm.jobRole === 'AiRForm Role' && label === "AiRForm") {
                return false;
            } else if(vm.jobRole !== 'Job Role') {
                return false;
            }
            return true;
        }
        vm.onRoleChange = function () {
            if(vm.securityRoleModel === null) {
                vm.securityRoleModel = {};
            }
            if(vm.jobRole === 'Standard Role') {
                vm.securityRoleModel['acl'] = false;
                vm.securityRoleModel['resource'] = "";
            }else if(vm.jobRole === 'Job Role') {
                vm.securityRoleModel['acl'] = true;
                vm.securityRoleModel['resource'] = "job";
                clearAllJobRoleSettings();
            }else if(vm.jobRole === 'AiRForm Role') {
                vm.securityRoleModel['acl'] = true;
                vm.securityRoleModel['resource'] = "applet";
                clearAllJobRoleSettings();
            }
        }

        vm.removeUserSecurityRole = function(securityRole, index) {
            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.securityRole.removeSecurityRole'))
                .ariaLabel($filter('translate')('atheer.securityRole.removeSecurityRole'))
                .ok($filter('translate')('entity.action.remove'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function() {
                Experience.deleteSecurityRole({
                    id: securityRole.id
                }, function() {
                    vm.userSecurityRoles.splice(index, 1);
                });
            });
        }

        if (id) {
            vm.loadingData = true;
            SecurityRole.get({
                id: id
            }, function(result) {
                vm.securityRoleModel = result;
                if (!vm.securityRoleModel.permissions) {
                    vm.securityRoleModel.permissions = [];
                }
                if(vm.securityRoleModel.acl && vm.securityRoleModel.resource === 'job') {
                    vm.jobRole = "Job Role";
                    vm.securityRoleModel['resource'] = "job";
                } else if (vm.securityRoleModel.acl && vm.securityRoleModel.resource === 'applet') {
                    vm.jobRole = "AiRForm Role";
                    vm.securityRoleModel['resource'] = "applet";
                }
                else {
                    vm.jobRole = "Standard Role";
                }
                vm.loadUserSecurityRoles();
                vm.loadAllPermissions();
            });
        }

        vm.openAssignModal = function() {
            var users = [];
            if (vm.userSecurityRoles != null) {
                for (var i = 0; i < vm.userSecurityRoles.length; i++) {
                    users.push(vm.userSecurityRoles[i].user_info);
                }
            }

            $mdDialog.show({
                templateUrl: 'modules/system/security-role/security-role-assign-dialog.tmpl.html',
                clickOutsideToClose: true,
                controller: "SecurityRoleAssignController",
                controllerAs: "vm",
                size: 'lg',
                locals: {
                    securityRole: vm.securityRoleModel,
                    userId: undefined,
                    users: users
                }
            }).then(function() {
                vm.loadUserSecurityRoles();
            });
        }

        vm.isAllPermissionChecked = function(objectMeta) {
            var objectPermission = 0;
            for (var i = 0, len = vm.securityRoleModel.permissions.length; i < len; i++) {
                if (vm.securityRoleModel.permissions[i].object_name == objectMeta.name) {
                    objectPermission++;
                }
            }
            return objectPermission == objectMeta.permissions.length;
        }

        vm.isAllPermissionIndeterminate = function(objectMeta) {
            var objectPermission = 0;
            for (var i = 0, len = vm.securityRoleModel.permissions.length; i < len; i++) {
                if (vm.securityRoleModel.permissions[i].object_name == objectMeta.name) {
                    objectPermission++;
                }
            }
            return (objectPermission !== 0 &&
                objectPermission !== objectMeta.permissions.length);
        }

        function clearAllJobRoleSettings() {
            for (var j = 0; j < vm.allMetaObjects.length; j++) {
                for (var i = 0, len = vm.allMetaObjects[j].permissions.length; i < len; i++) {
                    if(vm.allMetaObjects[j].label !== "Job") {
                        var permissionInfo = {
                            object_name: vm.allMetaObjects[j].name,
                            permission_name: vm.allMetaObjects[j].permissions[i].name
                        };

                        var idx = getPermissionIndex(permissionInfo);
                        if (idx > -1) {
                            vm.securityRoleModel.permissions.splice(idx, 1);
                        }
                    }
                }
            }
        }
        vm.toggleAllPermissions = function(objectMeta) {
            var objectPermission = 0;
            for (var i = 0, len = vm.securityRoleModel.permissions.length; i < len; i++) {
                if (vm.securityRoleModel.permissions[i].object_name == objectMeta.name) {
                    objectPermission++;
                }
            }

            if (objectPermission == objectMeta.permissions.length) {
                for (var i = 0, len = objectMeta.permissions.length; i < len; i++) {
                    var permissionInfo = {
                        object_name: objectMeta.name,
                        permission_name: objectMeta.permissions[i].name
                    };

                    var idx = getPermissionIndex(permissionInfo);
                    if (idx > -1) {
                        vm.securityRoleModel.permissions.splice(idx, 1);
                    }
                }
            } else {
                for (var i = 0, len = objectMeta.permissions.length; i < len; i++) {
                    var permissionInfo = {
                        object_name: objectMeta.name,
                        permission_name: objectMeta.permissions[i].name
                    };
                    var idx = getPermissionIndex(permissionInfo);
                    if (idx == -1) {
                        vm.securityRoleModel.permissions.push(permissionInfo);
                    }
                }
            }
        }

        vm.permissionExist = function(objectMeta, objectPermission) {
            var permissions = vm.securityRoleModel.permissions;
            var permissionInfo = {
                object_name: objectMeta.name,
                permission_name: objectPermission.name
            };

            for (var i = 0, len = permissions.length; i < len; i++) {
                if (permissionInfo.object_name == permissions[i].object_name && permissionInfo.permission_name == permissions[i].permission_name) {
                    return true;
                }
            }
            return false;
        }

        vm.togglePermission = function(objectMeta, permission) {
            var permissions = vm.securityRoleModel.permissions;
            var permissionInfo = {
                object_name: objectMeta.name,
                permission_name: permission.name
            };

            if (permissions) {
                var idx = getPermissionIndex(permissionInfo);
                if (idx > -1) {
                    permissions.splice(idx, 1);
                } else {
                    permissions.push(permissionInfo);
                }
            } else {
                permissions = [];
                permissions.push(permissionInfo);
            }
        }

        function getPermissionIndex(permissionInfo) {
            var index = -1;
            for (var i = 0, len = vm.securityRoleModel.permissions.length; i < len; i++) {
                if (permissionInfo.object_name == vm.securityRoleModel.permissions[i].object_name && permissionInfo.permission_name == vm.securityRoleModel.permissions[i].permission_name) {
                    index = i;
                }
            }
            return index;
        }

        function showMetaObject(atheerObject) {
            var name = atheerObject.name;
            if(name == 'cloud' || name == 'skill' || name == 'media' || name == 'provider' || name == 'sharing-theme' || name == 'content-field' || name == 'feed') {
                return false;
            } else {
                return true;
            }
        }

        vm.loadAllPermissions = function() {
            MetaData.query({
                page: pagingParams.page - 1,
                size: 100,
                query: getQuery()
            }, function(response, headers) {
                vm.allMetaObjects = [];
                angular.forEach(response, function(atheerObject) {
                    if (atheerObject.permissions && atheerObject.permissions.length > 0 && showMetaObject(atheerObject)) {
                        vm.allMetaObjects.push(atheerObject);
                    }
                });
                vm.loadingData = false;
            });
        }

        vm.loadUserSecurityRoles = function() {
            Experience.getSecurityRoles({
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                query: getUserQueryString(),
                user_info: true
            }, function(response, headers) {
                vm.userSecurityRoles = response;
                vm.page = pagingParams.page;
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
            });
        }

        vm.transitionUserSecurityRoles = function() {
            vm.promise = Experience.getSecurityRoles({
                page: vm.page - 1,
                size: paginationConstants.itemsPerPage,
                query: getUserQueryString(),
                user_info: true
            }).$promise;

            vm.promise.then(function(response, headers) {
                vm.userSecurityRoles = response;
            });
        }

        function getUserQueryString() {
            var queryString = "object_id==" + vm.securityRoleModel.id;
            return queryString;
        }

        vm.onSaveSuccess = function(result) {
            vm.securityRoleModel = result;
            if (!vm.securityRoleModel.permissions) {
                vm.securityRoleModel.permissions = [];
            }
            vm.isSaving = false;
            AlertService.displayToast($mdToast);
        };

        vm.onSaveError = function() {
            vm.isSaving = false;
        };

        vm.save = function() {
            if (vm.securityRoleModel.id) {
                SecurityRole.update(vm.securityRoleModel, vm.onSaveSuccess, vm.onSaveError);
            } else {
                SecurityRole.save(vm.securityRoleModel, vm.onSaveSuccess, vm.onSaveError);
                vm.loadAllPermissions();
            }
        };

        vm.goToSecurityRoles = function() {
            $state.go('storm.admin-setting.security-roles');
        };

        vm.isNew = function() {
            if (vm.securityRoleModel == undefined || vm.securityRoleModel.id == null) {
                return true;
            } else {
                return false;
            }
        }

        vm.copySecurityRole = function() {
            delete vm.securityRoleModel['id'];
            vm.securityRoleModel.locked = false;
            vm.securityRoleModel.name = "Copy Of " + vm.securityRoleModel.name;
        }

        vm.togglePanel = function(componentId) {
            $mdSidenav(componentId).toggle();
        };

        function getQuery() {
            var query = "label=out=(Assignment,Organization,Context)";
            return query;
        }
    }
})();
