(function() {
    'use strict';

    angular
        .module('atheer.content')
        .controller('ContentShareController', ContentShareController);

    /* @ngInject */
    function ContentShareController($scope, $state, $mdDialog, $q, $filter, $mdToast, User, contents, Experience, Team, Principal) {
        var vm = this;

        vm.asyncUsers = [];
        vm.asyncTeams = [];
        vm.contents = contents;

        vm.shareProgress = 'idle';
        vm.shareSuccess = 0;

        vm.contentShareModel = {
            note: null
        }

        vm.froalaOptions = {
            placeholderText: $filter('translate')('global.enterTextHerePlaceholder')
        }

        vm.close = function() {
            if (vm.shareProgress !== 'idle') {
                $mdDialog.hide();
            } else {
                $mdDialog.cancel();
            }
        }

        vm.submit = function() {
            vm.shareProgress = 'started';

            Experience.bulkShare({
                users: vm.asyncUsers,
                audiences: vm.asyncTeams,
                content_ids: vm.contents,
                note: vm.contentShareModel.note
            }, function(result) {
                vm.shareProgress = 'complete';
                vm.shareSuccess = result.success;
            });
        }

        vm.disableSubmit = function() {
            if (vm.asyncUsers.length <= 0 && vm.asyncTeams.length <= 0) {
                return true;
            }
        }
    }
})();
