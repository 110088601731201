(function() {
    'use strict';

    angular
        .module('atheer.system')
        .controller('LLMPromptConfigController', LLMPromptConfigController)

    /* @ngInject */
    function LLMPromptConfigController($mdDialog, $q, $scope, $filter, $sce, ParseLinks, $state, AlertService, ContentAction, $mdToast, $mdSidenav, Principal, Setting, Feature, ToastService) {
        var vm = this;

        vm.command_resolution =  "";
        vm.vision_preview = "";
        vm.checklist_generation = "";
        vm.guide_generation = "";
        vm.qNa_participant_prompt = "";
        vm.qNa_atheer_prompt = "";
        vm.qNa_workspace_prompt = "";

        vm.pageTitleKey = $state.$current.data.pageTitle;

        vm.llmPromptConfigModel = {
            id: null,
            command_resolution:  "",
            vision_preview:  "",
            checklist_generation:  "",
            guide_generation:  "",
            qNa_participant_prompt:  "",
            qNa_atheer_prompt:  "",
            qNa_workspace_prompt:  ""
        };

        vm.onSaveSuccess = function(result) {
            vm.llmPromptConfigModel = result;
            vm.llmPromptSetting = result;
            vm.isSaving = false;
            AlertService.displayToast($mdToast);
        };

        vm.onSaveError = function() {
            vm.isSaving = false;
        };

        function setModelData() {
            setSettingField("command_resolution", vm.llmPromptConfigModel.command_resolution);
            setSettingField("vision_preview", vm.llmPromptConfigModel.vision_preview);
            setSettingField("checklist_generation", vm.llmPromptConfigModel.checklist_generation);
            setSettingField("guide_generation", vm.llmPromptConfigModel.guide_generation);
            setSettingField("qNa_participant_prompt", vm.llmPromptConfigModel.qNa_participant_prompt);
            setSettingField("qNa_atheer_prompt", vm.llmPromptConfigModel.qNa_atheer_prompt);
            setSettingField("qNa_workspace_prompt", vm.llmPromptConfigModel.qNa_workspace_prompt);
        }

        function setSettingField(fieldName, fieldValue) {
            var foundField = false;
            angular.forEach(vm.llmPromptSetting.fields, function(settingField) {
                if (settingField.name == fieldName) {
                    settingField.value = fieldValue;
                    foundField = true;
                }
            });

            if (!foundField) {
                var settingField = {
                    name: fieldName,
                    value: fieldValue,
                }
                vm.llmPromptSetting.fields.push(settingField);
            }
        }

        vm.save = function() {
            setModelData();

            Setting.update(vm.llmPromptSetting, function() {
                ToastService.displayToast($filter('triTranslate')("atheer.setting.success"), 3000);
            });
        }



        vm.loadData = function() {
            vm.loadingData = true;

            $q.all([
                    Setting.getLLMPromptSettings().$promise
                ])
                .then(function(results) {
                    setLLMPRomptModel(results[0]);
                    vm.loadingData = false;
                });
        };

        function setLLMPRomptModel(settings) {

            angular.forEach(settings, function(setting) {
                if (setting.category == 'llm_prompts') {
                    vm.llmPromptSetting = setting;
                    angular.forEach(setting.fields, function(settingField) {
                        if (settingField.name == 'command_resolution') {
                           vm.llmPromptConfigModel.command_resolution = settingField.value;
                        }
                        if (settingField.name == 'vision_preview') {
                            vm.llmPromptConfigModel.vision_preview = settingField.value;
                        }
                        if (settingField.name == 'checklist_generation') {
                            vm.llmPromptConfigModel.checklist_generation = settingField.value;
                        }
                        if (settingField.name == 'guide_generation') {
                            vm.llmPromptConfigModel.guide_generation = settingField.value;
                        }
                        if (settingField.name == 'qNa_participant_prompt') {
                            vm.llmPromptConfigModel.qNa_participant_prompt = settingField.value;
                        }
                        if (settingField.name == 'qNa_atheer_prompt') {
                            vm.llmPromptConfigModel.qNa_atheer_prompt = settingField.value;
                        }
                        if (settingField.name == 'qNa_workspace_prompt') {
                            vm.llmPromptConfigModel.qNa_workspace_prompt = settingField.value;
                        }
                    });
                }
            });
        };

        vm.loadData();
    }
})();
