(function () {
    "use strict";
    angular.module("atheer.services").factory("DataModel", DataModel);

    /* @ngInject */
    function DataModel($resource) {
        var resourceUrl = "/api/smartflow/datamodels/:id";

        return $resource(
            resourceUrl,
            {
                id: "@id",
            },
            {
                query: {
                    method: "GET",
                    isArray: true,
                },
                get: {
                    method: "GET",
                },
                save: {
                    method: "POST",
                },
                update: {
                    method: "PUT",
                },
                getContents: {
                    method: "GET",
                    url: resourceUrl,
                    isArray: true,
                    transformResponse: function (result) {
                        var resultData = angular.fromJson(result);
                        var returnArray = [];
                        angular.forEach(resultData, function (contentData) {
                            returnArray.push(contentData.content);
                        });
                        return returnArray;
                    },
                },
            }
        );
    }
})();
