(function() {
    'use strict';
    angular
        .module('atheer.services')
        .factory('Experience', Experience);

    /* @ngInject */
    function Experience($resource, DateUtils) {
        var resourceUrl = 'api/experiences/';

        return $resource(resourceUrl, {
            id: '@id',
            userId: '@userId',
            conversationId: '@conversationId'
        }, {
            'getRatings': {
                url: resourceUrl + 'ratings',
                method: 'GET',
                isArray: true
            },
            'rate': {
                url: resourceUrl + 'ratings',
                method: 'POST'
            },
            'loginActivity': {
                url: resourceUrl + 'logins',
                method: 'POST'
            },
            'unrate': {
                method: 'DELETE',
                url: resourceUrl + 'ratings/:id'
            },
            'getLikes': {
                url: resourceUrl + 'reactions',
                method: 'GET',
                isArray: true
            },
            'like': {
                url: resourceUrl + 'reactions',
                method: 'POST'
            },
            'unlike': {
                method: 'DELETE',
                url: resourceUrl + 'reactions/:id'
            },
            'getView': {
                url: resourceUrl + 'views/:id',
                method: 'GET'
            },
            'getViews': {
                url: resourceUrl + 'views',
                method: 'GET',
                isArray: true
            },
            'getScans': {
                url: resourceUrl + 'scans',
                method: 'GET',
                isArray: true
            },
            'getTopicScans': {
                url: resourceUrl + 'topic-scans',
                method: 'GET',
                isArray: true
            },
            'markViewed': {
                url: resourceUrl + 'views',
                method: 'POST'
            },
            'updateViewed': {
                url: resourceUrl + 'views/:id',
                method: 'PUT'
            },
            'getShares': {
                url: resourceUrl + 'shares',
                method: 'GET',
                isArray: true
            },
            'share': {
                url: resourceUrl + 'shares',
                method: 'POST'
            },
            'updateShare': {
                url: resourceUrl + 'shares/:id',
                method: 'PUT'
            },
            'getBookmarks': {
                url: resourceUrl + 'bookmarks',
                method: 'GET',
                isArray: true
            },
            'bookmark': {
                url: resourceUrl + 'bookmarks',
                method: 'POST'
            },
            'updateBookmark': {
                url: resourceUrl + 'bookmarks/:id',
                method: 'PUT'
            },
            'getAssignment': {
                url: resourceUrl + 'assignments/:id',
                method: 'GET'
            },
            'getAssignments': {
                url: resourceUrl + 'assignments',
                method: 'GET',
                isArray: true
            },
            'assign': {
                url: resourceUrl + 'assignments',
                method: 'POST'
            },
            'bulkAssign': {
                url: resourceUrl + 'assignments/bulk',
                method: 'POST'
            },
            'bulkAssignRemove': {
                url: resourceUrl + 'assignments/bulk-remove',
                method: 'POST'
            },
            'updateAssignment': {
                url: resourceUrl + 'assignments/:id',
                method: 'PUT'
            },
            'deleteAssignment': {
                url: resourceUrl + 'assignments/:id',
                method: 'DELETE'
            },
            'getNotification': {
                url: resourceUrl + 'notifications/:id',
                method: 'GET'
            },
            'getNotifications': {
                url: resourceUrl + 'notifications',
                method: 'GET',
                isArray: true
            },
            'notification': {
                url: resourceUrl + 'notifications',
                method: 'POST'
            },
            'bulkNotification': {
                url: resourceUrl + 'notifications/bulk',
                method: 'POST'
            },
            'updateNotification': {
                url: resourceUrl + 'notifications/:id',
                method: 'PUT'
            },
            'deleteNotification': {
                url: resourceUrl + 'notifications/:id',
                method: 'DELETE'
            },
            'bulkShare': {
                url: resourceUrl + 'shares/bulk',
                method: 'POST'
            },
            'bulkUserFlowbots': {
                url: resourceUrl + 'workflows/bulk',
                method: 'POST'
            },
            'getCompletions': {
                url: resourceUrl + 'completions',
                method: 'GET',
                isArray: true
            },
            'complete': {
                url: resourceUrl + 'completions',
                method: 'POST'
            },
            'updateCompletion': {
                url: resourceUrl + 'completions/:id',
                method: 'PUT'
            },
            'getFlags': {
                url: resourceUrl + 'flags',
                method: 'GET',
                isArray: true
            },
            'raiseFlag': {
                url: resourceUrl + 'flags',
                method: 'POST'
            },
            'getProblems': {
                url: resourceUrl + 'problems',
                method: 'GET',
                isArray: true
            },
            'reportProblem': {
                url: resourceUrl + 'problems',
                method: 'POST'
            },
            'getFollows': {
                url: resourceUrl + 'follow',
                method: 'GET',
                isArray: true
            },
            'follow': {
                url: resourceUrl + 'follow',
                method: 'POST'
            },
            'unfollow': {
                method: 'DELETE',
                url: resourceUrl + 'follow/:id'
            },
            'getBadges': {
                url: resourceUrl + 'badges',
                method: 'GET',
                isArray: true
            },
            'assignBadge': {
                url: resourceUrl + 'badges',
                method: 'POST'
            },
            'updateBadge': {
                url: resourceUrl + 'badges/:id',
                method: 'PUT'
            },
            'deleteBadge': {
                url: resourceUrl + 'badges/:id',
                method: 'DELETE'
            },
            'getSecurityRoles': {
                url: resourceUrl + 'security-roles',
                method: 'GET',
                isArray: true
            },
            'assignSecurityRole': {
                url: resourceUrl + 'security-roles',
                method: 'POST'
            },
            'bulkSecurityRole': {
                url: resourceUrl + 'security-roles/bulk',
                method: 'POST',
                isArray: true
            },
            'updateSecurityRole': {
                url: resourceUrl + 'security-roles/:id',
                method: 'PUT'
            },
            'deleteSecurityRole': {
                url: resourceUrl + 'security-roles/:id',
                method: 'DELETE'
            },
            'getTeams': {
                url: resourceUrl + 'audiences',
                method: 'GET',
                isArray: true
            },
            'assignTeam': {
                url: resourceUrl + 'audiences',
                method: 'POST'
            },
            'updateTeam': {
                url: resourceUrl + 'audiences/:id',
                method: 'PUT'
            },
            'deleteTeam': {
                url: resourceUrl + 'audiences/:id',
                method: 'DELETE'
            },
            'bulkTeams': {
                url: resourceUrl + 'audiences/bulk',
                method: 'POST',
                isArray: true
            },
            'bulkTeamsRemove': {
                url: resourceUrl + 'audiences/bulk/remove',
                method: 'POST'
            },
            'createEvent': {
                url: resourceUrl + 'events',
                method: 'POST'
            },
            'updateEvent': {
                url: resourceUrl + 'events/:id',
                method: 'PUT'
            },
            'deleteEvent': {
                url: resourceUrl + 'events/:id',
                method: 'DELETE'
            },
            'getEvents': {
                url: resourceUrl + 'events',
                method: 'GET',
                isArray: true
            },
            'createResponse': {
                url: resourceUrl + "responses",
                method: 'POST'
            },
            'updateResponse': {
                url: resourceUrl + "responses/:id",
                method: 'PUT'
            },
            'getResponses': {
                url: resourceUrl + "responses",
                method: 'GET',
                isArray: true
            },
            'getFlowbots': {
                url: resourceUrl + 'workflows',
                method: 'GET',
                isArray: true
            },
            'getBoards': {
                url: '/api/users/me/boards',
                method: 'GET',
                isArray: true
            },
            'getPitches': {
                url: '/api/users/me/pitches',
                method: 'GET',
                isArray: true
            },
            'getActivityIds': {
                url: resourceUrl + 'activities/:id',
                method: 'GET',
                isArray: false
            },
            'getUsers': {
                url: resourceUrl + 'users',
                method: 'POST',
                isArray: true
            },
            'getOrganizations': {
                url: resourceUrl + 'organizations',
                method: 'POST',
                isArray: true
            },
            'sendOverdueReminder': {
                url: resourceUrl + 'overdue-reminder',
                method: 'POST',
                isArray: false
            },
            'createSessionActivity': {
                url: resourceUrl + 'session-activities',
                method: 'POST'
            },
            'updateSessionActivity': {
                url: resourceUrl + 'session-activities/:id',
                method: 'PUT'
            },
            'getSessionActivity': {
                url: resourceUrl + 'session-activities/:id',
                method: 'GET'
            },
            'getSessionActivities': {
                url: resourceUrl + 'session-activities',
                method: 'GET',
                isArray: true
            },
            'getSessionRatingAndComments': {
                url: resourceUrl + 'session-activities',
                method: 'GET',
                isArray: true
            },
            'getUnReadMessageCount': {
                url: resourceUrl + 'messages/unread/:id',
                method: 'GET',
                isArray: true
            },
            'getReadMessages': {
                url: resourceUrl + 'messages/user/:userId/conversation/:conversationId',
                method: 'POST'
            }
        });
    }
})();
