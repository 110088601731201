(function() {
    'use strict';

    angular
        .module('atheer')
        .controller('SessionTimeoutController', SessionTimeoutController);

    /* @ngInject */
    function SessionTimeoutController($mdDialog, $interval, Idle, Auth, $state, Keepalive) {
        var vm = this;

        vm.counter = 15;
        var timer = $interval(function() {
            if(vm.counter == 0) {
                closeDialog();
            }
            vm.counter--;
        }, 1000);
        
        function closeDialog() {
            $mdDialog.cancel();
            Auth.logout();
            $state.go('account.signout');
        };

        vm.close = function() {
            closeDialog();
        };

        vm.continue = function() {
            $interval.cancel(timer);
            $mdDialog.cancel();
            Keepalive.setInterval(15);
        };
    }
})();
