(function() {
    'use strict';

    angular
        .module('atheer')
        .directive('fpConvMessageUrl', fpConvMessageUrl);

    function fpConvMessageUrl() {
        var directive = {
            restrict: 'E',
            controller: fpConvMessageUrlController,
            scope: {
                message: '=',
                userId: '=',
                primaryColor: '=',
                sender: '='
            },
            templateUrl: 'modules/conversation/conversation-url-message.tmpl.html',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;
    }

    /* @ngInject */
    function fpConvMessageUrlController($window) {
        var vm = this;

        var showDownConverter = new showdown.Converter();
        showDownConverter.setOption('emoji', true);

        vm.metaData = {};
        if(vm.message && vm.message.url_meta_info) {
            var urlInfo = vm.message.url_meta_info.split(',[Date:')[0];
            urlInfo = urlInfo.replace('<200,', '');

            vm.metaData = angular.fromJson(urlInfo);
        };

        if(vm.message && vm.message.body) {
            var pos = vm.message.body.indexOf('www') !== -1 ? vm.message.body.indexOf('www') : vm.message.body.indexOf('http');
            var url = vm.metaData && vm.metaData.url ? vm.metaData.url : vm.message.body;

            //for multiline check
            if(url.indexOf('<br>') != -1) {
                var msgParts = url.split('<br>');
                var isMsg = false;
                angular.forEach(msgParts, function(msgPart) {
                    if(!isMsg && (msgPart.indexOf('http') != -1 || msgPart.indexOf('www') != -1)) {
                       isMsg = true;
                       msgPart = showDownConverter.makeHtml(msgPart);
                       msgPart = msgPart.replace('<p>', '').replace('</p>', '');
                       url = msgPart;
                    }
                });
            }

            if(!url.startsWith('http')) {
                url = 'http://' + url;
            }
            var formatMsgBody = [vm.message.body.slice(0, pos), '<a href='+ url + ' target="_blank">', vm.message.body.slice(pos)].join('') + '</a>';

            vm.message.formattedMsgBody = formatMsgBody;
        } else {
            vm.message.formattedMsgBody = vm.message.formattedBody;
        }

        vm.getImageUrl = function() {
            if(vm.metaData && vm.metaData.images && vm.metaData.images.length > 0) {
                return vm.metaData.images[0].url;
            }
            return vm.metaData.favicon_url;
        };

        vm.redirectToUrl = function() {
            var url = vm.metaData && vm.metaData.url ? vm.metaData.url : vm.message.body;
            $window.open(url, '_blank');
        };
    }
})();