(function() {
    'use strict';
    angular
        .module('atheer.content')
        .directive('fpContentView', fpContentView);

    function fpContentView($sce, $controller) {
        return {
            template: '<ng-include src="getTemplateUrl()" include-replace/>',
            scope: {
                data: '=?',
                mode: '=',
                context: '=?',
                contextId: '=?',
                contentType: '@',
                templateUrl: '=',
                assignmentId: '=?',
                parentPlayerEvents: "@?",
                doneCallBack: "&?",
                currentResponseData: "=?"
            },
            replace: "true",
            restrict: 'E',
            link: function(scope, element, attrs) {
                var contentTypeReceived = scope.contentType.replace(/_/g, '-').replace(/-/g, ' ');

                function getContentViewControllerName() {
                    var returnStr = contentTypeReceived.replace(/\w\S*/g, function(txt) {
                        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                    });
                    returnStr = returnStr.replace(/ /g, '');
                    return returnStr + 'ViewController as vm';
                }

                //If no assignmentId and mode is play then update the mode to be hybrid mode
                //If the content is loaded from smart content then take the mode from the scope as marked in smart content
                if ((!scope.assignmentId || scope.assignmentId == '') && scope.context != 'smart-content' && scope.mode == 'play') {
                    scope.mode = 'hybrid';
                }

                var locals = {
                    $scope: scope,
                    $element: element,
                    $attrs: attrs
                };
                element.data('$Controller', $controller(scope.$eval("'" + getContentViewControllerName() + "'"), locals));
            }
        };
    }
})();
