(function() {
    'use strict';

    angular
        .module('atheer.session')
        .controller('SessionInfoDialogController', SessionInfoDialogController);

    /* @ngInject */
    function SessionInfoDialogController($location, $mdDialog, $filter, sessionModel, workspaceCode, deviceDetector, ToastService) {
        var vm = this;

        vm.sessionModel = sessionModel;
        vm.sessionUrl = "https://demo.atheer.my:3000/sessions/player" + vm.sessionModel.session_no;
        vm.code = workspaceCode;
        vm.isMobileBrowser = deviceDetector.isMobile();

        if ($location.host().endsWith("atheer.my")) {
            vm.sessionUrl = 'https://' + $location.host() + ":" + $location.port() + '/sessions/player/' + vm.sessionModel.session_no;
        } else {
            vm.sessionUrl = 'https://' + $location.host() + '/sessions/player/' + vm.sessionModel.session_no;
        };

        vm.onCopySuccess = function() {
            ToastService.displayToast($filter('translate')('atheer.session.info.copied'), 2000);
        };

        vm.close = function() {
            $mdDialog.cancel();
        };
    }
})();
