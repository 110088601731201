(function () {
	"use strict";

	angular
		.module("atheer.smartFlow")
		.controller("JobFlowInsightsErrorDetailsController", JobFlowInsightsErrorDetailsController);

	/* @ngInject */
	function JobFlowInsightsErrorDetailsController(
		$mdDialog,
		JobFlow,
		data
	) {
		var vm = this;
		vm.error = data.errors;
		vm.loadingData = true;

		vm.clear = function () {
			$mdDialog.hide();
		};

		function init() {
			vm.headerList = ["atheer.smartFlow.name", "atheer.smartFlow.detail.startDate", "atheer.smartFlow.detail.endDate", "atheer.smartFlow.insights.batchId", "atheer.smartFlow.status"];
			vm.errorList = [];

			JobFlow.getExecutionDetail(
				{
					id: data.executionId
				},
				function (result) {
					vm.loadingData = false;
					angular.forEach(result.activityLogs, function (log) {
						vm.errorList.push([log.activityName, log.startDate, log.endDate, log.batchId, log.status])
					})
				}
			);
		}

		init();
	}
})();
