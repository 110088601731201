(function() {
    'use strict';

    angular
        .module('atheer.conversation')
        .directive('conversationTypingIndicator', conversationTypingIndicator);

    /* @ngInject */
    function conversationTypingIndicator() {
        var directive = {
            scope: {
                conversationModel: '='
            },
            restrict: "E",
            replace: true,
            templateUrl: 'modules/conversation/conversation-typing-indicator.html',
            bindToController: true,
            controller: conversationTypingIndicatorController,
            controllerAs: 'vm',
        };
        return directive;

        /* @ngInject */
        function conversationTypingIndicatorController($rootScope, $scope, PubNubService, Feature) {
            var vm = this;

            vm.usersTyping = [];
            vm.userStatus = '';

            $rootScope.$on('conversationUserTypingList', function(event, usersTyping) {
                $scope.$apply(function() {
                    if(vm.conversationModel.type == 'PRIVATE_USER') {
                        vm.usersTyping = usersTyping;
                    } else {
                        var userTyping = [];
                        angular.forEach(usersTyping, function(typing) {
                            angular.forEach(vm.conversationModel.users_info, function(userInfo) {
                                if(userInfo.id == typing.uuid) {
                                    userTyping.push(typing);
                                }
                            })
                        });
                        vm.usersTyping = userTyping;
                    }
                });
            });

            $scope.$on('refreshUserStatus', function(event, userId) {
                $scope.$apply(function() {
                    generateStatus();
                });
            });

            $scope.$watch('vm.conversationModel', function(newVal) {
                generateStatus();
            });

            function generateStatus() {
                if (vm.conversationModel.type == 'PRIVATE_USER') {
                    var pubNubState = PubNubService.getUserState(vm.conversationModel.user_info.id);
                    if (pubNubState != undefined && pubNubState != null) {
                        vm.userStatus = 'Active';
                    } else {
                        vm.userStatus = 'Away';
                    }
                } else {
                    vm.userStatus = '';
                    var userNames = [];
                    var len = 0;
                    vm.userInfo = []
                    var currentIds = [];
                    for (var i = 0; i < vm.conversationModel.users_info.length; i++) {
                        for (var j = 0; j < vm.conversationModel.users.length; j++) {
                            if (vm.conversationModel.users[j].user_id ==  vm.conversationModel.users_info[i].id && !vm.conversationModel.users[j].left) {
                                var exist = false;
                                for (var k = 0; k < currentIds.length; k++) {
                                    if (currentIds[k] == vm.conversationModel.users_info[i].id) {
                                        exist = true;
                                        break;
                                    }
                                }
                                if (!exist) {
                                    vm.userInfo.push(vm.conversationModel.users_info[i])
                                    currentIds.push(vm.conversationModel.users_info[i].id)
                                }
                            }
                        }
                    }

                    for (var i = 0; i < vm.userInfo.length; i++) {
                        if (i > 2) {
                            userNames.push((vm.userInfo.length - i) + " others");
                            break;
                        } else {
                            userNames.push(vm.userInfo[i].first_name);
                        }
                    };

                    vm.userStatus = userNames.join(', ');
                };
            }
            generateStatus();
        }
    }
})();
