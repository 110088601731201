(function() {
    'use strict';

    angular
        .module('atheer')
        .factory('idleHandler', idleHandler);

    /* @ngInject */
    function idleHandler($rootScope, Idle, Setting, $mdDialog, $location, Keepalive, Auth, $state, $http) {
        var isWarning = false;
        return {
            initialize: initialize
        };

        function initialize() {
            Setting.getAuthenticationSetupSettings(function(result) {
                var autoSignoutEnabled = result[0].fields.filter(function(value) {
                    return value.name == 'auto_signout_enabled'
                })[0].value;
                if(autoSignoutEnabled == "true") {
                    var timer = result[0].fields.filter(function(value) {
                        return value.name == 'auto_signout_wait_time_mins'
                    })[0].value;
                    if(timer > 0) {
                        Idle.setIdle(timer * 60);
                        Idle.watch();

                        isWarning = result[0].fields.filter(function(value) {
                            return value.name == 'auto_signout_show_warning'
                        })[0].value;
                    }
                }
            });

            $rootScope.$on('IdleStart', function() {
                if($location.path() !== '/signin' && $location.path() !== '/signout' && !$location.path().startsWith('/join') && !$rootScope.isSessionStarted
                    && !$rootScope.isJobExecutionActive) {
                    if(isWarning == "true") {
                        $mdDialog.show({
                            templateUrl: 'core/components/session-timeout/session-timeout-dialog.tmpl.html',
                            clickOutsideToClose: true,
                            controller: "SessionTimeoutController",
                            controllerAs: "vm",
                        }).then(function(result) {
                        });
                    } else {
                        Auth.logout();
                        $state.go('account.signout')
                    }
                } else {
                    Keepalive.setInterval(15);
                }
            });

            $rootScope.$on('IdleTimeout', function() {
                Keepalive.setInterval(15);
            });
        };
    }
})();
