(function() {
    'use strict';

    angular
        .module('atheer.services')
        .factory('Home', Home);
        
    /* @ngInject */
    function Home($resource, $http) {

        var resourceUrl = 'api/users/me/foryou?v2=true';

        return $resource(resourceUrl, {
        },{
            'query': {
                method: 'GET'
            }
        });
    }
})();
