(function() {
    'use strict';

    angular
        .module('atheer.insights')
        .config(moduleConfig);

    /* @ngInject */
    function moduleConfig($stateProvider, triMenuProvider) {

        $stateProvider
            .state('storm.insights', {
                url: '/insights',
                abstract: true,
                views: {
                    '@storm': {
                        templateUrl: 'modules/insights/insights.tmpl.html',
                        controller: 'InsightsController',
                        controllerAs: 'vm',
                    },
                    'sideMenu@storm': {
                        templateUrl: 'modules/insights/insights-menu.tmpl.html',
                        controller: 'InsightsMenuController',
                        controllerAs: 'vm',
                    },
                    'toolbar@storm': {
                        templateUrl: 'modules/insights/insights-toolbar.tmpl.html',
                        controller: 'InsightsToolbarController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    workspaceId: function($stateParams) {
                        return $stateParams.id;
                    },
                    dashboardId: function($stateParams) {
                        return $stateParams.dashboardId;
                    },
                    launchMode: function($stateParams) {
                        return $stateParams.launchMode;
                    }
                },
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: true
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'dashboard_no,asc',
                        squash: true
                    },
                    search: null
                }
            })
            .state('storm.insights.workspace-summary', {
                url: '/workspace-summary/:id',
                views: {
                    '@storm': {
                        templateUrl: 'modules/insights/insights-summary.tmpl.html',
                        controller: 'InsightsSummaryController',
                        controllerAs: 'vm',
                    },
                    'toolbar@storm': {
                        templateUrl: 'modules/insights/insight-dashboard-detail-toolbar.tmpl.html',
                        controller: 'InsightDashboardDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    id: function($stateParams) {
                        return $stateParams.id;
                    },
                    launchMode: function($stateParams) {
                        return $stateParams.launchMode;
                    }
                },
                params: {
                    launchMode: true
                }
            })
            .state('storm.insights.conversations', {
                url: '/conversations/:id',
                views: {
                    '@storm': {
                        templateUrl: 'modules/insights/insights-conversation.tmpl.html',
                        controller: 'InsightsConversationController',
                        controllerAs: 'vm',
                    },
                    'toolbar@storm': {
                        templateUrl: 'modules/insights/insight-dashboard-detail-toolbar.tmpl.html',
                        controller: 'InsightDashboardDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    id: function($stateParams) {
                        return $stateParams.id;
                    },
                    launchMode: function($stateParams) {
                        return $stateParams.launchMode;
                    }
                },
                data: {
                    layout: {
                        pageTitle: 'atheer.insights.conversations.title',
                    }
                },
                params: {
                    launchMode: true
                }
            })
            .state('storm.insights.contents', {
                url: '/content/:id',
                views: {
                    '@storm': {
                        templateUrl: 'modules/insights/insights-content.tmpl.html',
                        controller: 'InsightsContentController',
                        controllerAs: 'vm',
                    },
                    'toolbar@storm': {
                        templateUrl: 'modules/insights/insight-dashboard-detail-toolbar.tmpl.html',
                        controller: 'InsightDashboardDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    id: function($stateParams) {
                        return $stateParams.id;
                    },
                    launchMode: function($stateParams) {
                        return $stateParams.launchMode;
                    }
                },
                data: {
                    layout: {
                        pageTitle: 'atheer.insights.content.title',
                    }
                },
                params: {
                    launchMode: true
                }
            })
            .state('storm.insights.topics', {
                url: '/topics/:id',
                views: {
                    '@storm': {
                        templateUrl: 'modules/insights/insights-topic.tmpl.html',
                        controller: 'InsightsTopicController',
                        controllerAs: 'vm',
                    },
                    'toolbar@storm': {
                        templateUrl: 'modules/insights/insight-dashboard-detail-toolbar.tmpl.html',
                        controller: 'InsightDashboardDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    id: function($stateParams) {
                        return $stateParams.id;
                    },
                    launchMode: function($stateParams) {
                        return $stateParams.launchMode;
                    }
                },
                data: {
                    layout: {
                        pageTitle: 'atheer.insights.topics.title',
                    }
                },
                params: {
                    launchMode: true
                }
            })
            .state('storm.insights.sessions', {
                url: '/sessions/:id',
                views: {
                    '@storm': {
                        templateUrl: 'modules/insights/insights-session.tmpl.html',
                        controller: 'InsightsSessionController',
                        controllerAs: 'vm',
                    },
                    'toolbar@storm': {
                        templateUrl: 'modules/insights/insight-dashboard-detail-toolbar.tmpl.html',
                        controller: 'InsightDashboardDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    id: function($stateParams) {
                        return $stateParams.id;
                    },
                    launchMode: function($stateParams) {
                        return $stateParams.launchMode;
                    }
                },
                data: {
                    layout: {
                        pageTitle: 'atheer.insights.airSessions.title'
                    }
                },
                params: {
                    launchMode: true
                }
            })
            .state('storm.insights.airforms', {
                url: '/airforms/:id',
                views: {
                    '@storm': {
                        templateUrl: 'modules/insights/insights-airform.tmpl.html',
                        controller: 'InsightsAirformController',
                        controllerAs: 'vm',
                    },
                    'toolbar@storm': {
                        templateUrl: 'modules/insights/insight-dashboard-detail-toolbar.tmpl.html',
                        controller: 'InsightDashboardDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    id: function($stateParams) {
                        return $stateParams.id;
                    },
                    launchMode: function($stateParams) {
                        return $stateParams.launchMode;
                    }
                },
                data: {
                    layout: {
                        pageTitle: 'atheer.insights.airForms.title',
                    }
                },
                params: {
                    launchMode: true
                }
            })
            .state('storm.insights.users', {
                url: '/users/:id',
                views: {
                    '@storm': {
                        templateUrl: 'modules/insights/insights-user.tmpl.html',
                        controller: 'InsightsUserController',
                        controllerAs: 'vm',
                    },
                    'toolbar@storm': {
                        templateUrl: 'modules/insights/insight-dashboard-detail-toolbar.tmpl.html',
                        controller: 'InsightDashboardDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    id: function($stateParams) {
                        return $stateParams.id;
                    },
                    launchMode: function($stateParams) {
                        return $stateParams.launchMode;
                    }
                },
                data: {
                    layout: {
                        pageTitle: 'atheer.insights.users.title',
                    }
                },
                params: {
                    launchMode: true
                }
            })
            .state('storm.insights.adv-dashboards', {
                url: '/dashboards',
                views: {
                    '@storm': {
                        templateUrl: 'modules/insights/insights-dashboards.tmpl.html',
                        controller: 'InsightsDashboardsController',
                        controllerAs: 'vm',
                    },
                    'sideMenu@storm': {
                        templateUrl: 'modules/insights/insights-dashboards-menu.tmpl.html',
                        controller: 'InsightsDashboardsMenuController',
                        controllerAs: 'vm',
                    },
                    'toolbar@storm': {
                        templateUrl: 'modules/insights/insights-dashboards-toolbar.tmpl.html',
                        controller: 'InsightsDashboardsToolbarController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    layout: {
                        pageTitle: 'atheer.insights.dashboards',
                        contentClass: 'layout-column',
                        footer: false,
                        showSideNavMenu: true,
                        sideNavMenuPosition: 'left',
                        showToolbar: true,
                        sideNavMenuSize: 15
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'dashboard_no,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    workspaceId: function($stateParams) {
                        return $stateParams.id;
                    },
                    dashboardId: function($stateParams) {
                        return $stateParams.dashboardId;
                    }
                }
            })
            .state('storm.insights.adv-analyze', {
                url: '/analyze',
                views: {
                    '@storm': {
                        templateUrl: 'modules/insights/insights-analyze.tmpl.html',
                        controller: 'InsightsAnalyzeController',
                        controllerAs: 'vm',
                    },
                    'toolbar@storm': {
                        templateUrl: 'modules/insights/insights-toolbar.tmpl.html',
                        controller: 'InsightsToolbarController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    layout: {
                        pageTitle: 'atheer.insights.analyze',
                        contentClass: 'layout-column',
                        footer: false,
                        showSideNavMenu: false,
                        sideNavMenuPosition: 'left',
                        showToolbar: true,
                        sideNavMenuSize: 15
                    }
                }
            })
            .state('storm.insights.dashboard-view', {
                url: '/dashboard/:id/:workspaceId/:dashboardId/:dashboardmode',
                views: {
                    '@storm': {
                        templateUrl: 'modules/insights/insights-dashboard-view.tmpl.html',
                        controller: 'InsightsDashboardViewController',
                        controllerAs: 'vm',
                    },
                    'toolbar@storm': {
                        templateUrl: 'modules/insights/insight-dashboard-detail-toolbar.tmpl.html',
                        controller: 'InsightDashboardDetailController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    layout: {
                        pageTitle: 'atheer.insights.dashboard.objectLabel',
                        showSideNavMenu: false
                    }
                },
                resolve: {
                    id: function($stateParams) {
                        return $stateParams.id;
                    },
                    launchMode: function($stateParams) {
                        return $stateParams.launchMode;
                    },
                    dashboardId: function($stateParams) {
                        return $stateParams.dashboardId;
                    },
                    dashboardmode: function($stateParams) {
                        return $stateParams.dashboardmode;
                    }
                },
                params: {
                    launchMode: true,
                    fromHomeTile: function($stateParams) {
                        return $stateParams.fromHomeTile;
                    }
                }
            }).state('storm.insights.dashboard-detail', {
                url: '/dashboards/:id',
                templateUrl: 'modules/insights/insight-dashboard-detail.tmpl.html',
                controller: 'InsightDashboardDetailController',
                controllerAs: 'vm',
                resolve: {
                    id: function($stateParams) {
                        return $stateParams.id;
                    }
                },
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false
                    }
                }
            });
    }
})();
