(function() {
    'use strict';

    angular
        .module('atheer.user')
        .controller('ProfileSessionsPastController', ProfileSessionsPastController);

    /* @ngInject */
    function ProfileSessionsPastController($scope, $state, $q, $filter, $mdSidenav, $location, Report, Media, CONTENT_META, Pubnub, id, Session, ParseLinks, pagingParams, paginationConstants, AlertService, $mdDialog, $mdToast, $stateParams, Feature, Experience) {
        var vm = this;

        vm.loadingData = false;
        vm.sessionActivity = null;
        vm.sessionModel = null;
        vm.reOrder = reOrder;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.predicate = pagingParams.predicate;
        vm.transition = transition;
        vm.reOrder = reOrder;
        vm.pageSize = paginationConstants.itemsPerPage;
        vm.panelToShow = null;

        vm.togglePanel = function(componentId, session) {
            vm.sessionModel = null;
            if (session) {
                vm.sessionModel = session;
            }
            if(componentId == 'insightsPanel') {
                vm.panelToShow = 'insightsPanel';
            } else if (componentId == 'annotationsPanel') {
                vm.panelToShow = 'annotationsPanel';
            } else if (componentId == 'recordingsPanel'){
                vm.panelToShow = 'recordingsPanel';
            } else if (componentId == 'conversationPanel') {
                vm.panelToShow = 'conversationPanel';
            }

            $mdSidenav(componentId).toggle()
                .then(function() {

                });
        };

        vm.refresh = function() {
            loadData();
        };

        function reOrder(order) {
            vm.predicate = order;
            vm.transition();
        };

        function transition() {
            vm.promise = Session.query({
                page: vm.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort(),
                user_id: id,
                previous: true
            }).$promise;

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            vm.promise.then(function(data, headers) {
                vm.queryCount = vm.totalItems;
                vm.sessions = data;
            });
        };

        function getSortOrder() {
            if (vm.predicate.startsWith('-')) {
                return vm.predicate.substr(1) + ',' + 'desc';
            } else {
                return vm.predicate + ',' + 'asc';
            }
        };

        function loadData() {
            vm.loadingData = true;
            Session.query({
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort(),
                user_id: id,
                previous: true
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.sessions = data;
                vm.page = pagingParams.page;
                vm.loadingData = false;
            }

            function onError(error) {
                AlertService.error(error.data.message);
                vm.loadingData = false;
            }
        };
        loadData();
    }
})();
