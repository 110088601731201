(function() {
    'use strict';
    angular
        .module('atheer.services')
        .factory('Conversation', Conversation);


    /* @ngInject */
    function Conversation($resource) {
        var resourceUrl = 'api/conversations/:id';

        return $resource(resourceUrl, {
            id: '@id'
        }, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET'
            },
            'save': {
                method: 'POST'
            },
            'update': {
                method: 'PUT'
            },
            'invite': {
                url: 'api/conversations/:id/members',
                method: 'POST'
            },
            'leave': {
                url: resourceUrl + '/leave',
                method: 'POST'
            },
            'join': {
                url: 'api/conversations/:id/join',
                method: 'POST'
            },
            'pin': {
                url: resourceUrl + '/pin',
                method: 'POST'
            },
            'unpin': {
                url: resourceUrl + '/unpin',
                method: 'POST'
            },
            'archive': {
                url: 'api/conversations/:id/archive',
                method: 'POST'
            }
        });
    }
})();
