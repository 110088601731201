(function () {
    'use strict';

    angular
        .module('atheer.session')
        .controller('SessionDeeplinkController', SessionDeeplinkController);

    /* @ngInject */
    function SessionDeeplinkController($stateParams, $http, $window, $filter, ToastService) {
        var vm = this;
        vm.isLoading = true;
        vm.landingUrl;
        vm.workSpace;
        vm.env;
        vm.linkError = false;
        vm.appImageLauncher = 'AppImageLauncher';
        vm.appImage = "AppImage";
        vm.windowsInstallerLink;
        vm.macInstallerLink;
        vm.linuxInstallerLink;
        vm.linuxArmInstallerLink;

        function getDesktopProtocol() {
            return 'x-atheer-' + vm.env;
        }

        function getInstallerLink(osName) {
            if (vm.workSpace === 'rigpartner' || vm.workSpace === 'atheer') {
                return 'https://desktop.atheer.' + vm.env + '/download/' + osName + '/' + vm.workSpace;
            }

            return 'https://desktop.atheer.' + vm.env + '/download/' + osName;

        }

        function onLoad() {
            var host = $window.location.host;
            vm.workSpace = host.split('.')[0];
            vm.env = host.split('.')[2];
            if (host === 'localhost:3000') {
                vm.env = 'dev'
            }
            var url = 'api/secureurls/' + $stateParams.guid;
            $http.get(url).then(function (response) {
                if (response.data.handler === 'DESKTOP') {

                    vm.windowsInstallerLink = response.data.downloadLinks["windows"];
                    vm.macInstallerLink = response.data.downloadLinks["mac"];
                    vm.linuxInstallerLink = response.data.downloadLinks["linuxx64"];
                    vm.linuxArmInstallerLink = response.data.downloadLinks["linuxarm64"];

                    var desktopProtocol = getDesktopProtocol();
                    var targetUrl = response.data.url;
                    vm.landingUrl = desktopProtocol + '://' + targetUrl;
                    vm.openDesktopApp();
                }
                vm.isLoading = false;
            },
                function (error) {
                    vm.isLoading = false;
                    vm.linkError = true;
                })
        }

        vm.openDesktopApp = function () {
            window.protocolCheck(vm.landingUrl,
                //failure case
                function () {
                    console.log("No App available to handle protocol", vm.landingUrl.split(':')[0]);
                    var message = $filter('translate')('deeplink.desktopAppNotInstalledDescription')
                    alert(message);
                },
                //success Case
                function () {
                    console.log("App Available for handle protocol");
                    window.location.href = vm.landingUrl;
                },
                //unsupported case
                function () {
                    console.log("No App available to handle protocol", vm.landingUrl.split(':')[0]);
                    var message = $filter('translate')('deeplink.desktopAppNotInstalledDescription')
                    alert(message);
                }
            )
        }
        onLoad();
    }

})();