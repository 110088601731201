(function() {
    'use strict';

    angular
        .module('atheer.insights')
        .controller('InsightsToolbarController', InsightsToolbarController);

    /* @ngInject */
    function InsightsToolbarController($scope, $state, Setting, AlertService, $mdDialog, $mdToast, $stateParams, Principal, Feature, PermissionService) {
        var vm = this;

        var lessThan3Day = new Date();
        lessThan3Day.setDate(lessThan3Day.getDate() - 2);
        vm.illustartionEmptyScreenId = "insight_graph";

        vm.customTemplates = [{
            name: 'Last 3 Days',
            dateStart: lessThan3Day,
            dateEnd: new Date(),
        }];

        vm.pickerModel = {
            selectedTemplate: 'Last 3 Days'
        };

        vm.loadingData = true;

        vm.loadAll = loadAll;
        function loadAll() {
            vm.loadingData = false;
        };

        vm.goToDashboards = function() {
            if($stateParams.isDeeplink) {
                $window.history.go(-2);
            } else {
                // $window.history.back();
                $state.go('storm.insights.adv-dashboards');
            }
        };

        vm.loadAll();
    }
})();
