(function() {
    'use strict';

    angular
        .module('atheer.content')
        .controller('VideoDetailController', VideoDetailController)
        .controller('VideoViewController', VideoViewController)
        .controller('VideoFeedbackController', VideoFeedbackController)

    /* @ngInject */
    function VideoFeedbackController($scope, $mdBottomSheet) {

    }

    /* @ngInject */
    function VideoViewController($scope, $controller, $mdBottomSheet) {

        var basectrl = $controller('BaseContentViewController as vm', {
            $scope: $scope
        });
        angular.extend(this, basectrl);

        $scope.player_id = 'atheer-player-' + Math.floor(Math.random() * (100000 - 1000 + 1)) + 1000;

        if (!$scope.data.data.external_video) {
            $scope.videoSettings = {
                playlist: [{
                    file: '/media/videos/' + $scope.data.data.source_media.id + '/' + $scope.data.data.source_media.properties.file_name
                }],
                autostart: false,
                width: "100%",
                aspectratio: "16:9",
                skin: {
                    name: "atheer"
                },
                playbackRateControls: true,
                playbackRates: [0.25, 0.75, 1, 1.25, 1.5, 2, 3, 4]
            }
        }

        $scope.showFeedback = function(firstTime) {
            $mdBottomSheet.show({
                templateUrl: 'modules/content/video-feedback.tmpl.html',
                clickOutsideToClose: false,
                escapeToClose: false,
                controller: 'VideoFeedbackController',
                scope: $scope,
                preserveScope: true,
                parent: '#fp-content-view-' + $scope.data.id + '-' + $scope.context,
                disableParentScroll: true,
                locals: {
                    firstTime: firstTime
                }
            });
        }
    }

    /* @ngInject */
    function VideoDetailController($scope, $timeout, $controller, id, Content, $sce, poller, $resource) {

        var basectrl = $controller('BaseContentDetailController as vm', {
            $scope: $scope,
            id: id,
            content_type: "video"
        });
        angular.extend(this, basectrl);

        var vm = this;

        vm.uploadStatus = 'idle';
        vm.selectVideoProviders = false;
        vm.extractStatus = 'IDLE';
        vm.processStatus = 'IDLE';
        vm.isExternalVideo = false;
        vm.fileMaxSize = 500 * 1024 * 1024;

        vm.createVideo = function(mediaId, responseObj) {
            vm.processStatus = 'UPLOADED';

            vm.contentModel.title = responseObj.name;
            vm.contentModel.data.source_media = responseObj;
            vm.contentModel.data.video_status = 'UPLOADED';
            vm.contentModel.external_id = responseObj.external_id;

            vm.save();

            vm.checkVideoStatus();
        }

        $scope.$watch('vm.contentModel', function(newVal) {
            if (!vm.isNew()) {
                vm.checkVideoStatus();
            }
        });

        vm.checkVideoStatus = function() {
            if ((vm.contentModel.data.video_status == 'UPLOADED' || vm.contentModel.data.video_status == 'PROCESSING') && vm.contentModel.id) {
                vm.processStatus = vm.contentModel.data.video_status;

                // Get poller.
                var videoStatusPoller = poller.get(Content, {
                    action: 'get',
                    delay: 3000,
                    argumentsArray: [{
                        id: vm.contentModel.id
                    }],
                    smart: true
                });

                videoStatusPoller.promise.then(null, null, function(result) {
                    if (result.data.video_status == 'READY') {
                        poller.stopAll();
                        vm.processStatus = 'COMPLETE';
                        $timeout(function() {
                            vm.processStatus = 'READY';
                            vm.contentModel = result;
                        }, 2000);
                    }
                    if (result.data.video_status == 'FAILED') {
                        poller.stopAll();
                        vm.processStatus = 'FAILED';
                        vm.contentModel = result;
                    }
                });
            } else {
                vm.processStatus = vm.contentModel.data.video_status;
            }
        }

        vm.extractVideoDetails = function() {
            vm.isExternalVideo = true;
            vm.extractStatus = 'EXTRACTING';

            $timeout(function() {
                vm.extractVideoData();
            }, 2000);
        }

        vm.extractVideoData = function() {
            vm.promise = Content.oembed({
                url: vm.contentModel.data.source_url
            }).$promise;

            vm.promise.then(function(result, headers) {
                if (angular.isDefined(result.title) && result.title != null && result.title.length > 0) {
                    vm.contentMetaData.show_tags = true;

                    vm.contentModel.title = result.title;
                    vm.contentModel.description = result.description;
                    vm.contentModel.data.external_video = true;
                    vm.contentModel.data.oembed_id = result.id;
                    vm.contentModel.data.extract_id = result.extract_id;
                    vm.contentModel.data.provider = result.provider;
                    vm.contentModel.tags = result.tags;
                    vm.contentModel.data.use_custom_thumbnail = true;
                    vm.contentModel.data.video_status = "READY";
                    vm.contentModel.data.progress = 100;
                    vm.contentModel.data.thumbnail_url = result.thumbnail_url;
                    //strip width and height to make it responsive
                    vm.contentModel.data.embed_code = result.html.replace(/width=\"[0-9]+\"/g, '').replace(/height=\"[0-9]+\"/g, '');
                    vm.extractStatus = 'COMPLETE';

                    $timeout(function() {
                        vm.save();
                        vm.extractStatus = 'READY';
                    }, 2000);

                } else {
                    vm.extractStatus = 'INCOMPLETE';

                    $timeout(function() {
                        vm.extractStatus = 'READY';
                    }, 2000);
                }
            }, function(error) {
                vm.extractStatus = 'ERROR';

                $timeout(function() {
                    vm.extractStatus = 'READY';
                }, 2000);
            });
        }

        vm.useEmbedCode = function() {
            vm.isExternalVideo = true;
            vm.extractStatus = 'EXTRACTING';

            $timeout(function() {
                vm.extractEmbedData();
            }, 1000);
        }

        vm.extractEmbedData = function() {
            vm.contentMetaData.show_tags = true;

            vm.contentModel.title = "Untitled";
            vm.contentModel.description = null;
            vm.contentModel.data.external_video = true;
            vm.contentModel.data.oembed_id = null;
            vm.contentModel.data.extract_id = null;
            vm.contentModel.data.provider = null;
            vm.contentModel.tags = null;
            vm.contentModel.data.use_custom_thumbnail = true;
            vm.contentModel.data.video_status = "READY";
            vm.contentModel.data.progress = 100;
            vm.contentModel.data.thumbnail_url = null;
            //strip width and height to make it responsive
            vm.contentModel.data.embed_code = vm.contentModel.data.embed_code.replace(/width=\"[0-9]+\"/g, '').replace(/height=\"[0-9]+\"/g, '');

            vm.extractStatus = 'COMPLETE';

            $timeout(function() {
                vm.save();
                vm.extractStatus = 'READY';
            }, 1000);
        }

        vm.disableEmbedSubmit = function() {
            if (vm.contentModel.data.embed_code == null || vm.contentModel.data.embed_code.length <= 0) {
                return true;
            } else {
                return false;
            }
        }

        vm.disableVideoProviderSubmit = function() {
            if (vm.contentModel.data.source_url == null || vm.contentModel.data.source_url.length <= 0) {
                return true;
            } else {
                return false;
            }
        }
    }
})();
