(function() {
    'use strict';

    angular
        .module('atheer.session')
        .controller('SessionAnnotationZipController', SessionAnnotationZipController);

    /* @ngInject */
    function SessionAnnotationZipController($scope, $state, $q, $filter, $window, $http, $location, Report, Session, poller, ParseLinks, AlertService, $mdDialog, $mdToast, $stateParams, sessionModel) {
        var vm = this;

        vm.loadingData = false;
        vm.sessionModel = sessionModel;
        vm.jobStatus = 'idle';
        vm.jobResult = null;
        vm.imagefolder = "/media/images/";

        vm.close = function() {
            $mdDialog.cancel();
        }

        vm.load = function() {
            Session.getAnnotationZip({
                id: vm.sessionModel.id,
            }, onSaveSuccess, onSaveError);

            function onSaveSuccess(result) {
                vm.jobResult = result;
                vm.getImportJobStatus();
            };

            function onSaveError() {
                AlertService.displayToast($mdToast);
            };
        }

        vm.getImportJobStatus = function() {
            // Get poller.
            var jobStreamStatusPoller = poller.get(Session, {
                action: 'getImportStatus',
                delay: 3000,
                argumentsArray: [{
                    id: vm.jobResult.job_id
                }],
                smart: true
            });

            jobStreamStatusPoller.promise.then(null, null, function(result) {
                vm.jobStream = result;
                vm.jobStreamId = vm.jobResult.job_id;
                vm.jobData = vm.jobStream.job_data;
                if (result.status == 'COMPLETED') {
                    poller.stopAll();
                    vm.jobStatus = 'complete';
                }

            });
        };

        vm.getMediaUrl = function(sessionActivity) {
            if ($location.host().endsWith('atheer.my')) {
                return 'https://' + $location.host() + ':' + $location.port() + vm.imagefolder + vm.jobResult.path;
            } else {
                return vm.imagefolder + vm.jobResult.path;
            }
        }

        vm.download = function(sessionActivity, id) {
            var url = vm.getMediaUrl(sessionActivity);
            var downloadFileName = "AiRSession-Annotations-" + vm.sessionModel.session_no + ".zip";
            $http.get(url, {
                    responseType: "arraybuffer"
                })
                .success(function(data) {
                    var anchorTag = angular.element('<a/>');
                    var blob = new Blob([data]);

                    anchorTag.attr({
                        href: $window.URL.createObjectURL(blob),
                        target: '_blank',
                        download: downloadFileName
                    })[0].click();
                    $mdDialog.cancel();
                });
        }
        vm.load();
    }
})();
