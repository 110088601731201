(function() {
    'use strict';

    angular
        .module('atheer.cloud')
        .config(moduleConfig);

    function moduleConfig($stateProvider) {
        $stateProvider
        .state('storm.cloud-admin.metrics', {
            url: '/metrics',
            templateUrl: 'modules/cloud/metrics/metrics.html',
            controller: 'JhiMetricsMonitoringController',
            controllerAs: 'vm',
            data: {
                pageTitle: 'atheer.metrics.title',
                layout: {
                    contentClass: 'layout-column',
                    footer: false,
                    showToolbar: false,
                    showSideNavMenu: true,
                }
            }
        });
    }
})();
