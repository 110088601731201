(function() {
    'use strict';
    angular
        .module('atheer.services')
        .factory('IntegrationApp', IntegrationApp);

    /* @ngInject */
    function IntegrationApp($resource) {

        var resourceUrl = 'api/integration/:app?code=:code';

        return $resource(resourceUrl, {
            code: '@code',
            app:'@app'
        },{
            'slack': {
                method: 'GET'
            },
            'udacity': {
                method: 'GET'
            },
            'salesforce':{
                method: 'GET'
            },
            'msftoffice':{
                method: 'GET'
            },
            'googleapp':{
                method: 'GET'
            },
            'googledrive':{
                method: 'GET'
            },
            'dropbox':{
                method: 'GET'
            },
            'box':{
                method: 'GET'
            },
            'onedrive':{
                method: 'GET'
            },
            'vimeo':{
                method: 'GET'
            }
        });
    }
})();
