(function() {
    'use strict';

    angular
        .module('atheer.content')
        .controller('ContentDetailInsightOverviewController', ContentDetailInsightOverviewController)

    /* @ngInject */
    function ContentDetailInsightOverviewController($scope, $q, $filter, content_type, id, Report) {
        var vm = this;

        vm.loadingData = false;
        vm.contentModel = $scope.$parent.vm.contentModel;

        vm.contentCompletionChartData = {
            chart: {
                type: 'line',
            },
            title: {
                text: $filter('translate')('atheer.content.completions')
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            yAxis: {
                title: {
                    text: $filter('translate')('atheer.content.noOfCompletions')
                }
            },
            xAxis: {
                title: {
                    text: $filter('translate')('atheer.content.dates')
                },
                type: 'datetime',
                labels: {
                    format: '{value:%e - %b - %Y}'
                }

            },
            series: [{
                name: $filter('translate')('atheer.content.completions'),
                data: []
            }],
            tooltip: {
                formatter: function() {
                    return '<b>' + Highcharts.dateFormat('%e - %b - %Y',
                        new Date(this.x)) + '</b><br/> Completions : ' + this.y;
                }
            }
        };

        vm.contentViewsChartData = {
            chart: {
                type: 'line',
            },
            title: {
                text: $filter('translate')('atheer.content.views')
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            yAxis: {
                title: {
                    text: $filter('translate')('atheer.content.noOfViews')
                }
            },
            xAxis: {
                title: {
                    text: $filter('translate')('atheer.content.dates')
                },
                type: 'datetime',
                labels: {
                    format: '{value:%e - %b - %Y}'
                }

            },
            series: [{
                name: $filter('translate')('atheer.content.views'),
                data: []
            }],
            tooltip: {
                formatter: function() {
                    return '<b>' + Highcharts.dateFormat('%e - %b - %Y',
                        new Date(this.x)) + '</b><br/> Views : ' + this.y;
                }
            }
        };

        vm.contentTimeSpentChartData = {
            chart: {
                type: 'line',
            },
            title: {
                text: $filter('translate')('atheer.content.timeSpent')
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            yAxis: {
                title: {
                    text: $filter('translate')('atheer.content.minutes')
                },
                labels: {
                    formatter: function() {
                        var seconds = (this.value / 1000).toFixed(0);
                        var minutes = (seconds / 60).toFixed(2);
                        return minutes;
                    }
                }

            },
            xAxis: {
                title: {
                    text: $filter('translate')('atheer.content.dates')
                },
                type: 'datetime',
                labels: {
                    format: '{value:%e - %b - %Y}'
                }
            },
            series: [{
                name: $filter('translate')('atheer.content.avgTimeSpent'),
                data: []
            }, {
                name: $filter('translate')('atheer.content.totalTimeSpent'),
                data: []
            }],
            tooltip: {
                formatter: function() {
                    var seconds = (this.y / 1000).toFixed(0);
                    var minutes = (seconds / 60).toFixed(2);
                    return '<b>' + Highcharts.dateFormat('%e - %b - %Y',
                        new Date(this.x)) + '</b><br/> Time Spent : ' + minutes + ' minutes';
                }
            }
        };

        vm.multibarChartResponseData = {
            chart: {
                type: 'column',
            },
            title: {
                text: $filter('translate')('atheer.content.responses')
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            yAxis: {
                title: {
                    text: $filter('translate')('atheer.content.noOfContents')
                },
                allowDecimals: false
            },
            xAxis: {
                categories: []
            },
            series: [{
                'name': $filter('translate')('atheer.content.responseForChoice'),
                "color": "#1f77b4",
                'data': []
            }],
            tooltip: {
                formatter: function() {
                    return '<b>' + this.x + '</b> : ' + this.y;
                }
            }
        };

        vm.lineChartResponseData = {
            chart: {
                type: 'line',
            },
            title: {
                text: $filter('translate')('atheer.content.responses')
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            yAxis: {
                title: {
                    text: $filter('translate')('atheer.content.noOfResponses')
                },
                allowDecimals: false
            },
            xAxis: {
                title: {
                    text: $filter('translate')('atheer.content.dates')
                },
                type: 'datetime',
                labels: {
                    format: '{value:%e - %b - %Y}'
                }
            },
            series: [{
                'name': $filter('translate')('atheer.content.responses'),
                "color": "#1f77b4",
                'data': []
            }],
            tooltip: {
                formatter: function() {
                    return '<b>' + Highcharts.dateFormat('%e - %b - %Y',
                        new Date(this.x)) + '</b><br/> Responses : ' + this.y;
                }
            }
        };

        vm.pointLineChartData = {
            chart: {
                type: 'line',
            },
            title: {
                text: $filter('translate')('atheer.content.points')
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            yAxis: {
                title: {
                    text: $filter('translate')('atheer.content.points')
                }
            },
            xAxis: {
                title: {
                    text: $filter('translate')('atheer.content.dates')
                },
                type: 'datetime',
                labels: {
                    format: '{value:%e - %b - %Y}'
                }
            },
            series: [{
                name: $filter('translate')('atheer.content.avgPoints'),
                data: []
            }, {
                name: $filter('translate')('atheer.content.totalPoints'),
                data: []
            }],
            tooltip: {
                formatter: function() {
                    return '<b>' + Highcharts.dateFormat('%e - %b - %Y',
                        new Date(this.x)) + '</b><br/> ' + this.series.name + ' : ' + this.y;
                }
            }
        };

        function processHistoryData() {

            function getDateFromItem(item) {
                return item.x;
            }

            //set completions Data
            angular.forEach(vm.overviewSummary.history.completion, function(completionObj) {
                vm.contentCompletionChartData.series[0].data.push({ x: new Date(completionObj.name), y: completionObj.count })
            });
            vm.contentCompletionChartData.series[0].data = $filter('orderBy')(vm.contentCompletionChartData.series[0].data, getDateFromItem);

            //set views Data
            angular.forEach(vm.overviewSummary.history.view, function(viewObj) {
                vm.contentViewsChartData.series[0].data.push({ x: new Date(viewObj.name), y: viewObj.count })
            });
            vm.contentViewsChartData.series[0].data = $filter('orderBy')(vm.contentViewsChartData.series[0].data, getDateFromItem);

            //set average points Data
            angular.forEach(vm.overviewSummary.history.avg_points, function(pointObj) {
                vm.pointLineChartData.series[0].data.push({ x: new Date(pointObj.name), y: pointObj.count });
            });
            vm.pointLineChartData.series[0].data.values = $filter('orderBy')(vm.pointLineChartData.series[0].data, getDateFromItem);

            //set total points Data
            angular.forEach(vm.overviewSummary.history.total_points, function(pointObj) {
                vm.pointLineChartData.series[1].data.push({ x: new Date(pointObj.name), y: pointObj.count });
            });
            vm.pointLineChartData.series[0].data = $filter('orderBy')(vm.pointLineChartData.series[1].data, getDateFromItem);

            //set average time spent Data
            angular.forEach(vm.overviewSummary.history.avg_time_spent, function(timeSpentObj) {
                vm.contentTimeSpentChartData.series[0].data.push({ x: new Date(timeSpentObj.name), y: timeSpentObj.count });
            });
            vm.contentTimeSpentChartData.series[0].data = $filter('orderBy')(vm.contentTimeSpentChartData.series[0].data, getDateFromItem);

            //set total time spent Data
            angular.forEach(vm.overviewSummary.history.total_time_spent, function(timeSpentObj) {
                vm.contentTimeSpentChartData.series[1].data.push({ x: new Date(timeSpentObj.name), y: timeSpentObj.count });
            });
            vm.contentTimeSpentChartData.series[1].data = $filter('orderBy')(vm.contentTimeSpentChartData.series[1].data, getDateFromItem);


            //set total time spent Data
            angular.forEach(vm.overviewSummary.history.response, function(responseObj) {
                vm.lineChartResponseData.series[0].data.push({ x: new Date(responseObj.name), y: responseObj.count });
            });
            vm.lineChartResponseData.series[0].data = $filter('orderBy')(vm.lineChartResponseData.series[0].data, getDateFromItem);


            //Response For Each Choice
            if (vm.contentModel.data.type == 'yes_no_question') {

                var trueChoiceCount = $filter('filter')(vm.overviewSummary.choices, { name: true })[0];
                var falseChoiceCount = $filter('filter')(vm.overviewSummary.choices, { name: false })[0];

                vm.multibarChartResponseData.xAxis.categories.push('Yes');
                vm.multibarChartResponseData.series[0].data.push(trueChoiceCount ? trueChoiceCount.count : 0);

                vm.multibarChartResponseData.xAxis.categories.push('No');
                vm.multibarChartResponseData.series[0].data.push(falseChoiceCount ? falseChoiceCount.count : 0);

            } else if (vm.contentModel.data.type == 'true_false_question') {

                var trueChoiceCount = $filter('filter')(vm.overviewSummary.choices, { name: true })[0];
                var falseChoiceCount = $filter('filter')(vm.overviewSummary.choices, { name: false })[0];

                vm.multibarChartResponseData.xAxis.categories.push('True');
                vm.multibarChartResponseData.series[0].data.push(trueChoiceCount ? trueChoiceCount.count : 0);

                vm.multibarChartResponseData.xAxis.categories.push('False');
                vm.multibarChartResponseData.series[0].data.push(falseChoiceCount ? falseChoiceCount.count : 0)

            } else if (vm.contentModel.data.type == 'legal_question') {

                var trueChoiceCount = $filter('filter')(vm.overviewSummary.choices, { name: true })[0];
                var falseChoiceCount = $filter('filter')(vm.overviewSummary.choices, { name: false })[0];

                vm.multibarChartResponseData.xAxis.categories.push('Accepted');
                vm.multibarChartResponseData.series[0].data.push(trueChoiceCount ? trueChoiceCount.count : 0)

                vm.multibarChartResponseData.xAxis.categories.push('Not Accepted');
                vm.multibarChartResponseData.series[0].data.push(falseChoiceCount ? falseChoiceCount.count : 0)

            } else {
                angular.forEach(vm.contentModel.data.choices, function(choice, index) {
                    var choiceObjectCount = $filter('filter')(vm.overviewSummary.choices, { name: choice.choice_id })[0];

                    vm.multibarChartResponseData.xAxis.categories.push(choice.label);
                    vm.multibarChartResponseData.series[0].data.push(choiceObjectCount ? choiceObjectCount.count : 0)
                });
            }
        }

        vm.getCount = function(activityName) {
            for (var i = 0; i < vm.overviewSummary.metrics.length; i++) {
                if (vm.overviewSummary.metrics[i].name === activityName) {
                    return vm.overviewSummary.metrics[i].count;
                }
            }
            return 0;
        }

        vm.loadData = function() {
            vm.loadingData = true;

            $q.all([
                Report.getContentOverviewSummary({
                    id: id
                }).$promise
            ]).then(function(data) {
                vm.overviewSummary = data[0];
                processHistoryData();
                vm.loadingData = false;

            }).catch(function(data) {}).finally(function() {});
        }
        vm.loadData();
    }
})();
