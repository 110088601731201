(function () {
    'use strict';

    angular
        .module('atheer.user')
        .controller('UserActivateInfoController', UserActivateInfoController);

    /* @ngInject */
    function UserActivateInfoController($rootScope, $location, Feature, $translate, Auth, $timeout, User, UserInvitation, $scope, $state, $stateParams, $http, $filter, triSettings, id, ToastService) {
        var vm = this;

        vm.error = null;
        vm.success = false;
        vm.triSettings = triSettings;
        vm.step = 'user-info';
        vm.userInvitation = null;
        vm.status = null;
        vm.baseUrl = $location.protocol() + "://" + $location.host();

        vm.isLoginAvailable = true;
        vm.isCheckingLoginAvailability = false;
        vm.loginInfo = null;

        vm.user = {
            first_name: null,
            last_name: null,
            login: null,
            password: null,
            email: null,
            invitation_key: id
        }

        vm.next = function () {
            if (!vm.isLoginAvailable || vm.user.login == null || vm.user.login.length <= 0) {
                ToastService.displayToast($filter('triTranslate')('atheer.userInfo.enterUsername'), 3000);
            } else {
                vm.step = 'password';
            }
        };

        vm.register = function () {
            vm.doNotMatch = null;
            vm.error = null;
            vm.errorUserExists = null;
            vm.errorEmailExists = null;

            User.register(vm.user, function (result) {
                vm.success = 'OK';
                login()
            }, function (result) {
                vm.success = null;
                if (result.status === 400 && result.data === 'login already in use') {
                    vm.errorUserExists = 'ERROR';

                    ToastService.displayToast($filter('triTranslate')('atheer.userInfo.usernameAlreadyInUse'), 3000);

                } else if (result.status === 400 && result.data === 'e-mail address already in use') {
                    vm.errorEmailExists = 'ERROR';

                    ToastService.displayToast($filter('triTranslate')('atheer.userInfo.emailAlreadyInUse'), 3000);

                } else {
                    vm.error = 'ERROR';
                }
            });
        }

        vm.loadAll = function () {
            UserInvitation.getByActivationKey({
                id: id
            }, function (result) {
                vm.userInvitation = result;
                if (vm.userInvitation.status == 'ACCEPTED') {
                    vm.status = 'user-exist';
                } else {
                    vm.status = 'valid-key';
                }
                vm.user.email = vm.userInvitation.email;
                vm.user.login = vm.userInvitation.email;
                vm.user.first_name = vm.userInvitation.first_name;
                vm.user.last_name = vm.userInvitation.last_name;
            }, function () {
                vm.status = 'invalid-key';
                vm.userInvitation = null;
            });
        }

        function setupTeamName(settings) {
            angular.forEach(settings, function (setting) {
                if (setting.category == 'company_info') {
                    vm.companySetting = setting;

                    angular.forEach(setting.fields, function (settingField) {
                        if (settingField.name == 'name') {
                            vm.teamName = settingField.value;
                        }
                    });
                }
            });
        }

        function login() {
            Auth.login({
                username: vm.user.login,
                password: vm.user.password,
                rememberMe: true
            }).then(function () {
                vm.authenticationError = false;
                $rootScope.$broadcast('authenticationSuccess');
                $state.go('storm.home');
            }).catch(function () {
                vm.authenticationError = true;
                ToastService.displayToastWithAction($filter('translate')('atheer.userInfo.errorCreatingAccount'),
                    $filter('translate')('entity.action.ok'), 'md-warn')
            });
        }

        vm.checkLoginAvailable = function (login) {
            vm.isCheckingLoginAvailability = true;
            vm.isLoginAvailable = false;
            User.checkLogin({
                login: login
            }, function (result) {
                vm.isCheckingLoginAvailability = false;
                vm.loginInfo = result;
                vm.isLoginAvailable = vm.loginInfo.available;
            });
        }

        vm.loadAll();
    }
})();
