(function() {
    'use strict';

    angular
        .module('atheer.session')
        .controller('SessionTemplatesContoller', SessionTemplatesContoller);

    /* @ngInject */
    function SessionTemplatesContoller($rootScope, $scope, $q, $state, $filter, Report, CONTENT_META, Pubnub, Session, ParseLinks, pagingParams, paginationConstants, AlertService, $mdDialog, $mdToast, $stateParams) {
        var vm = this;

        vm.loadingData = false;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.editObject = editObject;
        vm.deleteObject = deleteObject;
        vm.pageSize = paginationConstants.itemsPerPage;
        vm.illustartionEmptyScreenId = "session_template";
        vm.illustartionEmptyScreenText = "There are no sessions yet";

        vm.permissions = {
            create: 'create-session',
            update: 'update-session',
            delete: 'delete-session',
            updatePersonalSession: 'update-personal-session'
        };

        vm.refresh = function() {
            loadData();
        };

        function editObject(objectId) {
            $state.go('storm.sessions.template-detail', {
                'id': objectId
            });
        };

        function deleteObject(entity) {
            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.session.template.delete'))
                .ariaLabel($filter('translate')('atheer.session.template.delete'))
                .ok($filter('translate')('entity.action.delete'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function() {
                Session.delete({
                    id: entity.id
                }, function() {
                    resetModal();
                });
            });
        };

        function resetModal() {
            loadData();
            AlertService.displayToast($mdToast);
        };

        function getQueryString() {
            var query = 'type==' + 'TEMPLATE';
            return query;
        };

        function loadData() {
            vm.loadingData = true;
            Session.query({
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort(),
                template: true
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.sessions = data;
                vm.page = pagingParams.page;
                vm.loadingData = false;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        };

        var refreshSessionListener = $rootScope.$on('refreshSessions', function(event, filter) {
            loadData();
        });

        // Unregister
        $scope.$on('$destroy', function() {
            refreshSessionListener();
        });

        loadData();
    }
})();
