(function() {
    'use strict';

    angular
        .module('atheer')
        .filter('numberToAlphabet', numberToAlphabet);

    function numberToAlphabet() {
        return numberToAlphabet;

        function numberToAlphabet (number) {
            return String.fromCharCode(number+97);
        }
    }
})();
