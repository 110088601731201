(function() {
    'use strict';

    angular
        .module('atheer.smartFlow')
        .controller('SmartFlowDialogController', SmartFlowDialogController);

    /* @ngInject */
    function SmartFlowDialogController($scope, $stateParams, $mdDialog, entity, SmartFlow) {
        var vm = this;
        vm.smartFlow = entity;

        vm.isNew = function() {
            if (vm.smartFlow == undefined || vm.smartFlow.id == null) {
                return true;
            } else {
                return false;
            }
        }

        vm.load = function(id) {
            if (id) {
                SmartFlow.get({
                    id: id
                }, function(result) {
                    vm.smartFlow = result;
                });
            }
        };

        var onSaveSuccess = function(result) {
            $scope.$emit('smartFlowUpdate', result);
            $mdDialog.hide(result);
            vm.isSaving = false;
        };

        var onSaveError = function() {
            vm.isSaving = false;
        };

        vm.save = function() {
            vm.isSaving = true;
            if (vm.smartFlow.id !== null) {
                SmartFlow.update(vm.smartFlow, onSaveSuccess, onSaveError);
            } else {
                SmartFlow.save(vm.smartFlow, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $mdDialog.cancel();
        };
    }
})();
