(function() {
    'use strict';

    angular
        .module('atheer')
        .config(html5ModeConfig);

      /* @ngInject */
    function html5ModeConfig($locationProvider, HTML5_ENABLED) {
        if(HTML5_ENABLED) {
            $locationProvider.html5Mode({
                enabled: true,
                requireBase: true
            });
        } else {
            $locationProvider.html5Mode({
                enabled: false,
                requireBase: false
            });
        }

    }
})();
