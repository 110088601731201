(function() {
    'use strict';

    angular
        .module('atheer.workspace')
        .controller('WorkspaceDetailController', WorkspaceDetailController)

    /* @ngInject */
    function WorkspaceDetailController($mdDialog, $scope, $filter, $sce, ParseLinks, pagingParams, paginationConstants, $state, $q, AlertService, $mdToast, $mdSidenav, Workspace, id, $timeout, Setting) {
        var vm = this;

        vm.loadingData = true;

        vm.loadAll = loadAll;
        vm.pageTitleKey = $state.$current.data.pageTitle;

        function loadAll() {
            vm.loadingData = false;
        };

        vm.loadAll();
    }
})();
