(function() {
    'use strict';

    angular
        .module('atheer.user')
        .config(moduleConfig);

    function moduleConfig($stateProvider, triMenuProvider) {
        $stateProvider
            .state('storm.profile', {
                abstract: true,
                url: '/profile/{id}',
                views: {
                    '@storm': {
                        templateUrl: 'modules/user/user-detail.tmpl.html',
                        controller: 'UserDetailController',
                        controllerAs: 'vm',
                    },
                    'sideMenu@storm': {
                        templateUrl: 'modules/user/user-detail-menu.tmpl.html',
                        controller: 'UserDetailMenuController',
                        controllerAs: 'vm',
                    }
                },
                params: {
                    id: null
                },
                resolve: {
                    id: function($stateParams) {
                        return $stateParams.id;
                    }
                },
                data: {
                    layout: {
                        footer: false,
                        showToolbar: true,
                        contentClass: 'layout-column'
                    }
                },
            })
            .state('storm.profile.overview', {
                url: '/overview',
                templateUrl: 'modules/user/overview/overview.tmpl.html',
                controller: 'ProfileOverviewController',
                controllerAs: 'vm',
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: true
                    }
                },
                params: {
                    launchQrCode: false
                },
            })
            .state('storm.profile.jobs', {
                url: '/jobs',
                templateUrl: 'modules/user/job/jobs.tmpl.html',
                controller: 'ProfileJobsController',
                controllerAs: 'vm',
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: true
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }]
                }
            })
            .state('storm.profile.library', {
                abstract: true,
                url: '/library',
                templateUrl: 'modules/user/library/library.tmpl.html',
                controller: 'ProfileLibraryController',
                controllerAs: 'vm',
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: true
                    }
                },
                params: {
                    id: null,
                    showToolbar: false,
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'created_on,desc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }]
                }
            })
            .state('storm.profile.library.bookmarks', {
                url: '/bookmarks',
                templateUrl: 'modules/user/library/library-bookmarks.tmpl.html',
                controller: 'ProfileLibraryBookmarksController',
                controllerAs: 'vm',
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: true
                    }
                },
                params: {
                    id: null,
                    showToolbar: false,
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'created_on,desc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }]
                }
            })
            .state('storm.profile.library.shared', {
                url: '/shared',
                templateUrl: 'modules/user/library/library-shared.tmpl.html',
                controller: 'ProfileLibrarySharedController',
                controllerAs: 'vm',
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: true
                    }
                },
                params: {
                    id: null,
                    showToolbar: false,
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'created_on,desc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }]
                }
            })
            .state('storm.profile.library.uploads', {
                url: '/uploads',
                templateUrl: 'modules/user/library/library-uploads.tmpl.html',
                controller: 'ProfileLibraryUploadsController',
                controllerAs: 'vm',
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: true
                    }
                },
                params: {
                    id: null,
                    showToolbar: false,
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'created_on,desc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }]
                }
            })
            .state('storm.profile.library.liked', {
                url: '/liked',
                templateUrl: 'modules/user/library/library-liked.tmpl.html',
                controller: 'ProfileLibraryLikedController',
                controllerAs: 'vm',
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: true
                    }
                },
                params: {
                    id: null,
                    showToolbar: false,
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'created_on,desc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }]
                }
            })
            .state('storm.profile.sessions', {
                abstract: true,
                url: '/sessions',
                templateUrl: 'modules/user/session/sessions.tmpl.html',
                controller: 'ProfileSessionsController',
                controllerAs: 'vm',
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: true
                    }
                },
                params: {
                    id: null,
                    showToolbar: false,
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'created_on,desc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }]
                }
            })
            .state('storm.profile.sessions.upcoming', {
                url: '/upcoming',
                templateUrl: 'modules/user/session/sessions-upcoming.tmpl.html',
                controller: 'ProfileSessionsUpcomingController',
                controllerAs: 'vm',
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: true
                    }
                },
                params: {
                    id: null,
                    showToolbar: false,
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'created_on,desc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }]
                }
            })
            .state('storm.profile.sessions.past', {
                url: '/past',
                templateUrl: 'modules/user/session/sessions-past.tmpl.html',
                controller: 'ProfileSessionsPastController',
                controllerAs: 'vm',
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: true
                    }
                },
                params: {
                    id: null,
                    showToolbar: false,
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'created_on,desc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }]
                }
            })
            .state('storm.profile.timeline', {
                url: '/timeline',
                templateUrl: 'modules/user/timeline/timeline.tmpl.html',
                controller: 'ProfileTimelineController',
                controllerAs: 'vm',
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: true
                    }
                }
            })
            .state('storm.profile.about', {
                url: '/about',
                templateUrl: 'modules/user/about/about.tmpl.html',
                controller: 'ProfileAboutController',
                controllerAs: 'vm',
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: true
                    }
                }
            })
            .state('storm.profile.password', {
                url: '/password',
                templateUrl: 'modules/user/password/password.tmpl.html',
                controller: 'ProfilePasswordController',
                controllerAs: 'vm',
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: true
                    }
                }
            })
            .state('storm.profile.preferences', {
                url: '/preferences',
                templateUrl: 'modules/user/preference/preferences.tmpl.html',
                controller: 'ProfilePreferncesController',
                controllerAs: 'vm',
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: true
                    }
                }
            })
            .state('storm.profile.properties', {
                url: '/properties',
                templateUrl: 'modules/user/properties/properties.tmpl.html',
                controller: 'ProfilePropertiesController',
                controllerAs: 'vm',
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: true
                    }
                },
                params: {
                    profileMeta: null
                },
                resolve: {
                    profileMeta: ['$stateParams', function($stateParams) {
                        return $stateParams.profileMeta;
                    }]
                }
            })
            .state('storm.profile.teams', {
                url: '/teams',
                templateUrl: 'modules/user/team/teams.tmpl.html',
                controller: 'ProfileTeamsController',
                controllerAs: 'vm',
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: true
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }]
                }
            })
            .state('storm.profile.security-roles', {
                url: '/security-roles',
                templateUrl: 'modules/user/security-role/security-roles.tmpl.html',
                controller: 'ProfileSecurityRolesController',
                controllerAs: 'vm',
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: true
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }]
                }
            })
            .state('storm.profile.event', {
                url: '/{eventName}',
                templateUrl: 'modules/user/event/event-list.tmpl.html',
                controller: 'ProfileEventListController',
                controllerAs: 'vm',
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: true
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    eventMeta: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    eventName: ['$stateParams', function($stateParams) {
                        return $stateParams.eventName;
                    }],
                    eventMeta: ['$stateParams', function($stateParams) {
                        return JSON.parse($stateParams.eventMeta);
                    }]
                }
            });
    }
})();
