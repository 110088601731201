(function() {
    'use strict';

    angular
        .module('atheer.content')
        .controller('BaseContentViewController', BaseContentViewController)

    /* @ngInject */
    function BaseContentViewController($scope, $sce, $filter, $mdBottomSheet, Principal, Experience, CONTENT_META) {

        $scope.completionData = undefined;
        $scope.isCompleted = false;

        $scope.isPreviewMode = false; // This is used where no view and no response is submitted
        $scope.isPlayMode = false; // This is used where view is submitted and response is submitted based on the HybridMode Value
        $scope.isHybridMode = false; // This is used as sub state of play, if true does not submit response for the content launched

        $scope.hasResponse = false;
        $scope.isContentRatingDone = false;

        $scope.responseData = angular.copy($scope.currentResponseData);

        $scope.needAnswer = ($scope.data.data.answer_data != null && $scope.data.data.answer_data != undefined) || $scope.data.data.required;

        if ($scope.mode == 'preview') {
            $scope.isPreviewMode = true;
            $scope.isPlayMode = false;
        } else if ($scope.mode == 'play') {
            $scope.isPreviewMode = false;
            $scope.isPlayMode = true;

            if ($scope.context != 'attention') {
                Experience.getResponses({
                    query: "object_id==" + $scope.data.id + ";user_id==" + Principal.getUserId() + ";assignment_id==" + $scope.assignment_id
                }, function(data) {
                    $scope.responseData = (data instanceof Array) ? data[0] : data;

                    if ($scope.responseData) {
                        $scope.hasResponse = true;
                        $scope.needAnswer = false;
                    } else {
                        // If specific responsedata on individual content type
                        if ($scope.setResponseToScope) {
                            $scope.setResponseToScope();
                        } else {
                            $scope.responseData = {
                                object: $scope.data,
                                response: {
                                    type: $scope.data.data.type,
                                    choice: undefined,
                                    correct: undefined,
                                    points: 0
                                },
                                user_id: Principal.getUserId(),
                                assignment_id: $scope.assignmentId,
                                context_id: $scope.contextId
                            };
                        }
                    }
                });
            }
        } else if ($scope.mode == 'hybrid') {
            $scope.isPreviewMode = false;
            $scope.isPlayMode = true;
            $scope.isHybridMode = true;
        }

        if ($scope.responseData) {
            $scope.hasResponse = true;
            $scope.needAnswer = false;
            if ($scope.setResponseToScope) {
                $scope.setResponseToScope();
            }
        } else {
            // If specific responsedata on individual content type
            if ($scope.setResponseToScope) {
                $scope.setResponseToScope();
            } else {
                $scope.responseData = {
                    object: $scope.data,
                    response: {
                        type: $scope.data.data.type,
                        choice: undefined,
                        correct: undefined,
                        points: 0
                    },
                    user_id: Principal.getUserId(),
                    assignment_id: $scope.assignmentId,
                    context_id: $scope.contextId
                };
            }
        }

        //If in play mode we get the completion data, based on which we show the completion mark on the content view
        if ($scope.isPlayMode) {
            if ($scope.context && $scope.context != 'smart-content') {
                Experience.getCompletions({
                    query: "object_id==" + $scope.data.id + ";user_id==" + Principal.getUserId() + ";assignment_id==" + $scope.assignmentId
                }, function(result) {
                    if (result.length > 0) {
                        $scope.completionData = result[0];
                        $scope.isCompleted = true;
                    }
                });
            }
        }

        //for all content other than smart content if we have any parent event either from parent as smart content or
        //attention player event emit required events on actions
        if ($scope.contentType.replace(/_/g, '-') !== "smart-content" && $scope.parentPlayerEvents) {
            $scope.moveNext = function() {
                $scope.$emit($scope.parentPlayerEvents + "PreserveState", {
                    preserveState: false
                });
            }

            $scope.$on($scope.contentType + "_smart_content_validate_" + $scope.data.id, function(event, data) {
                $scope.$emit($scope.parentPlayerEvents + "PreserveState", {
                    preserveState: $scope.needAnswer
                });
            });

            $scope.$on($scope.contentType + "_smart_content_updates_" + $scope.data.id, function(event, data) {
                $scope.$emit($scope.parentPlayerEvents + "UpdateState", $scope.getSmartContentUpdates());
            });
        }

        //this is function which is overridden at child contents where the bottomsheed feed back is to be shown
        $scope.showFeedback = function(firstTime) {
            angular.noop;
        }

        $scope.showExistingFeedback = function() {
            $mdBottomSheet.hide();
            if ($scope.hasResponse && $scope.hasFeedback()) {
                $scope.showFeedback(false);
            }
        }

        $scope.setFinalResponseObject = function() {
            angular.noop;
        }

        $scope.hasFeedback = function() {
            var cardMeta = $scope.getCardMeta();
            if (cardMeta.enable_feedback) {
                return true;
            } else {
                return false;
            }
        }

        // Create response for the content only if its in playmode and not in hybrid mode
        $scope.createResponse = function() {
            if ($scope.isPlayMode && !$scope.isHybridMode) {
                $scope.setFinalResponseObject();

                //fix this
                $scope.responseData.time_spent = 0;

                Experience.createResponse($scope.responseData, function(result) {
                    $scope.responseData = result;
                    $scope.handleResponse();

                    if ($scope.contentType.replace(/_/g, '-') !== "smart-content" && $scope.parentPlayerEvents) {
                        $scope.$emit("SmartContentUpdateResponse", { responseData: $scope.responseData });
                    }
                });
            } else {
                $scope.setFinalResponseObject();
                $scope.handleResponse();
                if ($scope.contentType.replace(/_/g, '-') !== "smart-content" && $scope.parentPlayerEvents) {
                    $scope.$emit("SmartContentUpdateResponse", { responseData: $scope.responseData });
                }
            }
        }

        $scope.handleResponse = function() {
            if ($scope.contextId != undefined && $scope.contextId != null) {
                $scope.needAnswer = false;
                $scope.hasResponse = true;
            }
            if ($scope.data.data.type !== 'smart_content') {
                $scope.performEndAction(false, true, false);
            }
        }

        $scope.getSmartContentUpdates = function() {
            $scope.showExistingFeedback();

            if ($scope.hasResponse && $scope.hasFeedback()) {
                return {
                    showNext: true,
                    showPrevious: true
                };
            } else {
                return {
                    showNext: !$scope.needAnswer,
                    showPrevious: true
                };
            }
        }

        $scope.getTemplateUrl = function() {
            return "modules/content/" + $scope.contentType.replace(/_/g, '-') + "-view.tmpl.html";
        }

        $scope.trustAsHtml = function(html) {
            return $sce.trustAsHtml(html);
        }

        $scope.getDocPreviewUrl = function(url) {
            var previewUrl = url.replace("cdn.filestackcontent.com", "www.filestackapi.com/api/preview");
            return $sce.trustAsResourceUrl(previewUrl);
        }

        $scope.getDocFileUrl = function(url) {
            return $sce.trustAsResourceUrl(url);
        }

        $scope.getAtheerDocPreviewUrl = function(mediaId, fileName) {
            const fileExt = fileName.includes(".PDF") ? ".PDF" : ".pdf";
            var previewUrl = "third-party/docviewer/viewer.html?file=/media/documents/" + mediaId + fileExt;
            return $sce.trustAsResourceUrl(previewUrl);
        }

        $scope.getLinkUrl = function(url) {
            return $sce.trustAsResourceUrl(url);
        }

        $scope.getTitleAlignment = function() {
            if ($scope.data == undefined) return;
            if ($scope.data.title_alignment === 'CENTER') {
                return "center";
            } else if ($scope.data.title_alignment === 'RIGHT') {
                return "end";
            } else {
                return "start";
            }
        }

        $scope.getMediaAlignment = function() {
            if ($scope.data == undefined) return;
            if ($scope.data.media_alignment === 'CENTER') {
                return "center";
            } else if ($scope.data.media_alignment === 'RIGHT') {
                return "end";
            } else {
                return "start";
            }
        }

        $scope.getMediaPosition = function() {
            if ($scope.data == undefined) return;
            if ($scope.data.media_position === 'BELOW_TITLE') {
                return "below";
            } else if ($scope.data.media_position === 'ABOVE_TITLE') {
                return "above";
            } else {
                return "below";
            }
        }

        $scope.getMediaShape = function() {
            if ($scope.data == undefined) return;
            if ($scope.data.media_shape === 'ROUND') {
                return "round";
            } else if ($scope.data.media_shape === 'SQUARE') {
                return "square";
            } else {
                return "square";
            }
        }

        $scope.getMediaSize = function() {
            if ($scope.data == undefined) return 'sm';
            if ($scope.data.media_size === 'LARGE') {
                return "lg";
            } else if ($scope.data.media_size === 'MEDIUM') {
                return "md";
            } else {
                return "sm";
            }
        }

        $scope.showMedia = function() {
            if ($scope.data == undefined) return;
            if ($scope.data.show_media && $scope.data.media_id !== null) {
                return true;
            } else {
                return false;
            }
        }

        $scope.getSpacerHeight = function(id) {
            var element = angular.element(document.querySelector('#fp-content-description-' + id));
            if (element && element[0]) {
                var height = element[0].offsetHeight + ($scope.showMedia() ? 100 : 0);
                if (height > 100) {
                    return 0;
                } else {
                    return 100 - height;
                }
                return height;
            } else {
                return 100;
            }
        }

        $scope.getCardMeta = function() {
            var contentMetaData = $filter('filter')(CONTENT_META, {
                type: $scope.data.data.type.replace(/_/g, '-')
            })[0];
            return contentMetaData;
        }


        //Main function which is called on done action done from the content views and then called in forward action
        // like after create response > show Feedback > show Ratings if required > call the done callback defined
        $scope.performEndAction = function(createResponse, showFeedback, showRating) {
            if (createResponse) {
                if (!$scope.hasResponse) {
                    $scope.createResponse();
                } else {
                    $scope.performEndAction(false, true, false);
                }
            } else if (showRating) {
                if ($scope.data.data.request_rating && !$scope.isContentRatingDone) {
                    var parentId = $scope.data.data.type === 'smart_content' ? $scope.data.data.cards[$scope.data.data.cards.length - 1].id + '-smart-content' : $scope.data.id;

                    $mdBottomSheet.show({
                        templateUrl: 'modules/content/content-rating.tmpl.html',
                        clickOutsideToClose: false,
                        escapeToClose: false,
                        controller: 'ContentRatingController',
                        scope: $scope,
                        preserveScope: true,
                        parent: '#fp-content-view-' + parentId,
                        disableParentScroll: true
                    });
                } else {
                    $scope.performEndAction(false, false, false);
                }

            } else if (showFeedback) {
                if ($scope.hasFeedback()) {
                    $scope.showFeedback(true);
                } else {
                    $scope.performEndAction(false, false, true);
                }
            } else {
                if (typeof($scope.doneCallBack) == 'function') {
                    $scope.doneCallBack();
                    $scope.$emit($scope.parentPlayerEvents + "UpdateState", $scope.getSmartContentUpdates());
                } else {
                    angular.noop;
                }
            }

        }
    }
})();
