(function() {
    'use strict';

    angular
        .module('atheer.session')
        .directive('sessionRecordings', sessionRecordings);

    function sessionRecordings() {
        var directive = {
            scope: {
                sessionModel: '='
            },
            bindToController: true,
            controller: sessionRecordingsController,
            controllerAs: 'vm',
            templateUrl: 'modules/session/session-recordings.tmpl.html',
            restrict: 'E'
        };
        return directive;
    };

    /* @ngInject */
    function sessionRecordingsController($scope, $mdDialog, $http, $window, $mdSidenav, $mdToast, $document, $mdPanel, $state, $timeout, $q, $filter, ParseLinks, Principal, Session, Experience, AlertService) {
        var vm = this;

        vm.loadingData = false;
        vm.transition = transition;
        vm.pageSize = 10;
        vm.sessionActivities = null;
        vm.sessionRecorings = [];

        vm.togglePanel = function() {
            $mdSidenav('recordingsPanel').toggle()
                .then(function() {

                });
        };

        function transition() {
            vm.promise = Experience.getSessionActivities({
                page: vm.page - 1,
                size: vm.pageSize,
                sort: sort(),
                query: getQueryString(),
                user_info: true
            }).$promise;

            vm.promise.then(function(data, headers) {
                vm.queryCount = headers('X-Total-Count');
                vm.sessionActivities = data;
            });
        };

        function sort() {
            return 'id,asc';
        };

        function getQueryString() {
            var queryString = "action_type==RECORDING_SAVED;object_id==" + vm.sessionModel.id;
            return queryString;
        };

        vm.download = function(mediaUrl, mediaFileName, id) {

            $http.get(mediaUrl, {
              responseType: "arraybuffer"
            })
            .success(function(data) {
              var anchorTag = angular.element('<a/>');
              var blob = new Blob([data]);
              var extension = mediaFileName.split('.')[1];
              var fileName = 'airsession-' + vm.sessionModel.session_no + '-recording-' + (id+1) + '.' + extension;
              anchorTag.attr({
                href: $window.URL.createObjectURL(blob),
                target: '_blank',
                download: fileName
              })[0].click();
            });
        };

        function loadData() {
            if (!vm.sessionModel) {
                return;
            }

            vm.loadingData = true;
            vm.sessionRecorings = [];

            Experience.getSessionActivities({
                page: 0,
                size: vm.pageSize,
                sort: sort(),
                query: getQueryString(),
                user_info: true
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.queryCount = headers('X-Total-Count');
                vm.sessionActivities = data;
                vm.page = 1;

                var i = 1;

                angular.forEach(vm.sessionActivities, function(sessionActivity) {
                    var folderName = sessionActivity.properties.media_id;

                    vm.sessionRecorings.push({
                        id: sessionActivity.id,
                        player_id: 'atheer-player-' + Math.floor(Math.random() * (100000 - 1000 + 1)) + 1000,
                        title: $filter('translate')('atheer.session.recordings.recording') + i++ ,
                        media_file_name: sessionActivity.properties.media_file_name,
                        media_url: '/media/session-recordings/' + folderName + '/' + sessionActivity.properties.media_file_name,
                        videoSettings: {
                            playlist: [{
                                file: '/media/session-recordings/' + folderName + '/' + sessionActivity.properties.media_file_name
                            }],
                            autostart: false,
                            aspectratio: "16:9",
                            skin: {
                                name: "atheer"
                            },
                            playbackRateControls: true,
                            playbackRates: [0.25, 0.75, 1, 1.25, 1.5, 2, 3, 4]
                        }
                    });
                });
                vm.loadingData = false;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        };

        $scope.$watch('vm.sessionModel', function(newValue) {
            if (angular.isDefined(vm.sessionModel) && vm.sessionModel != null) {
                loadData();
            }
        });
    };
})();
