(function() {
    'use strict';
    angular
        .module('atheer')
        .directive('fpCriteriaBuilder', fpCriteriaBuilder)
        .directive('fpCriteriaBuilderField', fpCriteriaBuilderField);

    function fpCriteriaBuilder() {
        return {
            restrict: 'AE',
            scope: {
                fpCriteria: '=',
                fpCriteriaFormIsValid: '=?'
            },
            templateUrl: 'core/components/filter/criteria-builder.tmpl.html',
            controller: CriteriaBuilderDirectiveController
        };
    }

    function fpCriteriaBuilderField() {
        return {
            restrict: 'AE',
            replace: true,
            scope: {
                fpFieldMeta: '=',
                fpFieldModel: '=',
                fpFieldOperator: '=',
                fpTimeZoneData: '=',
                fpLanguageData: '='
            },
            templateUrl: 'core/components/filter/criteria-builder-fields.tmpl.html',
            controller: CriteriaBuilderFieldsDirectiveController
        };
    }

    function CriteriaBuilderDirectiveController($scope, $q, $element, $filter, MetaData, Setting, CRITERIA_CONDITION_CONSTANT, DateUtils) {

        $scope.metaFieldGroups = [];
        $scope.isDataLoaded = false;
        $scope.timezones = [];
        $scope.languages = [];

        $scope.$watch('criteriaBuilderForm.$valid', function() {
            $scope.fpCriteriaFormIsValid = $scope.criteriaBuilderForm.$valid;
        });

        if (!$scope.fpCriteria) {
            $scope.fpCriteria = {};
            $scope.fpCriteria.condition_groups = [];
        }

        $scope.newFieldMeta = undefined;

        $scope.initFieldDropDownSearch = function() {
            $element.find('input[type="search"]').on('keydown', function(ev) {
                ev.stopPropagation();
            });
        }

        $scope.addField = function(condition_group_index, condition_index) {
            var default_field = {
                "field_group_name": undefined,
                "field_name": undefined,
                "data_type": undefined,
                "operator": null,
                "value": null,
            };
            $scope.fpCriteria.condition_groups[condition_group_index].conditions.splice(condition_index + 1, 0, angular.copy(default_field));
        }

        $scope.getDefaultOperator = function(dataType) {
            var defaultOperator = null;

            if ($scope.newFieldMeta.data_type == "BOOLEAN") {
                defaultOperator = "EQUAL";
            } else {
                var fieldConditionsMeta = CRITERIA_CONDITION_CONSTANT[$scope.newFieldMeta.data_type];
                defaultOperator = fieldConditionsMeta[0].value;
            }
            return defaultOperator;
        }

        $scope.addFieldGroup = function() {
            var defaultOperator = null;

            if ($scope.newFieldMeta.data_type == "BOOLEAN") {
                defaultOperator = "EQUAL";
            } else {
                var fieldConditionsMeta = CRITERIA_CONDITION_CONSTANT[$scope.newFieldMeta.data_type];
                defaultOperator = fieldConditionsMeta[0].value;
            }

            if ($scope.newFieldMeta) {
                var default_field = {
                    "field_group_name": $scope.newFieldMeta.field_group_name,
                    "field_name": $scope.newFieldMeta.field_name,
                    "data_type": $scope.newFieldMeta.data_type,
                    "operator": $scope.getDefaultOperator($scope.newFieldMeta.data_type),
                    "value": null,
                };

                var default_fieldGroup = {
                    conditions: [default_field]
                }

                $scope.fpCriteria.condition_groups.push(default_fieldGroup)
                $scope.newFieldMeta = undefined;
            }

        }

        $scope.hasCondition = function(field_group_name, field_name) {
            var fieldGroupMeta = $filter('filter')($scope.metaFieldGroups, {
                'name': field_group_name
            })[0];

            var fieldMeta = $filter('filter')(fieldGroupMeta.fields, {
                'name': field_name
            })[0];

            var fieldConditionsMeta = CRITERIA_CONDITION_CONSTANT[fieldMeta.data_type]
            if (fieldMeta.data_type === "OBJECT") {
                return fieldConditionsMeta[fieldMeta.ref_object];
            } else {
                return fieldConditionsMeta;
            }
        }

        $scope.getFieldOperators = function(field_group_name, field_name) {
            var fieldGroupMeta = $filter('filter')($scope.metaFieldGroups, {
                'name': field_group_name
            })[0];
            var fieldMeta = $filter('filter')(fieldGroupMeta.fields, {
                'name': field_name
            })[0];

            var fieldConditionsMeta = CRITERIA_CONDITION_CONSTANT[fieldMeta.data_type]
            if (fieldMeta.data_type === "OBJECT") {
                return fieldConditionsMeta[fieldMeta.ref_object];
            } else {
                return fieldConditionsMeta;
            }
        }

        $scope.removeField = function(criteriaIndex, fieldIndex) {
            $scope.fpCriteria.condition_groups[criteriaIndex].conditions.splice(fieldIndex, 1);
            if ($scope.fpCriteria.condition_groups[criteriaIndex].conditions.length == 0) {
                $scope.fpCriteria.condition_groups.splice(criteriaIndex, 1);
            }
        }

        function init() {
            $q.all([
                MetaData.get({
                    id: 'user',
                    is_name: true
                }).$promise,
                Setting.getSystemTimezones({
                    size: 100
                }).$promise,
                Setting.getSystemLanguages().$promise
            ]).then(function(data) {
                $scope.metaFieldGroups = data[0].field_groups;
                $scope.timezones = data[1];
                $scope.languages = data[2];
                $scope.isDataLoaded = true;
            });
        }
        init();
    }

    function CriteriaBuilderFieldsDirectiveController($scope, $filter, DateUtils) {
        $scope.tmp_date_option;

        $scope.formatToMetaObject = function(result) {
            var returnResultObj = [];
            angular.forEach(result, function(item) {
                returnResultObj.push({
                    'title': item.display || item.name || item.title || item.first_name + ' ' + item.last_name,
                    'id': item.id
                });
            })
            return returnResultObj;
        }

        $scope.getInitDateOption = function() {
            if ($scope.fpFieldModel && $scope.fpFieldModel.option) {
                return $scope.fpFieldModel.option;
            } else {
                return $scope.fpFieldModel;
            }
        }

        $scope.setDateFieldModel = function(value) {
            if (value == 'DAYS_BEFORE' || value == 'DAYS_AFTER') {
                $scope.fpFieldModel = {
                    'option': value,
                    'days': 0
                }
            } else {
                $scope.fpFieldModel = value
            }
        }

        $scope.getDate = function() {
            return $filter('fpToDate')($scope.fpFieldModel);
        }

        $scope.setDate = function(value) {
            $scope.fpFieldModel = value;
        }

        $scope.getFromDate = function() {
            if ($scope.fpFieldModel) {
                return $filter('fpToDate')($scope.fpFieldModel.from_date);
            } else {
                return new Date();
            }
        }

        $scope.getToDate = function() {
            if ($scope.fpFieldModel) {
                return $filter('fpToDate')($scope.fpFieldModel.to_date);
            } else {
                return new Date();
            }
        }



        $scope.setFromDateField = function(value) {
            $scope.fpFieldModel = {
                'from_date': value,
                'to_date': $scope.getToDate()
            }
        }

        $scope.setToDateField = function(value) {
            $scope.fpFieldModel = {
                'from_date': $scope.getFromDate(),
                'to_date': value
            }
        }
    }
})();
