(function() {
    'use strict';

    angular
        .module('atheer.team')
        .controller('TeamDetailDialogController', TeamDetailDialogController);

    /* @ngInject */
    function TeamDetailDialogController($scope, $state, $mdDialog, $q, $filter, $mdToast, $timeout, Team, mode, team) {
        var vm = this;

        vm.teams = [];
        vm.mode = mode;
        vm.createNewTeam = "true";
        vm.addToExistingTeam = "false";

        vm.teamModel = {
            name: null,
            description: null,
            type: 'STATIC',
            status: 'DRAFT'
        };

        vm.statusCheck= '';
        vm.fileSizeErrorMsg = false;
        if (team != null) {
            vm.teamModel = angular.copy(team);
        };

        vm.toggleMode = function(createMode) {
            if (createMode == 'create') {
                vm.createNewTeam = 'true';
                vm.addToExistingTeam = 'false';
            } else {
                vm.createNewTeam = 'false';
                vm.addToExistingTeam = 'true';
            }
        };

        vm.close = function() {
            $mdDialog.cancel();
        };

        vm.getTeamProfileImageUrl = function() {
            return vm.teamModel.picture_id ? '/media/pictures/' + vm.teamModel.picture_id : 'assets/images/avatars/group-avatar.png';
        };

        vm.updateTeamProfileImage = function(mediaId, responseObj) {
            vm.teamModel.picture_id = mediaId;
        };

        vm.removeTeamProfileImage = function() {
            vm.teamModel.picture_id = undefined;
        };

        vm.submit = function() {
            if (mode == 'DYNAMIC' || mode == 'UPDATE') {
                $mdDialog.hide(vm.teamModel);
            } else {
                if (vm.createNewTeam == 'true') {
                    $mdDialog.hide(vm.teamModel);
                } else {
                    $mdDialog.hide(vm.selectedTeam);
                }
            }
        };

        vm.loadAll = function() {
            $q.all([
                Team.query().$promise,
            ]).then(function(data) {

                angular.forEach(data[0], function(team) {
                    if (team.type == 'STATIC' && team.audience_no != 1000) {
                        vm.teams.push(team);
                    }
                });
            });
        };

        vm.loadAll();
    }
})();
