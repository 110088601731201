(function() {
    'use strict';

    angular
        .module('atheer')
        .controller('MatchSelectionPanelController', MatchSelectionPanelController);

    /* @ngInject */
    function MatchSelectionPanelController(mdPanelRef, $scope, $state, $mdDialog, $q, $filter, $mdToast, Principal, $mdPanel, FIELD_CONDITION_CONSTANT) {
        var vm = this;

        vm.matchType = 'all';

        vm.selectMatchType = function(matchType) {
            mdPanelRef.matchAll = matchType;
            mdPanelRef.close();
        };
    }
})();
