(function() {
    'use strict';

    angular
        .module('atheer.anchor')
        .controller('AnchorToolbarController', AnchorToolbarController);

    /* @ngInject */
    function AnchorToolbarController($rootScope, $scope, $state, $mdDialog, Anchor, ParseLinks, AlertService, pagingParams, paginationConstants, $mdToast, $stateParams, $filter, $translate, Feature, ToastService) {
        var vm = this;

        vm.refreshAll = refreshAll;
        vm.pageTitleKey = $state.$current.data.pageTitle;

        vm.query = {
            filter: ''
        };

        vm.filter = {
            options: {
                debounce: 500
            }
        };

        if ( getSavedFilters() ) {
            vm.query = getSavedFilters();
        }

        vm.removeFilter = removeFilter;
        vm.filter.show = vm.query.filter.length > 0 ? true : false;
        vm.createEnabled = false;
        vm.createObject = createObject;

        vm.permissions = {
            create: 'create-anchor',
            update: 'update-anchor',
            delete: 'delete-anchor',
            manage: 'manage-anchor'
        };

        vm.toggleFilter = function() {
            vm.filter.show = !vm.filter.show;
        };

        function refreshAll() {
            saveFilters(vm.query);
            $scope.$emit('refreshAnchors', vm.query.filter);
        };

        function removeFilter() {
            vm.filter.show = false;
            vm.query.filter = '';
            saveFilters(vm.query);
            $scope.$emit('refreshAnchors', vm.query.filter);
        };

        function createObject() {
            $state.go("storm.admin-anchor-detail");
        };

        function saveFilters(filterData) {
            sessionStorage.setItem('objectHeader', JSON.stringify(filterData));
        };
    
        function getSavedFilters() {
            return JSON.parse(sessionStorage.getItem('objectHeader'));
        };
    }
})();
