(function() {
    'use strict';

    angular
        .module('atheer.cloud')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('storm.cloud-admin', {
                url: '/cloud',
                abstract: true,
                views: {
                    '@storm': {
                        templateUrl: 'modules/cloud/cloud.tmpl.html',
                        controller: 'CloudController',
                        controllerAs: 'vm',
                    },
                    'sideMenu@storm': {
                        templateUrl: 'modules/cloud/cloud-menu.tmpl.html',
                        controller: 'CloudMenuController',
                        controllerAs: 'vm',
                    }
                },
                data: {
                    pageTitle: 'atheer.setting.home.title',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: false
                    }
                }
            });
    }
})();
