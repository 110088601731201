(function() {
    'use strict';

    angular
        .module('atheer.smartFlow')
        .controller('SmartFlowOptionsDialogController', SmartFlowOptionsDialogController);

    /* @ngInject */
    function SmartFlowOptionsDialogController($scope, $mdDialog, $state) {
        var vm = this;

        vm.launchSmartFlowTemplateSelector = launchSmartFlowTemplateSelector;
        vm.launchSmartFlowBuilder = launchSmartFlowBuilder;

        function launchSmartFlowTemplateSelector() {
            $state.go("storm.admin-smartflow-template");
            $mdDialog.hide();
        }

        function launchSmartFlowBuilder() {
            $state.go("storm.admin-smartflow-detail");
            $mdDialog.hide();
        }
    }
})();
