(function () {
    'use strict';

    angular
        .module('atheer.core')
        .directive('triMenuItem', triMenuItemDirective);

    /* @ngInject */
    function triMenuItemDirective() {
        var directive = {
            restrict: 'E',
            require: '^triMenu',
            scope: {
                item: '='
            },
            // replace: true,
            template: '<div ng-if="triMenuItem.isActive" permission permission-only="triMenuItem.item.permission" ng-include="::triMenuItem.item.template"></div>',
            controller: triMenuItemController,
            controllerAs: 'triMenuItem',
            bindToController: true
        };
        return directive;
    }

    /* @ngInject */
    function triMenuItemController($rootScope, $scope, $mdSidenav, $state, $filter, $location, triBreadcrumbsService, Feature, PermissionService, triSettings, triTheming) {
        var triMenuItem = this;

        triMenuItem.isActive = isFeatureActive();
        triMenuItem.primaryColor = triSettings.getSkinModel().primary_color_hex;
        triMenuItem.subMenus = [];

        $scope.backgroundColor = triTheming.getPaletteColor(triSettings.getSkinModel().primary_color, '100').hex;

        // load a template for this directive based on the type ( link | dropdown )
        triMenuItem.item.template = 'core/components/menu/menu-item.tmpl.html';

        triMenuItem.subMenuOpen = subMenuOpen;
        switch (triMenuItem.item.type) {
            case 'dropdown':
                // if we have kids reorder them by priority
                triMenuItem.item.children = $filter('orderBy')(triMenuItem.item.children, 'priority');
                triMenuItem.toggleDropdownMenu = toggleDropdownMenu;
                // add a check for open event
                $scope.$on('toggleDropdownMenu', function (event, item, open) {
                    // if this is the item we are looking for
                    if (triMenuItem.item === item) {
                        triMenuItem.item.open = open;
                    } else {
                        triMenuItem.item.open = false;
                    }
                });

                // this event is emitted up the tree to open parent menus
                $scope.$on('openParents', function () {
                    // openParents event so open the parent item
                    triMenuItem.item.open = true;
                    // also add this to the breadcrumbs
                    triBreadcrumbsService.addCrumb(triMenuItem.item);
                });
                break;
            case 'link':
                triMenuItem.openLink = openLink;

                // on init check if this is current menu
                checkItemActive($state.current.name, $state.params);

                $scope.$on('$stateChangeSuccess', function (event, toState, toParams) {
                    checkItemActive(toState.name, toParams);
                });
                break;
            case 'tab':
                triMenuItem.openLink = openLink;

                // on init check if this is current menu
                checkItemActive($state.current.name, $state.params);

                $scope.$on('$stateChangeSuccess', function (event, toState, toParams) {
                    checkItemActive(toState.name, toParams);
                });
                break;
            case 'menu':
                $scope.$on('$stateChangeSuccess', function (event, toState, toParams) {
                    if (toState.name.startsWith('storm.admin-airsdk')) {
                        $rootScope.selectedLeftNavMenu = 'airsdk';
                    } else if (toState.name.startsWith('storm.insights.adv-dashboards')) {
                        $rootScope.selectedLeftNavMenu = 'insights';
                    } else if (toState.name.startsWith('storm.admin-setting')) {
                        $rootScope.selectedLeftNavMenu = 'settings';
                    } else if (toState.name.startsWith('storm.sessions')) {
                        $rootScope.selectedLeftNavMenu = 'airsessions';
                    } else if (toState.name.startsWith('storm.conversations')) {
                        $rootScope.selectedLeftNavMenu = 'conversations';
                    } else if (toState.name.startsWith('storm.admin-users')) {
                        $rootScope.selectedLeftNavMenu = 'users';
                    } else if (toState.name.startsWith('storm.admin-teams')) {
                        $rootScope.selectedLeftNavMenu = 'users';
                    } else if (toState.name.startsWith('storm.admin-contents')) {
                        $rootScope.selectedLeftNavMenu = 'library';
                    } else if (toState.name.startsWith('storm.content-documents')) {
                        $rootScope.selectedLeftNavMenu = 'library';
                    }
                });

                for (var i = 0; i < triMenuItem.item.children.length; i++) {
                    var hasAccess = true;
                    var featureEnabled = true;

                    if (angular.isDefined(triMenuItem.item.children[i].feature)) {
                        featureEnabled = Feature.isActive(triMenuItem.item.children[i].feature);
                    }

                    if (featureEnabled && angular.isDefined(triMenuItem.item.children[i].permission)) {
                        hasAccess = PermissionService.hasAccess(triMenuItem.item.children[i].permission);
                    } else if (!featureEnabled) {
                        hasAccess = false;
                    };

                    if (hasAccess) {
                        triMenuItem.subMenus.push(triMenuItem.item.children[i]);
                    }
                };

                break;
        }

        function isFeatureActive() {
            if (angular.isDefined(triMenuItem.item.feature)) {
                return Feature.isActive(triMenuItem.item.feature);
            } else {
                return true;
            }
        }

        function checkItemActive() {
            triMenuItem.item.active = $state.includes(triMenuItem.item.state, triMenuItem.item.params);
            // if we are now the active item reset the breadcrumbs and open all parent dropdown items
            if (triMenuItem.item.active) {
                $scope.$emit('toggleLeftHandMenu', triMenuItem.item, false);
            }
        }

        function toggleDropdownMenu() {
            $scope.$parent.$parent.$broadcast('toggleDropdownMenu', triMenuItem.item, !triMenuItem.item.open);
        }

        function openLink() {
            sessionStorage.removeItem('objectHeader');
            sessionStorage.removeItem('objectMenu');
            sessionStorage.removeItem('objectFilters');
            if (!$rootScope.isJobExecutionActive) {
                $rootScope.selectedLeftNavMenu = triMenuItem.item.id;
                var params = angular.isUndefined(triMenuItem.item.params) ? {} : triMenuItem.item.params;
                $state.go(triMenuItem.item.state, params);
                triMenuItem.item.active = true;
                $mdSidenav('left').close();
            } else {
                var params = angular.isUndefined(triMenuItem.item.params) ? {} : triMenuItem.item.params;
                $state.go(triMenuItem.item.state, params);
            }
        }

        function subMenuOpen(menuItem) {
            sessionStorage.removeItem('objectHeader');
            sessionStorage.removeItem('objectMenu');
            sessionStorage.removeItem('objectFilters');
            $state.go(menuItem.state);
        }

        function leftSideNavOpen() {
            $scope.sideBarOpen = true;
        }

        function leftSideNavClose() {
            $scope.sideBarOpen = false;
        }

        function messagesCount(event, count) {
            triMenuItem.count = count;
        }

        // register listeners for loader
        $scope.$on('leftSideNavOpen', leftSideNavOpen);

        // register listeners for loader
        $scope.$on('leftSideNavClose', leftSideNavClose);

        $rootScope.$on('messageCount', messagesCount);
    }
})();
