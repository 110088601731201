(function() {
    'use strict';

    angular
        .module('atheer.anchor')
        .controller('AnchorDetailInsightsController', AnchorDetailInsightsController)

    /* @ngInject */
    function AnchorDetailInsightsController($mdDialog, $scope, $filter, $sce, $q, ParseLinks, pagingParams, paginationConstants, $state, Team, AlertService, $mdToast, $mdSidenav, ANCHOR_INSIGHT_META, Principal, Anchor, ContentAction, User, id) {

        var vm = this;

        vm.loadingData = true;

        vm.insightSections = angular.copy(ANCHOR_INSIGHT_META['anchor'].sections);

        vm.anchorModel = null;

        vm.loadData = function() {
            if (id) {
                Anchor.get({
                    id: id
                }, function(result) {
                    vm.anchorModel = result;
                    vm.loadingData = false;
                });
            }
        }
        vm.loadData();
    }
})();
