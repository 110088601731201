(function() {
    'use strict';

    angular
        .module('atheer.cloud')
        .controller('CloudMenuController', CloudMenuController);

    /* @ngInject */
    function CloudMenuController($scope, $state, Setting, AlertService, $mdDialog, $mdToast, $stateParams, Principal, Feature, PermissionService) {
        var vm = this;

        vm.loadingData = true;

        vm.loadAll = loadAll;
        vm.pageTitleKey = $state.$current.data.pageTitle;

        vm.settings = [];
        vm.activeLink = $state.current.name;

        vm.settingsConfig = [{
            state: 'storm.cloud-admin.job-streams',
            icon: 'fa fa-tasks',
            label: 'atheer.cloud.jobs',
            adminOnly: false,
        },  {
            state: 'storm.cloud-admin.logs',
            icon: 'zmdi zmdi-storage',
            label: 'atheer.cloud.logs',
            adminOnly: false,
        }];


        function loadAll() {
            vm.loadingData = false;

            angular.forEach(vm.settingsConfig, function(setting) {
                var featureActive = true;
                var hasPermission = true;

                if (angular.isDefined(setting.feature)) {
                    featureActive = Feature.isActive(setting.feature);
                };

                if (angular.isDefined(setting.permission)) {
                    hasPermission = PermissionService.hasAccess(setting.permission);
                };

                if (setting.adminOnly && Principal.isWorkspaceAdmin()) {
                    vm.settings.push(setting);
                } else if (!setting.adminOnly && featureActive && hasPermission) {
                    vm.settings.push(setting);
                };
            });
        };

        vm.loadAll();
    }
})();
