(function() {
    'use strict';
    angular
        .module('atheer.services')
        .factory('Message', Message);


    /* @ngInject */
    function Message($resource) {
        var resourceUrl = 'api/messages/:id';

        return $resource(resourceUrl, {
            id: '@id'
        }, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                isArray: true
            },
            'getPinned': {
                url: "api/messages/pinned",
                method: 'GET',
                isArray: true
            },
            'save': {
                method: 'POST'
            },
            'update': {
                method: 'PUT'
            },
            'pin': {
                url: resourceUrl + '/pinned',
                method: 'POST'
            },
            'reaction': {
                url: resourceUrl + '/reaction',
                method: 'POST'
            }
        });
    }
})();
