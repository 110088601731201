(function() {
    'use strict';

    angular
        .module('atheer')
        .directive('fpAutoComplete', fpAutoComplete);

    function fpAutoComplete() {
        var directive = {
            restrict: 'E',
            replace: 'true',
            scope: {
                'component': '@',
                'placeholder': '@?',
                'autoCompleteModel': '=',
                'multiple': '@?',
                'specialFilter': '&?',
                'queryObjs': '=', //  array of query fields with [{'fieldName' : 'name', operator=''}]
                'required': '@?',
                'isSession': '=',
                'isScheduledSession': '=',
                'isAdmin': '='
            },
            controller: autocompleteDirectiveController,
            templateUrl: 'core/components/autocomplete/autocomplete-directive.tmpl.html'
        };
        return directive;
    }

    /* @ngInject */
    function autocompleteDirectiveController($scope, $injector, $filter, $q, $mdDialog) {
        var componentToInject = $scope.component == "UserInvite" ? "User" : $scope.component;
        var service = $injector.get(componentToInject);
        $scope.required = $scope.required == 'true';

        $scope.allowMultiSelect = false;
        if ($scope.multiple == 'true') {
            $scope.allowMultiSelect = true;
        }

        var pickerDialogMeta = {
            'Content': {
                controller: 'ContentPickerController',
                templateUrl: 'modules/content/content-picker.html'
            },
            'Provider': {
                controller: 'ProviderPickerController',
                templateUrl: 'modules/content/provider/provider-picker.tmpl.html'
            },
            'Team': {
                controller: 'TeamPickerController',
                templateUrl: 'modules/team/team-picker.tmpl.html'
            },
            'Badge': {
                controller: 'BadgePickerController',
                templateUrl: 'modules/system/badge/badge-picker.tmpl.html'
            },
            'Folder': {
                controller: 'FolderPickerController',
                templateUrl: 'modules/content/folder/folder-picker.tmpl.html'
            },
            'Topic': {
                controller: 'TopicPickerController',
                templateUrl: 'modules/topic/topic-picker.tmpl.html'
            },
            'SecurityRole': {
                controller: 'SecurityRolePickerController',
                templateUrl: 'modules/system/security-role/security-role-picker.tmpl.html'
            },
            'User': {
                controller: 'UserPickerController',
                templateUrl: 'modules/user/user-picker.tmpl.html'
            },
            'EmailLayout': {
                controller: 'EmailLayoutPickerController',
                templateUrl: 'modules/system/email-layout/email-layout-picker.tmpl.html'
            },
            'UserInvite': {
                controller: 'SessionUserInviteController',
                templateUrl: 'modules/session/session-user-invite.tmpl.html'
            },
        };

        var query = '';

        $scope.placeholder = $scope.placeholder ? $scope.placeholder : $filter('translate')('autoComplete.placeholder.' + $filter('lowercase')($scope.component));

        angular.forEach($scope.queryObjs, function(queryObj, index) {
            if (index > 0) {
                query += ';';
            }
            query += (queryObj.fieldName + queryObj.operator + (queryObj.value ? queryObj.value : '{querySearchText}'))
        });

        $scope.itemChanged = function(item) {
            if ($scope.component == 'Topic') {
                $scope.autoCompleteModel = item;
            }
            if ($scope.component == 'Team' && item) {
                $scope.autoCompleteModel = item;
            }
            if ($scope.component == 'SecurityRole' && item) {
                $scope.autoCompleteModel = item;
            }
        };

        $scope.querySearch = function(searchText) {
            if (searchText.length >= 2) {
                var deferred = $q.defer();
                service.query({
                    'query': query.replace(/{querySearchText}/g, searchText),
                    check_admin: $scope.isAdmin
                }, function(result) {
                    if ($scope.specialFilter) {
                      deferred.resolve($scope.specialFilter({
                          result: result
                      }));
                    } else {
                        deferred.resolve(result);
                    }
                }, function(result) {
                    deferred.reject([]);
                });
                return deferred.promise;
            } else {
                return [];
            }
        };

        $scope.openPickerDialog = function(ev) {
            var pickerDialogMetaData = pickerDialogMeta[$scope.component];
            var filter = '';

            if ($scope.component == 'Content') {
                filter = 'embedded==false;enabled==true';
            }

            var users = [];
            if ($scope.component == 'User') {
                for (var i = 0; i < $scope.autoCompleteModel.length; i++) {
                    $scope.autoCompleteModel[i].is_guest ? users.push($scope.autoCompleteModel[i].guest_invite_value)
                        : users.push($scope.autoCompleteModel[i].id);
                }
            }

            var topics = []
            if ($scope.allowMultiSelect && $scope.component == 'Topic') {
                for (var i = 0; i < $scope.autoCompleteModel.length; i++) {
                    topics.push($scope.autoCompleteModel[i].id)
                }
            }

            angular.forEach($scope.queryObjs, function(queryObj, index) {
                if (angular.isDefined(queryObj.value)) {
                    if (filter !== '') {
                        filter += ';' + queryObj.fieldName + queryObj.operator + queryObj.value;
                    } else {
                        filter += queryObj.fieldName + queryObj.operator + queryObj.value;
                    }
                }
            });

            var selectedItemIds = [];
            if($scope.allowMultiSelect && $scope.autoCompleteModel) {
                angular.forEach($scope.autoCompleteModel, function(data) {
                    if(data && data.id) {
                        selectedItemIds.push(data.id);
                    }
                });
            } else if ($scope.autoCompleteModel) {
                selectedItemIds.push($scope.autoCompleteModel.id);
            }

            $mdDialog.show({
                multiple: true,
                templateUrl: pickerDialogMetaData.templateUrl,
                controller: pickerDialogMetaData.controller,
                controllerAs: 'vm',
                locals: {
                    filter: filter,
                    allowMultiSelect: $scope.allowMultiSelect,
                    userslist: users,
                    topics: topics,
                    isSession: $scope.isSession,
                    isScheduledSession: $scope.isScheduledSession,
                    isAdmin: $scope.isAdmin,
                    isConversationSession: false,
                    isSmartScan: false,
                    isWeb: true,
                    isNotFilter: false,
                    selectedItemIds: selectedItemIds
                }
            }).then(function(selectedItems) {
                if ($scope.allowMultiSelect) {
                    var deltaItems = [];
                    angular.forEach(selectedItems, function(selectedItem) {
                        if (!$filter('filter')($scope.autoCompleteModel, { id: selectedItem.id, guest_invite_value: selectedItem.guest_invite_value })[0]) {
                            deltaItems.push(selectedItem)
                        }
                    });
                    if (!$scope.autoCompleteModel) {
                        $scope.autoCompleteModel = [];
                    }
                    $scope.autoCompleteModel = $scope.autoCompleteModel.concat($scope.specialFilter ? $scope.specialFilter({result : deltaItems}) : deltaItems)
                } else {
                    $scope.autoCompleteModel = $scope.specialFilter ? $scope.specialFilter({ result: selectedItems })[0] : selectedItems[0];
                }
            });
        }

        $scope.transformChip = function(chip) {
            if (angular.isObject(chip)) {
                return chip;
            }
        }

        $scope.clear = function(){
            $scope.autoCompleteModel = null;
        }
    }
})();
