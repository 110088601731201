(function () {
    'use strict';

    angular
        .module('atheer.workspace')
        .controller('WorkspaceDetailInsightsController', WorkspaceDetailInsightsController);

    /* @ngInject */
    function WorkspaceDetailInsightsController($scope, $state, $mdDialog, $mdToast, $stateParams, id, 
        Setting, AlertService, Workspace, Principal, Feature, 
        PermissionService, workspaceId) {
        var vm = this;
        vm.loadingData = false;
        vm.isSaving = false;
        vm.isCreating = false;
        vm.errorSaving = false;

        vm.loadingData = true;
        vm.loadAll = loadAll;
        vm.pageTitleKey = $state.$current.data.layout.pageTitle;
        vm.workspaceId = id;
        vm.activeLink = $state.current.name;

        function loadAll() {
            console.log('With workspace id: ' + workspaceId);
            vm.advInsights = Workspace.getInsightSettings({
                id: workspaceId,
            });
            vm.loadingData = true;
        };

        vm.loadAll();

        $scope.$on('$destroy', function () {
            $scope.$emit('toggleSideMenu', 'reset');
        });

        vm.onUpdateSaveSuccess = function(result) {
            vm.advInsights = result;
            AlertService.displayToast($mdToast);
            vm.isSaving = false;
        };

        vm.onSaveError = function() {
            vm.isSaving = false;
            vm.isCreating = false;
            vm.errorSaving = true;
        };

        vm.save = function() {
            vm.isSaving = true;
            Workspace.setInsightSettings({id: workspaceId}, vm.advInsights, vm.onUpdateSaveSuccess, vm.onSaveError);
        };
    }
})();
