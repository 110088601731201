(function() {
    'use strict';

    angular
        .module('atheer.smartFlow')
        .controller('SmartFlowBuilderInsightsUserController', SmartFlowBuilderInsightsUserController);

    /* @ngInject */
    function SmartFlowBuilderInsightsUserController($scope, pagingParams, Experience, paginationConstants, $mdDialog, $filter, id, SmartFlow, $state, SmartFlowBuilder) {
        var vm = this;
        vm.viewType = 'insights';
        vm.dataLoaded = false;

        vm.smartFlowModel = $scope.$parent.vm.smartFlowModel;
        vm.goToSmartFlows = $scope.$parent.vm.goToSmartFlows;
        vm.isNew = $scope.$parent.vm.isNew;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;

        vm.smartFlows = [];

        vm.query = {
            filter: '',
            limit: paginationConstants.itemsPerPage,
            order: '-id',
            page: 1
        };

        vm.loadData = function() {
            Experience.getSmartFlows({
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort(),
                query: getQueryString(),
                user_info: true
            }, function(response, headers) {
                vm.smartFlows = response;
                vm.page = pagingParams.page;
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.dataLoaded = true;
            });
        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function getQueryString() {
            var queryString = "object_id==" + id;
            return queryString;
        }

        vm.transition = function() {
            vm.promise = Experience.getSmartFlows({
                page: vm.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort(),
                query: getQueryString(),
                user_info: true
            }).$promise;

            vm.promise.then(function(response, headers) {
                vm.smartFlows = response;
                vm.queryCount = vm.totalItems;
            });
        }

        vm.launchUserActivitySummary = function(userSmartFlow) {
            $mdDialog.show({
                templateUrl: 'modules/smartflow/smartflow-builder-insights-user-activity.tmpl.html',
                clickOutsideToClose: true,
                controller: "SmartFlowBuilderInsightsUserActivityController",
                controllerAs: "vm",
                locals: {
                    userSmartFlow: userSmartFlow,
                    smartFlow: $scope.$parent.vm.smartFlowModel
                }
            });
        }
        vm.loadData();
    }
})();
