(function() {
    'use strict';

    angular
        .module('atheer')
        .run(scormCloudRun);

    function scormCloudRun($rootScope, $state, $location, $log, Scorm, Base64) {
        var scormInfo = $location.search()['scormInfo'];

        if ($location.$$search.scormInfo) {
            delete $location.$$search.scormInfo;
            $location.$$compose();
        }

        if (angular.isDefined(scormInfo) && scormInfo != null) {
            var decodedScormInfo = Base64.decode(scormInfo);
            var assignmentId = getQueryVariable('assignmentId', decodedScormInfo);
            var viewId = getQueryVariable('viewId', decodedScormInfo);
            var launchTime = new Date(getQueryVariable('launchTime', decodedScormInfo));

            var closeTime = new Date();
            var time_spent = (closeTime - launchTime);

            Scorm.updateProgress({
                id: assignmentId,
                view_id: viewId,
                time_spent: time_spent
            }, function(result) {
                //$rootScope.$broadcast('scorm-update-complete');
            });
        }

        function getQueryVariable(variable, query) {
            var vars = query.split('&');
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split('=');
                if (decodeURIComponent(pair[0]) == variable) {
                    return decodeURIComponent(pair[1]);
                }
            }
        }
    }
})();
