(function() {
    'use strict';

    angular
        .module('atheer.insights')
        .controller('InsightsContentController', InsightsContentController);

    /* @ngInject */
    function InsightsContentController($scope, $q, $filter, $translate, Report, CONTENT_META, triSettings, Workspace, workspaceId) {
        var vm = this;

        vm.primaryColor = triSettings.getSkinModel().primary_color_hex;
        vm.secondaryColor = triSettings.getSkinModel().secondary_color_hex;
        vm.contentDashboard = null;
        vm.loadingData = false;
        vm.illustartionEmptyScreenId = "insight_graph";
        vm.getContentMeta = function(content_type) {
            var contentMeta = angular.copy($filter('filter')(CONTENT_META, {
                type: content_type.toLowerCase().replace(/_/g, '-')
            })[0]);

            return contentMeta;
        }

        vm.contentViewsChartData = {
            chart: {
                type: 'column',
            },
            title: {
                text: $translate.instant('atheer.insights.content.views')
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            yAxis: {
                title: {
                    text: $translate.instant('atheer.insights.content.noOfViews')
                }
            },
            xAxis: {
                title: {
                    text: $translate.instant('atheer.insights.dates')
                },
                categories: [],
                labels: {
                    enabled: true
                }
            },
            series: [{
                name: $translate.instant('atheer.insights.content.views'),
                color: vm.primaryColor,
                data: []
            }],
            tooltip: {
                formatter: function() {
                    return '<b>' + Highcharts.dateFormat('%e - %b - %Y',
                        new Date(this.x)) + '</b><br/> ' + $translate.instant('atheer.insights.content.views') + ' : ' + this.y;
                }
            }
        };

        vm.contentCreatedChartData = {
            chart: {
                type: 'column',
            },
            title: {
                text: $translate.instant('atheer.insights.content.contentCreated')
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            yAxis: {
                title: {
                    text: $translate.instant('atheer.insights.content.noOfContent')
                }
            },
            xAxis: {
                title: {
                    text: $translate.instant('atheer.insights.dates'),
                },
                categories: [],
                labels: {
                    enabled: true
                }
            },
            series: [{
                name: $translate.instant('atheer.insights.content.contentCreated'),
                color: vm.primaryColor,
                data: []
            }],
            tooltip: {
                formatter: function() {
                    return '<b>' + Highcharts.dateFormat('%e - %b - %Y',
                        new Date(this.x)) + '</b><br/> ' + $translate.instant('atheer.insights.content.created') + ' : ' + this.y;
                }
            }
        };

        vm.contentTimeSpentChartData = {
            chart: {
                type: 'line',
            },
            title: {
                text: $translate.instant('atheer.insights.content.timeSpent')
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            yAxis: {
                title: {
                    text: $translate.instant('atheer.insights.minutes')
                },
                labels: {
                    formatter: function() {
                        var seconds = (this.value / 1000).toFixed(0);
                        var minutes = (seconds / 60).toFixed(2);
                        return minutes;
                    }
                }

            },
            xAxis: {
                title: {
                    text: $translate.instant('atheer.insights.dates')
                },
                categories: [],
                labels: {
                    enabled: true
                }
            },
            series: [{
                name: $translate.instant('atheer.insights.content.avgTimeSpent'),
                data: [],
                color: '#82B1FF'
            }, {
                name: $translate.instant('atheer.insights.content.totalTimeSpent'),
                data: [],
                color: vm.primaryColor,
            }],
            tooltip: {
                formatter: function() {
                    var seconds = (this.y / 1000).toFixed(0);
                    var minutes = (seconds / 60).toFixed(2);
                    return '<b>' + Highcharts.dateFormat('%e - %b - %Y',
                        new Date(this.x)) + '</b><br/> ' + $translate.instant('atheer.insights.content.timeSpent') + ' : ' + minutes + ' minutes';
                }
            }
        };

        vm.contentTypeBarChartData = {
            chart: {
                type: 'column',
            },
            title: {
                text: $translate.instant('atheer.insights.content.contentType')
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            yAxis: {
                title: {
                    text: $translate.instant('atheer.insights.content.noOfContent')
                },
                allowDecimals: false
            },
            xAxis: {
                categories: [],
                labels: {
                    enabled: false
                }
            },
            plotOptions: {
                series: {
                    stacking: 'normal'
                }
            },
            series: [{ 
                name: $translate.instant('atheer.insights.content.total'),
                color: vm.primaryColor,
                data: []
            }, {
                name: $translate.instant('atheer.insights.content.featured'),
                color: "#d62728",
                data: []
            }],
            tooltip: {
                formatter: function() {
                    return '<b>' + this.series.name + '</b> : ' + this.y + ' ' + this.x;
                }
            }
        };

        vm.data = {
            "device_types_metrics": {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: 0,
                    plotShadow: false
                },
                title: {
                    text: 'Content View by Devices'
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                },
                plotOptions: {
                    pie: {
                        dataLabels: {
                            enabled: true,
                            distance: -50,
                            style: {
                                fontWeight: 'bold',
                                color: 'white'
                            }
                        }
                    }
                },
                credits: {
                    enabled: false
                },
                series: [{
                    type: 'pie',
                    name: 'Device usage',
                    innerSize: '50%',
                    data: []
                }]
            },
            "content_table_data": [{
                label: "Top Rated",
                data: []
            }, {
                label: "Most Viewed",
                data: []
            }, {
                label: "Most Liked",
                data: []
            }, {
                label: "Most Pinned",
                data: []
            }, {
                label: "Most Recommended",
                data: []
            }]
        }

        vm.getCount = function(activityName) {
            for (var i = 0; i < vm.contentDashboard.metrics.length; i++) {
                if (vm.contentDashboard.metrics[i].name === activityName) {
                    return vm.contentDashboard.metrics[i].count;
                }
            }
            return 0;
        }

        function getDateFromItem(item) {
            return item.x;
        }

        function performDataProcess() {
            //Msot Pinned/Popular/Liked/Viewed Data Process
            vm.data.content_table_data[0].data = vm.contentDashboard.top_rated;
            vm.data.content_table_data[1].data = vm.contentDashboard.most_liked;
            vm.data.content_table_data[2].data = vm.contentDashboard.most_pinned;
            vm.data.content_table_data[3].data = vm.contentDashboard.most_viewed;
            vm.data.content_table_data[3].data = vm.contentDashboard.most_recommended;

            //Content Created Line Char Data Process
            angular.forEach(vm.contentDashboard.history.content_created, function(contentCreatedObj) {
                vm.contentCreatedChartData.xAxis.categories.push(contentCreatedObj.name);
                vm.contentCreatedChartData.series[0].data.push(contentCreatedObj.count);
            });

            //Content Created Line Char Data Process
            angular.forEach(vm.contentDashboard.history.view, function(contentview) {
                vm.contentViewsChartData.xAxis.categories.push(contentview.name);
                vm.contentViewsChartData.series[0].data.push(contentview.count);
            });

            //set average time spent Data
            angular.forEach(vm.contentDashboard.history.avg_time_spent, function(timeSpentObj) {
                vm.contentTimeSpentChartData.xAxis.categories.push(timeSpentObj.name);
                vm.contentTimeSpentChartData.series[0].data.push(timeSpentObj.count);
            });

            //set total time spent Data
            angular.forEach(vm.contentDashboard.history.total_time_spent, function(timeSpentObj) {
                vm.contentTimeSpentChartData.xAxis.categories.push(timeSpentObj.name);
                vm.contentTimeSpentChartData.series[1].data.push(timeSpentObj.count);
            });

            //Content Bar Chart Data Process
            angular.forEach(vm.contentDashboard.type_metrics, function(contentTypeObj) {
                var featureObj = $filter('filter')(vm.contentDashboard.featured_metrics, {
                    name: contentTypeObj.name
                })[0];

                var contentMeta = vm.getContentMeta(contentTypeObj.name);

                vm.contentTypeBarChartData.xAxis.categories.push($translate.instant(contentMeta.label));
                vm.contentTypeBarChartData.series[0].data.push(contentTypeObj.count);
                vm.contentTypeBarChartData.series[1].data.push(featureObj ? featureObj.count : 0);

            });

            //device metrics
            var browserCount = $filter('filter')(vm.contentDashboard.device_metrics, {
                    name: 'browser'
                })[0],
                mobileCount = $filter('filter')(vm.contentDashboard.device_metrics, {
                    name: 'mobile'
                })[0],
                tabletCount = $filter('filter')(vm.contentDashboard.device_metrics, {
                    name: 'tablet'
                })[0]

            vm.data.device_types_metrics.showDeviceChart = browserCount || tabletCount || mobileCount;

            browserCount ? vm.data.device_types_metrics.series[0].data.push({
                name: 'Browser',
                y: browserCount.count,
                color: '#82B1FF'
            }) : angular.noop();
            tabletCount ? vm.data.device_types_metrics.series[0].data.push({
                name: 'Tablet',
                y: tabletCount.count,
                color: '#d62728'
            }) : angular.noop();
            mobileCount ? vm.data.device_types_metrics.series[0].data.push({
                name: 'Mobile',
                y: mobileCount.count,
                color: '#1f77b4'
            }) : angular.noop();

        }

        vm.loadData = function() {
            vm.loadingData = true;

            var resource = angular.isDefined(workspaceId) ? Workspace.getContentDashboard({
                id: workspaceId
            }).$promise : Report.getContentDashboard().$promise;

            $q.all([
                resource
            ]).then(function(data) {
                vm.contentDashboard = data[0];
                performDataProcess();
                vm.loadingData = false;

            }).catch(function(data) {}).finally(function() {});
        }
        vm.loadData();
    }
})();