(function() {
    'use strict';
    angular
        .module('atheer.services')
        .factory('Report', Report);

    /* @ngInject */
    function Report($resource, DateUtils) {
        var resourceUrl = 'api/reports/';

        return $resource(resourceUrl, {
            id: '@id'
        }, {
            'getQuestionResponseSummary': {
                url: resourceUrl + 'question/response/summary/:id',
                method: 'GET'
            },
            'getUserSummary': {
                url: resourceUrl + 'user/summary',
                method: 'GET',
                isArray: true
            },
            'exportUserSummary': {
                url: resourceUrl + 'user/summary/export',
                method: 'GET'
            },
            'getContentSummary': {
                url: resourceUrl + 'content/summary',
                method: 'GET',
                isArray: true
            },
            'exportContentSummary': {
                url: resourceUrl + 'content/summary/export',
                method: 'GET'
            },
            'getUserOverviewSummary': {
                url: resourceUrl + 'user/overview/summary/:id',
                method: 'GET'
            },
            'getContentOverviewSummary': {
                url: resourceUrl + 'content/overview/summary/:id',
                method: 'GET'
            },
            'getFlowbotOverviewSummary': {
                url: resourceUrl + 'workflow/overview/summary/:id',
                method: 'GET'
            },
            'getFlowbotActivitySummary': {
                url: resourceUrl + 'workflow/activity/summary/:id',
                method: 'GET',
                isArray: true
            },
            'getFlowbotUserActivitySummary': {
                url: resourceUrl + 'workflow/user/activity/summary/:id',
                method: 'GET',
                isArray: true
            },
            'getContentCardsSummary': {
                url: resourceUrl + 'content/cards/summary/:id',
                method: 'GET'
            },
            'getContentUserCardsSummary': {
                url: resourceUrl + 'content/cards/user/summary/:id/:userId',
                method: 'GET'
            },
            'getContentModulesSummary': {
                url: resourceUrl + 'content/modules/summary/:id',
                method: 'GET'
            },
            'getSmartScanSummary': {
                url: resourceUrl + 'smartscan/dashboard/:id',
                method: 'GET'
            },
            'getTopicSummary': {
                url: resourceUrl + 'topic/dashboard/:id',
                method: 'GET'
            },
            'getContentModulesUserSummary': {
                url: resourceUrl + 'content/modules/user/summary/:id/:userId',
                method: 'GET'
            },
            'getDashboard': {
                url: resourceUrl + 'dashboard',
                method: 'GET'
            },
            'getConversationDashboard': {
                url: resourceUrl + 'conversation/dashboard',
                method: 'GET'
            },
            'getSessionDashboard': {
                url: resourceUrl + 'session/dashboard',
                method: 'GET'
            },
            'getContentDashboard': {
                url: resourceUrl + 'content/dashboard',
                method: 'GET'
            },
            'getAirformDashboard': {
                url: resourceUrl + 'airform/dashboard',
                method: 'GET'
            },
            'getSmartScanDashboard': {
                url: resourceUrl + 'smartscan/dashboard',
                method: 'GET'
            },
            'getTopicDashboard': {
                url: resourceUrl + 'topic/dashboard',
                method: 'GET'
            },
            'getSmartflowDashboard': {
                url: resourceUrl + 'smartflow/dashboard',
                method: 'GET'
            },
            'getUserDashboard': {
                url: resourceUrl + 'user/dashboard',
                method: 'GET'
            },
        });
    }
})();
