(function () {
    'use strict';

    angular
        .module('atheer.insights')
        .controller('InsightDashboardDetailController', InsightDashboardDetailController)

    /* @ngInject */
    function InsightDashboardDetailController($scope, $mdDialog, $filter, $q, id, MetaData, Dashboard, ContentAction, 
        CONTENT_META, $state, AlertService, $mdToast, $mdSidenav, Content, Team, AirForm, User, ParseLinks, $timeout, 
        pagingParams, paginationConstants, $stateParams, $window, ToastService, launchMode) {
        var vm = this;

        var FORMLY_ATHEER_DATA_TYPE_MAPPING = {
            'BOOLEAN': 'boolean-switch',
            'STRING': 'input',
            'LIST': 'custom-select',
            'DATE': 'custom-datepicker',
            'DATETIME': 'datepicker',
            'NUMBER': 'input',
            'DECIMAL': 'input',
            'OBJECT': 'objectpicker',
            'ARRAY': 'custom-chips'
        };

        var FORMLY_TEMPLATE_TYPE_ATHEER_DATA_TYPE_MAPPING = {
            'STRING': 'text',
            'NUMBER': 'number',
            'DECIMAL': 'number'
        };

        vm.loadingData = false;
        vm.loadingInsights = false;
        vm.insights = [];
        vm.dashboardDataModel = null;
        vm.audiences = [];
        vm.owner = null;
        vm.isHovered = false;
        vm.topicMeta = null;
        vm.topicMetaFields = [];
        vm.show_scheduling = true;
        vm.show_audiences = true;
        vm.tabView = 'insights';
        vm.insightsPage = [];
        vm.insightsQueryCount = 0;

        vm.dashboardDataModel = {
            name: '',
            description: '',
        };
        vm.getDataModel = function() {
            return vm.dashboardDataModel;
        }

        if (angular.isDefined($stateParams.topicType) && $stateParams.topicType != null) {
            vm.topicType = $stateParams.topicType;
        };

        vm.query = {
            filter: '',
            limit: paginationConstants.itemsPerPage,
            page: 1
        };

        vm.isFormValid = function () {
            return (
                vm.dashboardDataModel.name !== undefined &&
                vm.dashboardDataModel.description !== undefined &&
                vm.dashboardDataModel.name.trim() !== ""
            );
        };

        vm.getQueryString = function (criteria) {
            if (criteria.length <= 0) {
                return null;
            } else {
                return 'name=re=' + criteria;
            }
        };

        vm.loadTeams = function (dashboard) {
            if (dashboard.audiences && dashboard.audiences.length > 0) {
                Team.query({
                    query: 'id=in=(' + dashboard.audiences.toString() + ')',
                }, function (response, headers) {
                    vm.audiences = response;
                });
            }
        };

        vm.goToDashboards = function () {
            if($stateParams.fromHomeTile) {
                $state.go("storm.home");
            } else {
                $state.go("storm.insights.adv-dashboards");
            }
        };

        vm.openInsightPicker = function () {
            $mdDialog.show({
                templateUrl: 'modules/insights/insight-picker.html',
                controller: 'InsightPickerController',
                controllerAs: 'vm',
                locals: {
                    filter: 'embedded==false;type=out=(SERIES,EMAIL_LAYOUT);meta_type==DISCOVERABLE;status!=ARCHIVED',
                    allowMultiSelect: true,
                    selectedInsights: vm.dashboardDataModel.insights
                }
            })
                .then(function (selectedItems) {
                    Dashboard.update(vm.dashboardDataModel, onSaveSuccess, onSaveError);

                    function onSaveSuccess(result) {
                        vm.loadInsights(vm.dashboardDataModel.id);
                        ToastService.displayToast($filter('translate')('atheer.insights.dashboard.addInsightConfirmation'), 3000);
                    };

                    function onSaveError() {

                    };
                });
        };

        vm.removeInsight = function (insightId) {
            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.insights.dashboard.removeInsight'))
                .ariaLabel($filter('translate')('atheer.insights.dashboard.removeInsight'))
                .ok($filter('translate')('entity.action.remove'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function () {
                vm.insights = vm.insights.filter(function(insight) {return insight.id != insightId});
                //console.log('Remove insight: ' + insightId + ', filtered list: ' + JSON.stringify(vm.insights));
                
                var insightIds = [];
                angular.forEach(vm.insights, function(value, key) {
                    insightIds.push(value.id);
                });
                vm.dashboardDataModel.insights = insightIds;
                
                Dashboard.update(vm.dashboardDataModel, onSaveSuccess, onSaveError);

                function onSaveSuccess(result) {
                    vm.loadAll();
                    ToastService.displayToast($filter('translate')('atheer.insights.dashboard.removeInsightConfirmation'), 3000);
                };

                function onSaveError() {

                };

            }).finally(function () { });
        };

        vm.loadInsights = function (dashboardId) {
            vm.loadingInsights = true;
            Dashboard.getDashboardInsights({
                id: dashboardId
            }, function (result, headers) {
                vm.insights = result;
                vm.loadingInsights = false;
                vm.insightsPage = pagingParams.page;
                vm.insightsQueryCount = headers('X-Total-Count');
            });
        };

        vm.loadAll = function () {

            vm.loadingData = true;

            MetaData.get({
                id: 'topic',
                is_name: true
            }, function (result, headers) {
                angular.forEach(result.field_groups, function (field_group) {
                    if (field_group.name == 'base') {
                        vm.topicMeta = field_group;
                    }
                });
                //console.log('dashboard detail loadAll: ' + JSON.stringify($stateParams));
                if (id) {                    
                    Dashboard.get({
                        id: id
                    }, function (result, headers) {
                        //console.log('Dashboard.get: ' + JSON.stringify(result));
                        vm.dashboardDataModel = result;
                        if(!vm.dashboardDataModel.insights || vm.dashboardDataModel.insights.length == 0) {
                            vm.dashboardDataModel.insights = [];
                        }
                        
                        if (vm.dashboardDataModel.available_from) {
                            vm.dashboardDataModel.available_from = new Date(vm.dashboardDataModel.available_from)
                        }
                        if (vm.dashboardDataModel.discontinue_from) {
                            vm.dashboardDataModel.discontinue_from = new Date(vm.dashboardDataModel.discontinue_from)
                        };

                        vm.loadTeams(vm.dashboardDataModel);
                        vm.loadInsights(id);

                        vm.loadingData = false;
                    });
                    
                } else {
                    vm.loadingData = false;
                }
            });


        };

        vm.save = function () {
            if (vm.audiences && vm.audiences.length > 0) {
                var audienceIds = [];
                angular.forEach(vm.audiences, function (audience) {
                    audienceIds.push(audience.id);
                });
                vm.dashboardDataModel.audiences = audienceIds;
            } else {
                vm.dashboardDataModel.audiences = null;
            }

            if(!vm.dashboardDataModel.type) {
                vm.dashboardDataModel.type = 'Custom';
            }

            if (vm.dashboardDataModel.id) {
                Dashboard.update(vm.dashboardDataModel, onSaveSuccess);
            } else {
                Dashboard.save(vm.dashboardDataModel, onSaveSuccess);
            }

            function onSaveSuccess(result) {
                id = result.id;
                vm.dashboardDataModel = result;
                console.log('onSaveSuccess::vm.dashboardDataModel: ' + JSON.stringify(vm.dashboardDataModel));
                if(!vm.dashboardDataModel.insights || vm.dashboardDataModel.insights.length == 0) {
                    vm.dashboardDataModel.insights = [];
                }
                vm.isSaving = false;
                AlertService.displayToast($mdToast);
            }
        };

        vm.isNew = function () {
            if (vm.dashboardDataModel.id) {
                return false;
            } else {
                return true;
            }
        };

        vm.isSystem = function () {
            if (vm.dashboardDataModel.id && (vm.dashboardDataModel.dashboard_no < 10000 || vm.dashboardDataModel.type == 'System')) {
                return true;
            }
            return false;
        };

        vm.isLaunchMode = function () {
            return launchMode;
        }

        vm.togglePanel = function (componentId) {
            $mdSidenav(componentId).toggle();
        };

        vm.getDefaultBackground = function () {
            return '#bdbec0';
        };

        vm.hideInfo = function () {
            $timeout(function () {
                if (vm.isHovered) {
                    vm.isHovered = false;
                }
            }, 1000);
        };

        function getFormlyField(atheerfield) {
            var formlytype = FORMLY_ATHEER_DATA_TYPE_MAPPING[atheerfield.data_type];
            var formlyTemplateType = FORMLY_TEMPLATE_TYPE_ATHEER_DATA_TYPE_MAPPING[atheerfield.data_type];
            var formlyField = {
                type: formlytype ? formlytype : 'input',
                key: atheerfield.name,
                templateOptions: {
                    label: atheerfield.label,
                    required: atheerfield.required
                }
            };
            if (formlytype == "datepicker") {
                formlyField.templateOptions['placeholder'] = $filter('translate')('atheer.about.selectDate');
            }
            if (formlyTemplateType) {
                formlyField.templateOptions['type'] = formlyTemplateType;
            }

            if (atheerfield.data_type == 'DATETIME') {
                formlyField.templateOptions['datepickerPopup'] = "HH:MM";
            }

            if (atheerfield.data_type == 'ARRAY') {
                formlyField.templateOptions['labelProp'] = "label";
                formlyField.defaultValue = [];
            }

            if (atheerfield.data_type == 'LIST') {
                formlyField.templateOptions['labelProp'] = "label";
                formlyField.templateOptions['valueProp'] = "option_id";
                formlyField.templateOptions['options'] = atheerfield.list_options;
            }

            if (atheerfield.data_type == 'OBJECT') {
                if (atheerfield.ref_object == 'Content') {
                    formlyField.templateOptions['objectName'] = 'Content';
                    formlyField.templateOptions['queryObject'] = [{
                        fieldName: 'title',
                        operator: '=re='
                    }];
                } else if (atheerfield.ref_object == 'User') {
                    formlyField.templateOptions['objectName'] = 'User';
                    formlyField.templateOptions['queryObject'] = [{
                        fieldName: 'text_search',
                        operator: '=ts='
                    }];
                }
            }
            return formlyField;
        };

        vm.loadAll();
    }
})();
