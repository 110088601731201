(function() {
    'use strict';

    angular
        .module('atheer.insights')
        .controller('InsightsDashboardsToolbarController', InsightsDashboardsToolbarController);

    /* @ngInject */
    function InsightsDashboardsToolbarController($rootScope, $scope, $state, $mdDialog, Dashboard, ParseLinks, AlertService, pagingParams, paginationConstants, $mdToast, $stateParams, $filter, $translate, Feature, ToastService) {
        var vm = this;

        vm.refreshAll = refreshAll;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.showNewDashboardMenu = false;
        vm.advInsightsDashboardEnabled = isAdvInsightsEnabled;

        vm.query = {
            searchText: ''
        };

        vm.filter = {
            options: {
                debounce: 500
            }
        };

        vm.removeFilter = removeFilter;
        vm.filter.show = false;

        vm.permissions = {
            create: 'create-dashboard',
            update: 'update-dashboard',
            delete: 'delete-dashboard',
            manage: 'manage-dashboard'
        };

        function isAdvInsightsEnabled() {
            var isActive = Feature.isActive('feature_adv-insights_enabled');
            console.log('vm.advInsightsDashboardEnabled = ' + isActive);
            return isActive;
        }

        vm.toggleFilter = function() {
            vm.filter.show = !vm.filter.show;
        };

        function refreshAll() {
            $scope.$emit('refreshDashboards', vm.query);
        };

        function removeFilter() {
            vm.filter.show = false;
            vm.query.searchText = '';
            $scope.$emit('refreshDashboards', vm.query);
        };

        vm.createDashboard = function() {
            $state.go("storm.insights.dashboard-detail");
        };
    }
})();
