(function() {
    angular
        .module('atheer')
        .controller('ErrorPageController', ErrorPageController);

    /* @ngInject */
    function ErrorPageController($state) {
        var vm = this;
        vm.goHome = goHome;
        /////////
        function goHome() {
            $state.go('storm.dashboard-analytics');
        }
    }
})();
