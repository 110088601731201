(function() {
    'use strict';

    angular
        .module('atheer.core')
        .factory('triLoaderService', LoaderService);

    /* @ngInject */
    function LoaderService($rootScope) {
        var queue = [];

        return {
            isActive: isActive,
            setLoaderActive: setLoaderActive
        };

        function isActive() {
            return queue.length !== 0;
        }

        function setLoaderActive(setActive) {
            if (setActive) {
                queue.push(true);
            } else {
                queue.shift();
            }
            $rootScope.$broadcast('loader', isActive());
        }
    }
})();
