(function() {
    'use strict';

    angular
        .module('atheer.user')
        .controller('ProfileLibraryUploadsController', ProfileLibraryUploadsController);

    /* @ngInject */
    function ProfileLibraryUploadsController($rootScope, $scope, $mdMedia, $state, $q,  $filter, $mdDialog, Content, triLoaderService, Feature, AlertService, pagingParams, paginationConstants, ParseLinks, Principal, CONTENT_META, ContentAction, id, User, Experience, Report, $window, $mdToast, ToastService) {
        var vm = this;
        vm.loadingData = false;
        vm.contents = [];
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.cardView = false;
        vm.listType = 'all';
        vm.listTypeLabel = 'All';
        vm.userId = id == 'me' ? Principal.getUserId() : id;
        vm.profileViewMode = (id == 'me' ? 'me' : 'admin');
        vm.isAdminMode = id != 'me';
        vm.userInfo = null;

        vm.query = {
            limit: paginationConstants.itemsPerPage,
            order: '-id',
            page: pagingParams.page - 1
        };

        vm.getBackgroundImage = function(imagepath) {
            return {
                'background-image': 'url(/media/images/' + imagepath + ')'
            }
        }

        function loadData() {
            vm.loadingData = true;

            Content.query({
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort(),
                query: getQueryString()
            }, function(data, headers) {
                vm.contents = data;
                vm.page = pagingParams.page;
                vm.totalItems = headers('X-Total-Count');
                vm.loadingData = false;
                vm.queryCount = vm.totalItems;
            });
        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function loadUserData() {
            $q.all([
                User.getById({
                    id: vm.userId
                }).$promise
            ]).then(function(data) {
                vm.userInfo = data[0];
                loadData();
            });
        }

        vm.transition = function() {
            vm.promise = Content.query({
                page: vm.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort(),
                query: getQueryString()
            }).$promise;

            vm.promise.then(function(response, headers) {
                vm.contents = response;
            });
        }

        vm.showList = function(listType) {
            loadData(listType);
        }

        vm.editBoard = function() {
            $mdDialog.show({
                    templateUrl: 'modules/user/board/board-detail-dialog.tmpl.html',
                    clickOutsideToClose: true,
                    controller: "BoardDetailDialogController",
                    controllerAs: "vm",
                    locals: {
                        board: vm.boardDetail,
                        isAdd: false
                    }
                })
                .then(function(board) {
                    vm.boardDetail = board;
                });
        }

        vm.deleteBoard = function() {
            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.upload.deleteBoard'))
                .ariaLabel($filter('translate')('atheer.upload.deleteBoard'))
                .ok($filter('translate')('entity.action.delete'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function() {
                Board.delete({
                    id: vm.boardDetail.id
                }, function() {
                    AlertService.displayToast($mdToast);
                });
            });
        };

        vm.deleteContent = function(content) {
            Board.removeContent({
                id: vm.boardDetail.id,
                contentId: content.id
            }, function(result) {
                ToastService.displayToast($filter('triTranslate')('atheer.upload.deleteContent'), 3000);
                loadData('all');
            });
        };

        function getQueryString() {
            return 'user_uploaded=ex=true;user_uploaded==true;created_by==' + vm.userInfo.login;
        };

        vm.openMyUploadDialog = function(ev) {
            ContentAction.openMyUploadDialog(ev, loadData());
        };

        vm.back = function() {
            $window.history.back();
        };

        loadUserData();
    }
})();
