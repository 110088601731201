(function() {
    'use strict';

    angular
        .module('atheer.team')
        .controller('TeamToolbarController', TeamToolbarController);

    /* @ngInject */
    function TeamToolbarController($rootScope, $scope, $state, $mdDialog, Team, ParseLinks, AlertService, pagingParams, paginationConstants, $mdToast, $stateParams, $filter, $translate, Feature, ToastService) {
        var vm = this;

        vm.refreshAll = refreshAll;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.showNewTeamMenu = false;

        vm.query = {
            filter: ''
        };

        vm.filter = {
            options: {
                debounce: 500
            }
        };

        if ( getSavedFilters() ) {
            vm.query = getSavedFilters();
        }

        vm.removeFilter = removeFilter;
        vm.filter.show = vm.query.filter.length > 0 ? true : false;

        vm.permissions = {
            create: 'manage-audience',
            update: 'manage-audience',
            delete: 'manage-audience',
            manage: 'manage-audience'
        };

        vm.toggleFilter = function() {
            vm.filter.show = !vm.filter.show;
        };

        function refreshAll() {
            saveFilters(vm.query);
            $scope.$emit('refreshTeams', vm.query.filter);
        };

        function removeFilter() {
            vm.filter.show = false;
            vm.query.filter = '';
            saveFilters(vm.query);
            $scope.$emit('refreshTeams', vm.query.filter);
        };

        vm.createStaticTeam = function() {
            $state.go('storm.admin-team-detail', {
                'teamType': 'STATIC'
            });
        };

        vm.createSmartTeam = function () {
          $state.go('storm.admin-team-detail', {
              'teamType': 'SMART'
          });
        };

        function saveFilters(filterData) {
            sessionStorage.setItem('objectHeader', JSON.stringify(filterData));
        };
    
        function getSavedFilters() {
            return JSON.parse(sessionStorage.getItem('objectHeader'));
        };
    }
})();
