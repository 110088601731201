(function() {
    'use strict';

    angular
        .module('atheer.user')
        .controller('MyUploadDialogController', MyUploadDialogController);

    /* @ngInject */
    function MyUploadDialogController($scope, $mdToast, $mdDialog, $state, $timeout, Content, CONTENT_META, $filter, AlertService, poller, Principal) {
        var vm = this;

        vm.contentType = null;
        vm.processStatus = 'IDLE';

        vm.close = function() {
            $mdDialog.cancel();
        }

        vm.createContent = function(mediaId, responseObj) {
            if (responseObj.type.toLowerCase() == 'document') {
                vm.contentType = 'document';
            } else if (responseObj.type.toLowerCase() == 'video') {
                vm.contentType = 'video';
            } else if (responseObj.type.toLowerCase() == 'audio') {
                vm.contentType = 'podcast';
            };

            vm.processStatus = 'UPLOADED';

            vm.contentMetaData = angular.copy($filter('filter')(CONTENT_META, {
                type: vm.contentType
            })[0]);

            vm.contentModel = vm.contentMetaData.default_model;
            vm.contentModel.title = responseObj.name;
            vm.contentModel.data.source_media = responseObj;
            vm.contentModel.user_uploaded = true;


            if (vm.contentType == 'document') {
                vm.contentModel.data.doc_status = 'UPLOADED';
            } else if (vm.contentType == 'video') {
                vm.contentModel.data.video_status = 'UPLOADED';
            } else if (vm.contentType == 'podcast') {
                vm.contentModel.data.podcast_status = 'UPLOADED';
            };
            vm.save();
        }

        vm.save = function() {
            if (vm.contentModel.id) {
                Content.update(vm.contentModel, vm.onSaveSuccess, vm.onSaveError);
            } else {
                Content.save(vm.contentModel, vm.onSaveSuccess, vm.onSaveError);
            }
        };

        vm.onSaveSuccess = function(result) {
            vm.contentModel = result;

            if (vm.contentType == 'document') {
                vm.checkDocumentStatus();
            } else if (vm.contentType == 'video') {
                vm.checkVideoStatus();
            } else if (vm.contentType == 'podcast') {
                vm.checkAudioStatus();
            };
        };

        vm.onSaveError = function() {
            $scope.vm.isSaving = false;
        };

        vm.checkDocumentStatus = function() {
            if ((vm.contentModel.data.doc_status == 'UPLOADED' || vm.contentModel.data.doc_status == 'PROCESSING') && vm.contentModel.id) {
                vm.processStatus = vm.contentModel.data.doc_status;

                // Get poller.
                var contentStatusPoller = poller.get(Content, {
                    action: 'get',
                    delay: 3000,
                    argumentsArray: [{
                        id: vm.contentModel.id
                    }],
                    smart: true
                });

                contentStatusPoller.promise.then(null, null, function(result) {
                    if (result.data.doc_status == 'READY') {
                        poller.stopAll();
                        vm.processStatus = 'COMPLETE';
                        vm.contentModel = result;
                        vm.completeProcess();
                    }
                    if (result.data.doc_status == 'FAILED') {
                        poller.stopAll();
                        vm.processStatus = 'FAILED';
                        vm.contentModel = result;
                    }
                });
            } else {
                vm.processStatus = vm.contentModel.data.doc_status;
                vm.completeProcess();
            }
        };

        vm.checkVideoStatus = function() {
            if ((vm.contentModel.data.video_status == 'UPLOADED' || vm.contentModel.data.video_status == 'PROCESSING') && vm.contentModel.id) {
                vm.processStatus = vm.contentModel.data.video_status;

                // Get poller.
                var contentStatusPoller = poller.get(Content, {
                    action: 'get',
                    delay: 3000,
                    argumentsArray: [{
                        id: vm.contentModel.id
                    }],
                    smart: true
                });

                contentStatusPoller.promise.then(null, null, function(result) {
                    if (result.data.video_status == 'READY') {
                        poller.stopAll();
                        vm.processStatus = 'COMPLETE';
                        vm.contentModel = result;
                        vm.completeProcess();
                    }
                    if (result.data.video_status == 'FAILED') {
                        poller.stopAll();
                        vm.processStatus = 'FAILED';
                        vm.contentModel = result;
                    }
                });
            } else {
                vm.processStatus = vm.contentModel.data.video_status;
                vm.completeProcess();
            }
        };

        vm.checkAudioStatus = function() {
            if ((vm.contentModel.data.podcast_status == 'UPLOADED' || vm.contentModel.data.podcast_status == 'PROCESSING') && vm.contentModel.id) {
                vm.processStatus = vm.contentModel.data.podcast_status;

                // Get poller.
                var contentStatusPoller = poller.get(Content, {
                    action: 'get',
                    delay: 3000,
                    argumentsArray: [{
                        id: vm.contentModel.id
                    }],
                    smart: true
                });

                contentStatusPoller.promise.then(null, null, function(result) {
                    if (result.data.podcast_status == 'READY') {
                        poller.stopAll();
                        vm.processStatus = 'COMPLETE';
                        vm.contentModel = result;
                        vm.completeProcess();
                    }
                    if (result.data.podcast_status == 'FAILED') {
                        poller.stopAll();
                        vm.processStatus = 'FAILED';
                        vm.contentModel = result;
                    }
                });
            } else {
                vm.processStatus = vm.contentModel.data.podcast_status;
                vm.completeProcess();
            }
        };

        vm.completeProcess = function() {
            AlertService.displayToast($mdToast);
            $mdDialog.hide();
        }

    }
})();
