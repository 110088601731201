(function() {
    'use strict';

    angular
        .module('atheer.team')
        .controller('TeamController', TeamController);

    /* @ngInject */
    function TeamController($rootScope, $scope, triTheming, triSettings, $window, $state, Team, ParseLinks, AlertService, pagingParams, paginationConstants, $mdDialog, $mdToast, $stateParams, Feature, $filter, ToastService) {
        var vm = this;

        vm.loadingData = false;
        vm.transition = transition;
        vm.removeFilter = removeFilter;
        vm.getQueryString = getQueryString;

        vm.createObject = createObject;
        vm.editObject = editObject;
        vm.deleteObject = deleteObject;
        vm.primaryBackgroundColor = triTheming.rgba(
            triTheming.getPaletteColor(triSettings.getSkinModel().primary_color, '500').value
        );
        vm.secondaryBackgroundColor = triTheming.rgba(
            triTheming.getPaletteColor(triSettings.getSkinModel().secondary_color, '500').value
        );

        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.reOrder = reOrder;
        vm.illustartionEmptyScreenId = "team";

        vm.permissions = {
            create: 'manage-audience',
            update: 'manage-audience',
            delete: 'manage-audience',
            manage: 'manage-audience'
        };

        vm.query = {
            filter: '',
            limit: paginationConstants.itemsPerPage,
            order: '-id',
            page: 1,
            filters: {
                active: false,
                externalId: false,
                functionalTeam: false,
                type: null,
                static: false,
                dynamic: false
            }
        };

        vm.filter = {
            show: false,
            options: {
                debounce: 500
            }
        };

        function loadAll() {
            vm.loadingData = true;
            var savedQuery = getSavedFilters();
            if ( savedQuery ) {
                vm.query = savedQuery;
            }
            vm.page = vm.query.page;

            Team.query({
                page: vm.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: getSortOrder(),
                query: getQueryString()
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.loadingData = false;
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.teams = data;
                //vm.page = pagingParams.page;

                processData();
            }

            function onError(error) {
                AlertService.error(error.data.message);
                vm.loadingData = false;
            }
        }

        function processData() {
            angular.forEach(vm.teams, function(team) {
                if (team.status == 'DRAFT') {
                    team.active = false;
                } else {
                    team.active = true;
                }
            });
        }

        function reOrder(order) {
            vm.predicate = order;
            vm.reverse = !vm.reverse;
            vm.transition();
        }

        function getSortOrder() {
            if (vm.predicate.startsWith('-')) {
                return vm.predicate.substr(1) + ',' + 'desc';
            } else {
                return vm.predicate + ',' + 'asc';
            }

        }

        function transition() {
            vm.promise = Team.query({
                page: vm.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: getSortOrder(),
                query: getQueryString()
            }).$promise;

            vm.promise.then(function(data, headers) {
                vm.queryCount = vm.totalItems;
                vm.teams = data;
                processData();
            });
            vm.query.page = vm.page;
            saveFilters(vm.query);
        };

        function getQueryString() {
            var teamQuery = "audienceNo=out=(1000,1001)";

            if (vm.query.filters.active) {
                if (teamQuery === "") {
                    teamQuery = 'status=in=(ACTIVE)';
                } else {
                    teamQuery = teamQuery + ';status=in=(ACTIVE)';
                }
            }

            if (vm.query.filters.externalId) {
                if (teamQuery === "") {
                    teamQuery = 'externalId=ex=true';
                } else {
                    teamQuery = teamQuery + ';externalId=ex=true';
                }
            }

            if (vm.query.filters.functionalTeam) {
                if (teamQuery === "") {
                    teamQuery = 'functionalTeam==true';
                } else {
                    teamQuery = teamQuery + ';functionalTeam==true';
                }
            }

            if (vm.query.filters.static || vm.query.filters.dynamic) {
                var typeFilter  = "";
                if(vm.query.filters.static && !vm.query.filters.dynamic) {
                    typeFilter = typeFilter + 'type=in=(STATIC)'
                }
                if(!vm.query.filters.static && vm.query.filters.dynamic) {
                    typeFilter = typeFilter + 'type=in=(SMART)'
                }
                if(vm.query.filters.static && vm.query.filters.dynamic) {
                    typeFilter = typeFilter + 'type=in=(STATIC,SMART)'
                }

                if (teamQuery === "") {
                    teamQuery = typeFilter;
                } else {
                    teamQuery = teamQuery + ';' + typeFilter;
                }
            }

            if (vm.query.filter.length > 0) {
                teamQuery = teamQuery + ';' + 'name=re=' + vm.query.filter;
            }

            return teamQuery;
        }

        function removeFilter() {
            vm.filter.show = false;
            vm.query.filter = '';

            if (vm.filter.form.$dirty) {
                vm.filter.form.$setPristine();
            }
        };

        vm.getDefaultBackground = function() {
            return '#bdbec0';
        };

        vm.back = function() {
            $window.history.back();
        };

        vm.onPublishChange = function(team) {
            Team.update(team, function() {
                ToastService.displayToast(team.name + ' ' + $filter('translate')('atheer.content.enableUpdateSuccess'), 3000);
            }, function() {
                ToastService.displayToast($filter('translate')('atheer.content.enableUpdateError'), 3000)
            });
        }

        function createObject(parentTeam) {
            $state.go('storm.admin-team-detail', {
                'teamType': 'STATIC',
                'parentTeam': null
            });
        };

        function editObject(objectId) {
            $state.go("storm.admin-team-detail", {
                'id': objectId
            });
        }

        function deleteObject(entity) {
            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.team.deleteTeam'))
                .ariaLabel($filter('translate')('atheer.team.deleteTeam'))
                .ok($filter('translate')('entity.action.delete'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function() {
                Team.delete({
                    id: entity.id
                }, function() {
                    resetModal();
                },function(error) {
                    var errorMessage;
                    if(error.data.description && error.data.description != '') {
                        errorMessage = error.data.description;
                    } else {
                        errorMessage = $filter('translate')('atheer.team.errorDeletingTeam');
                    }
                    var errorWindow = $mdDialog.alert()
                        .title(errorMessage)
                        .ok('Close');
                    $mdDialog.show(errorWindow);
                });
            });
        };

        function resetModal() {
            loadAll();
            AlertService.displayToast($mdToast);
        };

        var refreshTeamListener = $rootScope.$on('refreshTeams', function(event, filter) {
            vm.query.filter = filter;
            vm.query.page = 1;
            saveFilters(vm.query);
            loadAll();
        });

        var updateTeamListener = $rootScope.$on('updateTeamList', function(event, filters) {
            vm.query.filters = filters;
            vm.query.page = 1;
            saveFilters(vm.query);
            loadAll();
        });

        function saveFilters(filterData) {
            sessionStorage.setItem('objectFilters', JSON.stringify(filterData));
        };
    
        function getSavedFilters() {
            return JSON.parse(sessionStorage.getItem('objectFilters'));
        };

        // Unregister
        $scope.$on('$destroy', function() {
            refreshTeamListener();
            updateTeamListener();
        });

        loadAll();
    }
})();
