(function () {
    'use strict';
    angular
        .module('atheer.services')
        .factory('Content', Content);

    /* @ngInject */
    function Content($resource, $q, DateUtils) {
        var resourceUrl = 'api/contents/:id';

        return $resource(resourceUrl, {
            id: '@id'
        }, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (result) {
                    return transformResponse(result);
                }
            },
            'getConversationContents': {
                url: 'api/contents/conversation',
                method: 'GET',
                isArray: true
            },
            'getUnfilteredContents': {
                url: 'api/contents/unfiltered',
                method: 'GET',
                isArray: true
            },
            'save': {
                method: 'POST',
                transformResponse: function (result, headers, status) {
                    return (status === 201) ? transformResponse(result) : $q.reject(angular.fromJson(result));
                }
            },
            'update': {
                method: 'PUT',
                transformResponse: function (result, headers, status) {
                    return (status === 200) ? transformResponse(result) : $q.reject(angular.fromJson(result));
                }
            },
            'process': {
                url: 'api/contents/:id/process',
                method: 'PUT',
                transformResponse: function (result) {
                    return transformResponse(result);
                }
            },
            'convert': {
                url: 'api/contents/convert/:id',
                method: 'GET',
                isArray: true
            },
            'extract': {
                url: 'api/contents/extract',
                method: 'GET',
                isArray: false,
                params: {
                    url: null
                }
            },
            'oembed': {
                url: 'api/contents/oembed',
                method: 'GET',
                isArray: false,
                params: {
                    url: null
                }
            },
            'localContentShare': {
                url: 'api/contents/capture',
                method: 'POST',
                isArray: false
            },
            'processDocument': {
                url: 'api/contents/process/',
                method: 'POST',
                isArray: true
            }
        });

        function transformResponse(result) {

            result = angular.fromJson(result);

            if (result.data.available_from) {
                result.data.available_from = DateUtils.convertDateTimeFromServer(result.data.available_from);
            }

            if (result.data.discontinue_from) {
                result.data.discontinue_from = DateUtils.convertDateTimeFromServer(result.data.discontinue_from);
            }

            if (result.data.close_date) {
                result.data.close_date = DateUtils.convertDateTimeFromServer(result.data.close_date);
            }

            if (result.data.cards && result.data.type == 'smart_content') {
                angular.forEach(result.data.cards, function (card) {
                    if (card.data.available_from) {
                        card.data.available_from = DateUtils.convertDateTimeFromServer(card.data.available_from);
                    }

                    if (result.data.discontinue_from) {
                        card.data.discontinue_from = DateUtils.convertDateTimeFromServer(card.data.discontinue_from);
                    }
                });
            }
            return result;
        }
    }
})();
