(function () {
    "use strict";

    angular
        .module("atheer.services")
        .factory("PermissionService", PermissionService);

    /* @ngInject */
    function PermissionService($q, $http, Principal, Feature, $location) {
        var service = {
            hasPermission: hasPermission,
            hasAccess: hasAccess,
        };
        return service;

        function hasPermission(permission, transitionProperties) {
            var deferred = $q.defer();
            if ($location.path().startsWith("/deeplink/sessions")) {
                deferred.reject();
            } else if (permission.startsWith("feature")) {
                if (Feature.isActive(permission)) {
                    deferred.resolve();
                } else {
                    deferred.reject();
                }
            } else {
                Principal.identity().then(function (user) {
                    if (user && user.permissionSet.indexOf(permission) > -1) {
                        deferred.resolve();
                    } else {
                        deferred.reject();
                    }
                });
            }
            return deferred.promise;
        }

        function hasAccess(permission) {
            var user = Principal.getUserInfo();
            if (user && user.permissionSet.indexOf(permission) > -1) {
                return true;
            } else {
                return false;
            }
        }
    }
})();
