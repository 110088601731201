(function() {
    'use strict';

    angular
        .module('atheer.workspace')
        .controller('WorkspaceSignupConfirmController', WorkspaceSignupConfirmController);

    /* @ngInject */
    function WorkspaceSignupConfirmController($translate, $timeout, WorkspaceSignup, $scope, $state, $stateParams, $mdToast, $http, $filter, triSettings) {
        var vm = this;

        vm.firstTime = true;
        vm.validCode = false;
        vm.checkingCode = false;
        vm.triSettings = triSettings;

        vm.workspaceSignupData = $stateParams.workspaceSignupData;

        vm.confirmCode1 = null;
        vm.confirmCode2 = null;
        vm.confirmCode3 = null;
        vm.confirmCode4 = null;
        vm.confirmCode5 = null;
        vm.confirmCode6 = null;

        if (!vm.workspaceSignupData) {
            $state.go('workspace.signup');
        }

        vm.checkConfirmationCode = function(code) {
            if (code < 6) {
                $timeout(function() {
                    var nextInput = '[ng-model="vm.confirmCode' + (code + 1) + '"]';
                    angular.element(nextInput).focus();
                });
            }

            var confirmationCode = vm.confirmCode1 + vm.confirmCode2 + vm.confirmCode3 + "-" + vm.confirmCode4 + vm.confirmCode5 + vm.confirmCode6;

            if (confirmationCode && confirmationCode.length == 7) {
                vm.firstTime = false;
                vm.checkingCode = true;

                WorkspaceSignup.get({
                    code: confirmationCode
                }, function(result) {
                    if (result && result.confirmation_code && result.confirmation_code == confirmationCode) {
                        vm.validCode = true;
                        $state.go('workspace.setup-user-info', {
                            "workspaceSignupData": vm.workspaceSignupData
                        });
                    } else {
                        vm.validCode = false;

                        vm.confirmCode1 = null;
                        vm.confirmCode2 = null;
                        vm.confirmCode3 = null;
                        vm.confirmCode4 = null;
                        vm.confirmCode5 = null;
                        vm.confirmCode6 = null;

                        $timeout(function() {
                            angular.element('[ng-model="vm.confirmCode1"]').focus();
                        });
                    }
                    vm.checkingCode = false;
                });
            }
        };
    }
})();
