(function() {
    'use strict';

    angular
        .module('atheer.system')
        .controller('MediaDialogController', MediaDialogController);

    /* @ngInject */    
    function MediaDialogController ($scope, $stateParams, $mdDialog, entity, Media) {
        var vm = this;
        vm.media = entity;
        vm.load = function(id) {
            Media.get({id : id}, function(result) {
                vm.media = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('atheer:mediaUpdate', result);
            $mdDialog.hide(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.media.id !== null) {
                Media.update(vm.media, onSaveSuccess, onSaveError);
            } else {
                Media.save(vm.media, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $mdDialog.cancel();
        };
    }
})();
