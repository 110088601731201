(function() {
    'use strict';

    angular
        .module('atheer.workspace')
        .controller('WorkspaceDetailInsightsToolbarController', WorkspaceDetailInsightsToolbarController);

    /* @ngInject */
    function WorkspaceDetailInsightsToolbarController($scope, $state, Setting, AlertService, $mdDialog, $mdToast, $stateParams, Workspace, Principal, Feature, id, PermissionService) {
        var vm = this;

        vm.loadingData = true;
        vm.loadAll = loadAll;
        vm.pageTitleKey = $state.$current.data.layout.pageTitle;
        vm.workspaceId = id;
        vm.activeLink = $state.current.name;

        function loadAll() {
            vm.loadingData = true;
            Workspace.get({
                id: id
            }, function(result) {
                vm.loadingData = false;
                vm.workspace = result;
            });
        };

        vm.loadAll();

        $scope.$on('$destroy', function() {
            $scope.$emit('toggleSideMenu', 'reset');
        });
    }
})();
