(function() {
    'use strict';

    angular
        .module('atheer.topic')
        .controller('TopicDetailInsightsController', TopicDetailInsightsController)

    /* @ngInject */
    function TopicDetailInsightsController($mdDialog, $scope, $filter, $sce, $q, ParseLinks, pagingParams, paginationConstants, $state, Team, AlertService, $mdToast, $mdSidenav, TOPIC_INSIGHT_META, Principal, Topic, ContentAction, User, id) {

        var vm = this;

        vm.loadingData = true;

        vm.insightSections = angular.copy(TOPIC_INSIGHT_META['topic'].sections);

        vm.topicModel = null;

        vm.loadData = function() {
            if (id) {
                Topic.get({
                    id: id
                }, function(result) {
                    vm.topicModel = result;
                    vm.loadingData = false;
                });
            }
        }
        vm.loadData();
    }
})();
