(function() {
    'use strict';

    angular
        .module('atheer.team')
        .controller('TeamPickerController', TeamPickerController);

    /* @ngInject */
    function TeamPickerController($scope, $state, $mdDialog, Team, ParseLinks, AlertService, $mdToast, $filter, filter, allowMultiSelect, selectedItemIds, paginationConstants) {
        var vm = this;

        vm.loadAll = loadAll;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.predicate = "id";
        vm.transition = transition;
        vm.reOrder = reOrder;
        vm.selected = [];
        vm.allowMultiSelect = allowMultiSelect;

        vm.currentSelectedItems = [];

        vm.query = {
            filter: '',
            limit: paginationConstants.itemsPerPage,
            order: '-id',
            page: 1
        };

        vm.filter = {
            options: {
                debounce: 500
            }
        };

        vm.removeFilter = removeFilter;
        vm.filter.show = false;
        vm.getQueryString = getQueryString;

        vm.loadAll();

        function loadAll() {
            Team.query({
                page: 0,
                size: vm.query.limit,
                sort: sort(),
                query: getQueryString()
            }, onSuccess, onError);

            function sort() {
                var result = ['id' + ',' + 'asc'];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.teams = data;
                vm.page = 1;

                setOrUpdateSelectedItems();
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function reOrder(order) {
            vm.predicate = order;
            vm.transition();
        }

        function transition() {
            vm.promise = Team.query({
                page: vm.page - 1,
                size: vm.query.limit,
                sort: getSortOrder(),
                query: getQueryString()
            }).$promise;

            vm.promise.then(function(data, headers) {
                vm.queryCount = vm.totalItems;
                vm.teams = data;

                setOrUpdateSelectedItems();
            });
        }

        function getSortOrder() {
            if (vm.predicate.startsWith('-')) {
                return vm.predicate.substr(1) + ',' + 'desc';
            } else {
                return vm.predicate + ',' + 'asc';
            }

        }

        function getQueryString() {
            if (vm.query.filter.length <= 0) {
                return filter;
            } else {
                return filter + ';name=re="' + vm.query.filter +'"';
            }
        }

        function removeFilter() {
            vm.filter.show = false;
            vm.query.filter = '';
            resetModal();
        }

        function resetModal() {
            loadAll();
            AlertService.displayToast($mdToast);
        };

        function setOrUpdateSelectedItems() {
            angular.forEach(selectedItemIds, function(selectedItemId) {
                angular.forEach(vm.teams, function(team) {
                    if(selectedItemId === team.id) {
                        vm.currentSelectedItems.push(team);
                    }
                });
            });
        };

        vm.updateParentObject = function() {
            $mdDialog.hide(vm.currentSelectedItems);
        }

        vm.close = function() {
            $mdDialog.cancel();
        }
    }
})();
