(function() {
    'use strict';

    angular
        .module('atheer.user')
        .controller('ProfileEventListController', ProfileEventListController);

    /* @ngInject */
    function ProfileEventListController($scope, $state, $mdDialog, Principal, $translate, pagingParams, paginationConstants, $mdToast, $filter, Experience, id, eventName, eventMeta) {
        var vm = this;
        vm.doNotMatch = null;
        vm.error = null;
        vm.success = null;

        vm.eventName = eventName;
        vm.eventMeta = eventMeta;

        vm.user = null;
        vm.loadingData = true;
        vm.userId = id == 'me' ? Principal.getUserId() : id;
        vm.events = [];

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;

        vm.query = {
            filter: '',
            limit: paginationConstants.itemsPerPage,
            order: '-id',
            page: 1
        };

        var eventLabel = vm.eventMeta.label;
        vm.eventMetaTooltip = 'atheer.event.add.' + $filter('lowercase')(eventLabel.replace(/ /g, ""));

        vm.getListValueLabel = function(field, value) {
            return $filter('filter')(field.list_options, {
                'option_id': value
            })[0].label
        };

        vm.getFieldValue = function(field, value) {
            if(field.data_type == 'DATE') {
                return moment(value).format('MM/DD/YYYY');
            } else {
                return value;
            }
        };

        vm.addEvent = function() {
            $mdDialog.show({
                templateUrl: 'modules/user/event/event-dialog.tmpl.html',
                clickOutsideToClose: true,
                controller: "ProfileEventDialogController",
                controllerAs: "vm",
                size: 'lg',
                locals: {
                    eventMeta: vm.eventMeta,
                    userEvent: {
                        owner_id: vm.userId,
                        owner_type: 'user',
                        name: vm.eventName,
                        properties: {

                        }
                    }
                }
            }).then(function() {
                vm.loadData();
            });
        }

        vm.editEvent = function(userEvent) {
            $mdDialog.show({
                templateUrl: 'modules/user/event/event-dialog.tmpl.html',
                clickOutsideToClose: true,
                controller: "ProfileEventDialogController",
                controllerAs: "vm",
                size: 'lg',
                locals: {
                    eventMeta: vm.eventMeta,
                    userEvent: userEvent
                }
            }).then(function() {
                vm.loadData();
            });
        }

        vm.removeEvent = function(event, index) {
            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.event.removeEvent'))
                .ariaLabel($filter('translate')('atheer.event.removeEvent'))
                .ok($filter('translate')('entity.action.delete'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function() {
                Experience.deleteEvent({
                    eventType: vm.eventName,
                    id: event.id
                }, function() {
                    vm.loadData();
                });
            });
        }

        vm.loadData = function() {
            vm.loadingData = true;
            //fetch pins data
            Experience.getEvents({
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort(),
                query: getQueryString()
            }, function(response, headers) {
                vm.page = pagingParams.page;
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.events = response;
                vm.loadingData = false;
            });
        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function getQueryString() {
            var queryString = "name==" + vm.eventName + ";owner_id==" + vm.userId;
            if (vm.query.filter.length <= 0) {
                return queryString;
            }
        }

        vm.transition = function() {
            vm.promise = Experience.getEvents({
                page: vm.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort(),
                query: getQueryString()
            }).$promise;

            vm.promise.then(function(response, headers) {
                vm.events = response;
                vm.queryCount = vm.totalItems;
            });
        }
        vm.loadData();
    }
})();
