(function() {

    'use strict';
    angular
        .module('atheer.anchor')
        .constant('ANCHOR_RECOGNITION_META', {
            anchor: {
                sections: {
                    qr_code: {
                        label: 'atheer.anchor.qrCode',
                        icon: 'zmdi zmdi-account',
                        controller: 'AnchorDetailQRCodeController',
                        template: 'modules/anchor/anchor-detail-qrcode.tmpl.html'
                    }
                }
            }
        });
})();
