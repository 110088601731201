(function() {
    'use strict';

    angular
        .module('atheer.workspace')
        .controller('WorkspaceDetailInfoController', WorkspaceDetailInfoController)

    /* @ngInject */
    function WorkspaceDetailInfoController($mdDialog, $scope, $filter, $sce, ParseLinks, pagingParams, paginationConstants, $state, $q, AlertService, $mdToast, $mdSidenav, Workspace, id, $timeout, Setting) {
        var vm = this;

        vm.loadingData = false;
        vm.isSaving = false;
        vm.isCreating = false;
        vm.errorSaving = false;

        vm.loadingData = true;

        Workspace.get({
            id: id
        }, function(result) {
            vm.loadingData = false;
            vm.workspace = result;
        });

        vm.onUpdateSaveSuccess = function(result) {
            vm.workspace = result;

            AlertService.displayToast($mdToast);
            vm.isSaving = false;
        };

        vm.onSaveError = function() {
            vm.isSaving = false;
            vm.isCreating = false;
            vm.errorSaving = true;
        };

        vm.save = function() {
            vm.isSaving = true;
            Workspace.update(vm.workspace, vm.onUpdateSaveSuccess, vm.onSaveError);
        };
    }
})();
