(function() {
    'use strict';

    angular
        .module('atheer.content')
        .controller('ContentRatingController', ContentRatingController)

    /* @ngInject */
    function ContentRatingController($scope, $filter, $mdBottomSheet, Experience, Principal, ToastService) {

        $scope.ratingFeedback = {
            object: $scope.data,
            user_id: Principal.getUserId()
        };

        $scope.submitContentRatingForm = function() {
            if ($scope.isPlayMode) {
                Experience.rate($scope.ratingFeedback, function(result) {
                    ToastService.displayToast($filter('triTranslate')('atheer.content.weReceivedYourFeedback'), 3000);
                    $scope.isContentRatingDone = true;
                    $mdBottomSheet.hide();
                    $scope.performEndAction(false, false, false);
                });
            } else {
                $scope.isContentRatingDone = true;
                $mdBottomSheet.hide();
                $scope.performEndAction(false, false, false);
            }
        }

        $scope.notNowAction = function() {
            $scope.isContentRatingDone = false;
            $mdBottomSheet.hide();
            $scope.performEndAction(false, false, false);
        }
    }
})();
