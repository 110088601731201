(function() {
    'use strict';

    angular
        .module('atheer.content')
        .controller('ContentController', ContentController);

    /* @ngInject */
    function ContentController($rootScope, $scope, $state, $mdDialog, Content, Folder, ParseLinks, AlertService, ContentAction, pagingParams, paginationConstants, $mdToast, $stateParams, $filter, CONTENT_META, $translate, Feature, ToastService) {
        var vm = this;

        vm.loadAll = loadAll;
        vm.loadingData = false;
        vm.selectMenu = selectMenu;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.predicate = pagingParams.predicate;
        vm.transition = transition;
        vm.reOrder = reOrder;
        vm.showFolders = true;
        vm.currentSelectedCards = [];
        vm.showBulkAssignAction = false;
        vm.showBulkSendAction = false;
        vm.updateContentListListener = null;
        vm.illustartionEmptyScreenId = "content";
        vm.illustartionEmptyScreenText = "There are no contents yet";

        vm.defaultFolder = {
            id: "default",
            name: "Default",
            description: "Default"
        };

        vm.query = {
            filter: '',
            limit: paginationConstants.itemsPerPage,
            order: '-id',
            page: 1,
            filters: {
                folder: vm.defaultFolder,
                metaType: 'all',
                status: null,
            }
        };

        vm.filter = {
            options: {
                debounce: 500
            }
        };

        vm.removeFilter = removeFilter;
        vm.filter.show = false;
        vm.deleteObject = deleteObject;
        vm.archiveObject = archiveObject;
        vm.editObject = editObject;
        vm.createObject = createObject;
        vm.getQueryString = getQueryString;

        vm.permissions = {
            create: 'create-content',
            update: 'update-content',
            delete: 'delete-content',
            manage: 'manage-content',
            createFolder: 'manage-folder-content',
            updateFolder: 'manage-folder-content',
            deleteFolder: 'manage-folder-content',
            manageFolder: 'manage-folder-content',
            manageTopic: 'manage-topic-content',
            manageBadge: 'manage-badge-system'
        };

        vm.createMenu = true;
        vm.createMenuOptions = $filter('filter')($filter('orderBy')(CONTENT_META, 'display_order'), {
            category: 'main',
            dispay_in_options: true,
            type: '!session-recording'
        });

        //vm.createMenuOptions = $filter('filter')($filter('orderBy')(vm.createMenuOptions, 'display_order'), {
        //    type: '!session-recording'
        //});

        vm.launchContentPreview = function(content) {
            ContentAction.previewContent(content);
        };

        vm.openBulkEditDialog = function() {
            if (vm.currentSelectedCards.length > 0) {
                $mdDialog.show({
                    templateUrl: 'modules/content/content-bulk-edit-dialog.tmpl.html',
                    clickOutsideToClose: true,
                    controller: "ContentBulkEditController",
                    controllerAs: "vm",
                    size: 'lg',
                    locals: {
                        contents: vm.currentSelectedCards
                    }
                }).then(function(result) {
                    vm.currentSelectedCards = [];
                });
            }
        }

        vm.openBulkAssignDialog = function() {
            if (vm.currentSelectedCards.length > 0) {
                var currentSelectedCardsIds = [];
                angular.forEach(vm.currentSelectedCards, function(currentSelectedCard) {
                    currentSelectedCardsIds.push(currentSelectedCard.id)
                })
                ContentAction.openAssignModal(currentSelectedCardsIds, false, false, function() {
                    vm.currentSelectedCards = [];
                });
            }
        }

        vm.openBulkAssignRemoveDialog = function() {
            if (vm.currentSelectedCards.length > 0) {
                var currentSelectedCardsIds = [];
                angular.forEach(vm.currentSelectedCards, function(currentSelectedCard) {
                    currentSelectedCardsIds.push(currentSelectedCard.id)
                })
                ContentAction.openAssignModal(currentSelectedCardsIds, false, true, function() {
                    vm.currentSelectedCards = [];
                });
            }
        }

        vm.getMediaUrl = function(mediaUrl) {
            return $filter('mediaUrl')(mediaUrl);
        }

        vm.getCardProperty = function(type) {
            var contentMetaData = $filter('filter')(CONTENT_META, {
                type: type.replace(/_/g, '-')
            })[0];
            return contentMetaData.card_properties;
        }

        vm.getDefaultBackground = function(type) {
            var card_properties = vm.getCardProperty(type);
            return card_properties != undefined ? card_properties.defaultBackground : '';
        }

        vm.getDefaultIcon = function(type) {
            var card_properties = vm.getCardProperty(type);
            return card_properties != undefined ? card_properties.defaultIcon : '';
        }

        vm.convertTypeForBackend = function(type) {
            return type.replace(/-/g, '_')
        }

        vm.copyContent = function(contentId, contentType) {
            $state.go("storm.admin-content-detail", {
                'contentType': contentType.replace(/-/g, '_'),
                'id': contentId,
                'copy': true
            });
        }

        function loadAll() {
            vm.loadingData = true;
            var savedQuery = getSavedFilters();
            if ( savedQuery ) {
                vm.query = savedQuery;
            }
            vm.page = vm.query.page;
            Content.query({
                page: vm.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort(),
                query: getQueryString(),
                is_web: true,
                is_not_filter: true
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (pagingParams.ascending ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.contents = data;
                //vm.page = pagingParams.page;
                vm.loadingData = false;
            }

            function onError(error) {
                AlertService.error(error.data.message);
                vm.loadingData = false;
            }
        }

        function reOrder(order) {
            vm.predicate = order;
            vm.transition();
        }

        function transition() {
            vm.promise = Content.query({
                page: vm.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: getSortOrder(),
                query: getQueryString(),
                is_web: true,
                is_not_filter: true
            }).$promise;

            vm.promise.then(function(data, headers) {
                vm.queryCount = vm.totalItems;
                vm.contents = data;
            });
            vm.query.page = vm.page;
            saveFilters(vm.query);
        }

        function getSortOrder() {
            if (vm.predicate.startsWith('-')) {
                return vm.predicate.substr(1) + ',' + 'desc';
            } else {
                return vm.predicate + ',' + 'asc';
            }

        }

        function getQueryString() {
            var contentQuery = 'embedded==false;type=out=(SMART_CONTENT,EMAIL_LAYOUT);status!=ARCHIVED;';
            var metaTypeStr = vm.query.filters.metaType == 'all' ? '' : ';' + vm.query.filters.metaType;

            contentQuery = contentQuery + 'meta_type!=INFO_CARD' + metaTypeStr;

            if (vm.query.filters.topicId) {
                contentQuery = contentQuery + ';topics=in=' + vm.query.filters.topicId;
            }

            if (vm.query.filters.disabled) {
                contentQuery = contentQuery + ';enabled==false';
            }

            if (vm.query.filter.length > 0) {
                var qs = vm.query.filter;
                var reEscapedStr = String(qs).replace(/([.*+?=^!:${}()|[\]\/\\])/g, '\\$1');
                var reAndQuoteEscapedStr = String(reEscapedStr).replace(/([\\"])/g, '\\$1');
                var quoteEscapedStr = String(qs).replace(/([\\"])/g, '\\$1');
                contentQuery = contentQuery + ';(title=re="' + reAndQuoteEscapedStr + '",description=re="' + quoteEscapedStr + '",tags=re="' + quoteEscapedStr + '")';
            }
            return contentQuery;
        }

        function removeFilter() {
            vm.filter.show = false;
            vm.query.filter = '';
            resetModal();
        }

        function createObject() {
            $state.go("storm.admin-applet-detail");
        }

        function editObject(contentId, contentType) {
            $state.go("storm.admin-content-detail", {
                'contentType': contentType.replace(/-/g, '_'),
                'id': contentId
            });
        }

        function archiveObject(entity) {
            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.content.archiveObject'))
                .ariaLabel($filter('translate')('atheer.content.archiveObject'))
                .ok($filter('translate')('entity.action.archive'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function() {
                entity.status = 'ARCHIVED';

                Content.update(entity, function() {
                    resetModal();
                });
            });
        };

        function deleteObject(entity) {
            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.content.deleteObject'))
                .ariaLabel($filter('translate')('atheer.content.deleteObject'))
                .ok($filter('translate')('entity.action.delete'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function() {
                Content.delete({
                    id: entity.id
                }, function() {
                    resetModal();
                });
            });
        };

        function resetModal() {
            loadAll();
            AlertService.displayToast($mdToast);
        };

        vm.openAddFolderModal = function(contentObj) {
            $mdDialog.show({
                templateUrl: 'modules/content/content-folder-dialog.tmpl.html',
                clickOutsideToClose: true,
                controller: "ContentFolderController",
                controllerAs: "vm",
                size: 'lg',
                locals: {
                    content: contentObj
                }
            }).then(function() {
                vm.loadAll();
            });
        }

        vm.removeContentFolder = function(contentObj) {
            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.content.removeContentFolder') + vm.selectedFolder.name + '?')
                .ariaLabel($filter('translate')('atheer.content.deleteContentFolder'))
                .ok($filter('translate')('entity.action.remove'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function() {
                Folder.delete({
                    id: folder.id
                }, function() {
                    ToastService.displayToast($filter('triTranslate')('atheer.content.removedSuccessfully'), 3000);
                });
            });
        }

        vm.updateParentObject = function() {
            $mdDialog.hide(vm.currentSelectedCards);
        }

        vm.getCardMeta = function(card) {
            var contentMetaData = $filter('filter')(CONTENT_META, {
                type: card.data.type.replace(/_/g, '-')
            })[0];
            return contentMetaData;
        };

        vm.showCopyAction = function(card) {
            return vm.getCardMeta(card).show_copy_action;
        };

        vm.allowInsights = function(card) {
            var contentMetaData = $filter('filter')(CONTENT_META, {
                type: card.data.type.replace(/_/g, '-')
            })[0];
            return contentMetaData.enable_insights;
        };

        vm.toggleFilter = function() {
            vm.filter.show = !vm.filter.show;
            if (vm.filter.show) {
                vm.showFolders = false;
            }
        };

        vm.clearSelection = function() {
            vm.currentSelectedCards = [];
        };

        vm.seeInsights = function(contentId, contentType) {
            $state.go("storm.admin-content-detail-insights.section", {
                'contentType': contentType,
                'id': contentId,
                'section': 'overview'
            });
        };

        function selectMenu(item) {
            $state.go('storm.admin-content-detail', {
                'contentType': item.type.replace(/-/g, '_')
            });
        }

        function saveFilters(filterData) {
            sessionStorage.setItem('objectFilters', JSON.stringify(filterData));
        };
    
        function getSavedFilters() {
            return JSON.parse(sessionStorage.getItem('objectFilters'));
        };


        $scope.$watchCollection('vm.currentSelectedCards', function(newVal) {
            vm.showBulkAssignAction = false;
            vm.showBulkSendAction = false;

            angular.forEach(newVal, function(content) {
                if (content.data.type == 'email_template') {
                    vm.showBulkSendAction = true;
                } else {
                    vm.showBulkAssignAction = true;
                }
            });

            $scope.$emit('refreshSelectedCardsToolbar', vm.currentSelectedCards);
        });

        var refreshContentListener = $rootScope.$on('refreshContent', function(event, filter) {
            vm.query.filter = filter;
            vm.query.page = 1;
            saveFilters(vm.query);
            vm.loadAll();
        });

        //calls when hit menu on sidebar to open sub menus in side bar
        var updateContentListListener = $rootScope.$on('updateContentList', function(event, filters) {
            vm.illustartionEmptyScreenId = "no_content";
            vm.query.filters = filters;
            vm.query.page = 1;
            saveFilters(vm.query);
            vm.loadAll();
        });

        var refreshSelectedCardsListing = $rootScope.$on('refreshSelectedCardsListing', function(event, currentSelectedCards) {
            vm.currentSelectedCards = currentSelectedCards;
        });

        // Unregister
        $scope.$on('$destroy', function() {
            updateContentListListener();
            refreshContentListener();
            refreshSelectedCardsListing();
        });

        vm.loadAll();
    }
})();
