(function () {
    'use strict';

    angular
        .module('atheer.topic')
        .controller('TopicDetailQRCodeController', TopicDetailQRCodeController)

    /* @ngInject */
    function TopicDetailQRCodeController($scope, $q, $filter, id, Report, Topic, $timeout, $http) {
        var vm = this;

        vm.loadingData = false;
        vm.topicModel = null;
        vm.showActions = false;

        vm.qrCodeLink = '';
        vm.qrCodeImage;

        vm.loadData = function () {
            if (id) {
                vm.loadingData = true;
                Topic.get({
                    id: id
                }, function (result) {
                    vm.topicModel = result;
                    vm.loadingData = false;

                    $timeout(function () {
                        vm.showActions = true;
                    }, 1000);

                    vm.qrCodeLink = '/api/topics/' + vm.topicModel.id + '/qrcode';
                    $http.get(vm.qrCodeLink, { responseType: 'blob' }).then(function (response) {
                        vm.qrCodeImage = URL.createObjectURL(response.data);
                    })
                });
            }
        };

        vm.downloadQR = function () {
            var link = document.createElement('a');
            link.download = 'QR-Code',
                link.href = vm.qrCodeImage;
            link.click();
        };

        vm.printQR = function () {
            var printWin = window.open();
            printWin.document.open();
            printWin.document.write(ImagetoPrint());
            printWin.document.close();
        };

        function ImagetoPrint() {
            return "<html><head><script>function step1(){\n" +
                "setTimeout('step2()', 10);}\n" +
                "function step2(){window.print();window.close()}\n" +
                "</script></head><body onload='step1()'>\n" +
                "<img src='" + vm.qrCodeImage + "' /></body></html>";
        }

        vm.loadData();
    }
})();
