(function () {
	'use strict';

	angular.module('atheer.airform').config(stateConfig);

	/* @ngInject */
	function stateConfig($stateProvider, CONTENT_INSIGHT_META) {
		$stateProvider
			.state('storm.admin-job-templates', {
				url: '/airforms/settings/job-templates',
				templateUrl: 'modules/ngx-frame.html',
				controller: 'AirFormController',
				controllerAs: 'vm',
				data: {
					pageTitle: 'atheer.applet.home.title',
					layout: {
						contentClass: 'layout-column',
						footer: false,
						showSideNavMenu: false,
						sideNavMenuPosition: 'left',
						showToolbar: false,
						sideNavMenuSize: 15
					}
				}
			})
			.state('storm.admin-job-template-detail', {
				url: '/airforms/settings/job-templates/:id',
				templateUrl: 'modules/ngx-frame.html',
				controller: 'AirFormController',
				controllerAs: 'vm',
				data: {
					pageTitle: 'atheer.applet.home.title',
					layout: {
						contentClass: 'layout-column',
						footer: false,
						showSideNavMenu: false,
						sideNavMenuPosition: 'left',
						showToolbar: false,
						sideNavMenuSize: 15
					}
				},
				resolve: {
					id: function ($stateParams) {
						return $stateParams.id;
					}
				}
			})
			.state('storm.admin-data-exports', {
				url: '/airforms/settings/data-exports',
				templateUrl: 'modules/ngx-frame.html',
				controller: 'AirFormController',
				controllerAs: 'vm',
				data: {
					pageTitle: 'atheer.applet.home.title',
					layout: {
						contentClass: 'layout-column',
						footer: false,
						showSideNavMenu: false,
						sideNavMenuPosition: 'left',
						showToolbar: false,
						sideNavMenuSize: 15
					}
				}
			})
			.state('storm.admin-data-export-detail', {
				url: '/airforms/settings/data-exports/:id',
				templateUrl: 'modules/ngx-frame.html',
				controller: 'AirFormController',
				controllerAs: 'vm',
				data: {
					pageTitle: 'atheer.applet.home.title',
					layout: {
						contentClass: 'layout-column',
						footer: false,
						showSideNavMenu: false,
						sideNavMenuPosition: 'left',
						showToolbar: false,
						sideNavMenuSize: 15
					}
				},
				resolve: {
					id: function ($stateParams) {
						return $stateParams.id;
					}
				}
			})
			.state('storm.admin-scheduler-list', {
				url: '/airforms/settings/scheduler-list',
				templateUrl: 'modules/ngx-frame.html',
				controller: 'AirFormController',
				controllerAs: 'vm',
				data: {
					pageTitle: 'atheer.applet.home.title',
					layout: {
						contentClass: 'layout-column',
						footer: false,
						showSideNavMenu: false,
						sideNavMenuPosition: 'left',
						showToolbar: false,
						sideNavMenuSize: 15
					}
				}
			})
			.state('storm.admin-scheduler-detail', {
				url: '/airforms/settings/scheduler/:id',
				templateUrl: 'modules/ngx-frame.html',
				controller: 'AirFormController',
				controllerAs: 'vm',
				data: {
					pageTitle: 'atheer.applet.home.title',
					layout: {
						contentClass: 'layout-column',
						footer: false,
						showSideNavMenu: false,
						sideNavMenuPosition: 'left',
						showToolbar: false,
						sideNavMenuSize: 15
					}
				},
				resolve: {
					id: function ($stateParams) {
						return $stateParams.id;
					}
				}
			})
			.state('storm.admin-airforms-preview', {
				url: '/airforms-preview/:id',
				templateUrl: 'modules/ngx-frame.html',
				controller: 'AirFormController',
				controllerAs: 'vm',
				data: {
					pageTitle: 'atheer.applet.home.title',
					layout: {
						contentClass: 'layout-column',
						footer: false,
						showSideNavMenu: false,
						sideNavMenuPosition: 'left',
						showToolbar: false,
						sideNavMenuSize: 15
					}
				},
				resolve: {
					id: function ($stateParams) {
						return $stateParams.id;
					}
				}
			})
			.state('storm.admin-step-templates', {
				url: '/airforms/settings/step-templates',
				templateUrl: 'modules/ngx-frame.html',
				controller: 'AirFormController',
				controllerAs: 'vm',
				data: {
					pageTitle: 'atheer.applet.home.title',
					layout: {
						contentClass: 'layout-column',
						footer: false,
						showSideNavMenu: false,
						sideNavMenuPosition: 'left',
						showToolbar: false,
						sideNavMenuSize: 15
					}
				}
			})
			.state('storm.admin-step-template-detail', {
				url: '/airforms/settings/step-templates/:id',
				templateUrl: 'modules/ngx-frame.html',
				controller: 'AirFormController',
				controllerAs: 'vm',
				data: {
					pageTitle: 'atheer.applet.home.title',
					layout: {
						contentClass: 'layout-column',
						footer: false,
						showSideNavMenu: false,
						sideNavMenuPosition: 'left',
						showToolbar: false,
						sideNavMenuSize: 15
					}
				},
				resolve: {
					id: function ($stateParams) {
						return $stateParams.id;
					}
				}
			})
			.state('storm.admin-datasets', {
				url: '/airforms/settings/datasets',
				templateUrl: 'modules/ngx-frame.html',
				controller: 'AirFormController',
				controllerAs: 'vm',
				data: {
					pageTitle: 'atheer.applet.home.title',
					layout: {
						contentClass: 'layout-column',
						footer: false,
						showSideNavMenu: false,
						sideNavMenuPosition: 'left',
						showToolbar: false,
						sideNavMenuSize: 15
					}
				}
			})
			.state('storm.admin-dataset-detail', {
				url: '/airforms/settings/datasets/:id',
				templateUrl: 'modules/ngx-frame.html',
				controller: 'AirFormController',
				controllerAs: 'vm',
				data: {
					pageTitle: 'atheer.applet.home.title',
					layout: {
						contentClass: 'layout-column',
						footer: false,
						showSideNavMenu: false,
						sideNavMenuPosition: 'left',
						showToolbar: false,
						sideNavMenuSize: 15
					}
				},
				resolve: {
					id: function ($stateParams) {
						return $stateParams.id;
					}
				}
			})
			.state('storm.admin-categories', {
				url: '/airforms/settings/categories',
				templateUrl: 'modules/ngx-frame.html',
				controller: 'AirFormController',
				controllerAs: 'vm',
				data: {
					pageTitle: 'atheer.applet.home.title',
					layout: {
						contentClass: 'layout-column',
						footer: false,
						showSideNavMenu: false,
						sideNavMenuPosition: 'left',
						showToolbar: false,
						sideNavMenuSize: 15
					}
				}
			})
			.state('storm.admin-notification-templates', {
				url: '/airforms/settings/notification-templates',
				templateUrl: 'modules/ngx-frame.html',
				controller: 'AirFormController',
				controllerAs: 'vm',
				data: {
					pageTitle: 'atheer.applet.home.title',
					layout: {
						contentClass: 'layout-column',
						footer: false,
						showSideNavMenu: false,
						sideNavMenuPosition: 'left',
						showToolbar: false,
						sideNavMenuSize: 15
					}
				}
			})
			.state('storm.admin-airform-review-flows', {
				url: '/airforms/settings/reviewflows',
				templateUrl: 'modules/ngx-frame.html',
				controller: 'AirFormController',
				controllerAs: 'vm',
				data: {
					pageTitle: 'atheer.applet.home.title',
					layout: {
						contentClass: 'layout-column',
						footer: false,
						showSideNavMenu: false,
						sideNavMenuPosition: 'left',
						showToolbar: false,
						sideNavMenuSize: 15
					}
				}
			})
			.state('storm.admin-airform-review-flow', {
				url: '/airforms/settings/reviewflow',
				templateUrl: 'modules/ngx-frame.html',
				controller: 'AirFormController',
				controllerAs: 'vm',
				data: {
					pageTitle: 'atheer.applet.home.title',
					layout: {
						contentClass: 'layout-column',
						footer: false,
						showSideNavMenu: false,
						sideNavMenuPosition: 'left',
						showToolbar: false,
						sideNavMenuSize: 15
					}
				}
			})
			.state('storm.admin-airform-review-flow-detail', {
				url: '/airforms/settings/reviewflow/:id',
				templateUrl: 'modules/ngx-frame.html',
				controller: 'AirFormController',
				controllerAs: 'vm',
				data: {
					pageTitle: 'atheer.applet.home.title',
					layout: {
						contentClass: 'layout-column',
						footer: false,
						showSideNavMenu: false,
						sideNavMenuPosition: 'left',
						showToolbar: false,
						sideNavMenuSize: 15
					}
				}
			})
			.state('storm.admin-airforms', {
				url: '/airforms',
				templateUrl: 'modules/ngx-frame.html',
				controller: 'AirFormController',
				controllerAs: 'vm',
				data: {
					pageTitle: 'atheer.applet.home.title',
					layout: {
						contentClass: 'layout-column',
						footer: false,
						showSideNavMenu: false,
						sideNavMenuPosition: 'left',
						showToolbar: false,
						sideNavMenuSize: 15
					}
				}
			})
			.state('storm.admin-airform-detail', {
				url: '/airforms/:id',
				templateUrl: 'modules/ngx-frame.html',
				controller: 'AirFormController',
				controllerAs: 'vm',
				data: {
					pageTitle: 'atheer.applet.home.title',
					layout: {
						contentClass: 'layout-column',
						footer: false,
						showSideNavMenu: false,
						sideNavMenuPosition: 'left',
						showToolbar: false,
						sideNavMenuSize: 15
					}
				},
				resolve: {
					id: function ($stateParams) {
						return $stateParams.id;
					}
				}
			})
			.state('storm.admin-airform-dashboard', {
				url: '/airforms/insights/:id',
				templateUrl: 'modules/ngx-frame.html',
				controller: 'AirFormController',
				controllerAs: 'vm',
				data: {
					pageTitle: 'atheer.applet.home.title',
					layout: {
						contentClass: 'layout-column',
						footer: false,
						showSideNavMenu: false,
						sideNavMenuPosition: 'left',
						showToolbar: false,
						sideNavMenuSize: 15
					}
				},
				resolve: {
					id: function ($stateParams) {
						return $stateParams.id;
					}
				}
			})
	}
})();
