(function() {
    'use strict';

    angular
        .module('atheer.smartFlow')
        .controller('SmartFlowBuilderInsightsUserActivityController', SmartFlowBuilderInsightsUserActivityController);

    /* @ngInject */
    function SmartFlowBuilderInsightsUserActivityController($scope, $q, $filter, smartFlow, $state, Report, SmartFlowBuilder, $mdDialog, userSmartFlow, WORFLOW_CELL_CONSTANT) {
        var vm = this;
        vm.viewType = 'insights';
        vm.loadingData = false;

        vm.smartFlowModel = smartFlow;
        vm.userSmartFlow = userSmartFlow;

        vm.activitySummary = null;

        vm.close = function() {
            $mdDialog.cancel();
        };

        vm.loadData = function() {
            vm.loadingData = true;

            $q.all([
                Report.getSmartFlowUserActivitySummary({
                    id: userSmartFlow.smart_flow_instance_id
                }).$promise
            ]).then(function(data) {
                vm.activitySummary = data[0];
                vm.loadingData = false;

                if (vm.smartFlowModel.canvas_data != null && vm.smartFlowModel.canvas_data.length > 0) {
                    var graph = new joint.dia.Graph;
                    graph.fromJSON(JSON.parse(vm.smartFlowModel.canvas_data));

                    _.each(vm.activitySummary, function(activitySummary) {
                        var cell = graph.getCell(activitySummary.id);
                        if (cell) {
                            var title = cell.attr('text/text');
                            activitySummary.title = title;
                            if(WORFLOW_CELL_CONSTANT[activitySummary.name]) {
                                  activitySummary.type_label = WORFLOW_CELL_CONSTANT[activitySummary.name].label;
                            }
                        }
                    });
                }
            }).catch(function(data) {}).finally(function() {});
        }
        vm.loadData();
    }
})();
