(function() {
    'use strict';

    angular
        .module('atheer')
        .directive('fpRating', fpRating);

    function fpRating() {
        var directive = {
            scope: {
                rating: '=',
                maxRating: '@',
                readOnly: '@',
                click: "&",
                mouseHover: "&",
                mouseLeave: "&",
                shapeType: '@',
                color: '=',
                hoverTextArray: '='
            },
            controller: ratingController,
            restrict: 'EA',
            templateUrl: "core/components/rating/rating-directive.tmpl.html",
            compile: function(element, attrs) {
                if (!attrs.maxRating || (Number(attrs.maxRating) <= 0)) {
                    attrs.maxRating = '5';
                };
            }
        };
        return directive;
    }

    /* @ngInject */
    function ratingController($scope, $timeout, $mdToast, CloudUpload) {
        $scope.maxRatings = [];
        $scope.hoverValue = -1;

        if ($scope.rating) {
            $scope._rating = $scope.rating;
            $scope.hoverValue = 0;
        }

        for (var i = 1; i <= $scope.maxRating; i++) {
            $scope.maxRatings.push(i);
        };

        //$scope._rating = $scope.rating;
        $scope.ratingShape = {
            emptyIconClass: '',
            fullIconClass: ''
        };

        $scope.$watch('shapeType', function(newValue) {
            if (angular.lowercase(newValue) == 'heart') {
                $scope.ratingShape.emptyIconClass = "fa fa-heart-o";
                $scope.ratingShape.fullIconClass = "fa fa-heart";
            } else if (angular.lowercase(newValue) == 'circle') {
                $scope.ratingShape.emptyIconClass = "fa fa-circle-o";
                $scope.ratingShape.fullIconClass = "fa fa-circle";
            } else if (angular.lowercase(newValue) == 'star') {
                $scope.ratingShape.emptyIconClass = "fa fa-star-o";
                $scope.ratingShape.fullIconClass = "fa fa-star";
            } else if (angular.lowercase(newValue) == 'flag') {
                $scope.ratingShape.emptyIconClass = "fa fa-flag-o";
                $scope.ratingShape.fullIconClass = "fa fa-flag";
            }
        });

        $scope.$watch('maxRating', function(newValue, oldValue) {
            if (oldValue == newValue) return;
            $scope.maxRatings = [];
            for (var i = 1; i <= parseInt(newValue); i++) {
                $scope.maxRatings.push({});
            };
        });

        $scope.isolatedClick = function(param) {
            if ($scope.readOnly == 'true') return;
            $scope.rating = $scope._rating = param;
            $scope.hoverValue = 0;
            $scope.click({
                param: param
            });
        };

        $scope.isolatedMouseHover = function(param) {
            if ($scope.readOnly == 'true') return;
            $scope._rating = 0;
            $scope.hoverValue = param;
            $scope.mouseHover({
                param: param
            });
        };

        $scope.isolatedMouseLeave = function(param) {
            if ($scope.readOnly == 'true') return;
            $scope._rating = $scope.rating;
            $scope.hoverValue = 0;
            $scope.mouseLeave({
                param: param
            });
        };
    }
})();
