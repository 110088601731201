(function() {
    'use strict';

    angular
        .module('atheer.team')
        .controller('TeamDetailSaveDialogController', TeamDetailSaveDialogController);

    /* @ngInject */
    function TeamDetailSaveDialogController($scope, $state, $q, $filter, $window, $http, $location, Report, Team, poller, ParseLinks, AlertService, $mdDialog, $mdToast, $stateParams, team) {
        var vm = this;

        vm.loadingData = false;
        vm.teamModel = team;
        vm.jobStatus = 'idle';
        vm.jobResult = null;

        vm.close = function() {
            $mdDialog.cancel();
        };

        vm.saveClose = function() {
            $mdDialog.hide();
        };

        vm.saveTeam = function() {
            Team.update(vm.teamModel, onSaveSuccess);

            function onSaveSuccess(result) {
                Team.syncUsers({
                    id: vm.teamModel.id,
                }, onSyncSuccess, onSyncError);
            }

            function onSyncSuccess(result) {
                vm.jobResult = result;
                vm.getImportJobStatus();
            };

            function onSyncError() {
                AlertService.displayToast($mdToast);
            };
        }

        vm.getImportJobStatus = function() {
            // Get poller.
            var jobStreamStatusPoller = poller.get(Team, {
                action: 'getSyncUsersStatus',
                delay: 3000,
                argumentsArray: [{
                    id: vm.jobResult.id
                }],
                smart: true
            });

            jobStreamStatusPoller.promise.then(null, null, function(result) {
                vm.jobStream = result;
                vm.jobStreamId = vm.jobResult.id;
                if (result.status == 'COMPLETED') {
                    poller.stopAll();
                    vm.jobStatus = 'complete';
                }
            });
        };
    }
})();
