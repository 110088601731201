(function() {
    'use strict';

    angular
        .module('atheer.user')
        .controller('UserMetaDataController', UserMetaDataController)

    /* @ngInject */
    function UserMetaDataController($state, $scope, $window, $mdSidenav, MetaData, AlertService, $mdDialog, $filter, id, ToastService) {
        var vm = this;

        vm.loadAll = loadAll;
        vm.updateObject = false;
        vm.metaDataObject;
        vm.selectedFieldGroup;

        vm.loadAll();

        function loadAll() {
            MetaData.get({
                id: id,
                is_name: true,
                add_default_properties: true
            }, function(result) {
                vm.metaDataObject = result;
                vm.updateObject = false;
                if (vm.metaDataObject.field_groups && vm.metaDataObject.field_groups.length > 0) {
                    vm.selectedFieldGroup = vm.metaDataObject.field_groups[0];
                }
            });
        }

        vm.selectFieldGroup = function(fieldGroup, index) {
            vm.updateObject = false;
            vm.selectedFieldGroup = fieldGroup;
        }

        vm.addFieldGroup = function(fieldGroup) {
            $mdDialog.show({
                templateUrl: 'modules/system/metadata/field-group-dialog.tmpl.html',
                clickOutsideToClose: true,
                controller: 'FieldGroupDialogController',
                controllerAs: "vm",
                size: 'lg',
                locals: {
                    fieldGroup: {
                        name: null,
                        label: null,
                        description: null,
                        is_many: true,
                        fields: null,
                    },
                    isAdd: true
                }
            }).then(function(fieldGroup) {
                if (!vm.metaDataObject.field_groups) {
                    vm.metaDataObject.field_groups = [];
                }
                vm.metaDataObject.field_groups.push(fieldGroup);
                vm.updateObject = false;
                vm.selectedFieldGroup = fieldGroup;
                updateMetaData('atheer.userFields.fieldGroupAdded');
            });
        }

        vm.editFieldGroup = function(fieldGroup, index) {
            $mdDialog.show({
                templateUrl: 'modules/system/metadata/field-group-dialog.tmpl.html',
                clickOutsideToClose: true,
                controller: 'FieldGroupDialogController',
                controllerAs: "vm",
                size: 'lg',
                locals: {
                    fieldGroup: angular.copy(fieldGroup),
                    isAdd: false
                }
            }).then(function(fieldGroup) {
                vm.metaDataObject.field_groups[index] = fieldGroup;
                updateMetaData('atheer.userFields.fieldGroupUpdated');
            });
        }

        vm.deleteFieldGroup = function(fieldGroup, index) {
            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.userFields.deleteFieldGroup'))
                .ariaLabel($filter('translate')('atheer.userFields.deleteFieldGroup'))
                .ok($filter('translate')('entity.action.delete'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function() {
                vm.metaDataObject.field_groups.splice(index, 1);
                updateMetaData('atheer.userFields.fieldGroupDeleted');
                vm.selectedFieldGroup = vm.metaDataObject.field_groups[index == 0 ? 0 : index - 1];
            });
        }

        vm.addField = function() {
            $mdDialog.show({
                templateUrl: 'modules/system/metadata/field-dialog.tmpl.html',
                clickOutsideToClose: true,
                controller: 'FieldDialogController',
                controllerAs: "vm",
                size: 'lg',
                locals: {
                    field: {
                        name: null,
                        description: null,
                        label: null,
                        data_type: 'STRING',
                        type: 'FLEX',
                        required: false,
                        enabled: true,
                        search_enabled: true
                    },
                    isAdd: true
                }
            }).then(function(field) {
                vm.updateObject = false;
                if (!vm.selectedFieldGroup.fields) {
                    vm.selectedFieldGroup.fields = [];
                };
                vm.selectedFieldGroup.fields.push(field);
                updateMetaData('atheer.userFields.fieldAdded');
            });
        }

        vm.editField = function(field, index) {
            $mdDialog.show({
                templateUrl: 'modules/system/metadata/field-dialog.tmpl.html',
                clickOutsideToClose: true,
                controller: 'FieldDialogController',
                controllerAs: "vm",
                size: 'lg',
                locals: {
                    field: angular.copy(field),
                    isAdd: false
                }
            }).then(function(field) {
                vm.updateObject = false;
                vm.selectedFieldGroup.fields[index] = field;
                updateMetaData('atheer.userFields.fieldUpdated');
            });
        }

        vm.copyField = function(field, index) {
            var newField = angular.copy(field);
            newField.name = "Copy of " + newField.name;
            vm.updateObject = false;
            vm.selectedFieldGroup.fields.push(newField);
            updateMetaData('atheer.userFields.fieldCopied');
        }

        vm.deleteField = function(field, index) {
            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.userFields.deleteFieldGroup'))
                .ariaLabel($filter('translate')('atheer.userFields.deleteFieldGroup'))
                .ok($filter('translate')('entity.action.delete'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function() {
                vm.updateObject = false;
                vm.selectedFieldGroup.fields.splice(index, 1);
                updateMetaData('atheer.userFields.fieldDeleted');
            });
        }

        vm.back = function() {
            $window.history.back();
        };

        function updateMetaData(successMessage) {
            MetaData.update({
                id: id
            }, vm.metaDataObject, onSaveSuccess);

            function onSaveSuccess(result) {
                vm.metaDataObject = result;
                ToastService.displayToast($filter('triTranslate')(successMessage), 3000);
                var fGroup = vm.metaDataObject.field_groups.filter(function(item) {
                    return item.name === vm.selectedFieldGroup.name;
                  })[0];
                vm.selectedFieldGroup = fGroup;
            };
        }
    }
})();
