(function() {
    'use strict';

    angular
        .module('atheer')
        .config(ariaConfig);

      /* @ngInject */
    function ariaConfig($mdAriaProvider) {
        // Globally disables all ARIA warnings.
        $mdAriaProvider.disableWarnings();
    }
})();
