(function() {
    'use strict';

    angular
        .module('atheer.sdk')
        .controller('SdkDataExportController', SdkDataExportController);

    /* @ngInject */
    function SdkDataExportController($rootScope, $state, $scope, $sce, $window, $location, $cookies, $q, Setting, $mdToast, $filter, $mdDialog, Feature) {
        var vm = this;
        vm.ngxUrlTrusted = null;
        vm.webHooksEnabled = Feature.isActive('feature_airsdk_webhooks');
        vm.showToolbar = true;
        vm.viewType = "doc";

        function setNgxPageUrl() {
            var path = $location.path();
            var hostUrl =
                location.hostname === "localhost" ?
                "https://" + location.hostname + ":4200" :
                location.origin;
            var ngxUrl = hostUrl + '/ngx/config/template/dataexport/?sdkView=true';

            vm.ngxUrlTrusted = $sce.trustAsResourceUrl(ngxUrl);
        };

        function handleNgxEvent(event) {
            if (event.data.ngxLocationChanged) {
                var ngState = event.data.ngxLocationChanged.ngState;
                var id = event.data.ngxLocationChanged.id;

                if (id == null) {
                    $state.transitionTo(ngState, null, {
                        location: true,
                        inherit: true,
                        relative: $state.$current,
                        notify: false
                    })
                    vm.showToolbar = true;
                } else {
                    $state.transitionTo(ngState, {
                        id: id
                    }, {
                        location: true,
                        inherit: true,
                        relative: $state.$current,
                        notify: false
                    })
                    vm.showToolbar = false;
                }
            }
        };

        $window.addEventListener('message', handleNgxEvent, false);

        // Unregister
        $scope.$on('$destroy', function() {
            window.removeEventListener('message', handleNgxEvent, false);
        });

        vm.refreshAll = function() {
            var iFrame = document.getElementById('ngxFrame').contentWindow;
            var targetOrigin = location.hostname === "localhost"
                ? "https://" + location.hostname + ":4200"
                : location.origin;
            iFrame.postMessage({ 'refreshDataExportList': true}, targetOrigin);
        };

        vm.createObject = function() {
            var iFrame = document.getElementById('ngxFrame').contentWindow;
            var targetOrigin = location.hostname === "localhost"
                ? "https://" + location.hostname + ":4200"
                : location.origin;
            iFrame.postMessage({ 'createNewDataExport': true}, targetOrigin);
        };

        setNgxPageUrl();
    }
})();
