(function() {
    'use strict';

    angular
        .module('atheer.services')
        .factory('Insight', Insight);
        
    /* @ngInject */
    function Insight($resource, $http, Workspace, Setting, Dashboard) {

        const advInsights = {
            provisioned: false,
            enabled: false,
            state: 'bingo',
            dataSyncTimestamp: null,
            apiKey: '',
            dashboards: []
        };

        function onSuccess(data, headers) {
			if(data && data.length > 0) {
                advInsights = data;
			}
		}

		function onError(error) {
			AlertService.error(error.data.message);
		}

        function onInsightSyncSuccess(data, headers) {
        }

        return {
            getInsightSettings: function () {
                Workspace.getInsightSettings().$promise.then(onSuccess, onError);
            },

            setInsightSettings: function () {
                Setting.setInsightSettings(advInsights).$promise.then(onSuccess, onError);
            },
            syncAllInsights: function() {
                Dashboard.syncAllInsights().$promise.then(onInsightSyncSuccess, onError);
            }
        }
    }
})();
