(function() {
    'use strict';

    angular
        .module('atheer.system')
        .controller('AuthenticationStudioSetupController', AuthenticationStudioSetupController);

    /* @ngInject */
    function AuthenticationStudioSetupController($scope, $state, $q, Setting, $filter, $mdDialog, $window, ToastService, PermissionService, Feature) {
        var vm = this;

        vm.loadingData = false;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.loginSettings = [];
        vm.enableAtheerLogin = true;
        vm.enableForgotPassword = false;
        vm.googleSSO = false;
        vm.twitterSSO = false;
        vm.linkedInSSO = false;
        vm.facebookSSO = false;
        vm.samlSSO = false;
        vm.azureAdSSO = false;
        vm.oidcSSO = false;
        vm.autoSignoutEnabled = false;
        vm.autoSignoutWaitMins = 30;
        vm.autoSignoutWarning = false;
        vm.hasPermission = PermissionService.hasAccess('manage-sso-system');
        vm.isFeatureActive = Feature.isActive('feature_system_sso');

        vm.loadData = function() {
            vm.loadingData = true;

            $q.all([
                    Setting.getAuthenticationSetupSettings().$promise
                ])
                .then(function(results) {
                    setLoginFields(results[0]);
                    vm.loadingData = false;
                });
        };

        function setLoginFields(settings) {
            angular.forEach(settings, function(setting) {
                if (setting.category == 'authentication-setup') {
                    vm.loginSettings = setting;
                    angular.forEach(setting.fields, function(settingField) {
                        if (settingField.name == 'google_sso') {
                            vm.googleSSO = (settingField.value == 'true' ? true : false);
                        } else if (settingField.name == 'facebook_sso') {
                            vm.facebookSSO = (settingField.value == 'true' ? true : false);
                        } else if (settingField.name == 'linkedin_sso') {
                            vm.linkedInSSO = (settingField.value == 'true' ? true : false);
                        } else if (settingField.name == 'twitter_sso') {
                            vm.twitterSSO = (settingField.value == 'true' ? true : false);
                        } else if (settingField.name == 'saml_sso') {
                            vm.samlSSO = (settingField.value == 'true' ? true : false);
                        } else if (settingField.name == 'azureAD_sso') {
                            vm.azureAdSSO = (settingField.value == 'true' ? true : false);
                        } else if (settingField.name == 'studio_oidc_sso') {
                            vm.oidcSSO = (settingField.value == 'true' ? true : false);
                        } else if (settingField.name == 'forgot_password') {
                            vm.enableForgotPassword = (settingField.value == 'true' ? true : false);
                        } else if (settingField.name == 'atheer_login') {
                            vm.enableAtheerLogin = (settingField.value == 'true' ? true : false);
                        } else if (settingField.name == 'auto_signout_enabled') {
                            vm.autoSignoutEnabled = (settingField.value == 'true' ? true : false);
                        } else if (settingField.name == 'auto_signout_wait_time_mins') {
                            vm.autoSignoutWaitMins = parseInt(settingField.value);
                        } else if (settingField.name == 'auto_signout_show_warning') {
                            vm.autoSignoutWarning = (settingField.value == 'true' ? true : false);
                        }
                    });
                }
            });
        }

        function setLoginSettings() {
            if(vm.loginSettings.fields == undefined) {
                vm.loginSettings.fields = [];
            };

            setSettingField("atheer_login", (vm.enableAtheerLogin ? 'true' : 'false'));
            setSettingField("forgot_password", (vm.enableForgotPassword ? 'true' : 'false'));
            setSettingField("google_sso", (vm.googleSSO ? 'true' : 'false'));
            setSettingField("facebook_sso", (vm.facebookSSO ? 'true' : 'false'));
            setSettingField("linkedin_sso", (vm.linkedInSSO ? 'true' : 'false'));
            setSettingField("twitter_sso", (vm.twitterSSO ? 'true' : 'false'));
            setSettingField("saml_sso", (vm.samlSSO ? 'true' : 'false'));
            setSettingField("azureAD_sso", (vm.azureAdSSO ? 'true' : 'false'));
            setSettingField("studio_oidc_sso", (vm.oidcSSO ? 'true' : 'false'));
            setSettingField("auto_signout_enabled", (vm.autoSignoutEnabled ? 'true' : 'false'));
            setSettingField("auto_signout_wait_time_mins", vm.autoSignoutWaitMins + '');
            setSettingField("auto_signout_show_warning", (vm.autoSignoutWarning ? 'true' : 'false'));
        };

        function setSettingField(fieldName, fieldValue) {
            var foundField = false;

            angular.forEach(vm.loginSettings.fields, function(settingField) {
                if (settingField.name == fieldName) {
                    settingField.value = fieldValue;
                    foundField = true;
                }
            });

            if (!foundField) {
                var settingField = {
                    name: fieldName,
                    value: fieldValue,
                }
                vm.loginSettings.fields.push(settingField);
            }
        };

        vm.save = function() {
            setLoginSettings();

            Setting.update(vm.loginSettings, function() {
                ToastService.displayToastWithAction($filter('triTranslate')("atheer.setting.success"),
                    $filter('translate')('entity.action.refresh'), 'md-warn', '', function(res) {
                        if (res == 'ok') {
                            $window.location.reload();
                        }
                    });
            });
        };
        vm.loadData();
    }
})();
