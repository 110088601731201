(function() {
    'use strict';

    angular
        .module('atheer.content')
        .directive('fpContentMessageFeed', fpContentMessageFeed);

    function fpContentMessageFeed() {
        var directive = {
            restrict: 'E',
            scope: {
                'contents': '=',
                'embedMode': '@?',
                'enableAddToBoard': '@?',
                'enableLike': '@?',
                'enableRecommend': '@?',
                'enableFlagInappropiate': '@?',
                'enableReportProblem': '@?',
                'enableRemove': '@?',
                'removeCallback': '&?',
                'loadMoreEnabled': '@?',
                'fromSeries': '@?',
                'seriesEnrolled': '=?'
            },
            transclude: true,
            controller: contentMessageDirectiveController,
            templateUrl: 'modules/content/content-message.tmpl.html'
        };
        return directive;
    }

    /* @ngInject */
    function contentMessageDirectiveController($rootScope, $scope, $state, CONTENT_META, $q, Principal, Experience, $filter, ContentAction, Feature, $mdDialog, Launcher) {

        $scope.currentContentLikeData = {};
        $scope.enableAddToBoard = $scope.enableAddToBoard == 'true';
        $scope.enableLike = $scope.enableLike == 'true';
        $scope.enableRecommend = $scope.enableRecommend == 'true';
        $scope.enableFlagInappropiate = $scope.enableFlagInappropiate == 'true';
        $scope.enableReportProblem = $scope.enableReportProblem == 'true';
        $scope.embedMode = $scope.embedMode == 'true';
        $scope.loadMoreEnabled = $scope.loadMoreEnabled == 'true';
        $scope.fromSeries = $scope.fromSeries == 'true';

        $scope.limitToIndex = $scope.loadMoreEnabled && $scope.contents.length > 5 ? 5 : $scope.contents.length;

        $scope.loadMore = function(type) {
            var diff = $scope.contents.length - $scope.limitToIndex;
            $scope.limitToIndex += diff > 5 ? 5 : diff;
        };

        function init() {
            angular.forEach($scope.contents, function(content) {
                $q.all([
                    Experience.getLikes({
                        query: "object_id==" + content.id + ";user_id==" + Principal.getUserId()
                    }).$promise
                ]).then(function(result) {
                    if (result[0].length > 0) {
                        $scope.currentContentLikeData[content.id] = result[0][0];
                    }
                });
            })
        };

        $scope.isActive = function(feature) {
            return Feature.isActive(feature);
        };

        $scope.openBoardDialog = function(content) {
            ContentAction.openBoardDialog(content);
        };

        $scope.getMediaUrl = function(mediaUrl) {
            return $filter('mediaUrl')(mediaUrl);
        };

        $scope.getCardProperty = function(type) {
            var contentMetaData = $filter('filter')(CONTENT_META, {
                type: type.replace(/_/g, '-')
            })[0];
            return contentMetaData.card_properties;
        };

        $scope.getDefaultBackground = function(type) {
            var card_properties = $scope.getCardProperty(type);
            return card_properties != undefined ? card_properties.defaultBackground : '';
        };

        $scope.getDefaultIcon = function(type) {
            var contentMetaData = $filter('filter')(CONTENT_META, {
                type: type.replace(/_/g, '-')
            })[0];
            return contentMetaData != undefined ? contentMetaData.icon : '';
        };

        $scope.launchContent = function(content) {
            if ($scope.fromSeries && $scope.seriesEnrolled) {
                Launcher.getAssignment({
                    id: content.id
                }, function(result) {
                    ContentAction.launchContent(content);
                });
            } else if ($scope.fromSeries && !$scope.seriesEnrolled) {
                ContentAction.previewContent(content);
            } else {
                ContentAction.launchContent(content);
            };
        };

        $scope.openContentDetail = function(content) {
            $state.go('storm.content-details', {
                'id': content.id
            });
        };

        $scope.openRecommendDialog = function(content) {
            var contents = [];
            contents.push(content.id);
            ContentAction.openRecommendDialog(content);
        };

        $scope.isCompleted = function(contentId) {
            if ($rootScope.userActivities.completion_ids.indexOf(contentId) >= 0) {
                return true;
            } else {
                return false;
            }
        };

        $scope.openFeedbackDialog = function(reactionType, content) {
            ContentAction.openFeedbackDialog(reactionType, content);
        };
        init();
    }
})();
