(function() {
    'use strict';

    angular
        .module('atheer.system')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('storm.admin-setting.security-roles', {
                url: '/security-roles?page&sort&search',
                templateUrl: 'modules/system/security-role/security-roles.html',
                controller: 'SecurityRoleController',
                controllerAs: 'vm',
                data: {
                    pageTitle: 'atheer.securityRole.home.title',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: false
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }]
                }
            })
            .state('storm.admin-setting.security-role-detail', {
                url: '/security-roles/:id',
                templateUrl: 'modules/system/security-role/security-role-detail.tmpl.html',
                controller: 'SecurityRoleDetailController as vm',
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    id: function($stateParams) {
                        return $stateParams.id;
                    }
                },
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false
                    }
                }
            });
    }
})();
