(function() {
    'use strict';
    angular
        .module('atheer.services')
        .factory('Topic', Topic);

    /* @ngInject */
    function Topic($resource) {
        var resourceUrl = 'api/topics/:id';

        return $resource(resourceUrl, {
            id: '@id'
        }, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET'
            },
            'save': {
                method: 'POST'
            },
            'update': {
                method: 'PUT'
            },
            'bulkUpdate': {
                url: 'api/topics/bulk',
                method: 'PUT'
            },
            'assignContents': {
                url: 'api/topics/:id/contents/assign',
                method: 'POST'
            },
            'removeContents': {
                url: 'api/topics/:id/contents/remove',
                method: 'POST'
            },
            'getContents': {
                method: 'GET',
                url: resourceUrl + '/contents?isWeb=true',
                isArray: true,
                transformResponse: function(result) {
                    var resultData = angular.fromJson(result);
                    var returnArray = [];
                    angular.forEach(resultData, function(contentData) {
                        returnArray.push(contentData.content);
                    });
                    return returnArray;
                }
            },
            'getSubTopics': {
                method: 'GET',
                url: resourceUrl + '/sub-topics?isWeb=true',
                isArray: true
            }
        });
    }
})();
