(function() {

    'use strict';

    angular
        .module('atheer')
        .directive('fpPasswordStrength', fpPasswordStrength);

    function fpPasswordStrength() {
        var directive = {
            templateUrl: 'core/components/password/password-strength.tmpl.html',
            restrict: 'E',
            scope: {
                pwd: '=fpPasswordValue',
                value: '=strength'
            },
            link: function(scope /*, elem, attrs*/ ) {
                var
                    measureStrength = function(p) {
                        if (!p) return 0;

                        var strength = 0,
                            lower = p.match(/[a-z]/g),
                            upper = p.match(/[A-Z]/g),
                            numbers = p.match(/\d/g),
                            symbols = p.match(/[@#$-/:-?{-~!^_`\[\]]/g);
                        if (lower || p.length >= 8) {
                            strength += 25;
                        }
                        if (upper) {
                            strength += 25;
                        }
                        if (numbers) {
                            strength += 25;
                        }
                        if (symbols) {
                            strength += 25;
                        }

                        return strength;
                    },
                    getClass = function(s) {
                        switch (Math.round(s / 25)) {
                            case 0:
                                {
                                    return {
                                        value: 0,
                                        class: ''
                                    };
                                }
                            case 1:
                                {
                                    return {
                                        value: 1,
                                        class: 'weak'
                                    };
                                }
                            case 2:
                                {
                                    return {
                                        value: 2,
                                        class: 'so-so'
                                    };
                                }
                            case 3:
                                {
                                    return {
                                        value: 3,
                                        class: 'good'
                                    };
                                }
                            case 4:
                                {
                                    return {
                                        value: 4,
                                        class: 'strong'
                                    };
                                }
                        }
                    };

                scope.$watch('pwd', function() {
                    scope.value = measureStrength(scope.pwd);
                    scope.strengthObject = getClass(scope.value);
                });
            },
        };
        return directive;
    }
})();
