(function() {
    'use strict';

    angular
        .module('atheer.workspace')
        .controller('WorkspaceSetupUserInfoController', WorkspaceSetupUserInfoController);

    /* @ngInject */
    function WorkspaceSetupUserInfoController($translate, $timeout, WorkspaceSignup, $scope, $state, $stateParams, $mdToast, $http, $filter, triSettings) {
        var vm = this;

        vm.error = null;
        vm.success = false;
        vm.triSettings = triSettings;

        vm.workspaceSignupData = $stateParams.workspaceSignupData;

        vm.user = {
            first_name: null,
            last_name: null,
            password: null
        }

        if (!vm.workspaceSignupData) {
            $state.go('workspace.signup');
        }

        vm.next = function() {
            vm.workspaceSignupData.first_name = vm.user.first_name;
            vm.workspaceSignupData.last_name = vm.user.last_name;
            vm.workspaceSignupData.password = vm.user.password;

            $state.go('workspace.setup-company', {"workspaceSignupData": vm.workspaceSignupData});
        };
    }
})();
