(function() {
    'use strict';

    angular
        .module('atheer.system')
        .controller('UdacityAppDetailController', UdacityAppDetailController)

    /* @ngInject */
    function UdacityAppDetailController($mdDialog, $scope, $controller, appName) {
        

        var basectrl = $controller('AppDetailController as vm', {
            $scope: $scope,
            appName: appName
        });

        angular.extend(this, basectrl);

        var vm = this;
    }
})();
