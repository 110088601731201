(function() {
    'use strict';

    angular
        .module('atheer.content')
        .controller('ImageDetailController', ImageDetailController)
        .controller('ImageViewController', ImageViewController)
        .controller('ImageFeedbackController', ImageFeedbackController)

    /* @ngInject */
    function ImageFeedbackController($scope, $mdBottomSheet) {
    }

    /* @ngInject */
    function ImageViewController($scope, $controller, $mdBottomSheet) {

        var basectrl = $controller('BaseContentViewController as vm', {
            $scope: $scope
        });
        angular.extend(this, basectrl);

        $scope.showFeedback = function(firstTime) {

            $mdBottomSheet.show({
                templateUrl: 'modules/content/image-feedback.tmpl.html',
                clickOutsideToClose: false,
                escapeToClose: false,
                controller: 'ImageFeedbackController',
                scope: $scope,
                preserveScope: true,
                parent: '#fp-content-view-' + $scope.data.id + '-' + $scope.context,
                disableParentScroll: true,
                locals: {
                    firstTime: firstTime
                }
            });
        }
    }

    /* @ngInject */
    function ImageDetailController($scope, $timeout, $controller, $interval, id, Content, poller, $resource) {
        var basectrl = $controller('BaseContentDetailController as vm', {
            $scope: $scope,
            id: id,
            content_type: "image"
        });
        angular.extend(this, basectrl);

        var vm = this;

        vm.uploadStatus = 'idle';
        vm.processStatus = 'IDLE';
        vm.extractStatus = 'IDLE';
        vm.fileMaxSize = 500 * 1024 * 1024;

        vm.createImage = function(mediaId, responseObj) {
            vm.processStatus = 'UPLOADED';

            vm.contentModel.title = responseObj.name;
            vm.contentModel.description = responseObj.description;
            vm.contentModel.data.source_media = responseObj;
            vm.contentModel.data.image_status = 'UPLOADED';
            vm.contentModel.external_id = responseObj.external_id;

            vm.save();

            vm.checkImageStatus();
        }

        $scope.$watch('vm.contentModel', function(newVal) {
            if (!vm.isNew()) {
                vm.checkImageStatus();
            }
        });

        vm.checkImageStatus = function() {
            if ((vm.contentModel.data.image_status == 'UPLOADED' || vm.contentModel.data.image_status == 'PROCESSING') && vm.contentModel.id) {
                vm.processStatus = vm.contentModel.data.image_status;

                // Get poller.
                var imageStatusPoller = poller.get(Content, {
                    action: 'get',
                    delay: 3000,
                    argumentsArray: [{
                        id: vm.contentModel.id
                    }],
                    smart: true
                });

                imageStatusPoller.promise.then(null, null, function(result) {
                    if (result.data.image_status == 'READY') {
                        poller.stopAll();
                        vm.processStatus = 'COMPLETE';
                        $timeout(function() {
                            vm.processStatus = 'READY';
                            vm.contentModel = result;
                        }, 2000);
                    }
                    if (result.data.image_status == 'FAILED') {
                        poller.stopAll();
                        vm.processStatus = 'FAILED';
                        vm.contentModel = result;
                    }
                });
            } else {
                vm.processStatus = vm.contentModel.data.image_status;
            }
        }
    }
})();
