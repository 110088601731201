(function () {
    'use strict';

    angular
        .module('atheer')
        .factory('translationStorageProvider', translationStorageProvider);

    /* @ngInject */
    function translationStorageProvider($cookies, $log, LANGUAGES, $window, $rootScope, $location) {
        return {
            get: get,
            put: put
        };

        function get(name) {
            if ($location.host().startsWith("app.")) {
                if (LANGUAGES.indexOf($cookies.getObject('NG_TRANSLATE_LANG_KEY_ON_PARENT_DOMAIN')) === -1) {
                    $cookies.putObject(name, 'en_US');
                    setLanguage('en_US');
                } else {
                    $cookies.putObject(name, $cookies.getObject('NG_TRANSLATE_LANG_KEY_ON_PARENT_DOMAIN'));
                    setLanguage($cookies.getObject('NG_TRANSLATE_LANG_KEY_ON_PARENT_DOMAIN'));
                }
                return $cookies.getObject(name);
            }
            if ($rootScope.userSelectedLanguage) {
                $cookies.putObject(name, $rootScope.userSelectedLanguage);
                setLanguage($rootScope.userSelectedLanguage);
            }
            else if (localStorage.getItem('isUserSelectedLanguage')) {
                if (LANGUAGES.indexOf($cookies.getObject(name)) === -1) {
                    $log.info('Resetting invalid cookie language "' + $cookies.getObject(name) + '" to prefered language "en_US"');
                    $cookies.putObject(name, 'en_US');
                    setLanguage('en_US');
                }
            } else {
                var lang = ($window.navigator.language || $window.navigator.userLanguage).replace('-', '_');
                if (lang == 'el') {
                    lang = 'el_GR';
                } else if (lang == 'ja') {
                    lang = 'ja_JP';
                } else if (lang.startsWith('zh')) {
                    lang = 'zh_TW';
                } else if (lang.startsWith('nl')) {
                    lang = 'nl_NL';
                }
                if (LANGUAGES.indexOf(lang) === -1) {
                    $log.info('Resetting invalid cookie language "' + $cookies.getObject(name) + '" to prefered language "en_US"');
                    $cookies.putObject(name, 'en_US');
                    setLanguage('en_US');
                } else if ($cookies.getObject(name)) {
                    $cookies.putObject(name, $cookies.getObject(name));
                    setLanguage($cookies.getObject(name));
                } else {
                    $cookies.putObject(name, lang);
                    setLanguage(lang);
                }
            }
            return $cookies.getObject(name);
        }

        function put(name, value) {
            $cookies.putObject(name, value);
            setLanguage(value);
        }

        function setLanguage(value) {
            var domainParts = $location.host().split('.');
            var parentDomain = domainParts[1] + '.' + domainParts[2];
            $cookies.putObject('NG_TRANSLATE_LANG_KEY_ON_PARENT_DOMAIN', value, { domain: parentDomain });
        }
    }
})();
