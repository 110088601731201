(function() {
    'use strict';

    angular
        .module('atheer.smartFlow')
        .controller('SmartFlowBuilderSettingController', SmartFlowBuilderSettingController)

    /* @ngInject */
    function SmartFlowBuilderSettingController($scope, $injector, $state, $element, $filter, $q, Setting, $mdDialog, MetaData, CONTENT_META, FIELD_CONDITION_CONSTANT, setting, pageId, previewMode, ToastService) {
        var vm = this;
        vm.loadingData = true;
        vm.previewMode = previewMode;

        function init() {
            vm.receivedSetting = angular.copy(setting);
            vm.setting = angular.copy(setting);

            if (vm.setting.objectType) {
                var service = $injector.get(vm.setting.objectType);
                if (vm.setting.data instanceof Array) {
                    if (vm.setting.data.length > 0) {
                        service.query({
                            'query': 'id=in=(' + vm.setting.data + ')'
                        }, function(result) {
                            vm.loadingData = false;
                            vm.setting.data = result;
                        }, function(result) {
                            vm.loadingData = false
                        });
                    } else {
                        vm.loadingData = false;
                    }
                } else {
                    service.query({
                        'query': 'id=eq=(' + vm.setting.data + ')'
                    }, function(result) {
                        vm.loadingData = false;
                        vm.setting.data = result;
                    }, function(result) {
                        vm.loadingData = false;
                    });
                }
            } else {
                vm.loadingData = false;
            }
        }

        init();

        vm.criteriaIsValid = true;

        if (pageId == 'user-field-trigger' || pageId == 'user-field-check') {
            if (vm.setting.criteria) {
                var criteria = angular.fromJson(vm.setting.criteria);
                vm.setting.criteria = criteria;
            }
            vm.dataIsLoaded = false;
            vm.timezones = [];
            vm.languages = [];

            vm.field_groups = [];

            vm.tmp_criteria = angular.copy(vm.setting.criteria);

            vm.initFieldDropDownSearch = function() {
                $element.find('input[type="search"]').on('keydown', function(ev) {
                    ev.stopPropagation();
                });
            }

            vm.conditionChange = function() {
                var default_field = {
                    "field_group_name": vm.tmp_field.field_group_name,
                    "field_name": vm.tmp_field.field_name,
                    "data_type": vm.tmp_field.data_type,
                    "operator": vm.tmp_field.data_type == "BOOLEAN" ? "EQUAL" : null,
                    "value": null,
                };

                if (vm.setting.criteria) {
                    if (default_field.field_group_name != vm.setting.criteria.field_group_name || default_field.field_name != vm.setting.criteria.field_name) {
                        vm.setting.criteria = default_field;
                    }
                } else {
                    vm.setting.criteria = default_field;
                }
            }

            vm.setTitle = function() {
                var fieldGroupMeta = $filter('filter')(vm.field_groups, {
                    'name': vm.setting.criteria.field_group_name
                })[0];

                var fieldMeta = $filter('filter')(fieldGroupMeta.fields, {
                    'name': vm.setting.criteria.field_name
                })[0];

                var returnStr = '';

                if (fieldGroupMeta && fieldMeta) {
                    returnStr += fieldMeta.label + ' ';

                    if (vm.setting.criteria.data_type == 'BOOLEAN') {
                        if (vm.setting.criteria.value) {
                            returnStr += 'Is True';
                        } else {
                            returnStr += 'Is False';
                        }
                    } else {
                        var fieldOperators = vm.getFieldOperators(vm.setting.criteria.field_group_name, vm.setting.criteria.field_name);
                        var operator = $filter('filter')(fieldOperators, {
                            'value': vm.setting.criteria.operator
                        })[0]
                        if (operator) {
                            if (operator.value == 'IS' && vm.setting.criteria.data_type == 'DATE' && vm.setting.criteria.value.option) {
                                returnStr += operator.label + ' ' + vm.setting.criteria.value.days + ' ' + vm.setting.criteria.value.option;
                            } else {
                                if (vm.setting.criteria.value && vm.setting.criteria.value != '') {
                                    returnStr += operator.label + ' ' + vm.setting.criteria.value
                                }
                            }
                        } else {
                            returnStr += vm.setting.criteria.value;
                        }
                    }
                }
                vm.setting.title = returnStr;
            }

            vm.hasCondition = function(field_group_name, field_name) {
                var fieldGroupMeta = $filter('filter')(vm.field_groups, {
                    'name': field_group_name
                })[0];

                var fieldMeta = $filter('filter')(fieldGroupMeta.fields, {
                    'name': field_name
                })[0];

                var fieldConditionsMeta = FIELD_CONDITION_CONSTANT[fieldMeta.data_type]
                if (fieldMeta.data_type === "OBJECT") {
                    return fieldConditionsMeta[fieldMeta.ref_object];
                } else {
                    return fieldConditionsMeta;
                }
            }

            vm.getFieldOperators = function(field_group_name, field_name) {
                var fieldGroupMeta = $filter('filter')(vm.field_groups, {
                    'name': field_group_name
                })[0];
                var fieldMeta = $filter('filter')(fieldGroupMeta.fields, {
                    'name': field_name
                })[0];

                var fieldConditionsMeta = FIELD_CONDITION_CONSTANT[fieldMeta.data_type]
                if (fieldMeta.data_type === "OBJECT") {
                    return fieldConditionsMeta[fieldMeta.ref_object];
                } else {
                    return fieldConditionsMeta;
                }
            }

            vm.filterFieldGroup = function(fieldGroup) {
                if (fieldGroup.name == 'profile' || fieldGroup.name == 'job' || fieldGroup.locked == false) {
                    return true;
                } else {
                    false;
                }
            }

            $q.all([
                MetaData.get({
                    id: 'user',
                    is_name: true
                }).$promise,
                Setting.getSystemTimezones({
                    size: 100
                }).$promise,
                Setting.getSystemLanguages().$promise
            ]).then(function(data) {
                angular.forEach(data[0].field_groups, function(field_group) {
                    if (field_group.fields != null) {
                        vm.field_groups.push(field_group);
                    }
                });
                vm.timezones = data[1];
                vm.languages = data[2];
                vm.dataIsLoaded = true;
            });

        }

        vm.getCardMeta = function(card) {
            var contentMetaData = $filter('filter')(CONTENT_META, {
                type: card.data.type.replace(/_/g, '-')
            })[0];
            return contentMetaData;
        }

        vm.filterAssignment = function(result) {
            var returnResult = [];

            angular.forEach(result, function(data) {
                if (vm.getCardMeta(data).show_assign_action) {
                    returnResult.push(data);
                }
            });
            return returnResult;
        };

        vm.filterQuestion = function(result) {
            var returnResult = [];

            angular.forEach(result, function(data) {
                if (vm.getCardMeta(data).show_ask_action) {
                    returnResult.push(data);
                }
            });
            return returnResult;
        };

        vm.close = function() {
            $mdDialog.cancel();
        }

        vm.autoCompleteCallBack = function(result) {
            if (vm.setting.objectType) {
                var service = $injector.get(vm.setting.objectType);
                if ( (vm.setting.title && vm.setting.title.trim() == '') || !vm.setting.title) {
                    if (vm.setting.data instanceof Array) {
                        if (result.length > 1) {
                            vm.setting.title = result.length + $filter('translate')('atheer.smartFlow.builder.itemsSelected');
                        } else {
                            var selectedFirstObject = result[0];
                            vm.setting.title = selectedFirstObject.display || selectedFirstObject.name || selectedFirstObject.title || selectedFirstObject.first_name + ' ' + selectedFirstObject.last_name;
                        }
                    } else {
                        var selectedFirstObject = result;
                        vm.setting.title = selectedFirstObject.display || selectedFirstObject.name || selectedFirstObject.title || selectedFirstObject.first_name + ' ' + selectedFirstObject.last_name;
                    }
                }

            }
            return result;
        }

        vm.submit = function() {
            if (vm.criteriaIsValid) {

                if (vm.setting.objectType && vm.setting.data instanceof Array && vm.setting.data.length > 0) {
                    var dataIds = [];
                    angular.forEach(vm.setting.data, function(dataObj) {
                        dataIds.push(dataObj.id)
                    });

                    vm.setting.data = dataIds;
                }

                $mdDialog.hide(vm.setting);
            } else {
                ToastService.displayToast($filter('triTranslate')("atheer.smartFlow.builder.fillRequiredFields"), 3000);
            }
        };
    }
})();
