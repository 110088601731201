(function () {
    'use strict';

    angular
        .module('atheer')
        .factory('authInterceptor', authInterceptor);

    /* @ngInject */
    function authInterceptor() {
        var service = {
            request: request
        };

        return service;

        function request(config) {
            /*jshint camelcase: false */
            config.headers = config.headers || {};
            var token = localStorage.getItem('Authorization');

            if(!token) {
                token = sessionStorage.getItem('Authorization');
            }

            if (token) {
                config.headers.Authorization = 'Bearer ath-' + token;
            }
            return config;
        }
    }
})();
