(function() {
    'use strict';
    angular
        .module('atheer.services')
        .factory('Organization', Organization);


    /* @ngInject */
    function Organization($resource) {
        var resourceUrl = 'api/organizations/:id';

        return $resource(resourceUrl, {
            id: '@id'
        }, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function(data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': {
                method: 'PUT'
            },
            'validateImportFile': {
                url: 'api/organizations/import/validate',
                method: 'GET',
            },
            'importOrganizations': {
                url: 'api/organizations/import',
                method: 'POST',
            },
            'getImportStatus': {
                url: 'api/system/job-streams/:id',
                method: 'GET'
            },
        });
    }
})();
