(function() {
    'use strict';

    angular
        .module('atheer.system')
        .controller('CameraSettingController', CameraSettingController)

    /* @ngInject */
    function CameraSettingController($mdDialog, $q, $scope, $filter, $sce, ParseLinks, $state, AlertService, ContentAction, $mdToast, $mdSidenav, Principal, Setting, Feature, ToastService) {
        var vm = this;

        vm.photo_setting = "Standard";
        vm.video_setting = "Standard x 30 fps";

        vm.photo_setting_options = ["Standard", "High quality"];
        vm.video_setting_options = ["Standard x 30 fps", "HD x 30 fps", "HD x 60 fps"];
        vm.pageTitleKey = $state.$current.data.pageTitle;

        vm.cameraModel = {
            id: null,
            photo_setting: "Standard",
            video_setting: "Standard x 30 fps"
        };

        vm.onSaveSuccess = function(result) {
            vm.cameraModel = result;
            vm.isSaving = false;
            AlertService.displayToast($mdToast);
        };

        vm.onSaveError = function() {
            vm.isSaving = false;
        };

        function setCameraettings() {
            setCameraSettingField("photo_setting", vm.cameraModel.photo_setting);
            setCameraSettingField("video_setting", vm.cameraModel.video_setting);
        }

        function setCameraSettingField(fieldName, fieldValue) {
            var foundField = false;
            angular.forEach(vm.cameraSetting.fields, function(settingField) {
                if (settingField.name == fieldName) {
                    settingField.value = fieldValue;
                    foundField = true;
                }
            });

            if (!foundField) {
                var settingField = {
                    name: fieldName,
                    value: fieldValue,
                }
                vm.airformSetting.fields.push(settingField);
            }
        }

        vm.save = function() {
            setCameraettings();

            Setting.update(vm.cameraSetting, function() {
                ToastService.displayToast($filter('triTranslate')("atheer.setting.success"), 3000);
            });
        }



        vm.loadData = function() {
            vm.loadingData = true;

            $q.all([
                    Setting.getCameraSettings().$promise
                ])
                .then(function(results) {
                    setCameraSessionModel(results[0]);
                    vm.loadingData = false;
                });
        };

        function setCameraSessionModel(settings) {

            angular.forEach(settings, function(setting) {
                if (setting.category == 'Lens Camera') {
                    vm.cameraSetting = setting;

                    angular.forEach(setting.fields, function(settingField) {
                        if (settingField.name == 'photo_setting') {
                            vm.cameraModel.photo_setting = settingField.value;
                        }
                        if (settingField.name == 'video_setting') {
                            vm.cameraModel.video_setting = settingField.value;
                        }

                    });
                }
            });
        };

        vm.loadData();
    }
})();
