(function() {
    'use strict';

    angular
        .module('atheer.cloud')
        .config(moduleConfig);

    function moduleConfig($stateProvider) {
        $stateProvider
        .state('storm.cloud-admin.health', {
             url: '/health',
             templateUrl: 'modules/cloud/health/health.html',
             controller: 'JhiHealthCheckController',
             controllerAs: 'vm',
             data: {
                 pageTitle: 'atheer.health.title',
                 layout: {
                     contentClass: 'layout-column',
                     footer: false,
                     showToolbar: false,
                     showSideNavMenu: true,
                 }
             }
         });
    }
})();
