(function () {
    "use strict";

    angular
        .module("atheer.datamodel")
        .controller("DataModelController", DataModelController);

    /* @ngInject */
    function DataModelController(
        $scope,
        $window,
        $state,
        DataModel,
        ParseLinks,
        AlertService,
        pagingParams,
        paginationConstants,
        $mdDialog,
        $mdToast,
        $stateParams,
        Feature,
        $translate,
        ToastService
    ) {
        var vm = this;

        vm.loadAll = loadAll;
        vm.transition = transition;
        vm.removeFilter = removeFilter;
        vm.getQueryString = getQueryString;

        vm.createObject = createObject;
        vm.deleteObject = deleteObject;

        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.illustartionEmptyScreenId = "datamodel";

        vm.query = {
            filter: "",
            limit: paginationConstants.itemsPerPage,
            order: "name ",
            page: 1,
        };

        vm.filter = {
            show: false,
            options: {
                debounce: 500,
            },
        };

        vm.loadAll();

        function loadAll() {
            DataModel.query(
                {
                    page: pagingParams.page - 1,
                    size: paginationConstants.itemsPerPage,
                    sort: sort(),
                    query: getQueryString(),
                },
                onSuccess,
                onError
            );

            function sort() {
                var result = [
                    vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                ];
                if (vm.predicate !== "id") {
                    result.push("id");
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers("link"));
                vm.totalItems = headers("x-total-count");
                vm.queryCount = vm.totalItems;
                vm.datamodelList = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function reOrder(order) {
            vm.predicate = order;
            vm.reverse = !vm.reverse;
            vm.transition();
        }

        function transition() {
            vm.promise = DataModel.query({
                page: vm.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                query: getQueryString(),
            }).$promise;

            vm.promise.then(function (data, headers) {
                vm.queryCount = vm.totalItems;
                vm.datamodelList = data;
            });
        }

        function getQueryString() {
            if (vm.query.filter.length <= 0) {
                return null;
            } else {
                return "name=re=" + vm.query.filter;
            }
        }

        function removeFilter() {
            vm.filter.show = false;
            vm.query.filter = "";

            if (vm.filter.form && vm.filter.form.$dirty) {
                vm.filter.form.$setPristine();
            }
        }

        function createObject(data) {
            $mdDialog
                .show({
                    animation: true,
                    templateUrl:
                        "modules/smartflow/datamodel/datamodel-create-dialog.html",
                    controller: "DataModelCreateController",
                    controllerAs: "vm",
                    backdrop: "static",
                    size: "lg",
                    data: data,
                    resolve: {
                        entity: function () {
                            return {
                                id: null,
                                name: null,
                                properties: [],
                            };
                        },
                    },
                })
                .then(function () {
                    resetModal();
                });
        }

        function deleteObject(entity) {
            var confirm = $mdDialog
                .confirm()
                .title($translate.instant('atheer.datamodel.delete.question'))
                .ariaLabel($translate.instant('atheer.datamodel.delete.question'))
                .ok($translate.instant('atheer.datamodel.delete.delete'))
                .cancel($translate.instant('atheer.datamodel.cancel'));

            $mdDialog.show(confirm).then(function () {
                DataModel.delete(
                    {
                        id: entity.id,
                    },
                    function () {
                        ToastService.displayToast($translate.instant('atheer.datamodel.deleted'), 3000);
                        resetModal(false);
                    }
                );
            });
        }

        function resetModal(showToast) {
            loadAll();

            if (!!showToast) {
                AlertService.displayToast($mdToast);
            }
        }

        vm.getDefaultBackground = function () {
            return "#bdbec0";
        };

        vm.back = function () {
            $window.history.back();
        };
    }
})();
