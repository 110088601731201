(function() {
    'use strict';

    angular
        .module('atheer.user')
        .controller('ProfileOverviewController', ProfileOverviewController);

    /* @ngInject */
    function ProfileOverviewController($q, $scope, $timeout, $filter, $mdDialog, id, User, Experience, Report, System, $stateParams, ToastService) {
        var vm = this;

        vm.user = null;
        vm.userTimezone = null;
        vm.experienceSummary = null;
        vm.isAdminView = id != 'me';
        vm.userOverviewSummary = {
            job_summary: {
                assigned: 0,
                in_progress: 0,
                completed: 0,
                avg_duration: 0
            },
            session_summary: {
                scheduled: 0,
                attended: 0,
                avg_duration: 0,
                total_minutes: 0
            },
            content_summary: {
                shared: 0,
                bookmarked: 0,
                created: 0,
                viewed: 0
            },
            conversation_summary: {
                conversations: 0,
                oneToOne: 0,
                group: 0,
                messages: 0
            }
        };
        vm.start = moment().subtract(30, 'days');
        vm.end = moment();

        vm.getUserProfileImageUrl = function() {
            return vm.loadingData ? 'assets/images/avatars/user-avatar.png' : vm.user.picture_id ? '/media/pictures/' + vm.user.picture_id : 'assets/images/avatars/user-avatar.png';
        }

        vm.updateUserProfileImage = function(mediaId, responseObj) {
            vm.user.picture_id = mediaId;
            updateUser('atheer.overview.updatedSuccessfully');
        };

        vm.removeUserProfileImage = function() {
            vm.user.picture_id = undefined;
            updateUser('atheer.overview.updatedSuccessfully');
        };

        vm.getActivityCount = function(activityName) {
            var activitySummary = vm.experienceSummary.activity_summary;
            for (var i = 0; i < activitySummary.length; i++) {
                if (activitySummary[i].name === activityName) {
                    return activitySummary[i].count;
                }
            }
            return 0;
        };

        vm.linkDevice = function(user) {
            $mdDialog.show({
                templateUrl: 'modules/user/overview/qrcode-dialog.tmpl.html',
                clickOutsideToClose: true,
                controller: "QrCodeDialogController",
                controllerAs: "vm",
                locals: {
                    filter: user.id
                }
            }).then(function(conversation) {}).finally(function() {});
        };

        function updateUser(message) {
            if (!vm.isAdminView) {
                User.updateme(vm.user, function(result) {
                    vm.user = result;

                    //this is to update the picture in toolbar
                    $rootScope.$broadcast('updateUserInfo', vm.user);
                    Principal.identity(true).then(function(user) {});

                    ToastService.displayToast($filter('triTranslate')(message), 3000);
                });
            }
            else {
                User.update(vm.user, function(result) {
                    vm.user = result;
                    ToastService.displayToast($filter('triTranslate')(message), 3000);
                });
            }
        };

        function createUserOverviewSummary(userOverviewSummary) {
            var jobSummary = userOverviewSummary.job_summary;
            var sessionSummary = userOverviewSummary.session_summary;
            var contentSummary = userOverviewSummary.content_summary;
            var conversationSummary = userOverviewSummary.conversation_summary;

            if (jobSummary && jobSummary.length > 0) {
                jobSummary.forEach(function(res) {
                    if (res.name == 'assigned') {
                        vm.userOverviewSummary.job_summary.assigned = res.count;
                    } else if (res.name == 'in_progress') {
                        vm.userOverviewSummary.job_summary.in_progress = res.count;
                    } else if (res.name == 'completed') {
                        vm.userOverviewSummary.job_summary.completed = res.count;
                    } else if (res.name == 'avg_duration') {
                        vm.userOverviewSummary.job_summary.avg_duration = res.count;
                    }
                });
            }

            if (sessionSummary && sessionSummary.length > 0) {
                sessionSummary.forEach(function(res) {
                    if (res.name == 'scheduled') {
                        vm.userOverviewSummary.session_summary.scheduled = res.count;
                    } else if (res.name == 'attended') {
                        vm.userOverviewSummary.session_summary.attended = res.count;
                    } else if (res.name == 'avg_duration') {
                        vm.userOverviewSummary.session_summary.avg_duration = res.count;
                    } else if (res.name == 'total_minutes') {
                        vm.userOverviewSummary.session_summary.total_minutes = res.count;
                    }
                });
            }

            if (contentSummary && contentSummary.length > 0) {
                contentSummary.forEach(function(res) {
                    if (res.name == 'shared') {
                        vm.userOverviewSummary.content_summary.shared = res.count;
                    } else if (res.name == 'bookmarked') {
                        vm.userOverviewSummary.content_summary.bookmarked = res.count;
                    } else if (res.name == 'created') {
                        vm.userOverviewSummary.content_summary.created = res.count;
                    } else if (res.name == 'viewed') {
                        vm.userOverviewSummary.content_summary.viewed = res.count;
                    }
                });
            }

            if (conversationSummary && conversationSummary.length > 0) {
                conversationSummary.forEach(function(res) {
                    if (res.name == 'conversations') {
                        vm.userOverviewSummary.conversation_summary.conversations = res.count;
                    } else if (res.name == '1-1') {
                        vm.userOverviewSummary.conversation_summary.oneToOne = res.count;
                    } else if (res.name == 'group') {
                        vm.userOverviewSummary.conversation_summary.group = res.count;
                    } else if (res.name == 'messages') {
                        vm.userOverviewSummary.conversation_summary.messages = res.count;
                    }
                });
            }
            vm.loadingData = false;

            if ($stateParams.launchQrCode) {
                $timeout(function() {
                    vm.linkDevice(vm.user);
                }, 1000);    
            }
        };

        function loadData() {
            vm.loadingData = true;

            $q.all([
                User.getById({
                    id: id
                }).$promise,
                Report.getUserOverviewSummary({
                    id: id
                }).$promise,
                System.getTimezones({
                    size: 100
                }).$promise
            ]).then(function(data) {
                vm.user = data[0];
                createUserOverviewSummary(data[1])
                vm.timezones = data[2];

                vm.userTimezone = vm.timezones.find(function(timezone) {
                    return timezone.timezone_no == vm.user.timezone_id;
                });

            }).catch(function(data) {}).finally(function() {});
        };
        loadData();
    }
})();
