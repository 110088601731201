(function() {

    'use strict';
    angular
        .module('atheer')
        .constant('CRITERIA_CONDITION_CONSTANT', {
            'STRING': [{
                    label: 'Starts With',
                    value: 'STARTS_WITH'
                },
                {
                    label: 'Ends With',
                    value: 'ENDS_WITH'
                },
                {
                    label: 'Contains',
                    value: 'CONTAINS'
                },
                {
                    label: 'Is',
                    value: 'EQUAL'
                },
                {
                    label: 'Is Not',
                    value: 'NOT_EQUAL'
                }
            ],
            'OBJECT': {
                'content': [{
                    label: 'Is',
                    value: 'EQUAL'
                }],
                'badge': [{
                    label: 'Is',
                    value: 'EQUAL'
                }],
                'security-role': [{
                    label: 'Is',
                    value: 'EQUAL'
                }],
                'audience': [{
                    label: 'Is',
                    value: 'EQUAL'
                }],
                'topic': [{
                    label: 'Is',
                    value: 'EQUAL'
                }]
            },
            'LANGUAGE': [{
                    label: 'Is',
                    value: 'EQUAL'
                },
                {
                    label: 'Is Not',
                    value: 'NOT_EQUAL'
                }
            ],
            'TIMEZONE': [{
                    label: 'Is',
                    value: 'EQUAL'
                },
                {
                    label: 'Is Not',
                    value: 'NOT_EQUAL'
                }
            ],
            'LIST': [{
                    label: 'Is',
                    value: 'EQUAL'
                },
                {
                    label: 'Is Not',
                    value: 'NOT_EQUAL'
                }
            ],
            'NUMBER': [{
                    label: 'Is',
                    value: 'EQUAL'
                },
                {
                    label: 'Is Not',
                    value: 'NOT_EQUAL'
                },
                {
                    label: 'Greater Than',
                    value: 'GREATER_THAN'
                },
                {
                    label: 'Less Than',
                    value: 'LESS_THAN'
                },
                {
                    label: 'Greater Than Equals',
                    value: 'GREATER_THAN_OR_EQUAL'
                },
                {
                    label: 'Less Than Equals',
                    value: 'LESS_THAN_OR_EQUAL'
                }
            ],
            'DATE': [{
                    label: 'Is',
                    value: 'IS'
                },
                {
                    label: 'Between',
                    value: 'BETWEEN'
                },
                {
                    label: 'Is Equal',
                    value: 'EQUAL'
                },
                {
                    label: 'Is Not',
                    value: 'NOT_EQUAL'
                },
                {
                    label: 'Greater Than',
                    value: 'GREATER_THAN'
                },
                {
                    label: 'Less Than',
                    value: 'LESS_THAN'
                },
                {
                    label: 'Greater Than Equals',
                    value: 'GREATER_THAN_OR_EQUAL'
                },
                {
                    label: 'Less Than Equals',
                    value: 'LESS_THAN_OR_EQUAL'
                }
            ]
        });
})();
