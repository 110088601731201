(function () {
    'use strict';

    angular
        .module('atheer.system')
        .controller('FileExtSettingsController', FileExtSettingsController)

    /* @ngInject */
    function FileExtSettingsController($scope, $state, $q, $http, Setting, $filter, $window, Principal, ToastService) {
        var vm = this;

        vm.loadingData = false;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.featureSettings = null;
        vm.features = {};
        vm.isAdmin = Principal.isWorkspaceAdmin();
        vm.homePageFeatureValue = false;
        vm.fileExtensions = "";

        function setFeaturesModel(fileExtConfig) {
            vm.featureSettings = fileExtConfig[0];

            //Initialize based on current file ext configuration
            angular.forEach(fileExtConfig[0].fields, function (field) {
                if ( vm.fileExtensions !== "" ) { vm.fileExtensions = vm.fileExtensions.concat(", "); }
                vm.fileExtensions = vm.fileExtensions.concat(field.name);
            });
        }

        function setFeatureSettings() {
            vm.featureSettings.fields = [];
            var settingField;
            angular.forEach(vm.fileExtensions.split(','), function (mimeType) {
                settingField = {
                    name: mimeType.replace(/ /g,"")
                }
                vm.featureSettings.fields.push(settingField);
            });
        }

        vm.save = function () {
            setFeatureSettings();

            Setting.update(vm.featureSettings, function() {
                ToastService.displayToast($filter('triTranslate')("atheer.setting.success"), 3000);
            }); 
        }

        vm.loadData = function () {
            vm.loadingData = true;

            $q.all([
                Setting.getJobFileExtSettings().$promise
            ])
                .then(function (results) {
                    setFeaturesModel(results[0]);
                    vm.loadingData = false;
                });
        };
        vm.loadData();
    }
})();
