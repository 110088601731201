(function () {
    'use strict';

    angular
        .module('atheer.services')
        .factory('PubNubService', PubNubService)

    /* @ngInject */
    function PubNubService(Pubnub, $rootScope, $filter, NotificationHandleService, $state, $cookies) {

        var userId = null;
        var channelsDetail = null;
        var globalChannels = [];
        var workspaceChannelName = null;
        var usersState = [];
        var random_uuid = window.localStorage.getItem("random_uuid");
        var userPermissionSet = [];

        var service = {
            init: init,
            subscribe: subscribe,
            unsubscribe: unsubscribe,
            publish: publish,
            getMessageEventNameFor: getMessageEventNameFor,
            getPresenceEventNameFor: getPresenceEventNameFor,
            getUserState: getUserState,
            disconnect: disconnect
        };
        return service;

        function uuidv4() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = Math.random() * 16 | 0,
                    v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        }

        function init(id, messaging_config, channelsInfo, permissionSet) {
            if (window.localStorage.getItem("random_uuid") === null) {
                var ruuid = uuidv4();
                window.localStorage.setItem("random_uuid", ruuid);
                random_uuid = id + ruuid;
            } else {
                random_uuid = id + window.localStorage.getItem("random_uuid");
            }
            userId = id;
            channelsDetail = channelsInfo;

            var channels = [];
            var conversationChannels = [];

            userPermissionSet = permissionSet;

            angular.forEach(channelsInfo.personal, function (channel) {
                channels.push(channel.name);
            });

            angular.forEach(channelsInfo.global, function (channel) {
                if (channel.name.startsWith('workspace')) {
                    workspaceChannelName = channel.name;
                } else {
                    channels.push(channel.name);
                    globalChannels.push(channel.name);
                }
            });

            angular.forEach(channelsInfo.conversations, function (channel) {
                conversationChannels.push(channel.name);
            });

            Pubnub.init({
                origin: messaging_config.domain,
                ssl: true,
                publishKey: messaging_config.publish_key,
                subscribeKey: messaging_config.subscribe_key,
                uuid: id,
                restore: true
            });

            //subscribe to all the global channels
            if (channels.length > 0) {
                subscribe(channels, false);
            };

            if (conversationChannels.length > 0) {
                subscribe(conversationChannels, true);
            };

            //subscribe to workspace channels
            if (workspaceChannelName != null) {
                subscribe([workspaceChannelName], true);
            };

            Pubnub.addListener({
                status: function (statusEvent) {
                    console.log("Studio-Pubnub-status", statusEvent);
                    if (statusEvent.category === "PNUnknownCategory") {
                        var newState = {
                            new: 'error'
                        };
                        Pubnub.setState({
                            state: newState
                        },
                            function (status) {
                                console.log(statusEvent.errorData.message)
                            }
                        );
                    }
                },
                message: function (message) {
                    console.log("Studio-Pubnub-Message", message);
                    if (message.message.subject == 'CONVERSATION' && message.message.uuid != random_uuid && (hasAccess('access-conversation') || $cookies.get('isGuest'))) {
                        if ($state.current.url != '/conversations/:id' && message.message.type === 'MESSAGE_CREATED'
                            && $state.current.url != '/player/:session_no') {
                            NotificationHandleService.onNotification(message);
                        } else {
                            $rootScope.$broadcast('conversationMessagePubNub', message);
                            if (message.message.type === 'REFRESH_CONVERSATION') {
                                subscribe(['conversation-' + message.message.data.id], true);
                            }
                        }
                    } else if (message.message.type == 'NOTIFICATION' && message.message.uuid != random_uuid && hasAccess('access-session')) {
                        NotificationHandleService.onNotification(message.message.data)
                    } else if (message.message.subject == 'SESSION') {
                        $rootScope.$broadcast('sessionMessagePubNub', message);
                    } else if (message.message.type === 'JOB' && hasAccess('access-job')) {
                        NotificationHandleService.onNotification(message);
                    }
                },
                presence: function (message) {
                    console.log("Studio-Pubnub-presence", message);
                    if (message.uuid != userId && message.channel == workspaceChannelName) {
                        if (message.action == 'join') {
                            var usersStateIndex = usersState.findIndex(function (userState) {
                                return userState.uuid == message.uuid;
                            });

                            if (usersStateIndex <= 0) {
                                usersState.push({
                                    state: message.state,
                                    uuid: message.uuid
                                });
                            }
                        } else if (message.action == 'leave' || message.action == 'timeout') {
                            usersState = usersState.filter(function (userState) {
                                return userState.uuid != message.uuid;
                            });
                        }
                        $rootScope.$broadcast('refreshUserStatus', message.uuid);
                    }
                }
            });

            Pubnub.hereNow({
                channels: [workspaceChannelName],
                includeUUIDs: true,
                includeState: true
            },
                function (status, response) {
                    console.log("Studio-Pubnub-hereNow", status, response);
                    if (response != undefined && response.channels != undefined) {
                        usersState = response.channels[workspaceChannelName].occupants;
                        console.log("Studio-Pubnub-hereNow-usersState", usersState);
                    }
                }
            );
        };

        function subscribe(channels, withPresence) {
            console.log("Studio-PubNub-subscribe", channels);
            Pubnub.subscribe({
                channels: channels,
                withPresence: withPresence,
                triggerEvents: ['message', 'presence', 'status']
            });
        };

        function unsubscribe(channels) {
            Pubnub.unsubscribe({
                channels: channels,
            });
        };

        function publish(channel, message) {
            //console.log("Studio-Pubnub", channel, message);
            message.uuid = random_uuid;
            Pubnub.publish({
                channel: channel,
                message: message,
                storeInHistory: false
            }, function (status, response) {
                //console.log("Studio-Pubnub-Success", status, response);
            });
        };

        function getMessageEventNameFor(channel) {
            return Pubnub.getMessageEventNameFor(channel);
        };

        function getPresenceEventNameFor(channel) {
            return Pubnub.getPresenceEventNameFor(channel);
        };

        function getUserState(id) {
            var userInfo = $filter('filter')(usersState, {
                uuid: id
            })[0];
            return userInfo;
        };

        function disconnect() {
            console.log("Studio-Pubnub-disconnect");
            Pubnub.unsubscribeAll();
        };


        function hasAccess(permission) {
            if (userPermissionSet.indexOf(permission) > -1) {
                return true;
            } else {
                return false;
            }
        };
    }
})();
