(function() {
    'use strict';

    angular
        .module('atheer.content')
        .controller('ContentPlayerController', ContentPlayerController);

    /* @ngInject */
    function ContentPlayerController($rootScope, $scope, $document, $mdDialog, $mdToast, $mdBottomSheet, $filter, dataToPass, mode, Principal, Experience) {
        $scope.contentData = dataToPass;
        $scope.assignmentId = null;
        $scope.assignmentData = null;
        $scope.mode = mode;
        $scope.loadingData = false;
        $scope.isFullScreen = false;
        $scope.fullScreenIcon = 'zmdi zmdi-fullscreen';

        $scope.finishCallBack = function() {
            $scope.close();
            $rootScope.$emit("content-player-closed");
        }

        $scope.close = function() {
            $mdDialog.hide();
            $mdBottomSheet.hide();
        };

        $scope.toggleFullScreen = function() {
            $scope.isFullScreen = !$scope.isFullScreen;
            $scope.fullScreenIcon = $scope.isFullScreen ? 'zmdi zmdi-fullscreen-exit' : 'zmdi zmdi-fullscreen';
            // more info here: https://developer.mozilla.org/en-US/docs/Web/API/Fullscreen_API
            var doc = $document[0];
            if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
                if (doc.documentElement.requestFullscreen) {
                    doc.documentElement.requestFullscreen();
                } else if (doc.documentElement.msRequestFullscreen) {
                    doc.documentElement.msRequestFullscreen();
                } else if (doc.documentElement.mozRequestFullScreen) {
                    doc.documentElement.mozRequestFullScreen();
                } else if (doc.documentElement.webkitRequestFullscreen) {
                    doc.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
                }
            } else {
                if (doc.exitFullscreen) {
                    doc.exitFullscreen();
                } else if (doc.msExitFullscreen) {
                    doc.msExitFullscreen();
                } else if (doc.mozCancelFullScreen) {
                    doc.mozCancelFullScreen();
                } else if (doc.webkitExitFullscreen) {
                    doc.webkitExitFullscreen();
                }
            }
            $scope.$broadcast('toggleFullScreen', $scope.isFullScreen);
        }

        function init() {
            if (mode == 'play') {
                $scope.loadingData = true;
                Experience.getAssignments({
                    query: "object_id==" + $scope.contentData.id + ";status!=COMPLETED;user_id==" + Principal.getUserId()
                }, function(result) {
                    if (result.length > 0) {
                        $scope.assignmentId = result[0].id;
                        $scope.assignmentData = result[0];
                        updateAssigment();
                    } else {
                        $scope.loadingData = false;
                    }

                });
            }
        }

        function updateAssigment() {
            if ($scope.assignmentData != null && $scope.assignmentData.status != 'IN_PROGRESS') {
                $scope.assignmentData.status = 'IN_PROGRESS';
                $scope.assignmentData.started_on = new Date();

                Experience.updateAssignment({
                    id: $scope.assignmentData.id
                }, $scope.assignmentData, function(result) {
                    $scope.assignmentData = result;
                    $scope.loadingData = false;
                });
            } else {
                $scope.loadingData = false;
            }
        }
        init();
    }
})();
