(function() {
    'use strict';

    angular
        .module('atheer.content')
        .controller('LauncherController', LauncherController);

    /* @ngInject */
    function LauncherController($rootScope, $scope, $document, $state, $q, Content, Launcher, ContentAction, id, triLoaderService, Principal, $window, deviceDetector, Experience) {
        var vm = this;

        vm.loadingData = true;
        vm.contentId = id;
        vm.contentType;
        vm.contentData;
        vm.assignmentId;
        vm.assignmentData;
        vm.loadingData = false;
        vm.isFullScreen = false;
        vm.fullScreenIcon = 'zmdi zmdi-fullscreen';
        vm.mode = 'play';
        vm.viewData;
        vm.launchTime = new Date();

        vm.device = {
            os: deviceDetector.os,
            device: 'browser',
            browser: deviceDetector.browser,
            os_version: deviceDetector.os_version,
            browser_version: deviceDetector.browser_version
        };

        vm.finishCallBack = function() {
            vm.close();
            $rootScope.$emit("content-player-closed");
        }

        vm.close = function() {
            updateContentViewed();
            window.parent.postMessage({
                closeContent: true
            }, "*");
        };

        vm.toggleFullScreen = function() {
            vm.isFullScreen = !vm.isFullScreen;
            vm.fullScreenIcon = vm.isFullScreen ? 'zmdi zmdi-fullscreen-exit' : 'zmdi zmdi-fullscreen';
            // more info here: https://developer.mozilla.org/en-US/docs/Web/API/Fullscreen_API
            var doc = $document[0];
            if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
                if (doc.documentElement.requestFullscreen) {
                    doc.documentElement.requestFullscreen();
                } else if (doc.documentElement.msRequestFullscreen) {
                    doc.documentElement.msRequestFullscreen();
                } else if (doc.documentElement.mozRequestFullScreen) {
                    doc.documentElement.mozRequestFullScreen();
                } else if (doc.documentElement.webkitRequestFullscreen) {
                    doc.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
                }
            } else {
                if (doc.exitFullscreen) {
                    doc.exitFullscreen();
                } else if (doc.msExitFullscreen) {
                    doc.msExitFullscreen();
                } else if (doc.mozCancelFullScreen) {
                    doc.mozCancelFullScreen();
                } else if (doc.webkitExitFullscreen) {
                    doc.webkitExitFullscreen();
                }
            }
            $scope.$broadcast('toggleFullScreen', vm.isFullScreen);
        }

        function updateAssigment() {
            if (vm.assignmentData != null && vm.assignmentData.status != 'IN_PROGRESS') {
                vm.assignmentData.status = 'IN_PROGRESS';
                vm.assignmentData.started_on = new Date();

                Experience.updateAssignment({
                    id: vm.assignmentData.id
                }, vm.assignmentData, function(result) {
                    vm.assignmentData = result;
                });
            }
        };

        function markContentViewed() {
            Experience.markViewed({
                object: vm.contentData,
                user_id: Principal.getUserId(),
                time_spent: 0,
                device_properties: vm.device
            }, function(result) {
                vm.viewData = result;
            });
        };

        function updateContentViewed() {
            var closeTime = new Date();
            vm.viewData.time_spent = (closeTime - vm.launchTime);
            Experience.updateViewed({
                id: vm.viewData.id
            }, vm.viewData);
        }

        function init() {
            vm.loadingData = true;

            Launcher.getAssignment({
                id: vm.contentId
            }, function(result) {
                vm.contentType = result.object.data.type;
                vm.contentItem = result.object;
                vm.contentData = result.object;
                vm.assignmentId = null;
                vm.assignmentData = result;

                if (result.status != 'COMPLETED') {
                    vm.assignmentId = result.id;
                };

                markContentViewed();
                vm.loadingData = false;
            });
        };
        init();
    }
})();
