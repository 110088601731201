(function() {
    'use strict';

    angular
        .module('atheer.session')
        .controller('SessionSurveyController', SessionSurveyController);

    /* @ngInject */
    function SessionSurveyController($scope, $state, $q, $sce, $filter, Report, externalUrl, ParseLinks, AlertService, $mdDialog, $mdToast, $stateParams) {
        var vm = this;

        vm.externalUrl = $sce.trustAsResourceUrl(externalUrl);;

        vm.close = function() {
            $mdDialog.cancel();
        };
    };
})();
