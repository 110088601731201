(function() {
    'use strict';

    angular
        .module('atheer.insights')
        .controller('InsightsDashboardsMenuController', InsightsDashboardsMenuController);

    /* @ngInject */
    function InsightsDashboardsMenuController($scope, $state, $mdDialog, $mdPanel, Team, ParseLinks, AlertService, pagingParams, paginationConstants, $mdToast, $stateParams, $filter, $translate, Feature) {
        var vm = this;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.predicate = pagingParams.predicate;
        vm.advInsightsDashboardEnabled = Feature.isActive('feature_adv-insights_enabled');

        vm.permissions = {
            create: 'create-dashboard',
            update: 'update-dashboard',
            delete: 'delete-dashboard',
            manage: 'manage-dashboard',
            system: "system-dashboard"
        };

        vm.filters = {
            system: true,
            createdByMe: true,
            enabled: true
        };

        vm.updateFilters = function() {
            $scope.$emit('refreshDashboards', vm.filters);
        }
    }
})();
