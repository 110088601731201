(function() {
    'use strict';

    angular
        .module('atheer.conversation')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('storm.conversations', {
                url: '/conversations',
                templateUrl: 'modules/conversation/conversations.tmpl.html',
                controller: 'ConversationsController',
                controllerAs: 'vm',
                data: {
                    pageTitle: 'atheer.conversation.home.title',
                    layout: {
                        showSideNavMenu: false,
                        contentClass: 'layout-column',
                        showToolbar: false,
                        footer: false
                    }
                }
            })
            .state('storm.conversations-detail', {
                url: '/conversations/:id',
                views: {
                    '@storm': {
                        templateUrl: 'modules/conversation/conversation-detail.html',
                        controller: 'ConversationDetailController',
                        controllerAs: 'vm',
                    },
                    'sideMenu@storm': {
                        templateUrl: 'modules/conversation/conversation-list.tmpl.html',
                        controller: 'ConversationListController',
                        controllerAs: 'vm',
                    }
                },
                data: {
                    pageTitle: 'atheer.conversation.home.title',
                    layout: {
                        showSideNavMenu: true,
                        sideNavMenuSize: 20,
                        contentClass: 'layout-column',
                        showToolbar: false,
                        footer: false
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    id: function($stateParams) {
                        return $stateParams.id;
                    },
                }
            });
    }
})();
