(function() {
    'use strict';

    angular
        .module('atheer.cloud')
        .controller('JobStreamDialogController', JobStreamDialogController);

    JobStreamDialogController.$inject = ['$scope', '$stateParams', '$mdDialog', 'entity', 'JobStream'];

    function JobStreamDialogController ($scope, $stateParams, $mdDialog, entity, JobStream) {
        var vm = this;
        vm.jobStream = entity;
        vm.load = function(id) {
            JobStream.get({id : id}, function(result) {
                vm.jobStream = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('atheer:jobStreamUpdate', result);
            $mdDialog.hide(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.jobStream.id !== null) {
                JobStream.update(vm.jobStream, onSaveSuccess, onSaveError);
            } else {
                JobStream.save(vm.jobStream, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $mdDialog.cancel();
        };
    }
})();
