(function () {
    'use strict';

    angular
        .module('atheer')
        .factory('stateHandler', stateHandler);

    /* @ngInject */
    function stateHandler($rootScope, $state, $translate, JhiLanguageService, translationHandler, Auth, Principal, VERSION) {
        return {
            initialize: initialize
        };

        function initialize() {
            $rootScope.VERSION = VERSION;
            $rootScope.back = back;

            var stateChangeStart = $rootScope.$on('$stateChangeStart', function (event, toState, toStateParams) {
                if (!$rootScope.isJobExecutionActive && !$rootScope.isJobBoardActive) {
                    $rootScope.toState = toState;
                    $rootScope.toStateParams = toStateParams;

                    if (Principal.isIdentityResolved()) {
                        Auth.authorize();
                    }
                } else {
                    event.preventDefault();
                    var iFrame = document.getElementById('ngxFrame').contentWindow;
                    var targetOrigin = location.hostname === "localhost"
                        ? "https://" + location.hostname + ":4200"
                        : location.origin;
                    iFrame.postMessage({ 'jobExecutionCompleted': false, 'menuState': toState.name, 'type': 'leftNavChange' }, targetOrigin);
                }
            });

            var stateChangeSuccess = $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
                // Remember previous state unless we've been redirected to login or we've just
                // reset the state memory after logout. If we're redirected to login, our
                // previousState is already set in the authExpiredInterceptor. If we're going
                // to login directly, we don't want to be sent to some previous state anyway
                if (!$rootScope.redirected && $rootScope.previousStateName) {
                    $rootScope.previousStateName = fromState.name;
                    $rootScope.previousStateParams = fromParams;
                }

                // Update the language
                JhiLanguageService.getCurrent().then(function (language) {
                    $translate.fallbackLanguage('en_US');
                    $translate.use(language);
                });

            });

            $rootScope.$on('$destroy', function () {
                if (angular.isDefined(stateChangeStart) && stateChangeStart !== null) {
                    stateChangeStart();
                }
                if (angular.isDefined(stateChangeSuccess) && stateChangeSuccess !== null) {
                    stateChangeSuccess();
                }
            });

            function back() {
                // If previous state is 'activate' or do not exist go to 'home'
                if ($rootScope.previousStateName === 'activate' || $state.get($rootScope.previousStateName) === null) {
                    $state.go('home');
                } else {
                    $state.go($rootScope.previousStateName, $rootScope.previousStateParams);
                }
            }
        }
    }
})();
