(function() {
    'use strict';

    angular
        .module('atheer.user')
        .controller('UserInvitationController', UserInvitationController);

    /* @ngInject */
    function UserInvitationController($scope, $state, $q, $filter, $window, Setting, UserInvitation, ParseLinks, AlertService, pagingParams, paginationConstants, $mdDialog, ToastService, $stateParams) {
        var vm = this;

        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.pendingInvitations = null;
        vm.acceptedInvitations = null;
        vm.signupUrl = null;
        vm.invitationOnly = null;

        vm.query = {
            filter: '',
            limit: paginationConstants.itemsPerPage,
            order: '-id',
            page: 1
        };

        vm.selectedInvitation = null;

        vm.loadAll = function() {
            loadPending();
            loadAccepted();

            $q.all([
                    Setting.getCompanySettings().$promise,
                    Setting.getUserSignupSettings().$promise
                ])
                .then(function(results) {
                    setSignupUrl(results[0]);
                    setSignupEnabled(results[1]);
                });
        }

        function setSignupUrl(settings) {
            angular.forEach(settings, function(setting) {
                if (setting.category == 'company_info') {
                    vm.companySetting = setting;

                    angular.forEach(setting.fields, function(settingField) {
                        if (settingField.name == 'atheer_url') {
                            vm.signupUrl = settingField.value + '/signup';
                        }
                    });
                }
            });
        }

        function setSignupEnabled(settings) {
            angular.forEach(settings, function(setting) {
                if (setting.category == 'user_signup') {
                    vm.companySetting = setting;

                    angular.forEach(setting.fields, function(settingField) {
                        if (settingField.name == 'invitation_only') {
                            vm.invitationOnly = settingField.value;
                        }
                    });
                }
            });
        }

        function loadPending() {
            UserInvitation.query({
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort(),
                query: 'status==PENDING;type==ADMIN'
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.pendingQueryCount = headers('X-Total-Count');
                vm.pendingInvitations = data;
                vm.pendingPage = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadAccepted() {
            UserInvitation.query({
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort(),
                query: 'status==ACCEPTED;type==ADMIN'
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.acceptedQueryCount = headers('X-Total-Count');
                vm.acceptedInvitations = data;
                vm.acceptedPage = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        vm.transitionPending = function() {
            vm.promise = UserInvitation.query({
                page: vm.pendingPage - 1,
                size: paginationConstants.itemsPerPage,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                query: 'status==PENDING'
            }).$promise;

            vm.promise.then(function(data, headers) {
                vm.pendingInvitations = data;
            });
        }

        vm.transitionAccepted = function() {
            vm.promise = UserInvitation.query({
                page: vm.acceptedPage - 1,
                size: paginationConstants.itemsPerPage,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                query: 'status==ACCEPTED;type==ADMIN'
            }).$promise;

            vm.promise.then(function(data, headers) {
                vm.acceptedInvitations = data;
            });
        }

        vm.createObject = function() {
            $mdDialog.show({
                animation: true,
                templateUrl: 'modules/user/invitation/user-invitation-dialog.html',
                controller: 'UserInvitationDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg'
            }).then(function() {
                resetModal();
            });
        }

        vm.deleteObject = function(objectId) {
            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.userInvitation.revoke'))
                .ariaLabel($filter('translate')('atheer.userInvitation.revoke'))
                .ok($filter('translate')('entity.action.delete'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function() {
                UserInvitation.delete({
                    id: objectId
                }, function() {
                    resetModal();
                });
            });
        };

        vm.resend = function(objectId) {
            UserInvitation.resend({
                id: objectId
            }, function(result) {
                ToastService.displayToast($filter('triTranslate')('atheer.userInvitation.invitationSent'), 3000);
            });
        };

        vm.back = function() {
            $window.history.back();
        };

        function resetModal() {
            vm.loadAll();
        };
        vm.loadAll();
    }
})();
