(function() {
    'use strict';

    angular
        .module('atheer.system')
        .controller('ScormCloudAppDetailController', ScormCloudAppDetailController)

    /* @ngInject */
    function ScormCloudAppDetailController($mdDialog, $scope, $filter, $sce, ParseLinks, pagingParams, paginationConstants, $state, AlertService, $mdToast, $mdSidenav, App, appName, Experience, $location, $http, IntegrationApp) {
        var vm = this;

        vm.appModel = null;
        vm.appIdentiFier = null;
        vm.secretKey = null;

        vm.loadData = function() {
            App.getByName({
                id: appName
            }, function(result) {
                vm.appModel = result;
                angular.forEach(vm.appModel.settings, function(settingField) {
                    if (settingField.value != null) {
                        if (settingField.name == 'app_identifier') {
                            vm.appIdentiFier = settingField.value;
                        }
                        if (settingField.name == 'secret_key') {
                            vm.secretKey = settingField.value;
                        }
                    }
                });
            });
        }

        function setAppSettings() {
            setSettingField("app_identifier", vm.appIdentiFier);
            setSettingField("secret_key", vm.secretKey);
        }

        function setSettingField(fieldName, fieldValue) {
            var foundField = false;
            if(!vm.appModel.settings) {
                vm.appModel.settings = [];
            }

            angular.forEach(vm.appModel.settings, function(settingField) {
                if (settingField.name == fieldName) {
                    settingField.value = fieldValue;
                    foundField = true;
                }
            });

            if (!foundField) {
                var settingField = {
                    name: fieldName,
                    value: fieldValue,
                }
                vm.appModel.settings.push(settingField);
            }
        }

        vm.onSaveSuccess = function(result) {
            vm.appModel = result;
            vm.isSaving = false;
            AlertService.displayToast($mdToast);
        };

        vm.onSaveError = function() {
            vm.isSaving = false;
        };

        vm.save = function() {
            setAppSettings();
            App.update(vm.appModel, vm.onSaveSuccess, vm.onSaveError);
        };

        vm.enable = function() {
            vm.appModel.enabled = true;
            App.update(vm.appModel, vm.onSaveSuccess, vm.onSaveError);
        };

        vm.disable = function() {
            vm.appModel.enabled = false;
            App.update(vm.appModel, vm.onSaveSuccess, vm.onSaveError);
        };
        vm.loadData();
    }
})();
