(function() {
    'use strict';

    angular
        .module('atheer.workspace')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('workspace', {
                url: '/letsgo',
                abstract: true,
                views: {
                    'root': {
                        templateUrl: 'modules/workspace/workspace-account.tmpl.html'
                    }
                }
            })
            .state('workspace.signup', {
                url: '/signup',
                templateUrl: 'modules/workspace/workspace-signup.tmpl.html',
                controller: 'WorkspaceSignupController',
                controllerAs: 'vm'
            })
            .state('workspace.signup-confirm', {
                url: '/confirm',
                templateUrl: 'modules/workspace/workspace-signup-confirm.tmpl.html',
                controller: 'WorkspaceSignupConfirmController',
                controllerAs: 'vm',
                params: {
                    workspaceSignupData: null
                }
            })
            .state('workspace.setup-company', {
                url: '/setup-company',
                templateUrl: 'modules/workspace/workspace-setup-company.tmpl.html',
                controller: 'WorkspaceSetupCompanyController',
                controllerAs: 'vm',
                params: {
                    workspaceSignupData: null
                }
            })
            .state('workspace.setup-url', {
                url: '/setup-url',
                templateUrl: 'modules/workspace/workspace-setup-url.tmpl.html',
                controller: 'WorkspaceSetupURLController',
                controllerAs: 'vm',
                params: {
                    workspaceSignupData: null
                }
            })
            .state('workspace.setup-user-info', {
                url: '/setup-user',
                templateUrl: 'modules/workspace/workspace-setup-user-info.tmpl.html',
                controller: 'WorkspaceSetupUserInfoController',
                controllerAs: 'vm',
                params: {
                    workspaceSignupData: null
                }
            })
            .state('storm.admin-workspaces', {
                url: '/workspaces?page&sort&search',
                views: {
                    '@storm': {
                        templateUrl: 'modules/workspace/workspaces.html',
                        controller: 'WorkspaceController',
                        controllerAs: 'vm',
                    },
                    'sideMenu@storm': {
                        templateUrl: 'modules/workspace/workspace-menu.tmpl.html',
                        controller: 'WorkspaceMenuController',
                        controllerAs: 'vm',
                    }
                },
                data: {
                    pageTitle: 'atheer.workspace.home.title',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: false,
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }]
                }
            })
            .state('storm.admin-workspace-detail', {
                url: '/workspace/:id',
                abstract: true,
                views: {
                    '@storm': {
                        templateUrl: 'modules/workspace/workspace-detail.tmpl.html',
                        controller: 'WorkspaceDetailController',
                        controllerAs: 'vm',
                    }
                },
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: false
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    id: function($stateParams) {
                        return $stateParams.id;
                    }
                }
            })
            .state('storm.admin-workspace-detail.info', {
                url: '/info',
                templateUrl: 'modules/workspace/workspace-detail-info.tmpl.html',
                controller: 'WorkspaceDetailInfoController',
                controllerAs: 'vm',
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: false
                    }
                }
            })
            .state('storm.admin-workspace-detail.features', {
                url: '/features',
                templateUrl: 'modules/workspace/workspace-detail-features.tmpl.html',
                controller: 'WorkspaceDetailFeaturesController',
                controllerAs: 'vm',
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: false
                    }
                }
            })
            .state('storm.admin-workspace-detail.insights', {
                url: '/insights',
                abstract: true,
                views: {
                    '@storm': {
                        templateUrl: 'modules/workspace/workspace-detail-insights.tmpl.html',
                        controller: 'WorkspaceDetailInsightsController',
                        controllerAs: 'vm',
                    },
                    'toolbar@storm': {
                        templateUrl: 'modules/workspace/workspace-detail-insights-toolbar.tmpl.html',
                        controller: 'WorkspaceDetailInsightsToolbarController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    layout: {
                        pageTitle: 'Workspace',
                        contentClass: 'layout-column',
                        footer: false,
                        showSideNavMenu: false,
                        showToolbar: false
                    }
                },
                resolve: {
                    workspaceId: function($stateParams) {
                        return $stateParams.id;
                    }
                }
            })
            .state('storm.admin-workspace-detail.insights.dashboard', {
                url: '/workspace-summary',
                views: {
                    '@storm': {
                        templateUrl: 'modules/insights/insights-summary.tmpl.html',
                        controller: 'InsightsSummaryController',
                        controllerAs: 'vm',
                    },
                    'toolbar@storm': {
                        templateUrl: 'modules/workspace/workspace-detail-insights-toolbar.tmpl.html',
                        controller: 'WorkspaceDetailInsightsToolbarController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    layout: {
                        pageTitle: 'Dashboard',
                        showSideNavMenu: false,
                        showToolbar: true,
                    }
                },
            })
            .state('storm.admin-workspace-detail.insights.conversations', {
                url: '/conversations',
                views: {
                    '@storm': {
                        templateUrl: 'modules/insights/insights-conversation.tmpl.html',
                        controller: 'InsightsConversationController',
                        controllerAs: 'vm',
                    },
                    'toolbar@storm': {
                        templateUrl: 'modules/workspace/workspace-detail-insights-toolbar.tmpl.html',
                        controller: 'WorkspaceDetailInsightsToolbarController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    layout: {
                        pageTitle: 'Conversations',
                        showSideNavMenu: true,
                        showToolbar: true
                    }
                }
            })
            .state('storm.admin-workspace-detail.insights.contents', {
                url: '/content',
                views: {
                    '@storm': {
                        templateUrl: 'modules/insights/insights-content.tmpl.html',
                        controller: 'InsightsContentController',
                        controllerAs: 'vm',
                    },
                    'toolbar@storm': {
                        templateUrl: 'modules/workspace/workspace-detail-insights-toolbar.tmpl.html',
                        controller: 'WorkspaceDetailInsightsToolbarController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    layout: {
                        pageTitle: 'Content',
                        showSideNavMenu: true,
                        showToolbar: true
                    }
                }
            })
            .state('storm.admin-workspace-detail.insights.sessions', {
                url: '/sessions',
                views: {
                    '@storm': {
                        templateUrl: 'modules/insights/insights-session.tmpl.html',
                        controller: 'InsightsSessionController',
                        controllerAs: 'vm',
                    },
                    'toolbar@storm': {
                        templateUrl: 'modules/workspace/workspace-detail-insights-toolbar.tmpl.html',
                        controller: 'WorkspaceDetailInsightsToolbarController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    layout: {
                        pageTitle: 'AiRSessions',
                        showSideNavMenu: true,
                        showToolbar: true
                    }
                }
            })
            .state('storm.admin-workspace-detail.insights.airforms', {
                url: '/airforms',
                views: {
                    '@storm': {
                        templateUrl: 'modules/insights/insights-airform.tmpl.html',
                        controller: 'InsightsAirformController',
                        controllerAs: 'vm',
                    },
                    'toolbar@storm': {
                        templateUrl: 'modules/workspace/workspace-detail-insights-toolbar.tmpl.html',
                        controller: 'WorkspaceDetailInsightsToolbarController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    layout: {
                        pageTitle: 'AiRForms',
                        showSideNavMenu: true,
                        showToolbar: true
                    }
                }
            })
            .state('storm.admin-workspace-detail.insights.smartflows', {
                url: '/smartflows',
                views: {
                    '@storm': {
                        templateUrl: 'modules/insights/insights-smartflow.tmpl.html',
                        controller: 'InsightsSmartflowController',
                        controllerAs: 'vm',
                    },
                    'toolbar@storm': {
                        templateUrl: 'modules/workspace/workspace-detail-insights-toolbar.tmpl.html',
                        controller: 'WorkspaceDetailInsightsToolbarController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    layout: {
                        pageTitle: 'SmartFlows',
                        showSideNavMenu: true,
                        showToolbar: true
                    }
                }
            })
            .state('storm.admin-workspace-detail.insights.jobs', {
                url: '/jobs',
                views: {
                    '@storm': {
                        templateUrl: 'modules/insights/insights-job.tmpl.html',
                        controller: 'InsightsJobController',
                        controllerAs: 'vm',
                    },
                    'toolbar@storm': {
                        templateUrl: 'modules/workspace/workspace-detail-insights-toolbar.tmpl.html',
                        controller: 'WorkspaceDetailInsightsToolbarController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    layout: {
                        pageTitle: 'Jobs',
                        showSideNavMenu: true,
                        showToolbar: true
                    }
                }
            })
            .state('storm.admin-workspace-detail.insights.users', {
                url: '/users',
                views: {
                    '@storm': {
                        templateUrl: 'modules/insights/insights-user.tmpl.html',
                        controller: 'InsightsUserController',
                        controllerAs: 'vm',
                    },
                    'toolbar@storm': {
                        templateUrl: 'modules/workspace/workspace-detail-insights-toolbar.tmpl.html',
                        controller: 'WorkspaceDetailInsightsToolbarController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    layout: {
                        pageTitle: 'Users',
                        showSideNavMenu: true,
                        showToolbar: true
                    }
                }
            })
            .state('storm.admin-workspace-detail.adv-insights', {
                url: '/adv-insights',
                templateUrl: 'modules/workspace/workspace-detail-adv-insights.tmpl.html',
                controller: 'WorkspaceDetailAdvInsightsController',
                controllerAs: 'vm',
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: false
                    }
                }
            });
    }
})();
