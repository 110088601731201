(function() {
    'use strict';

    angular
        .module('atheer.session')
        .controller('sessionNotifierController', sessionNotifierController);

    /* @ngInject */
    function sessionNotifierController($scope, $state, $q, $filter, notification, $mdDialog, Session, $mdToast, $stateParams, $cookies, Guest, Auth, deviceDetector, Principal, $location, $rootScope, $window, ToastService) {
        var vm = this;

        vm.loadingData = false;
        vm.instantSession = false;
        vm.scheduleSession = false;
        vm.groupSession = false;
        vm.notification = notification;
        vm.workspace = null;

        vm.close = function() {
            $mdToast
                .hide()
                .then(function() {});
        };

        vm.closeSession = function() {
            $mdToast
                .hide()
                .then(function() {});
        };

        vm.joinAirSession = function() {
            if($cookies.get('isGuest')) {
                $rootScope.$broadcast('show-loader', true);
                Guest.validateWorkspaceCode(vm.sessionObj.code, 'guest').then(function(result) {
                    vm.workspace = result.data.api_url.split('.')[0].split(':')[1].split('/')[2];
                    var host = $location.host();
                    //for localhost
                    //host = (host == 'localhost') ? 'demo.atheer.dev' : host;
                    if (host.split('.')[0] !== vm.workspace) {
                        var hostUrl = result.data.api_url;
                        var firstName = $cookies.get('firstName') ? $cookies.get('firstName') : deviceDetector.browser;
                        var lastName = $cookies.get('lastName') ? $cookies.get('lastName') : deviceDetector.os;

                        var url = hostUrl.replace('api', 'airsessions/player/' + vm.sessionObj.session_no + '/guest/' + vm.sessionObj.code + '/firstName/' + firstName + '/lastName/' + lastName);
                        $window.location.href = url;
                        return;
                    }
                    var token = Guest.getBearerToken();
                    Auth.loginWithToken(token).then(function (response) {
                        updateGuestUser();
                    });
                }).catch(function(err) {
                    displayToast('notification.session.sessionExpired');
                });
            } else {
                vm.joinSession();
            }
        };

        vm.joinSession = function() {
            Session.get({
                id: vm.sessionObj.session_no,
                for_launch: true,
                is_session_no: true,
                add_user: true,
                is_web: true
            }, onSuccess, onError);

            function onSuccess(result) {
                if($cookies.get('isGuest')) {
                    $rootScope.$broadcast('show-loader', false);
                }
                if (!result.id && result.result && result.result === "NOT_FOUND") {
                    displayToast("notification.session.sessionAlreadyExpired");
                    vm.loadingData = false;

                } else {
                    vm.sessionModel = result;
                    vm.loadingData = false;
                    $mdDialog.cancel();
                    $mdToast.hide();
                    $state.go('storm.sessions.player', {
                        'session_no': vm.sessionObj.session_no
                    });
                }
            }

            function onError(error) {
                if($cookies.get('isGuest')) {
                    $rootScope.$broadcast('show-loader', false);
                }
                displayToast("notification.session.sessionAlreadyExpired")
            }
        }

        vm.loadData = function() {
            vm.loadingData = true;
            if (notification.type == "instant_session_invite") {
                vm.instantSession = true;
            }
            if (notification.type == "scheduled_session_invite") {
                vm.scheduleSession = true;
            }
            if (notification.type == "group_session_invite") {
                vm.groupSession = true;
            }

            vm.sessionObj = JSON.parse(vm.notification.body);
            //vm.joinSession();

            vm.loadingData = false;
        }

        function updateGuestUser() {
            var data = {
                first_name: $cookies.get('firstName') ? $cookies.get('firstName') : deviceDetector.browser,
                last_name: $cookies.get('lastName') ? $cookies.get('lastName') : deviceDetector.os
            };
            var guestUserId = Guest.getGuestUserId();
            Guest.updateGuestUser(guestUserId, vm.workspace, data).then(function(response) {
                var host = $location.host();
                var domainParts = host.split('.');
                var parentDomain = domainParts[1] + '.' + domainParts[2];
                $cookies.put('firstName', data.first_name, { domain: parentDomain });
                $cookies.put('lastName', data.last_name, { domain: parentDomain });
                Principal.identity().then(function(user) {
                    getSessionDetails(guestUserId);
                });
            }).catch(function(error) {
                displayToast("notification.session.unableToUpdate")
            });
        };

        function getSessionDetails(guestUserId) {
            Guest.getSessionDetails(guestUserId, vm.sessionObj.session_no, vm.workspace).then(function(result) {
                vm.joinSession();
            }).catch(function(err) {
                displayToast("notification.session.sessionDoesntExists");
            });
        }

        function displayToast(message) {
            ToastService.displayToast($filter('triTranslate')(message), 3000);
        }

        vm.loadData();
    }
})();
