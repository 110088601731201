(function () {
    'use strict';

    angular
        .module('atheer.session')
        .directive('sessionAnnotationBuilder', sessionAnnotationBuilder);

    function sessionAnnotationBuilder() {
        var directive = {
            scope: {
                annotationData: '=',
                sessionId: '=',
                userId: '='
            },
            bindToController: true,
            controller: sessionAnnotationBuilderController,
            controllerAs: 'vm',
            templateUrl: 'modules/session/session-annotation-builder.tmpl.html',
            restrict: 'E'
        };
        return directive;
    };

    /* @ngInject */
    function sessionAnnotationBuilderController($rootScope, $scope, $element, $window, PubNubService, SessionAnnotationService) {
        var vm = this;

        var annotationToolEnum = {
            pencil: "PENCIL",
            arrow: "ARROW",
            text: "TEXT",
            undo: "UNDO",
            clear: "CLEAR"
        }
        var colorPrefix = '#';
        var canvasWidth = vm.annotationData.currentImageWidth;
        var canvasHeight = vm.annotationData.currentImageHeight;
        var imageWidth = vm.annotationData.originalImageWidth;
        var imageHeight = vm.annotationData.originalImageHeight;
        var textParam = {
            width: 200,
            size: 48,
            text: ''
        };
        var pencilUpdateData = [];

        var toolChangeListener = null;
        var generateAnnotationImageListener = null;
        var setBrushColorListener = null;
        var pubNubSessionListener = null;

        vm.selectedTool = annotationToolEnum.pencil;
        vm.isPencilUpdate = false;
        vm.isArrowUpdate = false;
        vm.annotationCanvas = null;
        vm.arrow = null;
        vm.arrowThickness = 2.5;
        vm.brushColor = 'FFB800';
        vm.brushWidth = 2.5;
        vm.showCanvas = false;
        vm.showLoader = true;
        vm.isAnnotationEditAllowed = vm.annotationData.allowAnnotationEdit;
        vm.isFreezeFrame = vm.annotationData.action === 'ANNOTATION_FREEZE_FRAME' ? true : false;
        vm.previousSelectedTool = null;

        function onLoad() {
            $window.addEventListener('resize', onResize);
            vm.annotationCanvas = new fabric.Canvas('annotation-canvas', { isDrawingMode: true });
            setDimentions(canvasWidth, canvasHeight);
            var scaleFactor = getScaleFactor();
            fabric.Object.prototype.selectable = false;
            vm.annotationCanvas.isDrawingMode = vm.annotationData.allowAnnotationEdit;
            vm.annotationCanvas.setZoom(1);
            vm.annotationCanvas.freeDrawingBrush.width = vm.brushWidth;
            vm.annotationCanvas.freeDrawingBrush.color = colorPrefix + vm.brushColor;
            vm.arrow = new Arrow(vm.annotationCanvas, vm.arrowThickness);

            var filePath = '/media/images/' + vm.annotationData.screenShot;
            fabric.Image.fromURL(filePath, function (img) {
                img.set({ width: img.width, height: img.height, scaleX: scaleFactor, scaleY: scaleFactor, originX: 'left', originY: 'top' });
                vm.annotationCanvas.setBackgroundImage(img, vm.annotationCanvas.renderAll.bind(vm.annotationCanvas));
                vm.showCanvas = true;
                vm.showLoader = false;
                $scope.$emit('image-loaded', vm.showCanvas);
                $scope.$apply();
            });

            if (vm.annotationData.allowAnnotationEdit) {
                vm.annotationCanvas.on('mouse:down', vm.startDraw);
                vm.annotationCanvas.on('mouse:move', vm.updateDraw);
                vm.annotationCanvas.on('mouse:up', vm.endDraw);
                vm.annotationCanvas.on('text:editing:exited', vm.onTextAdd);
            };

            toolChangeListener = $scope.$on('tool-change', vm.updateTool);
            generateAnnotationImageListener = $scope.$on('generate-annotation-image', generateAnnotationImage);
            setBrushColorListener = $scope.$on('set-brush-color', setBrushColor);
        };

        function updateAnnotationStatus(isSaved) {
            $scope.$broadcast('is-drawing', isSaved);
            $scope.$emit('save-annotation-image', !isSaved);
        }

        function setBrushColor(event, color) {
            vm.brushColor = color;
            vm.annotationCanvas.freeDrawingBrush.color = colorPrefix + vm.brushColor;
        }

        function generateAnnotationImage(event) {
            var image = vm.annotationCanvas.toDataURL({ format: 'jpeg', quality: 1 });
            SessionAnnotationService.setAnnotationImage(image);
            updateAnnotationStatus(true);
        };

        vm.startDraw = function (event) {
            var x = event.pointer.x;
            var y = event.pointer.y;
            var tx = SessionAnnotationService.getPercentage(x, vm.annotationCanvas.width);
            var ty = SessionAnnotationService.getPercentage(y, vm.annotationCanvas.height);
            var createdata = {
                x: tx,
                y: ty,
                color: vm.brushColor
            };
            var createMessage = {
                subject: 'SESSION',
                action: vm.isFreezeFrame ? 'ANNOTATION_FREEZE_FRAME' : 'ANNOTATION_CLASSIC',
                data: createdata
            };
            if (vm.annotationCanvas.isDrawingMode) {
                vm.isPencilUpdate = true;
                createMessage.type = 'PENCIL_CREATE';
            } else if (vm.selectedTool === annotationToolEnum.arrow) {
                vm.isArrowUpdate = true;
                createMessage.type = 'ARROW_CREATE';
                vm.arrow.onMouseDown(event);
            }
            publishMessage(createMessage);
            updateAnnotationStatus(false);
        };

        vm.updateDraw = function (event) {
            if (vm.isPencilUpdate || vm.isArrowUpdate) {
                var x = event.pointer.x;
                var y = event.pointer.y;
                var tx = SessionAnnotationService.getPercentage(x, vm.annotationCanvas.width);
                var ty = SessionAnnotationService.getPercentage(y, vm.annotationCanvas.height);
                var updateData = {
                    x: tx,
                    y: ty
                };
                var updateMessage = {
                    subject: 'SESSION',
                    action: vm.isFreezeFrame ? 'ANNOTATION_FREEZE_FRAME' : 'ANNOTATION_CLASSIC',
                };
                if (vm.annotationCanvas.isDrawingMode) {
                    if(pencilUpdateData.length == 100) {
                        updateMessage.type = 'PENCIL_UPDATE';
                        updateMessage.data = {
                            points: pencilUpdateData
                        };
                        pencilUpdateData = [];
                        publishMessage(updateMessage);
                    } else {
                        pencilUpdateData.push(updateData);
                    }
                } else if (vm.selectedTool === annotationToolEnum.arrow) {
                    vm.arrow.onMouseMove(event);
                }
            }
        };

        vm.endDraw = function (event) {
            if (vm.annotationCanvas.isDrawingMode) {
                vm.isPencilUpdate = false;
                var confirmMessage = {
                    subject: 'SESSION',
                    action: vm.isFreezeFrame ? 'ANNOTATION_FREEZE_FRAME' : 'ANNOTATION_CLASSIC',
                    type: 'PENCIL_CONFIRM'
                };
                confirmMessage.data = {
                    points: pencilUpdateData
                };
                pencilUpdateData = [];
                publishMessage(confirmMessage);
            } else if (vm.selectedTool === annotationToolEnum.arrow) {
                if (vm.isArrowUpdate) {
                    var x = event.pointer.x;
                    var y = event.pointer.y;
                    var tx = SessionAnnotationService.getPercentage(x, vm.annotationCanvas.width);
                    var ty = SessionAnnotationService.getPercentage(y, vm.annotationCanvas.height);
                    var updateData = {
                        x: tx,
                        y: ty
                    };
                    var updateMessage = {
                        subject: 'SESSION',
                        action: vm.isFreezeFrame ? 'ANNOTATION_FREEZE_FRAME' : 'ANNOTATION_CLASSIC',
                        data: updateData
                    };
                    vm.isArrowUpdate = false;
                    vm.arrow.onMouseMove(event);
                    vm.arrow.onMouseUp();
                    vm.annotationCanvas.discardActiveObject();
                    updateMessage.type = 'ARROW_UPDATE';
                    publishMessage(updateMessage);
                }
            } else {
                createText(event.pointer);
            }
        };

        vm.onTextAdd = function (event) {
            var objects = vm.annotationCanvas.getObjects();
            var textObj = objects[objects.length - 1];
            if (textObj.text !== '') {
                var textData = {
                    x1: SessionAnnotationService.getPercentage(textObj.aCoords.tl.x, vm.annotationCanvas.width),
                    x2: SessionAnnotationService.getPercentage(textObj.aCoords.br.x, vm.annotationCanvas.width),
                    y1: SessionAnnotationService.getPercentage(textObj.aCoords.tl.y, vm.annotationCanvas.height),
                    y2: SessionAnnotationService.getPercentage(textObj.aCoords.br.y, vm.annotationCanvas.height),
                    lines: textObj.textLines,
                    color: vm.brushColor
                };
                var textMessage = {
                    subject: 'SESSION',
                    action: vm.isFreezeFrame ? 'ANNOTATION_FREEZE_FRAME' : 'ANNOTATION_CLASSIC',
                    type: 'TEXT_CREATE',
                    data: textData
                };
                publishMessage(textMessage);
                updateAnnotationStatus(false);
            }
        };

        vm.updateTool = function (event, data) {
            endText();
            vm.previousSelectedTool = vm.selectedTool;
            vm.selectedTool = data.tool;
            if (data.tool === annotationToolEnum.pencil) {
                vm.annotationCanvas.isDrawingMode = true;
            } else if (data.tool === annotationToolEnum.arrow) {
                vm.annotationCanvas.isDrawingMode = false;
                vm.annotationCanvas.selection = false;
            } else if (data.tool === annotationToolEnum.undo) {
                vm.onUndoClick();
                vm.selectedTool = vm.previousSelectedTool;
            } else if (data.tool === annotationToolEnum.clear) {
                vm.onClearClick();
                vm.selectedTool = vm.previousSelectedTool;
            } else {
                vm.annotationCanvas.isDrawingMode = false;
            }
        }

        vm.onUndoClick = function () {
            var undoMessage = {
                subject: 'SESSION',
                action: vm.isFreezeFrame ? 'ANNOTATION_FREEZE_FRAME' : 'ANNOTATION_CLASSIC',
                type: 'UNDO'
            }
            undoLastStep();
            publishMessage(undoMessage);
            updateAnnotationStatus(false);
        };

        vm.onClearClick = function () {
            var clearMessage = {
                subject: 'SESSION',
                action: vm.isFreezeFrame ? 'ANNOTATION_FREEZE_FRAME' : 'ANNOTATION_CLASSIC',
                type: 'CLEAR'
            }
            clearCanvas();
            publishMessage(clearMessage);
            updateAnnotationStatus(false);
        };

        function clearCanvas() {
            if(vm.isPencilUpdate) {
                endPathDraw();
            }
            for (var i = vm.annotationCanvas._objects.length - 1; i >= 0; i--) {
                vm.annotationCanvas.remove(vm.annotationCanvas._objects[i]);
            }
        };

        function undoLastStep() {
            if(vm.isPencilUpdate) {
                endPathDraw();
            }
            endText();
            vm.annotationCanvas.discardActiveObject();
            var objects = vm.annotationCanvas.getObjects();
            vm.annotationCanvas.remove(objects[objects.length - 1]);
            vm.annotationCanvas.renderAll();
        };

        function createText(point) {
            endText();
            addText('', true, point, textParam.width, textParam.height, textParam.size);
        };

        function addText(text, isEditable, point, width, height, fontSize) {
            var textBox = new fabric.Textbox(text, {
                fontFamily: 'Roboto',
                left: point.x,
                top: point.y,
                fill: colorPrefix + vm.brushColor,
                fontSize: fontSize,
                textAlign: 'left',
                editable: isEditable,
                width: width,
                height: height,
                padding: 20
            });
            vm.annotationCanvas.add(textBox);
            if (isEditable) {
                vm.annotationCanvas.setActiveObject(textBox);
                textBox.enterEditing();
            } else {
                textBox.setCoords();
                vm.annotationCanvas.renderAll();
            }
        };

        function endText() {
            var obj = vm.annotationCanvas.getActiveObject();
            if (obj instanceof fabric.Textbox && obj.isEditing) {
                obj.exitEditing();
                vm.annotationCanvas.discardActiveObject();
                if (obj.text === '') {
                    var objects = vm.annotationCanvas.getObjects();
                    vm.annotationCanvas.remove(objects[objects.length - 1]);
                    vm.annotationCanvas.renderAll();
                }
            }
        };

        function publishMessage(message) {
            PubNubService.publish(vm.sessionId, message);
        };

        function cleanUp() {
            vm.annotationCanvas.off('mouse:down', vm.startDraw);
            vm.annotationCanvas.off('mouse:move', vm.updateDraw);
            vm.annotationCanvas.off('mouse:up', vm.endDraw);
            vm.annotationCanvas.off('text:editing:exited', vm.onTextAdd);
            toolChangeListener = null;
            generateAnnotationImageListener = null;
            setBrushColorListener = null;
        };

        function handleMessage(message) {
            if ((message.message.action === 'ANNOTATION_CLASSIC' || message.message.action === 'ANNOTATION_FREEZE_FRAME') && message.message.type === 'PENCIL_CREATE') {
                beginPathDraw(message.message.data);
            } else if ((message.message.action === 'ANNOTATION_CLASSIC' || message.message.action === 'ANNOTATION_FREEZE_FRAME') && message.message.type === 'PENCIL_UPDATE') {
                updatePathDraw(message.message.data);
            } else if ((message.message.action === 'ANNOTATION_CLASSIC' || message.message.action === 'ANNOTATION_FREEZE_FRAME') && message.message.type === 'PENCIL_CONFIRM') {
                endPathDraw(message.message.data);
            } else if ((message.message.action === 'ANNOTATION_CLASSIC' || message.message.action === 'ANNOTATION_FREEZE_FRAME') && message.message.type === 'ARROW_CREATE') {
                startArrow(message.message.data);
            } else if ((message.message.action === 'ANNOTATION_CLASSIC' || message.message.action === 'ANNOTATION_FREEZE_FRAME') && message.message.type === 'ARROW_UPDATE') {
                updateArrow(message.message.data);
            } else if ((message.message.action === 'ANNOTATION_CLASSIC' || message.message.action === 'ANNOTATION_FREEZE_FRAME') && message.message.type === 'TEXT_CREATE') {
                drawText(message.message.data);
            } else if ((message.message.action === 'ANNOTATION_CLASSIC' || message.message.action === 'ANNOTATION_FREEZE_FRAME') && message.message.type === 'UNDO') {
                undoLastStep();
            } else if ((message.message.action === 'ANNOTATION_CLASSIC' || message.message.action === 'ANNOTATION_FREEZE_FRAME') && message.message.type === 'CLEAR') {
                clearCanvas();
            }
        };

        function beginPathDraw(data) {
            vm.isPencilUpdate = true;
            vm.annotationCanvas.freeDrawingBrush.color = colorPrefix + data.color;
            var rX = data.x * vm.annotationCanvas.width;
            var rY = data.y * vm.annotationCanvas.height;

            var pointer = {
                x: rX,
                y: rY
            };
            var options = {
                e: { isPrimary: true },
                pointer: pointer
            };
            vm.annotationCanvas.freeDrawingBrush.onMouseDown(pointer, options);
        };


        function updatePathDraw(updateData) {
            angular.forEach(updateData.points, function(data){
                var rX = data.x * vm.annotationCanvas.width;
                var rY = data.y * vm.annotationCanvas.height;

                var pointer = {
                    x: rX,
                    y: rY
                };
                var options = {
                    e: { isPrimary: true },
                    pointer: pointer
                };
                vm.annotationCanvas.freeDrawingBrush.onMouseMove(pointer, options);
            });
        };

        function endPathDraw(data) {
            if(data && data.points.length > 0) {
                updatePathDraw(data);
            }
            vm.isPencilUpdate = false;
            var options = {
                e: { isPrimary: true },
                pointer: null
            };
            vm.annotationCanvas.freeDrawingBrush.onMouseUp(options);
        };

        function startArrow(data) {
            vm.annotationCanvas.freeDrawingBrush.color = colorPrefix + data.color;
            var rX = data.x * vm.annotationCanvas.width;
            var rY = data.y * vm.annotationCanvas.height;
            var event = {
                pointer: {
                    x: rX,
                    y: rY
                }
            };
            vm.arrow.onMouseDown(event);
        };

        function updateArrow(data) {
            var rX = data.x * vm.annotationCanvas.width;
            var rY = data.y * vm.annotationCanvas.height;
            var event = {
                pointer: {
                    x: rX,
                    y: rY
                }
            };
            vm.arrow.onMouseMove(event);
        };

        function drawText(data) {
            vm.brushColor = data.color;
            var rX1 = data.x1 * vm.annotationCanvas.width;
            var rY1 = data.y1 * vm.annotationCanvas.height;
            var rX2 = data.x2 * vm.annotationCanvas.width;
            var rY2 = data.y2 * vm.annotationCanvas.height;
            var width = rX2 - rX1;
            var height = rY2 - rY1;
            var content = data.lines.join('\n');
            var point = { x: rX1, y: rY1 };
            var xScaleFactor;
            if (imageWidth > imageHeight) {
                xScaleFactor = vm.annotationCanvas.width / imageWidth;
            } else {
                xScaleFactor = imageWidth / imageHeight;
            }
            var size = textParam.size * xScaleFactor;
            addText(content, false, point, width, height, size);
        };

        function getScaleFactor() {
            var scaleFactor;
            if (imageWidth > imageHeight) {
                scaleFactor = vm.annotationCanvas.width / imageWidth;
            } else {
                scaleFactor = vm.annotationCanvas.height / imageHeight;
            }
            return scaleFactor;
        };

        function setDimentions(width, height) {
            var aspectRatio;
            var finalHeight;
            var finalWidth;
            if (imageWidth > imageHeight) {
                aspectRatio = imageWidth / imageHeight;
                finalWidth = width;
                finalHeight = width / aspectRatio;
                if (height < finalHeight) {
                    finalHeight = height;
                    finalWidth = height * aspectRatio;
                }
            } else {
                aspectRatio = imageHeight / imageWidth;
                finalHeight = height;
                finalWidth = height / aspectRatio;
                if (width < finalWidth) {
                    finalWidth = width;
                    finalHeight = width * aspectRatio;
                }
            }
            vm.annotationCanvas.setWidth(finalWidth);
            vm.annotationCanvas.setHeight(finalHeight);
        }

        function onResize() {
            var canvas = vm.annotationCanvas;
            var newWidth = $element.width();
            var newHeight = $element.height();
            var oldWidth = canvas.width;
            var oldHeight = canvas.height;
            if (newWidth !== oldWidth || newHeight !== oldHeight) {
                setDimentions(newWidth, newHeight);
                var scaleFactor = getScaleFactor();
                var objects = vm.annotationCanvas.getObjects();
                for (var i in objects) {
                    objects[i].scaleX = objects[i].scaleX * (vm.annotationCanvas.width / oldWidth);
                    objects[i].scaleY = objects[i].scaleY * (vm.annotationCanvas.height / oldHeight);
                    objects[i].left = objects[i].left * (vm.annotationCanvas.width / oldWidth);
                    objects[i].top = objects[i].top * (vm.annotationCanvas.height / oldHeight);
                    objects[i].setCoords();
                }
                var image = vm.annotationCanvas.backgroundImage;
                if (image) {
                    image.scaleX = scaleFactor;
                    image.scaleY = scaleFactor;
                }
                vm.annotationCanvas.renderAll();
                vm.annotationCanvas.calcOffset();
            }
        };

        $scope.$on('$destroy', function() {
            console.log("Studio-Session-ANNOTATION-CLASSIC-Destroy");
            pubNubSessionListener = null;
            cleanUp();
        });

        pubNubSessionListener = $scope.$on('sessionMessagePubNub', function(event, message) {
            $scope.$apply(function() {
                if (message.message.subject == 'SESSION' && message.publisher != vm.userId) {
                    console.log("Studio-Session-Pubnub-Message", message.publisher, vm.userId, message);
                    handleMessage(message);
                };
            });
        });

        onLoad();
    }
})();
