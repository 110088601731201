(function() {
    'use strict';

    angular
        .module('atheer.user')
        .controller('ProfilePreferncesController', ProfilePreferncesController);

    /* @ngInject */
    function ProfilePreferncesController($scope, $state, $q, Auth, Principal, User, JhiLanguageService, $translate, $mdToast, $filter, id) {
        var vm = this;

        vm.doNotMatch = null;
        vm.error = null;
        vm.success = null;
        vm.user = null;

        vm.userId = id == 'me' ? Principal.getUserId() : id;

        vm.loadData = function() {
            vm.loadingData = true;

            $q.all([
                User.getById({
                    id: vm.userId
                }).$promise
            ]).then(function(data) {
                vm.user = data[0];
                vm.loadingData = false;

            }).catch(function(data) {}).finally(function() {});

            JhiLanguageService.getAll().then(function(languages) {
                vm.languages = languages;
            });
        }

        vm.settingsGroups = [{
            name: 'atheer.preferences.accountSettings',
            settings: [{
                title: 'atheer.preferences.showMyLocation',
                icon: 'zmdi zmdi-pin',
                enabled: true
            }, {
                title: 'atheer.preferences.showMyAvatar',
                icon: 'zmdi zmdi-face',
                enabled: false
            }, {
                title: 'atheer.preferences.sendMeNotifications',
                icon: 'zmdi zmdi-notifications-active',
                enabled: true
            }]
        }, {
            name: 'atheer.preferences.conversationSettings',
            settings: [{
                title: 'atheer.preferences.showMyUsername',
                icon: 'zmdi zmdi-account',
                enabled: true
            }, {
                title: 'atheer.preferences.makeMyProfilePublic',
                icon: 'zmdi zmdi-account-box',
                enabled: false
            }, {
                title: 'atheer.preferences.allowCloudBackups',
                icon: 'zmdi zmdi-cloud-upload',
                enabled: true
            }]
        }];

        vm.loadData();
    }
})();
