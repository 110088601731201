(function () {
    'use strict';
    angular
        .module('atheer.services')
        .factory('Guest', Guest);

    /* @ngInject */
    function Guest($http) {
        var env;
        var host = window.location.href.split('/')[2];
        var bearerToken = null;
        var userId = null;
        if(host.startsWith('localhost')){
            env = 'dev';
        } else {
            env = host.split('.')[2];
        }
        var parentDomain = host.split('.')[1]; 
        var service = {
            createGuestUser: createGuestUser,
            validateWorkspaceCode: validateWorkspaceCode,
            updateGuestUser: updateGuestUser,
            getSessionDetails: getSessionDetails,
            setBearerToken: setBearerToken,
            getBearerToken: getBearerToken,
            setGuestUserId: setGuestUserId,
            getGuestUserId: getGuestUserId
        };
        return service;

        function createGuestUser(data, workspace) {
            return $http.post('https://' + workspace + '.' + parentDomain + '.' + env + '/api/users/guest', data);
        };

        function validateWorkspaceCode(code, workspace) {
            return $http.get('https://' + workspace + '.' + parentDomain + '.'  + env + '/api/system/settings/workspace/info/code/' + code);
        };

        function updateGuestUser(userId, workspace, data) {
            return $http({
                method: 'POST',
                url: 'https://' + workspace + '.' + parentDomain + '.'  + env + '/api/users/guest/update/' + userId,
                data: data
            });
        };

        function getSessionDetails(userId, roomNo, workspace) {
            return $http({
                method: 'GET',
                url: 'https://' + workspace + '.' + parentDomain + '.' + env + '/api/sessions/guest/session/' + userId + '/' + roomNo
            });
        };

        function setBearerToken(token) {
            bearerToken = token;
        };

        function getBearerToken() {
            return bearerToken;
        };

        function setGuestUserId(id) {
            userId = id;
        };

        function getGuestUserId() {
            return userId;
        };
    }
})();