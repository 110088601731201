(function() {
    'use strict';

    angular
        .module('atheer.session')
        .controller('SessionPlayerUnsupportedBrowserController', SessionPlayerUnsupportedBrowserController);

    /* @ngInject */
    function SessionPlayerUnsupportedBrowserController($scope, $state, $q, $timeout, $filter, Report, sessionModel, $mdDialog, $mdToast, $stateParams) {
        var vm = this;

        vm.validPasscode = false;
        vm.sessionModel = sessionModel;

        vm.close = function() {
            $mdDialog.cancel();
            $state.go('storm.sessions.upcoming');
        }

        vm.downloadApp = function() {
            $mdDialog.cancel();
            $state.go('storm.lens-apps');
        }
    }
})();
