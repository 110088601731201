(function () {
    'use strict';

    angular
        .module('atheer.system')
        .controller('SettingFeatureController', SettingFeatureController)

    /* @ngInject */
    function SettingFeatureController($scope, $state, $q, $http, Setting, $filter, $window, Principal, ToastService) {
        var vm = this;

        vm.loadingData = false;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.featureSettings = null;
        vm.features = {};
        vm.isAdmin = Principal.isWorkspaceAdmin();
        vm.homePageFeatureValue = false;
        vm.originalPublicAccessFeatureValue = false;
        vm.publicAccessFeatureValue = false;

        function setFeaturesModel(featureSettings) {
            angular.forEach(featureSettings, function (feature) {
                if (feature.category == 'features') {
                    vm.featureSettings = feature;
                    angular.forEach(feature.fields, function (settingField) {
                        if (settingField.value == 'true') {
                            settingField.value = true;
                        } else {
                            settingField.value = false;
                        }

                        if (settingField.group_name === 'system' && settingField.name === "public_access") {
                            vm.originalPublicAccessFeatureValue = settingField.value;
                            console.log("setting vm.originalPublicAccessFeatureValue", vm.originalPublicAccessFeatureValue)
                        }
                        var feature = vm.features[settingField.group_name];
                        if (feature) {
                            feature.settings.push(settingField);
                        } else {
                            var feature = {
                                name: settingField.group_name,
                                label: settingField.group_label,
                                editable: settingField.group_editable,
                                settings: []
                            }
                            feature.settings.push(settingField);
                            vm.features[settingField.group_name] = feature;
                        }
                    });
                }
            });
        }

        function setFeatureSettings() {
            vm.featureSettings.fields = [];
            for (var key in vm.features) {
                var feature = vm.features[key];
                angular.forEach(feature.settings, function (settingField) {
                    if (settingField.group_name == 'system' && settingField.name == 'enable_configurable_home_page') {
                        vm.homePageFeatureValue = settingField.value;
                    }

                    if (settingField.group_name == 'system' && settingField.name == 'public_access') {
                        vm.publicAccessFeatureValue = settingField.value;
                        console.log("saving vm.publicAccessFeatureValue", vm.publicAccessFeatureValue)
                    }

                    vm.featureSettings.fields.push(settingField);
                });
            }
        }

        vm.save = function () {
            setFeatureSettings();
            Setting.update(vm.featureSettings, function (result) {
                vm.featureSettings = result;
                $http.post('api/homepage/feature-flag/' + vm.homePageFeatureValue).then(function (data) { }, function (error) { });
                console.log("API", vm.originalPublicAccessFeatureValue, vm.publicAccessFeatureValue)
                if (vm.publicAccessFeatureValue !== vm.originalPublicAccessFeatureValue) {
                    $http.post('api/public-access/feature-flag/change/' + vm.publicAccessFeatureValue).then(function (data) {
                        vm.originalPublicAccessFeatureValue = vm.publicAccessFeatureValue;
                    }, function (error) { });
                }
                ToastService.displayToastWithAction($filter('triTranslate')("atheer.setting.featureSuccess"),
                    $filter('triTranslate')("entity.action.refresh"), 'md-accent', '', function (response) {
                        if (response == 'ok') {
                            $window.location.reload();
                        }
                    });
            });
        }

        vm.loadData = function () {
            vm.loadingData = true;

            $q.all([
                Setting.getFeaturesSettings().$promise
            ])
                .then(function (results) {
                    setFeaturesModel(results[0]);
                    vm.loadingData = false;
                });
        };
        vm.loadData();
    }
})();
