(function() {
    'use strict';

    angular
        .module('atheer.topic')
        .controller('TopicInsightScanHistoryController', TopicInsightScanHistoryController)

    /* @ngInject */
    function TopicInsightScanHistoryController($scope, $q, $state, $filter, id, Report, Experience, ParseLinks, pagingParams, paginationConstants) {
        var vm = this;

        vm.loadingData = false;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.reOrder = reOrder;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.predicate = pagingParams.predicate;
        vm.transition = transition;
        vm.pageSize = paginationConstants.itemsPerPage;

        function reOrder(order) {
            vm.predicate = order;
            vm.transition();
        };

        function transition() {
            vm.promise = Experience.getTopicScans({
                    query: "object_id==" + id,
                    page: vm.page - 1,
                    size: paginationConstants.itemsPerPage,
                    sort: sort(),
                    user_info: true
                }).$promise;

            function sort() {
                var result = [getSortOrder()];

                return result;
            }

            vm.promise.then(function(data, headers) {
                vm.queryCount = vm.totalItems;
                vm.viewData = data;
            });
        };

        function getSortOrder() {
            if (vm.predicate.startsWith('id')) {
                return vm.predicate + ',' + 'desc';
            }

            if (vm.predicate.startsWith('-')) {
                return vm.predicate.substr(1) + ',' + 'desc';
            } else {
                return vm.predicate + ',' + 'asc';
            }
        };

        vm.loadData = function() {
           vm.loadingData = true;
           Experience.getTopicScans({
                    query: "object_id==" + id,
                    page: pagingParams.page - 1,
                    size: paginationConstants.itemsPerPage,
                    sort: sort(),
                    user_info: true
                }, onSuccess, onError);

            function sort() {
                var result = [getSortOrder()];

                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.viewData = data;
                vm.page = pagingParams.page;
                vm.loadingData = false;
            }

            function onError(error) {
                AlertService.error(error.data.message);
                vm.loadingData = false;
            }
        };

        vm.loadData();
    }
})();
