(function() {
    'use strict';

    angular
        .module('atheer.smartFlow')
        .controller('SmartFlowAddUsersController', SmartFlowAddUsersController)

    /* @ngInject */
    function SmartFlowAddUsersController($scope, $injector, $state, $mdToast, $element, $filter, $q, Experience, $mdDialog, smartFlow) {
        var vm = this;

        vm.users = [];
        vm.assignProgress = 'idle';

        vm.close = function() {
            $mdDialog.cancel();
        }

        vm.submit = function() {
            vm.assignProgress = 'started';

            var userSmartFlows = [];

            angular.forEach(vm.users, function(user) {
                var userSmartFlow = {
                    user_id: user.id,
                    object: smartFlow,
                    status: 'STARTED',
                    started_on: new Date()
                }
                userSmartFlows.push(userSmartFlow);
            });

            Experience.bulkUserSmartFlows(userSmartFlows, function(result) {
                vm.assignProgress = 'complete';
                vm.assignSuccess = result.success;
            });
        };
    }
})();
