(function () {
    'use strict';

    angular
        .module('atheer')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('deeplink', {
                abstract: true,
                views: {
                    'root': {
                        templateUrl: 'modules/public/public.tmpl.html'
                    }
                }
            })
            .state('deeplink.sessiondeeplink', {
                url: '/deeplink/sessions/:guid',
                templateUrl: 'modules/system/deeplink/session-deeplink.tmpl.html',
                controller: 'SessionDeeplinkController',
                controllerAs: 'vm',
                resolve: {
                    id: function ($stateParams) {
                        return $stateParams.guid;
                    }
                }
            })
            .state('deeplink.commonDeepLink', {
                url: '/deeplink/:configType/:id',
                templateUrl: 'modules/system/deeplink/deeplink.tmpl.html',
                controller: 'DeeplinkController',
                controllerAs: 'vm',
                resolve: {
                    id: function ($stateParams) {
                        return $stateParams.id;
                    },
                    configType: function ($stateParams) {
                        return $stateParams.configType
                    }
                }
            })
    }
})();
