(function() {
    'use strict';

    angular
        .module('atheer.workspace')
        .controller('WorkspaceSetupURLController', WorkspaceSetupURLController);

    /* @ngInject */
    function WorkspaceSetupURLController($window, $location, $translate, $timeout, WorkspaceSignup, $scope, $state, $stateParams, $mdToast, $http, $filter, triSettings) {
        var vm = this;

        var host = $location.host();

        vm.domain = host.slice(4, host.length);
        vm.isDomainAvailable = false;
        vm.isCheckingDomainAvailability = false;

        vm.error = null;
        vm.success = false;
        vm.activating = false;

        vm.triSettings = triSettings;

        vm.workspaceSignupData = $stateParams.workspaceSignupData;

        vm.workspace = {
            name: null
        }

        if (!vm.workspaceSignupData) {
            $state.go('workspace.signup');
        }

        vm.checkIsDomainAvailable = function(name) {
            vm.isCheckingDomainAvailability = true;

            WorkspaceSignup.checkName({
                name: vm.workspace.name
            }, function(result) {
                vm.isCheckingDomainAvailability = false;
                vm.workspaceInfo = result;
                vm.isDomainAvailable = vm.workspaceInfo.available;
            });
        }

        vm.activate = function() {
            vm.workspaceSignupData.team_url = vm.workspace.name;
            vm.activating = true;
            WorkspaceSignup.activate(vm.workspaceSignupData, vm.onSaveSuccess, vm.onSaveError);
        };

        vm.onSaveSuccess = function(result) {
            vm.workspaceSignupData = result;
            var successToken = vm.workspaceSignupData.success_token;
            var finalUrl = vm.workspaceSignupData.final_url + "?" + "activation_token=" + successToken;
            $window.location.href = finalUrl;
        };

        vm.onSaveError = function() {
            vm.error = true;
            vm.activating = false;
        };
    }
})();
