(function() {
    'use strict';

    angular
        .module('atheer.workspace')
        .controller('WorkspaceDetailFeaturesController', WorkspaceDetailFeaturesController)

    /* @ngInject */
    function WorkspaceDetailFeaturesController($mdDialog, $scope, $filter, $sce, ParseLinks, pagingParams, paginationConstants, $state, $q, AlertService, $mdToast, $mdSidenav, Workspace, id, $timeout, Setting) {
        var vm = this;

        vm.loadingData = false;
        vm.isSaving = false;
        vm.isCreating = false;
        vm.errorSaving = false;

        vm.featureSettings = null;
        vm.features = {};

        vm.loadingData = true;
        Workspace.get({
            id: id
        }, function(result) {
            vm.workspace = result;
            loadFeaturesData();
        });

        vm.onFeatureSaveSuccess = function(result) {
            vm.featureSettings = result;
            AlertService.displayToast($mdToast);
        };

        vm.onSaveError = function() {
            vm.isSaving = false;
            vm.isCreating = false;
            vm.errorSaving = true;
        };

        vm.save = function() {
            vm.isSaving = true;
            setFeatureSettings();
            Workspace.updateFeatures({
              id: id
            }, vm.featureSettings, vm.onFeatureSaveSuccess, vm.onSaveError);
        };

        vm.goToWorkspaces = function() {
            $state.go("storm.admin-workspaces");
        };

        function setFeaturesModel(featureSettings) {
            angular.forEach(featureSettings, function(feature) {
                if (feature.category == 'features') {
                    vm.featureSettings = feature;
                    angular.forEach(feature.fields, function(settingField) {
                        if (settingField.value == 'true') {
                            settingField.value = true;
                        } else {
                            settingField.value = false;
                        }
                        var feature = vm.features[settingField.group_name];
                        if (feature) {
                            feature.settings.push(settingField);
                        } else {
                            var feature = {
                                name: settingField.group_name,
                                label: settingField.group_label,
                                settings: []
                            }
                            feature.settings.push(settingField);
                            vm.features[settingField.group_name] = feature;
                        }
                    });
                }
            });
        }

        function setFeatureSettings() {
            vm.featureSettings.fields = [];
            for (var key in vm.features) {
                var feature = vm.features[key];
                angular.forEach(feature.settings, function(settingField) {
                    vm.featureSettings.fields.push(settingField);
                });
            }
        }

        function loadFeaturesData() {
            vm.loadingData = true;
            $q.all([
                    Workspace.getFeatures({
                      id: id
                    }).$promise
                ])
                .then(function(results) {
                    setFeaturesModel(results[0]);
                    vm.loadingData = false;
                });
        };
    }
})();
