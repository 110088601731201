(function () {
    'use strict';

    angular
        .module('atheer.smartFlow')
        .controller('JobFlowMoleculesPropertiesController', JobFlowMoleculesPropertiesController)

    /* @ngInject */
    function JobFlowMoleculesPropertiesController($mdDialog, dataModelProperties, type) {
        var vm = this;

        vm.loadingData = true;
        vm.tableQuery = {
            limit: 6,
            page: 1,
            order: 'name'
        };

        vm.selectedObject;

        vm.close = function () {
            $mdDialog.cancel();
        };

        vm.submit = function () {
            $mdDialog.hide(vm.selectedObject);
        };

        vm.onObjectSelection = function (data) {
            vm.selectedObject = data;
        }

        function init() {
            vm.dataModelProperties = angular.copy(dataModelProperties);

            vm.title = type;
            vm.loadingData = false;
        }

        init();
    }
})();
