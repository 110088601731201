(function() {
    'use strict';

    angular
        .module('atheer.topic')
        .controller('TopicToolbarController', TopicToolbarController);

    /* @ngInject */
    function TopicToolbarController($rootScope, $scope, $state, $mdDialog, Topic, ParseLinks, AlertService, pagingParams, paginationConstants, $mdToast, $stateParams, $filter, $translate, Feature, ToastService) {
        var vm = this;

        vm.refreshAll = refreshAll;
        vm.pageTitleKey = $state.$current.data.pageTitle;

        vm.query = {
            filter: ''
        };

        if ( getSavedFilters() ) {
            vm.query = getSavedFilters();
        }

        vm.filter = {
            options: {
                debounce: 500
            }
        };

        vm.removeFilter = removeFilter;
        vm.filter.show = vm.query.filter.length > 0 ? true : false;
        vm.createObject = createObject;

        vm.permissions = {
            create: 'manage-topic-content',
            update: 'manage-topic-content',
            delete: 'manage-topic-content',
            manage: 'manage-topic-content'
        };

        vm.toggleFilter = function() {
            vm.filter.show = !vm.filter.show;
        };

        function refreshAll() {
            saveFilters(vm.query);
            $scope.$emit('refreshTopics', vm.query.filter);
        };

        function removeFilter() {
            vm.filter.show = false;
            vm.query.filter = '';
            saveFilters(vm.query);
            $scope.$emit('refreshTopics', vm.query.filter);
        };

        function createObject() {
            $state.go('storm.admin-topic-detail', {
                'topicType': 'STATIC',
                'parentTopic': null
            });
        };

        function saveFilters(filterData) {
            sessionStorage.setItem('objectHeader', JSON.stringify(filterData));
        };
    
        function getSavedFilters() {
            return JSON.parse(sessionStorage.getItem('objectHeader'));
        };
    }
})();
