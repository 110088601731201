(function () {
    'use strict';

    angular
        .module('atheer')
        .factory('dictionaryHandler', dictionaryHandler);

    /* @ngInject */
    function dictionaryHandler($q, $http, Setting) {

        'use strict';

        return function (options) {

            if (!options || (!angular.isArray(options.files) && (!angular.isString(options.prefix) || !angular.isString(options.suffix)))) {
                throw new Error('Couldn\'t load static files, no files and prefix or suffix specified!');
            }

            if (!options.files) {
                options.files = [{
                    prefix: options.prefix,
                    suffix: options.suffix
                }];
            }

            var load = function (file) {
                if (!file || (!angular.isString(file.prefix) || !angular.isString(file.suffix))) {
                    throw new Error('Couldn\'t load static file, no prefix or suffix specified!');
                }

                var fileUrl = [
                    file.prefix,
                    options.key,
                    file.suffix
                ].join('');

                if (angular.isObject(options.fileMap) && options.fileMap[fileUrl]) {
                    fileUrl = options.fileMap[fileUrl];
                }

                return $http(angular.extend({
                    url: fileUrl,
                    method: 'GET'
                }, options.$http))
                    .then(function (result) {
                        return result.data;
                    }, function () {
                        return $q.reject(options.key);
                    });
            };

            var promises = [],
                length = options.files.length;

            for (var i = 0; i < length; i++) {
                promises.push(load({
                    prefix: options.files[i].prefix,
                    key: options.key,
                    suffix: options.files[i].suffix
                }));
            }

            promises.push(Setting.getLabelDictionarySettings().$promise);

            function processStrings(resourceStrings, dictionaryKeys, dictionaryValues) {
                Object.keys(resourceStrings).forEach(function (key) {
                    if (typeof resourceStrings[key] === 'object') {
                        processStrings(resourceStrings[key], dictionaryKeys, dictionaryValues)
                    } else {
                        if (resourceStrings[key] && resourceStrings[key].indexOf("{{") != -1) {
                            var replacedString = resourceStrings[key];

                            for (var i = dictionaryKeys.length - 1; i >= 0; i--) {
                                var finalAns = replacedString.replaceAll(dictionaryKeys[i], dictionaryValues[i]);
                                replacedString = finalAns;
                            }
                            resourceStrings[key] = replacedString;
                        }
                    }
                })
                return resourceStrings;
            }

            function getDictionarySettings(dictionaryModel) {
                var dictionarySettings = {};
                var dictionary = {};

                angular.forEach(dictionaryModel, function (dictionaryModel) {
                    if (dictionaryModel.category == 'label-dictionary') {
                        dictionarySettings = dictionaryModel;

                        angular.forEach(dictionaryModel.fields, function (dictionaryField) {
                            if (options.key == dictionaryField.group_name) {
                                var dictionaryFieldSetting = dictionary[dictionaryField.group_name];
                                if (dictionaryFieldSetting) {
                                    dictionaryFieldSetting.settings.push(dictionaryField);
                                } else {
                                    var dictionaryFieldSetting = {
                                        name: dictionaryField.group_name,
                                        label: dictionaryField.group_label,
                                        editable: dictionaryField.group_editable,
                                        settings: []
                                    }
                                    dictionaryFieldSetting.settings.push(dictionaryField);
                                    dictionary[dictionaryField.group_name] = dictionaryFieldSetting;
                                }
                            }
                        });
                    }
                });
                return dictionary;
            };

            return $q.all(promises)
                .then(function (data) {
                    var mergedData = {};
                    var dictionarySettings = getDictionarySettings(data[1]);
                    var dictionaryKeys = [];
                    var dictionaryValues = [];

                    for (var key in data[0]) {
                        mergedData[key] = data[0][key];
                    }

                    if (dictionarySettings[options.key] && dictionarySettings[options.key] != null) {
                        angular.forEach(dictionarySettings[options.key].settings, function (setting) {
                            dictionaryKeys.push("{{" + setting.name + "}}");
                            dictionaryValues.push(setting.value);
                        });

                        return processStrings(mergedData, dictionaryKeys, dictionaryValues);
                    } else {
                        return mergedData;
                    }
                });
        };
    }
})();
