(function() {
    'use strict';

    angular
        .module('atheer')
        .config(alertServiceConfig);

    /* @ngInject */    
    function alertServiceConfig(AlertServiceProvider) {
        // set below to true to make alerts look like toast
        AlertServiceProvider.showAsToast(true);
    }
})();
