(function () {
    'use strict';

    angular
        .module('atheer.user')

        /*
         Languages codes are ISO_639-1 codes, see http://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
         They are written in English to avoid character encoding issues (not a perfect solution)
         */
        .constant('LANGUAGES', [
            'en_US',
            'en_GB',
            'fr_FR',
            'de_DE',
            'el_GR',
            'it_IT',
            'ja_JP',
            'pt_BR',
            'es_CL',
            'zh_CN',
            'zh_TW',
            'nl_NL',
            'da_DK',
            'no_NO',
            'pl_PL',
            'ru_RU',
            'sv_SE',
            'tr_TR'
            // atheer-needle-i18n-language-constant - Atheer will add/remove languages in this array
        ]
    );
})();
