(function() {
    'use strict';

    angular
        .module('atheer.content')
        .controller('SmartPageDetailController', SmartPageDetailController)
        .controller('SmartPageViewController', SmartPageViewController)
        .controller('SmartPageFeedbackController', SmartPageFeedbackController)

    /* @ngInject */
    function SmartPageFeedbackController($scope, $mdBottomSheet) {

    }

    /* @ngInject */
    function SmartPageViewController($scope, $controller, $mdBottomSheet) {

        var basectrl = $controller('BaseContentViewController as vm', {
            $scope: $scope
        });
        angular.extend(this, basectrl);

        $scope.showFeedback = function(firstTime) {
            $mdBottomSheet.show({
                templateUrl: 'modules/content/smart-page-feedback.tmpl.html',
                clickOutsideToClose: false,
                escapeToClose: false,
                controller: 'SmartPageFeedbackController',
                scope: $scope,
                preserveScope: true,
                parent: '#fp-content-view-' + $scope.data.id + '-' + $scope.context,
                disableParentScroll: true,
                locals: {
                    firstTime: firstTime
                }
            });
        }

    }

    /* @ngInject */
    function SmartPageDetailController($scope, $controller, id, $mdDialog, Feature, ContentAction, $filter, $stateParams, $state) {
        this.isFroalaOptionsSet = false;
        var basectrl = $controller('BaseContentDetailController as vm', {
            $scope: $scope,
            id: id,
            content_type: "smart-page"
        });
        angular.extend(this, basectrl);

        var vm = this;

        vm.showSettings = true;

        vm.previewContent = function() {
            ContentAction.previewContent(vm.contentModel);
        };

        vm.froalaOptions = {
            pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'entities', 'file', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'lineBreaker', 'link', 'lists', 'paragraphFormat', 'paragraphStyle', 'quote', 'save', 'table', 'url', 'video'],
            toolbarButtons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', '|', 'color', 'emoticons', 'inlineStyle', 'paragraphStyle', '|', 'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', '-', 'quote', 'insertHR', 'insertLink', 'insertImage', 'insertVideo', 'insertFile', 'insertTable', '|', 'undo', 'redo', 'clearFormatting', 'selectAll', 'html', 'applyFormat', 'removeFormat', 'fullscreen', 'print', 'help'],
            toolbarButtonsMD: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', '|', 'color', 'emoticons', 'inlineStyle', 'paragraphStyle', '|', 'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', '-', 'quote', 'insertHR', 'insertLink', 'insertImage', 'insertVideo', 'insertFile', 'insertTable', '|', 'undo', 'redo', 'clearFormatting', 'selectAll', 'html', 'applyFormat', 'removeFormat', 'fullscreen', 'print', 'help'],
            toolbarButtonsSM: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', '|', 'color', 'emoticons', 'inlineStyle', 'paragraphStyle', '|', 'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', '-', 'quote', 'insertHR', 'insertLink', 'insertImage', 'insertVideo', 'insertFile', 'insertTable', '|', 'undo', 'redo', 'clearFormatting', 'selectAll', 'html', 'applyFormat', 'removeFormat', 'fullscreen', 'print', 'help'],
            toolbarButtonsXS: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', '|', 'color', 'emoticons', 'inlineStyle', 'paragraphStyle', '|', 'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', '-', 'quote', 'insertHR', 'insertLink', 'insertImage', 'insertVideo', 'insertFile', 'insertTable', '|', 'undo', 'redo', 'clearFormatting', 'selectAll', 'html', 'applyFormat', 'removeFormat', 'fullscreen', 'print', 'help'],
            charCounterMax: -1,
            heightMin: 625,
            heightMax: 625,
            pastePlain: true,
            placeholderText: $filter('translate')('global.enterTextHerePlaceholder')
        }

        if(Feature.isActive('feature_system_adv_image_edit')) {
            vm.froalaOptions.pluginsEnabled.push('imageAviary');
        }
        vm.isFroalaOptionsSet = true;

        vm.goBack = function() {
            if($stateParams.isDeeplink) {
                window.history.go(-2);
            } else {
                $state.go('storm.admin-contents');
            }
        };
    }
})();
