(function() {
    'use strict';

    angular
        .module('atheer.system')
        .controller('LensAppSupportDevicesController', LensAppSupportDevicesController);

    /* @ngInject */
    function LensAppSupportDevicesController ($scope, $state, $q, filter, Principal,  $mdDialog, $mdToast, $stateParams) {
        var vm = this;
        vm.lens = filter;
        vm.type = vm.lens.type;
        vm.loadingData = true;

        vm.load = function() {
            vm.loadingData = false;
        };

        vm.close = function() {
            $mdDialog.cancel();
        }

        vm.load();
    }
})();
