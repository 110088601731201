(function () {

    'use strict';

    angular
        .module('atheer.smartFlow')
        .service('JobFlowBuilder', JobFlowBuilder);

    function JobFlowBuilder($http) {
        var jobStencil = {};
        jobStencil.config = {};
        jobStencil.config.stencil = {};
        jobStencil.config.stencil.shapes = {};
        joint.shapes.smartflow = {};

        joint.shapes.smartflow.Base = joint.shapes.basic.Generic.extend({
            markup: '<g class="rotatable"><g class="scalable"><image/></g><text/></g>',
            defaults: joint.util.deepSupplement({
                type: 'smartflow.Base',
                size: {
                    width: 50,
                    height: 50
                },
                attrs: {
                    image: {
                        width: 45,
                        height: 45
                    },
                    text: {
                        text: '',
                        'text-anchor': 'middle',
                        'ref-x': .5,
                        'ref-dy': 15,
                        'font-family': 'RobotoDraft, Roboto, Helvetica Neue, sans-serif',
                        'font-weight': 'Normal',
                        'font-size': 14,
                        display: '',
                        stroke: '#000',
                        'stroke-width': 0,
                        'fill': '#222138'
                    }
                }
            }, joint.shapes.basic.Generic.prototype.defaults),
            updateSetting: function (setting) {
                //do nothing
                this.prop('setting', setting);
                var title = this.prop('setting/title');
                if (title) {
                    this.updateTitle(title);
                    setting.isConfigured = true;
                } else {
                    // this.updateTitle(title);
                }
            },
            validate: function () {
                //do nothing
                var setting = this.prop('setting');
                if (Object.keys(setting.properties).length == 0) {
                    return false;
                } else {
                    return true;
                }
            },
            updateTitle: function (title) {
                var wrapText = joint.util.breakText(title, {
                    width: 100
                });
                var lines = wrapText.split(/\r\n|\r|\n/).length;
                this.attr('text', { text: wrapText, 'data-tooltip': title });
            }
        });

        var service = {
            getCategories: getCategories,
            buildStencil: buildStencil
        }

        return service;

        function getCategories() {
            return $http.get('/i18n/en/jobflow-builder.json');
        }

        function buildMoleculeObject(molecule) {
            var molObj = {};
            molObj.type = (molecule.type) ? molecule.type : '';
            molObj.typeId = (molecule.typeId) ? molecule.typeId : '';
            molObj.pageId = (molecule.pageId) ? molecule.pageId : '';
            molObj.attrs = {};
            molObj.attrs.image = {};
            molObj.attrs.text = {};
            molObj.attrs.image['xlink:href'] = (molecule.icon) ? molecule.icon : '';
            molObj.attrs.text.text = (molecule.text) ? molecule.text : '';
            molObj.active = (molecule.isActive) ? molecule.isActive : null;
            molObj.setting = {};
            molObj.setting.properties = {};
            molObj.slices = (molecule.type == 'smartflow.End') ? null : {};
            molObj.isAllowedTitleChange = (molecule.isAllowedTitleChange) ? molecule.isAllowedTitleChange : false;
            molObj.isValidationRequired = (molecule.isValidationRequired) ? molecule.isValidationRequired : false;
            molObj.smartFlowStencilType = (molecule.smartFlowStencilType) ? molecule.smartFlowStencilType : '';
            if (molecule.data) {
                molObj.setting.data = molecule.data;
            }
            if (molecule.isConfigured) {
                molObj.setting.isConfigured = molecule.isConfigured;
            }

            if (molecule.title) {
                molObj.setting.title = molecule.title;
            }
            if (molecule.objectType) {
                molObj.setting.properties.objectType = molecule.objectType;
            }

            if (molObj.slices != null && molecule.color) {
                molObj.slices.color = molecule.color;
            }

            return molObj;
        }

        function extendMoleculeObject(group, molecule, moleculeObject) {
            joint.shapes.smartflow[molecule.name] = joint.shapes.smartflow[group.name].extend({
                defaults: joint.util.deepSupplement(moleculeObject, joint.shapes.smartflow.Base.prototype.defaults)
            });

            if (moleculeObject.isAllowedTitleChange) {
                joint.shapes.smartflow[molecule.name].updateSetting = function (setting) {
                    this.prop('setting', setting);
                    var title = this.prop('setting/title');
                    if (title) {
                        this.updateTitle(title);
                        setting.isConfigured = true;
                    } else {
                        this.updateTitle(title);
                    }
                }
            }

            if (moleculeObject.isValidationRequired) {
                joint.shapes.smartflow[molecule.name].validate = function () {
                    var setting = this.prop('setting');
                    if (setting.data.length == 0) {
                        return false;
                    } else {
                        return true;
                    }
                }
            }
        }

        function buildStencil(data, type) {
            jobStencil.config.stencil.groups = {};

            jobStencil.getPaperCell = function (cell) {
                return cell.clone();
            }

            angular.forEach(data.groups, function (group) {
                if (type === "JOB_EVENT" && group.id === "handlers") {
                    return;
                }

                var molecules = group.molecules;

                joint.shapes.smartflow[group.name] = joint.shapes.smartflow.Base.extend({
                    defaults: joint.util.deepSupplement({}, joint.shapes.smartflow.Base.defaults)
                });

                jobStencil.config.stencil.groups[group.id] = {
                    index: group.index,
                    label: group.label
                };

                jobStencil.config.stencil.shapes[group.id] = [];

                angular.forEach(molecules, function (molecule) {
                    if (molecule.type === 'smartflow.JobScope' && type !== "JOB_EVENT") {
                        return;
                    }

                    var moleculeType = {
                        type: molecule.type
                    };
                    jobStencil.config.stencil.shapes[group.id].push(moleculeType);

                    var moleculeObject = buildMoleculeObject(molecule);
                    extendMoleculeObject(group, molecule, moleculeObject);
                });
            });
            return jobStencil;
        }
    }
})();
