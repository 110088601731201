(function() {
    'use strict';

    angular
        .module('atheer.content')
        .controller('PodcastDetailController', PodcastDetailController)
        .controller('PodcastViewController', PodcastViewController)
        .controller('PodcastFeedbackController', PodcastFeedbackController)

    /* @ngInject */
    function PodcastFeedbackController($scope, $mdBottomSheet) {

    }

    /* @ngInject */
    function PodcastViewController($scope, $controller, $mdBottomSheet) {

        var basectrl = $controller('BaseContentViewController as vm', {
            $scope: $scope
        });
        angular.extend(this, basectrl);

        $scope.player_id = 'atheer-player-' + Math.floor(Math.random() * (100000 - 1000 + 1)) + 1000;

        $scope.podcastSettings = null;

        if(!$scope.data.data.external_podcast) {
          $scope.podcastSettings = {
              playlist: [{
                  file: '/media/audios/' + $scope.data.data.source_media.properties.file_name
              }],
              height: 40,
              autostart: false,
              skin: {
                  name: "atheer"
              }
          }
        };

        if($scope.data.tags === undefined) {
            $scope.data.tags = [];
        };

        $scope.showFeedback = function(firstTime) {
            $mdBottomSheet.show({
                templateUrl: 'modules/content/podcast-feedback.tmpl.html',
                clickOutsideToClose: false,
                escapeToClose: false,
                controller: 'PodcastFeedbackController',
                scope: $scope,
                preserveScope: true,
                parent: '#fp-content-view-' + $scope.data.id + '-' + $scope.context,
                disableParentScroll: true,
                locals: {
                    firstTime: firstTime
                }
            });
        }
    }

    /* @ngInject */
    function PodcastDetailController($scope, $timeout, $controller, id, Content, $sce, poller, $resource) {

        var basectrl = $controller('BaseContentDetailController as vm', {
            $scope: $scope,
            id: id,
            content_type: "podcast"
        });
        angular.extend(this, basectrl);

        var vm = this;

        vm.uploadStatus = 'idle';
        vm.processStatus = 'IDLE';
        vm.extractStatus = 'IDLE';
        vm.isExternalDocument = false;
        vm.fileMaxSize = 500 * 1024 * 1024;

        vm.createPodcast = function(mediaId, responseObj) {
            vm.processStatus = 'UPLOADED';

            vm.contentModel.title = responseObj.name;
            vm.contentModel.description = responseObj.description;
            vm.contentModel.data.source_media = responseObj;
            vm.contentModel.data.podcast_status = 'UPLOADED';
            vm.contentModel.external_id = responseObj.external_id;

            vm.save();

            vm.checkPodcastStatus();
        }

        $scope.$watch('vm.contentModel', function(newVal) {
            if (!vm.isNew()) {
                vm.checkPodcastStatus();
            }
        });

        vm.checkPodcastStatus = function() {
            if ((vm.contentModel.data.podcast_status == 'UPLOADED' || vm.contentModel.data.podcast_status == 'PROCESSING') && vm.contentModel.id) {
                vm.processStatus = vm.contentModel.data.podcast_status;

                // Get poller.
                var podcastStatusPoller = poller.get(Content, {
                    action: 'get',
                    delay: 3000,
                    argumentsArray: [{
                        id: vm.contentModel.id
                    }],
                    smart: true
                });

                podcastStatusPoller.promise.then(null, null, function(result) {
                    if (result.data.podcast_status == 'READY') {
                        poller.stopAll();
                        vm.processStatus = 'COMPLETE';
                        $timeout(function() {
                            vm.processStatus = 'READY';
                            vm.contentModel = result;
                        }, 2000);
                    }
                    if (result.data.podcast_status == 'FAILED') {
                        poller.stopAll();
                        vm.processStatus = 'FAILED';
                        vm.contentModel = result;
                    }
                });
            } else {
                vm.processStatus = vm.contentModel.data.podcast_status;
            }
        }

        vm.useEmbedCode = function() {
            vm.isExternalPodcast = true;
            vm.extractStatus = 'EXTRACTING';

            $timeout(function() {
                vm.extractEmbedData();
            }, 1000);
        }

        vm.extractEmbedData = function() {
            vm.contentMetaData.show_tags = true;

            vm.contentModel.title = "Untitled";
            vm.contentModel.description = null;
            vm.contentModel.data.external_podcast = true;
            vm.contentModel.tags = null;
            vm.contentModel.data.use_custom_thumbnail = true;
            vm.contentModel.data.podcast_status = "READY";
            vm.contentModel.data.thumbnail_url = null;
            //strip width and height to make it responsive
            vm.contentModel.data.embed_code = vm.contentModel.data.embed_code.replace(/width=\"[0-9]+\"/g, '').replace(/height=\"[0-9]+\"/g, '');

            vm.extractStatus = 'COMPLETE';

            $timeout(function() {
                vm.save();
                vm.extractStatus = 'READY';
            }, 1000);
        }

        vm.disableEmbedSubmit = function() {
            if(vm.contentModel.data.embed_code == null || vm.contentModel.data.embed_code.length <= 0) {
                return true;
            } else {
                return false;
            }
        }
    }
})();
