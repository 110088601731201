(function () {
    'use strict';

    angular
        .module('atheer.smartFlow')
        .service('SmartFlowBuilder', SmartFlowBuilder);

    /* @ngInject */
    function SmartFlowBuilder($mdDialog, $filter, Feature, JobFlowBuilder, LocalResourceService, ToastService) {

        var paper;
        var graph;
        var paperScroller;
        var navigator;
        var stencil;
        var snapLines;
        var selection;
        var selectionView;
        var clipboard;
        var commandManager;
        var validator;
        var smartFlowDataModel;
        var smartFlowData;
        var isCellSelected = false;
        var typeId;
        var cellView;
        var halo = null;
        var inAction = false;
        var scheduler;
        var jobEnd;
        var jobFlowStencil;

        var hasTriggerCell = false;
        var triggerInfo = {
            trigger_type: null,
            audiences: null,
            user_field_condition: null
        };

        var audienceTrigger = new joint.shapes.atheer.TeamTrigger({
            position: {
                x: 100,
                y: 100
            }
        });

        var endSmartFlow = new joint.shapes.atheer.End({
            position: {
                x: 700,
                y: 100
            }
        });

        var gridSize = 10;
        var showSnaplines = true;
        var zoomLevel = 100;

        var isJobFlow;
        var isModified = false;
        var category = '';
        var datamapperDatamodel = [];

        var service = {
            init: init,
            initPreview: initPreview,
            initInsights: initInsights,
            loadCanvasData: loadCanvasData,
            getCanvasData: getCanvasData,
            getCellMetaData: getCellMetaData,
            getTriggerInfo: getTriggerInfo,
            setTriggerInfo: setTriggerInfo,
            setGridSize: setGridSize,
            centerAndFit: centerAndFit,
            showAnalyticView: showAnalyticView,
            undo: undo,
            redo: redo,
            destroy: destroy,
            validate: validate,
            getDataModelId: getDataModelId,
            getAirFormId: getAirFormId,
            getSmartFlowStatus: getSmartFlowStatus,
            setSmartFlowStatus: setSmartFlowStatus,
            setSmartFlowCategory: setSmartFlowCategory,
            getSmartFlowCategory: getSmartFlowCategory,
            setDatamapperModel: setDatamapperModel,
            getDatamapperModel: getDatamapperModel,
            getSmartFlowData: getSmartFlowData,
            setSmartFlowData: setSmartFlowData,
            getJobUpdateStatus: getJobUpdateStatus
        }
        return service;

        function init(element, canvasData, triggerData, type, isJob) {
            joint.dia.ElementView.prototype.getStrokeBBox = function () {
                return this.model.getBBox();
            };

            isJobFlow = isJob;

            if (isJobFlow) {
                LocalResourceService.get({ fileName: 'jobflow-builder.json' }, function (sections) {
                    jobFlowStencil = JobFlowBuilder.buildStencil(sections, type);
                    scheduler = new joint.shapes.smartflow.Start({
                        position: {
                            x: 100,
                            y: 100
                        }
                    });
                    jobEnd = new joint.shapes.smartflow.End({
                        position: {
                            x: 700,
                            y: 100
                        }
                    });
                    initSmartFlow(element, canvasData, triggerData, type);
                });
            } else {
                initSmartFlow(element, canvasData, triggerData, type);
            }
        }

        function initSmartFlow(element, canvasData, triggerData, type) {
            initPaper(element);
            initStencil(element, type);
            initNavigator(element);
            initInteraction(element);
            initClipBoard(element);
            initCommandManager(element);
            initValidator(element);
            initToolTips(element);

            //load graph data
            loadCanvasData(canvasData);
            setTriggerInfo(triggerData);
        }

        function initPreview(element, canvasData, isJob, type) {
            isJobFlow = isJob;
            if (isJobFlow) {
                LocalResourceService.get({ fileName: 'jobflow-builder.json' }, function (sections) {
                    jobFlowStencil = JobFlowBuilder.buildStencil(sections, type);
                    scheduler = new joint.shapes.smartflow.Start({
                        position: {
                            x: 100,
                            y: 100
                        }
                    });
                    jobEnd = new joint.shapes.smartflow.End({
                        position: {
                            x: 700,
                            y: 100
                        }
                    });
                    initSmartFlowPreview(element, canvasData);
                });
            } else {
                initSmartFlowPreview(element, canvasData);
            }
        }

        function initSmartFlowPreview(element, canvasData) {
            initPaper(element);
            initPreviewInteraction(element);
            initNavigator(element);

            //load Graph Data
            loadCanvasData(canvasData);
        }

        function initInsights(element) {
            initPaper(element);
            initNavigator(element);
        }

        function destroy() {
            paper = null;
            graph = null;
            paperScroller = null;
            navigator = null;
            stencil = null;
            snapLines = null;
            selection = null;
            selectionView = null;
            clipboard = null;
            commandManager = null;
            smartFlowDataModel = null;
            typeId = null;
            cellView = null;
        }

        function initPaper(element) {
            var CustomPaper = joint.dia.Paper.extend({
                events: function () {
                    return _.extend({}, joint.dia.Paper.prototype.events, {
                        'mouseenter .joint-cell': 'cellMouseenter',
                        'mouseleave .joint-cell': 'cellMouseleave'
                    });
                },
                cellMouseenter: function (evt) {
                    evt = joint.util.normalizeEvent(evt);
                    var view = this.findView(evt.target);
                    if (view) {
                        if (this.guard(evt, view)) return;
                        view.mouseenter(evt);
                    }
                },
                cellMouseleave: function (evt) {
                    evt = joint.util.normalizeEvent(evt);
                    var view = this.findView(evt.target);
                    if (view) {
                        if (this.guard(evt, view)) return;
                        view.mouseleave(evt);
                    }
                }
            });

            joint.dia.CellView.prototype.mouseenter = function (evt) {
                this.notify('cell:mouseenter', evt);
            };
            joint.dia.CellView.prototype.mouseleave = function (evt) {
                this.notify('cell:mouseleave', evt);
            };

            graph = new joint.dia.Graph;

            paper = new CustomPaper({
                width: $('.paper-container').width() - 25,
                height: $('.paper-container').height() - 25,
                gridSize: gridSize,
                drawGrid: true,
                model: graph,
                linkPinning: false
            });

            paperScroller = new joint.ui.PaperScroller({
                paper: paper,
                autoResizePaper: true,
                cursor: 'grab'
            });

            snapLines = new joint.ui.Snaplines({
                paper: paper
            });

            $('.paper-container', element).append(paperScroller.el);

            paperScroller.render().center();
        }

        // Create stencil.
        function initStencil(element, type) {

            var stencilShapes = {};
            var groups = {};
            var group_index = 1;

            var stencilType = isJobFlow ? jobFlowStencil : SmartFlowStencil;

            angular.forEach(stencilType.config.stencil.shapes,
                function (value, key) {
                    var shapesForGroup = $filter('filter')(value, function (shape) {
                        var isActiveStencil = true;
                        angular.forEach(shape.feature_permissions, function (permission) {
                            if (isActiveStencil) {
                                isActiveStencil = Feature.isActive(permission);
                            }
                        });
                        return isActiveStencil;
                    });

                    if (shapesForGroup.length != 0) {
                        stencilShapes[key] = shapesForGroup;
                        var groupMeta = stencilType.config.stencil.groups[key];
                        if (groupMeta) {
                            groupMeta.index = group_index;
                            groups[key] = groupMeta;
                        }
                    }
                });

            stencil = new joint.ui.Stencil({
                graph: graph,
                paper: paper,
                width: 240,
                snaplines: snapLines,
                dropAnimation: true,
                // Use default Grid Layout
                layout: {
                    columnWidth: 120,
                    columns: 2,
                    rowHeight: 90,
                    resizeToFit: false,
                    marginX: -10
                },
                dragEndClone: function (cell) {
                    return stencilType.getPaperCell(cell);
                },
                groups: groups
            });

            $('.stencil-container', element).append(stencil.render().el);

            if (type == 'ONE_TIME' || type == 'TIME_BASED') {
                //remove user profile field trigger
                var triggers = $filter('filter')(stencilShapes.triggers, {
                    type: '!atheer.UserFieldTrigger'
                });
                stencilShapes.triggers = triggers;
            }
            stencil.render().load(stencilShapes);
        }

        function initNavigator(element) {
            navigator = new joint.ui.Navigator({
                width: 250,
                height: 150,
                paperScroller: paperScroller,
                zoom: true,
                padding: 25,
                zoomOptions: {
                    max: 2,
                    min: 0.5
                }
            });

            $('.navigator-container').append(navigator.el);
            navigator.render();
        }


        function initPreviewInteraction(element) {
            selection = new Backbone.Collection;
            selectionView = new joint.ui.Selection({
                paper: paper,
                graph: graph,
                model: selection
            });

            paper.on('cell:mouseenter', function (cellView) {
                var cell = cellView.model;

                var shapeType = null;

                if (cell instanceof joint.shapes.atheer.Trigger) {
                    shapeType = 'trigger';
                } else if (cell instanceof joint.shapes.atheer.Activity) {
                    shapeType = 'activity';
                } else if (cell instanceof joint.shapes.atheer.Condition) {
                    shapeType = 'condition';
                } else if (cell instanceof joint.shapes.atheer.Flow) {
                    shapeType = 'flow';
                }

                if (cell.isElement()) {
                    if (!halo && !inAction) {
                        var slices = cell.get('slices');
                        var toolPosition2;

                        switch (shapeType) {
                            case 'trigger':
                                toolPosition2 = function (bbox) {
                                    return g.Ellipse.fromRect(bbox).intersectionWithLineFromCenterToPoint(bbox.topRight());
                                };
                                break;
                            case 'flow': // Pentagon
                                toolPosition2 = function (bbox) {
                                    return g.Line(bbox.topMiddle(), bbox.topMiddle().rotate(bbox.center(), -72)).midpoint();
                                };
                                break;
                            case 'condition': // Hexagon
                                toolPosition2 = function (bbox) {
                                    return g.Line(bbox.topRight(), bbox.topMiddle()).midpoint().offset(0, 10);
                                };
                                break;
                            case 'activity': //rectangle
                                toolPosition2 = function (bbox) {
                                    return bbox.topRight();
                                };
                                break;
                            default:
                                toolPosition2 = function (bbox) {
                                    return bbox.topRight();
                                };
                                break;
                        }

                        var tools = [{
                            color: 'grey',
                            position: toolPosition2,
                            action: function (model) {
                                showSettings(cellView, true);
                            }
                        }];

                        halo = joint.custom.Halo.open(cellView, {
                            previewMode: true,
                            tools: tools
                        }).on('linking:start', function () {
                            inAction = true;
                        }).on('linking:end', function () {
                            halo.remove();
                            halo = null;
                            inAction = false;
                        });
                        halo.$el.on('mouseleave', function (evt) {
                            if (evt.buttons === 0) {
                                halo.remove();
                                halo = null;
                            }
                        });
                    }
                }
            });

            paper.on('cell:mouseleave', function (cellView, evt) {
                if (cellView.model.isElement()) {
                    if (halo && !inAction && !$.contains(halo.el, evt.relatedTarget)) {
                        halo.remove();
                        halo = null;
                    }
                }
            });

            // Initiate selecting when the user grabs the blank area of the paper while the Shift key is pressed.
            // Otherwise, initiate paper pan.
            paper.on('blank:pointerdown', function (evt, x, y) {
                if (_.contains(KeyboardJS.activeKeys(), 'shift')) {
                    selectionView.startSelecting(evt, x, y);
                } else {
                    selectionView.cancelSelection();
                    paperScroller.startPanning(evt, x, y);
                }
            });

            paper.on('element:pointerdown', function (cellView, evt) {
                // Select an element if CTRL/Meta key is pressed while the element is clicked.
                if ((evt.ctrlKey || evt.metaKey)) {
                    selection.add(cellView.model);
                }
            });

            paper.on('element:pointerup', function (elementView, evt, x, y) {
                if (V(evt.target).hasClass('delete-icon')) {
                    elementView.model.remove();
                } else {
                    var cell = elementView.model;
                    var setting = cell.prop('setting');

                    if (!setting.isConfigured) {
                        if (!(cell instanceof joint.shapes.atheer.End
                            || (joint.shapes.smartflow.End && cell instanceof joint.shapes.smartflow.End))) {
                            showSettings(elementView, true);
                        }
                    }
                }
            });

            paper.on('cell:mouseover', function (cellView, evt) {
                // Select an element if CTRL/Meta key is pressed while the element is clicked.
                handleCellMouseOver(cellView);
            });

            paper.on('cell:mouseout', function (cellView, evt) {
                // Select an element if CTRL/Meta key is pressed while the element is clicked.
                handleCellMouseOut(cellView);
            });

            paper.on('cell:pointerup', function (cellView) {
                // We don't want a Halo for links.
                if (cellView.model instanceof joint.dia.Link) return;
            });


            selectionView.on('selection-box:pointerdown', function (cellView, evt) {
                // Unselect an element if the CTRL/Meta key is pressed while a selected element is clicked.
                if (evt.ctrlKey || evt.metaKey) {
                    selection.remove(cellView.model);
                }
            });

            KeyboardJS.on('delete, backspace', _.bind(function (evt) {
                if (!$.contains(evt.target, paper.el)) {
                    // remove selected elements from the paper only if the target is the paper
                    return;
                }

                commandManager.initBatchCommand();
                selection.invoke('remove');
                commandManager.storeBatchCommand();
                selectionView.cancelSelection();

                if (_.contains(KeyboardJS.activeKeys(), 'backspace') && !$(evt.target).is("input, textarea")) {
                    // Prevent Backspace from navigating back.
                    evt.preventDefault();
                }
            }));
        }

        function initInteraction(element) {
            selection = new Backbone.Collection;
            selectionView = new joint.ui.Selection({
                paper: paper,
                graph: graph,
                model: selection
            });

            paper.on('cell:mouseenter', function (cellView) {
                var cell = cellView.model;

                var shapeType = null;

                if (cell instanceof joint.shapes.atheer.Trigger) {
                    shapeType = 'trigger';
                } else if (cell instanceof joint.shapes.atheer.Activity) {
                    shapeType = 'activity';
                } else if (cell instanceof joint.shapes.atheer.Condition) {
                    shapeType = 'condition';
                } else if (cell instanceof joint.shapes.atheer.Flow) {
                    shapeType = 'flow';
                }

                if (cell.isElement()) {
                    if (!halo && !inAction) {
                        var slices = cell.get('slices');
                        var toolPosition1, toolPosition2;

                        switch (shapeType) {
                            case 'trigger':
                                toolPosition1 = function (bbox) {
                                    return g.Ellipse.fromRect(bbox).intersectionWithLineFromCenterToPoint(bbox.origin());
                                };
                                toolPosition2 = function (bbox) {
                                    return g.Ellipse.fromRect(bbox).intersectionWithLineFromCenterToPoint(bbox.topRight());
                                };
                                break;
                            case 'flow': // Pentagon
                                toolPosition1 = function (bbox) {
                                    return g.Line(bbox.topMiddle(), bbox.topMiddle().rotate(bbox.center(), 72)).midpoint();
                                };
                                toolPosition2 = function (bbox) {
                                    return g.Line(bbox.topMiddle(), bbox.topMiddle().rotate(bbox.center(), -72)).midpoint();
                                };
                                break;
                            case 'condition': // Hexagon
                                toolPosition1 = function (bbox) {
                                    return g.Line(bbox.origin(), bbox.topMiddle()).midpoint().offset(0, 10);
                                };
                                toolPosition2 = function (bbox) {
                                    return g.Line(bbox.topRight(), bbox.topMiddle()).midpoint().offset(0, 10);
                                };
                                break;
                            case 'activity': //rectangle
                                toolPosition1 = function (bbox) {
                                    return bbox.origin();
                                };
                                toolPosition2 = function (bbox) {
                                    return bbox.topRight();
                                };
                                break;
                            default:
                                toolPosition1 = function (bbox) {
                                    return bbox.origin();
                                };
                                toolPosition2 = function (bbox) {
                                    return bbox.topRight();
                                };
                                break;
                        }

                        var tools = [{
                            color: 'red',
                            position: toolPosition1,
                            action: function (model) {
                                model.remove({
                                    halo: this.cid
                                });
                            }
                        }, {
                            color: 'grey',
                            position: toolPosition2,
                            action: function (model) {
                                showSettings(cellView, false);
                            }
                        }];

                        if (cell instanceof joint.shapes.atheer.End ||
                            (joint.shapes.smartflow.End && cell instanceof joint.shapes.smartflow.End)
                            || (joint.shapes.smartflow.Trigger && cell instanceof joint.shapes.smartflow.Trigger && !(cell instanceof joint.shapes.smartflow.JobScope))
                            || (joint.shapes.smartflow.JobCreate && cell instanceof joint.shapes.smartflow.JobCreate)
                            || (joint.shapes.smartflow.JobUpdate && cell instanceof joint.shapes.smartflow.JobUpdate)) {
                            tools = [{
                                color: 'red',
                                position: toolPosition1,
                                action: function (model) {
                                    model.remove({
                                        halo: this.cid
                                    });
                                }
                            }];
                        }

                        halo = joint.custom.Halo.open(cellView, {
                            slices: _.map(slices, function (slice) {
                                var title = slice.title || '';
                                var color = slice.color || 'gray';
                                return _.defaults({
                                    link: createLink(color, title)
                                }, slice);
                            }),
                            tools: tools
                        }).on('linking:start', function () {
                            inAction = true;
                        }).on('linking:end', function () {
                            halo.remove();
                            halo = null;
                            inAction = false;
                        });
                        halo.$el.on('mouseleave', function (evt) {
                            if (evt.buttons === 0) {
                                halo.remove();
                                halo = null;
                            }
                        });
                    }
                }
            });

            paper.on('cell:mouseleave', function (cellView, evt) {
                if (cellView.model.isElement()) {
                    if (halo && !inAction && !$.contains(halo.el, evt.relatedTarget)) {
                        halo.remove();
                        halo = null;
                    }
                }
            });

            // Initiate selecting when the user grabs the blank area of the paper while the Shift key is pressed.
            // Otherwise, initiate paper pan.
            paper.on('blank:pointerdown', function (evt, x, y) {
                if (_.contains(KeyboardJS.activeKeys(), 'shift')) {
                    selectionView.startSelecting(evt, x, y);
                } else {
                    selectionView.cancelSelection();
                    paperScroller.startPanning(evt, x, y);
                }
            });

            paper.on('element:pointerdown', function (cellView, evt) {
                // Select an element if CTRL/Meta key is pressed while the element is clicked.
                if ((evt.ctrlKey || evt.metaKey)) {
                    selection.add(cellView.model);
                }
            });

            paper.on('element:pointerup', function (elementView, evt, x, y) {
                if (V(evt.target).hasClass('delete-icon')) {
                    elementView.model.remove();
                }
            });

            paper.on('cell:mouseover', function (cellView, evt) {
                // Select an element if CTRL/Meta key is pressed while the element is clicked.
                handleCellMouseOver(cellView);
            });

            paper.on('cell:mouseout', function (cellView, evt) {
                // Select an element if CTRL/Meta key is pressed while the element is clicked.
                handleCellMouseOut(cellView);
            });

            paper.on('cell:pointerup', function (cellView) {
                // We don't want a Halo for links.
                if (cellView.model instanceof joint.dia.Link) return;
            });

            graph.on('add', function (cell) {
                isModified = true;
                if (cell instanceof joint.dia.Element) {
                    shapeForPaper(cell);

                    if (cell instanceof joint.shapes.atheer.Trigger
                        || (joint.shapes.smartflow.Trigger && cell instanceof joint.shapes.smartflow.Trigger)) {
                        if (hasTriggerCell && cell.attributes.pageId !== "job-scope") {
                            ToastService.displayToast($filter('translate')('atheer.smartFlow.builder.trigger'), 3000);
                            cell.graph.removeCells([cell]);
                        } else {
                            hasTriggerCell = true;

                            if (cell instanceof joint.shapes.atheer.TeamTrigger) {
                                triggerInfo.trigger_type = 'AUDIENCE';
                            } else if (cell instanceof joint.shapes.atheer.UserFieldTrigger) {
                                triggerInfo.trigger_type = 'USER_FIELD';
                            }
                        }
                    } else if (cell.get('active') === false) {
                        ToastService.displayToast($filter('translate')('atheer.smartFlow.builder.comingSoon'), 3000);
                        cell.graph.removeCells([cell]);
                    }
                }
            });

            graph.on('remove', function (cell) {

                isModified = true;
                if (cell instanceof joint.shapes.atheer.Trigger
                    || (joint.shapes.smartflow.Trigger && cell instanceof joint.shapes.smartflow.Trigger)) {

                    hasTriggerCell = false;

                    if (cell instanceof joint.shapes.atheer.TeamTrigger) {
                        triggerInfo.trigger_type = null;
                        triggerInfo.audiences = null;
                    } else if (cell instanceof joint.shapes.atheer.UserFieldTrigger) {
                        triggerInfo.trigger_type = null;
                        triggerInfo.user_field_condition = null;
                    }
                }
            });

            //window.addEventListener('resize', function() {
            //    paperScroller.center();
            //});

            selectionView.on('selection-box:pointerdown', function (cellView, evt) {
                // Unselect an element if the CTRL/Meta key is pressed while a selected element is clicked.
                if (evt.ctrlKey || evt.metaKey) {
                    selection.remove(cellView.model);
                }
            });

            KeyboardJS.on('delete, backspace', _.bind(function (evt) {
                if (!$.contains(evt.target, paper.el)) {
                    // remove selected elements from the paper only if the target is the paper
                    return;
                }

                commandManager.initBatchCommand();
                selection.invoke('remove');
                commandManager.storeBatchCommand();
                selectionView.cancelSelection();

                if (_.contains(KeyboardJS.activeKeys(), 'backspace') && !$(evt.target).is("input, textarea")) {
                    // Prevent Backspace from navigating back.
                    evt.preventDefault();
                }
            }));
        }

        function initClipBoard(element) {
            clipboard = new joint.ui.Clipboard;

            KeyboardJS.on('ctrl + c', function () {
                // Copy all selected elements and their associated links.
                clipboard.copyElements(selection, graph, {
                    translate: {
                        dx: 20,
                        dy: 20
                    },
                    useLocalStorage: true
                });
            });

            KeyboardJS.on('ctrl + v', function () {
                selectionView.cancelSelection();
                clipboard.pasteCells(graph, {
                    link: {
                        z: -1
                    },
                    useLocalStorage: true
                });

                // Make sure pasted elements get selected immediately. This makes the UX better as
                // the user can immediately manipulate the pasted elements.
                clipboard.each(function (cell) {
                    if (cell.get('type') === 'link') return;
                    // Push to the selection not to the model from the clipboard but put the model into the graph.
                    // Note that they are different models. There is no views associated with the models
                    // in clipboard.
                    selection.add(graph.getCell(cell.id));
                });
            });

            KeyboardJS.on('ctrl + x', function () {
                var originalCells = clipboard.copyElements(selection, graph, {
                    useLocalStorage: true
                });
                commandManager.initBatchCommand();
                _.invoke(originalCells, 'remove');
                commandManager.storeBatchCommand();
                selectionView.cancelSelection();
            });
        }

        function initCommandManager(element) {
            commandManager = new joint.dia.CommandManager({
                graph: graph
            });

            KeyboardJS.on('ctrl + z', function () {
                commandManager.undo();
                selectionView.cancelSelection();
            });

            KeyboardJS.on('ctrl + y', function () {
                commandManager.redo();
                selectionView.cancelSelection();
            });
        }

        function initValidator(element) {
            validator = new joint.dia.Validator({
                commandManager: commandManager
            });

            validator.validate('change:source change:target remove',
                function (err, command, next) {
                    //if (command.data.type === 'basic.Rect') return next('Rectangles cannot be removed.');
                    //return next();
                },
                function (err, command, next) {
                    if (err) { }
                    return next(err);
                }
            );
        }

        function initToolTips(element) {
            new joint.ui.Tooltip({
                rootTarget: document.body,
                target: '[data-tooltip]',
                direction: 'auto',
                padding: 10
            });
        }

        function createLink(color, title) {
            return function () {
                return new joint.dia.Link({
                    router: {
                        name: 'manhattan'
                    },
                    attrs: {
                        '.connection': {
                            stroke: '#999',
                            'stroke-width': 2
                        },
                        '.marker-target': {
                            d: 'M 10 0 L 0 5 L 10 10 z',
                            fill: '#999',
                            stroke: '#999'
                        },
                        '.marker-source': {
                            d: V.convertCircleToPathData(V('circle', {
                                r: 7
                            })),
                            fill: color,
                            stroke: '#fff',
                            'stroke-width': 2
                        }
                    },
                    z: -1,
                    labels: [{
                        position: {
                            distance: 0.5,
                            offset: 20
                        },
                        attrs: {
                            text: {
                                text: title,
                                fill: '#999',
                                'font-family': 'sans-serif',
                                'font-size': 14,
                                'font-weight': 'bold'
                            }
                        }
                    }]
                });
            };
        };

        function loadCanvasData(canvasData) {
            hasTriggerCell = false;
            if (canvasData != null && canvasData.length > 0) {
                graph.fromJSON(JSON.parse(canvasData));
            } else {
                graph.clear();
                var firstCell = isJobFlow ? angular.copy(scheduler) : angular.copy(audienceTrigger);
                var lastCell = isJobFlow ? angular.copy(jobEnd) : angular.copy(endSmartFlow);
                graph.addCells([firstCell, lastCell]);
            }

            _.each(graph.getElements(), function (cell) {
                shapeForPaper(cell);

                if ((!isJobFlow && cell instanceof joint.shapes.atheer.Trigger) ||
                    (isJobFlow && cell instanceof joint.shapes.smartflow.Trigger)) {
                    hasTriggerCell = true;
                }
            });
        }

        function getCellMetaData() {
            var cellData = [];
            _.each(graph.getElements(), function (cell) {
                var cellInfo = {
                    id: cell.id,
                    title: cell.attr('text/text')
                }
                cellData.push(cellInfo);
            });
            return cellData;;
        }

        function getCanvasData() {
            return JSON.stringify(graph);
        }

        function getTriggerInfo() {
            return triggerInfo;
        }

        function setTriggerInfo(triggerData) {
            if (triggerData) {
                triggerInfo = triggerData;
            } else {
                triggerInfo = {
                    trigger_type: null,
                    audiences: null,
                    user_field_condition: null
                };
            }
        }

        function shapeForPaper(cell) {
            var setting = cell.prop("setting");
            setting.type = cell.prop("type");
            setting.typeId = cell.prop("typeId");
            cell.prop("setting", setting);

            //var size = cell.size();
            //cell.scale(125/size.width, 125/size.height, cell.getBBox().center());
            cell.resize(125, 125);
        }

        function handleCellMouseOver(cellView) {
            var cell = graph.getCell(cellView.model.id);
        }

        function handleCellMouseOut(cellView) {
            var cell = graph.getCell(cellView.model.id);
        }

        function showAnalyticView(analyticsView) {
            if (analyticsView) {
                _.each(graph.getElements(), function (cell) {
                    cell.attr({
                        '.analytics': {
                            display: ''
                        }
                    });
                    cell.resize(cell.attributes.analyticsSize.width, cell.attributes.analyticsSize.height);
                });
            } else {
                _.each(graph.getElements(), function (cell) {
                    cell.attr({
                        '.analytics': {
                            display: 'none'
                        }
                    });
                    cell.resize(cell.attributes.defaultSize.width, cell.attributes.defaultSize.height);
                });
            }
        }

        function showSettings(cellView, previewMode) {
            var cell = cellView.model;
            var pageId = cellView.model.attributes.pageId;
            var element = graph.getCell(cellView.model.id);
            var setting = element.prop("setting");

            var cellType = cellView.model.attributes.type;

            var templateUrl = isJobFlow ? 'modules/smartflow/jobflow-molecules-' + pageId + '.tmpl.html' :
                'modules/smartflow/smartflow-builder-setting-' + pageId + '.tmpl.html';

            var controller = isJobFlow ? getController(pageId) : 'SmartFlowBuilderSettingController';
            $mdDialog
                .show({
                    controller: controller,
                    controllerAs: 'vm',
                    templateUrl: templateUrl,
                    escapeToClose: true,
                    clickOutsideToClose: true,
                    locals: {
                        setting: setting,
                        pageId: pageId,
                        previewMode: previewMode,
                        cellType: cellType
                    }
                })
                .then(function (result) {
                    if (result.properties && result.properties.mapping && result.type === 'smartflow.DataMapper') {
                        datamapperDatamodel = Object.keys(result.properties.mapping);
                    }
                    cell.updateSetting(result);
                    if (cell instanceof joint.shapes.atheer.TeamTrigger) {
                        triggerInfo.trigger_type = 'AUDIENCE';
                        if (result.data) {
                            triggerInfo.audiences = result.data;
                        }
                    } else if (cell instanceof joint.shapes.atheer.UserFieldTrigger) {
                        triggerInfo.trigger_type = 'USER_FIELD';

                        if (result.criteria) {
                            triggerInfo.user_field_condition = angular.fromJson(result.criteria);
                        }
                    }
                });
        }

        function getController(pageId) {
            var controller;
            if (pageId == 'data-mapper') {
                controller = 'JobFlowMoleculesDataMapperController';
            } else if (pageId == 'airform-resolver') {
                controller = 'JobFlowMoleculesAirFormResolverController';
            } else if (pageId == 'audience-resolver') {
                controller = 'JobFlowMoleculesTeamResolverController';
            } else if (pageId == 'user-resolver') {
                controller = 'JobFlowMoleculesUserResolverController';
            } else if (pageId == 'resolver') {
                controller = 'JobFlowMoleculesResolverController';
            } else if (pageId == "job-scope") {
                controller = "JobFlowMoleculesJobScopeController";
            } else if (pageId == 'job-seed') {
                controller = 'JobFlowMoleculesJobSeedController';
            } else {
                controller = 'JobFlowMoleculesSettingController';
            }

            return controller;
        }

        function setGridSize(gridSize) {
            paper.setGridSize(gridSize);
        };

        function centerAndFit() {
            paperScroller.center();
        };

        function undo() {
            commandManager.undo();
        };

        function redo() {
            commandManager.redo();
        };

        function validate(type) {
            var validGraph = true;
            var hasActivityCell = false;
            var hasEndCell = false;
            var hasHandlerCell = false;
            var hasAiRFormResolver = false;
            var hasTeamResolver = false;
            var hasUserResolver = false;
            var hasJobCreateCell = false;
            var hasJobUpdateCell = false;
            var isJobEvent = type === 'JOB_EVENT' ? true : false;

            var flowType = isJobFlow ? $filter('translate')('atheer.smartFlow.builder.jobFlow') : $filter('translate')('atheer.smartFlow.builder.smartFlow');

            if (!hasTriggerCell) {
                validGraph = false;
                showValidationMessage( $filter('translate')('atheer.smartFlow.builder.addTrigger') + flowType + '.');
                return validGraph;
            }

            var allElements = graph.getElements();

            if (validGraph) {
                for (var i = 0; i < allElements.length; i++) {
                    if (joint.shapes.smartflow.JobUpdate && allElements[i] instanceof joint.shapes.smartflow.JobUpdate) {
                        hasJobUpdateCell = true;
                        validGraph = validateJobUpdate(allElements[i]);
                        break;
                    }
                }
            }
            _.each(allElements, function (cell) {
                if (validGraph) {
                    if (cell instanceof joint.shapes.atheer.Trigger || (joint.shapes.smartflow.Trigger && cell instanceof joint.shapes.smartflow.Trigger)) {
                        validGraph = validateTrigger(cell);
                    } else if (cell instanceof joint.shapes.atheer.Activity) {
                        validGraph = validateActivity(cell);
                        hasActivityCell = true;
                    } else if (cell instanceof joint.shapes.atheer.Condition) {
                        validGraph = validateCondition(cell);
                        hasActivityCell = true;
                    } else if (cell instanceof joint.shapes.atheer.Wait) {
                        validGraph = validateWait(cell);
                    } else if (cell instanceof joint.shapes.atheer.End || (joint.shapes.smartflow.End && cell instanceof joint.shapes.smartflow.End)) {
                        validGraph = validateEnd(cell);
                        hasEndCell = true;
                    } else if (!isJobEvent && (joint.shapes.smartflow.Handlers && cell instanceof joint.shapes.smartflow.Handlers)) {
                        validGraph = validateHandlers(cell);
                        hasHandlerCell = true;
                    } else if (joint.shapes.smartflow.Mappers && cell instanceof joint.shapes.smartflow.Mappers) {
                        validGraph = validateMappers(cell);
                    } else if ((joint.shapes.smartflow.AirFormResolver && cell instanceof joint.shapes.smartflow.AirFormResolver) && !hasJobUpdateCell) {
                        validGraph = validateResolver(cell);
                        hasAiRFormResolver = true;
                    } else if ((joint.shapes.smartflow.TeamResolver && cell instanceof joint.shapes.smartflow.TeamResolver) && !hasJobUpdateCell) {
                        validGraph = validateResolver(cell);
                        hasTeamResolver = true;
                    } else if ((joint.shapes.smartflow.UserResolver && cell instanceof joint.shapes.smartflow.UserResolver) && !hasJobUpdateCell) {
                        validGraph = validateResolver(cell);
                        hasUserResolver = true;
                    } else if ((joint.shapes.smartflow.JobCreate && cell instanceof joint.shapes.smartflow.JobCreate) && !hasJobUpdateCell) {
                        validGraph = validateJobCreate(cell);
                        hasJobCreateCell = true;
                    }
                } else {
                    return validGraph;
                }
            });

            if (validGraph && !hasActivityCell && !isJobFlow) {
                validGraph = false;
                showValidationMessage($filter('translate')('atheer.smartFlow.builder.activity'));
                return validGraph;
            }

            if (validGraph && !hasEndCell) {
                validGraph = false;
                showValidationMessage($filter('translate')('atheer.smartFlow.builder.endActivity') + flowType + '.');
                return validGraph;
            }

            if (validGraph && !hasHandlerCell && isJobFlow) {
                if (isJobEvent) {
                    return true;
                }

                validGraph = false;
                showValidationMessage($filter('translate')('atheer.smartFlow.builder.handler'));
                return validGraph;
            }

            if (validGraph && !hasAiRFormResolver && isJobFlow && !hasJobUpdateCell) {
                validGraph = false;
                showValidationMessage($filter('translate')('atheer.smartFlow.builder.airFormResolver'));
                return validGraph;
            }

            if (validGraph && !hasTeamResolver && !hasUserResolver && isJobFlow && !hasJobUpdateCell) {
                validGraph = false;
                showValidationMessage(
                    $filter('translate')('atheer.smartFlow.builder.resolver')
                );
                return validGraph;
            }

            if (validGraph && !hasJobCreateCell && isJobFlow && !hasJobUpdateCell) {
                validGraph = false;
                showValidationMessage($filter('translate')('atheer.smartFlow.builder.jobCreate'));
                return validGraph;
            }

            return validGraph;
        }

        function validateTrigger(cell) {
            if (!cell.validate() && (cell instanceof joint.shapes.atheer.Trigger || cell instanceof joint.shapes.smartflow.Trigger) && !(cell instanceof joint.shapes.smartflow.Start)) {
                showValidationMessage($filter('translate')('atheer.smartFlow.builder.pleaseConfigure') + cell.attr('text/text') + $filter('translate')('atheer.smartFlow.builder.configure.trigger'));
                return false;
            }
            var outboundLinks = graph.getConnectedLinks(cell, {
                outbound: true
            });
            if (outboundLinks.length == 0) {
                var message = $filter('translate')('atheer.smartFlow.builder.pleaseLink') + cell.attr('text/text') + $filter('translate')('atheer.smartFlow.builder.triggerWith') + ((isJobFlow) ? $filter('translate')('atheer.smartFlow.builder.dataHandler') : $filter('translate')('atheer.smartFlow.builder.configure.activity')) + '.'
                showValidationMessage(message);
                return false;
            }
            return true;
        }

        function validateActivity(cell) {
            if (!cell.validate()) {
                showValidationMessage($filter('translate')('atheer.smartFlow.builder.pleaseConfigure') + cell.attr('text/text') + $filter('translate')('atheer.smartFlow.builder.configure.trigger'));
                return false;
            }
            var inboundLinks = graph.getConnectedLinks(cell, {
                inbound: true
            });
            if (inboundLinks.length == 0) {
                showValidationMessage($filter('translate')('atheer.smartFlow.builder.pleaseLink') + cell.attr('text/text') + $filter('translate')('atheer.smartFlow.builder.activityWithOther'));
                return false;
            }
            return true;
        }

        function validateCondition(cell) {
            if (!cell.validate()) {
                showValidationMessage($filter('translate')('atheer.smartFlow.builder.pleaseConfigure') + cell.attr('text/text') + $filter('translate')('atheer.smartFlow.builder.configure.condition'));
                return false;
            }

            var inboundLinks = graph.getConnectedLinks(cell, {
                inbound: true
            });
            var outboundLinks = graph.getConnectedLinks(cell, {
                outbound: true
            });

            if (inboundLinks.length == 0 || outboundLinks.length == 0) {
                showValidationMessage($filter('translate')('atheer.smartFlow.builder.pleaseLink') + cell.attr('text/text') + $filter('translate')('atheer.smartFlow.builder.conditionWithOther'));
                return false;
            }
            return true;
        }

        function validateWait(cell) {
            if (!cell.validate()) {
                showValidationMessage($filter('translate')('atheer.smartFlow.builder.pleaseConfigure') + cell.attr('text/text') + $filter('translate')('atheer.smartFlow.builder.configure.activities'));
                return false;
            }

            var inboundLinks = graph.getConnectedLinks(cell, {
                inbound: true
            });
            var outboundLinks = graph.getConnectedLinks(cell, {
                outbound: true
            });

            if (inboundLinks.length == 0 || outboundLinks.length == 0) {
                showValidationMessage($filter('translate')('atheer.smartFlow.builder.pleaseLink') + cell.attr('text/text') + $filter('translate')('atheer.smartFlow.builder.conditionWithOther'));
                return false;
            }
            return true;
        }

        function validateEnd(cell) {
            var inboundLinks = graph.getConnectedLinks(cell, {
                inbound: true
            });

            if (inboundLinks.length == 0) {
                if (isJobFlow) {
                    showValidationMessage($filter('translate')('atheer.smartFlow.builder.pleaseLink') + cell.attr('text/text') + '.');
                } else {
                    showValidationMessage($filter('translate')('atheer.smartFlow.builder.pleaseLink') + cell.attr('text/text') + $filter('translate')('atheer.smartFlow.builder.oneOfTheActivity'));
                }
                return false;
            }
            return true;
        }

        function validateHandlers(cell) {
            if (!cell.validate()) {
                showValidationMessage($filter('translate')('atheer.smartFlow.builder.pleaseConfigure') + cell.attr('text/text'));
                return false;
            }

            var outboundLinks = graph.getConnectedLinks(cell, {
                outbound: true
            });

            if (outboundLinks.length == 0) {
                showValidationMessage($filter('translate')('atheer.smartFlow.builder.pleaseLink') + cell.attr('text/text'));
                return false;
            }

            return true;
        }

        function validateMappers(cell) {
            if (!cell.validate()) {
                showValidationMessage($filter('translate')('atheer.smartFlow.builder.pleaseConfigure') + cell.attr('text/text'));
                return false;
            }

            var outboundLinks = graph.getConnectedLinks(cell, {
                outbound: true
            });

            if (outboundLinks.length == 0) {
                showValidationMessage($filter('translate')('atheer.smartFlow.builder.pleaseLink') + cell.attr('text/text'));
                return false;
            }

            return true;
        }

        function validateResolver(cell) {
            if (!cell.validate()) {
                showValidationMessage($filter('translate')('atheer.smartFlow.builder.pleaseConfigure') + (cell.attr('text/text') == 'Resolver' ? '' : cell.attr('text/text')) + $filter('translate')('atheer.smartFlow.builder.configure.resolver'));
                return false;
            }

            var outboundLinks = graph.getConnectedLinks(cell, {
                outbound: true
            });

            if (outboundLinks.length == 0) {
                showValidationMessage($filter('translate')('atheer.smartFlow.builder.pleaseLink') + cell.attr('text/text'));
                return false;
            }

            return true;
        }

        function validateJobCreate(cell) {
            var outboundLinks = graph.getConnectedLinks(cell, {
                outbound: true
            });

            if (outboundLinks.length == 0) {
                showValidationMessage($filter('translate')('atheer.smartFlow.builder.pleaseLink') + cell.attr('text/text'));
                return false;
            }

            return true;
        }

        function validateJobUpdate(cell) {
            var outboundLinks = graph.getConnectedLinks(cell, {
                outbound: true
            });

            if (outboundLinks.length == 0) {
                showValidationMessage($filter('translate')('atheer.smartFlow.builder.pleaseLink') + cell.attr('text/text'));
                return false;
            }

            return true;
        }

        function showValidationMessage(message) {
            ToastService.displayToast(message, 3000);
        }

        function getDataModelId() {
            var canvas_data = JSON.parse(getCanvasData());
            var id;
            angular.forEach(canvas_data.cells, function (cell) {
                if (cell.type == 'smartflow.DataMapper') {
                    id = cell.setting.dataModel;
                }
            });

            return id;
        }

        function getAirFormId() {
            var canvas_data = JSON.parse(getCanvasData());
            var id;
            angular.forEach(canvas_data.cells, function (cell) {
                if (cell.type == "smartflow.AirFormResolver") {
                    id = cell.setting.properties.staticResult;
                }
            });

            return id;
        }

        function getJobUpdateStatus() {
            var canvas_data = JSON.parse(getCanvasData());
            var hasJobUpdate = false;

            angular.forEach(canvas_data.cells, function (cell) {
                if (cell.type == "smartflow.JobUpdate") {
                    hasJobUpdate = true;
                }
            });

            return hasJobUpdate;
        }

        function getSmartFlowStatus() {
            return isModified;
        }

        function setSmartFlowData(data) {
            smartFlowData = data;
        }

        function getSmartFlowData() {
            return smartFlowData;
        }

        function setSmartFlowStatus() {
            isModified = false;
        }

        function getSmartFlowCategory() {
            return category;
        }
        function setSmartFlowCategory(value) {
            category = value;
        }
        function getDatamapperModel() {
            return datamapperDatamodel;
        }
        function setDatamapperModel(value) {
            datamapperDatamodel = value;
        }
    }
})();
