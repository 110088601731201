(function() {
    'use strict';

    angular
        .module('atheer.core', [
            'ngMaterial',
            'ui.router',
            'ngIdle'
        ]);
})();
