(function() {
    'use strict';

    angular
        .module('atheer.system')
        .controller('AppDialogController', AppDialogController);

    AppDialogController.$inject = ['$scope', '$stateParams', '$mdDialog', 'entity', 'App'];

    function AppDialogController ($scope, $stateParams, $mdDialog, entity, App) {
        var vm = this;
        vm.app = entity;
        vm.load = function(id) {
            App.get({id : id}, function(result) {
                vm.app = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('atheer:appUpdate', result);
            $mdDialog.hide(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.app.id !== null) {
                App.update(vm.app, onSaveSuccess, onSaveError);
            } else {
                App.save(vm.app, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $mdDialog.cancel();
        };
    }
})();
