(function() {
    'use strict';

    angular
        .module('atheer.core')
        .directive('illustrationEmptyScreen', illustrationEmptyScreen);

    /* @ngInject */
    function illustrationEmptyScreen() {
        var directive = {
            restrict: 'E',
            controller: illustrationEmptyScreenController,
            bindToController: true,
            scope: {
                id: '=',
                name: '=',
                callback: '='
            },
            templateUrl: 'core/components/illustration/illustration-empty-screen.html',
            controllerAs: 'vm'
        };

        return directive;
    }

    /* @ngInject */
    function illustrationEmptyScreenController($scope, $sce, $timeout, $state, $attrs, $injector, $filter, $q, $mdDialog) {
        var vm = this;

        vm.modules = {
            air_forms : {
                imgSrc: "/assets/images/card/No_AiRForms.png",
                title: "Create your first AiRForm",
                textMsg1: "Atheer AiRForms allow you to rapidly create and share<br/>anything from work instructions, step by step guidance,<br/> checklists, surveys to full blown apps, without needing to<br/>write a single line of code.",
                button: "Let's begin!",
                create : null,
                bold: true,
                state: null,
                state_param: null
            },
            no_airforms : {
                imgSrc: "/assets/images/card/No_AiRForms.png",
                title: $filter('translate')('illustrationEmptyScreen.noAirForms.title'),
                textMsg1: "",
                button: null,
                create : null,
                bold: true,
                state: null,
                state_param: null
            },
            conversation : {
                imgSrc: "/assets/images/card/FirstTime_Conversations.png",
                title: $filter('translate')('illustrationEmptyScreen.conversation.title'),
                textMsg1: $filter('translate')('illustrationEmptyScreen.conversation.textMsg1'),
                button: null,
                create : null,
                bold: true,
                state: null,
                state_param: null
            },
            message : {
                imgSrc: "/assets/images/card/NoMessages.png",
                title: $filter('translate')('illustrationEmptyScreen.message.title') + "Name",
                textMsg1: $filter('translate')('illustrationEmptyScreen.message.textMsg1'),
                button: null,
                create : null,
                bold: true,
                state: null,
                state_param: null
            },
            group_conv : {
                imgSrc: "/assets/images/card/No_Groups.png",
                title: $filter('translate')('illustrationEmptyScreen.groupConv.title'),
                textMsg1: "",
                button: null,
                create : null,
                bold: true,
                state: null,
                state_param: null
            },
            session_chat : {
                imgSrc: "/assets/images/card/NoMessages.png",
                title: $filter('translate')('illustrationEmptyScreen.sessionChat.title'),
                textMsg1: "",
                button: null,
                create : null,
                bold: true,
                state: null,
                state_param: null
            },
            session_upcoming : {
                imgSrc: "/assets/images/card/FirstTime_Sessions.png",
                title: $filter('translate')('illustrationEmptyScreen.sessionUpcoming.title'),
                textMsg1: $filter('translate')('illustrationEmptyScreen.sessionUpcoming.textMsg1'),
                textMsg2: $filter('translate')('illustrationEmptyScreen.sessionUpcoming.textMsg2'),
                bold: true,
                state_param: null
            },
            session_past : {
                imgSrc: "/assets/images/card/FirstTime_Sessions.png",
                title: $filter('translate')('illustrationEmptyScreen.sessionPast.title'),
                textMsg1: $filter('translate')('illustrationEmptyScreen.sessionPast.textMsg1'),
                button: $filter('translate')('illustrationEmptyScreen.sessionPast.button'),
                create : "create-session",
                bold: true,
                state: "storm.sessions.upcoming-detail",
                state_param: null
            },
            session_all : {
                imgSrc: "/assets/images/card/FirstTime_Sessions.png",
                title: "",
                textMsg1: $filter('translate')('illustrationEmptyScreen.sessionAll.textMsg1'),
                button: null,
                create : null,
                bold: true,
                state: null,
                state_param: null
            },
            session_template : {
                imgSrc: "/assets/images/card/No_Templates_session.png",
                title: $filter('translate')('illustrationEmptyScreen.sessionTemplate.title'),
                textMsg1: $filter('translate')('illustrationEmptyScreen.sessionTemplate.textMsg1'),
                button: null,
                create : null,
                bold: true,
                state: null,
                state_param: null
            },
            content : {
                imgSrc: "/assets/images/card/FirstTime_Content.png",
                title: $filter('translate')('illustrationEmptyScreen.content.title'),
                textMsg1: $filter('translate')('illustrationEmptyScreen.content.textMsg1'),
                button: null,
                create : null,
                bold: true,
                state: null,
                state_param: null
            },
            no_content : {
                imgSrc: "/assets/images/card/NoContent.png",
                title: $filter('translate')('illustrationEmptyScreen.noContent.title'),
                textMsg1: "",
                button: null,
                create : null,
                bold: true,
                state: null,
                state_param: null
            },
            topic : {
                imgSrc: "/assets/images/card/No_Topics.png",
                title: $filter('translate')('illustrationEmptyScreen.topic.title'),
                textMsg1: $filter('translate')('illustrationEmptyScreen.topic.textMsg1'),
                button: $filter('translate')('illustrationEmptyScreen.topic.button'),
                create : null,
                bold: true,
                state: "storm.admin-topic-detail",
                state_param: {
                    'topicType': 'STATIC',
                    'parentTopic': null
                }
            },
            team : {
                imgSrc: "/assets/images/card/No_Topics.png",
                title: $filter('translate')('illustrationEmptyScreen.team.title'),
                textMsg1: $filter('translate')('illustrationEmptyScreen.team.textMsg1'),
                button: $filter('translate')('illustrationEmptyScreen.team.button'),
                create : null,
                bold: true,
                state: "storm.admin-team-detail",
                state_param: {
                    'type': 'STATIC'
                }
            },
            dashboard : {
                imgSrc: "/assets/images/card/dashboards-hero.png",
                title: $filter('translate')('illustrationEmptyScreen.dashboard.title'),
                textMsg1: $filter('translate')('illustrationEmptyScreen.dashboard.textMsg1'),
                create : null,
                bold: true,
                state: "storm.admin-dashboard-detail",
                state_param: {
                    'type': 'STATIC'
                }
            },
            smart_flow : {
                imgSrc: "/assets/images/card/No_SmartFlows@2x.png",
                title: $filter('translate')('illustrationEmptyScreen.smartFlow.title'),
                textMsg1: $filter('translate')('illustrationEmptyScreen.smartFlow.textMsg1'),
                button: $filter('translate')('illustrationEmptyScreen.smartFlow.button'),
                createPermission : 'create-workflow',
                bold: true,
                state: "storm.admin-smartflow-detail",
                state_param: { 'isJobFlow': true }
            },
            no_smart_flow : {
                imgSrc: "/assets/images/card/NoSmartFlows.png",
                title: $filter('translate')('illustrationEmptyScreen.noSmartFlow.title'),
                textMsg1: "",
                button: null,
                createPermission : 'create-workflow',
                bold: true,
                state: "storm.admin-smartflow-detail",
                state_param: null
            },
            smart_scan : {
                imgSrc: "/assets/images/card/NewBottomAlignTrial.png",
                title: $filter('translate')('illustrationEmptyScreen.smartScan.title'),
                textMsg1: $filter('translate')('illustrationEmptyScreen.smartScan.textMsg1'),
                button: $filter('translate')('illustrationEmptyScreen.smartScan.button'),
                create : null,
                bold: true,
                state: "storm.admin-smartscan-detail",
                state_param: null
            },
            web_hooks : {
                imgSrc: "/assets/images/card/web-hook-empty-screen-illustration.svg",
                title: $filter('translate')('illustrationEmptyScreen.webHook.title'),
                textMsg1: $filter('translate')('illustrationEmptyScreen.webHook.textMsg1'),
                button: $filter('translate')('illustrationEmptyScreen.webHook.button'),
                create : null,
                bold: true,
                state: "storm.admin-airsdk-web-hook-detail",
                state_param: null
            },
            web_hooks_logs : {
                imgSrc: "/assets/images/card/web-hook-empty-screen-illustration.svg",
                title: $filter('translate')('illustrationEmptyScreen.webHookLogs.title'),
                textMsg1: $filter('translate')('illustrationEmptyScreen.webHookLogs.textMsg1'),
                button: null,
                create : null,
                bold: true,
                state: "storm.admin-airsdk-web-hook-detail",
                state_param: null
            },
            no_smart_scan : {
                imgSrc: "/assets/images/card/No_SmartScans@2x.png",
                title: $filter('translate')('illustrationEmptyScreen.noSmartScan.title'),
                textMsg1: $filter('translate')('illustrationEmptyScreen.noSmartScan.textMsg1'),
                button: null,
                create : null,
                bold: true,
                state: "storm.admin-smartscan-detail",
                state_param: null
            },
            users : {
                imgSrc: "/assets/images/card/NoUsers.png",
                title: $filter('translate')('illustrationEmptyScreen.users.title'),
                textMsg1: "",
                button: null,
                create : null,
                bold: true,
                state: null,
                state_param: null
            },
            security_role_user: {
                imgSrc: "/assets/images/card/NoUsers.png",
                title: $filter('translate')('illustrationEmptyScreen.securityRoleUser.title'),
                textMsg1: "",
                button: null,
                create : null,
                bold: true,
                state: null,
                state_param: null
            },
            badge : {
                imgSrc: "/assets/images/card/No_Badges.png",
                title: $filter('translate')('illustrationEmptyScreen.badge.title'),
                textMsg1: "",
                button: null,
                create : null,
                bold: true,
                state: null,
                state_param: null
            },
            insight_graph : {
                imgSrc: "/assets/images/card/No_insights.png",
                title: "Not enough activity yet",
                textMsg1: "",
                button: null,
                create : null,
                bold: false,
                state: null,
                state_param: null,

            },
            no_Problem : {
                imgSrc: "/assets/images/card/No_Problems@2x.png",
                title: $filter('translate')('illustrationEmptyScreen.noProblem.title'),
                textMsg1: $filter('translate')('illustrationEmptyScreen.noProblem.textMsg1'),
                button: null,
                create : null,
                bold: true,
                state: null,
                state_param: null,

            },
            no_rating : {
                imgSrc: "/assets/images/card/No_Ratings.png",
                title: $filter('translate')('illustrationEmptyScreen.noRating.title'),
                textMsg1: $filter('translate')('illustrationEmptyScreen.noRating.textMsg1'),
                button: null,
                create : null,
                bold: true,
                state: null,
                state_param: null,

            },
            no_content_smartscan : {
                imgSrc: "/assets/images/card/no_content_smartscan.png",
                title: $filter('translate')('illustrationEmptyScreen.noContentSmartScan.title'),
                textMsg1: "",
                button: null,
                create : null,
                bold: true,
                state: null,
                state_param: null
            },
            no_airform_smartscan : {
                imgSrc: "/assets/images/card/no_smartscan_airform.png",
                title: $filter('translate')('illustrationEmptyScreen.noAirFormSmartScan.title'),
                textMsg1: "",
                button: null,
                create : null,
                bold: true,
                state: null,
                state_param: null
            },
            no_user_smartscan : {
                imgSrc: "/assets/images/card/no_smartscan_user.png",
                title: $filter('translate')('illustrationEmptyScreen.noUserSmartScan.title'),
                textMsg1: "",
                button: null,
                create : null,
                bold: true,
                state: null,
                state_param: null
            },
            no_user_team : {
                imgSrc: "/assets/images/card/no_smartscan_user.png",
                title: $filter('translate')('illustrationEmptyScreen.noUserSmartScan.title'),
                textMsg1: "",
                button: null,
                create : null,
                bold: true,
                state: null,
                state_param: null
            },
            no_users_session : {
                imgSrc: "/assets/images/card/NoUsers.png",
                title: $filter('translate')('illustrationEmptyScreen.noSessionUsers.title'),
                textMsg1: $filter('translate')('illustrationEmptyScreen.noSessionUsers.textMsg1'),
                button: null,
                create : null,
                bold: true,
                state: null,
                state_param: null
            },
            no_users_search : {
                imgSrc: "/assets/images/card/NoUsers.png",
                title: $filter('translate')('illustrationEmptyScreen.noUserSearch.title'),
                textMsg1: "",
                button: null,
                create : null,
                bold: true,
                state: null,
                state_param: null
            },
            upcoming_no_scheduled_session: {
                imgSrc: "/assets/images/card/FirstTime_Sessions.png",
                title: $filter('translate')('illustrationEmptyScreen.upcomingNoScheduled.title'),
                textMsg1: $filter('translate')('illustrationEmptyScreen.upcomingNoScheduled.textMsg1'),
                button: null,
                create: null,
                bold: true,
                state: null,
                state_param: null
            },
            upcoming_no_session: {
                imgSrc: "/assets/images/card/FirstTime_Sessions.png",
                title: "",
                textMsg1: $filter('translate')('illustrationEmptyScreen.upcomingNoSession.textMsg1'),
                button: null,
                create: null,
                bold: true,
                state: null,
                state_param: null
            },
            user_import: {
                imgSrc: "/assets/images/card/PeopleIllustration.png",
                title: "",
                textMsg1: "",
                button: null,
                create: null,
                bold: true,
                state: null,
                state_param: null
            },
            no_content_anchor : {
                imgSrc: "/assets/images/card/no_content_smartscan.png",
                title: $filter('translate')('illustrationEmptyScreen.noContentAnchor.title'),
                textMsg1: "",
                button: null,
                create : null,
                bold: true,
                state: null,
                state_param: null
            },
            no_airform_anchor : {
                imgSrc: "/assets/images/card/no_smartscan_airform.png",
                title: $filter('translate')('illustrationEmptyScreen.noAirFormAnchor.title'),
                textMsg1: "",
                button: null,
                create : null,
                bold: true,
                state: null,
                state_param: null
            },
            no_user_anchor : {
                imgSrc: "/assets/images/card/no_smartscan_user.png",
                title: $filter('translate')('illustrationEmptyScreen.noUserAnchor.title'),
                textMsg1: "",
                button: null,
                create : null,
                bold: true,
                state: null,
                state_param: null
            },
            anchor : {
                imgSrc: "/assets/images/card/No_Badges.png",
                title: $filter('translate')('illustrationEmptyScreen.badge.title'),
                textMsg1: "",
                button: null,
                create : null,
                bold: true,
                state: null,
                state_param: null
            },
        }


        if (vm.id == 'message') {
            vm.modules[vm.id].title= vm.modules[vm.id].title.replace("Name", vm.name);
            vm.modules[vm.id].textMsg1 = vm.modules[vm.id].textMsg1.replace("Name", vm.name);
        }

        vm.imgSrc = vm.modules[vm.id].imgSrc;
        vm.permission = vm.modules[vm.id].create;
        vm.addButton = vm.modules[vm.id].button == null ? false : true;
        vm.button = vm.modules[vm.id].button;
        vm.permisson = vm.modules[vm.id].createPermission;

        var className = (vm.id == 'web_hooks' || vm.id == 'web_hooks_logs') ? 'fp-illustration-description' : '';
        if (vm.modules[vm.id].bold == true) {
            vm.html = '<span align="center" class="md-title margin-bottom-20">' + vm.modules[vm.id].title + '</span><span align="center" class="md-subhead'+ className +'">' + vm.modules[vm.id].textMsg1 + '</span>';
        } else if (vm.modules[vm.id].bold == false) {
            vm.html = '<span align="center"  class="margin-top-10 main">' + vm.modules[vm.id].title + '</span><span align="center" class="md-subhead'+ className +'">' + vm.modules[vm.id].textMsg1 + '</span>';
        }

        vm.trustedHtml = $sce.trustAsHtml(vm.html);


        if (vm.modules[vm.id].state_param == null) {
            vm.goState = function() {
                $state.go(vm.modules[vm.id].state);
            }
        } else {
            vm.goState = function() {
                $state.go(vm.modules[vm.id].state, vm.modules[vm.id].state_param);
            }
        }
    }
})();
