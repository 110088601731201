(function() {
    'use strict';

    angular
        .module('atheer.cloud')
        .controller('LogsController', LogsController);

    /* @ngInject */
    function LogsController(LogsService) {
        var vm = this;

        vm.changeLevel = changeLevel;
        vm.getLoggers = getLoggers;

        vm.query = {
            filter: '',
            limit: '10',
            order: '-id',
            page: 1
        };

        vm.filter = {
            options: {
                debounce: 500
            }
        };

        vm.removeFilter = removeFilter;
        vm.filter.show = false;

        function getLoggers() {
            vm.promise = LogsService.findAll().$promise;
            vm.promise.then(function(data, headers) {
                vm.loggers = data;
            });
        }

        function removeFilter() {
            vm.filter.show = false;
            vm.query.filter = '';

            if (vm.filter.form.$dirty) {
                vm.filter.form.$setPristine();
            }
        }

        function changeLevel(name, level) {
            LogsService.changeLevel({
                name: name,
                level: level
            }, function() {
                vm.loggers = LogsService.findAll();
            });
        }

        getLoggers();
    }
})();
