(function() {
    'use strict';

    angular
        .module('atheer.core')
        .directive('dynamicController', dynamicController);

    /* @ngInject */
    function dynamicController($controller) {
      var directive = {
          restrict: 'A',
          scope: true,
          link: function(scope, element, attrs) {
              var locals = {
                  $scope: scope,
                  $element: element,
                  $attrs: attrs,
                  id: attrs.id
              };
              element.data('$Controller', $controller(scope.$eval(attrs.dynamicController), locals));
          }
      };
      return directive;
    }
    
    
    
    angular
    .module('atheer.core').directive('scrollToBookmark', function() {
        return {
            link: function(scope, element, attrs) {
              var value = attrs.scrollToBookmark;
              element.click(function() {
                scope.$apply(function() {
                  var selector = "[scroll-bookmark='"+ value +"']";
                  var element = $(selector);
                  if(element.length)
                    window.scrollTo(0, element[0].offsetTop - 100);  // Don't want the top to be the exact element, -100 will go to the top for a little bit more
                });
              });
            }
          };
      });
    
})();
