(function () {
    'use strict';
    angular
        .module('atheer.services')
        .factory('LocalResourceService', LocalResourceService);

    /* @ngInject */
    function LocalResourceService($resource) {
        var resourceUrl = '/assets/samples/:fileName';

        return $resource(resourceUrl, { fileName: '@fileName' }, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });
    }
})();
