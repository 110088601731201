(function () {
    'use strict';

    angular
        .module('atheer.user')
        .controller('ProfileEventDialogController', ProfileEventDialogController);

    /* @ngInject */
    function ProfileEventDialogController($scope, $state, $q, $mdDialog, MetaData, User, Experience, $translate, $filter, eventMeta, userEvent, ToastService) {
        var vm = this;

        var FORMLY_ATHEER_DATA_TYPE_MAPPING = {
            'BOOLEAN': 'checkbox',
            'STRING': 'input',
            'LIST': 'select',
            'DATE': 'datepicker',
            'DATETIME': 'datepicker',
            'NUMBER': 'input',
            'OBJECT': 'objectpicker'
        };

        var FORMLY_TEMPLATE_TYPE_ATHEER_DATA_TYPE_MAPPING = {
            'STRING': 'text',
            'NUMBER': 'number'
        };

        vm.doNotMatch = null;
        vm.error = null;
        vm.success = null;

        vm.userEvent = userEvent;
        vm.metaFieldGroup = eventMeta;

        vm.metaFields = [];

        vm.loadingData = false;
        vm.eventMetaCreateTitle = 'atheer.event.create';
        vm.eventMetaUpdateTitle = 'atheer.event.update';
        vm.close = function () {
            $mdDialog.cancel();
        };

        vm.save = function () {
            vm.userEvent.name = vm.metaFieldGroup.name;

            if (vm.userEvent.id) {
                Experience.updateEvent({
                    id: vm.userEvent.id
                }, vm.userEvent, function (result) {
                    ToastService.displayToast(vm.metaFieldGroup.label + $filter('triTranslate')('atheer.event.messages.success.update'), 3000);
                    $mdDialog.hide();
                });
            } else {
                Experience.createEvent(vm.userEvent, function (result) {
                    ToastService.displayToast(vm.metaFieldGroup.label + $filter('triTranslate')('atheer.event.messages.success.added'), 3000);
                    $mdDialog.hide();
                });
            }
        };

        vm.isNew = function () {
            if (vm.userEvent.id) {
                return false;
            } else {
                return true;
            }
        };

        vm.loadData = function () {
            vm.loadingData = true;

            angular.forEach(vm.metaFieldGroup.fields, function (field) {
                if (!field.locked) {
                    vm.metaFields.push(getFormlyField(field));
                }
            });

            vm.loadingData = false;
        };

        function getFormlyField(atheerfield) {
            var formlytype = FORMLY_ATHEER_DATA_TYPE_MAPPING[atheerfield.data_type];
            var formlyTemplateType = FORMLY_TEMPLATE_TYPE_ATHEER_DATA_TYPE_MAPPING[atheerfield.data_type];
            var formlyField = {
                type: formlytype ? formlytype : 'input',
                key: atheerfield.name,
                templateOptions: {
                    label: atheerfield.label,
                    required: atheerfield.required
                }
            };

            if (formlyTemplateType) {
                formlyField.templateOptions['type'] = formlyTemplateType;
            }
            
            if (
                (atheerfield.data_type === "DATETIME" ||
                    atheerfield.data_type === "DATE") &&
                formlyField.templateOptions["required"] &&
                formlyField.templateOptions["label"].indexOf("*") === -1
            ) {
                formlyField.templateOptions["label"] =
                    formlyField.templateOptions["label"] + " *";
            }

            if (atheerfield.data_type == 'DATETIME') {
                formlyField.templateOptions['datepickerPopup'] = "HH:MM"
            }

            if (atheerfield.data_type == 'LIST') {
                formlyField.templateOptions['labelProp'] = "label";
                formlyField.templateOptions['valueProp'] = "option_id";
                formlyField.templateOptions['options'] = atheerfield.list_options;
            }

            if (atheerfield.data_type == 'OBJECT') {
                if (atheerfield.ref_object == 'Content') {
                    formlyField.templateOptions['objectName'] = 'Content';
                    formlyField.templateOptions['queryObject'] = [{
                        fieldName: 'title',
                        operator: '=re='
                    }];
                } else if (atheerfield.ref_object == 'User') {
                    formlyField.templateOptions['objectName'] = 'User';
                    formlyField.templateOptions['queryObject'] = [{
                        fieldName: 'text_search',
                        operator: '=ts='
                    }];
                }
            }
            return formlyField;
        };
        vm.loadData();
    }
})();
