(function () {
    "use strict";

    angular
        .module("atheer.smartFlow")
        .controller(
            "JobFlowMoleculesResolverController",
            JobFlowMoleculesResolverController
        );

    /* @ngInject */
    function JobFlowMoleculesResolverController(
        $mdDialog,
        JobFlow,
        cellType,
        setting,
        SmartFlowBuilder
    ) {
        var vm = this;

        var object = {
            search_key_name: "searchKeys",
            search_key_value: "",
            data_model_name: "dataModel",
            data_model_value: "",
        };

        vm.resolverTypeList = [
            { name: "Static", value: "STATIC" },
            { name: "Query", value: "QUERY" },
            { name: "Lookup", value: "LOOKUP" },
        ];

        vm.resolverList = [];
        vm.lookUpData = [];
        vm.loadingData = true;
        vm.listItems = [];
        vm.staticObject = [];
        vm.dynamicObject = [];
        vm.isStaticObjectLoaded = false;
        vm.isDynamicObjectLoaded = false;
        vm.dataModelProperties = [];
        vm.objects = [];

        vm.close = function () {
            $mdDialog.cancel();
        };

        vm.submit = function () {
            var rawData = angular.copy(vm.setting.properties);
            vm.setting.properties = {};

            switch (rawData.resolverType) {
                case "STATIC":
                    vm.setting.properties = {
                        objectType: rawData.objectType,
                        staticResult: rawData.staticResult,
                        resolverType: rawData.resolverType,
                    };
                    break;
                case "QUERY":
                    vm.setting.properties = {
                        objectType: rawData.objectType,
                        resolverType: rawData.resolverType,
                        searchCriteria: {
                            searchKeys: rawData.searchCriteria.searchKeys,
                            returnSingleObject: true,
                        },
                    };
                    break;
                case "LOOKUP":
                    var lookupCriteria = [];
                    angular.forEach(vm.lookUpData, function (row) {
                        var obj = {
                            conditionGroup: {
                                logicalOperator: "AND",
                                conditions: [],
                            },
                            onSuccessResult: row["onSuccessResult"],
                        };

                        angular.forEach(row, function (value, key) {
                            if (key === "onSuccessResult") {
                                return;
                            }

                            obj.conditionGroup.conditions.push({
                                operand: key,
                                operator: "EQ",
                                value: value,
                            });
                        });

                        lookupCriteria.push(obj);
                    });
                    vm.setting.properties = {
                        objectType: rawData.objectType,
                        resolverType: rawData.resolverType,
                        returnSingleObject: true,
                        lookupCriteria: lookupCriteria,
                    };
                    break;
            }

            if (vm.setting.properties.resolverType !== "STATIC") {
                vm.setting.properties.returnSingleObject = true;
            }

            $mdDialog.hide(vm.setting);
        };

        vm.onResolveTypeChnage = function () {
            if (vm.lookUpData && vm.lookUpData.length > 0) {
                return;
            }

            var obj = {};
            angular.forEach(vm.resolverList, function (value) {
                obj[value] = undefined;
            });

            vm.lookUpData.push(obj);
        };

        vm.onObjectTypeChange = function (isData) {
            vm.loadingData = true;
            vm.staticObject = [];
            vm.dynamicObject = [];

            if (vm.setting.properties.objectType === "AiRForm") {
                vm.setting.properties.resolverType = "STATIC";
            }

            JobFlow.getObjectList(
                { objectType: vm.setting.properties["objectType"] },
                function (result) {
                    vm.isStaticObjectLoaded = true;
                    vm.loadingData = false;
                    vm.staticObject = result;
                }
            );

            if (vm.setting.properties.resolverType === "QUERY") {
                JobFlow.getMetaData(
                    { objectType: vm.setting.properties["objectType"] },
                    function (result) {
                        vm.isDynamicObjectLoaded = true;
                        vm.loadingData = false;
                        vm.setMetaData(result);
                    }
                );

                vm.setting.properties["searchCriteria"]["searchKeys"] = isData
                    ? vm.setting.properties["searchCriteria"]["searchKeys"]
                    : {};
            }

            if (vm.setting.properties.resolverType === "LOOKUP") {
                var dataModelId = SmartFlowBuilder.getDataModelId();

                if (!dataModelId) {
                    vm.loadingData = false;
                    return;
                }

                setDataModelProperties(dataModelId);
            }
        };

        vm.setMetaData = function (result) {
            angular.forEach(result, function (metaData) {
                var value = metaData.split(".").join("##");
                var data = {
                    label: metaData,
                    value: value,
                };
                vm.dynamicObject.push(data);
            });
        };

        vm.onResolverTypeChange = function () {
            if (vm.setting.properties.resolverType === "QUERY") {
                vm.setting.properties["searchCriteria"] = {};
                vm.setting.properties.searchCriteria["searchKeys"] = {};
                vm.objects = [];

                initQueryData();
            }

            if (vm.setting.properties.resolverType === "LOOKUP") {
                vm.onObjectTypeChange();
            }
        };

        vm.showProperties = function (object) {
            $mdDialog
                .show({
                    controller: "JobFlowMoleculesPropertiesController",
                    controllerAs: "vm",
                    templateUrl:
                        "modules/smartflow/jobflow-molecules-properties.tmpl.html",
                    escapeToClose: true,
                    clickOutsideToClose: true,
                    locals: {
                        dataModelProperties: vm.dataModelProperties,
                        type: vm.setting.properties["objectType"],
                    },
                    multiple: true,
                })
                .then(function (result) {
                    vm.setting.properties.searchCriteria.searchKeys[
                        object.search_key_value
                    ] = result.name;
                });
        };

        vm.addRow = function (key) {
            var obj = {};
            angular.forEach(vm.resolverList, function (value) {
                obj[value] = undefined;
            });

            vm.lookUpData.push(obj);
        };

        vm.deleteRow = function (index) {
            vm.lookUpData.splice(index, 1);
        };

        function initLookUp() {
            var properties = angular.copy(vm.setting.properties);
            vm.resolverList = [];
            vm.lookUpData = [];
            var obj = {};

            angular.forEach(properties.lookupCriteria, function (row) {
                obj = {};
                angular.forEach(row.conditionGroup.conditions, function (
                    value
                ) {
                    obj[value.operand] = value.value;
                    if (!vm.resolverList[value.operand]) {
                        vm.resolverList.push(value.operand);
                    }
                });

                obj["onSuccessResult"] = row.onSuccessResult;
                vm.lookUpData.push(obj);
            });
        }

        function init() {
            vm.setting = angular.copy(setting);

            if (!vm.setting.properties.resolverType) {
                vm.setting.properties.resolverType = "STATIC";
            }

            JobFlow.getMoleculesProperties(function (data) {
                vm.loadingData = true;
                setListItem(data);
            });

            if (vm.setting.properties.resolverType === "LOOKUP") {
                initLookUp();
            }

            if (
                !vm.setting.properties.resolverType ||
                vm.setting.properties.resolverType !== "QUERY"
            ) {
                if (vm.setting.properties.objectType) {
                    vm.onObjectTypeChange();
                }
                return;
            }

            initQueryData();
        }

        vm.addField = function (key) {
            var obj = angular.copy(object);
            obj.search_key_value = key ? key : "";
            vm.objects.push(obj);
        };

        vm.removeField = function (id, obj) {
            vm.objects.splice(id, 1);
            delete vm.setting.properties.searchCriteria.searchKeys[
                obj.search_key_value
            ];
        };

        function initQueryData() {
            var dataModelId = SmartFlowBuilder.getDataModelId();
            setDataModelProperties(dataModelId);

            var keys = Object.keys(vm.setting.properties.searchCriteria);
            if (keys && keys.length > 0) {
                var searchKeys = Object.keys(
                    vm.setting.properties.searchCriteria.searchKeys
                );
                if (
                    searchKeys &&
                    searchKeys.length > 0 &&
                    vm.setting.properties["resolverType"] === "QUERY"
                ) {
                    angular.forEach(searchKeys, function (key) {
                        vm.addField(key);
                    });
                } else {
                    vm.addField();
                }
                vm.onObjectTypeChange(true);
            } else {
                vm.resolverType = true;
                vm.addField();
            }
        }

        function setDataModelProperties(dataModelId) {
            JobFlow.getDataModelById({ id: dataModelId }, function (result) {
                vm.dataModelProperties = result.properties;
                vm.loadingData = false;
            });
        }

        function setListItem(data) {
            angular.forEach(data, function (inputElement) {
                if (inputElement.type == cellType) {
                    angular.forEach(inputElement.properties, function (
                        property
                    ) {
                        if (
                            property.type == "LIST" &&
                            property.name === "objectType"
                        ) {
                            vm.listItems = property.list;
                        }
                    });
                    vm.loadingData = false;
                }
            });
        }

        init();
    }
})();
