(function () {
    'use strict';
    angular
        .module('atheer')
        .factory('froalaConfig', froalaConfig);
    /* @ngInject */
    function froalaConfig($cookies) {
        return {
            key: 'Ig1A7vC2A3C3F2G2A3mEZXQUVJe1EZb1IWIAUKLJZMBQuF2C1G1G1A10B1E7A1A5E5==',
            aviaryKey: '1daf90129bbe47de80a0a38c3399f9fc',
            fileUploadParam: 'file',
            fileUploadURL: '/api/media/file_upload',
            fileUploadMethod: 'POST',
            fileAllowedTypes: ['*'],
            imageUploadURL: '/api/media/image_upload',
            imageUploadParam: 'file',
            imageUploadMethod: 'POST',
            imageAllowedTypes: ['jpeg', 'jpg', 'png'],
            toolbarInline: false,
            heightMin: 100,
            heightMax: 200,
            charCounterMax: 1000,
            pastePlain: true,
            pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'emoticons', 'entities', 'file', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageManager', 'inlineStyle', 'lineBreaker', 'link', 'lists', 'paragraphFormat', 'paragraphStyle', 'quote', 'save', 'table', 'url', 'video'],
            toolbarButtons: ['bold', 'italic', 'underline', 'strikeThrough', '|', 'insertLink', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', '|', 'undo', 'redo', 'fullscreen'],
            toolbarButtonsMD: ['bold', 'italic', 'underline', 'strikeThrough', '|', 'insertLink', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', '|', 'undo', 'redo', 'fullscreen'],
            toolbarButtonsSM: ['bold', 'italic', 'underline', 'strikeThrough', '|', 'insertLink', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', '|', 'undo', 'redo', 'fullscreen'],
            toolbarButtonsXS: ['bold', 'italic', 'underline', 'strikeThrough', '|', 'insertLink', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', '|', 'undo', 'redo', 'fullscreen'],
            placeholderText: 'Enter Text Here',
            zIndex: 70,
            language: setEditorLanguage(),
            attribution: false,
            linkList: [
                {
                    text: 'Topics',
                    href: window.location.origin + '/deeplink/topic/'
                },
                {
                    text: 'Content',
                    href: window.location.origin + '/deeplink/content/'
                },
                {
                    text: 'User',
                    href: window.location.origin + '/deeplink/user/'
                }
            ],
            requestHeaders: {
                authorization: 'Bearer ath-' + localStorage.getItem('Authorization')
            }
        };
        
        //Function to get the language which is active and set the same language to Froala Object
        function setEditorLanguage() {
            var lang = 'en-US';
            var langKey = $cookies.getObject('NG_TRANSLATE_LANG_KEY');
            if (langKey) {
                lang = langKey.replace(/["]+/g, '').replace('_', '-');
                lang = lang.toLowerCase().replace(/-/g, '_');
            }
            var withRegionLanguages = ['en_gb', 'pt_br', 'zh_tw', 'zh_cn'];
            if (withRegionLanguages.includes(lang)) {
                return lang;
            }
            if (lang && lang.split('_') && lang.split('_')[0]) {
                return lang.split('_')[0]
            }
            else {
                return lang;
            }
        }
    }
})();

