(function() {
    'use strict';

    angular
        .module('atheer.user')
        .controller('NoAccessContoller', NoAccessContoller);

    /* @ngInject */
    function NoAccessContoller($rootScope, $mdDialog, $state, $q, $scope, $sce, triLoaderService, Principal, AlertService, $mdToast, Feature, Experience, $stateParams, $location, Auth, User) {
        var vm = this;
        vm.buildInfoSettings = $rootScope.buildInfo;

        vm.isWorkspaceInactive = $stateParams.isWorkspaceInactive;

        vm.isApiProcessing = true;

        vm.doesWorkspaceExist = true;

        // for localhost
        var workspace = $location.host() == 'localhost' ? 'demo' : $location.host().split('.')[0];
        Auth.validateWorkspaceName(workspace).then(function (res) {
            User.getFullUser({
                id: 'me'
            }).$promise.then(function(user) {
                if(user.workspace_info.domain === workspace || workspace === 'demo') {
                    $state.go('storm.home');
                }
            }).catch(function (error) {
                // If user not signed in redirect to signin page
                if(error.status === 401) {
                    $state.go('account.signin');
                }
                console.error(error);
            }).finally(function () {
                vm.isApiProcessing = false;
            });
        }).catch(function (error) {
            if(error.status === 400) {
                vm.doesWorkspaceExist = false;
            }
            vm.isApiProcessing = false;
            console.error(error)
        });
    }

})();
