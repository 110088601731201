(function () {
    'use strict';

    angular
        .module('atheer.job')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider, CONTENT_INSIGHT_META) {
        $stateProvider
            .state('storm.jobs', {
                url: '/jobs',
                templateUrl: 'modules/ngx-frame.html',
                controller: 'JobController',
                controllerAs: 'vm',
                data: {
                    pageTitle: 'atheer.job.home.title',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showSideNavMenu: false,
                        sideNavMenuPosition: 'left',
                        showToolbar: false,
                        sideNavMenuSize: 15
                    }
                }
            })
            .state('storm.job-execute', {
                url: '/jobs/execute/:id',
                templateUrl: 'modules/ngx-frame.html',
                controller: 'JobController',
                controllerAs: 'vm',
                data: {
                    pageTitle: 'atheer.job.home.title',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showSideNavMenu: false,
                        sideNavMenuPosition: 'left',
                        showToolbar: false,
                        sideNavMenuSize: 15
                    }
                },
                resolve: {
                    id: function ($stateParams) {
                        return $stateParams.id;
                    }
                },
            })
            .state('storm.job-detail', {
                url: '/jobs/:id',
                templateUrl: 'modules/ngx-frame.html',
                controller: 'JobController',
                controllerAs: 'vm',
                data: {
                    pageTitle: 'atheer.job.home.title',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showSideNavMenu: false,
                        sideNavMenuPosition: 'left',
                        showToolbar: false,
                        sideNavMenuSize: 15
                    }
                },
                resolve: {
                    id: function ($stateParams) {
                        return $stateParams.id;
                    }
                }
            })
            .state('storm.new-job', {
                url: '/job/new',
                templateUrl: 'modules/ngx-frame.html',
                controller: 'JobController',
                controllerAs: 'vm',
                data: {
                    pageTitle: 'atheer.job.home.title',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showSideNavMenu: false,
                        sideNavMenuPosition: 'left',
                        showToolbar: false,
                        sideNavMenuSize: 15
                    }
                }
            })
            .state('storm.edit-job', {
                url: '/jobs/edit/:id',
                templateUrl: 'modules/ngx-frame.html',
                controller: 'JobController',
                controllerAs: 'vm',
                data: {
                    pageTitle: 'atheer.job.home.title',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showSideNavMenu: false,
                        sideNavMenuPosition: 'left',
                        showToolbar: false,
                        sideNavMenuSize: 15
                    }
                },
                resolve: {
                    id: function ($stateParams) {
                        return $stateParams.id;
                    }
                }
            });
    }
})();
