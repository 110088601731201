(function () {
    'use strict';

    angular
        .module('atheer.session')
        .controller('SessionPlayerImagesController', SessionPlayerImagesController);

    /* @ngInject */
    function SessionPlayerImagesController($scope, $state, $http, $window, $location, $q, $timeout, $filter, Report, sessionModel, userId, Experience, ParseLinks, $mdDialog, $mdToast, $stateParams, isMobileBrowser, hasRecording) {
        var vm = this;

        vm.loadingData = false;
        vm.transition = transition;
        vm.pageSize = 100;
        vm.mediaPathAnnotation = "/media/images/";
        vm.sessionModel = sessionModel;

        vm.togglePanel = function () {
            $mdSidenav('annotationsPanel').toggle()
                .then(function () {

                });
        };

        vm.getMediaUrl = function (sessionActivity) {
            if ($location.host().endsWith('atheer.my')) {
                return 'https://' + $location.host() + ':' + $location.port() + vm.mediaPathAnnotation + sessionActivity.properties.media_file_name;
            } else {
                return vm.mediaPathAnnotation + sessionActivity.properties.media_file_name;
            }
        }

        vm.download = function (sessionActivity, id) {
            var url = vm.getMediaUrl(sessionActivity);
            var downloadFileName = null;

            if (sessionActivity.properties.file_name) {
                downloadFileName = sessionActivity.properties.file_name;
            } else {
                downloadFileName = sessionActivity.properties.media_file_name;
            }

            $http.get(url, {
                responseType: "arraybuffer"
            })
                .success(function (data) {
                    var anchorTag = angular.element('<a/>');
                    var blob = new Blob([data]);

                    anchorTag.attr({
                        href: $window.URL.createObjectURL(blob),
                        target: '_blank',
                        download: downloadFileName
                    })[0].click();
                });
        }

        vm.downloadZip = function () {
            $mdDialog.show({
                templateUrl: 'modules/session/session-annotation-zip.tmpl.html',
                clickOutsideToClose: true,
                controller: "SessionAnnotationZipController",
                controllerAs: "vm",
                locals: {
                    sessionModel: vm.sessionModel
                },
            }).then(function (result) {

            });
        }

        vm.fullScreen = function (sessionActivity) {
            $mdDialog.show({
                animation: true,
                templateUrl: 'modules/session/session-annotation-fullscreen.tmpl.html',
                controller: 'sessionAnnotationFullscreenController',
                controllerAs: 'vm',
                hasBackdrop: true,
                backdrop: 'static',
                locals: {
                    sessionActivity: sessionActivity
                },
                size: 'lg'
            }).then(function () {

            });
        };

        vm.close = function () {
            $mdDialog.cancel();
        };

        function closeDialog() {
            var sessionActivities = null;
            if (vm.provideFeedback) {
                sessionActivities = {
                    user_id: vm.userId,
                    activity_type: "FEEDBACK",
                    object: vm.sessionModel,
                    properties: {
                        rating: vm.rating,
                        feedback: vm.ratingFeedback
                    }
                };
            }
            $mdDialog.hide(sessionActivities);
        };

        function transition() {
            vm.loadingData = true;
            vm.promise = Experience.getSessionActivities({
                page: vm.page - 1,
                size: vm.pageSize,
                sort: sort(),
                query: getQueryString(),
                user_info: true
            }, onTSuccess, onTError);

            function onTSuccess(data, headers) {
                vm.queryCount = headers('X-Total-Count');
                vm.sessionActivities = data;
                vm.loadingData = false;
            }

            function onTError(error) {
                AlertService.error(error.data.message);
            }
        };

        function sort() {
            return 'id,asc';
        };

        function getQueryString() {
            var queryString = "action_type==ANNOTATION_SAVED;object_id==" + vm.sessionModel.id;
            return queryString;
        };

        function loadData() {
            if (!vm.sessionModel) {
                return;
            }

            vm.loadingData = true;

            Experience.getSessionActivities({
                page: 0,
                size: vm.pageSize,
                sort: sort(),
                query: getQueryString(),
                user_info: true
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.queryCount = headers('X-Total-Count');
                vm.sessionActivities = data;
                vm.page = 1;
                vm.loadingData = false;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        };

        $scope.$watch('vm.sessionModel', function (newValue) {
            if (angular.isDefined(vm.sessionModel) && vm.sessionModel != null) {
                loadData();
            }
        });


    }
})();
