(function () {
    'use strict';

    angular
        .module('atheer.session')
        .service('SessionAnnotationService', SessionAnnotationService);

    /* @ngInject */
    function SessionAnnotationService(Upload, $filter) {
        var annotationImage;
        var service = {
            uploadScreenShot: uploadScreenShot,
            setAnnotationImage: setAnnotationImage,
            saveAnnotation: saveAnnotation,
            getPercentage: getPercentage,
            uploadScreenShotBlob: uploadScreenShotBlob
        };
        return service;

        function uploadScreenShot(sessionNo, screenShotData) {
            var image = convertdataURItoBlob(screenShotData.dataURI);
            image.name = sessionNo + "-screenshot-" + moment().format('YYYYMMDDHHmmss') + '.jpg';
            return upload(image, 'image', false, null);
        };

        function uploadScreenShotBlob(sessionNo, screenShotBlob) {
            screenShotBlob.name = sessionNo + "-screenshot-" + moment().format('YYYYMMDDHHmmss') + '.jpg';
            return upload(screenShotBlob, 'image', false, null);
        }

        function saveAnnotation(fileName, sessionId) {
            var image = convertdataURItoBlob(annotationImage);
            image.name = fileName;
            return upload(image, 'image', true, sessionId);
        };

        function upload(file, type, is_annotation, sessionId) {
            return Upload.upload({
                url: 'api/media/upload',
                data: {
                    'file': file,
                    'type': type,
                    'is_annotation': is_annotation,
                    'session_id': sessionId
                }
            });
        };

        function convertdataURItoBlob(dataURI) {
            var binary = atob(dataURI.split(',')[1]);
            var array = [];
            for (var i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i));
            }

            return new Blob([new Uint8Array(array)], {
                type: dataURI.split(',')[0]
            });
        };

        function setAnnotationImage(image) {
            annotationImage = image;
        };

        function getPercentage(a, b) {
            return Math.round((a / b) * 1000) / 1000;
        };
    }
})();
