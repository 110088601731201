(function() {
    'use strict';

    angular
        .module('atheer.sdk')
        .controller('SdkWebHooksController', SdkWebHooksController);

    /* @ngInject */
    function SdkWebHooksController($scope, $state, WebHook, Feature, ParseLinks, AlertService, pagingParams, paginationConstants, $mdDialog, $mdToast, $stateParams, $filter, ToastService) {
        var vm = this;

        vm.webHooksEnabled = Feature.isActive('feature_airsdk_webhooks');
        vm.loadAll = loadAll;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.reOrder = reOrder;
        vm.transition = transition;
        vm.illustartionEmptyScreenId = "web_hooks";
        vm.loadAll();

        vm.permissions = {
            create: 'create-web-hook',
            update: 'update-web-hook',
            delete: 'delete-web-hook',
            manage: 'manage-web-hook'
        };

        vm.query = {
            filter: '',
            limit: paginationConstants.itemsPerPage,
            order: '-id',
            page: 1
        };

        vm.filter = {
            options: {
                debounce: 500
            }
        };

        vm.removeFilter = removeFilter;
        vm.filter.show = false;

        vm.createObject = createObject;
        vm.editObject = editObject;
        vm.deleteObject = deleteObject;

        function loadAll() {
            vm.loadingData = true;
            WebHook.query({
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.webHooks = data;
                vm.page = pagingParams.page;
                vm.loadingData = false;
            }

            function onError(error) {
                vm.loadingData = false;
                AlertService.error(error.data.message);
            }
        }

        function reOrder(order) {
            vm.predicate = order;
            vm.transition();
        }

        function getSortOrder() {
            if (vm.predicate.startsWith('-')) {
                return vm.predicate.substr(1) + ',' + 'desc';
            } else {
                return vm.predicate + ',' + 'asc';
            }

        }

        function transition() {
            vm.promise = WebHook.query({
                page: vm.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: getSortOrder(),
                search: vm.currentSearch
            }).$promise;

            vm.promise.then(function(data, headers) {
                vm.queryCount = vm.totalItems;
                vm.webHooks = data;
            });
        }

        function removeFilter() {
            vm.filter.show = false;
            vm.query.filter = '';

            if (vm.filter.form.$dirty) {
                vm.filter.form.$setPristine();
            }
        }

        function createObject() {
            $state.go('storm.admin-airsdk-web-hook-detail');
        };

        function editObject(objectId) {
            $state.go('storm.admin-airsdk-web-hook-detail', {
                'id': objectId
            });
        };

        function deleteObject(entity) {
            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.webHook.deleteObject'))
                .ariaLabel($filter('translate')('atheer.webHook.deleteObject'))
                .ok($filter('translate')('entity.action.delete'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function() {
                WebHook.delete({
                    id: entity.id
                }, function() {
                    resetModal();
                });
            });
        };

        function resetModal() {
            loadAll();
            AlertService.displayToast($mdToast);
        };

        vm.showWebHookLogs = function(webHook) {
          $state.go('storm.admin-airsdk-web-hook-detail-logs', {
              'id': webHook.id
          });
        };

        vm.onEnableChange = function(webHook) {
            WebHook.update(webHook, function() {
                ToastService.displayToast(webHook.name + ' ' + $filter('translate')('atheer.content.enableUpdateSuccess'), 3000);
            }, function() {
                ToastService.displayToast($filter('translate')('atheer.content.enableUpdateError'), 3000);
            });
        };
    }
})();
