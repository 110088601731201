(function () {
    'use strict';

    angular
        .module('atheer.sdk')
        .controller('SdkMobileSdkController', SdkMobileSdkController);

    /* @ngInject */
    function SdkMobileSdkController($rootScope, $scope, $sce, $cookies, $q, Setting, $mdToast, $filter, $mdDialog, Feature) {
        var vm = this;

        vm.viewType = "doc";
        vm.webHooksEnabled = Feature.isActive('feature_airsdk_webhooks');
        vm.hostUrl = $sce.trustAsResourceUrl("https://light-sloth-6.redoc.ly/");
    }
})();
