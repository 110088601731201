(function() {
    'use strict';

    angular
        .module('atheer.system')
        .controller('AuthenticationGoogleController', AuthenticationGoogleController);

    /* @ngInject */
    function AuthenticationGoogleController($scope, $state, $q, Setting, $filter, $mdDialog, ToastService) {
        var vm = this;

        vm.loadingData = false;
        vm.configSettings = [];
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.googleKey = null;
        vm.googleSecret = null;
        vm.googleLensAndroidClientId = null;
        vm.googleLensiOSClientId = null;
        vm.googleLensiOSUrl = null;
        vm.twitterKey = null;
        vm.twitterSecret = null;
        vm.linkedInKey = null;
        vm.linkedInSecret = null;
        vm.facebookKey = null;
        vm.facebookSecret = null;

        vm.loadData = function() {
            vm.loadingData = true;

            $q.all([
                    Setting.getAuthenticationConfigSettings().$promise
                ])
                .then(function(results) {
                    setConfigFields(results[0]);

                    vm.loadingData = false;
                });
        };

        function setConfigFields(settings) {
            angular.forEach(settings, function(setting) {
                if (setting.category == 'authentication-config') {
                    vm.configSettings = setting;
                    angular.forEach(setting.fields, function(settingField) {
                        if (settingField.name == 'google_key') {
                            vm.googleKey = settingField.value;
                        } else if (settingField.name == 'google_secret') {
                            vm.googleSecret = settingField.value;
                        } else if (settingField.name == 'google_lens_android_client_id') {
                            vm.googleLensAndroidClientId = settingField.value;
                        } else if (settingField.name == 'google_lens_ios_client_id') {
                            vm.googleLensiOSClientId = settingField.value;
                        } else if (settingField.name == 'google_lens_ios_url') {
                            vm.googleLensiOSUrl = settingField.value;
                        } else if (settingField.name == 'facebook_key') {
                            vm.twitterKey = settingField.value;
                        } else if (settingField.name == 'facebook_secret') {
                            vm.twitterSecret = settingField.value;
                        } else if (settingField.name == 'linkedin_key') {
                            vm.linkedInKey = settingField.value;
                        } else if (settingField.name == 'linkedin_secret') {
                            vm.linkedInSecret = settingField.value;
                        } else if (settingField.name == 'twitter_key') {
                            vm.facebookKey = settingField.value;
                        } else if (settingField.name == 'twitter_secret') {
                            vm.facebookSecret = settingField.value;
                        }
                    });
                }
            });
        }

        function setConfigSettings() {
            if(vm.configSettings.fields == undefined) {
                vm.configSettings.fields = [];
            };

            setSettingField("google_key", vm.googleKey);
            setSettingField("google_secret", vm.googleSecret);
            setSettingField("google_lens_android_client_id", vm.googleLensAndroidClientId);
            setSettingField("google_lens_ios_client_id", vm.googleLensiOSClientId);
            setSettingField("google_lens_ios_url", vm.googleLensiOSUrl);
            setSettingField("facebook_key", vm.facebookKey);
            setSettingField("facebook_secret", vm.facebookSecret);
            setSettingField("linkedin_key", vm.linkedInKey);
            setSettingField("linkedin_secret", vm.linkedInSecret);
            setSettingField("twitter_key", vm.twitterKey);
            setSettingField("twitter_secret", vm.twitterSecret);
        };

        function setSettingField(fieldName, fieldValue) {
            var foundField = false;

            angular.forEach(vm.configSettings.fields, function(settingField) {
                if (settingField.name == fieldName) {
                    settingField.value = fieldValue;
                    foundField = true;
                }
            });

            if (!foundField) {
                var settingField = {
                    name: fieldName,
                    value: fieldValue,
                }
                vm.configSettings.fields.push(settingField);
            }
        };

        vm.save = function() {
            setConfigSettings();

            Setting.update(vm.configSettings, function() {
                ToastService.displayToast($filter('triTranslate')("atheer.setting.success"));
            });
        };

        vm.loadData();
    }
})();
