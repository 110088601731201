(function () {
    'use strict';

    angular
        .module('atheer.content')
        .controller('ContentDetailRecognitionQRCodeController', ContentDetailRecognitionQRCodeController)

    /* @ngInject */
    function ContentDetailRecognitionQRCodeController($scope, $timeout, $q, $filter, content_type, id, Content, $http) {
        var vm = this;

        vm.loadingData = false;
        vm.contentModel = $scope.$parent.vm.contentModel;
        vm.showActions = false;
        vm.qrCodeImage;

        vm.loadData = function () {
            if (id) {
                vm.loadingData = true;
                $timeout(function () {
                    vm.showActions = true;
                    vm.qrCodeLink = '/api/contents/' + id + '/qrcode';
                    $http.get(vm.qrCodeLink, { responseType: 'blob' }).then(function (response) {
                        vm.qrCodeImage = URL.createObjectURL(response.data);
                        vm.loadingData = false;
                    })
                }, 1000);
            };
        };

        vm.downloadQR = function () {
            var link = document.createElement('a');
            link.download = 'QR-Code',
                link.href = vm.qrCodeImage;
            link.click();
        };

        vm.printQR = function () {
            var printWin = window.open();
            printWin.document.open();
            printWin.document.write(ImagetoPrint());
            printWin.document.close();
        };

        function ImagetoPrint() {
            return "<html><head><script>function step1(){\n" +
                "setTimeout('step2()', 10);}\n" +
                "function step2(){window.print();window.close()}\n" +
                "</script></head><body onload='step1()'>\n" +
                "<img src='" + vm.qrCodeImage + "' /></body></html>";
        }

        vm.loadData();
    }
})();
