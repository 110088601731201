(function () {
    'use strict';

    angular
        .module('atheer')
        .factory('authExpiredInterceptor', authExpiredInterceptor);

    /* @ngInject */
    function authExpiredInterceptor($rootScope, $q, $injector, $cookies) {
        var service = {
            responseError: responseError
        };

        return service;

        function responseError(response) {
            if (response.status === 401) {
                localStorage.removeItem('Authorization');
                var Principal = $injector.get('Principal');
                if (Principal.isAuthenticated()) {
                    var Auth = $injector.get('Auth');
                    Auth.authorize(true);
                }
            }
            return $q.reject(response);
        }
    }
})();
