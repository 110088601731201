(function() {
    'use strict';

    angular
        .module('atheer')
        .controller('NoAccessController', NoAccessController);

    /* @ngInject */
    function NoAccessController($mdDialog, content, title, isDeeplink) {
        var vm = this;

        vm.content = content;
        vm.title = title;
        vm.isDeeplink = isDeeplink;
        vm.close = function() {
            $mdDialog.cancel();
            if (vm.isDeeplink) {
                window.history.back();
            }
        };
    }
})();
