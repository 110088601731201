(function() {
    'use strict';

    angular
        .module('atheer.session')
        .controller('SessionPlayerCloseController', SessionPlayerCloseController);

    /* @ngInject */
    function SessionPlayerCloseController($scope, $state, $q, $timeout, $filter, Report, sessionModel, userId, isEnd, Experience, $mdDialog, $mdToast, $stateParams, isMobileBrowser, hasRecording) {
        var vm = this;

        vm.loadingData = false;
        vm.sessionNo = null;
        vm.sessionModel = sessionModel;
        vm.isEnd = isEnd;
        vm.userId = userId;
        vm.provideFeedback = false;
        vm.rating = null;
        vm.ratingFeedback = null;
        vm.isMobileBrowser = isMobileBrowser;
        vm.hasRecording = hasRecording;

        vm.close = function() {
            $mdDialog.cancel();
        };

        vm.closeSession = function() {
            if (vm.hasRecording) {
                vm.loadingData = true;
                $timeout(function() {
                    vm.loadingData = false;
                    closeDialog();
                }, 5000);
            } else {
                closeDialog();
            };
        };

        function closeDialog() {
            var sessionActivities = null;
            if (vm.provideFeedback) {
                sessionActivities = {
                    user_id: vm.userId,
                    activity_type: "FEEDBACK",
                    object: vm.sessionModel,
                    properties: {
                        rating: vm.rating,
                        feedback: vm.ratingFeedback
                    }
                };
            }
            $mdDialog.hide(sessionActivities);
        };
    }
})();
