(function() {
    'use strict';

    angular
        .module('atheer')
        .config(idleConfig);

    /* @ngInject */
    function idleConfig(IdleProvider, KeepaliveProvider) {
        IdleProvider.idle(1);
        IdleProvider.timeout(0);
    }
})();
