(function() {

    'use strict';
    angular
        .module('atheer.anchor')
        .constant('ANCHOR_INSIGHT_META', {
            anchor: {
                sections: {
                    overview: {
                        label: 'atheer.anchor.overview',
                        icon: 'zmdi zmdi-account',
                        controller: 'AnchorInsightOverviewController',
                        template: 'modules/anchor/anchor-insight-overview.tmpl.html'
                    },
                    scan_history: {
                        label: 'atheer.anchor.scanHistory',
                        icon: 'zmdi zmdi-account',
                        controller: 'AnchorInsightScanHistoryController',
                        template: 'modules/anchor/anchor-insight-scan-history.tmpl.html'
                    }
                }
            }
        });
})();
