(function() {

    'use strict';
    angular
        .module('atheer.system')
        .constant('INTEGRATION_APP_META', {
            'adp': {
                icon: 'zmdi zmdi-account',
                logo: '/assets/images/integration/adp.png',
                controller: 'AppDetailController',
                template: 'modules/system/app/app-detail.tmpl.html'
            },
            'salesforce': {
                icon: 'zmdi zmdi-account',
                logo: '/assets/images/integration/salesforce.png',
                controller: 'SalesforceAppDetailController',
                template: 'modules/system/app/salesforce-app-detail.tmpl.html'
            },
            'slack': {
                icon: 'zmdi zmdi-account',
                logo: '/assets/images/integration/slack.png',
                controller: 'SlackAppDetailController',
                template: 'modules/system/app/slack-app-detail.tmpl.html'
            },
            'namely': {
                icon: 'zmdi zmdi-account',
                logo: '/assets/images/integration/namely.svg',
                controller: 'AppDetailController',
                template: 'modules/system/app/app-detail.tmpl.html'
            },
            'zenefits': {
                icon: 'zmdi zmdi-account',
                logo: '/assets/images/integration/zenefits.png',
                controller: 'AppDetailController',
                template: 'modules/system/app/app-detail.tmpl.html'
            },
            'google-app': {
                icon: 'zmdi zmdi-account',
                logo: '/assets/images/integration/google.png',
                controller: 'GoogleAppDetailController',
                template: 'modules/system/app/google-app-detail.tmpl.html'
            },
            'google-drive': {
                icon: 'zmdi zmdi-account',
                logo: '/assets/images/integration/google-drive.png',
                controller: 'GoogleDriveAppDetailController',
                template: 'modules/system/app/google-drive-app-detail.tmpl.html'
            },
            'workday': {
                icon: 'zmdi zmdi-account',
                logo: '/assets/images/integration/workday.png',
                controller: 'AppDetailController',
                template: 'modules/system/app/app-detail.tmpl.html'
            },
            'udemy': {
                icon: 'zmdi zmdi-account',
                logo: '/assets/images/integration/udemy.png',
                controller: 'AppDetailController',
                template: 'modules/system/app/app-detail.tmpl.html'
            },
            'lynda': {
                icon: 'zmdi zmdi-account',
                logo: '/assets/images/integration/lynda.png',
                controller: 'AppDetailController',
                template: 'modules/system/app/app-detail.tmpl.html'
            },
            'udacity' : {
                icon: 'zmdi zmdi-account',
                logo: '/assets/images/integration/udacity.png',
                controller: 'UdacityAppDetailController',
                template: 'modules/system/app/udacity-app-detail.tmpl.html'
            },
            'coursera': {
                icon: 'zmdi zmdi-account',
                logo: '/assets/images/integration/coursera.png',
                controller: 'AppDetailController',
                template: 'modules/system/app/app-detail.tmpl.html'
            },
            'pluralsight': {
                icon: 'zmdi zmdi-account',
                logo: '/assets/images/integration/pluralsight.png',
                controller: 'AppDetailController',
                template: 'modules/system/app/app-detail.tmpl.html'
            },
            'getabstract': {
                icon: 'zmdi zmdi-account',
                logo: '/assets/images/integration/getabstract.png',
                controller: 'AppDetailController',
                template: 'modules/system/app/app-detail.tmpl.html'
            },
            'tuts': {
                icon: 'zmdi zmdi-account',
                logo: '/assets/images/integration/tuts.png',
                controller: 'AppDetailController',
                template: 'modules/system/app/app-detail.tmpl.html'
            },
            'skillshare': {
                icon: 'zmdi zmdi-account',
                logo: '/assets/images/integration/skillshare.png',
                controller: 'AppDetailController',
                template: 'modules/system/app/app-detail.tmpl.html'
            },
            'codeschool': {
                icon: 'zmdi zmdi-account',
                logo: '/assets/images/integration/codeschool.png',
                controller: 'AppDetailController',
                template: 'modules/system/app/app-detail.tmpl.html'
            },
            'safari-books': {
                icon: 'zmdi zmdi-account',
                logo: '/assets/images/integration/safari-books.png',
                controller: 'AppDetailController',
                template: 'modules/system/app/app-detail.tmpl.html'
            },
            'khan-academy': {
                icon: 'zmdi zmdi-account',
                logo: '/assets/images/integration/khan-academy.png',
                controller: 'AppDetailController',
                template: 'modules/system/app/app-detail.tmpl.html'
            },
            'ted': {
                icon: 'zmdi zmdi-account',
                logo: '/assets/images/integration/ted.png',
                controller: 'AppDetailController',
                template: 'modules/system/app/app-detail.tmpl.html'
            },
            'vimeo': {
                icon: 'zmdi zmdi-account',
                logo: '/assets/images/integration/vimeo.png',
                controller: 'VimeoAppDetailController',
                template: 'modules/system/app/vimeo-app-detail.tmpl.html'
            },
            'edx': {
                icon: 'zmdi zmdi-account',
                logo: '/assets/images/integration/edx.png',
                controller: 'AppDetailController',
                template: 'modules/system/app/app-detail.tmpl.html'
            },
            'box': {
                icon: 'zmdi zmdi-account',
                logo: '/assets/images/integration/box.png',
                controller: 'BoxAppDetailController',
                template: 'modules/system/app/box-app-detail.tmpl.html'
            },
            'dropbox': {
                icon: 'zmdi zmdi-account',
                logo: '/assets/images/integration/dropbox.png',
                controller: 'DropboxAppDetailController',
                template: 'modules/system/app/dropbox-app-detail.tmpl.html'
            },
            'saba': {
                icon: 'zmdi zmdi-account',
                logo: '/assets/images/integration/saba.png',
                controller: 'AppDetailController',
                template: 'modules/system/app/app-detail.tmpl.html'
            },
            'sumtotal': {
                icon: 'zmdi zmdi-account',
                logo: '/assets/images/integration/sumtotal.png',
                controller: 'AppDetailController',
                template: 'modules/system/app/app-detail.tmpl.html'
            },
            'successfactors': {
                icon: 'zmdi zmdi-account',
                logo: '/assets/images/integration/successfactors.png',
                controller: 'AppDetailController',
                template: 'modules/system/app/app-detail.tmpl.html'
            },
            'csod': {
                icon: 'zmdi zmdi-account',
                logo: '/assets/images/integration/csod.png',
                controller: 'AppDetailController',
                template: 'modules/system/app/app-detail.tmpl.html'
            },
            'sap': {
                icon: 'zmdi zmdi-account',
                logo: '/assets/images/integration/sap.png',
                controller: 'AppDetailController',
                template: 'modules/system/app/app-detail.tmpl.html'
            },
            'oracle': {
                icon: 'zmdi zmdi-account',
                logo: '/assets/images/integration/oracle.png',
                controller: 'AppDetailController',
                template: 'modules/system/app/app-detail.tmpl.html'
            },
            'peoplesoft': {
                icon: 'zmdi zmdi-account',
                logo: '/assets/images/integration/peoplesoft.png',
                controller: 'AppDetailController',
                template: 'modules/system/app/app-detail.tmpl.html'
            },
            'msft-office': {
                icon: 'zmdi zmdi-account',
                logo: '/assets/images/integration/msft-office.png',
                controller: 'MicrosoftAppDetailController',
                template: 'modules/system/app/microsoft-app-detail.tmpl.html'
            },
            'msft-onedrive': {
                icon: 'zmdi zmdi-account',
                logo: '/assets/images/integration/msft-onedrive.png',
                controller: 'OneDriveAppDetailController',
                template: 'modules/system/app/onedrive-app-detail.tmpl.html'
            },
            'scorm-cloud': {
                icon: 'zmdi zmdi-account',
                logo: '/assets/images/integration/scorm-cloud.png',
                controller: 'ScormCloudAppDetailController',
                template: 'modules/system/app/scorm-cloud-app-detail.tmpl.html'
            },
            'watershed': {
                icon: 'zmdi zmdi-account',
                logo: '/assets/images/integration/watershed.png',
                controller: 'WatershedAppDetailController',
                template: 'modules/system/app/watershed-app-detail.tmpl.html'
            },
            'wistia': {
                icon: 'zmdi zmdi-account',
                logo: '/assets/images/integration/wistia.png',
                controller: 'OneDriveAppDetailController',
                template: 'modules/system/app/onedrive-app-detail.tmpl.html'
            },
            'zoom': {
                icon: 'zmdi zmdi-account',
                logo: '/assets/images/integration/zoom.png',
                controller: 'ZoomAppDetailController',
                template: 'modules/system/app/zoom-app-detail.tmpl.html'
            }
        });
})();
