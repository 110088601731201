(function() {
    'use strict';

    angular
        .module('atheer.session')
        .controller('sessionAnnotationFullscreenController', sessionAnnotationFullscreenController);

    /* @ngInject */
    function sessionAnnotationFullscreenController($scope, $stateParams, $mdDialog, $mdToast, $filter, sessionActivity, UserInvitation, $location) {
        var vm = this;
        vm.sessionActivity = sessionActivity;
        vm.mediaPathAnnotation = "/media/images/";

        vm.clear = function() {
            $mdDialog.hide();
        };

        vm.getMediaUrl = function() {
            if ($location.host().endsWith('atheer.my')) {
                return 'https://' + $location.host() + ':' + $location.port() + vm.mediaPathAnnotation + vm.sessionActivity.properties.media_file_name;
            } else {
                return vm.mediaPathAnnotation + vm.sessionActivity.properties.media_file_name;
            }
        }

    }
})();

