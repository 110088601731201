(function() {
    'use strict';
    angular
        .module('atheer.services')
        .factory('System', System);

    /* @ngInject */
    function System($resource) {
        var resourceUrl = 'api/system/settings/:id';

        return $resource(resourceUrl, {
            id: '@id'
        }, {
            'getTimezones': {
                method: 'GET',
                isArray: true,
                url: 'api/system/timezones'
            },
            'getLanguages': {
                method: 'GET',
                isArray: true,
                url: 'api/system/languages'
            },
            'getInsights': {
                method: 'GET',
                url: 'api/reports/master-dashboard'
            },
            'getCountryCodes': {
                method: 'GET',
                isArray: true,
                url: 'api/system/country-codes'
            }
        });
    }
})();
