(function() {
    'use strict';

    angular
        .module('atheer.content')
        .controller('ContentDetailInsightUserSummaryController', ContentDetailInsightUserSummaryController)

    /* @ngInject */
    function ContentDetailInsightUserSummaryController($scope, $mdDialog, content_type, id, ParseLinks, pagingParams, paginationConstants, Experience) {
        var vm = this;

        vm.loadingData = false;
        vm.contentType = content_type;
        vm.contentModel = $scope.$parent.vm.contentModel;
        vm.contentMetaData = $scope.$parent.vm.contentMetaData;

        if (vm.contentType == 'course') {
            vm.scoringEnabled = true;
        } else {
            vm.scoringEnabled = vm.contentModel.data.enable_scoring;
        }

        if (vm.contentType == 'smart_content' || vm.contentType == 'series') {
            vm.showCardSummary = true;
        } else {
            vm.showCardSummary = false;
        }

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;

        vm.assignments = [];

        vm.query = {
            filter: '',
            limit: paginationConstants.itemsPerPage,
            order: '-id',
            page: 1
        };

        vm.loadData = function() {
            vm.loadingData = true;
            //fetch pins data
            Experience.getAssignments({
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort(),
                query: getQueryString(),
                user_info: true
            }, function(response, headers) {
                vm.assignments = response;
                vm.page = pagingParams.page;
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;

                vm.loadingData = false;
            });
        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function getQueryString() {
            var queryString = "object_id==" + id;
            return queryString;
        }

        vm.deleteObject = function(entity) {
            var confirm = $mdDialog.confirm()
                .title('Are you sure you want to delete this Assignment?')
                .ariaLabel('Are you sure you want to delete this Assignment?')
                .ok('Delete')
                .cancel('Cancel');

            $mdDialog.show(confirm).then(function() {
                Experience.deleteAssignment({
                    id: entity.id
                }, function() {
                    vm.loadData();
                });
            });
        };

        vm.transition = function() {
            vm.promise = Experience.getAssignments({
                page: vm.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort(),
                query: getQueryString(),
                user_info: true
            }).$promise;

            vm.promise.then(function(response, headers) {
                vm.assignments = response;
                vm.queryCount = vm.totalItems;
            });
        }

        vm.launchUserCardSummary = function(userId) {
            if (vm.contentType == 'smart_content') {
                $mdDialog.show({
                    templateUrl: 'modules/content/smart-content-insight-user-card-summary.tmpl.html',
                    clickOutsideToClose: true,
                    controller: "SmartContentDetailInsightUserCardSummaryController",
                    controllerAs: "vm",
                    locals: {
                        content: vm.contentModel,
                        userId: userId,
                        title: 'User Card Summary'
                    }
                });
            } else if (vm.contentType == 'series') {
                $mdDialog.show({
                    templateUrl: 'modules/content/series-insight-module-user-summary.tmpl.html',
                    clickOutsideToClose: true,
                    controller: "SeriesInsightModuleUserSummaryController",
                    controllerAs: "vm",
                    locals: {
                        userId: userId,
                        content: vm.contentModel,
                        title: 'User Module Summary'
                    }
                });
            }
        }

        vm.markComplete = function(assignment) {
            var responseData = {
                object: assignment.object,
                response: {
                    type: assignment.object.data.type,
                    choice: undefined,
                    correct: true,
                    points: 0
                },
                user_id: assignment.user_info.id,
                assignment_id: assignment.id,
                context_id: null
            }

            Experience.createResponse(responseData, function(result) {
                vm.loadData();
            });
        }

        vm.loadData();
    }
})();
