(function() {
    'use strict';

    angular
        .module('atheer')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('error', {
                parent: 'app',
                url: '/error',
                data: {
                    pageTitle: 'atheer.error.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'core/components/error/error.html'
                    }
                },
                resolve: {
                }
            })
            .state('accessdenied', {
                parent: 'app',
                url: '/accessdenied',
                views: {
                    'content@': {
                        templateUrl: 'core/components/error/accessdenied.html'
                    }
                },
                resolve: {
                }
            });
    }
})();
