(function() {
    'use strict';

    angular
        .module('atheer.user')
        .controller('UserDetailToolbarController', UserDetailToolbarController);

    /* @ngInject */
    function UserDetailToolbarController($rootScope, $q, $scope, $state, PermissionService, Auth, Principal, $translate, $mdDialog, $mdToast, $mdMenu, $filter, id, User, Experience, MetaData, Report, Feature, $window) {
        var vm = this;

        vm.user = null;
        vm.userId = id;
        vm.profileViewMode = id == 'me' ? 'me' : 'admin';
        vm.loadingData = false;
        vm.isAdminMode = vm.profileViewMode == 'admin';
        vm.isMeMode = vm.profileViewMode == 'me';
        vm.isBoardActive = false;
        vm.isMyUploadActive = false;

        vm.loadData = function() {
            vm.loadingData = true;

            $q.all([
                User.getById({
                    id: id
                }).$promise

            ]).then(function(data) {
                vm.user = data[0];
                vm.isMyUploadActive = Feature.isActive("feature_content_allow_upload") && hasPermission('create-content');
                vm.loadingData = false;

            }).catch(function(data) {}).finally(function() {});
        }

        function hasPermission(permission) {
            return PermissionService.hasAccess(permission);
        }

        vm.goToUsers = function() {
            $state.go("storm.admin-users");
        };

        vm.back = function() {
            $window.history.back();
        };

        vm.loadData();
    }
})();
