(function () {
    'use strict';

    angular
        .module('atheer.system')
        .controller('FieldDialogController', FieldDialogController);

    /* @ngInject */
    function FieldDialogController($scope, $stateParams, $mdDialog, field, isAdd, _) {
        var vm = this;
        vm.field = field;
        vm.isAdd = isAdd;

        vm.add = function () {
            $mdDialog.hide(vm.field);
        };

        vm.clear = function () {
            $mdDialog.cancel();
        };

        vm.setFieldName = function () {
            if ( vm.isAdd ) {
                vm.field.name = _.underscored(vm.field.label);
            }
        }

        vm.removeOption = function (index) {
            vm.field.list_options.splice(index, 1);
        }

        vm.copyOption = function (item) {
            var option = angular.copy(item);
            item.option_id = Math.floor((Math.random() * 10000) + 1);
            vm.field.list_options.push(option);
        }

        vm.addNewOption = function () {
            if (vm.field.list_options == null || vm.field.list_options == undefined) {
                vm.field.list_options = [];
            }
            vm.field.list_options.push({
                label: null,
                description: null,
                option_id: Math.floor((Math.random() * 10000) + 1)
            });
        }
    }
})();
