(function() {
    'use strict';

    angular
        .module('atheer.system')
        .controller('NotificationController', NotificationController);

    /* @ngInject */
    function NotificationController($scope, $state, Notification, ParseLinks, AlertService, pagingParams, paginationConstants, $mdDialog, $filter, $stateParams, ToastService) {
        var vm = this;

        vm.loadingData = false;
        vm.loadAll = loadAll;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.reOrder = reOrder;

        vm.query = {
            filter: '',
            limit: '100',
            order: '-id',
            page: 1
        };

        vm.filter = {
            options: {
                debounce: 500
            }
        };

        vm.removeFilter = removeFilter;
        vm.filter.show = false;

        vm.editObject = editObject;

        vm.loadAll();

        function loadAll() {
            vm.loadingData = true;

            Notification.query({
                page: pagingParams.page - 1,
                size: 100,
                sort: sort(),
                query: getQueryString()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.notifications = data;
                vm.page = pagingParams.page;
                vm.loadingData = false;
            }

            function onError(error) {
                AlertService.error(error.data.message);
                vm.loadingData = false;
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function getQueryString() {
            if (vm.query.filter.length <= 0) {
                return 'purpose==Generic;name=out=(conversationSendMessage,conversationUserInvite)';
            } else {
                return 'purpose==Generic;name=out=(conversationSendMessage,conversationUserInvite);name=re=' + vm.query.filter;
            }
        }

        function reOrder(order) {
            vm.predicate = order;
            vm.reverse = !vm.reverse;
            vm.transition();
        }

        function transition() {
            vm.promise = Notification.query({
                page: vm.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                query: getQueryString()
            }).$promise;

            vm.promise.then(function(data, headers) {
                vm.queryCount = vm.totalItems;
                vm.notifications = data;
            });
        }

        function removeFilter() {
            vm.filter.show = false;
            vm.query.filter = '';

            if (vm.filter.form.$dirty) {
                vm.filter.form.$setPristine();
            }
        }

        function editObject(objectId) {
            $state.go('storm.admin-notification-detail', {
                'id': objectId
            });
        }

        function resetModal() {
            loadAll();
        };

        vm.toggleEmail = function(notification) {
            Notification.update(notification, vm.onSaveSuccess, vm.onSaveError);
        };

        vm.togglePush = function(notification) {
            Notification.update(notification, vm.onSaveSuccess, vm.onSaveError);
        };

        vm.toggleMessage = function(notification) {
            Notification.update(notification, vm.onSaveSuccess, vm.onSaveError);
        };

        vm.onSaveSuccess = function(result) {
            resetModal();
        };

        vm.onSaveError = function() {};

        vm.testNotification = function(objectId) {
            Notification.test({
                id: objectId
            }, function(result) {
                ToastService.displayToast($filter('triTranslate')("atheer.notification.testNotificationSuccess"), 3000);
            });
        }
    }
})();
