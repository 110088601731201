(function() {
    'use strict';

    angular
        .module('atheer.user')
        .controller('UserDetailDialogController', UserDetailDialogController);

    /* @ngInject */
    function UserDetailDialogController($scope, $stateParams,Feature, $mdDialog, entity, User) {
        var vm = this;
        vm.user = entity;
        vm.isSavingError = false;
        vm.errorMessage = '';
        vm.showPassword = false;
        vm.showConfirmPassword = false;
        vm.featureForcedPassword = Feature.isActive('feature_user_initial_login_forced_password_required');
        vm.user.notify_user = true;
        var re = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,50}$/);
        var reWhiteSpace = /\s/;

        var onSaveSuccess = function(result) {
            $scope.$emit('atheer:userUpdate', result);
            $mdDialog.hide(result);
            vm.isSaving = false;
        };

        var onSaveError = function(err) {
            vm.isSaving = false;
            vm.isSavingError = true;
            if(err) {
                var obj = err.headers();
                vm.errorMessage = obj['x-atheer-error-message'];
            }
        };

        vm.checkPassword = function(){
            if((vm.user.password !== vm.confirm_password) && vm.confirm_password ){
                vm.profileForm.confirm_password.$setValidity('passwordNoMatchErr', false);
            }
            else {
                vm.profileForm.confirm_password.$setValidity('passwordNoMatchErr', true);
            }
            if(!re.test(vm.user.password)) {
                vm.profileForm.password.$setValidity('passwordPolicyErr', false);
            }
            else {
                vm.profileForm.password.$setValidity('passwordPolicyErr', true);
            }
        }

        vm.checkLogin = function(){
            if (vm.user.login !== undefined && (reWhiteSpace.test(vm.user.login) || vm.user.login.indexOf(' ') > 0)) {
                vm.profileForm.login.$setValidity('loginWhiteSpaceError', false);
            } else {
                vm.profileForm.login.$setValidity('loginWhiteSpaceError', true);
            }
        }

        vm.save = function() {
            vm.isSaving = true;
            if ( vm.user.notify_user !== undefined && vm.user.notify_user == true ) {
                vm.user.forced_password_enabled = false;
            }
            User.save(vm.user, onSaveSuccess, onSaveError);
        };

        vm.clear = function() {
            $mdDialog.hide();
        };

        vm.forcePasswordToggle = function() {
            if ( vm.user.notify_user !== undefined && vm.user.notify_user == true ) {
                vm.user.forced_password_enabled = false;
            }
        }

        vm.dynamicTitle = null !== vm.user.id ? 'atheer.userManagement.home.editLabel' : 'atheer.userManagement.home.createLabel';
    }
})();
