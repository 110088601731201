(function () {
    'use strict';

    angular
        .module('atheer.session')
        .directive('sessionAnnotationArBuilder', sessionAnnotationArBuilder);

    function sessionAnnotationArBuilder() {
        var directive = {
            scope: {
                annotationData: '=',
                sessionId: '=',
                userId: '='
            },
            bindToController: true,
            controller: sessionAnnotationArBuilderController,
            controllerAs: 'vm',
            templateUrl: 'modules/session/session-annotation-ar-builder.tmpl.html',
            restrict: 'E'
        };
        return directive;
    };

    /* @ngInject */
    function sessionAnnotationArBuilderController($rootScope, $scope, PubNubService, SessionAnnotationService) {
        var vm = this;

        var annotationToolEnum = {
            pencil: "PENCIL",
            laser: "LASER",
            arrow: "ARROW",
            text: "TEXT",
            undo: "UNDO",
            clear: "CLEAR"
        };
        var colorPrefix = '#';
        var textParams = {
            width: 200,
            size: 20,
            text: '',
            height: 30
        };

        var toolChangeListener = null;
        var setBrushColorListener = null;
        var resizeVideoListener = null;
        var sceneReadyListener = null;
        var showLoaderListener = null;
        var pubNubSessionListener = null;

        vm.selectedTool = vm.annotationData.type == 'LIVE' ? annotationToolEnum.laser : annotationToolEnum.pencil;
        vm.arCanvas = null;
        vm.brushColor = 'FFB800';
        vm.brushWidth = 2.5;
        vm.isAnnotationEditAllowed = vm.annotationData.allowAnnotationEdit;
        vm.isLaserUpdate = false;
        vm.laserPreviousPoint = null;
        vm.isArrowUpdate = false;
        vm.isPencilUpdate = false;
        vm.annotationType  = vm.annotationData.type;
        vm.isFreezeFrame = !vm.annotationData.allowAnnotationEdit ? true : false;
        vm.isTextBox = false;
        vm.text = null;
        vm.textPointer = null;
        vm.previousSelectedTool = null;
        vm.laserPoints = [];

        if(vm.annotationData.annotationFrom !== 'FREEZE_FRAME'){
            vm.showLoader = vm.annotationType == 'AR' ? true : false;
        }

        function onLoad() {
            vm.arCanvas = new fabric.Canvas('annotation-ar-canvas', { isDrawingMode: false });
            vm.arCanvas.setWidth(vm.annotationData.videoWidth);
            vm.arCanvas.setHeight(vm.annotationData.videoHeight);
            fabric.Object.prototype.selectable = false;
            vm.arCanvas.setZoom(1);
            vm.arCanvas.selection = false;
            vm.arCanvas.freeDrawingBrush.width = vm.brushWidth;
            vm.arCanvas.freeDrawingBrush.color = colorPrefix + vm.brushColor;
            vm.laserBrush = new fabric.LaserBrush(vm.arCanvas);
            vm.arrow = new Arrow(vm.arCanvas, vm.brushWidth, true);

            vm.arCanvas.on('mouse:down', vm.startDraw);
            vm.arCanvas.on('mouse:move', vm.updateDraw);
            vm.arCanvas.on('mouse:up', vm.endDraw);

            toolChangeListener = $scope.$on('tool-change', vm.updateTool);
            setBrushColorListener = $scope.$on('set-brush-color', setOrUpdateBrushColor);
            resizeVideoListener = $scope.$on('resize-video', onResizeVideo);
            sceneReadyListener = $scope.$on('scene-ready', sceneReady);
            showLoaderListener = $scope.$on('show-loader', showLoader);
        }

        vm.updateTool = function (event, tool) {
            vm.previousSelectedTool = vm.selectedTool;
            vm.selectedTool = tool.tool;
            if (tool.tool === annotationToolEnum.undo) {
                vm.onUndoClick();
                vm.selectedTool = vm.previousSelectedTool;
            } else if (tool.tool === annotationToolEnum.clear) {
                vm.onClearClick();
                vm.selectedTool = vm.previousSelectedTool;
            }
        };

        vm.startDraw = function (event) {
            var tx = SessionAnnotationService.getPercentage(event.pointer.x, vm.arCanvas.width);
            var ty = SessionAnnotationService.getPercentage(event.pointer.y, vm.arCanvas.height);

            var createLaserData = {
                x: tx,
                y: ty,
                color: vm.brushColor
            };
            var createMessage = {
                subject: 'SESSION',
                action: vm.annotationType === 'LIVE' ? 'ANNOTATION_LIVE' : 'ANNOTATION_AR',
                data: createLaserData
            };
            vm.arCanvas.freeDrawingBrush.color = colorPrefix + vm.brushColor;
            if (vm.selectedTool === annotationToolEnum.laser) {
                vm.isLaserUpdate = true;
                vm.laserPreviousPoint = event.pointer;
                createMessage.type = 'LASER_CREATE';
            } else if (vm.selectedTool === annotationToolEnum.arrow && vm.annotationType === 'LIVE') {
                vm.isArrowUpdate = true;
                vm.arrow.onMouseDown(event);
                createMessage.type = 'ARROW_CREATE';
            } else if (vm.selectedTool === annotationToolEnum.pencil) {
                vm.isPencilUpdate = true;
                createMessage.type = 'PENCIL_CREATE';
            } else if (vm.selectedTool === annotationToolEnum.arrow && vm.annotationType === 'AR') {
                createMessage.type = 'ARROW_CREATE';
            } else if (vm.selectedTool === annotationToolEnum.text) {
                vm.isTextBox = true;
                vm.text = null;
                vm.textPointer = event.pointer;
                createMessage.type = 'AR_TEXT_CREATE';
            }
            publishMessage(createMessage);
        };

        vm.updateDraw = function (event) {
            var tx = SessionAnnotationService.getPercentage(event.pointer.x, vm.arCanvas.width);
            var ty = SessionAnnotationService.getPercentage(event.pointer.y, vm.arCanvas.height);
            var updateData = {
                x: tx,
                y: ty
            };

            var updateMessage = {
                subject: 'SESSION',
                action: vm.annotationType === 'LIVE' ? 'ANNOTATION_LIVE' : 'ANNOTATION_AR',
                data: updateData
            }
            if (vm.selectedTool === annotationToolEnum.laser && vm.isLaserUpdate) {
                var points = [vm.laserPreviousPoint, event.pointer];
                if(vm.laserPoints.length == 25) {
                    updateMessage.type = 'LASER_UPDATE';
                    updateMessage.data = {
                        points: vm.laserPoints
                    };
                    publishMessage(updateMessage);
                    vm.laserPoints = [];
                }
                vm.laserPoints.push(updateData);
                vm.laserBrush.onMouseMove(points);
                vm.laserPreviousPoint = event.pointer;
            } else if (vm.selectedTool === annotationToolEnum.arrow && vm.isArrowUpdate && vm.annotationType === 'LIVE') {
                vm.arrow.onMouseMove(event);
            } else if (vm.selectedTool === annotationToolEnum.pencil && vm.isPencilUpdate) {
                updateMessage.type = 'PENCIL_UPDATE';
                publishMessage(updateMessage);
            }
        };
        vm.endDraw = function (event) {
            var tx = SessionAnnotationService.getPercentage(event.pointer.x, vm.arCanvas.width);
            var ty = SessionAnnotationService.getPercentage(event.pointer.y, vm.arCanvas.height);
            var confirmData = {
                x: tx,
                y: ty
            };
            var confirmMessage = {
                subject: 'SESSION',
                action: vm.annotationType === 'LIVE' ? 'ANNOTATION_LIVE' : 'ANNOTATION_AR'
            };
            if (vm.selectedTool === annotationToolEnum.laser) {
                vm.laserPreviousPoint = null;
                vm.isLaserUpdate = false;
                confirmMessage.type = 'LASER_UPDATE';
                confirmMessage.data = {
                    points: vm.laserPoints
                };
                vm.laserPoints = [];
                publishMessage(confirmMessage);
            } else if (vm.selectedTool === annotationToolEnum.arrow && vm.annotationType === 'LIVE') {
                vm.isArrowUpdate = false;
                confirmMessage.type = 'ARROW_CONFIRM';
                confirmMessage.data = confirmData;
                publishMessage(confirmMessage);
                vm.arrow.onMouseUp();
            } else if (vm.selectedTool === annotationToolEnum.pencil) {
                vm.isPencilUpdate = false;
                confirmMessage.type = 'PENCIL_CONFIRM';
                publishMessage(confirmMessage);
            }
        };

        vm.onUndoClick = function () {
            var undoMessage = {
                subject: 'SESSION',
                action: 'ANNOTATION_AR',
                type: 'UNDO'
            };

            publishMessage(undoMessage);
        };

        vm.onClearClick = function () {
            var clearMessage = {
                subject: 'SESSION',
                action: 'ANNOTATION_AR',
                type: 'CLEAR'
            };

            publishMessage(clearMessage);
        };

        vm.addText = function () {
            vm.isTextBox = false;
            if(vm.text !== null) {

                addText(vm.text, vm.textPointer, textParams.width, textParams.height, textParams.size);
                vm.onTextAdd();

                var objects = vm.arCanvas.getObjects();
                var textObj = objects[objects.length - 1];

                vm.arCanvas.remove(textObj);
            }
        }

        vm.onTextAdd = function () {
            var objects = vm.arCanvas.getObjects();
            var textObj = objects[objects.length - 1];
            if (textObj.text !== '') {
                var textData = {
                    width: textObj.aCoords.br.x - textObj.aCoords.tl.x,
                    height: textObj.aCoords.br.y - textObj.aCoords.tl.y,
                    lines: textObj.textLines
                };
                var textMessage = {
                    subject: 'SESSION',
                    action: 'ANNOTATION_AR',
                    type: 'AR_TEXT_UPDATE',
                    data: textData
                };
                publishMessage(textMessage);
            }
        };

        function addText(text, point, width, height, fontSize) {
            var textBox = new fabric.Textbox(text, {
                fontFamily: 'Roboto',
                left: point.x,
                top: point.y,
                fill: colorPrefix + vm.brushColor,
                fontSize: fontSize,
                textAlign: 'left',
                editable: false,
                width: width,
                height: height
            });
            vm.arCanvas.add(textBox);
            vm.arCanvas.setActiveObject(textBox);
            textBox.enterEditing();
        };

        function onResizeVideo(event, data) {
            var videoWidth = data.data.width;
            var videoHeight = data.data.height;
            var width, height;
            var videoContainer = document.getElementById('annotationARContainer');
            width = videoWidth < videoContainer.clientWidth ? videoWidth : videoContainer.clientWidth;
            height = videoHeight < videoContainer.clientHeight ? videoHeight : videoContainer.clientHeight;
            vm.arCanvas.setWidth(width);
            vm.arCanvas.setHeight(height);
        };

        function setOrUpdateBrushColor(event, color) {
            vm.brushColor = color;
            vm.arCanvas.freeDrawingBrush.color = colorPrefix + color;
        };

        function sceneReady(event, isSceneReady) {
            vm.showLoader = !isSceneReady;
        }

        function showLoader(event, isLoader) {
            vm.showLoader = isLoader;
        }

        function publishMessage(message) {
            PubNubService.publish(vm.sessionId, message);
        };

        function cleanUp() {
            vm.arCanvas.off('mouse:down', vm.startDraw);
            vm.arCanvas.off('mouse:move', vm.updateDraw);
            vm.arCanvas.off('mouse:up', vm.endDraw);
            toolChangeListener = null;
            setBrushColorListener = null;
            resizeVideoListener = null;
            sceneReadyListener = null;
            showLoaderListener = null;
        };

        function handleMessage(message) {
            if ((message.message.action === 'ANNOTATION_LIVE' || message.message.action === 'ANNOTATION_AR') && message.message.type === 'LASER_CREATE') {
                beginLaserPathDraw(message.message.data);
            } else if ((message.message.action === 'ANNOTATION_LIVE' || message.message.action === 'ANNOTATION_AR') && message.message.type === 'LASER_UPDATE') {
                updateLaserPathDraw(message.message.data);
            } else if (message.message.action === 'ANNOTATION_LIVE' && message.message.type === 'ARROW_CREATE') {
                beginArrow(message.message.data);
            } else if (message.message.action === 'ANNOTATION_LIVE' && message.message.type === 'ARROW_UPDATE') {
                updateArrow(message.message.data);
            } else if (message.message.action === 'ANNOTATION_LIVE' && message.message.type === 'ARROW_CONFIRM') {
                endArrow(message.message.data);
            }
        };

        function beginLaserPathDraw(data) {
            var rx = data.x * vm.arCanvas.width;
            var ry = data.y * vm.arCanvas.height;
            vm.arCanvas.freeDrawingBrush.color = colorPrefix + data.color;

            var pointer = {
                x: rx,
                y: ry
            };

            vm.laserPreviousPoint = pointer;
        };

        function updateLaserPathDraw(laserPathData) {
            angular.forEach(laserPathData.points, function(data){
                var rx = data.x * vm.arCanvas.width;
                var ry = data.y * vm.arCanvas.height;
                var pointer = {
                    x: rx,
                    y: ry
                };
                var points = [vm.laserPreviousPoint, pointer];
                vm.laserBrush.onMouseMove(points);
                vm.laserPreviousPoint = pointer;
            });
        };

        function beginArrow(data) {
            var rx = data.x * vm.arCanvas.width;
            var ry = data.y * vm.arCanvas.height;
            vm.arCanvas.freeDrawingBrush.color = colorPrefix + data.color;
            var event = {
                pointer: {
                    x: rx,
                    y: ry
                }
            };
            vm.arrow.onMouseDown(event);
        };

        function updateArrow(data) {
            var rx = data.x * vm.arCanvas.width;
            var ry = data.y * vm.arCanvas.height;
            var event = {
                pointer: {
                    x: rx,
                    y: ry
                }
            };
            vm.arrow.onMouseMove(event);
        };

        function endArrow(data) {
            updateArrow(data);
            vm.arrow.onMouseUp();
        };

        $scope.$on('$destroy', function() {
            console.log("Studio-Session-ANNOTATION-AR-Destroy");
            pubNubSessionListener = null;
            cleanUp();
        });

        pubNubSessionListener = $scope.$on('sessionMessagePubNub', function(event, message) {
            $scope.$apply(function() {
                if (message.message.subject == 'SESSION' && message.publisher != vm.userId) {
                    console.log("Studio-Session-Pubnub-Message", message.publisher, vm.userId, message);
                    handleMessage(message);
                };
            });
        });

        onLoad();
    }

})();
