(function() {
    'use strict';

    angular
        .module('atheer.content')
        .controller('ContentSelectorDialogController', ContentSelectorDialogController);

    /* @ngInject */
    function ContentSelectorDialogController($scope, $state, action, $mdDialog) {
        var vm = this;
        vm.action = action;

        if (action == 'Recommend') {
            vm.data = {
                contents: [],
                note: null
            };
        }
        else
        {
            vm.data = {
                contents: [],
                required: false,
                is_due_date_relative: 'true',
                relative_period: 'DAYS',
                relative_value: 5,
                due_on: moment().add(5, 'd').toDate(),
                type: 'TODO',
                note: null
            };
        }


        vm.close = function() {
            $mdDialog.cancel();
        }

        vm.submit = function() {
            $mdDialog.hide(vm.data);
        }
        vm.queryFilter = {
            'Assign': [{ fieldName: 'title', operator: '=re=' }, { fieldName: 'meta_type', operator: '==', value: 'DISCOVERABLE' }],
            'Ask': [{ fieldName: 'title', operator: '=re=' }, { fieldName: 'meta_type', operator: '==', value: 'QUESTION' }],
            'Recommend': [{ fieldName: 'title', operator: '=re=' }, { fieldName: 'meta_type', operator: '==', value: 'DISCOVERABLE' }]
        }
    }
})();
