(function() {
    'use strict';

    angular
        .module('atheer.core')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider, $urlRouterProvider) {
        // Setup the apps routes
        // 404 & 500 pages
        $stateProvider
            .state('storm', {
                abstract: true,
                views: {
                    'root': {
                        templateProvider: function($templateRequest, triLayout) {
                            if (angular.isDefined(triLayout.layout.contentLayoutTemplateUrl)) {
                                return $templateRequest(triLayout.layout.contentLayoutTemplateUrl);
                            }
                        },
                        controllerProvider: function(triLayout) {
                            return triLayout.layout.contentLayoutController;
                        },
                        controllerAs: 'stateController'
                    },
                    'sidebarLeft@storm': {
                        templateProvider: function($templateRequest, triLayout) {
                            if (angular.isDefined(triLayout.layout.sidebarLeftTemplateUrl)) {
                                return $templateRequest(triLayout.layout.sidebarLeftTemplateUrl);
                            }
                        },
                        controllerProvider: function(triLayout) {
                            return triLayout.layout.sidebarLeftController;
                        },
                        controllerAs: 'vm'
                    },
                    'sideMenu@storm': {
                        templateProvider: function($templateRequest, triLayout) {
                            if (angular.isDefined(triLayout.layout.sideMenuTemplateUrl)) {
                                return $templateRequest(triLayout.layout.sideMenuTemplateUrl);
                            }
                        },
                        controllerProvider: function(triLayout) {
                            return triLayout.layout.sideMenuController;
                        },
                        controllerAs: 'vm'
                    },
                    'sidebarNotifications@storm': {
                        templateProvider: function($templateRequest, triLayout) {
                            if (angular.isDefined(triLayout.layout.sidebarNotificationsTemplateUrl)) {
                                return $templateRequest(triLayout.layout.sidebarNotificationsTemplateUrl);
                            }
                        },
                        controllerProvider: function(triLayout) {
                            return triLayout.layout.sidebarNotificationsController;
                        },
                        controllerAs: 'vm'
                    },
                    'toolbar@storm': {
                        templateProvider: function($templateRequest, triLayout) {
                            if (angular.isDefined(triLayout.layout.toolbarTemplateUrl)) {
                                return $templateRequest(triLayout.layout.toolbarTemplateUrl);
                            }
                        },
                        controllerProvider: function(triLayout) {
                            return triLayout.layout.toolbarController;
                        },
                        controllerAs: 'vm'
                    },
                    'loader@storm': {
                        templateProvider: function($templateRequest, triLayout) {
                            if (angular.isDefined(triLayout.layout.loaderTemplateUrl)) {
                                return $templateRequest(triLayout.layout.loaderTemplateUrl);
                            }
                        },
                        controllerProvider: function(triLayout) {
                            return triLayout.layout.loaderController;
                        },
                        controllerAs: 'loader'
                    }
                }
            })
            .state('404', {
                url: '/404',
                views: {
                    'root': {
                        templateUrl: '/modules/user/noaccess/404.tmpl.html',
                        controller: 'ErrorPageController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('401', {
                url: '/401',
                views: {
                    'root': {
                        templateUrl: '401.tmpl.html',
                        controller: 'ErrorPageController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('500', {
                url: '/500',
                views: {
                    'root': {
                        templateUrl: '500.tmpl.html',
                        controller: 'ErrorPageController',
                        controllerAs: 'vm'
                    }
                }
            });
        // always goto 404 if route not found
        $urlRouterProvider.otherwise('/404');
    }
})();
