(function() {
    'use strict';

    angular
        .module('atheer')
        .component('counterWidget', {
            templateUrl: 'core/components/counter/counter-widget.tmpl.html',
            controllerAs: 'vm',
            bindings: {
                title: '@',
                count: '<',
                icon: '@',
                background: '@',
                color: '@'
            }
        });
})();
