(function() {
    'use strict';

    angular
        .module('atheer.workspace')
        .controller('WorkspaceSetupCompanyController', WorkspaceSetupCompanyController);

    /* @ngInject */
    function WorkspaceSetupCompanyController($translate, $timeout, WorkspaceSignup, $scope, $state, $stateParams, $mdToast, $http, $filter, triSettings) {
        var vm = this;

        vm.error = null;
        vm.success = false;
        vm.triSettings = triSettings;

        vm.workspaceSignupData = $stateParams.workspaceSignupData;

        vm.workspace = {
            company_name: null,
            company_type: null,
            company_size: null,
            user_role: null
        }

        if (!vm.workspaceSignupData) {
            $state.go('workspace.signup');
        }

        vm.next = function() {
            vm.workspaceSignupData.company_name = vm.workspace.company_name;
            vm.workspaceSignupData.company_type = vm.workspace.company_type;
            vm.workspaceSignupData.company_size = vm.workspace.company_size;
            vm.workspaceSignupData.user_role = vm.workspace.user_role;

            $state.go('workspace.setup-url', {
                "workspaceSignupData": vm.workspaceSignupData
            });
        };
    }
})();
