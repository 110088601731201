(function () {
    'use strict';

    angular
        .module('atheer.session')
        .directive('sessionAnnotationTools', sessionAnnotationTools);

    function sessionAnnotationTools() {
        var directive = {
            scope: {
                sessionNo: '=',
                annotationType: '=',
                freezeFrame: '='
            },
            bindToController: true,
            controller: sessionAnnotationToolsController,
            controllerAs: 'vm',
            templateUrl: 'modules/session/session-annotation-tools.tmpl.html',
            restrict: 'E'
        };
        return directive;
    };

    /* @ngInject */
    function sessionAnnotationToolsController($scope, $mdDialog, $filter) {
        var vm = this;

        var annotationColor = {
            red: 'E41E1E',
            darkYellow: 'FFB800',
            yellow: 'FFF500',
            green: '07E36C',
            blue: '0094FF',
            violet: '8000FF',
            pink: 'FF0099',
            black: '000000',
            white: 'FFFFFF'
        };

        vm.isAnnotationSaved = false;
        vm.brushColor = "#" + annotationColor.darkYellow;
        vm.previousTool = '';
        vm.isDefault = true;
        vm.openColorPalette = false;

        $scope.$on('is-drawing', updateAnnotationStatus);

        function updateAnnotationStatus(event, isSaved) {
            vm.isAnnotationSaved = isSaved;
        }

        $scope.$on('destory', function() {
            $scope.$off('is-drawing',updateAnnotationStatus);
        });

        vm.updateTool = function (tool) {
            var element;
            vm.isDefault = false;
            if(tool === 'FREEZE_FRAME') {
                $scope.$emit('freeze-frame', {});
            } else {
                if(tool == 'PENCIL') {
                    element = document.getElementById('annotation-pencil');
                    vm.setOrUpdateActiveTool(element);
                } else if(tool == 'LASER') {
                    element = document.getElementById('annotation-laser');
                    vm.setOrUpdateActiveTool(element);
                } else if(tool == 'ARROW') {
                    element = document.getElementById('annotation-arrow');
                    vm.setOrUpdateActiveTool(element);
                } else if(tool == 'TEXT') {
                    element = document.getElementById('annotation-text');
                    vm.setOrUpdateActiveTool(element);
                }
                $scope.$emit('tool-change', { tool: tool });
            }
        };

        vm.updateColor = function () {
            vm.openColorPalette = !vm.openColorPalette;
        };

        vm.setColorPaletteColor = function (color) {
            var colorPalette = document.getElementById('colorPalette');
            vm.previousTool.style.color = "#" + color;
            colorPalette.style.backgroundColor = "#" + color;
        }

        vm.setOrUpdateColor = function (color) {
            if(vm.previousTool == '' && vm.annotationType !== 'LIVE') {
                vm.previousTool = document.getElementById('annotation-pencil');
                vm.isDefault = false;
            } else if(vm.previousTool == '' && vm.annotationType === 'LIVE') {
                vm.previousTool = document.getElementById('annotation-laser');
                vm.isDefault = false;
            }
            if(color == 'RED') {
                vm.brushColor = annotationColor.red;
                vm.setColorPaletteColor(annotationColor.red);
            } else if(color == 'DARK_YELLOW') {
                vm.brushColor = annotationColor.darkYellow;
                vm.setColorPaletteColor(annotationColor.darkYellow);
            } else if(color == 'YELLOW') {
                vm.brushColor = annotationColor.yellow;
                vm.setColorPaletteColor(annotationColor.yellow);
            } else if(color == 'GREEN') {
                vm.brushColor = annotationColor.green;
                vm.setColorPaletteColor(annotationColor.green);
            } else if(color == 'BLUE') {
                vm.brushColor = annotationColor.blue;
                vm.setColorPaletteColor(annotationColor.blue);
            } else if(color == 'VIOLET') {
                vm.brushColor = annotationColor.violet;
                vm.setColorPaletteColor(annotationColor.violet);
            } else if(color == 'PINK') {
                vm.brushColor = annotationColor.pink;
                vm.setColorPaletteColor(annotationColor.pink);
            } else if(color == 'WHITE') {
                vm.brushColor = annotationColor.white;
                vm.setColorPaletteColor(annotationColor.white);
            } else if(color == 'BLACK') {
                vm.brushColor = annotationColor.black;
                vm.setColorPaletteColor(annotationColor.black);
            }

            $scope.$emit('set-brush-color', vm.brushColor);

            vm.brushColor = "#" + vm.brushColor;
            vm.openColorPalette = !vm.openColorPalette;
        };

        vm.setOrUpdateActiveTool = function (element) {
            if(vm.previousTool) {
                vm.previousTool.style.color = 'white';
            }
            element.style.color = vm.brushColor;
            vm.previousTool = element;
        }

        vm.saveAnnotation = function () {
            $mdDialog.show({
                templateUrl: 'modules/session/session-annotation-save.tmpl.html',
                controller: 'SessionSaveAnnotationController',
                controllerAs: 'vm',
                locals: {
                    sessionNo: vm.sessionNo,
                    isAnnotationSaved: vm.isAnnotationSaved
                }
            }).then(function () {
                
            });
        }

        vm.close = function () {
            if(vm.annotationType === 'LIVE' || vm.annotationType === 'AR' ) {
                $scope.$emit('close-annotation', true);
            } else {
                if (vm.isAnnotationSaved) {
                    $scope.$emit('close-annotation', true);
                } else {
                    var confirmAnnotationSave = $mdDialog.confirm()
                        .title($filter('translate')('atheer.session.saveAnnotation.save.title'))
                        .textContent($filter('translate')('atheer.session.saveAnnotation.save.content'))
                        .ariaLabel($filter('translate')('atheer.session.saveAnnotation.save.content'))
                        .ok($filter('translate')('entity.action.save'))
                        .cancel($filter('translate')('entity.action.cancel'));
    
                    $mdDialog.show(confirmAnnotationSave).then(function () {
                        vm.saveAnnotation();
                    }, function () {
                        vm.isAnnotationSaved = true;
                        $scope.$emit('close-annotation', true);
                    });
                }
            }
        }
    };
})();
