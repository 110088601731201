(function() {
    'use strict';

    angular
        .module('atheer.system')
        .controller('BadgeDetailController', BadgeDetailController)

    /* @ngInject */
    function BadgeDetailController($mdDialog, $scope, $filter, $sce, ParseLinks, pagingParams, paginationConstants, $state, AlertService, $mdToast, $mdSidenav, Badge, id, Experience) {
        var vm = this;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;

        vm.query = {
            filter: '',
            limit: paginationConstants.itemsPerPage,
            order: '-id',
            page: 1
        };

        vm.userBadges = []

        if (id) {
            Badge.get({
                id: id
            }, function(result) {
                vm.badgeModel = result;
                vm.loadUserBadges();
            });
        } else {
            vm.badgeModel = {
                name: null,
                description: null,
                status: 'DRAFT'
            }
        }

        vm.openAssignModal = function() {
            $mdDialog.show({
                templateUrl: 'modules/system/badge/badge-assign-dialog.tmpl.html',
                clickOutsideToClose: true,
                controller: "BadgeAssignController",
                controllerAs: "vm",
                size: 'lg',
                locals: {
                    badge: vm.badgeModel,
                    userId: undefined
                }
            }).then(function() {
                vm.loadUserBadges();
            });
        }

        vm.loadUserBadges = function() {
            Experience.getBadges({
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                query: getUserQueryString(),
                user_info: true
            }, function(response, headers) {
                vm.userBadges = response;
                vm.page = pagingParams.page;
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.loadingData = false;
            });
        }

        vm.removeUserBadge = function(badge, index) {
            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.badge.removeUserBadge'))
                .ariaLabel($filter('translate')('atheer.badge.removeUserBadge'))
                .ok($filter('translate')('entity.action.delete'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function() {
                Experience.deleteBadge(badge, function() {
                    vm.userBadges.splice(index, 1);
                });
            });
        }

        vm.transitionUserBadges = function() {
            vm.promise = Experience.getBadges({
                page: vm.page - 1,
                size: paginationConstants.itemsPerPage,
                query: getUserQueryString(),
                user_info: true
            }).$promise;

            vm.promise.then(function(response, headers) {
                vm.userBadges = response;
            });
        }

        function getUserQueryString() {
            var queryString = "object_id==" + vm.badgeModel.id;
            return queryString;
        }

        vm.onSaveSuccess = function(result) {
            vm.badgeModel = result;
            vm.isSaving = false;
            AlertService.displayToast($mdToast);
        };

        vm.onSaveError = function() {
            vm.isSaving = false;
        };

        vm.save = function() {
            if (vm.badgeModel.id) {
                Badge.update(vm.badgeModel, vm.onSaveSuccess, vm.onSaveError);
            } else {
                Badge.save(vm.badgeModel, vm.onSaveSuccess, vm.onSaveError);
            }
        };

        vm.goToBadges = function() {
            $state.go('storm.admin-badges');
        };

        vm.isNew = function() {
            if (vm.badgeModel == undefined || vm.badgeModel.id == null) {
                return true;
            } else {
                return false;
            }
        }

        vm.copyBadge = function() {
            delete vm.badgeModel['id'];
        }

        this.togglePanel = function(componentId) {
            $mdSidenav(componentId).toggle();
        };
    }
})();
