(function () {
    'use strict';

    angular
        .module('atheer.system')
        .controller('NotificationDetailController', NotificationDetailController)

    /* @ngInject */
    function NotificationDetailController($mdDialog, $scope, $filter, $sce, $state, $stateParams, AlertService, $mdToast, $mdSidenav, Notification, id, ToastService) {
        var vm = this;

        vm.notificationModel = null;
        vm.selectedLanguage = "en_US";
        vm.email_subject = null;
        vm.email_body = null;
        vm.push_title = null;
        vm.push_body = null;
        vm.bot_title = null;
        vm.bot_body = null;
        vm.message_title = null;
        vm.message_body = null;
        vm.sms_title = null;
        vm.sms_body = null;

        vm.languages = [
            { name: 'english_us', displayName: 'English (US)', value: 'en_US', serverKey: 'en_US' },
            { name: 'english_uk', displayName: 'English (UK)', value: 'en_GB', serverKey: 'en_GB' },
            { name: 'french', displayName: 'French', value: 'fr_FR', serverKey: 'fr_FR' },
            { name: 'german', displayName: 'German', value: 'de_DE', serverKey: 'de_DE' },
            { name: 'greek', displayName: 'Greek', value: 'el_GR', serverKey: 'el_GR' },
            { name: 'italian', displayName: 'Italian', value: 'it_IT', serverKey: 'it_IT' },
            { name: 'japanese', displayName: 'Japanese', value: 'ja_JP', serverKey: 'ja_JP' },
            { name: 'portuguese', displayName: 'Portuguese', value: 'pt_BR', serverKey: 'pt_BR' },
            { name: 'spanish', displayName: 'Spanish', value: 'es_CL', serverKey: 'es_CL' },
            { name: 'chinese', displayName: 'Chinese (Traditional)', value: 'zh_TW', serverKey: 'zh_TW' },
            { name: 'chinese_cn', displayName: 'Chinese (Simplified)', value: 'zh_CN', serverKey: 'zh_CN' },
            { name: 'dutch', displayName: 'Dutch', value: 'nl_NL', serverKey: 'nl_NL' },
            { name: 'danish', displayName: 'Danish (Denmark)', value: 'da_DK', serverKey: 'da_DK' },
            { name: 'norwegian', displayName: 'Norwegian', value: 'no_NO', serverKey: 'no_NO' },
            { name: 'polish', displayName: 'Polish (Poland)', value: 'pl_PL', serverKey: 'pl_PL' },
            { name: 'russian', displayName: 'Russian', value: 'ru_RU', serverKey: 'ru_RU' },
            { name: 'swedish', displayName: 'Swedish (Sweden)', value: 'sv_SE', serverKey: 'sv_SE' },
            { name: 'turkish', displayName: 'Turkish (Turkey)', value: 'tr_TR', serverKey: 'tr_TR' }
        ];

        vm.languages.sort(function (a, b) {
            if (a['displayName'] > b['displayName']) {
                return 1;
            } else if (a['displayName'] < b['displayName']) {
                return -1;
            }
            return 0;
        });

        vm.froalaOptions = {
            pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'entities', 'fontFamily', 'fontSize', 'fullscreen', 'lineBreaker', 'link', 'lists', 'paragraphFormat', 'paragraphStyle', 'quote', 'save', 'table', 'url'],
            toolbarButtons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', '|', 'color', 'inlineStyle', 'paragraphStyle', '|', 'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', '-', 'quote', 'insertHR', 'insertLink', 'insertTable', '|', 'undo', 'redo', 'clearFormatting', 'selectAll', 'html', 'applyFormat', 'removeFormat', 'fullscreen', 'print', 'help'],
            charCounterMax: -1,
            heightMin: 450,
            heightMax: 450,
            placeholderText: $filter('translate')('global.enterTextHerePlaceholder'),
            htmlAllowedAttrs: ['accept', 'accept-charset', 'accesskey', 'action', 'align', 'allowfullscreen', 'allowtransparency', 'alt', 'aria-.*', 'async', 'autocomplete', 'autofocus', 'autoplay', 'autosave', 'background', 'bgcolor', 'border', 'charset', 'cellpadding', 'cellspacing', 'checked', 'cite', 'class', 'color', 'cols', 'colspan', 'content', 'contenteditable', 'contextmenu', 'controls', 'coords', 'data', 'data-.*', 'datetime', 'default', 'defer', 'dir', 'dirname', 'disabled', 'download', 'draggable', 'dropzone', 'enctype', 'for', 'form', 'formaction', 'frameborder', 'headers', 'height', 'hidden', 'high', 'href', 'hreflang', 'http-equiv', 'icon', 'id', 'ismap', 'itemprop', 'keytype', 'kind', 'label', 'lang', 'language', 'list', 'loop', 'low', 'max', 'maxlength', 'media', 'method', 'min', 'mozallowfullscreen', 'multiple', 'muted', 'name', 'novalidate', 'open', 'optimum', 'pattern', 'ping', 'placeholder', 'playsinline', 'poster', 'preload', 'pubdate', 'radiogroup', 'readonly', 'rel', 'required', 'reversed', 'rows', 'rowspan', 'sandbox', 'scope', 'scoped', 'scrolling', 'seamless', 'selected', 'shape', 'size', 'sizes', 'span', 'src', 'srcdoc', 'srclang', 'srcset', 'start', 'step', 'summary', 'spellcheck', 'style', 'tabindex', 'target', 'title', 'type', 'translate', 'usemap', 'value', 'valign', 'webkitallowfullscreen', 'width', 'wrap', 'th:if']
        };

        if (id) {
            vm.loadingData = true;
            Notification.get({
                id: id
            }, function (result) {
                vm.notificationModel = result;
                setNotificationFields(result);
            });
        }

        vm.onSaveSuccess = function (result) {
            vm.notificationModel = result;
            vm.isSaving = false;
            AlertService.displayToast($mdToast);
        };

        vm.onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            if (vm.froalaOptions.froalaEditor.codeView.isActive()) {
                vm.froalaOptions.froalaEditor.codeView.toggle();
                setTimeout(vm.save, 1000);
                return;
            }
            setNotificationModel();
            Notification.update(vm.notificationModel, vm.onSaveSuccess, vm.onSaveError);
        };

        vm.goToNotifications = function () {
            $state.go("storm.admin-setting.notifications");
        };

        vm.updateLanguage = function () {
            setNotificationFields();
        };

        function setNotificationFields() {
            if (vm.selectedLanguage == "en_US") {
                vm.email_subject = vm.notificationModel.email_subject;
                vm.email_body = vm.notificationModel.email_body;
                vm.push_title = vm.notificationModel.push_title;
                vm.push_body = vm.notificationModel.push_body;
                vm.bot_title = vm.notificationModel.bot_title;
                vm.bot_body = vm.notificationModel.bot_body;
                vm.message_title = vm.notificationModel.message_title;
                vm.message_body = vm.notificationModel.message_body;
                vm.sms_title = vm.notificationModel.sms_title;
                vm.sms_body = vm.notificationModel.sms_body;
            } else {
                vm.email_subject = getFieldValue(vm.notificationModel.email_subject_language, vm.notificationModel.email_subject);
                vm.email_body = getFieldValue(vm.notificationModel.email_body_language, vm.notificationModel.email_body);
                vm.push_title = getFieldValue(vm.notificationModel.push_title_language, vm.notificationModel.push_title);
                vm.push_body = getFieldValue(vm.notificationModel.push_body_language, vm.notificationModel.push_body);
                vm.bot_title = getFieldValue(vm.notificationModel.bot_title_language, vm.notificationModel.bot_title);
                vm.bot_body = getFieldValue(vm.notificationModel.bot_body_language, vm.notificationModel.bot_body);
                vm.message_title = getFieldValue(vm.notificationModel.message_title_language, vm.notificationModel.message_title);
                vm.message_body = getFieldValue(vm.notificationModel.message_body_language, vm.notificationModel.message_body);
                vm.sms_title = getFieldValue(vm.notificationModel.sms_title_language, vm.notificationModel.sms_title);
                vm.sms_body = getFieldValue(vm.notificationModel.sms_body_language, vm.notificationModel.sms_body);
            };
        };

        function setNotificationModel() {
            if (vm.selectedLanguage == "en_US") {
                vm.notificationModel.email_subject = vm.email_subject;
                vm.notificationModel.email_body = vm.email_body;
                vm.notificationModel.push_title = vm.push_title;
                vm.notificationModel.push_body = vm.push_body;
                vm.notificationModel.bot_title = vm.bot_title;
                vm.notificationModel.bot_body = vm.bot_body;
                vm.notificationModel.message_title = vm.message_title;
                vm.notificationModel.message_body = vm.message_body;
                vm.notificationModel.sms_title = vm.sms_title;
                vm.notificationModel.sms_body = vm.sms_body;
            } else {
                vm.notificationModel.email_subject_language = setFieldValue(vm.notificationModel.email_subject_language, vm.email_subject);
                vm.notificationModel.email_body_language = setFieldValue(vm.notificationModel.email_body_language, vm.email_body);
                vm.notificationModel.sms_title_language = setFieldValue(vm.notificationModel.sms_title_language, vm.sms_title);
                vm.notificationModel.sms_body_language = setFieldValue(vm.notificationModel.sms_body_language, vm.sms_body);
            };
        };

        function getFieldValue(field, defaultFieldValue) {
            if (field && field[vm.selectedLanguage]) {
                return field[vm.selectedLanguage];
            } else {
                return defaultFieldValue;
            };
        };

        function setFieldValue(field, fieldValue) {
            if (field && field[vm.selectedLanguage]) {
                field[vm.selectedLanguage] = fieldValue;
            } else if (field) {
                field[vm.selectedLanguage] = fieldValue;
            } else {
                field = {};
                field[vm.selectedLanguage] = fieldValue;
            };
            return field;
        };
    }
})();
