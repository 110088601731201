(function() {
    'use strict';

    angular
        .module('atheer.session')
        .controller('SessionsAllController', SessionsAllController);

    /* @ngInject */
    function SessionsAllController($rootScope, $scope, $state, $q, $filter, $mdSidenav, Report, CONTENT_META, Pubnub, Session, ParseLinks, pagingParams, paginationConstants, AlertService, $mdDialog, $mdToast, $stateParams, Feature, Experience, ToastService) {
        var vm = this;

        vm.loadingData = false;
        vm.scheduleSession = Feature.isActive('feature_airsession_schedule_session');
        vm.sessionModel = null;
        vm.reOrder = reOrder;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.predicate = pagingParams.predicate;
        vm.transition = transition;
        vm.reOrder = reOrder;
        vm.pageSize = paginationConstants.itemsPerPage;
        vm.panelToShow = null;
        vm.illustartionEmptyScreenId = "session_all";
        vm.illustartionEmptyScreenText = "There are no sessions yet";
        vm.removeFilter = removeFilter;
        vm.loadData = loadData;
        vm.deleteObject = deleteObject;

        vm.query = {
            filter: '',
            limit: '10',
            order: '-id',
            page: 1
        };

        vm.filter = {
            options: {
                debounce: 500
            }
        };
        vm.filter.show = false;

        vm.permissions = {
            create: 'create-session',
            update: 'update-session',
            delete: 'delete-session',
            updatePersonalSession: 'update-personal-session'
        };

        vm.joinSession = function() {
            $mdDialog.show({
                templateUrl: 'modules/session/session-join-dialog.tmpl.html',
                clickOutsideToClose: true,
                controller: "SessionJoinController",
                controllerAs: "vm",
                locals: {
                    sessionNo: null
                }
            }).then(function(result) {

            });
        };

        vm.createObject = function() {
            $state.go('storm.sessions.upcoming-detail');
        };

        vm.togglePanel = function(componentId, session) {
            if (session) {
                vm.sessionModel = session;
            }

            if(componentId == 'insightsPanel') {
                vm.panelToShow = 'insightsPanel';
            } else if (componentId == 'annotationsPanel') {
                vm.panelToShow = 'annotationsPanel';
            } else if (componentId == 'recordingsPanel'){
                vm.panelToShow = 'recordingsPanel';
            } else if (componentId == 'conversationPanel') {
                vm.panelToShow = 'conversationPanel';
            }

            $mdSidenav(componentId).toggle()
                .then(function() {

                });
        };

        vm.endSession = function (session) {
            session.status = 'CLOSED';
            session.duration = 1;
            Session.update(session, onSaveSuccess, onSaveError);

            function onSaveSuccess(result) {
                ToastService.displayToast($filter('translate')('atheer.session.endSessionSuccess'), 3000);
                loadData();
            };

            function onSaveError() {
                ToastService.displayToast($filter('translate')('atheer.session.endSessionError'), 3000);
            };
        };

        vm.refresh = function() {
            loadData();
        };

        function reOrder(order) {
            vm.predicate = order;
            vm.transition();
        };

        function transition() {
            vm.promise = Session.query({
                page: vm.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort(),
                query: getQueryString(),
                all: true
            }).$promise;

            function sort() {
                var result = [getSortOrder()];

                return result;
            }

            vm.promise.then(function(data, headers) {
                vm.queryCount = vm.totalItems;
                vm.sessions = data;
            });
        };

        vm.editObject = function(entity) {
            $mdDialog.show({
                templateUrl: 'modules/session/session-past-edit.tmpl.html',
                clickOutsideToClose: true,
                controller: "SessionPastEditController",
                controllerAs: "vm",
                 locals: {
                       filter: entity.id
                    }
            }).then(function(result) {
                vm.refresh();
            });
        };

        function getSortOrder() {
            if (vm.predicate.startsWith('id')) {
                return vm.predicate + ',' + 'desc';
            }

            if (vm.predicate.startsWith('-')) {
                return vm.predicate.substr(1) + ',' + 'desc';
            } else {
                return vm.predicate + ',' + 'asc';
            }
        };

        vm.toggleFilter = function() {
            vm.filter.show = !vm.filter.show;
        }

        function removeFilter() {
            vm.filter.show = false;
            vm.query.filter = '';
            resetModal();
        }

        function getQueryString() {
            var query = '';
            if (vm.query.filter.length > 0) {
                query = query + '(text_search=' + vm.query.filter + ')';
            }
            return query;
        };

        function deleteObject(entity) {
            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.session.upcomingSessions.deleteSession'))
                .ariaLabel($filter('translate')('atheer.session.upcomingSessions.deleteSession'))
                .ok($filter('translate')('entity.action.delete'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function() {
                Session.delete({
                    id: entity.id
                }, function() {
                    resetModal();
                });
            });
        };

        function resetModal() {
            loadData();
            AlertService.displayToast($mdToast);
        };

        function loadData() {
            vm.loadingData = true;
            Session.query({
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort(),
                query: getQueryString(),
                all: true
            }, onSuccess, onError);

            function sort() {
                var result = [getSortOrder()];

                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.sessions = data;
                vm.page = pagingParams.page;
                vm.loadingData = false;
            }

            function onError(error) {
                AlertService.error(error.data.message);
                vm.loadingData = false;
            }
        };

        var refreshSessionListener = $rootScope.$on('refreshSessions', function(event, filter) {
            vm.query.filter = filter;
            loadData();
        });

        // Unregister
        $scope.$on('$destroy', function() {
            refreshSessionListener();
        });

        loadData();
    }
})();
