/* global d3 */
(function() {
    'use strict';

    angular
        .module('atheer.user')
        .controller('ProfileTimelineController', ProfileTimelineController);

    /* @ngInject */
    function ProfileTimelineController($scope, $timeout, $mdToast, $filter, $mdDialog, id) {
        var vm = this;

        vm.loadingData = false;

        var timelineContent = '<div class="md-padding"><div><span class="md-title" translate="atheer.timeline.date">07 Dec</span></div><div><p translate="atheer.timeline.completedLearningEvents">Completed 5 learning events</p> <p translate="atheer.timeline.providedFeedBack">Provided feedback for 3 questions</p></div></div>';

        vm.events = [];

        for (var i = 0; i <= 4; i++) {
            vm.events.push({
                'content': timelineContent
            });
        }
    }
})();
