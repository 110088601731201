(function() {
    'use strict';
    angular
        .module('atheer.core')
        .filter('fpCapitalizeWord', fpCapitalizeWord);

    function fpCapitalizeWord() {
        return function(text) {
            return (!!text) ? text.charAt(0).toUpperCase() + text.substr(1).toLowerCase() : '';
        }
    }
})();
