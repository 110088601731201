'use strict';

angular.module('atheer')
    .directive('ngAudioRecorder', ['recorderService', '$timeout',
        function(recorderService, $timeout) {
            return {
                restrict: 'EA',
                scope: {
                    id: '@',
                    audioModel: '=',
                    onRecordStart: '&',
                    onRecordComplete: '&',
                    onPlaybackComplete: '&',
                    onPlaybackStart: '&',
                    onPlaybackPause: '&',
                    onPlaybackResume: '&',
                    onSaveComplete: '&',
                    showPlayer: '=?',
                    autoStart: '=?',
                    timeLimit: '=?'
                },
                controllerAs: 'recorder',
                bindToController: true,
                template: function(element, attrs) {
                    return '<div class="audioRecorder">' +
                        '<div style="width: 250px; margin: 0 auto;"><div id="audioRecorder-fwrecorder"></div></div>' +
                        element.html() +
                        '</div>';
                },
                controller: 'RecorderController',
                link: function(scope, element, attrs) {
                    $timeout(function() {
                        if (recorderService.isAvailable && !(recorderService.isHtml5 || recorderService.isCordova)) {}
                    }, 100);

                }
            };
        }
    ]);
