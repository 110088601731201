(function () {
    'use strict';
    angular
        .module('atheer')
        .directive('fpAccordion', fpAccordion)
        .directive('fpAccordionExpander', fpAccordionExpander);

    function fpAccordion() {
        return {
            restrict: 'AE',
            replace: true,
            transclude: true,
            scope: {
                'addItem': '&',
                'accordionModel': '=',
                'hideAdd': '=?',
                'allowMultiple': '=?'
            },
            templateUrl: 'core/components/accordion/accordion.tmpl.html',
            link: function (scope, element, attrs) {
                scope.add = function () {
                    scope.addItem();
                }

                scope.showAdd = function () {
                    return !scope.hideAdd;
                }
            },
            controller: function ($scope) {
                var items = [];

                this.register = function (item) {
                    items.push(item);
                };

                this.handleSelectedItem = function (selectedItem) {
                    if ($scope.allowMultiple) {
                        angular.noop();
                    } else {
                        angular.forEach(items, function (item) {
                            if (selectedItem != item) {
                                item.isVisible = false;
                            } else {
                                if (item.isVisible) {
                                    item.isVisible = true;
                                } else {
                                    item.isVisible = false;
                                }
                            }
                        });
                    }

                }
            }
        };
    }

    function fpAccordionExpander() {
        return {
            restrict: 'E',
            replace: true,
            transclude: true,
            require: '?^fpAccordion',
            scope: {
                item: '=',
                index: '=',
                copyItem: '&',
                removeItem: '&',
                hideRemove: '=?',
                hideCopy: '=?',
                hideDone: '=?',
                title: '=',
                isVisible: '=?isOpen'
            },
            templateUrl: 'core/components/accordion/accordion-expander.tmpl.html',

            link: function (scope, element, attrs, fpAccordionController) {
                var hasAccordionController = !!fpAccordionController;

                scope.isVisible = scope.isVisible === undefined ? false : scope.isVisible;

                scope.copy = function () {
                    scope.copyItem({
                        itemObj: scope.item
                    });
                }

                scope.showCopy = function () {
                    return !scope.hideCopy;
                }

                scope.showRemove = function () {
                    return !scope.hideRemove;
                }

                scope.showDone = function () {
                    return !scope.hideDone;
                }

                scope.remove = function () {
                    scope.removeItem({
                        index: scope.index
                    });
                }

                scope.handleToggle = function () {
                    scope.isVisible = !scope.isVisible;

                    if (hasAccordionController) {
                        fpAccordionController.handleSelectedItem(scope);
                    }
                }

                if (hasAccordionController) {
                    fpAccordionController.register(scope);
                }
            }
        };
    }
})();
