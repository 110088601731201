(function() {
    'use strict';

    angular
        .module('atheer.team')
        .controller('TeamMenuController', TeamMenuController);

    /* @ngInject */
    function TeamMenuController($scope, $state, $mdDialog, $mdPanel, Team, ParseLinks, AlertService, pagingParams, paginationConstants, $mdToast, $stateParams, $filter, $translate, Feature) {
        var vm = this;

        vm.loadingData = true;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.predicate = pagingParams.predicate;

        vm.filters = {
            active: false,
            externalId: false,
            functionalTeam: false,
            type: null,
            static: false,
            dynamic: false
        };

        if ( getSavedFilters() ) {
            vm.filters = getSavedFilters();
        }

        vm.updateFilters = function() {
            saveFilters(vm.filters);
            $scope.$emit('updateTeamList', vm.filters);
        }

        function saveFilters(filterData) {
            sessionStorage.setItem('objectMenu', JSON.stringify(filterData));
        };
    
        function getSavedFilters() {
            return JSON.parse(sessionStorage.getItem('objectMenu'));
        };

        vm.loadAll = function() {
            vm.loadingData = false;
        }
        vm.loadAll();
    }
})();
