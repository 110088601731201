(function() {
    'use strict';

    angular
        .module('atheer.conversation')
        .directive('conversationMessage', conversationMessage);

    /* @ngInject */
    function conversationMessage() {
        var directive = {
            scope: {
                conversation: '=',
                message: '=',
                index: '=',
                isReadOnly: '='
            },
            restrict: "E",
            replace: true,
            templateUrl: 'modules/conversation/conversation-message.html',
            bindToController: true,
            controller: conversationMessageController,
            controllerAs: 'vm',
        };
        return directive;

        /* @ngInject */
        function conversationMessageController($rootScope, $timeout, $scope, PubNubService, Content, $mdToast, $mdDialog, Message, $mdPanel, ConversationModelService, AlertService, Principal, triSettings) {
            var vm = this;

            vm.userId = Principal.getUserId();
            vm.primaryColor = triSettings.getSkinModel().primary_color_hex;

            vm.showMessageActions = function($event, message, xPosition) {
                var position = $mdPanel.newPanelPosition()
                    .relativeTo('#message-menu')
                    .addPanelPosition(xPosition == 'END' ? $mdPanel.xPosition.ALIGN_START : $mdPanel.xPosition.ALIGN_END, $mdPanel.yPosition.ALIGN_TOPS);

                var config = {
                    attachTo: angular.element(document.body),
                    controller: 'ConversationMessageMenuController',
                    controllerAs: 'vm',
                    templateUrl: 'modules/conversation/conversation-message-menu.html',
                    position: position,
                    locals: {
                        messageModel: message,
                        conversationModel: vm.conversation
                    },
                    openFrom: $event,
                    clickOutsideToClose: true,
                    escapeToClose: true,
                    focusOnOpen: false,
                    zIndex: 2,
                    onCloseSuccess: function(panelRef, reason) {

                    }
                };
                $mdPanel.open(config);
            };

            vm.addReaction = function(ev) {
                ConversationModelService.addMessageReaction(ev, vm.message);
            };

            vm.addOrRemoveReaction = function(reactionType, unified) {
                ConversationModelService.addOrRemoveMessageReaction(vm.message, reactionType, unified);
            };

            vm.unPinMessage = function() {
                ConversationModelService.unPinMessage(vm.message);
            };
        };
    }
})();
