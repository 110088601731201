(function() {
    'use strict';

    var fpAlertError = {
        template: '<div>' + '</div>',
        controller: fpAlertErrorController,
        controllerAs: 'vm'
    };

    angular
        .module('atheer')
        .component('fpAlertError', fpAlertError);

    /* @ngInject */
    function fpAlertErrorController($scope, AlertService, $rootScope, $translate, $mdToast, toastConstants) {
        var vm = this;

        vm.alerts = AlertService.get();

        if(!angular.isDefined(vm.alerts)) {
            vm.alerts = [];
        }

        for(var i=0, len= vm.alerts.length; i < len; i++){
          $mdToast.show({
              template: '<md-toast><span flex>' + vm.alerts[i].msg + '</span></md-toast>',
              position: toastConstants.position,
              hideDelay: 3000
          });
        }

        function addErrorAlert(message, key, data) {
            key = key && key !== null ? key : message;
            vm.alerts.push(
                AlertService.add({
                        type: 'danger',
                        msg: key,
                        params: data,
                        timeout: 5000,
                        toast: AlertService.isToast(),
                        scoped: true
                    },
                    vm.alerts
                )
            );
        }

        var cleanHttpErrorListener = $rootScope.$on('atheer.httpError', function(event, httpResponse) {
            var i;
            event.stopPropagation();
            switch (httpResponse.status) {
                // connection refused, server not reachable
                case 0:
                    addErrorAlert('Server not reachable', 'error.server.not.reachable');
                    break;

                case 400:
                    var errorHeader = httpResponse.headers('X-atheer-error');
                    var entityKey = httpResponse.headers('X-atheer-params');
                    if (errorHeader) {
                        var entityName = $translate.instant('global.menu.entities.' + entityKey);
                        addErrorAlert(errorHeader, errorHeader, {
                            entityName: entityName
                        });
                    } else if (httpResponse.data && httpResponse.data.fieldErrors) {
                        for (i = 0; i < httpResponse.data.fieldErrors.length; i++) {
                            var fieldError = httpResponse.data.fieldErrors[i];
                            // convert 'something[14].other[4].id' to 'something[].other[].id' so translations can be written to it
                            var convertedField = fieldError.field.replace(/\[\d*\]/g, '[]');
                            var fieldName = $translate.instant('atheer.' + fieldError.objectName + '.' + convertedField);
                            addErrorAlert('Field ' + fieldName + ' cannot be empty', 'error.' + fieldError.message, {
                                fieldName: fieldName
                            });
                        }
                    } else if (httpResponse.data && httpResponse.data.message) {
                        addErrorAlert(httpResponse.data.message, httpResponse.data.message, httpResponse.data);
                    } else {
                        addErrorAlert(httpResponse.data);
                    }
                    break;

                default:
                    if (httpResponse.data && httpResponse.data.message) {
                        addErrorAlert(httpResponse.data.message);
                    } else {
                        addErrorAlert(angular.toJson(httpResponse));
                    }
            }
        });

        $scope.$on('$destroy', function() {
            if (angular.isDefined(cleanHttpErrorListener) && cleanHttpErrorListener !== null) {
                cleanHttpErrorListener();
                vm.alerts = [];
            }
        });
    }
})();
