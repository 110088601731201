(function() {

    'use strict';
    angular
        .module('atheer.topic')
        .constant('TOPIC_INSIGHT_META', {
            topic: {
                sections: {
                    overview: {
                        label: 'atheer.topic.overview',
                        icon: 'zmdi zmdi-account',
                        controller: 'TopicInsightOverviewController',
                        template: 'modules/topic/topic-insight-overview.tmpl.html'
                    },
                    scan_history: {
                        label: 'atheer.topic.scanHistory',
                        icon: 'zmdi zmdi-account',
                        controller: 'TopicInsightScanHistoryController',
                        template: 'modules/topic/topic-insight-scan-history.tmpl.html'
                    }
                }
            }
        });
})();
