(function() {
    'use strict';

    angular
        .module('atheer.doc')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider, CONTENT_INSIGHT_META) {
        $stateProvider
            .state('storm.product-doc', {
                url: '/documentation/',
                templateUrl: 'modules/doc/product-doc.tmpl.html',
                controller: 'ProductDocumentationController',
                controllerAs: 'vm',
                data: {
                    pageTitle: 'atheer.applet.home.title',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showSideNavMenu: false,
                        sideNavMenuPosition: 'left',
                        showToolbar: false,
                        sideNavMenuSize: 15
                    }
                }
            });
    }
})();
