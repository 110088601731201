(function() {
    'use strict';

    angular.module('atheer.core').directive('whenScrolledNoTimeout', ['$timeout', function($timeout) {
        return function(scope, elm, attr) {
            var raw = elm[0];

            elm.bind('scroll', function() {
                if (!raw.scrollTop) {
                    scope.$apply(attr.whenScrolledNoTimeout); 
                }
            });
        };
    }]);
})();
