(function() {
    'use strict';

    angular
        .module('atheer.content')
        .controller('ContentDetailInsightRatingsSummaryController', ContentDetailInsightRatingsSummaryController)

    /* @ngInject */
    function ContentDetailInsightRatingsSummaryController($scope, content_type, id, ParseLinks, pagingParams, paginationConstants, Experience) {
        var vm = this;

        vm.loadingData = false;
        vm.contentType = content_type;
        vm.contentModel = $scope.$parent.vm.contentModel;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.illustartionEmptyScreenId = "no_rating";

        vm.ratings = [];

        vm.query = {
            filter: '',
            limit: paginationConstants.itemsPerPage,
            order: '-id',
            page: 1
        };

        vm.loadData = function() {
            vm.loadingData = true;
            //fetch pins data
            Experience.getRatings({
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort(),
                query: getQueryString(),
                user_info: true
            }, function(response, headers) {
                vm.ratings = response;
                vm.page = pagingParams.page;
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;

                vm.loadingData = false;
            });
        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function getQueryString() {
            var queryString = "object_id==" + id;
            return queryString;
        }

        vm.getUserProfileImageUrl = function(pictureId) {
            return pictureId ? '/media/pictures/' + pictureId : 'assets/images/avatars/user-avatar.png';
        }

        vm.transition = function() {
            vm.promise = Experience.getRatings({
                page: vm.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort(),
                query: getQueryString()
            }).$promise;

            vm.promise.then(function(response, headers) {
                vm.ratings = response;
                vm.queryCount = vm.totalItems;
            });
        }
        vm.loadData();
    }
})();
