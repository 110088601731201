(function () {
    "use strict";
    angular
        .module("atheer.smartFlow")
        .controller("SmartFlowDetailController", SmartFlowDetailController);

    /* @ngInject */
    function SmartFlowDetailController(
        $mdDialog,
        $stateParams,
        $scope,
        $filter,
        $sce,
        $state,
        AlertService,
        $mdSidenav,
        SmartFlow,
        id,
        SmartFlowBuilder,
        WORFLOW_CELL_CONSTANT,
        ToastService
    ) {
        var vm = this;

        vm.viewType = $state.params.viewType;
        vm.dataLoaded = false;
        vm.gridSize = 10;
        vm.showAnalyticView = false;
        vm.useSetting = true;
        vm.isReadOnly = true;
        vm.isJobFlow = $state.params.isJobFlow;
        vm.scheduleConfig = {
            allowMultiple: false,
            options: {
                allowMinute: false,
                allowHour: false,
            },
            quartz: true,
        };

        vm.isUpdate = false;
        vm.isSmartflowSaved = true;
        vm.minDate = new Date();
        vm.tempSmartFlowName = "";

        if (id) {
            vm.viewType = $state.params.viewType;

            SmartFlow.get(
                {
                    id: id,
                },
                function (result) {
                    vm.smartFlowModel = result;
                    vm.dataLoaded = true;
                    vm.viewType = $state.params.viewType;

                    vm.isJobFlow = result.newSmartFlow;
                    if (result.canvas_data) {
                        var resultCanvasCells = JSON.parse(result.canvas_data);
                        for (
                            var cnt = 0;
                            cnt < resultCanvasCells.cells.length;
                            cnt++
                        ) {
                            if (
                                resultCanvasCells.cells[cnt].type ===
                                "smartflow.DataMapper" &&
                                resultCanvasCells.cells[cnt].setting
                                    .properties &&
                                resultCanvasCells.cells[cnt].setting.properties
                                    .mapping
                            ) {
                                SmartFlowBuilder.setDatamapperModel(
                                    Object.keys(
                                        resultCanvasCells.cells[cnt].setting
                                            .properties.mapping
                                    )
                                );
                                break;
                            }
                        }
                    }
                    if (result.category) {
                        SmartFlowBuilder.setSmartFlowCategory(result.category);
                    }

                    if (vm.smartFlowModel.status == "DRAFT") {
                        vm.isReadOnly = false;
                    }

                    if ($stateParams.isCopy) {
                        vm.copySmartFlow();
                        $stateParams.isCopy = false;
                    }

                    vm.tempSmartFlowName = vm.smartFlowModel.name;
                }
            );
        } else {
            vm.viewType = "settings";
            vm.dataLoaded = true;
            vm.smartFlowModel = {
                smart_flow_no: null,
                name: null,
                description: null,
                status: "DRAFT",
                type: "ON_GOING",
                schedule_info: null,
                newSmartFlow: vm.isJobFlow,
            };
        }

        vm.onSaveSuccess = function (result) {
            vm.smartFlowModel = result;
            if (vm.smartFlowModel.status == "DRAFT") {
                vm.isReadOnly = false;
            }
            vm.isSaving = false;
            var message;
            if (vm.isJobFlow) {
                message = vm.isUpdate
                    ? $filter('translate')('atheer.smartFlow.detail.jobFlowUpdateSuccess')
                    : $filter('translate')('atheer.smartFlow.detail.jobFlowCreateSuccess');
            } else {
                message = vm.isUpdate
                    ? $filter('translate')('atheer.smartFlow.detail.smartFlowUpdateSuccess')
                    : $filter('translate')('atheer.smartFlow.detail.smartFlowCreateSuccess');
            }

            ToastService.displayToast(message, 3000);

            SmartFlowBuilder.setSmartFlowStatus();
            SmartFlowBuilder.setSmartFlowData(result);
            vm.dataLoaded = true;
            vm.viewType = "build";
        };

        vm.onSaveCopiedSuccess = function (result) {
            vm.smartFlowModel = result;
            if (vm.smartFlowModel.status == "DRAFT") {
                vm.isReadOnly = false;
            }
            vm.isSaving = false;

            $state.go("storm.admin-smartflow-detail", {
                id: result.id,
                isCopy: false,
            });
            vm.dataLoaded = true;
            vm.viewType = "build";
        };

        vm.onSaveError = function (err) {
            var message;
            if(err) {
                message = err.data.description ? err.data.description : $filter('translate')('atheer.smartFlow.detail.jobFlowSaveFailed');
                vm.errorMessage = err.data.description;
            }

            ToastService.displayToast(message, 3000);

            document.getElementById('field_name').focus();
            vm.smartFlowModel.name = vm.tempSmartFlowName;

            vm.isSaving = false;
            vm.isCreating = false;
            vm.errorSaving = true;
        };

        vm.save = function (isFromCopy) {
            if (isFromCopy && vm.isJobFlow) {
                delete vm.smartFlowModel["worfklow_id"];
                delete vm.smartFlowModel["bpaid"];
            }

            if (vm.smartFlowModel.id) {
                vm.isUpdate = true;
                vm.setOrUpdateSmartFlowModelData();
                SmartFlow.update(
                    vm.smartFlowModel,
                    vm.onSaveSuccess,
                    vm.onSaveError
                );
            } else {
                vm.isUpdate = false;
                SmartFlow.save(
                    vm.smartFlowModel,
                    isFromCopy ? vm.onSaveCopiedSuccess : vm.onSaveSuccess,
                    vm.onSaveError
                );
            }
        };

        vm.onPublishSuccess = function (result) {
            vm.smartFlowModel = result;
            vm.isReadOnly = true;
            vm.isSaving = false;
            ToastService.displayToast($filter("triTranslate")("atheer.smartFlow.detail.smartFlowPublishSuccess"), 3000);
        };

        vm.onPublishError = function () {
            vm.isSaving = false;
        };

        vm.publish = function () {
            var valid = SmartFlowBuilder.validate(vm.smartFlowModel.category);
            if (valid) {
                var confirm = $mdDialog
                    .confirm()
                    .title($filter('translate')('atheer.smartFlow.detail.publishSmartFlow'))
                    .ariaLabel(
                        $filter('translate')('atheer.smartFlow.detail.publishSmartFlow')
                    )
                    .ok($filter('translate')('atheer.smartFlow.detail.publish'))
                    .cancel($filter('translate')('entity.action.cancel'));

                $mdDialog.show(confirm).then(function () {
                    vm.smartFlowModel.status = "LIVE";
                    vm.setOrUpdateSmartFlowModelData();
                    SmartFlow.update(
                        vm.smartFlowModel,
                        vm.onPublishSuccess,
                        vm.onPublishError
                    );
                });
            }
        };

        vm.onPauseSuccess = function (result) {
            vm.smartFlowModel = result;
            vm.isSaving = false;
            ToastService.displayToast($filter("triTranslate")("atheer.smartFlow.detail.smartFlowPauseSuccess"), 3000);
        };

        vm.onPauseError = function () {
            vm.isSaving = false;
        };

        vm.pause = function () {
            var confirm = $mdDialog
                .confirm()
                .title($filter('translate')('atheer.smartFlow.detail.pauseSmartFlow'))
                .ariaLabel($filter('translate')('atheer.smartFlow.detail.pauseSmartFlow'))
                .ok($filter('translate')('atheer.smartFlow.detail.pause'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function () {
                vm.smartFlowModel.status = "PAUSED";
                vm.smartFlowModel.canvas_data = SmartFlowBuilder.getCanvasData();
                SmartFlow.update(
                    vm.smartFlowModel,
                    vm.onPauseSuccess,
                    vm.onPauseError
                );
            });
        };

        vm.onResumeSuccess = function (result) {
            vm.smartFlowModel = result;
            vm.isSaving = false;
            ToastService.displayToast($filter("triTranslate")('atheer.smartFlow.detail.smartFlowResumeSuccess'), 3000);
        };

        vm.onResumeError = function () {
            vm.isSaving = false;
        };

        vm.resume = function () {
            var confirm = $mdDialog
                .confirm()
                .title($filter('translate')('atheer.smartFlow.detail.resumeSmartFlow'))
                .ariaLabel($filter('translate')('atheer.smartFlow.detail.resumeSmartFlow'))
                .ok($filter('translate')('atheer.smartFlow.detail.resume'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function () {
                vm.smartFlowModel.status = "LIVE";
                vm.smartFlowModel.canvas_data = SmartFlowBuilder.getCanvasData();
                SmartFlow.update(
                    vm.smartFlowModel,
                    vm.onResumeSuccess,
                    vm.onResumeError
                );
            });
        };

        vm.onEndSuccess = function (result) {
            vm.smartFlowModel = result;
            vm.isSaving = false;
            ToastService.displayToast($filter("triTranslate")("atheer.smartFlow.detail.smartFlowEndedSucess"), 3000);
        };

        vm.onEndError = function () {
            vm.isSaving = false;
        };

        vm.end = function () {
            var confirm = $mdDialog
                .confirm()
                .title($filter('translate')('atheer.smartFlow.detail.endedSmartFlow'))
                .ariaLabel($filter('translate')('atheer.smartFlow.detail.endedSmartFlow'))
                .ok($filter('translate')('atheer.smartFlow.detail.end'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function () {
                vm.smartFlowModel.status = "ENDED";
                vm.smartFlowModel.canvas_data = SmartFlowBuilder.getCanvasData();
                SmartFlow.update(
                    vm.smartFlowModel,
                    vm.onEndSuccess,
                    vm.onEndError
                );
            });
        };

        vm.goToSmartFlows = function () {
            $state.go("storm.admin-smartflows");
        };

        vm.showJobInsightsView = function () {
            $state.go("storm.admin-jobflow-insights", {
                id: vm.smartFlowModel.id,
                name: vm.smartFlowModel.name,
            });
        };

        vm.isDraft = function () {
            if (vm.smartFlowModel.status == "DRAFT") {
                return true;
            } else {
                return false;
            }
        };

        vm.isPaused = function () {
            if (vm.smartFlowModel.status == "PAUSED") {
                return true;
            } else {
                return false;
            }
        };

        vm.isLive = function () {
            if (vm.smartFlowModel.status == "LIVE") {
                return true;
            } else {
                return false;
            }
        };

        vm.isEnded = function () {
            if (vm.smartFlowModel.status == "ENDED") {
                return true;
            } else {
                return false;
            }
        };

        vm.isNew = function () {
            if (
                vm.smartFlowModel == undefined ||
                vm.smartFlowModel.id == null
            ) {
                return true;
            } else {
                return false;
            }
        };

        vm.useThisSmartFlow = function () {
            delete vm.smartFlowModel["id"];
            vm.smartFlowModel.status = "DRAFT";
            vm.smartFlowModel.smart_flow_id = null;
            vm.smartFlowModel.smart_flow_no = null;

            vm.smartFlowModel.name = $filter('translate')('atheer.smartFlow.detail.copyOf') + vm.smartFlowModel.name;
            if (!vm.useSetting) {
                var cellsArray = [];
                var canvas_data = JSON.parse(vm.smartFlowModel.canvas_data);

                angular.forEach(canvas_data.cells, function (cell) {
                    if (cell.setting) {
                        var setting =
                            WORFLOW_CELL_CONSTANT[cell.setting.typeId].setting;
                        setting.type = cell.setting.type;
                        setting.typeId = cell.setting.typeId;
                        cell.setting = setting;
                    }
                    cellsArray.push(cell);
                });

                canvas_data.cells = cellsArray;
                vm.smartFlowModel.canvas_data = angular.toJson(canvas_data);
            }
            vm.save(true);
            $mdDialog.hide();
        };

        vm.copySmartFlow = function () {
            $mdDialog.show({
                animation: true,
                templateUrl:
                    "modules/smartflow/smartflow-template-options-dialog.tmpl.html",
                scope: $scope,
                backdrop: "static",
                clickOutsideToClose: !$stateParams.copy,
                size: "lg",
            });
        };

        vm.setGridSize = function () {
            SmartFlowBuilder.setGridSize(vm.gridSize);
        };

        vm.centerAndFit = function () {
            SmartFlowBuilder.centerAndFit();
        };

        vm.togglePanel = function (componentId) {
            $mdSidenav(componentId).toggle();
        };

        vm.showBuildView = function () {
            vm.viewType = "build";
        };

        vm.showSettingsView = function () {
            vm.isSmartflowSaved = SmartFlowBuilder.getSmartFlowStatus();

            if (vm.isSmartflowSaved) {
                var confirm = $mdDialog
                    .confirm()
                    .title(
                        $filter('translate')('atheer.smartFlow.detail.unsavedChanged')
                    )
                    .ariaLabel(
                        $filter('translate')('atheer.smartFlow.detail.unsavedChanged')
                    )
                    .ok($filter('translate')('entity.action.save'))
                    .cancel($filter('translate')('entity.action.cancel'));

                vm.viewType = "";

                $mdDialog.show(confirm).then(
                    function () {
                        vm.save();
                        vm.viewType = "settings";
                    },
                    function () {
                        SmartFlowBuilder.setSmartFlowStatus();
                        vm.viewType = "settings";
                    }
                );
            } else {
                vm.viewType = "settings";
            }
        };

        vm.changeType = function () {
            if (vm.smartFlowModel.type == "ONE_TIME") {
                vm.smartFlowModel.end_date = null;
                vm.smartFlowModel.allow_reentry = false;
                vm.smartFlowModel.schedule_info = null;
            } else if (vm.smartFlowModel.type == "ON_GOING") {
                vm.smartFlowModel.allow_reentry = false;
                vm.smartFlowModel.schedule_info = null;
            } else if (vm.smartFlowModel.type == "TIME_BASED") {
                vm.smartFlowModel.allow_reentry = true;
            } else if (vm.smartFlowModel.type === "JOB_EVENT") {
                vm.smartFlowModel.end_date = null;
                vm.smartFlowModel.allow_reentry = false;
                vm.smartFlowModel.schedule_info = null;
            }
        };

        vm.disableReEntry = function () {
            return (
                vm.smartFlowModel.status != "DRAFT" &&
                vm.smartFlowModel.status != "JOB_EVENT"
            );
        };

        vm.disableType = function () {
            return (
                vm.smartFlowModel.status != "DRAFT" &&
                vm.smartFlowModel.status != "JOB_EVENT"
            );
        };

        vm.disableStartDate = function () {
            return (
                vm.smartFlowModel.status != "DRAFT" &&
                vm.smartFlowModel.type == "ONE_TIME" &&
                vm.smartFlowModel.status != "JOB_EVENT"
            );
        };

        vm.disableEndDate = function () {
            return (
                vm.smartFlowModel.status != "DRAFT" &&
                vm.smartFlowModel.type == "ONE_TIME" &&
                vm.smartFlowModel.status != "JOB_EVENT"
            );
        };

        vm.addUsers = function () {
            $mdDialog.show({
                templateUrl: "modules/smartflow/smartflow-add-users.tmpl.html",
                clickOutsideToClose: true,
                controller: "SmartFlowAddUsersController",
                controllerAs: "vm",
                size: "lg",
                locals: {
                    smartFlow: vm.smartFlowModel,
                },
            });
        };

        vm.setOrUpdateSmartFlowModelData = function () {
            if (vm.isJobFlow) {
                var canvas_data = JSON.parse(SmartFlowBuilder.getCanvasData());
                vm.smartFlowModel.readerNodes = { cells: [] };
                angular.forEach(canvas_data.cells, function (cell) {
                    if (cell.smartFlowStencilType == "TRIGGER_READ") {
                        vm.smartFlowModel.readerNodes.cells.push(cell);
                    }
                });
                vm.smartFlowModel.readerNodes = angular.toJson(
                    vm.smartFlowModel.readerNodes
                );
                vm.smartFlowModel.canvas_data = angular.toJson(canvas_data);
            } else {
                vm.smartFlowModel.canvas_data = SmartFlowBuilder.getCanvasData();
                vm.smartFlowModel.trigger_info = SmartFlowBuilder.getTriggerInfo();
            }
        };

        vm.setFocusOnInput = function() {
            setTimeout(function() {
                document.getElementById('field_name').focus();
            }, 700);
        };

        $scope.$watch('vm.viewType', function(newValue) {
            if(newValue == 'settings') {
                vm.setFocusOnInput();
            }
        });
    }
})();
