(function() {
    'use strict';

    angular
        .module('atheer.content')
        .controller('ContentToolbarController', ContentToolbarController);

    /* @ngInject */
    function ContentToolbarController($rootScope, $scope, $state, $mdDialog, Content, ContentAction, CONTENT_META, ParseLinks, AlertService, pagingParams, paginationConstants, $mdToast, $stateParams, $filter, $translate, Feature, ToastService) {
        var vm = this;

        vm.refreshAll = refreshAll;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.currentSelectedCards = [];
        vm.showBulkAssignAction = false;
        vm.showBulkSendAction = false;

        vm.permissions = {
            create: 'create-content',
            update: 'update-content',
            delete: 'delete-content',
            manage: 'manage-content',
            createFolder: 'manage-folder-content',
            updateFolder: 'manage-folder-content',
            deleteFolder: 'manage-folder-content',
            manageFolder: 'manage-folder-content',
            manageTopic: 'manage-topic-content',
            manageBadge: 'manage-badge-system'
        };

        vm.createMenu = true;
        vm.createMenuOptions = $filter('filter')($filter('orderBy')(CONTENT_META, 'display_order'), {
            category: 'main',
            dispay_in_options: true,
            type: '!session-recording'
        });

        vm.query = {
            filter: ''
        };

        if ( getSavedFilters() ) {
            vm.query = getSavedFilters();
        }

        vm.filter = {
            options: {
                debounce: 500
            }
        };

        vm.removeFilter = removeFilter;
        vm.filter.show = vm.query.filter.length > 0 ? true : false;
        vm.createObject = createObject;

        vm.toggleFilter = function() {
            vm.filter.show = !vm.filter.show;
        };

        vm.selectMenu = function(item) {
            $state.go('storm.admin-content-detail', {
                'contentType': item.type.replace(/-/g, '_')
            });
        };

        vm.clearSelection = function() {
            vm.currentSelectedCards = [];
            $scope.$emit('refreshSelectedCardsListing', vm.currentSelectedCards);
        };

        vm.openBulkSendDialog = function() {
            if (vm.currentSelectedCards.length > 0) {
                var currentSelectedCardsIds = [];
                angular.forEach(vm.currentSelectedCards, function(currentSelectedCard) {
                    currentSelectedCardsIds.push(currentSelectedCard.id)
                })
                ContentAction.openSendModal(currentSelectedCardsIds, function() {
                    vm.currentSelectedCards = [];
                    $scope.$emit('refreshSelectedCardsListing', vm.currentSelectedCards);
                });
            }
        };

        vm.openBulkShareDialog = function() {
            if (vm.currentSelectedCards.length > 0) {
                var currentSelectedCardsIds = [];
                angular.forEach(vm.currentSelectedCards, function(currentSelectedCard) {
                    currentSelectedCardsIds.push(currentSelectedCard.id)
                })
                ContentAction.openShareDialog(currentSelectedCardsIds, function() {
                    vm.currentSelectedCards = [];
                    $scope.$emit('refreshSelectedCardsListing', vm.currentSelectedCards);
                });
            }
        };

        var refreshSelectedCardsToolbar = $rootScope.$on('refreshSelectedCardsToolbar', function(event, currentSelectedCards) {
            vm.currentSelectedCards = currentSelectedCards;

            vm.showBulkAssignAction = false;
            vm.showBulkSendAction = false;

            angular.forEach(vm.currentSelectedCards, function(content) {
                if (content.data.type == 'email_template') {
                    vm.showBulkSendAction = true;
                } else {
                    vm.showBulkAssignAction = true;
                }
            });
        });

        // Unregister
        $scope.$on('$destroy', function() {
            refreshSelectedCardsToolbar();
        });

        function refreshAll() {
            saveFilters(vm.query);
            $scope.$emit('refreshContent', vm.query.filter);
        };

        function removeFilter() {
            vm.filter.show = false;
            vm.query.filter = '';
            saveFilters(vm.query);
            $scope.$emit('refreshContent', vm.query.filter);
        };

        function createObject() {
            $state.go("storm.admin-content-detail");
        };

        function saveFilters(filterData) {
            sessionStorage.setItem('objectHeader', JSON.stringify(filterData));
        };
    
        function getSavedFilters() {
            return JSON.parse(sessionStorage.getItem('objectHeader'));
        };
    }
})();
