(function() {
    'use strict';

    angular
        .module('atheer.conversation')
        .controller('ConversationAudioRecordController', ConversationAudioRecordController);

    /* @ngInject */
    function ConversationAudioRecordController(mdPanelRef, $state, $timeout, Upload, recorderService) {
        var vm = this;

        vm.recordedAudio = null;
        vm.audioStatus = 'RECORD';
        vm.processStatus = 'IDLE';
        vm.uploadPercentage = 0;
        vm.media = null;

        vm.close = function() {
            recorderService.refresh();
            mdPanelRef.close();
        };

        vm.save = function() {
            vm.audioFile = vm.recordedAudio;
            vm.audioFile.name = 'audio_recording_' + generateUuid() + ".wav";
            vm.audioStatus = 'PROCESS';
            vm.processStatus = 'PROCESSING';

            Upload.upload({
                url: 'api/media/upload',
                data: {
                    'file': vm.audioFile,
                    'type': 'audio'
                },
                arrayKey: ''
            }).then(function(resp) {
                vm.media = resp.data;
                mdPanelRef.media = vm.media;
                mdPanelRef.send = true;
                mdPanelRef.close();
                vm.processStatus = 'COMPLETE';
            }, function(resp) {
                vm.processStatus = 'FAILED';
            }, function(evt) {
                vm.uploadPercentage = parseInt(100.0 * evt.loaded / evt.total);
            });
        }

        function generateUuid() {
            function _p8(s) {
                var p = (Math.random().toString(16) + "000000000").substr(2, 8);
                return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
            }

            return _p8() + _p8(true) + _p8(true) + _p8();
        };
    }
})();
