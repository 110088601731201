(function() {
    'use strict';

    angular
        .module('atheer.session')
        .controller('SessionPlayerMakeAlternateHostController', SessionPlayerMakeAlternateHostController);

    /* @ngInject */
    function SessionPlayerMakeAlternateHostController(userId, userName, $mdDialog, isMobileBrowser) {
        var vm = this;
        vm.userId = userId;
        vm.userName = userName;
        vm.isMobileBrowser = isMobileBrowser;

        vm.close = function() {
            $mdDialog.cancel();
        };
        vm.changeHost = function() {
            $mdDialog.hide(userId);
        }
    }
})();
