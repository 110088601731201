(function() {

    'use strict';
    angular
        .module('atheer.content')
        .constant('CONTENT_INSIGHT_META', {
            'document': {
                'sections': {
                    'overview': {
                        label: 'atheer.content.overview',
                        icon: 'zmdi zmdi-account',
                        controller: 'ContentDetailInsightOverviewController',
                          template: 'modules/content/content-detail-insight-overview.tmpl.html'
                    },
                    'ratings': {
                        label: 'atheer.content.ratings',
                        icon: 'zmdi zmdi-account',
                        controller: 'ContentDetailInsightRatingsSummaryController',
                        template: 'modules/content/content-detail-insight-ratings-summary.tmpl.html'
                    },
                    'problems': {
                        label: 'atheer.content.problems',
                        icon: 'zmdi zmdi-account',
                        controller: 'ContentDetailInsightProblemsSummaryController',
                        template: 'modules/content/content-detail-insight-problems-summary.tmpl.html'
                    }
                }
            },
            'image': {
                'sections': {
                    'overview': {
                        label: 'atheer.content.overview',
                        icon: 'zmdi zmdi-account',
                        controller: 'ContentDetailInsightOverviewController',
                        template: 'modules/content/content-detail-insight-overview.tmpl.html'
                    },
                    'ratings': {
                        label: 'atheer.content.ratings',
                        icon: 'zmdi zmdi-account',
                        controller: 'ContentDetailInsightRatingsSummaryController',
                        template: 'modules/content/content-detail-insight-ratings-summary.tmpl.html'
                    },
                    'problems': {
                        label: 'atheer.content.problems',
                        icon: 'zmdi zmdi-account',
                        controller: 'ContentDetailInsightProblemsSummaryController',
                        template: 'modules/content/content-detail-insight-problems-summary.tmpl.html'
                    }
                }
            },
            'smart_page': {
                'sections': {
                    'overview': {
                        label: 'atheer.content.overview',
                        icon: 'zmdi zmdi-account',
                        controller: 'ContentDetailInsightOverviewController',
                        template: 'modules/content/content-detail-insight-overview.tmpl.html'
                    },
                    'ratings': {
                        label: 'atheer.content.ratings',
                        icon: 'zmdi zmdi-account',
                        controller: 'ContentDetailInsightRatingsSummaryController',
                        template: 'modules/content/content-detail-insight-ratings-summary.tmpl.html'
                    },
                    'problems': {
                        label: 'atheer.content.problems',
                        icon: 'zmdi zmdi-account',
                        controller: 'ContentDetailInsightProblemsSummaryController',
                        template: 'modules/content/content-detail-insight-problems-summary.tmpl.html'
                    }
                }
            },
            'podcast': {
                'sections': {
                    'overview': {
                        label: 'atheer.content.overview',
                        icon: 'zmdi zmdi-account',
                        controller: 'ContentDetailInsightOverviewController',
                        template: 'modules/content/content-detail-insight-overview.tmpl.html'
                    },
                    'ratings': {
                        label: 'atheer.content.ratings',
                        icon: 'zmdi zmdi-account',
                        controller: 'ContentDetailInsightRatingsSummaryController',
                        template: 'modules/content/content-detail-insight-ratings-summary.tmpl.html'
                    },
                    'problems': {
                        label: 'atheer.content.problems',
                        icon: 'zmdi zmdi-account',
                        controller: 'ContentDetailInsightProblemsSummaryController',
                        template: 'modules/content/content-detail-insight-problems-summary.tmpl.html'
                    }
                }
            },
            'video': {
                'sections': {
                    'overview': {
                        label: 'atheer.content.overview',
                        icon: 'zmdi zmdi-account',
                        controller: 'ContentDetailInsightOverviewController',
                        template: 'modules/content/content-detail-insight-overview.tmpl.html'
                    },
                    'ratings': {
                        label: 'atheer.content.ratings',
                        icon: 'zmdi zmdi-account',
                        controller: 'ContentDetailInsightRatingsSummaryController',
                        template: 'modules/content/content-detail-insight-ratings-summary.tmpl.html'
                    },
                    'problems': {
                        label: 'atheer.content.problems',
                        icon: 'zmdi zmdi-account',
                        controller: 'ContentDetailInsightProblemsSummaryController',
                        template: 'modules/content/content-detail-insight-problems-summary.tmpl.html'
                    }
                }
            },
            'announcement': {
                'sections': {
                    'overview': {
                        label: 'atheer.content.overview',
                        icon: 'zmdi zmdi-account',
                        controller: 'AnnouncementInsightOverviewController',
                        template: 'modules/content/announcement-insight-overview.tmpl.html'
                    }
                }
            },
            'link': {
                'sections': {
                    'overview': {
                        label: 'atheer.content.overview',
                        icon: 'zmdi zmdi-account',
                        controller: 'ContentDetailInsightOverviewController',
                        template: 'modules/content/content-detail-insight-overview.tmpl.html'
                    },
                    'ratings': {
                        label: 'atheer.content.ratings',
                        icon: 'zmdi zmdi-account',
                        controller: 'ContentDetailInsightRatingsSummaryController',
                        template: 'modules/content/content-detail-insight-ratings-summary.tmpl.html'
                    },
                    'problems': {
                        label: 'atheer.content.problems',
                        icon: 'zmdi zmdi-account',
                        controller: 'ContentDetailInsightProblemsSummaryController',
                        template: 'modules/content/content-detail-insight-problems-summary.tmpl.html'
                    }
                }
            },
            'session_recording': {
                'sections': {
                    'overview': {
                        label: 'atheer.content.overview',
                        icon: 'zmdi zmdi-account',
                        controller: 'ContentDetailInsightOverviewController',
                          template: 'modules/content/content-detail-insight-overview.tmpl.html'
                    },
                    'ratings': {
                        label: 'atheer.content.ratings',
                        icon: 'zmdi zmdi-account',
                        controller: 'ContentDetailInsightRatingsSummaryController',
                        template: 'modules/content/content-detail-insight-ratings-summary.tmpl.html'
                    },
                    'problems': {
                        label: 'atheer.content.problems',
                        icon: 'zmdi zmdi-account',
                        controller: 'ContentDetailInsightProblemsSummaryController',
                        template: 'modules/content/content-detail-insight-problems-summary.tmpl.html'
                    }
                }
            },
        });
})();
