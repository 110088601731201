(function() {
    'use strict';

    angular
        .module('atheer.system')
        .controller('BadgeAssignController', BadgeAssignController);

    /* @ngInject */
    function BadgeAssignController($scope, $state, $mdDialog, $q, $filter, $timeout, badge, userId, Experience, User, Badge, ToastService) {
        var vm = this;

        vm.asyncUsers = [];
        vm.badge = badge;
        vm.searchUser = searchUser;

        vm.asyncBadges = [];
        vm.fromProfilePage = userId != undefined;
        vm.searchBadge = searchBadge;

        function updateSelectedBadgeIndex(value) {
            if (value) {
                var skillObjectFromList = $filter('filter')(vm.asyncSkills, {
                    skillObj: {
                        id: value.skillObj.id
                    }
                })[0];
                if (skillObjectFromList) {
                    vm.selectedSkillIndex = vm.asyncSkills.indexOf(skillObjectFromList);
                } else {
                    vm.selectedSkillIndex = -1;
                }
            }
        }

        function getUserQueryString(criteria) {
            if (criteria.length <= 0) {
                return null;
            } else {
                return 'text_search=ts=' + criteria;
            }
        }

        function getBadgeQueryString(criteria) {
            if (criteria.length <= 0) {
                return null;
            } else {
                return 'name=re=' + criteria;
            }
        }

        function searchBadge(criteria) {
            var d = $q.defer();
            var result = Badge.query({
                query: getBadgeQueryString(criteria)
            }, function(result) {
                d.resolve(result);
            });
            return d.promise;
        }

        vm.transformChip = function(chip) {
            if (angular.isObject(chip)) {
                return chip;
            }
        }

        function searchUser(criteria) {
            var d = $q.defer();
            var result = User.query({
                query: getUserQueryString(criteria)
            }, function(result) {
                d.resolve(result);
            });
            return d.promise;
        }

        vm.close = function() {
            $mdDialog.cancel();
        }

        vm.submit = function() {
            if (!vm.fromProfilePage) {
                angular.forEach(vm.asyncUsers, function(user) {
                    Experience.assignBadge({
                        user_id: user.id,
                        object: vm.badge,
                        acquired_on: new Date()
                    }, function(result) {
                        ToastService.displayToast($filter('triTranslate')('assignBadge.assignedSuccessfull'), 3000);
                        $mdDialog.hide();
                    });
                });
            } else {
                angular.forEach(vm.asyncBadges, function(badge) {
                    Experience.assignBadge({
                        user_id: userId,
                        object: badge,
                        acquired_on: new Date()
                    }, function(result) {
                        ToastService.displayToast($filter('triTranslate')("assignBadge.assignedSuccessfull"), 3000);
                        $mdDialog.hide();
                    });
                });
            }
        }
    }
})();
