(function() {
    'use strict';

    angular
        .module('atheer.content')
        .controller('SessionRecordingDetailController', SessionRecordingDetailController)
        .controller('SessionRecordingViewController', SessionRecordingViewController)
        .controller('SessionRecordingFeedbackController', SessionRecordingFeedbackController)

    /* @ngInject */
    function SessionRecordingFeedbackController($scope, $mdBottomSheet) {
    }

    /* @ngInject */
    function SessionRecordingViewController($scope, $timeout, $controller, $mdBottomSheet) {
        var basectrl = $controller('BaseContentViewController as vm', {
            $scope: $scope
        });
        angular.extend(this, basectrl);

        $scope.player_id = 'atheer-player-' + Math.floor(Math.random() * (100000 - 1000 + 1)) + 1000;
        $scope.isLoaded = false;

        $timeout(function() {
            $scope.videoSettings = {
                playlist: [{
                    file: '/media/session-recordings/' + $scope.data.data.source_media.id + '/' + $scope.data.data.source_media.properties.file_name
                }],
                autostart: false,
                width: "100%",
                aspectratio: "16:9",
                skin: {
                    name: "atheer"
                },
                playbackRateControls: true,
                playbackRates: [0.25, 0.75, 1, 1.25, 1.5, 2, 3, 4]
            }
            $scope.isLoaded = true;
        }, 1000);

        $scope.showFeedback = function(firstTime) {
            $mdBottomSheet.show({
                templateUrl: 'modules/content/session-recording-feedback.tmpl.html',
                clickOutsideToClose: false,
                escapeToClose: false,
                controller: 'SessionRecordingFeedbackController',
                scope: $scope,
                preserveScope: true,
                parent: '#fp-content-view-' + $scope.data.id + '-' + $scope.context,
                disableParentScroll: true,
                locals: {
                    firstTime: firstTime
                }
            });
        }
    }

    /* @ngInject */
    function SessionRecordingDetailController($scope, $timeout, $controller, $interval, id, Content, poller, $resource) {
        var basectrl = $controller('BaseContentDetailController as vm', {
            $scope: $scope,
            id: id,
            content_type: "session-recording"
        });
        angular.extend(this, basectrl);

        var vm = this;
        vm.uploadStatus = 'idle';
        vm.processStatus = 'IDLE';
        vm.extractStatus = 'IDLE';
        vm.isExternalSessionRecording = false;
        vm.fileMaxSize = 500 * 1024 * 1024;



    }
})();
