(function() {
    'use strict';

    angular
        .module('atheer.content')
        .controller('PodcastFeedbackController', PodcastFeedbackController)

    function PodcastFeedbackController($scope, $mdBottomSheet) {

    }
})();
