(function() {
    'use strict';

    angular
        .module('atheer.core')
        .config(config);

    /* @ngInject */
    function config(triLayoutProvider) {
        // set app templates (all in core/layouts folder so you can tailor them to your needs)

        // loader screen HTML & controller
        triLayoutProvider.setDefaultOption('loaderTemplateUrl', 'core/components/loader/loader.tmpl.html');
        triLayoutProvider.setDefaultOption('loaderController', 'LoaderController');

        // Contenty Layout HTML and controller
        triLayoutProvider.setDefaultOption('contentLayoutTemplateUrl', 'core/layouts/core.tmpl.html');
        triLayoutProvider.setDefaultOption('contentLayoutController', 'StormStateController');

        // left sidemenu HTML and controller
        triLayoutProvider.setDefaultOption('sidebarLeftTemplateUrl', 'core/components/leftsidenav/leftsidenav.tmpl.html');
        triLayoutProvider.setDefaultOption('sidebarLeftController', 'LeftSidenavController');

        // Side Menu HTML and Controller
        triLayoutProvider.setDefaultOption('sideMenuTemplateUrl', 'core/components/menu/sidemenu.tmpl.html');
        triLayoutProvider.setDefaultOption('sideMenuController', 'SideMenuController');

        // top toolbar HTML and controller
        triLayoutProvider.setDefaultOption('toolbarTemplateUrl', 'core/components/toolbars/toolbar.tmpl.html');
        triLayoutProvider.setDefaultOption('toolbarController', 'ToolbarController');

        // right sidemenu HTML and controller
        triLayoutProvider.setDefaultOption('sidebarRightTemplateUrl', 'core/components/rightsidenav/rightsidenav.tmpl.html');
        triLayoutProvider.setDefaultOption('sidebarRightController', 'RightSidenavController');

        triLayoutProvider.setDefaultOption('sidebarNotificationsTemplateUrl', 'core/components/notifications/notifications.tmpl.html');
        triLayoutProvider.setDefaultOption('sidebarNotificationsController', 'NotificationsSideNavController');

        // footer HTML
        triLayoutProvider.setDefaultOption('footerTemplateUrl', 'core/components/footer/footer.tmpl.html');

        triLayoutProvider.setDefaultOption('toolbarSize', 'default');
        triLayoutProvider.setDefaultOption('toolbarShrink', false);
        triLayoutProvider.setDefaultOption('toolbarClass', '');
        triLayoutProvider.setDefaultOption('contentClass', '');
        triLayoutProvider.setDefaultOption('sideMenuSize', 'icon');
        triLayoutProvider.setDefaultOption('showToolbar', true);
        triLayoutProvider.setDefaultOption('showSideNavMenu', false);
        triLayoutProvider.setDefaultOption('sideNavMenuPosition', 'left');
        triLayoutProvider.setDefaultOption('sideMenuNavSize', 15);
        triLayoutProvider.setDefaultOption('footer', false);
    }
})();
