(function() {
    'use strict';

    angular
        .module('atheer.team')
        .controller('TeamDetailController', TeamDetailController)

    /* @ngInject */
    function TeamDetailController($scope, $rootScope, $mdDialog, $filter, $q, id, triTheming, triSettings, Experience, Team, $state, AlertService, $mdToast, $mdSidenav, Content, AirForm, User, ParseLinks, $timeout, pagingParams, paginationConstants, $stateParams, $window, ToastService) {
        var vm = this;

        vm.loadingData = false;
        vm.loadingUsers = false;
        vm.teamDataModel = null;
        vm.showTeamDetails = true;
        vm.emptyCriteria = {
            match_all: true,
            condition_groups: []
        };
        vm.showTeamFilters = true;
        vm.showUsersUsingFilters = false;
        vm.teamType = 'STATIC';
        vm.users = [];
        vm.owner = null;
        vm.isHovered = false;
        vm.predicate = "id";
        vm.primaryBackgroundColor = triTheming.rgba(
            triTheming.getPaletteColor(triSettings.getSkinModel().primary_color, '500').value
        );
        vm.secondaryBackgroundColor = triTheming.rgba(
            triTheming.getPaletteColor(triSettings.getSkinModel().secondary_color, '500').value
        );

        vm.userHistoryPredicate = 'id';
        vm.userHistoryReverse = 'asc';
        vm.userHistoryPage = 1;
        vm.userHistoryTotalItems = null;
        vm.userHistory = [];
        vm.isUserHistoryLoaded = false;
        vm.userHistoryQueryCount = 0;
        vm.userHistoryQueryLimit = paginationConstants.itemsPerPage;
        vm.illustartionEmptyScreenIdUser = "no_user_team";
        vm.isTeamInsightsLoaded = false;

        if (angular.isDefined($stateParams.teamType) && $stateParams.teamType != null) {
            vm.teamType = $stateParams.teamType;
        };

        vm.query = {
            filter: '',
            limit: paginationConstants.itemsPerPage,
            page: 1
        };

        vm.teamUserChartData = {
            chart: {
                type: 'line',
            },
            title: {
                text: $filter('translate')('atheer.teamInsights.members')
            },
            credits: {
                enabled: false
            },
            yAxis: {
                title: {
                    text: $filter('translate')('atheer.teamInsights.noOfMembers')
                }
            },
            xAxis: {
                title: {
                    text: $filter('translate')('atheer.teamInsights.date')
                },
                type: 'datetime',
                labels: {
                    format: '{value:%e - %b - %Y}'
                }

            },
            series: [{
                name: $filter('translate')('atheer.teamInsights.members'),
                data: []
            }],
            tooltip: {
                formatter: function() {
                    return '<b>' + Highcharts.dateFormat('%e - %b - %Y',
                        new Date(this.x)) + '</b><br/> Members : ' + this.y;
                }
            }
        };

        function loadTeamInsights() {
            vm.isTeamInsightsLoaded = true;

            Team.getMemberCountHistory({
                id: id,
                page: 0,
                size: 90,
                sort: 'id,desc',
                query: null
            }, onInsightsSuccess, onInsightsError);

            function onInsightsSuccess(result) {
                vm.teamUserChartData.series[0].data = [];
                vm.teamMemberCountHistory = result;
                angular.forEach(result, function(countHistory) {
                    vm.teamUserChartData.series[0].data.push({
                        x: new Date(countHistory.record_date),
                        y: countHistory.member_count
                    })
                });
                vm.teamUserChartData.series[0].data = $filter('orderBy')(vm.teamUserChartData.series[0].data, getDateFromItem);
                vm.isTeamInsightsLoaded = false;
            }

            function onInsightsError(error) {
                AlertService.error(error.data.message);
                vm.isTeamInsightsLoaded = false;
            }
        };

        function getDateFromItem(item) {
            return item.x;
        }

        function loadAll() {
            if (id) {
                vm.loadingData = true;

                Team.get({
                    id: id
                }, function(result, headers) {
                    vm.teamDataModel = result;
                    vm.teamType = result.type;
                    vm.loadingData = false;
                    if (vm.teamType == 'SMART') {
                        vm.showTeamDetails = false;
                        vm.showTeamFilters = true;
                    };
                    if (vm.teamDataModel.status == 'DRAFT' && vm.teamType == 'SMART') {
                        vm.showUsersUsingFilters = true;
                    } else {
                        vm.showUsersUsingFilters = false;
                    }
                    loadUsers();
                });
            } else {
                if (vm.teamType == 'SMART') {
                    vm.teamDataModel = {
                        name: "Team Title",
                        description: "",
                        external_id: null,
                        type: vm.teamType,
                        status: "DRAFT",
                        enabled: false,
                        criteria: vm.emptyCriteria
                    };
                } else {
                    vm.teamDataModel = {
                        name: "Team Title",
                        description: "",
                        external_id: null,
                        type: vm.teamType,
                        status: "DRAFT",
                        enabled: false
                    };
                }

            }
        };

        function loadUsers() {
            vm.loadingUsers = true;

            if (vm.teamDataModel.type == 'SMART' && vm.showUsersUsingFilters) {
                var criteria = vm.teamDataModel.criteria;

                Experience.getUsers({
                    page: pagingParams.page - 1,
                    size: paginationConstants.itemsPerPage,
                    sort: getSortOrder()
                }, criteria, function(data, headers) {
                    vm.users = data;
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    vm.page = pagingParams.page;
                    vm.loadingUsers = false;
                }, function onError(error) {
                    AlertService.error(error.data.message);
                    vm.loadingUsers = false;
                });
            } else {
                Experience.getTeams({
                    page: pagingParams.page - 1,
                    size: paginationConstants.itemsPerPage,
                    query: "object_id==" + id,
                    user_info: true
                }, function(data, headers) {
                    vm.users = [];
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;

                    angular.forEach(data, function(userTeam) {
                        var userDetails = userTeam.user_info;
                        userDetails.assigned_on = userTeam.assigned_on;
                        vm.users.push(userDetails);
                    });

                    vm.page = pagingParams.page;
                    vm.loadingUsers = false;
                }, function onError(error) {
                    AlertService.error(error.data.message);
                    vm.loadingUsers = false;
                });
            }
        };

        function saveTeam() {
            $mdDialog.show({
                templateUrl: 'modules/team/team-detail-save-dialog.tmpl.html',
                clickOutsideToClose: true,
                controller: "TeamDetailSaveDialogController",
                controllerAs: "vm",
                locals: {
                    mode: 'STATIC',
                    team: vm.teamDataModel
                },
                size: 'lg'
            }).then(function() {
                if (vm.teamDataModel.type == 'SMART') {
                    loadAll();
                }
            });
        };

        vm.transitionUsers = function() {
            if (vm.teamDataModel.type == 'SMART' && vm.showUsersUsingFilters) {
                var criteria = vm.teamDataModel.criteria;

                vm.promise = Experience.getUsers({
                    page: vm.page - 1,
                    size: paginationConstants.itemsPerPage,
                    sort: getSortOrder(),
                }, criteria).$promise;

                vm.promise.then(function(data, headers) {
                    vm.users = data;
                    vm.queryCount = vm.totalItems;
                });
            } else {
                vm.promise = Experience.getTeams({
                    page: vm.page - 1,
                    size: paginationConstants.itemsPerPage,
                    query: "object_id==" + id,
                    user_info: true
                }).$promise;

                vm.promise.then(function(data, headers) {
                    vm.users = [];
                    vm.queryCount = vm.totalItems;
                    angular.forEach(data, function(userTeam) {
                        vm.users.push(userTeam.user_info);
                    });
                });
            }
        };

        vm.getQueryString = function(criteria) {
            if (criteria.length <= 0) {
                return null;
            } else {
                return 'name=re=' + criteria;
            }
        };

        vm.refreshUsers = function() {
            loadUsers();
        };

        vm.save = function() {
            if (vm.teamDataModel.id) {
                if (vm.teamDataModel.type == 'SMART' && vm.teamDataModel.status == 'ACTIVE') {
                    saveTeam();
                } else {
                    Team.update(vm.teamDataModel, onSaveSuccess, onSaveFailure);
                }
            } else {
                Team.save(vm.teamDataModel, onSaveSuccess, onSaveFailure);
            };

            function onSaveSuccess(result) {
                if (vm.teamDataModel.id == null) {
                    $state.go("storm.admin-team-detail", {
                        'id': result.id
                    });
                    AlertService.displayToast($mdToast);
                } else {
                    id = result.id;
                    vm.teamDataModel = result;
                    vm.isSaving = false;
                    AlertService.displayToast($mdToast);

                    // firing an event downwards
                    $scope.$emit('updateTeam');
                }
            }

            function onSaveFailure(error) {
                var msg = error.data.description;
                vm.isSaving = false;
                if ( msg.includes("duplicate key error") ) {
                    msg = $filter('translate')('atheer.team.uniqueNameRequired');
                }
                ToastService.displayToast($filter('translate')('atheer.team.saveFailed', { reason: msg }));
            }
        };

        vm.goToTeams = function() {
            $state.go('storm.admin-teams');
        };

        vm.isNew = function() {
            if (!vm.teamDataModel) {
                return false;
            } else {
                if (vm.teamDataModel.id == null) {
                    return true;
                } else {
                    return false;
                }
            }
        };

        vm.togglePanel = function(componentId) {
            vm.isUserHistoryLoaded = false;
            vm.isTeamInsightsLoaded = false;
            $mdSidenav(componentId).toggle()
                .then(function() {
                    if (componentId == 'teamHistoryPanel') {
                        vm.loadMemberHistory();
                    }
                    if (componentId == 'teamInsightsPanel') {
                        loadTeamInsights();
                    }
                });
        };

        vm.getDefaultBackground = function() {
            return '#bdbec0';
        };

        vm.hideInfo = function() {
            $timeout(function() {
                if (vm.isHovered) {
                    vm.isHovered = false;
                }
            }, 1000);
        };

        vm.loadMemberHistory = function() {
            vm.userHistory = [];
            vm.isUserHistoryLoaded = false;
            Team.getMemberHistory({
                id: id,
                page: 0,
                size: paginationConstants.itemsPerPage,
                query: null
            }, onUserHistorySuccess, onUserHistoryError);

            function onUserHistorySuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.userHistoryTotalItems = headers('X-Total-Count');
                vm.userHistoryQueryCount = vm.userHistoryTotalItems;
                vm.userHistoryPage = 1;
                vm.userHistory = data;
                vm.isUserHistoryLoaded = true;
            }

            function onUserHistoryError(error) {
                AlertService.error(error.data.message);
                vm.isUserHistoryDataLoaded = true;
            }
        };

        vm.userHistoryTransition = function(pageNo) {
            if (!pageNo) {
                pageNo = vm.userHistoryPage;
            } else {
                $scope.userHistoryPage = pageNo;
            }

            vm.promise = Team.getMemberHistory({
                id: id,
                page: pageNo - 1,
                size: paginationConstants.itemsPerPage,
                sort: getSortOrder()
            }).$promise;

            vm.promise.then(function(data, headers) {
                vm.userHistoryQueryCount = vm.userHistoryTotalItems;
                vm.userHistory = data;
            });
        };

        vm.removeUser = function(userToRemove, index) {
            var usersToRemove = [];
            usersToRemove.push(userToRemove);

            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.team.deleteUser'))
                .ariaLabel($filter('translate')('atheer.team.deleteUser'))
                .ok($filter('translate')('entity.action.delete'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function() {
                var userTeams = [];
                angular.forEach(usersToRemove, function(user) {
                    userTeams.push({
                        user_id: user.id,
                        object: vm.teamDataModel,
                        source: 'ADMIN',
                        assigned_on: new Date()
                    });
                });
                Experience.bulkTeamsRemove(userTeams, function(result) {
                    loadUsers();
                });
            });
        };

        vm.removeUsers = function(usersToRemove, index) {
            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.team.deleteUser'))
                .ariaLabel($filter('translate')('atheer.team.deleteUser'))
                .ok($filter('translate')('entity.action.delete'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function() {
                var userTeams = [];
                angular.forEach(vm.selectedUsers, function(user) {
                    userTeams.push({
                        user_id: id,
                        object: vm.teamDataModel,
                        source: 'ADMIN',
                        assigned_on: new Date()
                    });
                });
                Experience.bulkTeamsRemove(userTeams, function(result) {
                    loadUsers();
                });
            });
        };

        vm.openUserPicker = function() {
            $mdDialog.show({
                    templateUrl: 'modules/user/user-picker.tmpl.html',
                    controller: 'UserPickerController',
                    controllerAs: 'vm',
                    locals: {
                        filter: vm.invitedUserIds,
                        allowMultiSelect: true,
                        userslist: [],
                        isSession: false,
                        isScheduledSession: false,
                        isAdmin: true,
                        isConversationSession: false,
                        isSmartScan: true
                    }
                })
                .then(function(selectedItems) {
                    var userTeams = [];
                    angular.forEach(selectedItems, function(user) {
                        userTeams.push({
                            user_id: user.id,
                            object: vm.teamDataModel,
                            source: 'ADMIN',
                            assigned_on: new Date()
                        });
                    });
                    Experience.bulkTeams(userTeams, function(result) {
                        loadUsers();
                    });
                });
        };

        vm.togglerShowUsersFilter = function() {
            loadUsers();
        };

        //calls when filters are updated
        var updateUserListListener = $rootScope.$on('updateUserList', function(event) {
            if (vm.teamDataModel.type == 'SMART' && vm.showUsersUsingFilters) {
                loadUsers();
            }
        });

        // Unregister
        $scope.$on('$destroy', function() {
            updateUserListListener();
        });

        function getSortOrder() {
            if (vm.predicate.startsWith('-')) {
                return vm.predicate.substr(1) + ',' + 'desc';
            } else {
                return vm.predicate + ',' + 'asc';
            }

        }
        loadAll();
    }
})();
