(function () {
    "use strict";

    angular
        .module("atheer.insights")
        .controller("InsightsSummaryController", InsightsSummaryController);

    /* @ngInject */
    function InsightsSummaryController(
        $rootScope,
        $scope,
        $state,
        $mdDialog,
        AlertService,
        Workspace,
        workspaceId,
        pagingParams,
        paginationConstants,
        ParseLinks,
        $q,
        $mdToast,
        $filter,
        Report,
        CONTENT_META
    ) {
        var vm = this;

        vm.loadingData = false;
        vm.dashboard = null;

        vm.getCount = function (activityName) {
            for (
                var i = 0;
                i < vm.dashboard.contents_summary.metrics.length;
                i++
            ) {
                if (
                    vm.dashboard.contents_summary.metrics[i].name ===
                    activityName
                ) {
                    return vm.dashboard.contents_summary.metrics[i].count;
                }
            }
            return 0;
        };

        vm.loadData = function () {
            vm.loadingData = true;

            var resource = angular.isDefined(workspaceId)
                ? Workspace.getDashboard({
                      id: workspaceId,
                  }).$promise
                : Report.getDashboard().$promise;

            $q.all([
                resource
            ]).then(function (results) {
                vm.dashboard = results[0];
                vm.dashboard.conversations_summary.avg_messages_per_conversation = Math.ceil(
                    vm.dashboard.conversations_summary
                        .avg_messages_per_conversation
                );
                vm.dashboard.smartflows_summary = {
                    total_smartflows: 0,
                    total_executions: 0,
                    total_active: 0,
                    total_draft: 0,
                };

                vm.loadingData = false;
            });
        };
        vm.loadData();
    }
})();
