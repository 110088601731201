(function() {
    'use strict';

    angular
        .module('atheer.services')
        .factory('SessionConfigService', SessionConfigService)

    /* @ngInject */
    function SessionConfigService($rootScope, $filter) {

        var domain = null;

        var service = {
            init: init,
            getDomain: getDomain
        };
        return service;

        function init(session_config) {
            domain = session_config.domain;
        };

        function getDomain() {
            return domain;
        };
    }
})();
