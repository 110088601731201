(function() {
    'use strict';
    angular
        .module('atheer')
        .directive('fpHelp', fpHelp)

    function fpHelp() {
        var directive = {
            restrict: 'E',
            scope: {
                'key': '@'
            },
            controller: helpDirectiveController,
            controllerAs: 'vm',
            templateUrl: 'core/components/help/help.tmpl.html'
        };
        return directive;
    }

    /* @ngInject */
    function helpDirectiveController($scope, $timeout, $window, Feature) {
        var vm = this;
        vm.showHelpCenter = false; // Feature.isActive('feature_studio-settings_help_center');
    }
})();
