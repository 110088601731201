(function() {
    'use strict';
    angular
        .module('atheer')
        .directive('fpToggle', fpToggle)

    function fpToggle() {
        var directive = {
            restrict: 'E',
            scope: {
                'key': '@'
            },
            controller: toggleDirectiveController,
            templateUrl: 'core/components/toggle/toggle.tmpl.html'
        };
        return directive;
    }

    /* @ngInject */
    function toggleDirectiveController($scope, $timeout) {

        $scope.key = angular.isDefined($scope.key) ? $scope.key : 'zmdi zmdi-menu';

        $scope.toggleSideMenu = function() {
            $scope.$emit('toggleSideMenu', 'toggle');
        };
    }
})();
