(function() {
    'use strict';
    angular
        .module('atheer.services')
        .factory('BuildLanguages', BuildLanguages);

    /* @ngInject */
    function BuildLanguages ($cookies) {
        // Any changes in this array need to be made in `server/app.js`
        var availableBuildLanguages = [
            "de-DE",
            "el-GR",
            "en-GB",
            "es-CL",
            "fr-FR",
            "it-IT",
            "ja-JP",
            "pt-BR",
            "zh-TW",
            "zh-CN",
            'da_DK',
            'no_NO',
            'pl_PL',
            'ru_RU',
            'sv_SE',
            'tr_TR'
        ];

        var service = {
            getBuildLanguage: getBuildLanguage,
            getAvailableBuildLanguages: getAvailableBuildLanguages
        };
        return service;

        function getBuildLanguage() {
            var selectedBuildLanguage = "en-US";
            var locale = $cookies.get('NG_TRANSLATE_LANG_KEY');
            locale = locale.replace(/_/g, "-").replace(/"/g, "");
            availableBuildLanguages.find(function (buildLanguage) {
                if (buildLanguage.indexOf(locale) > -1) {
                    selectedBuildLanguage = buildLanguage;
                }
            });

            return selectedBuildLanguage;
        }

        function getAvailableBuildLanguages() {
            return availableBuildLanguages;
        }
    }
})();
