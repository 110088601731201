(function () {
    "use strict";

    angular
        .module("atheer.smartFlow")
        .controller(
            "JobFlowInsightsJobDetailsController",
            JobFlowInsightsJobDetailsController
        );

    /* @ngInject */
    function JobFlowInsightsJobDetailsController($mdDialog, JobFlow, data) {
        var vm = this;
        vm.loadingData = true;

        vm.clear = function () {
            $mdDialog.hide();
        };

        function init() {
            JobFlow.getJobDetail(
                {
                    query: "jobNumber==" + data,
                },
                function (result) {
                    vm.loadingData = false;
                    vm.jobList = [
                        {
                            id: data,
                            airform: result[0].airformRef.name,
                            team: result[0].team ? result[0].team.name : "",
                            assignedTo: result[0].owner
                                ? result[0].owner.fullName
                                : "",
                        },
                    ];
                }
            );
        }

        init();
    }
})();
