(function () {
    'use strict';

    angular
        .module('atheer.sdk')
        .controller('SdkKeyController', SdkKeyController);

    /* @ngInject */
    function SdkKeyController($rootScope, $scope, $sce, $cookies, $q, Setting, $filter, $mdDialog, Feature, ToastService) {
        var vm = this;

        vm.viewType = "doc";
        vm.webHooksEnabled = Feature.isActive('feature_airsdk_webhooks');
        vm.hostUrl = $sce.trustAsResourceUrl("https://light-sloth-6.redoc.ly/");

        vm.loadingData = false;
        vm.apiKeySettings = [];
        vm.apiKey = null;
        vm.createdOn = null;
        vm.expiresOn = null;

        vm.loadData = function() {
            vm.loadingData = true;

            $q.all([
                    Setting.getAPIKeySettings().$promise
                ])
                .then(function(results) {
                    vm.apiKeySettings = results[0];
                    setAPIKey(vm.apiKeySettings);
                    vm.loadingData = false;
                });
        };

        function setAPIKey(settings) {
            angular.forEach(settings, function(setting) {
                if (setting.category == 'api_key') {
                    angular.forEach(setting.fields, function(settingField) {
                        if (settingField.name == 'api_key') {
                            vm.apiKey = settingField.value;
                        }
                    });
                    vm.createdOn = new Date(setting.created_on);
                    vm.expiresOn = new Date(setting.created_on);
                    vm.expiresOn = vm.expiresOn.setDate(vm.expiresOn.getDate() + 365) ;
                }
            });
        }

        vm.generateAPIKeySettings = function() {
            Setting.generateAPIKeySettings(function(result) {
                vm.apiKeySettings = result;
                setAPIKey(vm.apiKeySettings);
                ToastService.displayToast($filter('triTranslate')("atheer.airsdk.generatedSucess"), 3000);
            });
        }

        vm.deactivateAPIKeySettings = function() {
            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.airsdk.deactivateApiKey'))
                .htmlContent($filter('translate')('atheer.airsdk.deactivateHtmlContent'))
                .ariaLabel($filter('translate')('atheer.airsdk.deactivateApiKey'))
                .ok($filter('translate')('atheer.airsdk.deactivateButton'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function() {
                Setting.deactivateAPIKeySettings(function() {
                    vm.apiKeySettings = null;
                    vm.apiKey = null;
                    vm.createdOn = null;
                    vm.expiresOn = null;

                    ToastService.displayToast($filter('triTranslate')("atheer.airsdk.deactivatedSuccessful"), 3000);
                });
            });
        }

        vm.showDocView = function() {
            vm.viewType = "doc";
        };

        vm.showKeyView = function() {
            vm.viewType = "key";
        };

        vm.loadData();
    }
})();
