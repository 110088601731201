(function () {
	'use strict';

	angular.module('atheer.copilot').controller('CopilotController', CopilotController);

	/* @ngInject */
	function CopilotController(
		$rootScope,
		$state,
		$scope,
		$sce,
		$cookies,
		BuildLanguages,
		$location,
		$window
	) {
		var vm = this;
		vm.ngxUrlTrusted = null;

		function setNgxPageUrl() {
			
			var buildLanguage = BuildLanguages.getBuildLanguage();
			var path = $location.path();
			var hostUrl =
				location.hostname === 'localhost'
					? 'http://' + location.hostname + ':3005'
					: 'https://airpilot.atheer.dev';
			
			var ngxUrl = hostUrl;
			vm.ngxUrlTrusted = $sce.trustAsResourceUrl(ngxUrl);
		}

		function handleNgxEvent(event) {
			var hostUrl =
				location.hostname === 'localhost'
					? 'http://' + location.hostname + ':3005'
					: location.origin;
			if(event.origin !== hostUrl) return;
			if (event.data.ngxLocationChanged) {
				var ngState = event.data.ngxLocationChanged.ngState;
				var id = event.data.ngxLocationChanged.id;

				if (id == null) {
					$state.transitionTo(ngState, null, {
						location: true,
						inherit: true,
						relative: $state.$current,
						notify: false
					});
				} else {
					$state.transitionTo(
						ngState,
						{
							id: id
						},
						{
							location: true,
							inherit: true,
							relative: $state.$current,
							notify: false
						}
					);
				}
			}
		}

		$window.addEventListener('message', handleNgxEvent, false);

		// Unregister
		$scope.$on('$destroy', function () {
			window.removeEventListener('message', handleNgxEvent, false);
		});

		setNgxPageUrl();
	}
})();
