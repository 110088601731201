(function() {
    'use strict';

    angular
        .module('atheer.user')
        .controller('UserMenuController', UserMenuController);

    /* @ngInject */
    function UserMenuController($rootScope, $scope, $state, $mdDialog, Content, Team, ParseLinks, AlertService, ContentAction, pagingParams, paginationConstants, $mdToast, $stateParams, $filter, $translate, Feature) {
        var vm = this;

        vm.loadAll = function() {
            vm.loadingData = false;
        }

        vm.loadAll();
    }
})();
