(function() {
    'use strict';

    angular
        .module('atheer.insights')
        .controller('InsightsUserController', InsightsUserController);

    /* @ngInject */
    function InsightsUserController($scope, $q, $filter, $translate, Report, Workspace, workspaceId, triSettings) {
        var vm = this;

        vm.primaryColor = triSettings.getSkinModel().primary_color_hex;
        vm.secondaryColor = triSettings.getSkinModel().secondary_color_hex;
        vm.loadingData = false;
        vm.dashboardModel = null;
        vm.illustartionEmptyScreenId = "insight_graph";

        vm.topUsersByCreatedCount = {
            chart: {
                type: 'column',
            },
            title: {
                text: $translate.instant('atheer.insights.users.usersCreatedByCount')
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            yAxis: {
                title: {
                    text: $translate.instant('atheer.insights.users.noOfUsers')
                },
                allowDecimals: false
            },
            xAxis: {
                categories: [],
                labels: {
                    enabled: true
                }
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            series: [{
                name: $translate.instant('atheer.insights.users.title'),
                color: vm.primaryColor,
                data: []
            },
            {
                name: $translate.instant('atheer.insights.users.guestUsers'),
                color: vm.secondaryColor,
                data: []
            }],
            tooltip: {
                formatter: function() {
                    return '<b>' + this.series.name + '</b> : ' + this.y + ' ' + this.x;
                }
            }
        };

        function performDataProcess() {
            angular.forEach(vm.dashboardModel.users_created_by_date_count, function(topUsersByCreatedCount) {
                vm.topUsersByCreatedCount.xAxis.categories.push(topUsersByCreatedCount.name);
                if (topUsersByCreatedCount.label == 'User') {
                    vm.topUsersByCreatedCount.series[0].data.push(topUsersByCreatedCount.count);
                }
                if (topUsersByCreatedCount.label == 'Guest') {
                    vm.topUsersByCreatedCount.series[1].data.push(topUsersByCreatedCount.count);
                }
            });
        
            vm.loadingData = false;
        }

        vm.loadData = function() {
            vm.loadingData = true;

            var resource = angular.isDefined(workspaceId) ? Workspace.getUserDashboard({
                id: workspaceId
            }).$promise : Report.getUserDashboard().$promise;

            $q.all([
                    resource
                ])
                .then(function(results) {
                    vm.dashboardModel = results[0];
                    performDataProcess()
                });
        }

        vm.loadData();
    }
})();
