(function() {
    'use strict';

    angular
        .module('atheer.session')
        .controller('sessionSummaryReportController', sessionSummaryReportController);

    /* @ngInject */
    function sessionSummaryReportController($scope, $state, $q, $mdDialog, $mdToast, $filter, Session, Principal, toastConstants) {
        var vm = this;
        vm.loadData = true;
        vm.ownerHasEmail = false;

        Principal.identity().then(function(user) {
            vm.owner = user;
            vm.loadData = false;
            if(vm.owner.email != null && vm.owner.email.length > 0) {
                vm.ownerHasEmail = true;
            }
        });

        vm.close = function() {
            $mdDialog.cancel();
        };

        vm.EmailReport = function() {
            Session.getSessionSummaryReport({
            }, onSuccess, onError);

            function onSuccess(result) {
                $mdDialog.cancel();
                displayToast("Summary will be sent to: " + vm.owner.email);
            }

            function onError(error) {
                displayToast("Failed to send Summary.")
            }
        };

        function displayToast(message) {
             $mdToast.show({
                    template: '<md-toast><span flex>' + message + '</span></md-toast>',
                    position: toastConstants.position,
                    hideDelay: 3000
                });
        };
    }
})();
