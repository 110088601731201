(function() {
    'use strict';

    angular
        .module('atheer.session')
        .controller('SessionPlayerController', SessionPlayerController);

    /* @ngInject */
    function SessionPlayerController($scope, $state, $q, $filter, Report, session_no, SessionPlayerService, $cookies) {
        var vm = this;
        vm.sessionNo = session_no;
        vm.isGuest = $cookies.get('isGuest');
    };
})();
