var SmartFlowStencil = SmartFlowStencil || {};
SmartFlowStencil.config = SmartFlowStencil.config || {};

(function() {

    'use strict';

    var svgns = "http://www.w3.org/2000/svg";

    SmartFlowStencil.config.stencil = {};

    SmartFlowStencil.config.stencil.groups = {
        triggers: {
            index: 1,
            label: 'Triggers'
        },
        activities: {
            index: 2,
            label: 'Activities'
        },
        decisions: {
            index: 3,
            label: 'Decisions'
        },
        flow: {
            index: 4,
            label: 'Flow Control'
        }
    };

    SmartFlowStencil.config.stencil.shapes = {};

    SmartFlowStencil.config.stencil.shapes.triggers = [{
        type: 'atheer.TeamTrigger'
    }];

    SmartFlowStencil.config.stencil.shapes.activities = [{
            type: 'atheer.ContentAssign'
        },
        {
            type: 'atheer.ContentRemove'
        },
        {
            type: 'atheer.ContentRecommend'
        },
        {
            type: 'atheer.AnnouncementAssign'
        },
        {
            type: 'atheer.EmailSend'
        },
        {
            type: 'atheer.PushNotificationSend'
        },
        {
            type: 'atheer.TeamAssign'
        },
        {
            type: 'atheer.TeamRemove'
        },
        {
            type: 'atheer.BadgeAssign'
        },
        {
            type: 'atheer.BadgeRemove'
        }
    ];

    SmartFlowStencil.config.stencil.shapes.decisions = [{
            type: 'atheer.ContentCheck'
        },
        {
            type: 'atheer.QuestionCheck'
        },
        {
            type: 'atheer.UserFieldCheck'
        },
        {
            type: 'atheer.TeamCheck'
        }
    ];

    SmartFlowStencil.config.stencil.shapes.flow = [{
            type: 'atheer.Wait'
        },
        {
            type: 'atheer.End'
        }
    ];

    SmartFlowStencil.getPaperCell = function(cell) {
        return cell.clone();
    }
})();
