(function() {
    'use strict';
    angular
        .module('atheer.services')
        .factory('WorkspaceSignup', WorkspaceSignup);

    /* @ngInject */
    function WorkspaceSignup($resource) {
        var resourceUrl = 'api/workspace-signups/:code';

        return $resource(resourceUrl, {
            id: '@id'
        }, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                        return data;
                    } else {
                        return null;
                    }
                }
            },
            'checkName': {
                url: 'api/workspace-signups/name/:name',
                method: 'GET',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                        return data;
                    } else {
                        return null;
                    }
                }
            },
            'update': {
                method: 'PUT'
            },
            'activate': {
                url: 'api/workspace-signups/activate',
                method: 'POST'
            }
        });
    }
})();
