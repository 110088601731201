(function() {
    'use strict';

    angular
        .module('atheer.session')
        .controller('SessionPlayerPasscodeController', SessionPlayerPasscodeController);

    /* @ngInject */
    function SessionPlayerPasscodeController($scope, $state, $q, $timeout, $filter, Report, Session, sessionModel, $mdDialog, $translate, ToastService, $stateParams) {
        var vm = this;

        vm.validPasscode = false;

        vm.passcode1 = null;
        vm.passcode2 = null;
        vm.passcode3 = null;
        vm.passcode4 = null;
        vm.passcode5 = null;
        vm.passcode6 = null;

        vm.sessionModel = sessionModel;

        vm.checkPasscode = function(modelVal, code) {
            if (modelVal != '' && modelVal != null && code < 6) {
                $timeout(function() {
                    var nextInput = '[ng-model="vm.passcode' + (code + 1) + '"]';
                    angular.element(nextInput).focus();
                });
            } else {
                var confirmationCode = vm.passcode1 + vm.passcode2 + vm.passcode3 + vm.passcode4 + vm.passcode5 + vm.passcode6;

                vm.validPasscode = false;

                if (confirmationCode && confirmationCode.length == 6) {
                    vm.validPasscode = true;
                }

            };
        };

        vm.joinSession = function() {
            var confirmationCode = vm.passcode1 + vm.passcode2 + vm.passcode3 + vm.passcode4 + vm.passcode5 + vm.passcode6;
            Session.validatePasscode({
                id: vm.sessionModel.id,
                passcode: confirmationCode
            }, onSuccessValidatePasscode, onErrorValidatePasscode)
        }

        function onSuccessValidatePasscode() {
            $mdDialog.hide(true);
        };

        function onErrorValidatePasscode() {
            ToastService.displayToast($translate.instant('atheer.session.detail.invalidPasscode'), 3000);
        };

        vm.closeAirSession = function() {
            $state.go('storm.sessions.upcoming');
            $mdDialog.hide(true);
        };
    }
})();
