(function() {
    'use strict';

    angular
        .module('atheer')
        .controller('FieldSettingPanelController', FieldSettingPanelController);

    /* @ngInject */
    function FieldSettingPanelController(mdPanelRef, $scope, $state, $mdDialog, $q, $filter, $mdToast, Principal, metaFieldGroups, condition, conditionListOptions, $mdPanel, FIELD_CONDITION_CONSTANT, Audience,SecurityRole) {
        var vm = this;
        vm.booleanTemp = "";
        vm.tmp_date;
        vm.tmp_from_date;
        vm.tmp_to_date;
        vm.stringChoices;
        vm.metaFieldGroups = metaFieldGroups;
        vm.condition = condition;
        vm.conditionListOptions = conditionListOptions;

        if (condition.data_type === "OBJECT") {
            vm.conditionOperators = FIELD_CONDITION_CONSTANT[vm.condition.data_type][vm.condition.field_name];
            if(condition.field_name == "security-role"){
                getSecurityRoles();
            }
            else if(condition.field_name == "audience") {
                getAudienceRoles();
            }
        }
        else if (condition.data_type === "BOOLEAN") {
            //set default to true
            updateBoolean(condition.value);
            vm.conditionOperators = FIELD_CONDITION_CONSTANT[vm.condition.data_type];
        }
        else if (vm.condition.data_type == 'STRING' && (
            vm.condition.operator == 'IN' || vm.condition.operator == 'NOT_IN' )
        ) {
            vm.stringChoices = "";
            if ( condition.value ) {
                vm.stringChoices  = condition.value.join(", "); 
            }
            vm.conditionOperators = FIELD_CONDITION_CONSTANT[vm.condition.data_type];
        }
        else {
            vm.conditionOperators = FIELD_CONDITION_CONSTANT[vm.condition.data_type];
        }

        function getSecurityRoles() {
            $q.all([
                SecurityRole.query({
                    size: 100
                }).$promise
            ]).then(function(data) {
                vm.conditionListOptions = data[0];
            });
        }
        function getAudienceRoles() {
            $q.all([
                Audience.query({
                    size: 100
                }).$promise
            ]).then(function(data) {
                vm.conditionListOptions = data[0];
            });
        }

        if (condition.data_type === "LIST") {
            var fieldMeta;
            var fieldGroupMeta = $filter('filter')(vm.metaFieldGroups, {
                'name': condition.field_group_name
            })[0];
            //filter gets array of all fields which contain condition.field_name
            var fieldMetaArray = $filter('filter')(fieldGroupMeta.fields, {
                'name': condition.field_name
            });
            for(var cnt = 0; cnt < fieldMetaArray.length; cnt++) {
                if(fieldMetaArray[cnt].name === condition.field_name) {
                    fieldMeta = fieldMetaArray[cnt];
                    break;
                }
            }
            vm.conditionListOptions = fieldMeta.list_options;
            if(condition.value){
                var selectedListOption = $filter('filter')(fieldMeta.list_options, {
                    'option_id': condition.value
                })[0];
                condition.displayValue = selectedListOption.label;
            }
        }

        vm.done = function() {
            if(vm.condition.data_type == 'DATE') {
                checkDateValue();
            } else if (vm.condition.data_type == 'STRING' && (
                vm.condition.operator == 'IN' || vm.condition.operator == 'NOT_IN' )
            ) {
                if ( vm.stringChoices ) {
                    condition.value = vm.stringChoices.split(",").map(function(item) {
                        return item.trim();
                      });
                }
            }
            mdPanelRef.close();
        };

        vm.checkFilterOptions = function(condition, index) {
            return condition.value !== 'IN' && condition.value !== 'NOT_IN';
        };

        vm.disallowNegative = function (evt) {
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57)){
                evt.preventDefault();
                return false;
            }
            return true;
        }

        function checkDateValue() {
            if(vm.tmp_date || vm.tmp_from_date || vm.tmp_to_date) {
                if(vm.condition.operator === "NOT_EXISTS" || vm.condition.operator === "EXISTS") {
                    vm.condition.value = null;
                }
                else if(vm.condition.operator === "BETWEEN") {
                    if(vm.condition.value === null) {
                        vm.condition.value = {};
                    }
                    if(vm.tmp_from_date) {
                        vm.condition.value.from_date = new Date($filter('date')(vm.tmp_from_date, "yyyy-MM-ddT00:00:00.000Z" ,"+0000")).toISOString();
                    }
                    if(vm.tmp_to_date) {
                        vm.condition.value.to_date = new Date($filter('date')(vm.tmp_to_date, "yyyy-MM-ddT00:00:00.000Z" ,"+0000")).toISOString();
                    }
                }
                else if(vm.condition.operator !== "BETWEEN") {
                    if(vm.tmp_date) {
                        vm.condition.value = new Date($filter('date')(vm.tmp_date, "yyyy-MM-ddT00:00:00.000Z" ,"+0000")).toISOString();
                    }
                }
            }
        }

        vm.getDate = function(value) {
            return $filter('fpToDate')(value);
        }

        vm.setDate = function(conditionValue, value) {
            conditionValue = value;
        }

        vm.showInput = function(operator) {
            if (condition.data_type === "OBJECT") {
                var operatorMeta = $filter('filter')(FIELD_CONDITION_CONSTANT[condition.data_type][condition.field_name], {
                    value: operator
                }, true)[0];
            } else {
                var operatorMeta = $filter('filter')(FIELD_CONDITION_CONSTANT[condition.data_type], {
                    value: operator
                }, true)[0];
            }

            return operatorMeta.showInput;
        }

        function updateBoolean(val) {
            if(val === "" || val === null) {
                vm.condition.value = null;
                vm.booleanTemp = "";
            }
            else if(val.toString() === "false") {
                vm.condition.value = false;
                vm.booleanTemp = "false";
            }
            else if(val.toString() === "true") {
                vm.condition.value = true;
                vm.booleanTemp = "true";
            }
        }

        vm.updateConditionValue = function(condition) {
            if(vm.condition.data_type == 'BOOLEAN') {
                updateBoolean(condition);
            } else if (vm.condition.data_type == 'STRING' && (
                vm.condition.operator == 'IN' || vm.condition.operator == 'NOT_IN' )
            ) {
                vm.stringChoices = "";
                if ( condition.value ) {
                    vm.stringChoices  = condition.value.join(", "); 
                }
            }
            else {
                if(!vm.showInput(condition.operator)) {
                    condition.value = null;
                }
                if(condition.data_type == 'DATE') {
                    condition.value = null;
                    vm.tmp_date = null;
                    vm.tmp_from_date = null;
                    vm.tmp_to_date = null;
                }
            }
        }
        vm.updateConditionListObject = function(val) {
            vm.condition.value = val;
            vm.condition.displayValue = val.name;
        }
        vm.updateConditionList = function(val) {
            vm.condition.displayValue = val;
        }
    }
})();
