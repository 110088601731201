(function () {
    'use strict';

    angular
        .module('atheer.system')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('storm.system-admin', {
                url: '/system',
                abstract: true,
                views: {
                    '@storm': {
                        templateUrl: 'modules/system/system.tmpl.html',
                        controller: 'SystemController',
                        controllerAs: 'vm',
                    }
                },
                data: {
                    pageTitle: 'atheer.setting.home.title',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: false
                    }
                },
                resolve: {
                    workspaceId: function ($stateParams) {
                        return $stateParams.id;
                    }
                }
            })
            .state('storm.admin-setting', {
                url: '/settings',
                abstract: true,
                views: {
                    '@storm': {
                        templateUrl: 'modules/system/settings.tmpl.html',
                        controller: 'SettingController',
                        controllerAs: 'vm',
                    }
                },
                data: {
                    pageTitle: 'atheer.setting.home.title',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: false
                    }
                }
            })
            .state('storm.admin-setting.workspace-info', {
                url: '/workspace',
                controller: 'WorkspaceInfoSettingController',
                controllerAs: 'vm',
                templateUrl: 'modules/system/workspace-info/workspace-info-setting.tmpl.html',
                data: {
                    pageTitle: 'atheer.setting.workspaceInfo',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: false
                    }
                }
            })
            .state('storm.admin-setting.skin', {
                url: '/skin',
                controller: 'SkinSettingController',
                controllerAs: 'vm',
                templateUrl: 'modules/system/skin/skin-setting.tmpl.html',
                data: {
                    pageTitle: 'atheer.setting.logoAndColor',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: false
                    }
                }
            })
            .state('storm.admin-setting.dictionary', {
                url: '/label-dictionary',
                controller: 'LabelDictionaryController',
                controllerAs: 'vm',
                templateUrl: 'modules/system/label-dictionary/label-dictionary.tmpl.html',
                data: {
                    pageTitle: 'atheer.setting.dictionary.mainTitle',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: false
                    }
                }
            })
            .state('storm.admin-setting.notifications', {
                url: '/notifications?page&sort&search',
                templateUrl: 'modules/system/notification/notifications.html',
                controller: 'NotificationController',
                controllerAs: 'vm',
                data: {
                    pageTitle: 'atheer.setting.notifications',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: false
                    }
                },

                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }]
                }
            })
            .state('storm.admin-notification-detail', {
                url: '/settings/notifications/:id',
                templateUrl: 'modules/system/notification/notification-detail.tmpl.html',
                controller: 'NotificationDetailController as vm',
                resolve: {
                    id: function ($stateParams) {
                        return $stateParams.id;
                    }
                },
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false
                    }
                }
            })
            .state('storm.admin-setting.email', {
                url: '/email-setup',
                controller: 'EmailSettingController',
                controllerAs: 'vm',
                templateUrl: 'modules/system/email-setup/email-setting.tmpl.html',
                data: {
                    pageTitle: 'atheer.setting.setup',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: false
                    }
                }
            })
            .state('storm.admin-setting.user-signup', {
                url: '/user-signup',
                controller: 'UserSignupSettingController',
                controllerAs: 'vm',
                templateUrl: 'modules/system/user-signup/user-signup-setting.tmpl.html',
                data: {
                    pageTitle: 'atheer.setting.signup',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: false
                    }
                }
            })
            .state('storm.admin-setting.session', {
                url: '/session',
                controller: 'SessionSettingController',
                controllerAs: 'vm',
                templateUrl: 'modules/system/session/session-setting.tmpl.html',
                data: {
                    pageTitle: 'atheer.setting.session',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: false
                    }
                }
            })
            .state('storm.admin-setting.airform', {
                url: '/airform',
                controller: 'AirformSettingController',
                controllerAs: 'vm',
                templateUrl: 'modules/system/airform/airform-setting.tmpl.html',
                data: {
                    pageTitle: 'atheer.setting.airform',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: false
                    }
                }
            })
            .state('storm.admin-setting.camera', {
                url: '/camera-setting',
                controller: 'CameraSettingController',
                controllerAs: 'vm',
                templateUrl: 'modules/system/camera/camera-setting.tmpl.html',
                data: {
                    pageTitle: 'atheer.setting.camera',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: false
                    }
                }
            })
            .state('storm.admin-setting.features', {
                url: '/features',
                controller: 'SettingFeatureController',
                controllerAs: 'vm',
                templateUrl: 'modules/system/feature/feature-setting.tmpl.html',
                data: {
                    pageTitle: 'atheer.setting.features',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: false
                    }
                }
            })
            .state('storm.admin-setting.homepageconfig', {
                url: '/lens-home-page-config',
                controller: 'LensHomePageConfigController',
                controllerAs: 'vm',
                templateUrl: 'modules/system/lens-home-page-config/lens-home-page-config.tmpl.html',
                data: {
                    pageTitle: 'atheer.setting.homepageconfig',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: false
                    }
                }
            })
            .state('storm.admin-setting.jobpdfconfig', {
                url: '/jobreport-config',
                controller: 'JobPDFSettingsController',
                controllerAs: 'vm',
                templateUrl: 'modules/system/jobreport-config/jobpdf-setting.tmpl.html',
                data: {
                    pageTitle: 'atheer.setting.pdfconfig',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: false
                    }
                }
            })
            .state('storm.admin-setting.jobfileextconfig', {
                url: '/jobfileext-config',
                controller: 'FileExtSettingsController',
                controllerAs: 'vm',
                templateUrl: 'modules/system/fileext-config/fileext-setting.tmpl.html',
                data: {
                    pageTitle: 'atheer.setting.fileExtConfig',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: false
                    }
                }
            })
            .state('storm.admin-setting.workspacellmconfig', {
                url: '/llmconfig',
                controller: 'LLMConfigController',
                controllerAs: 'vm',
                templateUrl: 'modules/system/llmconfig/llmconfig-setting.tmpl.html',
                data: {
                    pageTitle: 'atheer.setting.llmConfig',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: false
                    }
                }
            })
            .state('storm.admin-setting.workspace-llmprompts', {
                url: '/llmprompts',
                controller: 'LLMPromptConfigController',
                controllerAs: 'vm',
                templateUrl: 'modules/system/llmpromptconfig/llmpromptconfig-setting.tmpl.html',
                data: {
                    pageTitle: 'atheer.setting.llmPromptConfig',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false,
                        showSideNavMenu: false
                    }
                }
            })
            .state('storm.admin-setting.material-icons', {
                url: '/material-icons',
                controller: 'MaterialIconsController',
                controllerAs: 'vm',
                templateUrl: 'modules/system/icons/material-icons.tmpl.html',
                resolve: {
                    icons: function ($http) {
                        return $http({
                            method: 'GET',
                            url: '/assets/samples/icons.json'
                        });
                    }
                }
            })
            .state('storm.admin-setting.fa-icons', {
                url: '/fa-icons',
                controller: 'FaIconsController',
                controllerAs: 'vm',
                templateUrl: 'modules/system/icons/fa-icons.tmpl.html',
                resolve: {
                    icons: function ($http) {
                        return $http({
                            method: 'GET',
                            url: '/assets/samples/fa.json'
                        });
                    }
                }
            });
    }
})();
