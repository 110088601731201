(function() {
    'use strict';

    angular
        .module('atheer.conversation')
        .service('ConversationTypingService', ConversationTypingService);

    /* @ngInject */
    function ConversationTypingService($rootScope, Pubnub, $filter, Principal, Feature) {

        var usersTyping = [];
        var channel = null;
        var isCurrentUserTyping = false;
        var typingListener = null;
        var currentUser = Principal.getUserInfo();

        var service = {
            init: init,
            destroy: destroy,
            getUsersTyping: getUsersTyping,
            startTyping: startTyping,
            stopTyping: stopTyping,
            isCurrentUserTyping: isCurrentUserTyping
        };

        return service;

        function init(conversation) {
            channel = 'conversation-' + conversation.id;
            isCurrentUserTyping = false;
            usersTyping = [];
            typingListener = $rootScope.$on(Pubnub.getPresenceEventNameFor(channel), function(ngEvent, presenceEvent) {
                updateTypingUserList(presenceEvent);
            });
        };

        function destroy() {
            channel = null;
            typingListener = null;
        };

        function updateTypingUserList(event) {
            // We don't want to receive our own presence events
            if (event['uuid'] === currentUser.id) return;

            // Add people typing
            if (event['action'] === 'state-change' && event['state']['isTyping']) {
                
                // Check if not already in the array
                if (!_.find(usersTyping, {
                        uuid: event['uuid']
                    }))
                    usersTyping.push({
                        uuid: event['uuid'],
                        userInfo: event['state']['userInfo']
                    });

            } else if ((event['action'] === 'state-change' && event['state']['isTyping'] === false) ||
                event['action'] === 'timeout' ||
                event['action'] === 'leave') {

                _.remove(usersTyping, function(user) {
                    return user['uuid'] === event['uuid'];
                });
            }

            // firing an event downwards
            $rootScope.$broadcast('conversationUserTypingList', usersTyping);
        };

        function getUsersTyping(uuid) {
            return usersTyping;
        };

        function setTypingState(isTyping) {
            isCurrentUserTyping = isTyping;
            Pubnub.setState({
                channels: [channel],
                uuid: currentUser.id,
                state: {
                    isTyping: isCurrentUserTyping,
                    userInfo: currentUser.first_name
                }
            });
        };

        function startTyping() {
            if (Feature.isActive('feature_conversation_typing_indicator')) {
                setTypingState(true)
            }

        };

        function stopTyping() {
            if (isCurrentUserTyping && Feature.isActive('feature_conversation_typing_indicator')) {
                setTypingState(false)
            }
        };
    }
})();
