(function() {
    'use strict';

    angular
        .module('atheer.services')
        .factory('LoginService', LoginService);

    function LoginService() {
        var service = {
            open: open
        };

        return service;

        function open() {}
    }
})();
