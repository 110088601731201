(function() {
    'use strict';

    angular
        .module('atheer.system')
        .controller('LocaleDialogController', LocaleDialogController);

    LocaleDialogController.$inject = ['$scope', '$stateParams', '$mdDialog', 'entity', 'Locale'];

    function LocaleDialogController ($scope, $stateParams, $mdDialog, entity, Locale) {
        var vm = this;
        vm.locale = entity;
        vm.load = function(id) {
            Locale.get({id : id}, function(result) {
                vm.locale = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('atheer:localeUpdate', result);
            $mdDialog.hide(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.locale.id !== null) {
                Locale.update(vm.locale, onSaveSuccess, onSaveError);
            } else {
                Locale.save(vm.locale, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $mdDialog.cancel();
        };
    }
})();
